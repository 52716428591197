import { tableSchema } from '@nozbe/watermelondb';
import { field, date, json } from '@nozbe/watermelondb/decorators';
import Model from './simapp-model';

export const newsSchema = tableSchema({
  name: 'news',
  columns: [
    { name: 'b_id', type: 'number', isIndexed: true },
    { name: 'uid', type: 'string', isIndexed: true },
    { name: 'title', type: 'string' },
    { name: 'subtitle', type: 'string', isOptional: true },
    { name: 'content', type: 'string' },
    { name: 'type', type: 'number' },
    { name: 'product_id', type: 'number', isOptional: true },
    { name: 'content_id', type: 'number', isOptional: true },
    { name: 'role_ids', type: 'string' },
    { name: 'country_ids', type: 'string' },
    { name: 'available_for_thermofisher', type: 'boolean' },
    { name: 'available_for_distributors', type: 'boolean' },
    { name: 'b_created_at', type: 'number' },
    { name: 'b_updated_at', type: 'number' },
    { name: 'created_at', type: 'number' },
    { name: 'updated_at', type: 'number' },
    { name: 'read', type: 'boolean' },
  ]
});

export default class News extends Model {
  static table = 'news'

  @field('b_id') bId // aka backend ID
  @field('uid') uid // aka backend ID
  @field('title') title
  @field('subtitle') subtitle
  @field('content') content
  @field('type') type
  @field('product_id') productId
  @field('content_id') contentId
  @json('role_ids', jsonData => jsonData) roleIds
  @json('country_ids', jsonData => jsonData) countryIds
  @field('available_for_thermofisher') availableForThermofisher
  @field('available_for_distributors') availableForDistributors
  @date('b_created_at') bCreatedAt
  @date('b_updated_at') bUpdatedAt
  @date('created_at') createdAt
  @date('updated_at') updatedAt
  @field('read') read
}
