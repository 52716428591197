import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { observable, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import { simappColors } from 'shared/lib/theme';
import SimappIcon from 'web/components/_ui/simapp-icon';

const styles = {
  productGroupCheckbox: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  productSubgroupCheckbox: {
    paddingLeft: 23,
  },
  inputWrapper: {
    display: 'flex',
    padding: '11px 16px'
  },
  input: {
    flex: 1
  },
  searchIcon: {
    color: simappColors.steelGrey,
    marginTop: 4,
    marginRight: 16
  },
};

@inject('productStore') @observer
class ProductFilter extends Component {
  @observable searchString = '';
  searchStringLower = '';
  @observable open = false;
  disabledProducts = {};

  @action setSearchString = (e) => {
    this.searchString = e.target.value;
    this.searchStringLower = e.target.value.toLowerCase();
  }

  onFilterSet = (e) => {
    // is also called, when input field is selected, but then it's value is ""
    // we don't want to propagate this to the store, so filter it out
    this.props.productStore.setFilter(e.target.value.filter(element => typeof element !== 'undefined' && element !== ''));
  };

  productFilter = () => [
    <div className={this.props.classes.inputWrapper} key="searchField">
      <SimappIcon
        icon="search"
        className={this.props.classes.searchIcon}
      />
      <Input placeholder="Product" ref={(e) => { this.searchField = e; }} value={this.searchString} onChange={this.setSearchString} className={this.props.classes.input} />
    </div>,
    [...this.props.productStore.productsUnique.map((p) => {
      if (this.disabledProducts[p.id]) return null;
      let index = -1;
      if (this.searchString.length > 0) {
        index = p.name.toLowerCase().indexOf(this.searchStringLower);
        if (index === -1) return null;
      }
      return (
        <MenuItem key={p.id} value={p.id}>
          <Checkbox checked={this.props.productStore.filteredProductsForUI.indexOf(p.id) > -1} color="primary" />
          <ListItemText primary={this.buildName(p.name, index)} />
        </MenuItem>
      );
    })]
  ];

  buildName = (name, index) => {
    if (index >= 0) {
      const substring = name.substr(index, this.searchString.length);
      return (
        <span>
          {name.substring(0, index)}
          <b>{name.substring(index, index + substring.length)}</b>
          {name.substring(index + substring.length)}
        </span>
      );
    }
    return name;
  }

  @action handleOpen = () => {
    this.disabledProducts = Object.assign(this.props.productStore.disabledProducts);
    this.open = true;
    this.searchString = '';
    window.setTimeout(() => {
      // eslint-disable-next-line react/no-find-dom-node
      ReactDOM.findDOMNode(this.searchField).childNodes[0].focus();
    }, 10);
  }

  @action handleClose = () => {
    this.open = false;
  }

  render() {
    const { productStore, MenuProps } = this.props;
    return (
      <Select
        multiple
        displayEmpty
        value={productStore.filteredProductsForUI}
        onChange={this.onFilterSet}
        disableUnderline
        renderValue={() => 'Product'}
        MenuProps={MenuProps}
        open={this.open}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
      >
        {this.productFilter()}
      </Select>
    );
  }
}

ProductFilter.wrappedComponent.propTypes = {
  productStore: PropTypes.object.isRequired,
  MenuProps: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductFilter);
