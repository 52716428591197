import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import ImageUploader from 'web/components/_misc/image-upload';
import { simappColors, simappConstants } from 'shared/lib/theme';
import SimappIcon from 'web/components/_ui/simapp-icon';
import InputField from 'web/components/_ui/form-elements/input-field';
import SelectField from 'web/components/_ui/form-elements/select-field';
import SaveButton from 'web/components/_ui/form-elements/save-button';
import CancelButton from 'web/components/_ui/form-elements/cancel-button';

const styles = {
  root: {
    height: '100vH',
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: simappConstants.contentMarginLarge
  },
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 8
  },
  subheading: {
    marginTop: 32,
    marginLeft: 8
  },
  newUser: {
    color: simappColors.pumpkin
  },
  formPaper: {
    padding: '1em',
  },
  avatarCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    width: 110,
    height: 110,
    marginTop: '1em'
  },
  avatarBtn: {
    marginTop: '0.5em',
    fontSize: '1rem'
  },
  logout: {
    color: simappColors.strawberry,
  },
  logoutText: {
    fontSize: '1.1rem',
    paddingBottom: '0.2em',
    paddingLeft: '0.3em'
  },
  saveBtn: {
    marginTop: 32,
    marginLeft: 8,
    marginRight: 8
  },
  buttonArea: {
    padding: '12px 24px'
  },
};

@inject('settingsForm', 'applicationStore', 'countryStore', 'roleStore') @observer
class Settings extends Component {
  constructor(props) {
    super(props);
    // populate Form
    this.props.settingsForm.setDefaultValues();
  }

  onDrop = (picture) => {
    const { onFieldChange } = this.props.settingsForm;
    const reader = new FileReader();
    reader.readAsDataURL(picture[0]);
    reader.onload = (event) => {
      onFieldChange({ target: { id: 'profile_pic', value: event.target.result } });
    };
  }

  handleSave = () => {
    this.props.settingsForm.save();
    this.props.applicationStore.addNotification('Saved Settings');
  }

  render() {
    const { applicationStore, countryStore, settingsForm, classes } = this.props;
    const { fields, onFieldChange } = settingsForm;
    return (
      <div className={classes.root}>
        <div className={classes.headerRow}>
          <Typography variant="h5" gutterBottom>Settings</Typography>
          <Button onClick={() => { applicationStore.logout(true); }} className={classes.logout}>
            <SimappIcon icon="logout" width={16} height={16} />
            <span className={classes.logoutText}>Logout</span>
          </Button>
        </div>
        { !this.props.applicationStore.userLoaded
          && <Typography variant="subtitle1" gutterBottom className={`${classes.subheading} ${classes.newUser}`}>Please set your user settings first.</Typography>
        }
        <Typography variant="subtitle1" gutterBottom className={classes.subheading}>Personal Information</Typography>
        <Grid container spacing={24} direction="row-reverse" justify="flex-end">
          <Grid item xs={3} md={4} lg={4} className={classes.avatarCol}>
            <Avatar alt="" src={fields.profile_pic.value} className={classes.avatar} />
            <div className={classes.avatarBtn}>
              <ImageUploader
                onChange={this.onDrop}
                buttonText="Change Photo"
                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                maxFileSize={5242880}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <Paper className={classes.formPaper}>
              <form>
                <InputField fields={fields} name="name" onChange={onFieldChange} />
                <Divider light />
                <InputField fields={fields} name="email" onChange={onFieldChange} />
                <Divider light />
                <InputField fields={fields} name="mobile_phone" onChange={onFieldChange} />
                <Divider light />
                <SelectField
                  fields={fields}
                  name="country_id"
                  list={countryStore.getCountriesForSelect()}
                  currentSelect={applicationStore.appUser.get('country_id')}
                  currentValue={countryStore.getCountryName(fields.country_id.value)}
                  onChange={(id) => { onFieldChange({ target: { id: 'country_id', value: id } }); }}
                />
                <Divider light />
                <SelectField
                  fields={fields}
                  name="role_id"
                  list={this.props.roleStore.getRolesForSelect()}
                  currentSelect={applicationStore.appUser.get('role_id')}
                  currentValue={this.props.roleStore.getRoleName(fields.role_id.value)}
                  onChange={(id) => { onFieldChange({ target: { id: 'role_id', value: id } }); }}
                />
              </form>
            </Paper>
          </Grid>
        </Grid>
        <Typography variant="subtitle1" gutterBottom className={classes.subheading}>Signature</Typography>
        <Paper className={classes.formPaper}>
          <form>
            <InputField fields={fields} name="signature" onChange={onFieldChange} />
          </form>
        </Paper>
        <DialogActions className={this.props.classes.buttonArea}>
          {applicationStore.userLoaded
            && <CancelButton form={settingsForm} onClick={settingsForm.setDefaultValues} label="Reset" />
          }
          <SaveButton form={settingsForm} onClick={this.handleSave} />
        </DialogActions>
      </div>
    );
  }
}

Settings.wrappedComponent.propTypes = {
  applicationStore: PropTypes.object.isRequired,
  countryStore: PropTypes.object.isRequired,
  roleStore: PropTypes.object.isRequired,
  settingsForm: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Settings);
