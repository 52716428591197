import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ModalHeader from 'web/components/_ui/modal-header';
import texts from 'shared/lib/resources/texts';
import InputField from 'web/components/_ui/form-elements/input-field';
import SaveButton from 'web/components/_ui/form-elements/save-button';
import CancelButton from 'web/components/_ui/form-elements/cancel-button';
import AlertDialog from 'web/components/_misc/alert-dialog';

const dialogStyles = {
  inputField: {
    width: '100%'
  },
  labelWrapper: {
    width: '25%',
    display: 'flex',
    position: 'relative',
    height: 32
  },
  textfield: {
    width: '90%',
    display: 'flex',
    position: 'relative',
    height: 32
  },
  dialogContent: {
    width: '100%',
    padding: '0 0 0 24px',
    overflow: 'hidden'
  },
  label: {
    position: 'static',
    transform: 'none',
    alignSelf: 'center',
    whiteSpace: 'nowrap',
    width: '100%',
    fontWeight: '500',
    fontSize: '17px'
  },
  dialog: {
    maxWidth: '496pt',
    maxHeight: '282pt',
    margin: 'auto'
  }
};

@inject('applicationStore', 'qcTestRequestForm') @observer
class TestRequestDialog extends Component {
  onSend() {
    this.props.qcTestRequestForm.send().then(() => {
      this.props.applicationStore.addNotification('Mail Sent.');
      this.props.applicationStore.toggleTestRequestDialog();
    }).catch(() => {
    });
  }

  errorMessage = () => {
    switch (this.props.qcTestRequestForm.responseError) {
      case this.props.qcTestRequestForm.errors.NO_MAIL_PERMISSION:
        return (
          <Typography className={this.props.classes.errorText}>It seems that you have not given Office 365 the required permission to send mails.<br />Please re-login and grant requested permissions:
            <Button size="large" variant="contained" color="primary" className={this.props.classes.loginButton} onClick={() => { this.props.applicationStore.login(true); }}>Log in</Button>
          </Typography>
        );
      default:
        return texts.couldNotSendMail;
    }
  }

  render() {
    const { classes, qcTestRequestForm } = this.props;
    const { fields, onFieldChange } = qcTestRequestForm;

    return (
      <Dialog
        open={this.props.trigger}
        title="Dialog"
        aria-labelledby="simple-dialog-title"
        fullWidth
        onClose={() => {
          this.props.applicationStore.toggleTestRequestDialog();
        }}
      >
        <ModalHeader id="simple-dialog-title" title={this.props.message} />
        <DialogContent className={classes.dialogContent}>
          <InputField fields={fields} name="instrumentName" onChange={onFieldChange} />
          <Divider light />
          <InputField fields={fields} name="testName" onChange={onFieldChange} />
          <Divider light />
          <InputField fields={fields} name="microlitersUsed" onChange={onFieldChange} />
        </DialogContent>
        <DialogActions>
          <CancelButton form={qcTestRequestForm} onClick={() => this.props.applicationStore.toggleTestRequestDialog()} label={this.props.cancelText} />
          <SaveButton form={qcTestRequestForm} onClick={() => this.onSend()} label={this.props.okText} />
        </DialogActions>
        <AlertDialog trigger={!!String(qcTestRequestForm.responseError).length} message={this.errorMessage()} close={qcTestRequestForm.resetResponseError} />
      </Dialog>
    );
  }
}

TestRequestDialog.wrappedComponent.propTypes = {
  applicationStore: PropTypes.object.isRequired,
  qcTestRequestForm: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  trigger: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  okText: PropTypes.string.isRequired
};

export default withStyles(dialogStyles)(TestRequestDialog);
