import React, { Component } from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AlertDialog from 'web/components/_misc/alert-dialog';

const styles = {
  div: {
    paddingLeft: '20px'
  }
};

@observer
class ComponentsDemo extends Component {
  @observable dialogOpen = false;

  @action openDialog = () => {
    this.dialogOpen = true;
  }

  @action closeDialog = () => {
    this.dialogOpen = false;
  }

  render() {
    return (
      <div style={styles.div}>
        <Typography variant="h1" gutterBottom>H1</Typography>
        <Typography variant="h2" gutterBottom>H2</Typography>
        <Typography variant="h3" gutterBottom>H3</Typography>
        <Typography variant="h4" gutterBottom>H4</Typography>
        <Typography variant="h5" gutterBottom>H5</Typography>
        <Typography variant="h6" gutterBottom>H6</Typography>
        <Typography variant="body1" gutterBottom>body1</Typography>
        <Typography variant="body2" gutterBottom>body2</Typography>
        <Typography variant="subtitle1" gutterBottom>subtitle1</Typography>
        <Button variant="contained" color="primary" onClick={this.openDialog}>Open Dialog</Button>
        <AlertDialog trigger={this.dialogOpen} title="Alert Demo" message="Alert Demo Message" close={this.closeDialog} />
      </div>
    );
  }
}

export default ComponentsDemo;
