import routing from './router-store';
import applicationStore from './application-store';
import countryStore from './country-store';
import productStore from './product-store';
import roleStore from './role-store';
import contentTypeStore from './content-type-store';
import languageStore from './language-store';
import topicStore from './topic-store';
import contentPieceStore from './content-piece-store';
import materialStore from './material-store';
import consumablesCalculatorStore from './consumables-calculator-store';
import newsStore from './news-store';
import settingsForm from './forms/settings-form';
import eventForm from './forms/event-form';
import addressForm from './forms/kryptor-addressform';
import shareForm from './forms/share-form';
import SendProposalForm from './forms/qc-send-proposal-form';
import pctStore from './pct-store';
import kryptorStore from './kryptor-store';
import calendarStore from './calendar-store';
import presentationStore from './presentation-store';
import logStore from './log-store';
import qcStore from './qc-store';
import qcNewCustomerForm from './forms/qc-new-customer-form';
import qcInstrumentStore from './qc-instrument-store';
import qcProductStore from './qc-product-store';
import qcCountryStore from './qc-country-store';
import qcCustomerStore from './qc-customer-store';
import qcAnalyteStore from './qc-analyte-store';
import qcTestRequestForm from './forms/qc-test-request-form';
import copeptinStore from './copeptin-store';
import calculatorStore from './calculator-store';

/**
 * WARNING: This is a legacy construct that might be removed in the future.
 * Would require to move all imports like
 * `import { materialStore } from 'shared/stores'` to
 * `import materialStore from stores/material-store`
 */

export { default as routing } from './router-store';
export { default as contentTypeStore } from './content-type-store';
export { default as languageStore } from './language-store';
export { default as topicStore } from './topic-store';
export { default as calendarStore } from './calendar-store';
export { default as contentPieceStore } from './content-piece-store';
export { default as countryStore } from './country-store';
export { default as productStore } from './product-store';
export { default as roleStore } from './role-store';
export { default as materialStore } from './material-store';
export { default as consumablesCalculatorStore } from './consumables-calculator-store';
export { default as newsStore } from './news-store';
export { default as applicationStore } from './application-store';
export { default as pctStore } from './pct-store';
export { default as kryptorStore } from './kryptor-store';
export { default as presentationStore } from './presentation-store';
export { default as logStore } from './log-store';
export { default as eventForm } from './forms/event-form';
export { default as shareForm } from './forms/share-form';
export { default as SendProposalForm } from './forms/qc-send-proposal-form';
export { default as qcStore } from './qc-store';
export { default as qcInstrumentStore } from './qc-instrument-store';
export { default as qcProductStore } from './qc-product-store';
export { default as qcCountryStore } from './qc-country-store';
export { default as qcCustomerStore } from './qc-customer-store';
export { default as qcAnalyteStore } from './qc-analyte-store';
export { default as copeptinStore } from './copeptin-store';
export { default as calculatorStore } from './calculator-store';

export default {
  contentTypeStore,
  calendarStore,
  languageStore,
  topicStore,
  contentPieceStore,
  applicationStore,
  countryStore,
  productStore,
  roleStore,
  materialStore,
  consumablesCalculatorStore,
  newsStore,
  routing,
  settingsForm,
  eventForm,
  shareForm,
  SendProposalForm,
  pctStore,
  kryptorStore,
  addressForm,
  presentationStore,
  logStore,
  qcStore,
  qcNewCustomerForm,
  qcInstrumentStore,
  qcProductStore,
  qcCountryStore,
  qcTestRequestForm,
  qcCustomerStore,
  qcAnalyteStore,
  copeptinStore,
  calculatorStore
};
