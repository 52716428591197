import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import NumberFormat from 'react-number-format';
import SimAppButton from 'web/components/_ui/simapp-button';
import { getTotalCosts, getTotalQCVolume, round, getItemFromList } from 'shared/helper/qc-helper';
import BaseOverlay from 'web/components/_ui/form-elements/base-overlay';
import AlertDialog from 'web/components/_misc/alert-dialog';
import { simappColors } from 'shared/lib/theme';
import QcProductTable from './qc-product-table';
import QcPriceDialog from './qc-price-dialog';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontWeight: 'normal'
  },
  headline: {
    flexDirection: 'row',
    justifyContent: 'center',
    display: 'flex',
    height: '56px',
    marginTop: '40px',
    marginBottom: '60px',
    paddingLeft: '20px',
    paddingRight: '20px',
    '& img': {
      height: '100%'
    }
  },
  buttonRow: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginTop: '30px',
    paddingLeft: '20px',
    paddingRight: '20px',
    marginBottom: '20px'
  },
  button: {
    marginLeft: '5px',
    marginRight: '5px'
  },
  discountContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
    marginBottom: '10px',
    paddingRight: '20px'
  },
  discountLabel: {
    lineHeight: '36px',
    padding: 'unset',
    margin: 'unset',
    paddingLeft: '5px',
    paddingRight: '5px'
  },
  discountButton: {
    padding: '0px',
    fontSize: 16,
    backgroundColor: 'transparent',
    color: `${simappColors.azul}`,
    '&:hover': {
      backgroundColor: 'unset'
    }
  },
  summaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& div': {
      alignItems: 'center',
      flexDirection: 'column',
      display: 'flex'
    },
    '& div div': {
      alignItems: 'center',
      flexDirection: 'row',
      display: 'flex'
    },
    '&.bold': {
      fontWeight: 'bold',
      marginTop: '30px'
    }
  },
  inlineText: {
    display: 'flex',
    flex: 1,
    marginLeft: '3px'
  },
  summaryTotal: {
    fontWeight: 'bold'
  }
};

@inject('qcStore', 'routing') @observer
class QcProductView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      overlayRequestFrom: -1,
      discountDialogIndex: -1,
      totalPriceDialogIndex: -1,
      showMassDiscountWarning: false,
      showMassDiscountDialog: false
    };
  }

  getProductSummary(products) {
    const { classes, qcStore } = this.props;
    const numberOfProducts = products.length;
    const totalCosts = getTotalCosts(products);
    const totalQCVolume = getTotalQCVolume(products);
    const costPerML = totalCosts !== 0 && totalQCVolume !== 0 ? round(totalCosts / totalQCVolume) : 0;
    return (
      <div className={classes.summaryContainer}>
        <div>
          <div className={classes.summeryTotal}>{qcStore.getText('QCProductSelectionSummaryTotal')}: </div>
          <div>{qcStore.getText('QCProductSelectionSummaryNumberOfProducts')}: {numberOfProducts}</div>
          <div>{qcStore.getText('QCProductSelectionSummaryCostPerML')}:
            <NumberFormat
              id="CostPerML"
              value={costPerML}
              className={classes.inlineText}
              onValueChange={() => { }}
              decimalScale={2}
              fixedDecimalScale
              decimalSeparator={qcStore.getDecimalSeparator()}
              thousandSeparator={qcStore.getThousandSeparator()}
              prefix={qcStore.getCurrencyPosition() === 'prefix' ? qcStore.getCurrencySign() : ''}
              suffix={qcStore.getCurrencyPosition() === 'suffix' ? ` ${qcStore.getCurrencySign()}` : ''}
              displayType="text"
            />
          </div>
          <div>{qcStore.getText('QCProductSelectionSummaryTotalQCVolume')}:
            <NumberFormat
              id="totalQcVolume"
              value={totalQCVolume}
              className={classes.inlineText}
              onValueChange={() => { }}
              decimalScale={0}
              fixedDecimalScale
              decimalSeparator={qcStore.getDecimalSeparator()}
              thousandSeparator={qcStore.getThousandSeparator()}
              suffix=" ml"
              displayType="text"
            />
          </div>
          <div>{qcStore.getText('QCProductSelectionSummaryTotalCost')}:
            <NumberFormat
              id="totalCost"
              value={totalCosts}
              className={classes.inlineText}
              onValueChange={() => { }}
              decimalScale={2}
              fixedDecimalScale
              decimalSeparator={qcStore.getDecimalSeparator()}
              thousandSeparator={qcStore.getThousandSeparator()}
              prefix={qcStore.getCurrencyPosition() === 'prefix' ? qcStore.getCurrencySign() : ''}
              suffix={qcStore.getCurrencyPosition() === 'suffix' ? ` ${qcStore.getCurrencySign()}` : ''}
              displayType="text"
            />
          </div>
        </div>
      </div>
    );
  }

  productValueChange = (index, value, type) => {
    const { qcStore } = this.props;
    if (type === 'PricePerUnit') {
      qcStore.updateProductPricePerUnit(index, value);
    } else if (type === 'Discount') {
      qcStore.updateProductDiscount(index, value);
    } else if (type === 'Quantity') {
      qcStore.updateProductQuantity(index, value);
    }
  }

  addProduct = () => {
    const { qcStore } = this.props;
    this.setState({
      overlayRequestFrom: qcStore.productList.length
    });
  }

  editProduct = (index) => {
    this.setState({
      overlayRequestFrom: index
    });
  }

  editDiscount = (index) => {
    const { qcStore } = this.props;
    this.setState({
      discountDialogIndex: index
    });
    if (qcStore.productList[index].discount === undefined && qcStore.massDiscount && qcStore.massDiscount.value) {
      this.setState({
        showMassDiscountWarning: true
      });
    }
  }

  editTotalPrice = (index) => {
    this.setState({
      totalPriceDialogIndex: index
    });
  }

  editMassDiscount = () => {
    this.setState({
      showMassDiscountDialog: true
    });
  }

  saveAndReview = () => {
    const { push } = this.props.routing;
    const { qcStore } = this.props;
    qcStore.saveCustomerProposal();
    push('/qc-calculator/proposal-review');
  }

  renderMassDiscountSelection = () => {
    const { qcStore } = this.props;
    return (
      <BaseOverlay
        open={this.state.showMassDiscountDialog}
        onClose={() => {
          this.setState({ showMassDiscountDialog: false });
        }}
        onSelect={(id) => {
          if (qcStore.massDiscount === undefined || qcStore.massDiscount.id !== id) {
            qcStore.updateMassDiscount(getItemFromList(id, qcStore.getDiscountSelectData()));
          }
          this.setState({ showMassDiscountDialog: false });
        }}
        currentSelect={qcStore.massDiscount ? qcStore.massDiscount.id : ''}
        label={qcStore.getText('QCProductSelectionProductViewMassDiscountDialogTitle')}
        list={qcStore.getDiscountSelectData()}
        valueField="name"
      />
    );
  }

  renderDiscountDialog = () => {
    const { qcStore } = this.props;
    return (
      <div>
        <AlertDialog
          trigger={this.state.showMassDiscountWarning}
          message={qcStore.getText('QCProductSelectionProductViewPriceDialogDiscountWarning')}
          close={() => {
            this.setState({
              showMassDiscountWarning: false,
              discountDialogIndex: -1
            });
          }}
          additionalActions={[{ label: qcStore.getText('QCProductSelectionProductViewPriceDialogYesButton'), action: () => this.setState({ showMassDiscountWarning: false }) }]}
          okLabel={qcStore.getText('QCProductSelectionProductViewPriceDialogNoButton')}
        />
        <QcPriceDialog
          trigger={!this.state.showMassDiscountWarning && this.state.discountDialogIndex !== -1}
          message={qcStore.getText('QCProductSelectionProductViewPriceDialogDiscountTitle')}
          cancelText={qcStore.getText('QCProductSelectionProductViewPriceDialogCancelButton')}
          okText={qcStore.getText('QCProductSelectionProductViewPriceDialogSaveButton')}
          onClose={() => {
            this.setState({
              discountDialogIndex: -1
            });
          }}
          onSave={(price, percentage) => {
            if (percentage !== undefined) {
              qcStore.updateProductDiscount(this.state.discountDialogIndex, percentage);
            } else if (price !== undefined) {
              qcStore.updateProductDiscount(this.state.discountDialogIndex, 0);
              qcStore.updateProductDiscountedPricePerUnit(this.state.discountDialogIndex, price);
            } else {
              qcStore.updateProductDiscount(this.state.discountDialogIndex, undefined);
            }
            this.setState({
              discountDialogIndex: -1
            });
          }}
          showPrice
          showDiscount
          currencyValue={qcStore.getCurrencySign()}
          currencyPosition={qcStore.getCurrencyPosition()}
        />
      </div>
    );
  }

  renderTotalPriceDialog = () => {
    const { qcStore } = this.props;
    return (
      <QcPriceDialog
        trigger={this.state.totalPriceDialogIndex !== -1}
        message={qcStore.getText('QCProductSelectionProductViewPriceDialogTotalPriceTitle')}
        cancelText={qcStore.getText('QCProductSelectionProductViewPriceDialogCancelButton')}
        okText={qcStore.getText('QCProductSelectionProductViewPriceDialogSaveButton')}
        onClose={() => {
          this.setState({
            totalPriceDialogIndex: -1
          });
        }}
        onSave={(price) => {
          if (price !== undefined && qcStore.productList[this.state.totalPriceDialogIndex].quantity > 0) {
            qcStore.updateProductDiscount(this.state.totalPriceDialogIndex, 0);
            const newPrice = price / qcStore.productList[this.state.totalPriceDialogIndex].quantity;
            qcStore.updateProductDiscountedPricePerUnit(this.state.totalPriceDialogIndex, round(newPrice));
          }
          this.setState({
            totalPriceDialogIndex: -1
          });
        }}
        showPrice
        currencyValue={qcStore.getCurrencySign()}
        currencyPosition={qcStore.getCurrencyPosition()}
      />
    );
  }

  render() {
    const { classes, qcStore, adminView, adminViewButtonClick } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.headline}>
          <img src="/icons/logo@2x.png" alt="" onClick={() => adminViewButtonClick()} /> {//eslint-disable-line
          }
        </div>
        <QcProductTable
          editProduct={index => this.editProduct(index)}
          editDiscountedPricePerUnit={index => this.editDiscount(index)}
          editTotalPrice={index => this.editTotalPrice(index)}
          deleteProduct={index => qcStore.deleteSelectedProduct(index)}
          editable
          showKitFormat
          showDiscountedPriceWhenActive
          adminView={adminView}
          showDiscountedPricePerUnit={adminView}
          productValueChanged={(index, value, type) => this.productValueChange(index, value, type)}
        />
        {adminView
          && (
            <div className={classes.discountContainer}>
              <p className={classes.discountLabel}>{qcStore.getText('QCProductSelectionProductViewMassDiscount')}:</p>
              <Button className={classes.discountButton} onClick={() => this.editMassDiscount()}>{qcStore.massDiscount ? qcStore.massDiscount.name : qcStore.getText('QCProductSelectionProductViewSelect')}</Button>
            </div>
          )
        }
        <div className={classes.buttonRow}>
          <div>
            <SimAppButton className={classes.button} onClick={() => this.addProduct()} inline>{qcStore.getText('QCProductSelectionProductViewAddProductButton')}</SimAppButton>
          </div>
        </div>
        {this.getProductSummary(qcStore.productList)}
        <div className={classes.buttonRow}>
          <SimAppButton className={classes.button} onClick={() => this.saveAndReview()} isNavigationButton inline>{qcStore.getText('QCProductSelectionProductViewSaveReviewButton')}</SimAppButton>
        </div>
        <BaseOverlay
          open={this.state.overlayRequestFrom !== -1}
          onClose={() => {
            this.setState({ overlayRequestFrom: -1 });
          }}
          onSelect={(id) => {
            const itemAdded = this.state.overlayRequestFrom === qcStore.productList.length;
            const product = getItemFromList(id, qcStore.getThermoProducts());
            if (itemAdded) {
              qcStore.addProduct(product);
            } else {
              qcStore.updateProduct(product, this.state.overlayRequestFrom);
            }
            this.setState({ overlayRequestFrom: -1 });
          }}
          currentSelect={this.state.overlayRequestFrom >= 0 && this.state.overlayRequestFrom < qcStore.productList.length ? qcStore.productList[this.state.overlayRequestFrom].product.id : ''}
          labelPrefix=""
          label=""
          list={qcStore.getThermoProducts()}
          valueField="name"
        />
        {this.renderMassDiscountSelection()}
        {this.renderDiscountDialog()}
        {this.renderTotalPriceDialog()}
      </div>
    );
  }
}

QcProductView.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  qcStore: PropTypes.object.isRequired,
  adminView: PropTypes.bool,
  adminViewButtonClick: PropTypes.func,
  routing: PropTypes.object.isRequired
};

QcProductView.defaultProps = {
  adminView: false,
  adminViewButtonClick: () => { }
};

export default withStyles(styles)(QcProductView);
