import { action, observable, toJS } from 'mobx';
import Validator from 'validatorjs';
import lang from 'validatorjs/src/lang';
import en from 'validatorjs/src/lang/en';

// eslint-disable-next-line
lang._set('en', en);

class BaseForm {
  @observable isValid = false;
  @observable error = null;
  @observable saveInProgess = false;

  getFlattenedValues = (valueKey = 'value') => {
    const data = {};
    const form = toJS(this.fields);
    Object.keys(form).map((key) => {
      data[key] = form[key][valueKey];
    });
    return data;
  };

  @action
  onFieldChange = (e) => {
    let field = e.target.id;
    // Select has name, not id
    if (typeof field === 'undefined') {
      field = e.target.name;
    }
    const { value } = e.target;
    this.fields[field].value = value;
    const validation = new Validator(
      this.getFlattenedValues('value'),
      this.getFlattenedValues('rule')
    );
    this.isValid = validation.passes();
    const errorMessage = validation.errors.first(field);
    if (errorMessage) {
      this.fields[field].error = errorMessage.replace(field, this.fields[field].label);
    } else {
      this.fields[field].error = '';
    }
    // update error status of corresponding fields
    if (Array.isArray(this.fields[field].validateWith)) {
      this.fields[field].validateWith.forEach((nextField) => {
        const nextErrorMessage = validation.errors.first(nextField);
        if (nextErrorMessage) {
          this.fields[nextField].error = nextErrorMessage.replace(nextField, this.fields[nextField].label);
          this.fields[nextField].error = this.fields[nextField].error.replace(field, this.fields[field].label);
        } else {
          this.fields[nextField].error = '';
        }
        this.fields[field].error = this.fields[field].error.replace(nextField, this.fields[nextField].label);
      });
    }
  };

  @action
  setError = (errMsg) => {
    this.error = errMsg;
  }

  // allows to overwrite a form value from anywhere
  @action setProperty = (property, value) => {
    this.fields[property].value = value;
  }

  // can be used to save a global error, e.g. from a server
  @observable responseError = '';

  @action setResponseError = (error) => {
    this.responseError = error;
  }

  resetResponseError = () => {
    this.setResponseError('');
  }
}

export default BaseForm;
