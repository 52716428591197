import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import SimAppButton from 'web/components/_ui/simapp-button';
import { withStyles } from '@material-ui/core/styles';
import { simappColors } from 'shared/lib/theme';
import AlertDialog from 'web/components/_misc/alert-dialog';
import WebWorker from 'web/helper/web-worker';
import TestViewWorker from 'shared/helper/qc-calculator-worker';
import QcInstrument from './qc-instrument';
import TestRequestDialog from './qc-test-request-dialog';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontWeight: 'normal'
  },
  buttonRow: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginTop: '30px',
    paddingLeft: '20px',
    paddingRight: '20px',
    marginBottom: '20px'
  },
  button: {
    marginLeft: '5px',
    marginRight: '5px'
  },
  disabled: {
    backgroundColor: 'transparent',
    border: `1px solid ${simappColors.disableButtonGrey}`,
    color: `${simappColors.disableButtonGrey}`,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }

};

@inject('qcStore', 'applicationStore', 'routing') @observer
class QcTestView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemAdded: false,
      isAnyTestEnabled: false,
      isCalculating: false
    };
  }

  componentDidMount = () => {
    this.worker = new WebWorker(TestViewWorker);
  };

  onClose = () => {
    const { qcStore } = this.props;
    qcStore.setNoInstrumentTestWithProduct(false);
  }

  isAnyTestEnabledCallback = (value) => {
    this.setState({
      isAnyTestEnabled: value
    });
  }

  onCalculateClick = () => {
    this.setState({
      isCalculating: true
    });

    this.worker.postMessage(JSON.stringify(
      {
        instrumentsData: this.props.qcStore.instrumentsData,
        productList: this.props.qcStore.getThermoProducts(),
        workingDaysPerWeek: this.props.qcStore.customerData.workingDaysPerWeek,
        contractPeriodInMonths: this.props.qcStore.customerData.contractPeriodInMonths,
        isTestView: true,
        priceIso: this.props.qcStore.getPriceCountryIso()
      }
    ));
    this.worker.addEventListener('message', (e) => {
      this.setState({
        isCalculating: false
      });
      const result = JSON.parse(e.data);
      this.props.qcStore.clearTestsWithoutProduct();
      this.props.qcStore.setNoInstrumentTestWithProduct(result.noProducts);
      result.noProductsList.map((item) => {
        this.props.qcStore.addTestWithoutProduct(item.testItem, item.levelItem);
      });
      this.props.qcStore.setCheapestResult(result.cheapest);
      this.props.qcStore.setSmallestQuantityResult(result.smallestQuantity);
      this.props.qcStore.setFewestSKUResult(result.smallestSKU);
      if (!this.props.qcStore.noInstrumentTestWithProduct) {
        this.props.qcStore.setCalculationView('TestView');
        this.props.routing.push('/qc-calculator/options-proposal');
      }
    });
  }

  addInstrument() {
    this.state.itemAdded = true;
    this.props.qcStore.addListInstrument();
  }

  render() {
    const { classes, qcStore } = this.props;
    return (
      <div className={classes.root}>
        <div>
          {qcStore.instrumentsData.map((item, index) => {
            const key = index;
            let expand = false;
            if (this.state.itemAdded && index === (qcStore.instrumentsData.length - 1)) {
              expand = true;
            }
            return (
              <QcInstrument
                key={key}
                index={index}
                data={item}
                expand={expand}
                isAnyTestEnabled={this.state.isAnyTestEnabled}
                isAnyTestEnabledCallback={this.isAnyTestEnabledCallback}
              />
            );
          })}
        </div>
        <div className={classes.buttonRow}>
          <div>
            <SimAppButton
              className={classes.button}
              onClick={() => this.addInstrument()}
              inline
            >{qcStore.getText('QCProductSelectionTestViewAddNewInstrument')}
            </SimAppButton>
          </div>
        </div>
        <div className={classes.buttonRow}>
          <SimAppButton
            className={classes.button}
            onClick={async () => this.onCalculateClick()}
            disabled={!this.state.isAnyTestEnabled}
            inline
            isCalculateButton
            isCalculating={this.state.isCalculating}
          >{qcStore.getText('QCProductSelectionCalculate')}
          </SimAppButton>
        </div>
        <AlertDialog trigger={qcStore.noInstrumentTestWithProduct} message={qcStore.getText('QCProductSelectionTestViewAllTestsWithoutProduct')} close={this.onClose} />

        <TestRequestDialog
          trigger={this.props.applicationStore.showTestRequestDialog}
          message={qcStore.getText('QcTestRequestDialogMessage')}
          okText={qcStore.getText('QCTestRequestDialogSend')}
          cancelText={qcStore.getText('QCTestRequestDialogCancel')}
        />
      </div>
    );
  }
}

QcTestView.wrappedComponent.propTypes = {
  qcStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired,
  applicationStore: PropTypes.object.isRequired
};

export default withStyles(styles)(QcTestView);
