import { simappColors } from 'shared/lib/theme';

export const headlineRow = {
  display: 'flex',
  justifyContent: 'space-between',
  margin: '4px 8px 0px 16px',
  '& h5': {
    flex: 1,
    fontSize: '1.25rem',
    paddingTop: 8,
    paddingBottom: 8,
    '& span': {
      fontSize: '1rem',
      color: simappColors.azul,
    }
  },
  '& button': {
    fontSize: '1rem',
  },
};

export const section = {
  marginBottom: '1rem',
  background: '#ddd',
  paddingBottom: 8
};
