import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FlipMove from 'react-flip-move';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { simappColors } from 'shared/lib/theme';

const styles = {
  root: {
    position: 'absolute',
    right: 20,
    top: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    zIndex: 100,
  },
  notification: {
    backgroundColor: simappColors.darkGrey,
    padding: '8px 26px',
    margin: '8px 0',
    boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12)'
  },
  message: {
    color: simappColors.white
  }
};

@inject('applicationStore') @observer
class Notifications extends Component {
  render() {
    return (
      <div className={this.props.classes.root}>
        <FlipMove enterAnimation="fade" leaveAnimation="fade" maintainContainerHeight typeName={null}>
          {this.props.applicationStore.notifications.map(n => (
            <Paper className={this.props.classes.notification} key={0}>
              <Typography className={this.props.classes.message}>{n.message}</Typography>
            </Paper>
          ))}
        </FlipMove>
      </div>
    );
  }
}

Notifications.wrappedComponent.propTypes = {
  applicationStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Notifications);
