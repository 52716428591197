import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { simappColors } from 'shared/lib/theme';

const styles = {
  root: {
    borderBottom: `1px solid ${simappColors.dark}`,
    paddingLeft: 0,
    display: 'flex'
  },
  current: {
    backgroundColor: simappColors.dark,
    '& span': {
      color: simappColors.white
    }
  },
  listitem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  text: {
    '& span': {
      fontSize: '0.875rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  },
  selectSharing: {
    borderRadius: 0,
    marginRight: 8,
    padding: 0,
    '& *': {
      color: `${simappColors.azul} !important`,
    }
  },
  selectSharingChecked: {
  }
};

@inject('routing', 'applicationStore', 'materialStore') @observer
class SidebarListitemSmall extends Component {
  updateSelection = (e, checked) => {
    if (checked) {
      this.props.materialStore.addToSharingMaterialsCache(e.target.value);
    } else {
      this.props.materialStore.removeFromSharingMaterialsCache(e.target.value);
    }
  }

  render() {
    const { classes, applicationStore, materialStore, current, uid, onClick, primary, shareable } = this.props;
    return (
      <div className={`${classes.root} ${current ? classes.current : ''} ${this.props.className}`}>
        { applicationStore.sharingMode && !applicationStore.sharingOpen && shareable
        && (
          <Checkbox color="primary" className={classes.selectSharing} classes={{ checked: classes.selectSharingChecked }} value={uid} centerRipple={false} onChange={this.updateSelection} checked={materialStore.sharingMaterialsCache.indexOf(uid) > -1} />
        )}
        <ListItem button onClick={onClick} className={classes.listitem}>
          <ListItemText primary={primary} className={classes.text} />
        </ListItem>
      </div>
    );
  }
}

SidebarListitemSmall.wrappedComponent.propTypes = {
  materialStore: PropTypes.object.isRequired,
  applicationStore: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  current: PropTypes.bool,
  primary: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  uid: PropTypes.string.isRequired,
  shareable: PropTypes.bool.isRequired,
  className: PropTypes.string
};

SidebarListitemSmall.wrappedComponent.defaultProps = {
  current: false,
  className: ''
};

export default withStyles(styles)(SidebarListitemSmall);
