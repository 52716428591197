import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { simappColors } from 'shared/lib/theme';

const styles = {
  root: {
    height: '2.7em',
    borderBottom: `1px solid ${simappColors.lightGrey}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    fontSize: '1.2rem'
  },
  closeButton: {
    minHeight: 0,
    minWidth: 0,
    position: 'absolute',
    right: 5,
    fontSize: 16
  },
  selectButton: {
    minHeight: 0,
    minWidth: 0,
    position: 'absolute',
    left: 5,
    fontSize: 16
  }
};

const ModalHeader = props => (
  <div className={props.classes.root}>
    { typeof props.handleSelect === 'function' && (
      <Button color="primary" onClick={props.handleSelect} className={props.classes.selectButton}>Select</Button>
    )}
    <Typography variant="h5" className={props.classes.title}>{props.title}</Typography>
    { typeof props.handleClose === 'function' && (
      <Button color="primary" onClick={props.handleClose} className={props.classes.closeButton}>Close</Button>
    )}
  </div>
);

ModalHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  handleClose: PropTypes.func,
  handleSelect: PropTypes.func
};

ModalHeader.defaultProps = {
  title: '',
  handleClose: undefined,
  handleSelect: undefined
};

export default withStyles(styles)(ModalHeader);
