export default {
  countryChangedTo: 'Region Changed to: ',
  countryChangedNote: 'Please note that changing the region means you will only see materials and news available for this region. This does not affect the language of the app.',
  dontShowAgain: 'Don\'t show me again',
  presentationNotSavedTitle: 'Your presentation will not be saved',
  presentationNotUpdatedTitle: 'Your changes will not be saved',
  presentationNotSavedText: 'Proceed without saving?',
  sharingPopupTitle: 'Share Materials',
  sharingPopupExpiryNote1: 'Please note: ',
  sharingPopupExpiryNote2: 'The link(s) to material(s) attached will expire in 2 weeks from the time it is sent.',
  sharingPopupSelectButton: 'Select more...',
  offlineInfo: 'No internet connection available.',
  couldNotSendMail: 'Could not sent Mail. Please try again later or inform your Administrator.',
};
