import { tableSchema } from '@nozbe/watermelondb';
import { field, date } from '@nozbe/watermelondb/decorators';
import Model from './simapp-model';

export const qcManufacturerSchema = tableSchema({
  name: 'qcManufacturer',
  columns: [
    { name: 'b_id', type: 'number', isIndexed: true },
    { name: 'uid', type: 'string', isIndexed: true },
    { name: 'name', type: 'string' },
    { name: 'b_created_at', type: 'number' },
    { name: 'b_updated_at', type: 'number' },
    { name: 'created_at', type: 'number' },
    { name: 'updated_at', type: 'number' },
  ]
});

export default class QcManufacturer extends Model {
  static table = 'qcManufacturer'

  @field('b_id') bId;
  @field('uid') uid;
  @field('name') name;
  @date('b_created_at') bCreatedAt;
  @date('b_updated_at') bUpdatedAt;
  @date('created_at') createdAt;
  @date('updated_at') updatedAt;
}
