
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ThermoFooter from './thermo-footer';

const styles = {
  outer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  headline: {
    margin: '10vH 0 20vH 0',
    fontWeight: 500,
    fontSize: '1.5rem',
    textAlign: 'center'
  }
};

const NoBackend = props => (
  <div className={props.classes.outer}>
    <Typography className={props.classes.headline}>Sorry, SIMAPP Backend is currently not available.<br />Please try again later.</Typography>
    <ThermoFooter />
  </div>
);

NoBackend.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NoBackend);
