import { action, observable } from 'mobx';
import { applicationStore } from 'shared/stores';
import BaseForm from './base-form';

class SettingsForm extends BaseForm {
  @observable
  fields = {
    email: {
      value: '',
      error: null,
      disabled: true,
      label: 'Email',
      rule: 'required|email'
    },
    name: {
      value: '',
      error: null,
      rule: 'required',
      required: true,
      label: 'Name',
      placeholder: 'Your full name'
    },
    mobile_phone: {
      value: '',
      error: null,
      label: 'Mobile Phone',
      rule: 'string',
      placeholder: 'Please also provide your region code, e.g. +49'
    },
    role_id: {
      value: 0,
      error: null,
      label: 'Role',
      rule: 'required|min:1',
      required: true,
      placeholder: 'Select a role'
    },
    country_id: {
      value: 0,
      error: null,
      label: 'Region',
      rule: 'required|min:1',
      required: true,
      placeholder: 'Select a region'
    },
    signature: {
      value: '',
      error: null,
      // label: 'Signature',
      rule: 'string',
      multiline: true,
      rows: 8
    },
    profile_pic: {
      value: '',
      error: null,
      // label: 'Signature',
      rule: 'string'
    }
  }

  @action setDefaultValues = () => {
    applicationStore.appUser.forEach((value, key) => {
      if (typeof this.fields[key] !== 'undefined') {
        this.fields[key].value = value;
        this.onFieldChange({ target: { id: key, value } });
      }
    });
  }

  @action onNativeFieldChange = (e) => {
    this.fields[e.fieldName].value = e.value;
  }

  @action save = (navigation) => {
    this.saveInProgess = true;
    // news are filtered by user role on the server side, so we must reload them after changing the user role
    const reloadNews = this.fields.role_id.value !== applicationStore.appUser.get('role_id');
    Object.keys(this.fields).forEach((key) => {
      applicationStore.appUser.set(key, this.fields[key].value);
    });
    applicationStore.updateAppUser(navigation, reloadNews);
    this.saveInProgess = false;
  }
}

export default new SettingsForm();
