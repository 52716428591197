import { observable, runInAction } from 'mobx';
import { persist } from 'mobx-persist';
import rest from 'shared/lib/rest';
import { merge } from 'shared/lib/helpers';
import createStorage from './create-storage';

const route = '/country';

class CountryStore {
  constructor() {
    createStorage('countryStore', this).then(() => {
    });
  }

  @persist('list') @observable countries = [];

  loadData(forceReload = false, lastUpdated = null) {
    const startTime = new Date().getTime();
    return new Promise((resolve) => {
      let params = '?';
      if (this.hasDataLoaded && !forceReload && lastUpdated !== null) {
        params += `since=${lastUpdated}`;
      } else if (forceReload) {
        runInAction(() => {
          this.countries = [];
        });
      }

      rest.get(route + params).then((response) => {
        runInAction(() => {
          merge(this.countries, response.data, 'id', 'name');
          console.log(`${new Date().getTime() - startTime}ms - ${this.constructor.name}`);
          resolve();
        });
      }).catch((error) => {
        console.log('Could not load countries', error.response.data);
        resolve();
      });
    });
  }

  get countryCount() {
    return this.countries.length;
  }

  get hasDataLoaded() {
    return !!this.countries.length;
  }

  getCountryName(id) {
    const result = this.countries.find(country => country.id === id);
    return typeof result !== 'undefined' ? result.name : '';
  }

  getCountryIso(id) {
    const result = this.countries.find(country => country.id === id);
    return typeof result !== 'undefined' ? result.iso : '';
  }

  getCountriesForSelect() {
    const result = [];
    this.countries.map((option) => {
      result.push({ id: option.id, value: option.name, iso: option.iso });
    });
    return result;
  }
}

export default new CountryStore();
