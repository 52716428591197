export default class CopeptinResultCalculation {
  copeptinStore;

  constructor(copeptinStore) {
    this.copeptinStore = copeptinStore;
  }

  calculateStaffTimesDoctor = () => {
    const staffTimesDoctor = [];
    staffTimesDoctor.push(this.calculateRow('copeptinResultStaffTimesPhysicalExamination', null,
      [this.copeptinStore.getStaffTimesDoctorValue(1)], [this.copeptinStore.getStaffTimesDoctorValue(1)]));
    staffTimesDoctor.push(this.calculateRow('copeptinResultStaffTimesFirstECG', null,
      [this.copeptinStore.getStaffTimesDoctorValue(2)], [this.copeptinStore.getStaffTimesDoctorValue(2)]));
    staffTimesDoctor.push(this.calculateRow('copeptinResultStaffTimesSecondECG', this.multiply,
      [this.copeptinStore.getStaffTimesDoctorValue(5), this.copeptinStore.getPatientProportionCopeptinValue(4)],
      [this.copeptinStore.getStaffTimesDoctorValue(5), this.copeptinStore.getPatientProportionDefaultValue(4)]));
    staffTimesDoctor.push(this.calculateRow('copeptinResultStaffTimesFirstBloodSampling', null,
      [this.copeptinStore.getStaffTimesDoctorValue(3)], [this.copeptinStore.getStaffTimesDoctorValue(3)]));
    staffTimesDoctor.push(this.calculateRow('copeptinResultStaffTimesThreeHourBloodSampling', this.multiply,
      [this.copeptinStore.getStaffTimesDoctorValue(3), this.copeptinStore.getPatientProportionCopeptinValue(6)],
      [this.copeptinStore.getStaffTimesDoctorValue(3), this.copeptinStore.getPatientProportionDefaultValue(6)]));
    staffTimesDoctor.push(this.calculateRow('copeptinResultStaffTimesSixHourBloodSampling', this.multiply,
      [this.copeptinStore.getStaffTimesDoctorValue(3), this.copeptinStore.getPatientProportionCopeptinValue(7)],
      [this.copeptinStore.getStaffTimesDoctorValue(3), this.copeptinStore.getPatientProportionDefaultValue(7)]));
    staffTimesDoctor.push(this.calculateRow('copeptinResultStaffTimesEcho', this.multiply,
      [this.copeptinStore.getStaffTimesDoctorValue(6), this.copeptinStore.getPatientProportionCopeptinValue(10)],
      [this.copeptinStore.getStaffTimesDoctorValue(6), this.copeptinStore.getPatientProportionDefaultValue(10)]));
    staffTimesDoctor.push(this.calculateRow('copeptinResultStaffTimesMedicationDelivery', this.multiply,
      [this.copeptinStore.getStaffTimesDoctorValue(11), this.copeptinStore.getPatientProportionCopeptinValue(11)],
      [this.copeptinStore.getStaffTimesDoctorValue(11), this.copeptinStore.getPatientProportionDefaultValue(11)]));
    staffTimesDoctor.push(this.calculateRow('copeptinResultStaffTimesTransferCPU', this.multiply,
      [this.copeptinStore.getStaffTimesDoctorValue(8), this.copeptinStore.getPatientProportionCopeptinValue(12)],
      [this.copeptinStore.getStaffTimesDoctorValue(8), this.copeptinStore.getPatientProportionDefaultValue(12)]));
    staffTimesDoctor.push(this.calculateRow('copeptinResultStaffTimesTransferStation', this.multiplyDiff,
      [this.copeptinStore.getStaffTimesDoctorValue(9), this.copeptinStore.getPatientProportionCopeptinValue(0)],
      [this.copeptinStore.getStaffTimesDoctorValue(9), this.copeptinStore.getPatientProportionDefaultValue(0)]));
    staffTimesDoctor.push(this.calculateRow('copeptinResultStaffTimesInitialAssessment', null,
      [this.copeptinStore.getStaffTimesDoctorValue(0)], [this.copeptinStore.getStaffTimesDoctorValue(0)]));
    staffTimesDoctor.push(this.calculateRow('copeptinResultStaffTimesCommunication', null,
      [this.copeptinStore.getStaffTimesDoctorValue(7)], [this.copeptinStore.getStaffTimesDoctorValue(7)]));
    staffTimesDoctor.push(this.calculateRow('copeptinResultStaffTimesDocumentation', this.multiply,
      [this.copeptinStore.getStaffTimesDoctorValue(13), this.copeptinStore.getPatientProportionCopeptinValue(0)],
      [this.copeptinStore.getStaffTimesDoctorValue(13), this.copeptinStore.getPatientProportionDefaultValue(0)]));
    staffTimesDoctor.push(this.calculateRow('copeptinResultStaffTimesDischarge', this.multiply,
      [this.copeptinStore.getStaffTimesDoctorValue(14), this.copeptinStore.getPatientProportionCopeptinValue(0)],
      [this.copeptinStore.getStaffTimesDoctorValue(14), this.copeptinStore.getPatientProportionDefaultValue(0)]));
    staffTimesDoctor.push(this.calculateRow('copeptinResultStaffTimesDoctorSum', this.sum,
      this.getArrayFromObjectArray(staffTimesDoctor, 'copeptin_value'),
      this.getArrayFromObjectArray(staffTimesDoctor, 'default_value')));
    this.copeptinStore.setStaffTimesDoctor(staffTimesDoctor);
  }

  calculateStaffTimesNursing = () => {
    const staffTimesNursing = [];
    staffTimesNursing.push(this.calculateRow('copeptinResultStaffTimesPhysicalExamination', null,
      [this.copeptinStore.getStaffTimesNursingValue(1)], [this.copeptinStore.getStaffTimesNursingValue(1)]));
    staffTimesNursing.push(this.calculateRow('copeptinResultStaffTimesFirstECG', null,
      [this.copeptinStore.getStaffTimesNursingValue(2)], [this.copeptinStore.getStaffTimesNursingValue(2)]));
    staffTimesNursing.push(this.calculateRow('copeptinResultStaffTimesSecondECG', this.multiply,
      [this.copeptinStore.getStaffTimesNursingValue(5), this.copeptinStore.getPatientProportionCopeptinValue(4)],
      [this.copeptinStore.getStaffTimesNursingValue(5), this.copeptinStore.getPatientProportionDefaultValue(4)]));
    staffTimesNursing.push(this.calculateRow('copeptinResultStaffTimesFirstBloodSampling', null,
      [this.copeptinStore.getStaffTimesNursingValue(4)], [this.copeptinStore.getStaffTimesNursingValue(4)]));
    staffTimesNursing.push(this.calculateRow('copeptinResultStaffTimesThreeHourBloodSampling', this.multiply,
      [this.copeptinStore.getStaffTimesNursingValue(4), this.copeptinStore.getPatientProportionCopeptinValue(6)],
      [this.copeptinStore.getStaffTimesNursingValue(4), this.copeptinStore.getPatientProportionDefaultValue(6)]));
    staffTimesNursing.push(this.calculateRow('copeptinResultStaffTimesSixHourBloodSampling', this.multiply,
      [this.copeptinStore.getStaffTimesNursingValue(4), this.copeptinStore.getPatientProportionCopeptinValue(7)],
      [this.copeptinStore.getStaffTimesNursingValue(4), this.copeptinStore.getPatientProportionDefaultValue(7)]));
    staffTimesNursing.push(this.calculateRow('copeptinResultStaffTimesEcho', this.multiply,
      [this.copeptinStore.getStaffTimesNursingValue(6), this.copeptinStore.getPatientProportionCopeptinValue(10)],
      [this.copeptinStore.getStaffTimesNursingValue(6), this.copeptinStore.getPatientProportionDefaultValue(10)]));
    staffTimesNursing.push(this.calculateRow('copeptinResultStaffTimesMedicationDelivery', this.multiply,
      [this.copeptinStore.getStaffTimesNursingValue(12), this.copeptinStore.getPatientProportionCopeptinValue(11)],
      [this.copeptinStore.getStaffTimesNursingValue(12), this.copeptinStore.getPatientProportionDefaultValue(11)]));
    staffTimesNursing.push(this.calculateRow('copeptinResultStaffTimesTransferCPU', this.multiply,
      [this.copeptinStore.getStaffTimesNursingValue(8), this.copeptinStore.getPatientProportionCopeptinValue(12)],
      [this.copeptinStore.getStaffTimesNursingValue(8), this.copeptinStore.getPatientProportionDefaultValue(12)]));
    staffTimesNursing.push(this.calculateRow('copeptinResultStaffTimesTransferStation', this.multiplyDiffSum,
      [this.copeptinStore.getPatientProportionCopeptinValue(0), this.copeptinStore.getStaffTimesNursingValue(9), this.copeptinStore.getStaffTimesNursingValue(10)],
      [this.copeptinStore.getPatientProportionDefaultValue(0), this.copeptinStore.getStaffTimesNursingValue(9), this.copeptinStore.getStaffTimesNursingValue(10)]));
    staffTimesNursing.push(this.calculateRow('copeptinResultStaffTimesInitialAssessment', null,
      [this.copeptinStore.getStaffTimesNursingValue(0)], [this.copeptinStore.getStaffTimesNursingValue(0)]));
    staffTimesNursing.push(this.calculateRow('copeptinResultStaffTimesCommunication', null,
      [this.copeptinStore.getStaffTimesNursingValue(7)], [this.copeptinStore.getStaffTimesNursingValue(7)]));
    staffTimesNursing.push(this.calculateRow('copeptinResultStaffTimesDocumentation', this.multiply,
      [this.copeptinStore.getStaffTimesNursingValue(13), this.copeptinStore.getPatientProportionCopeptinValue(0)],
      [this.copeptinStore.getStaffTimesNursingValue(13), this.copeptinStore.getPatientProportionDefaultValue(0)]));
    staffTimesNursing.push(this.calculateRow('copeptinResultStaffTimesDischarge', this.multiplySum,
      [this.copeptinStore.getPatientProportionCopeptinValue(0), this.copeptinStore.getStaffTimesNursingValue(14), this.copeptinStore.getStaffTimesNursingValue(15)],
      [this.copeptinStore.getPatientProportionDefaultValue(0), this.copeptinStore.getStaffTimesNursingValue(14), this.copeptinStore.getStaffTimesNursingValue(15)]));
    staffTimesNursing.push(this.calculateRow('copeptinResultStaffTimesNursingSum', this.sum,
      this.getArrayFromObjectArray(staffTimesNursing, 'copeptin_value'),
      this.getArrayFromObjectArray(staffTimesNursing, 'default_value')));
    this.copeptinStore.setStaffTimesNursing(staffTimesNursing);
  }

  calculateStaffTimesTotal = () => {
    const staffTimesTotal = [];
    if (this.copeptinStore.staffTimesDoctor.length === this.copeptinStore.staffTimesNursing.length) {
      for (let i = 0; i < this.copeptinStore.staffTimesDoctor.length - 1; i++) {
        staffTimesTotal.push(this.calculateRow(this.copeptinStore.staffTimesDoctor[i].name, this.sum,
          [this.copeptinStore.staffTimesDoctor[i].copeptin_value, this.copeptinStore.staffTimesNursing[i].copeptin_value],
          [this.copeptinStore.staffTimesDoctor[i].default_value, this.copeptinStore.staffTimesNursing[i].default_value]));
      }
    }
    staffTimesTotal.push(this.calculateRow('copeptinResultStaffTimesTotalSum', this.sum,
      this.getArrayFromObjectArray(staffTimesTotal, 'copeptin_value'),
      this.getArrayFromObjectArray(staffTimesTotal, 'default_value')));
    this.copeptinStore.setStaffTimesTotal(staffTimesTotal);
  }

  calculateAcuteSettingCosts = () => {
    const acuteSettingCosts = [];
    acuteSettingCosts.push(this.calculateRow('copeptinResultCostsPhysicalExamination', null,
      [this.copeptinStore.getMedicalCheckCostsCostsValue(0)], [this.copeptinStore.getMedicalCheckCostsCostsValue(0)]));
    acuteSettingCosts.push(this.calculateRow('copeptinResultCostsFirstECG', null,
      [this.copeptinStore.getMedicalCheckCostsCostsValue(1)], [this.copeptinStore.getMedicalCheckCostsCostsValue(1)]));
    acuteSettingCosts.push(this.calculateRow('copeptinResultCostsSecondECG', this.multiply,
      [this.copeptinStore.getMedicalCheckCostsCostsValue(1), this.copeptinStore.getPatientProportionCopeptinValue(4)],
      [this.copeptinStore.getMedicalCheckCostsCostsValue(1), this.copeptinStore.getPatientProportionDefaultValue(4)]));
    acuteSettingCosts.push(this.calculateRow('copeptinResultCostsFirstBloodSampling', null,
      [this.copeptinStore.getMedicalCheckCostsCostsValue(2)], [this.copeptinStore.getMedicalCheckCostsCostsValue(2)]));
    acuteSettingCosts.push(this.calculateRow('copeptinResultCostsThreeHourBloodSampling', this.multiply,
      [this.copeptinStore.getMedicalCheckCostsCostsValue(2), this.copeptinStore.getPatientProportionCopeptinValue(6)],
      [this.copeptinStore.getMedicalCheckCostsCostsValue(2), this.copeptinStore.getPatientProportionDefaultValue(6)]));
    acuteSettingCosts.push(this.calculateRow('copeptinResultCostsSixHourBloodSampling', this.multiply,
      [this.copeptinStore.getMedicalCheckCostsCostsValue(2), this.copeptinStore.getPatientProportionCopeptinValue(7)],
      [this.copeptinStore.getMedicalCheckCostsCostsValue(2), this.copeptinStore.getPatientProportionDefaultValue(7)]));
    acuteSettingCosts.push(this.calculateRow('copeptinResultCostsTroponin', this.multiply,
      [this.copeptinStore.getMedicalCheckCostsCostsValue(3), this.sum([
        this.copeptinStore.getPatientProportionCopeptinValue(5),
        this.copeptinStore.getPatientProportionCopeptinValue(6),
        this.copeptinStore.getPatientProportionCopeptinValue(7)
      ])], [this.copeptinStore.getMedicalCheckCostsCostsValue(3), this.sum([
        this.copeptinStore.getPatientProportionDefaultValue(5),
        this.copeptinStore.getPatientProportionDefaultValue(6),
        this.copeptinStore.getPatientProportionDefaultValue(7)
      ])]));
    acuteSettingCosts.push(this.calculateRow('copeptinResultCostsCopeptin', null, [this.copeptinStore.getMedicalCheckCostsCostsValue(4)], [0]));
    acuteSettingCosts.push(this.calculateRow('copeptinResultCostsEcho', this.multiply,
      [this.copeptinStore.getMedicalCheckCostsCostsValue(5), this.copeptinStore.getPatientProportionCopeptinValue(10)],
      [this.copeptinStore.getMedicalCheckCostsCostsValue(5), this.copeptinStore.getPatientProportionDefaultValue(10)]));
    acuteSettingCosts.push(this.calculateRow('copeptinResultCostsTotalCheck', this.sum,
      this.getArrayFromObjectArray(acuteSettingCosts, 'copeptin_value'),
      this.getArrayFromObjectArray(acuteSettingCosts, 'default_value')));
    acuteSettingCosts.push(this.calculateRow('copeptinResultCostsMedication', this.multiply,
      [this.copeptinStore.getPatientProportionCopeptinValue(11), this.copeptinStore.getMedicationCostsCostsValue(0)],
      [this.copeptinStore.getPatientProportionDefaultValue(11), this.copeptinStore.getMedicationCostsCostsValue(1)]));
    acuteSettingCosts.push(this.calculateRow('copeptinResultCostsTotal', this.sum,
      this.removeIndexFromArray(this.getArrayFromObjectArray(acuteSettingCosts, 'copeptin_value'), 9),
      this.removeIndexFromArray(this.getArrayFromObjectArray(acuteSettingCosts, 'default_value'), 9)));
    this.copeptinStore.setAcuteSettingCosts(acuteSettingCosts);
  }

  calculateAcuteSettingCostsAllPatients = () => {
    const acuteSettingCostsAllPatients = [];
    acuteSettingCostsAllPatients.push(this.calculateRow('copeptinResultTotalCostsCosts', this.multiply,
      [this.copeptinStore.acuteSettingCosts[this.copeptinStore.acuteSettingCosts.length - 1].copeptin_value,
        this.copeptinStore.getTotalNumberPatientsTotalValue(0) * this.copeptinStore.getTotalNumberPatientsPercentageValue(0)],
      [this.copeptinStore.acuteSettingCosts[this.copeptinStore.acuteSettingCosts.length - 1].default_value,
        this.copeptinStore.getTotalNumberPatientsTotalValue(0) * this.copeptinStore.getTotalNumberPatientsPercentageValue(0)], true));
    this.copeptinStore.setAcuteSettingCostsAllPatients(acuteSettingCostsAllPatients);
  }

  calculateRow = (rowName, calcFunction, inputArray1, inputArray2, hasPercentageValue) => {
    let copeptinValue;
    let defaultValue;
    let diffValue;
    let percentageValue;
    if (inputArray1 && inputArray2) {
      if (calcFunction) {
        copeptinValue = calcFunction(inputArray1);
        defaultValue = calcFunction(inputArray2);
      } else if (inputArray1.length === 1) {
        copeptinValue = inputArray1[0];
        defaultValue = inputArray2[0];
      } else {
        return undefined;
      }
      diffValue = this.diff([copeptinValue, defaultValue]);
      if (hasPercentageValue) {
        percentageValue = this.divide([-diffValue, defaultValue]);
      }
    }
    return { name: rowName, copeptin_value: copeptinValue, default_value: defaultValue, diff_value: diffValue, percentage_value: percentageValue };
  }

  diff = (array) => {
    let result;
    if (array && array.length === 2) {
      result = array[0] - array[1];
    }
    return result;
  }

  multiply = (array) => {
    let result;
    if (array && array.length === 2) {
      result = array[0] * array[1];
    }
    return result;
  }

  multiplyDiff = (array) => {
    let result;
    if (array && array.length === 2) {
      result = array[0] * (1 - array[1]);
    }
    return result;
  }

  multiplyDiffSum = (array) => {
    let result;
    if (array && array.length === 3) {
      result = (1 - array[0]) * (array[1] + array[2]);
    }
    return result;
  }

  multiplySum = (array) => {
    let result;
    if (array && array.length === 3) {
      result = array[0] * (array[1] + array[2]);
    }
    return result;
  }

  divide = (array) => {
    let result;
    if (array && array.length === 2) {
      result = array[0] / array[1];
    }
    return result;
  }

  getArrayFromObjectArray = (objectArray, key) => {
    const resultArray = [];
    if (objectArray) {
      objectArray.map((object) => {
        resultArray.push(object[key]);
      });
    }
    return resultArray;
  }

  sum = (array) => {
    let result = 0;
    if (array) {
      array.map((item) => {
        result += item;
      });
    }
    return result;
  }

  removeIndexFromArray = (inputArray, index) => {
    const resultArray = inputArray.slice();
    if (index + 1 <= inputArray.length) {
      resultArray.splice(index, 1);
    }
    return resultArray;
  }

  fixFloat = value => Number.parseFloat(value).toFixed(2);
}

export function calculateChartingMaximum(maximum, minMaxLineValue) {
  const value = 50 * Math.ceil(maximum / 50);
  if (minMaxLineValue && minMaxLineValue > value) {
    return minMaxLineValue;
  }
  return value;
}

