const copeptinConstants = {
  DefaultLanguageCode: 'en',
  LanguageCodeEnglish: 'en',
  LanguageCodeGerman: 'de',
  LanguageCodeFrench: 'fr',
  LanguageCodeSpanish: 'es',
  LanguageCodeUSA: 'us',
  CopeptinTableRowCurrency: 'currency',
  CopeptinTableRowPercentage: 'percentage',
  CopeptinTableRowTime: 'time',
  CopeptinPopulationField: 'firstMenuField',
  CopeptinStaffTimesDoctorValue: 'CopeptinStaffTimesDoctorValue',
  CopeptinStaffTimesNursingValue: 'CopeptinStaffTimesNursingValue',
  CopeptinMedicalCheckCostsCostsValue: 'CopeptinMedicalCheckCostsCostsValue',
  CopeptinMedicationCostsCopeptinValue: 'CopeptinMedicationCostsCopeptinValue',
  CopeptinMedicationCostsDefaultValue: 'CopeptinMedicationCostsDefaultValue',
  CopeptinPatientProportionCopeptinValue: 'CopeptinPatientProportionCopeptinValue',
  CopeptinPatientProportionDefaultValue: 'CopeptinPatientProportionDefaultValue',
  CopeptinTotalNumberPatientsTotalValue: 'CopeptinTotalNumberPatientsTotalValue',
  CopeptinTotalNumberPatientsPercentageValue: 'CopeptinTotalNumberPatientsPercentageValue'
};

export default copeptinConstants;
