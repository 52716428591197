import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import BaseOverlay from 'web/components/_ui/form-elements/base-overlay';
import { withStyles } from '@material-ui/core/styles';
import { simappColors } from 'shared/lib/theme';
import { inject, observer } from 'mobx-react';
import { AssayItem } from 'shared/stores/kryptor-store';
import KryptorAssay from './kryptor-assay';

const colors = simappColors;

const styles = {
  headline: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'hidden',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    color: colors.steelGrey,
    marginBottom: '8px',
    fontSize: '10px',
    fontWeight: '500',
    letterSpacing: '0.91px',
    lineHeight: '22px',
  },
  assay: {
    marginBottom: '16px',
    marginRight: '-56px'
  },
};

@inject('consumablesCalculatorStore', 'kryptorStore') @observer
class KryptorAssays extends Component {
  brahms = undefined;
  kryptor = undefined;

  constructor(props) {
    super(props);
    this.initializeLists(props);
    this.setValidState();
    this.state = { assays: props.kryptorStore.kryptorAssayList, overlayRequestFrom: -1 };
  }

  // eslint-disable-next-line
  UNSAFE_componentWillUpdate() {
    this.props.inputSelectionHelper.unregisterFollowing(this.props.inputSelectionStartIndex);
  }

  getAssay = (name) => {
    for (let i = 0; i < KryptorAssays.assays.length; i++) {
      if (KryptorAssays.assays[i].id === name) {
        return KryptorAssays.assays[i];
      }
    }
    return undefined;
  }

  getAssayName(assayTitle) {
    if (this.brahms === undefined) {
      const splitTitle = assayTitle.split(' ');
      this.brahms = splitTitle[0];
      this.kryptor = splitTitle[splitTitle.length - 1];
    }
    return assayTitle.replace(this.brahms, '').replace(this.kryptor, '').trim();
  }

  setValidState() {
    const assays = this.props.kryptorStore.kryptorAssayList;
    let isValid = assays.length > 0; // at least one item added
    const keys = Object.keys(assays);
    keys.map((assayKey, index) => {
      const assay = assays[index];
      isValid = isValid && assay.addedTests !== undefined && assay.patientsPerCoverTime !== undefined;
      isValid = isValid && (assay.multiControlIds.length > 0 || (assay.volumePerAliquot !== undefined && assay.aliquotCoverPerInstrument !== undefined));
    });
    this.props.setValidState(isValid);
  }

  static assays;

  initializeLists(props) {
    let index;
    if (KryptorAssays.assays !== undefined) {
      return;
    }
    KryptorAssays.assays = [];
    for (index = 0; index < props.consumablesCalculatorStore.assays.length; index++) {
      const assayRaw = this.props.consumablesCalculatorStore.assays[index];
      const assayName = this.getAssayName(assayRaw.title);
      const assay = {
        id: assayName,
        assayId: assayRaw.id,
        value: assayName,
        isMultiControl: assayRaw.multi_control_ids.length > 0,
        canRunRecoveryTest: assayRaw.recovery_test_enabled,
        multiControlIds: assayRaw.multi_control_ids
      };
      KryptorAssays.assays.push(assay);
    }
  }

  update() {
    this.setValidState();
    this.setState(prevState => ({ assays: this.props.kryptorStore.kryptorAssayList, overlayRequestFrom: prevState.overlayRequestFrom }));
  }

  overlayRequest(assayIndex) {
    this.setState(prevState => ({ assays: prevState.assays, overlayRequestFrom: assayIndex }));
  }

  render() {
    let index = 0;
    let selectableInputFieldStartIndex = 0;
    let lastInputFieldIndex = this.props.inputSelectionStartIndex;
    return (
      <div key={this.state.assays.length + this.state.overlayRequestFrom}>
        <Typography variant="body1" className={this.props.classes.headline}>{this.props.kryptorStore.getText('KryptorAssays')}</Typography>
        {this.state.assays.map((assay) => {
          selectableInputFieldStartIndex = lastInputFieldIndex;
          // calculate index for next field
          lastInputFieldIndex = assay.showMultiControl ? lastInputFieldIndex + 2 : lastInputFieldIndex + 4; // 2 or 4 selectable inputs per assay
          return (
            <div key={index} className={this.props.classes.assay}>
              <KryptorAssay
                key={index}
                inputSelectionStartIndex={selectableInputFieldStartIndex}
                id={index++}
                update={() => this.update()}
                requestOverlay={assayIndex => this.overlayRequest(assayIndex)}
                inputSelectionHelper={this.props.inputSelectionHelper}
              />
            </div>
          );
        })}
        <div>
          <KryptorAssay
            id={index}
            update={() => this.update()}
            requestOverlay={assayIndex => this.overlayRequest(assayIndex)}
            inputSelectionHelper={{}}
            inputSelectionStartIndex={-1}
          />
        </div>
        <BaseOverlay
          open={this.state.overlayRequestFrom !== -1}
          onClose={() => {
            this.setState(prevState => ({ assays: prevState.assays, overlayRequestFrom: -1 }));
          }}
          onSelect={(name) => {
            const itemAdded = this.state.overlayRequestFrom === this.state.assays.length;
            const assay = this.getAssay(name);
            const newAssay = new AssayItem();
            newAssay.name = name;
            newAssay.id = assay.assayId;
            newAssay.assay = this.props.kryptorStore.getAssay(assay.assayId);
            newAssay.showMultiControl = assay.isMultiControl;
            newAssay.showRunRecoveryTest = assay.canRunRecoveryTest;
            newAssay.multiControlIds = assay.multiControlIds;
            newAssay.multiControlId = newAssay.multiControlIds.length > 0 ? newAssay.multiControlIds[0] : undefined;
            if (itemAdded) {
              this.props.kryptorStore.addAssayToList(newAssay);
            } else {
              this.props.kryptorStore.updateListAssay(newAssay, this.state.overlayRequestFrom);
            }
            this.setValidState();
            this.setState({ assays: this.props.kryptorStore.kryptorAssayList, overlayRequestFrom: -1 });
          }}
          currentSelect={this.state.overlayRequestFrom >= 0 && this.state.overlayRequestFrom < this.state.assays.length ? this.state.assays[this.state.overlayRequestFrom].name : ''}
          labelPrefix={this.props.kryptorStore.getText('KryptorChooseAssay').split(' ')[0]}
          label={this.props.kryptorStore.getText('KryptorChooseAssay').split(' ')[1]}
          list={KryptorAssays.assays}
        />
      </div>
    );
  }
}

KryptorAssays.wrappedComponent.propTypes = {
  consumablesCalculatorStore: PropTypes.object.isRequired,
  kryptorStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  setValidState: PropTypes.func.isRequired,
  inputSelectionHelper: PropTypes.object.isRequired,
  inputSelectionStartIndex: PropTypes.number.isRequired
};

export default withStyles(styles)(KryptorAssays);
