import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withDatabase } from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import { observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import { newsStore } from 'shared/stores';
import NewsItem from '../news-item';
import { headlineRow, section } from '../headline';

const newsLimit = 2;
const styles = {
  headlineRow,
  section,
  newsWrapper: {
    overflowY: 'auto',
    maxHeight: 'calc(100vH - 90px)',
    margin: '0 8px'
  },
  onlyNews: {
    marginBottom: 0
  },
  defaultView: {
    height: 190,
  }
};

@observer
class NewsSection extends Component {
  sortNews = news => news.sort((a, b) => ((a.uid < b.uid) ? 1 : -1));

  dashboardNews() {
    return this.sortNews(this.props.news).slice(0, newsLimit);
  }

  allNews() {
    return this.sortNews(this.props.news).slice(newsLimit);
  }

  render() {
    const { classes } = this.props;
    const unreadNewsCount = this.props.news.filter(n => n.read === false).length;
    return (
      <div className={`${classes.section} ${newsStore.onlyNews ? classes.onlyNews : classes.defaultView}`}>
        <div className={classes.headlineRow}>
          <Typography variant="h5">News Feed {unreadNewsCount
            ? <span>({unreadNewsCount} new)</span> : ''}
          </Typography>
          <Button
            color="primary"
            onClick={newsStore.onlyNews ? newsStore.backToDashboard : newsStore.openOnlyNews}
          >
            {newsStore.onlyNews ? 'Back to Dashboard' : 'View all News'}
          </Button>
        </div>
        <div className={classes.newsWrapper}>
          {this.dashboardNews().map(news => (
            <NewsItem
              news={news}
              key={news.id}
              currentId={newsStore.currentId}
            />
          ))}
          <Collapse in={newsStore.onlyNews}>
            {this.allNews().map(news => (
              <NewsItem
                news={news}
                key={news.id}
                currentId={newsStore.currentId}
              />
            ))}
          </Collapse>
        </div>
      </div>
    );
  }
}

NewsSection.propTypes = {
  classes: PropTypes.object.isRequired,
  news: PropTypes.array.isRequired
};

export default withDatabase(withObservables([], ({ database }) => ({
  news: database.collections.get('news').query().observeWithColumns(['read']),
}))(withStyles(styles)(NewsSection)));
