import { observable, runInAction, action, computed } from 'mobx';
import { persist } from 'mobx-persist';
import rest from 'shared/lib/rest';
import { merge } from 'shared/lib/helpers';
import createStorage from './create-storage';

class ProductStore {
  constructor() {
    createStorage('productStore', this).then(() => {
    });
  }

  api = {
    product: '/product',
    productGroup: '/product_group',
    productSubgroup: '/product_subgroup',
  };

  @persist('list') @observable products = [];
  // @persist('list') @observable productsUnique = [];
  @persist('list') @observable productGroups = [];
  @persist('list') @observable productSubgroups = [];

  @observable filteredProductIds = [];
  @observable filteredProductIdsFromGroups = []; // holds all product Ids that are in selected product groups
  @observable filteredProductGroupIds = [];
  @observable filteredProductSubgroupIds = [];
  @observable disabledProducts = {};
  @observable disabledProductGroups = {};
  @observable disabledProductSubgroups = {};

  loadData(forceReload = false, lastUpdated = null) {
    const startTime = new Date().getTime();
    return new Promise((resolve) => {
      let params = '?';
      if (this.hasDataLoaded && !forceReload && lastUpdated !== null) {
        params += `since=${lastUpdated}`;
      } else if (forceReload) {
        runInAction(() => {
          this.products = [];
          this.productGroups = [];
          this.productSubgroups = [];
        });
      }

      Promise
        .all([rest.get(this.api.product + params), rest.get(this.api.productGroup + params), rest.get(this.api.productSubgroup + params)])
        .then((response) => {
          runInAction(() => {
            if (response[0].data.length > 0 || response[1].data.length > 0 || response[2].data.length > 0) {
              merge(this.products, response[0].data, 'id', 'name');
              merge(this.productGroups, response[1].data, 'id', 'name');
              merge(this.productSubgroups, response[2].data, 'id', 'name');
              this.resetFilters();
            }
            console.log(`${new Date().getTime() - startTime}ms - ${this.constructor.name}`);
            resolve(true);
          });
        }).catch((error) => {
          console.log('Could not load products', error.response.data);
          resolve();
        });
    });
  }

  get hasDataLoaded() {
    return !!this.products.length;
  }

  getProductName(id) {
    const foundProduct = this.products.find(product => product.id === id);
    return typeof foundProduct !== 'undefined' ? foundProduct.name : '';
  }

  getProductIds(name) {
    return this.products.filter(product => (product.name === name)).map(product => (product.id));
  }

  getProductSubgroupName(id) {
    const foundProductSubgroup = this.productSubgroups.find(psg => psg.id === id);
    return typeof foundProductSubgroup !== 'undefined' ? foundProductSubgroup.name : '';
  }

  getProductGroupName(id) {
    const foundProductGroup = this.productGroups.find(pg => pg.id === id);
    return typeof foundProductGroup !== 'undefined' ? foundProductGroup.name : '';
  }

  @computed get filteredProductsForUI() {
    return this.filteredProductIds.length === this.products.length ? [] : this.filteredProductIds;
  }

  @action setFilter(filteredProductIds) {
    if (filteredProductIds.length === 0) {
      this.filteredProductIds = this.products.map(product => product.id);
    } else {
      let filteredProductIdCache = [];
      /**
       * the material Select Components returns all selected productIds
       * If a productId is unselected, it's siblings with identical name are still selected
       * thus, we have to find and remove them from the result manually
       */
      let removedIds = [];
      if (this.filteredProductIds.length !== this.products.length) {
        this.filteredProductIds.filter(x => !filteredProductIds.includes(x)).forEach((removeId) => {
          removedIds = [...removedIds, ...this.getProductIds(this.getProductName(removeId))];
        });
      }
      filteredProductIds.forEach((productId) => {
        filteredProductIdCache = [...filteredProductIdCache, ...this.getProductIds(this.getProductName(productId))];
      });
      this.filteredProductIds = [...new Set(filteredProductIdCache)].filter(productId => !removedIds.includes(productId));
      if (this.filteredProductIds.length === 0) {
        this.filteredProductIds = this.products.map(product => product.id);
      }
    }
  }

  /**
   *
   * @param {int} productId
   * @param {bool} allbyName if true, will toggle all IDs with the name of productId
   */
  @action toggleFilter(productId, allbyName = false) {
    const idList = allbyName ? this.getProductIds(this.getProductName(productId)) : [productId];
    if (this.filteredProductsForUI.length > 0) {
      idList.forEach((id) => {
        const index = this.filteredProductIds.indexOf(id);
        if (index > -1) {
          this.filteredProductIds.splice(index, 1);
          if (this.filteredProductIds.length === 0) {
            this.filteredProductIds = this.products.map(product => product.id);
          }
        } else {
          this.filteredProductIds.push(id);
        }
      });
    } else {
      this.filteredProductIds = idList;
    }
  }

  /**
   *
   * @param {int} productGroupId
   *
   * This method is called when a product group is selected/deselected in the searchbar filter.
   * Toggles the status of the selected group.
   * Also toggles the checkboxes of the corresponding subgroup.
   */
  @action toggleGroupFilter(productGroupId) {
    const sameGroupSubgroupIds = this.productSubgroups
      .filter(subgroup => subgroup.product_group_id === productGroupId)
      .map(subgroup => subgroup.id);

    const index = this.filteredProductGroupIds.indexOf(productGroupId);
    if (index > -1) {
      // if deselected:
      this.filteredProductGroupIds.splice(index, 1);
      sameGroupSubgroupIds.forEach(subgroupId => this.filteredProductSubgroupIds.remove(subgroupId));
    } else {
      // if selected:
      this.filteredProductGroupIds.push(productGroupId);
    }
    // deselect any matching subgroups
    sameGroupSubgroupIds.forEach(subgroupId => this.filteredProductSubgroupIds.remove(subgroupId));
    this.rebuildFilteredProductIdsFromGroups();
  }

  /**
   *
   * @param {int} productSubgroupId
   *
   * This method is called when a product subgroup is selected/deselected in the searchbar filter.
   * Toggles the status of the selected subgroup.
   * Also toggles the checkboxes of the parent group if needed.
   */
  @action toggleSubgroupFilter(productSubgroupId) {
    const productSubgroup = this.productSubgroups.find(subgroup => subgroup.id === productSubgroupId);
    const index = this.filteredProductSubgroupIds.indexOf(productSubgroupId);
    if (index > -1) {
      // if deselected:
      this.filteredProductSubgroupIds.splice(index, 1);
    } else {
      // if selected:
      this.filteredProductSubgroupIds.push(productSubgroupId);
      // delesect parent product group
      this.filteredProductGroupIds.remove(productSubgroup.product_group_id);
    }
    this.rebuildFilteredProductIdsFromGroups();
  }

  /**
   * rebuilds 'filteredProductIdsFromGroups' based on selected product groups and product subgroups
   */
  @action rebuildFilteredProductIdsFromGroups() {
    if (this.filteredProductGroupIds.length === 0 && this.filteredProductSubgroupIds.length === 0) {
      this.filteredProductIdsFromGroups = this.products.map(product => product.id);
    } else {
      // rebuild list
      const productIds = [];
      this.filteredProductGroupIds.forEach((pgId) => {
        this.products.forEach((p) => {
          if (p.product_group_id === pgId && productIds.indexOf(p.id) < 0) productIds.push(p.id);
        });
      });
      this.filteredProductSubgroupIds.forEach((pgId) => {
        this.products.forEach((p) => {
          if (p.product_subgroup_ids.includes(pgId) && productIds.indexOf(p.id) < 0) productIds.push(p.id);
        });
      });
      this.filteredProductIdsFromGroups = productIds;
    }
  }

  @action resetFilters = () => {
    if (this.filteredProductIds.length !== this.products.length) {
      this.filteredProductIds = this.products.map(product => product.id);
    }
    if (this.filteredProductIdsFromGroups.length !== this.products.length) {
      this.filteredProductIdsFromGroups = this.products.map(product => product.id);
    }
    if (this.filteredProductGroupIds.length > 0) {
      this.filteredProductGroupIds = [];
    }
    if (this.filteredProductSubgroupIds.length > 0) {
      this.filteredProductSubgroupIds = [];
    }
  };

  // unique by name (and alphabetically sorted)
  @action buildProductsUnique() {
    const unique = [];
    this.products.forEach((product) => {
      const index = unique.findIndex(el => el.name === product.name);
      if (index > -1) {
        unique[index].ids.push(product.id);
      } else {
        unique.push({
          ids: [product.id],
          name: product.name
        });
      }
    });
    this.productsUnique = unique;
  }

  @computed get productsUnique() {
    return this.products.filter((value, index, self) => self.map(mapObj => mapObj.name).indexOf(value.name) === index);
    // .sort((a, b) => {
    //   if (a.name < b.name) { return -1; }
    //   if (a.name > b.name) { return 1; }
    //   return 0;
    // });
  }

  /**
   * returns all product subgroups that are currently selected, but their group is not
   */
  @computed get productSubGroupsIndependent() {
    return this.productSubgroups.filter(subgroup => this.filteredProductSubgroupIds.includes(subgroup.id) && !this.filteredProductGroupIds.includes(subgroup.product_group_id));
  }
}

export default new ProductStore();
