import moment from 'moment';
import QcPdfTemplate from './qc-pdf-template';
import QcPdfTable, { TextAlign } from './qc-pdf-table';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/es';

const mmToPixelMultiplicator = 2.83;
const a4Height = 297 * mmToPixelMultiplicator;
const a4Width = 210 * mmToPixelMultiplicator;
const headerLabelToTextGap = 6;
const signatureLineLength = 200;
const signatureLineGap = 50;
const dateLineLength = 100;

export default class QCTemplateUsaLoc extends QcPdfTemplate {
  date = new Date();
  address;
  language;
  constructor(qcStore, appUser, address, isNative, language) {
    super(a4Width, a4Height, qcStore, appUser, isNative);
    this.address = address;
    this.language = language;
  }

  getCurrentDateString() {
    const date = new Date();
    moment.locale(this.language);
    return moment(date).format('LL');
  }

  addHeader() {
    this.addGeneralHeader();
    this.addCustomerInfoHeader();
    this.currentY = this.getIncreasedY(this.headerFontSize * 2);
  }

  addNewPageHeader() {
    this.currentY = a4Height - (this.outerMargin / 2);
    const logoWidth = Math.ceil((a4Width - this.outerMargin * 2) / 6);
    const logoHeight = Math.ceil((logoWidth / this.images.logo.dimensions.width) * this.images.logo.dimensions.height);
    this.drawImage('logo', this.outerMargin * 1.5, this.currentY - logoHeight, logoWidth, logoHeight);
    this.currentY = this.getIncreasedY(logoHeight + 15);
  }

  addGeneralHeader() {
    this.currentFontSize = this.headerFontSize;
    this.currentFont = this.boldFont;
    this.addThermoFisherHeader(true);
    this.currentFontSize = this.fontSizeTitle;
    this.currentFont = this.boldFont;
    this.drawTextToPdf(this.qcStore.getPdfData('QcPdfTitle-US-LOC', this.language), this.outerMargin, this.currentY);
  }

  addThermoFisherHeader(isPdfHeader) {
    this.currentY = a4Height - (this.outerMargin + 5);
    const logoWidth = Math.ceil((a4Width - this.outerMargin * 2) / 3);
    const logoHeight = Math.ceil((logoWidth / this.images.logo.dimensions.width) * this.images.logo.dimensions.height);
    this.drawImage('logo', this.outerMargin - 14, this.currentY - logoHeight + 10, logoWidth, logoHeight);
    // const addressLineHeight = this.currentFont[1].heightOfFontAtSize(this.currentFontSize) + 2;
    this.currentY = this.currentY; // + addressLineHeight;

    const addrParts = this.address.address.match(/[^\r\n]+/g);
    const posX = 3 * (this.width / 5);
    let count = 0;
    addrParts.forEach((addr) => {
      this.currentFont = count === 0 ? this.boldFont : this.lightFont;
      this.drawTextToPdf(addr, posX, this.currentY, undefined, true);
      this.currentY = this.getIncreasedY(this.currentFontSize);
      count++;
    });
    // add extra lines if header address is short
    if (count < 5) {
      this.currentY = this.getIncreasedY(this.currentFontSize * (6 - count));
    }
    this.drawLineToPdf(this.outerMargin, this.currentY, this.width - this.outerMargin * 2);
    this.currentY -= (isPdfHeader ? 10 : 15) * mmToPixelMultiplicator;
  }

  addCustomerInfoHeader() {
    // add space to customer info
    this.currentY = this.getIncreasedY(this.currentFontSize * 2);
    this.currentFontSize = this.headerFontSize;
    this.currentFont = this.lightFont;
    const dateString = this.getCurrentDateString();
    const country = this.qcStore.customerData.country ? this.qcStore.getCountryObject(this.qcStore.customerData.country) : this.qcStore.getCountryObject('US');
    const durationString = `${this.qcStore.customerData.contractPeriodInMonths} ${this.qcStore.getPdfData(this.qcStore.customerData.contractPeriodInMonths > 1 ? 'QcContractDurationUnitPlural' : 'QcContractDurationUnitSingular', this.language)}`;
    // eslint-disable-next-line no-unused-vars
    let addressRowCount = this.addCustomerAddressRow(this.qcStore.getPdfData('QcCustomerContact', this.language), this.qcStore.customerData.contactName, 0);
    addressRowCount = this.addCustomerAddressRow(this.qcStore.getPdfData('QcCustomerCompanyName', this.language), this.qcStore.customerData.customerName, addressRowCount);
    addressRowCount = this.addCustomerAddressRow(this.qcStore.getPdfData('QcCustomerLocation', this.language), country.name, addressRowCount);
    addressRowCount = this.addCustomerAddressRow(this.qcStore.getPdfData('QcProposalDate', this.language), dateString, addressRowCount);
    addressRowCount = this.addCustomerAddressRow(this.qcStore.getPdfData('QcCustomerContractDuration', this.language), durationString, addressRowCount);
    this.currentFont = this.lightFont;
  }

  addCustomerAddressRow(rowTitle, addressPart, addressRowCount, titleHidden) {
    this.currentFont = this.boldFont;
    const yPos = this.getIncreasedY(this.headerFontSize);
    if (!titleHidden) {
      this.drawTextToPdf(rowTitle, this.outerMargin, yPos);
    }
    if (addressPart !== undefined && addressPart.length > 0 && addressPart !== null) {
      const xPos = this.outerMargin + this.measureText(rowTitle) + headerLabelToTextGap;
      this.currentFont = this.lightFont;
      this.drawTextToPdf(addressPart, xPos, yPos);
    } else {
      console.error(`PDF generation: undefined or wrong data for '${rowTitle}'`);
    }
    return addressRowCount + 1;
  }

  addContent() {
    this.currentFont = this.lightFont;
    this.createIntroductionText();
    this.currentY = this.getIncreasedY(this.headerFontSize * 2);
    this.createProductTable();
    this.currentFontSize = this.headerFontSize;
    this.currentFont = this.lightFont;
    if (!this.pageHasEnoughSpaceFor(this.headerFontSize, 20)) {
      this.addPageBreak();
    }
    this.currentY = this.getIncreasedY(this.headerFontSize * 2);
    this.createConditionsAndRegardsText();
    this.currentY = this.getIncreasedY(this.headerFontSize * 4);
    this.createSignatureLineSalesRep();
    this.currentY = this.getIncreasedY(this.headerFontSize * 4);
    this.createSignatureLineCustomer();
    this.createAdditionalInformationText();
    this.createTermsAndConditionsText();
  }

  createIntroductionText() {
    const staticText1 = this.qcStore.getPdfData('QcProposalFirstParagraph', this.language);
    const staticText2 = this.qcStore.getPdfData('QcProposalSecondParagraph', this.language);
    const email = this.appUser.get('email') !== undefined ? `${this.appUser.get('email')}` : '';
    this.drawTextToPdf(`${staticText1} ${email}. ${staticText2}`, this.outerMargin, this.currentY, 0);
  }

  createProductTable() {
    const col1 = this.qcStore.getPdfData('QcProposalCol1', this.language);
    const col2 = this.qcStore.getPdfData('QcProposalCol2', this.language);
    const col3 = this.qcStore.getPdfData('QcProposalCol3', this.language);
    const col4 = this.qcStore.getPdfData('QcProposalCol4', this.language);
    const col5 = this.qcStore.getPdfData('QcProposalCol5', this.language);
    const col6 = this.qcStore.getPdfData('QcProposalCol6', this.language);
    const col7 = this.qcStore.getPdfData('QcProposalCol7', this.language);
    const tableData = [
      { content: col1, colSize: 1, align: TextAlign.Left, isCurrencyValue: false },
      { content: col2, colSize: 3, align: TextAlign.Left, isCurrencyValue: false },
      { content: col3, colSize: 1, align: TextAlign.Left, isCurrencyValue: false },
      { content: col4, colSize: 1, align: TextAlign.Right, isCurrencyValue: true },
      { content: col5, colSize: 1, align: TextAlign.Right, isCurrencyValue: true },
      { content: col6, colSize: 1, align: TextAlign.Right, isCurrencyValue: false },
      { content: col7, colSize: 1, align: TextAlign.Right, isCurrencyValue: true },
    ];
    const table = new QcPdfTable(tableData, this, this.lightFont, this.boldFont, this.lightArialFont, this.tableFontSize, this.qcStore);
    const data = [];
    const products = this.qcStore.productList;
    let totalCost = 0.0;
    products.map((product) => {
      totalCost += product.totalPrice;
      data.push([
        { content: `${product.product.partNumber}` },
        { content: product.product.name },
        { content: `${product.product.kitSize} ${this.qcStore.getPdfData('QcKitMultiplier', this.language)} ${this.getVolumePerBottle(product)} ${this.qcStore.getPdfData('QcKitUnit', this.language)}` },
        { content: `${product.pricePerUnit}` },
        { content: `${product.discountedPricePerUnit}` },
        { content: `${product.quantity}` },
        { content: `${product.totalPrice}` }
      ]);
    });
    const totalCostData = [
      { content: undefined },
      { content: undefined },
      { content: undefined },
      { content: undefined },
      { content: undefined },
      { content: `${this.qcStore.getPdfData('QcProposalCol7', this.language)}:` },
      { content: `${totalCost}` }
    ];
    table.create(data, totalCostData);
  }

  getVolumePerBottle = (product) => {
    const value = product.product.kitVolume / product.product.kitSize;
    return String(value).replace('.', this.qcStore.getDecimalSeparator());
  }

  createAdditionalInformationText() {
    if (this.address.additionalInformation) {
      const infoParts = this.address.additionalInformation.match(/[^\r\n]+/g);
      if (!this.pageHasEnoughSpaceFor(this.headerFontSize, infoParts.length + 2)) {
        this.addPageBreak();
        this.currentY = this.getIncreasedY(this.headerFontSize);
      } else {
        this.currentY = this.getIncreasedY(this.headerFontSize * 2);
      }
      this.currentFontSize = this.headerFontSize;
      this.currentFont = this.boldFont;
      this.drawTextToPdf(this.qcStore.getPdfData('QcProposalAdditionalInformation', this.language), this.outerMargin, this.currentY, 0);
      this.currentFontSize = this.headerFontSize;
      this.currentFont = this.lightFont;
      infoParts.forEach((info) => {
        this.currentY = this.getIncreasedY(this.currentFontSize);
        this.drawTextToPdf(info, this.outerMargin, this.currentY, 0);
      });
    }
  }

  createTermsAndConditionsText() {
    if (this.address.termsAndConditions) {
      this.currentY = this.getIncreasedY(this.headerFontSize * 2);
      this.currentFontSize = this.headerFontSize;
      this.currentFont = this.boldFont;
      this.drawTextToPdf(this.qcStore.getPdfData('QcProposalTermsAndConditions', this.language), this.outerMargin, this.currentY, 0);
      this.currentFontSize = this.termsAndConditionsFontSize;
      this.currentFont = this.lightFont;
      const dataParts = this.address.termsAndConditions.match(/[^\r\n]+/g);
      dataParts.forEach((data) => {
        this.currentY = this.getIncreasedY(this.currentFontSize);
        this.drawTextToPdf(data, this.outerMargin, this.currentY, 0);
      });
    }
  }

  createConditionsAndRegardsText() {
    this.drawTextToPdf(this.qcStore.getPdfData('QcProposalThirdParagraph', this.language), this.outerMargin, this.currentY, 0);
    this.currentY = this.getIncreasedY(this.headerFontSize * 4);
    this.drawTextToPdf(this.qcStore.getPdfData('QcProposalBestRegards', this.language), this.outerMargin, this.currentY, 0);
  }

  createSignatureLineSalesRep() {
    const staticTextTf = this.qcStore.getPdfData('QcProposalContactSignature', this.language);
    this.createSignatureLine(this.appUser.get('name'), staticTextTf);
  }

  createSignatureLineCustomer() {
    const customerAckText = this.qcStore.getPdfData('QcProposalCustomerSignature', this.language);
    const customerName = this.qcStore.customerData.contactName;
    this.createSignatureLine(customerAckText, customerName);
  }

  createSignatureLine(sigLineText1, sigLineText2) {
    const dateText = this.qcStore.getPdfData('QcProposalSignatureDate', this.language);
    this.drawLineToPdf(this.outerMargin, this.currentY, signatureLineLength);
    this.drawLineToPdf(this.outerMargin + signatureLineLength + signatureLineGap, this.currentY, dateLineLength);
    this.currentY = this.getIncreasedY(this.headerFontSize);
    this.drawTextToPdf(sigLineText1, this.outerMargin, this.currentY, 0);
    this.drawTextToPdf(`${dateText}`, this.outerMargin + signatureLineLength + signatureLineGap + this.getCenteredTextPosition(dateText, dateLineLength), this.currentY, 0);
    this.currentY = this.getIncreasedY(this.headerFontSize);
    this.drawTextToPdf(`${sigLineText2}`, this.outerMargin, this.currentY, 0);
  }

  getCenteredTextPosition(text, areaWidth) {
    const dateTextLen = this.measureText(text);
    return areaWidth / 2 - dateTextLen / 2;
  }

  addFooter() {
    this.currentFont = this.lightFont;
    this.currentFontSize = this.tableFontSize;
    const pageText = `${this.qcStore.getPdfData('QcProposalPage', this.language)} ${this.currentPageNo} ${this.qcStore.getPdfData('QcOf', this.language)} ${this.totalPageCount}`;
    this.drawTextToPdf(pageText, this.width - this.outerMargin - this.measureText(pageText), this.outerMargin + this.headerFontSize * 2, 0);
  }
}
