import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import PopoverContent from './popoverContent';

const styles = {
  popover: {
    position: 'relative',
    zIndex: '200',
  }
};

@inject('routing') @observer
class Popover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: !!this.props.visible
    };
    this.onClickOutSide = this.onClickOutSide.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onShow(e) {
    e.stopPropagation();
    this.setState({ visible: true });
  }

  onClose() {
    this.setState({ visible: false });
  }

  onToggle(e) {
    e.stopPropagation();
    this.setState(prevState => ({ visible: !prevState.visible }));
  }

  onClickOutSide() {
    this.onClose();
    this.props.onClickOutSide();
  }

  render() {
    return (
      <div className={this.props.classes.popover}>
        {this.props.triggerNode && React.cloneElement(this.props.triggerNode, {
          onClick: this.props.trigger === 'click' || this.props.trigger === 'hover' ? this.onToggle.bind(this) : null,
          onMouseOver: this.props.trigger === 'hover' ? this.onShow.bind(this) : null
        })}
        {this.state.visible
          && (
            <PopoverContent
              closeOnClick={this.props.closeOnClick}
              onClickOutSide={this.onClickOutSide}
              className={this.props.className}
              style={this.props.style}
              onClose={this.onClose}
              popoverArrowBackground={this.props.popoverArrowBackground}
              popoverArrowForeground={this.props.popoverArrowForeground}
            >
              {this.props.children}
            </PopoverContent>
          )
        }
      </div>
    );
  }
}

Popover.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  trigger: PropTypes.string.isRequired,
  triggerNode: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  popoverArrowBackground: PropTypes.string,
  popoverArrowForeground: PropTypes.string,
  closeOnClick: PropTypes.bool,
  visible: PropTypes.bool,
  onClickOutSide: PropTypes.func,
};

Popover.wrappedComponent.defaultProps = {
  style: null,
  className: '',
  popoverArrowBackground: '',
  popoverArrowForeground: '',
  closeOnClick: false,
  visible: false,
  onClickOutSide: () => { },
};

export default withStyles(styles)(Popover);
