import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { inject, observer } from 'mobx-react';
import simappColors from 'shared/lib/theme/simapp-colors';
import ShareMaterialCard from './share-material-card';

const styles = {
  pleaseNote: {
    marginBottom: '1rem'
  },
  selectMoreButton: {
    marginBottom: '1rem',
    width: '100%'
  },
  nothingSelected: {
    textAlign: 'center',
    color: simappColors.strawberry
  }
};

const ShareMaterialList = inject('applicationStore', 'materialStore')(observer((props) => {
  const list = props.materialStore.sharingMaterials.map(m => (
    <ShareMaterialCard key={m} uid={m} />
  ));

  return (
    <Fragment>
      <Typography className={props.classes.pleaseNote}><b>Please note:</b> The link(s) to material(s) attached will expire in 2 weeks from the time it&#x27;s sent.</Typography>
      <Button variant="contained" aria-label="Select more..." className={props.classes.selectMoreButton} color="primary" onClick={props.applicationStore.startSelectMore}>
        <AddIcon />
        Select more...
      </Button>
      { props.materialStore.sharingMaterials.length
        ? list
        : <Typography className={props.classes.nothingSelected}>You need to select at least one document to share.</Typography>
      }
    </Fragment>
  );
}));

ShareMaterialList.wrappedComponent.propTypes = {
  applicationStore: PropTypes.object.isRequired,
  materialStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ShareMaterialList);
