import React from 'react';
import { applicationStore } from 'shared/stores';
import { observer } from 'mobx-react';
import AlertDialog from 'web/components/_misc/alert-dialog';

const KryptorValidationDialog = observer(() => (
  <AlertDialog
    trigger={applicationStore.showKryptorValidationDialog}
    message={applicationStore.kryptorValidationMsg}
    close={applicationStore.toggleKryptorValidationDialog}
    width="sm"
  />
));

export default KryptorValidationDialog;
