import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import { simappColors } from 'shared/lib/theme';

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'relative',
    overflow: 'hidden',
    outline: 'none',
    userSelect: 'none',
    padding: '2em',
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    textAlign: 'center'
  },
  titleInput: {
    width: '80%',
    margin: '2rem auto',
    border: `1px solid ${simappColors.paleGrey}`,
  },
  error: {
    borderColor: simappColors.strawberry
  },
  buttonArea: {
    justifyContent: 'space-around',
    marginTop: '2rem'
  },
  buttonWrapper: {
    position: 'relative',
    marginLeft: 8,
    marginRight: 8
  },
  buttonProgress: {
    color: simappColors.azul,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
};

@observer
class DeleteOverlay extends Component {
  @observable deleteInProgress = false;

  handleClose = () => {
    this.props.onClose();
  };

  @action handleDelete = () => {
    this.deleteInProgress = true;
    this.props.onDelete();
  }

  render() {
    const { classes } = this.props;
    return (
      <Modal
        open={this.props.open}
        onClose={this.handleClose}
        className={classes.modal}
      >
        <ClickAwayListener onClickAway={this.handleClose}>
          <Paper className={classes.paper} tabIndex="-1">
            <Typography variant="h6" className={classes.title}>Please confirm that you want to delete the presentation<br />&quot;{this.props.presentationTitle}&quot;</Typography>

            <DialogActions className={classes.buttonArea}>
              <div className={classes.buttonWrapper}>
                <Button variant="outlined" color="primary" disabled={this.deleteInProgress} onClick={this.handleClose}>Cancel</Button>
              </div>
              <div className={classes.buttonWrapper}>
                <Button variant="contained" color="secondary" disabled={this.deleteInProgress} onClick={this.handleDelete}>Delete</Button>
                {this.deleteInProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </DialogActions>

          </Paper>
        </ClickAwayListener>
      </Modal>
    );
  }
}

DeleteOverlay.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  presentationTitle: PropTypes.string.isRequired
};

DeleteOverlay.defaultProps = {
};

export default withStyles(styles)(DeleteOverlay);
