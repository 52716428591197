import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { simappConstants, simappColors } from 'shared/lib/theme';
import pctConstants from 'shared/lib/resources/pct-constants';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import PctInputField from 'web/components/pct/pct-inputfield';
import ClickableTextButton from 'web/components/_misc/clickableTextButton';
import Checkbox from '@material-ui/core/Checkbox';
import { PctEUResultCalculation, PctUSResultCalculation, getTabContent } from 'shared/helper/pct-calculationhelper';
import PctNavButton from './pct-navbutton';
import PctAbbreviations from './pct-abbreviations';

const styles = {
  root: {
    width: '100%',
    height: '100vH',
    display: 'flex',
    flexDirection: 'column',
    padding: simappConstants.contentMarginLarge,
    overflowX: 'hidden',
    fontFamily: 'helvetica-neue-lt, sans-serif'
  },
  borderlessExpansionPanel: {
    '&::before': { content: 'none' },
    boxShadow: 'unset'
  },
  bottomBar: {
    width: '100%',
    marginTop: '30px',
    paddingBottom: '30px'
  },
  textContainer: {
    marginTop: '40px',
    marginBottom: '60px',
    marginLeft: simappConstants.pctHorizontalMargin,
    marginRight: simappConstants.pctHorizontalMargin
  },
  expansionPanel: {
    marginLeft: '-50px',
    boxShadow: 'unset'
  },
  animationlessPanelSummary: {
    minHeight: '48px !important',
    lineHeight: '48px',
    '& div:last-child': {
      padding: '0'
    },
    '& div:first-child': {
      margin: '0'
    }
  },
  mainItem: {
    backgroundColor: simappColors.paleGrey,
    marginBottom: '1px',
    marginTop: '1px'
  },
  lastMainItem: {
    marginBottom: '0px'
  },
  expansionHead: {
    paddingLeft: '0',
    paddingRight: '0'
  },
  expansionContent: {
    paddingLeft: '10px',
    paddingRight: '0px'
  },
  expansionContentTotal: {
    paddingLeft: '10px',
    paddingRight: '0px',
    marginRight: '50px'
  },
  expansionContentCosts: {
    marginTop: '-10px',
    boxShadow: 'unset'
  },
  expansionContentCostsOverview: {
    marginTop: '-15px'
  },
  expansionContentCostsDetails: {
    marginBottom: '-15px'
  },
  tableHeaderRow: {
    height: '29px',
    paddingLeft: '5px',
  },
  borderlessCell: {
    border: 'none'
  },
  tableCell: {
    textAlign: 'center',
    width: '205px',
    padding: '0 5px'
  },
  mediumText: {
    fontWeight: '500'
  },
  totalTable: {
    backgroundColor: simappColors.white,
    paddingLeft: '10px',
    paddingRight: '0px'
  },
  table: {
    marginTop: '-20px',
    marginBottom: '-25px',
    paddingLeft: '10px',
    paddingRight: '0px'
  },
  tableEUTotal: {
    marginTop: '-10px'
  },
  tableUSTotal: {
    marginBottom: '-25px'
  },
  leftAlignedText: {
    textAlign: 'left',
    marginLeft: '-40px'
  },
  longTermEffectsRow: {
    height: '60px',
    display: 'inline-block',
    marginLeft: '-85px',
    fontStyle: 'italic',
    fontFamily: 'helvetica-neue-lt, sans-serif'
  },
  longTermEffectsCheckbox: {
    marginLeft: '13px',
    marginRight: '5px',
    marginBottom: '3px'
  },
  hidden: {
    display: 'none'
  }
};

const longTermEffectsText = { marginLeft: '80px', display: 'inline-block', fontSize: 14 };

@inject('applicationStore', 'pctStore', 'routing') @observer
class PctResults extends Component {
  calculation;
  isUS = (this.props.applicationStore.presentingInName === pctConstants.CountryNameUS);
  uniqueKey = 0;

  constructor(props) {
    super(props);
    this.state = {
      enableABR_LRTI: true,
      enableCdiff_LRTI: true,
      enableABR_sepsis: true,
      enableCdiff_sepsis: true,
      displayLongTermLrti: 0, // displays on 2
      displayLongTermSepsis: 0 // displays on 2
    };
    this.calculation = this.isUS ? new PctUSResultCalculation(props.pctStore) : new PctEUResultCalculation(props.pctStore);
  }

  onNavigationClick(location) {
    this.props.routing.push(location);
  }

  getTableHeader(text) {
    return (
      <TableHead>
        <TableRow className={this.props.classes.tableHeaderRow}>
          <TableCell className={`${this.props.classes.borderlessCell}`}>
            <Typography variant="body1" className={this.props.classes.mediumText}>{text}</Typography>
          </TableCell>
          <TableCell className={`${this.props.classes.tableCell} ${this.props.classes.borderlessCell}`}>
            {this.props.pctStore.getText(pctConstants.PctWithoutPct)}
          </TableCell>
          <TableCell className={`${this.props.classes.tableCell}  ${this.props.classes.borderlessCell}`}>
            {this.props.pctStore.getText(pctConstants.PctWithPct)}
          </TableCell>
          <TableCell className={`${this.props.classes.tableCell}  ${this.props.classes.borderlessCell}`}>
            {this.props.pctStore.getText(pctConstants.PctDifference)}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  getTabs(tabContent, isLastTotal) {
    return (
      <div key={this.uniqueKey++}>
        <ExpansionPanel key={this.uniqueKey++} onChange={(object, expanded) => this.displayLongTermRow(tabContent.id, expanded)} CollapseProps={{ timeout: 0 }} className={this.props.classes.borderlessExpansionPanel}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={`${this.props.classes.expansionHead} ${this.props.classes.animationlessPanelSummary}`}>
            <Table>{this.getTableHeader(this.props.pctStore.getText(tabContent.tabTitleKey))}</Table>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={this.props.classes.expansionContent}>
            <Table className={this.props.classes.table}>
              <TableBody>
                {tabContent.tabContent.map((content, index) => (tabContent.totalIndex !== index ? this.BuildTableRow(content) : undefined))}
              </TableBody>
            </Table>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        {tabContent.totalIndex !== undefined
          ? (
            <div className={`${this.props.classes.totalTable} ${this.isUS && isLastTotal ? this.props.classes.tableUSTotal : ''}`}>
              <Table>
                <TableBody>
                  {this.BuildTableRow(tabContent.tabContent[tabContent.totalIndex], true, tabContent.isLast)}
                </TableBody>
              </Table>
            </div>
          )
          : ''}
      </div>
    );
  }

  getEUTables() {
    if (this.isUS) {
      return '';
    }
    const pageContent = this.calculation.getPageContent();

    return (
      <div>
        {pageContent.map(tabContent => this.getTabs(tabContent))}
      </div>
    );
  }

  getUSTables() {
    if (!this.isUS) {
      return '';
    }
    this.calculation.resultTotalNoPct = 0;
    this.calculation.resultTotalPct = 0;

    const pageContent = [
      { tabTitleKey: pctConstants.PctResultsTableHeadTextTextFieldLRTI, tabContent: this.getCostTables(pctConstants.ClinicalEffectLrti) },
      { tabTitleKey: pctConstants.PctResultsTableHeadTextTextFieldSepsis, tabContent: this.getCostTables(pctConstants.ClinicalEffectSepsis) },
      {
        tabTitleKey: pctConstants.PctResultsTableHeadTextTextFieldTotal,
        tabContent: (
          <ExpansionPanelDetails className={this.props.classes.expansionContentTotal}>
            <Table className={`${this.props.classes.table} ${this.props.classes.tableEUTotal}`}>
              {this.getTableHeader('')}
              <TableBody>
                {this.BuildTableRow(getTabContent(4,
                  [{ text: pctConstants.PctResultsTableHeadText4TextField0, type: pctConstants.PctTableRowCurrency }],
                  this.calculation, true)[0], true, true)}
              </TableBody>
            </Table>
          </ExpansionPanelDetails>
        )
      }
    ];
    return (
      <div key={this.uniqueKey++}>
        {pageContent.map(content => (
          <ExpansionPanel key={this.uniqueKey++} CollapseProps={{ timeout: 0 }} className={this.props.classes.borderlessExpansionPanel}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={`${this.props.classes.mainItem} ${this.props.classes.animationlessPanelSummary}`}>
              <Typography variant="body1" className={this.props.classes.mediumText}>
                {this.props.pctStore.getText(content.tabTitleKey)}
              </Typography>
            </ExpansionPanelSummary>
            {content.tabContent}
          </ExpansionPanel>
        ))
        }
      </div>
    );
  }

  getLongTermEffectsDisableRow(clinicalEffect) {
    const isLrti = pctConstants.ClinicalEffectLrti === clinicalEffect;
    return (
      <div
        key={this.uniqueKey++}
        className={
          `${this.props.classes.longTermEffectsRow}
          ${(isLrti && this.state.displayLongTermLrti === 2) || (!isLrti && this.state.displayLongTermSepsis === 2) ? '' : this.props.classes.hidden}`
        }
      >
        <Typography style={longTermEffectsText}>Disable long-term effects:</Typography>
        <Typography style={longTermEffectsText}>ABR</Typography>
        <Checkbox
          className={this.props.classes.longTermEffectsCheckbox}
          onChange={() => this.setState(prevState => ({
            enableABR_LRTI: isLrti ? !prevState.enableABR_LRTI : prevState.enableABR_LRTI,
            enableCdiff_LRTI: prevState.enableCdiff_LRTI,
            enableABR_sepsis: isLrti ? prevState.enableABR_sepsis : !prevState.enableABR_sepsis,
            enableCdiff_sepsis: prevState.enableCdiff_sepsis,
            displayLongTermLrti: prevState.displayLongTermLrti,
            displayLongTermSepsis: prevState.displayLongTermSepsis
          }))}
          checked={isLrti ? !this.state.enableABR_LRTI : !this.state.enableABR_sepsis}
          color="primary"
        />
        <Typography style={longTermEffectsText}>C.diff.</Typography>
        <Checkbox
          className={this.props.classes.longTermEffectsCheckbox}
          onChange={() => this.setState(prevState => ({
            enableABR_LRTI: prevState.enableABR_LRTI,
            enableCdiff_LRTI: isLrti ? !prevState.enableCdiff_LRTI : prevState.enableCdiff_LRTI,
            enableABR_sepsis: prevState.enableABR_sepsis,
            enableCdiff_sepsis: isLrti ? prevState.enableCdiff_sepsis : !prevState.enableCdiff_sepsis,
            displayLongTermLrti: prevState.displayLongTermLrti,
            displayLongTermSepsis: prevState.displayLongTermSepsis
          }))}
          checked={isLrti ? !this.state.enableCdiff_LRTI : !this.state.enableCdiff_sepsis}
          color="primary"
        />
      </div>
    );
  }

  getCostTables(clinicalEffect) {
    const isLrti = pctConstants.ClinicalEffectLrti === clinicalEffect;
    const enableABR = isLrti ? this.state.enableABR_LRTI : this.state.enableABR_sepsis;
    const enableCdiff = isLrti ? this.state.enableCdiff_LRTI : this.state.enableCdiff_sepsis;
    this.calculation.execute(clinicalEffect, enableABR, enableCdiff);
    const costTableContent = this.calculation.getCostContent();
    return (
      <ExpansionPanelDetails key={this.uniqueKey++} className={this.props.classes.expansionContentCosts}>
        {costTableContent.map((content, index) => (
          <ExpansionPanel key={this.uniqueKey++} onChange={(object, expanded) => this.displayLongTermRow(index === 0 ? undefined : clinicalEffect, expanded)} CollapseProps={{ timeout: 0 }} className={this.props.classes.expansionPanel}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              className={`${index === 0 ? '' : this.props.classes.expansionContentCostsDetails} ${this.props.classes.animationlessPanelSummary}`}
            >
              <Typography variant="body1" className={this.props.classes.mediumText}>
                {this.props.pctStore.getText(content.tabTitleKey)}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={`${this.props.classes.expansionContent} ${index === 0 ? this.props.classes.expansionContentCostsOverview : ''}`}>
              {index === 0 // overview
                ? (
                  <Table className={this.props.classes.table}>
                    {this.getTableHeader('')}
                    <TableBody>{content.tabContent.map(tabContent => this.BuildTableRow(tabContent, true))}</TableBody>
                  </Table>
                )
                : content.tabContent.map((tabContent, contentIndex) => this.getTabs(tabContent, contentIndex + 1 === content.tabContent.length)) // details
              }

            </ExpansionPanelDetails>
          </ExpansionPanel>
        ))
        }
        {this.getLongTermEffectsDisableRow(clinicalEffect)}
      </ExpansionPanelDetails>
    );
  }

  displayLongTermRow = (id, expanded) => {
    if (id === undefined) {
      return;
    }
    const counterAdaptation = expanded ? 1 : -1;
    this.setState(prevState => ({
      enableABR_LRTI: prevState.enableABR_LRTI,
      enableCdiff_LRTI: prevState.enableCdiff_LRTI,
      enableABR_sepsis: prevState.enableABR_sepsis,
      enableCdiff_sepsis: prevState.enableCdiff_sepsis,
      displayLongTermLrti: prevState.displayLongTermLrti + (id === pctConstants.ClinicalEffectLrti ? counterAdaptation : 0),
      displayLongTermSepsis: prevState.displayLongTermSepsis + (id === pctConstants.ClinicalEffectSepsis ? counterAdaptation : 0)
    }));
  }

  BuildTableRow(rowContent, isTotal, isLast) {
    const { resourceName, noPct, pct, dif, type, tab, row } = rowContent;
    const bold = isTotal ? this.props.classes.mediumText : '';
    const text = this.props.pctStore.getText(resourceName);
    return (
      <TableRow key={this.uniqueKey++}>
        <TableCell className={isLast ? this.props.classes.borderlessCell : ''}>
          <Typography variant="body1" className={bold}>{text}</Typography>
        </TableCell>
        {this.BuildTableCell(`tab${tab}row${row}value0`, noPct, type, false, isLast)}
        {this.BuildTableCell(`tab${tab}row${row}value1`, pct, type, false, isLast)}
        {this.BuildTableCell(`tab${tab}row${row}value2`, dif, type, true, isLast)}
      </TableRow>
    );
  }

  BuildTableCell(name, value, type, allowNegative, removeBorder) {
    const val = value === undefined ? 0 : value;
    return (
      <TableCell className={`${this.props.classes.tableCell} ${removeBorder ? this.props.classes.borderlessCell : ''}`}>
        <PctInputField
          fieldName={name}
          displayType="text"
          isUSNumberInput={this.isUS}
          value={val}
          type={type}
          allowNegative={allowNegative}
          textAlign="right"
        />
      </TableCell>
    );
  }

  render() {
    this.uniqueKey = 0;
    let bottomText = this.props.pctStore.getText(pctConstants.PctResultsBottomText);
    if (this.isUS) {
      bottomText = bottomText.split('\n');
    }
    return (
      <div className={this.props.classes.root} key={this.uniqueKey++}>
        <div className={this.props.classes.textContainer}>
          <Typography align="center" variant="subtitle1" className={this.isUS ? this.props.classes.leftAlignedText : ''}>{this.props.pctStore.getText(pctConstants.PctResultsHeadText)}</Typography>
        </div>
        {this.getUSTables()}
        {this.getEUTables()}
        <div><PctAbbreviations cDiff abr hidden={!this.isUS} /></div>
        <div className={this.props.classes.textContainer}>
          {this.isUS
            ? (
              <div>
                <Typography align="center" variant="subtitle1" className={this.props.classes.leftAlignedText}>{bottomText[0]}</Typography>
                <Typography align="center" variant="subtitle1" className={this.props.classes.leftAlignedText}>{bottomText[1]}</Typography>
              </div>
            )
            : <Typography align="center" variant="subtitle1">{bottomText}</Typography>}
        </div>
        <div className={this.props.classes.bottomBar}>
          <ClickableTextButton onClick={() => this.props.routing.push('/he-models/pct-calculator/references')} text={this.props.pctStore.getText(pctConstants.PctReferences)} />
          <PctNavButton
            nextText={this.props.pctStore.getText(pctConstants.PctPreviousStep)}
            nextOnClickLocation={this.isUS ? '/he-models/pct-calculator/clinicaleffect-sepsis' : '/he-models/pct-calculator/clinicaleffect'}
            nextOnClick={(location) => {
              this.onNavigationClick(location);
            }}
            isUS={this.isUS}
            screenNumber={5}
          />
        </div>
      </div>
    );
  }
}

PctResults.wrappedComponent.propTypes = {
  applicationStore: PropTypes.object.isRequired,
  pctStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired,
};

export default withStyles(styles)(PctResults);
