import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import Add from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import { simappColors } from 'shared/lib/theme';
import SimappIcon from 'web/components/_ui/simapp-icon';

const styles = {
  search: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  searchIcon: {
    color: simappColors.steelGrey,
    marginLeft: 24,
    marginRight: 15,

  },
  searchField: {
    flexGrow: 1
  },
  createPresentationButton: {
    whiteSpace: 'nowrap',
    height: '100%',
    paddingLeft: '2em',
    paddingRight: '2em',
    borderRadius: 0
  },
  createPresentationIcon: {
    marginRight: '0.5em'
  },
  deleteButton: {
    marginLeft: 24,
    marginRight: 15,
    color: simappColors.strawberry,
    backgroundColor: simappColors.paleGrey,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: simappColors.steelGrey,
    }
  }
};

@inject('materialStore', 'applicationStore', 'routing') @observer
class MaterialsSearch extends Component {
  @observable searchString = '';

  onChange = (e) => {
    this.searchString = e.target.value;
    const oldText = e.target.value;
    window.setTimeout(() => {
      if (oldText === this.searchString) {
        this.props.materialStore.setSearchString(this.searchString);
      }
    }, 300);
  }

  render() {
    const { materialStore, applicationStore, classes } = this.props;
    const { push } = this.props.routing;
    return (
      <div className={classes.search}>
        { this.searchString.length > 0
          ? (
            <ButtonBase className={classes.deleteButton} title="Close Material" onClick={() => { this.searchString = ''; materialStore.setSearchString(''); }}>
              <SimappIcon icon="x" />
            </ButtonBase>
          )
          : (
            <SimappIcon
              icon="search"
              className={classes.searchIcon}
            />
          )
        }
        <Input
          id="search"
          value={this.searchString}
          onChange={this.onChange}
          disableUnderline
          autoComplete="off"
          placeholder="Search..."
          className={classes.searchField}
        />
        { !applicationStore.isDistributor && (
          <Button
            variant="contained"
            color="primary"
            className={classes.createPresentationButton}
            onClick={() => { push('/create-presentation'); }}
          >
            <Add className={classes.createPresentationIcon} />
            <span>Create Presentation</span>
          </Button>
        ) }
      </div>
    );
  }
}

MaterialsSearch.wrappedComponent.propTypes = {
  materialStore: PropTypes.object.isRequired,
  applicationStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired
};
export default withStyles(styles)(MaterialsSearch);
