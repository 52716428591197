import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import { applicationStore, newsStore } from 'shared/stores';
import ActivitiesSection from './sections/activities-section';
import NewsSection from './sections/news-section';
import EventsSection from './sections/events-section';

const styles = {
  root: {
    height: '100vH',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 24px',
    overflowX: 'hidden',
    justifyContent: 'space-between',
    '& > div': {
      minHeight: 'auto !important'
    }
  },
};

@observer
class Dashboard extends Component {
  componentWillMount() {
    newsStore.backToDashboard();
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Collapse in={applicationStore.dashboardAllOrNews}>
          <NewsSection />
        </Collapse>
        <Collapse in={applicationStore.dashboardAllOrCalendar}>
          {!applicationStore.isDistributor && (
            <EventsSection />
          )}
        </Collapse>
        <Collapse in={applicationStore.dashboardAllOrMaterials}>
          { applicationStore.canAccessMaterials && (
            <ActivitiesSection />
          )}
        </Collapse>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dashboard);
