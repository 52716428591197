import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { simappConstants, simappColors } from '../../../shared/lib/theme';
import CopeptinDialog from './copeptin-dialog';

const styles = {
  root: {
    padding: simappConstants.contentMarginLarge,
    width: '100%',
    overflow: 'auto'
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingLeft: 8
  },
  title: {
    fontSize: '28px',
    fontWeight: '300'
  },
  tableBox: {
    paddingTop: 20,
    paddingBottom: 40
  },
  table: {
    width: '100%',
    display: 'table',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    borderSpacing: 0,
    borderCollapse: 'collapse'
  },
  tableBody: {
    display: 'table-row-group'
  },
  tableRow: {
    color: 'inherit',
    height: 48,
    display: 'table-row',
    outline: 'none',
    textAlign: 'middle'
  },
  tableCell: {
    display: 'table-cell',
    padding: '4px 56px 4px 24px',
    borderBottom: '1px solid rgba(226, 226, 226, 1)',
    verticalAlign: 'inherit',
    textAlign: 'left',
    direction: 'row-reverse',
    color: '#0e0e10',
    fontSize: '0.8125rem',
    fontWeight: 'normal',
    '&:last-child': {
      paddingRight: 24
    }
  },
  tableCellNumerical: {
    textAlign: 'right',
    flexDirection: 'row-reverse'
  },
  bottomBar: {
    width: '100%',
    marginTop: '30px',
    paddingBottom: '30px'
  },
  text: {
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontSize: '0.8125rem',
    '&.bold': {
      fontWeight: 'bold'
    }
  },
  navigationButton: {
    borderRadius: '2px',
    height: '56px',
    width: '100%',
    fontSize: '17px',
    fontWeight: 'normal',
    transition: 'none',
    backgroundColor: `${simappColors.coolGrey}`,
    color: `${simappColors.white}`,
    '&:hover': {
      backgroundColor: `${simappColors.coolGrey}`
    }
  },
  image: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageContainer: {
    width: '100%',
    height: undefined,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

const json = require('../../../shared/lib/resources/copeptin-references.json');

@inject('copeptinStore', 'routing') @observer
class CopeptinReferences extends Component {
  rows;
  backgroundImg;

  constructor(props) {
    super(props);
    this.backgroundImg = this.props.copeptinStore.getLocalizedReferenceImage();
  }

  createData() {
    this.rows = json.default;
  }

  render() {
    const { classes } = this.props;
    const { push } = this.props.routing;
    this.createData();
    return (
      <div className={classes.root}>
        <div className={classes.headerRow}>
          <Typography variant="h5" className={classes.title} gutterBottom>{this.props.copeptinStore.getText('copeptinReferencesHeadline')}</Typography>
        </div>
        <div className={classes.tableBox}>
          <table className={classes.table}>
            <tbody className={classes.tableBody}>
              {this.rows.map((row) => {
                if (row.url !== undefined) {
                  return (
                    <tr className={classes.tableRow} key={row.id} id={row.id}>
                      <td className={`${classes.tableCell} ${classes.tableCellNumerical}`}>{row.id}</td>
                      <td className={classes.tableCell}>
                        {row.text}<br /><a href={row.url}>{row.url}</a>
                      </td>
                    </tr>
                  );
                }
                return (
                  <tr className={classes.tableRow} key={row.id} id={row.id}>
                    <td className={`${classes.tableCell} ${classes.tableCellNumerical}`}>{row.id}</td>
                    <td className={classes.tableCell}>
                      {row.text}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className={this.props.classes.imageContainer}>
          <img src={`${this.backgroundImg}`} className={this.props.classes.image} alt="copeptin savings chart" />
        </div>
        <div className={classes.bottomBar}>
          <Button onClick={() => push(this.props.copeptinStore.infoPageReturnLocation)} className={this.props.classes.navigationButton}>{this.props.copeptinStore.getLocalizedText('copeptinBackButton')}</Button>
        </div>
        <CopeptinDialog />
      </div>
    );
  }
}

CopeptinReferences.wrappedComponent.propTypes = {
  copeptinStore: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CopeptinReferences);
