import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import DoneIcon from '@material-ui/icons/Done';
import { simappColors } from 'shared/lib/theme';
import ModalHeader from '../modal-header';

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'relative',
    width: '40%',
    height: '80%',
    overflow: 'hidden',
    outline: 'none',
    userSelect: 'none'
  },
  list: {
    height: 'calc(100% - 2.5em)',
    overflowY: 'auto'
  },
  listItem: {
    paddingLeft: 40,
    '&:not(:last-child)': {
      borderBottom: `1px solid ${simappColors.paleGrey}`,
    },
    '&.disabled': {
      color: simappColors.lightGrey,
      '&:hover': {
        backgroundColor: 'unset',
        cursor: 'unset'
      }
    }
  },
  multiSelectItem: {
    paddingLeft: 0
  },
  multiselectContainer: {
    width: 48,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
};

@observer
class BaseOverlay extends Component {
  handleClose = () => {
    this.props.onClose();
  };

  handleSelect = () => {
    this.props.onClose();
    this.props.onSelect(this.props.currentSelect);
  }

  handleSelectionClick = (id) => {
    this.props.onSelect(id);
    this.handleClose();
  };

  handleMultiSelectionClick = (id, isdelete) => {
    if (isdelete) {
      this.props.currentSelect.splice(this.props.currentSelect.indexOf(id), 1);
    } else {
      this.props.currentSelect.push(id);
    }
    this.forceUpdate();
  };

  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.handleClose}
        className={this.props.classes.modal}
      >
        <ClickAwayListener onClickAway={this.handleClose}>
          <Paper className={this.props.classes.paper} tabIndex="-1">
            {this.props.multiSelect
              ? <ModalHeader title={`${this.props.labelPrefix} ${this.props.label}`} handleSelect={this.handleSelect} handleClose={this.handleClose} />
              : <ModalHeader title={`${this.props.labelPrefix} ${this.props.label}`} handleClose={this.handleClose} />
            }
            <MenuList className={this.props.classes.list}>
              {this.props.list.map(option => (
                this.props.multiSelect
                  ? (
                    <MenuItem
                      className={`${this.props.classes.listItem} ${this.props.classes.multiSelectItem}`}
                      key={option[this.props.idField]}
                      selected={false}
                      onClick={() => this.handleMultiSelectionClick(option[this.props.idField], this.props.currentSelect.indexOf(option[this.props.idField]) > -1)}
                    >
                      <div className={this.props.classes.multiselectContainer}>{this.props.currentSelect.indexOf(option[this.props.idField]) > -1 ? <DoneIcon color="primary" /> : ''}</div>
                      {this.props.additionalField ? `${option[this.props.additionalField]} ${option[this.props.valueField]}` : option[this.props.valueField]}
                    </MenuItem>
                  )
                  : (
                    <MenuItem
                      className={`${this.props.classes.listItem}`}
                      key={option[this.props.idField]}
                      selected={option[this.props.idField] === this.props.currentSelect}
                      onClick={() => this.handleSelectionClick(option[this.props.idField])}
                    >
                      {this.props.additionalField ? `${option[this.props.additionalField]} ${option[this.props.valueField]}` : option[this.props.valueField]}
                    </MenuItem>
                  )
              ))}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Modal>
    );
  }
}

BaseOverlay.propTypes = {
  classes: PropTypes.object.isRequired,
  multiSelect: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  labelPrefix: PropTypes.string,
  list: PropTypes.array.isRequired,
  currentSelect: PropTypes.any,
  idField: PropTypes.string,
  valueField: PropTypes.string,
  additionalField: PropTypes.string
};

BaseOverlay.defaultProps = {
  multiSelect: false,
  currentSelect: null,
  labelPrefix: 'Select',
  idField: 'id',
  valueField: 'value',
  additionalField: undefined
};

export default withStyles(styles)(BaseOverlay);
