import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable, action, reaction, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import Input from '@material-ui/core/Input';
import { simappColors } from 'shared/lib/theme';

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'relative',
    width: 600,
    overflow: 'hidden',
    outline: 'none',
    userSelect: 'none',
    padding: '1em 0 0',
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    textAlign: 'center'
  },
  titleInput: {
    width: '80%',
    margin: '2rem auto',
    border: `1px solid ${simappColors.paleGrey}`,
  },
  error: {
    borderColor: simappColors.strawberry
  },
  buttonArea: {
  },
  buttonWrapper: {
    position: 'relative',
    marginLeft: 8,
    marginRight: 8
  },
  buttonProgress: {
    color: simappColors.azul,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
};

@inject('presentationStore') @observer
class TitleOverlay extends Component {
  @observable title = '';
  @observable titleError = false;
  @observable saveInProgress = false;

  setTitle = reaction(
    () => (this.props.open),
    (open) => {
      if (open && this.props.presentationStore.id !== null) {
        this.title = this.props.presentationStore.title;
      }
    }
  );

  handleCancel = () => {
    this.props.onCancel();
  };

  @action handleChange = (event) => {
    this.title = event.target.value;
  }

  @action handleSave = () => {
    if (this.title.length === 0) {
      this.titleError = true;
      return;
    }

    this.saveInProgress = true;
    this.props.presentationStore.save(this.title).then(() => {
      this.props.onSave();
      runInAction(() => { this.saveInProgress = false; });
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <Modal
        open={this.props.open}
        onClose={this.handleCancel}
        className={classes.modal}
      >
        <ClickAwayListener onClickAway={this.handleCancel}>
          <Paper className={classes.paper} tabIndex="-1">
            <Typography variant="h6" className={classes.title}>Please save your presenation with a meaningful title</Typography>
            <Input
              autoFocus
              disableUnderline
              className={this.titleError ? `${classes.titleInput} ${classes.error}` : classes.titleInput}
              onChange={this.handleChange}
              value={this.title}
              error={this.titleError}
            />
            <DialogActions className={classes.buttonArea}>
              <div className={classes.buttonWrapper}>
                <Button variant="contained" disabled={this.saveInProgress} onClick={this.handleCancel}>Go back to editing</Button>
              </div>
              <div className={classes.buttonWrapper}>
                <Button variant="contained" color="primary" disabled={this.saveInProgress} onClick={this.handleSave}>Save</Button>
                {this.saveInProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </DialogActions>

          </Paper>
        </ClickAwayListener>
      </Modal>
    );
  }
}

TitleOverlay.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  presentationStore: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

TitleOverlay.wrappedComponent.defaultProps = {
};

export default withStyles(styles)(TitleOverlay);
