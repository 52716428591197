import { action, observable } from 'mobx';
import moment from 'moment';

class LogStore {
  @observable logs = [];

  datetimeFormat = 'YYYY-MM-DD HH:mm:ss';

  pushLog = (message) => { this.log(message, 'log'); };
  pushInfo = (message) => { this.log(message, 'info'); };
  pushWarn = (message) => { this.log(message, 'warn'); };
  pushError = (message) => { this.log(message, 'error'); };

  @action log = (message, level) => {
    this.logs.push({
      level,
      message,
      time: moment().format(this.datetimeFormat)
    });
  }
}

export default new LogStore();
