/* eslint-disable */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

class ReactImageUploadComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pictures: [],
      notAcceptedFileType: [],
      notAcceptedFileSize: []
    };
    this.inputElement = '';
    this.onDropFile = this.onDropFile.bind(this);
    this.triggerFileUpload = this.triggerFileUpload.bind(this);
  }

  /*
	 Handle file validation
	 */
  onDropFile(e) {
    const files = e.target.files;
    const _this = this;
    // If callback giving, fire.
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(files);
    }
    // Iterate over all uploaded files
    for (let i = 0; i < files.length; i++) {
      const f = files[i];
      // Check for file extension
      if (!this.hasExtension(f.name)) {
        const newArray = _this.state.notAcceptedFileType.slice();
        newArray.push(f.name);
        _this.setState({ notAcceptedFileType: newArray });
        continue;
      }
      // Check for file size
      if (f.size > this.props.maxFileSize) {
        const newArray = _this.state.notAcceptedFileSize.slice();
        newArray.push(f.name);
        _this.setState({ notAcceptedFileSize: newArray });
        continue;
      }

      const reader = new FileReader();
      // Read the image via FileReader API and save image result in state.
      reader.onload = (function () {
        return function (e) {
				  if (_this.props.singleImage === true) {
            _this.setState({ pictures: [e.target.result] });
          } else if (_this.state.pictures.indexOf(e.target.result) === -1) {
				    const newArray = _this.state.pictures.slice();
            newArray.push(e.target.result);
            _this.setState({ pictures: newArray });
          }
        };
      }(f));
      reader.readAsDataURL(f);
    }
  }

  /*
	 On button click, trigger input file to open
	 */
  triggerFileUpload() {
    this.inputElement.click();
  }

  /*
	 Check file extension (onDropFile)
	 */
  hasExtension(fileName) {
    return (new RegExp(`(${this.props.imgExtension.join('|').replace(/\./g, '\\.')})$`)).test(fileName);
  }

  /*
	 Remove the image from state
	 */
  removeImage(picture) {
    const filteredAry = this.state.pictures.filter(e => e !== picture);
    this.setState({ pictures: filteredAry });
  }

  /*
	 Check if any errors && render
	 */
  renderErrors() {
    let notAccepted = '';
    if (this.state.notAcceptedFileType.length > 0) {
      notAccepted = this.state.notAcceptedFileType.map((error, index) => (
        <div className={`errorMessage${this.props.errorClass}`} key={index} style={this.props.errorStyle}>
						* {error} {this.props.fileTypeError}
        </div>
      ));
    }
    if (this.state.notAcceptedFileSize.length > 0) {
      notAccepted = this.state.notAcceptedFileSize.map((error, index) => (
        <div className={`errorMessage${this.props.errorClass}`} key={index} style={this.props.errorStyle}>
						* {error} {this.props.fileSizeError}
        </div>
      ));
    }
    return notAccepted;
  }

  render() {
    return (
      <div className={`fileUploader ${this.props.className}`} style={this.props.style}>
        <div className="fileContainer">
          <div className="errorsContainer">
            {this.renderErrors()}
          </div>
          <Button style={{ fontSize: '1rem' }} color="primary" disableRipple disableFocusRipple onClick={this.triggerFileUpload} >{this.props.buttonText}</Button>
          <input
            type="file"
            ref={input => this.inputElement = input}
            name={this.props.name}
            style={{ display: 'none' }}
            // multiple="multiple"
            onChange={this.onDropFile}
            accept={this.props.accept}
          />
        </div>
      </div>
    );
  }
}

ReactImageUploadComponent.defaultProps = {
  className: '',
  buttonClassName: {},
  buttonStyles: {},
  withPreview: false,
  accept: 'accept=image/*',
  name: '',
  withIcon: false,
  buttonText: 'Choose images',
  withLabel: false,
  label: 'Max file size: 5mb, accepted: jpg|gif|png',
  labelStyles: {},
  labelClass: '',
  imgExtension: ['.jpg', '.gif', '.png'],
  maxFileSize: 5242880,
  fileSizeError: ' file size is too big',
  fileTypeError: ' is not supported file extension',
  errorClass: '',
  style: {},
  errorStyle: {},
  singleImage: true
};

ReactImageUploadComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  buttonClassName: PropTypes.object,
  buttonStyles: PropTypes.object,
  withPreview: PropTypes.bool,
  accept: PropTypes.string,
  name: PropTypes.string,
  withIcon: PropTypes.bool,
  buttonText: PropTypes.string,
  withLabel: PropTypes.bool,
  label: PropTypes.string,
  labelStyles: PropTypes.object,
  labelClass: PropTypes.string,
  imgExtension: PropTypes.array,
  maxFileSize: PropTypes.number,
  fileSizeError: PropTypes.string,
  fileTypeError: PropTypes.string,
  errorClass: PropTypes.string,
  errorStyle: PropTypes.object,
  singleImage: PropTypes.bool
};

export default ReactImageUploadComponent;
