import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { simappConstants, simappColors } from '../../../shared/lib/theme';
import CopeptinDialog from './copeptin-dialog';

const styles = {
  root: {
    width: '100%',
    height: '100vH',
    display: 'flex',
    flexDirection: 'column',
    padding: simappConstants.contentMarginLarge,
    overflowX: 'hidden',
    fontFamily: 'helvetica-neue-lt, sans-serif'
  },
  bottomBar: {
    width: '100%',
    marginTop: '60px',
    paddingBottom: '30px'
  },
  textContainer: {
    marginTop: '20px',
    marginBottom: '40px',
  },
  image: {
    display: 'block',
    maxWidth: '95%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  navigationButton: {
    borderRadius: '2px',
    height: '56px',
    width: '100%',
    fontSize: '17px',
    fontWeight: 'normal',
    transition: 'none',
    backgroundColor: `${simappColors.coolGrey}`,
    color: `${simappColors.white}`,
    '&:hover': {
      backgroundColor: `${simappColors.coolGrey}`
    }
  },
};

@inject('copeptinStore', 'routing') @observer
class CopeptinInfo extends Component {
  title;
  imagePath;

  constructor(props) {
    super(props);
    this.imagePath = `/images/${this.props.copeptinStore.getInfoImageName()}`;
  }

  render() {
    const { copeptinStore } = this.props;
    const { push } = this.props.routing;
    return (
      <div className={this.props.classes.root}>
        <div>
          <img src={this.imagePath} className={this.props.classes.image} alt={this.imagePath} />
        </div>
        <div className={this.props.classes.bottomBar}>
          <Button onClick={() => push(this.props.copeptinStore.infoPageReturnLocation)} className={this.props.classes.navigationButton}>{copeptinStore.getLocalizedText('copeptinBackButton')}</Button>
        </div>
        <CopeptinDialog />
      </div>
    );
  }
}

CopeptinInfo.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  copeptinStore: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired,
};

export default withStyles(styles)(CopeptinInfo);
