
// Just ligt the Mui Divider, but vertical
import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    width: 1,

    height: '70%',
    margin: 'auto 1em',
  }
};

const DividerV = props => (
  <Divider className={props.classes.root} {...props} />
);

DividerV.propTypes = {
  classes: PropTypes.object.isRequired,
  light: PropTypes.bool
};

DividerV.defaultProps = {
  light: false
};

export default withStyles(styles)(DividerV);
