// import fillPdf from 'shared/helper/kryptor-pdf-helper';
import QCTemplateUsaLoc from '../../shared/helper/qcPdf/qc-pdf-template-usa-loc';

export function open(bytes) {
  // Create a Blob from the PDF Stream
  const file = new Blob(
    [bytes],
    { type: 'application/pdf' }
  ); // Build a URL from the file
  const fileURL = URL.createObjectURL(file); // Open the URL on new Window
  return fileURL;
}

export default function createPdf(qcStore, appUser, address, language) {
  const template = new QCTemplateUsaLoc(qcStore, appUser, address, false, language);
  const bytes = template.createPdf();
  return open(bytes);
}
