import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { observable, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Input from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core/styles';
import { simappColors } from 'shared/lib/theme';
import SimappIcon from 'web/components/_ui/simapp-icon';

const styles = {
  productGroupCheckbox: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  productSubgroupCheckbox: {
    paddingLeft: 23,
  },
  inputWrapper: {
    display: 'flex',
    padding: '11px 16px'
  },
  input: {
    flex: 1
  },
  searchIcon: {
    color: simappColors.steelGrey,
    marginTop: 4,
    marginRight: 16
  },
};

@inject('productStore') @observer
class ProductGroupsFilter extends Component {
  @observable searchString = '';
  searchStringLower = '';
  @observable open = false;
  disabledProductGroups = {};
  disabledProductSubgroups = {};

  @action setSearchString = (e) => {
    this.searchString = e.target.value;
    this.searchStringLower = e.target.value.toLowerCase();
  }

  onProductSubgroupFilterChange = (pId) => {
    this.props.productStore.toggleSubgroupFilter(pId);
  };

  onProductGroupFilterChange = (pId) => {
    this.props.productStore.toggleGroupFilter(pId);
  };

  buildName = (name) => {
    if (this.searchString.length > 0) {
      const index = name.toLowerCase().indexOf(this.searchStringLower);
      const substring = name.substr(index, this.searchString.length);
      return (
        <span>
          {name.substring(0, index)}
          <b>{name.substring(index, index + substring.length)}</b>
          {name.substring(index + substring.length)}
        </span>
      );
    }
    return name;
  }

  @action handleOpen = () => {
    this.disabledProductGroups = Object.assign(this.props.productStore.disabledProductGroups);
    this.open = true;
    this.searchString = '';
    window.setTimeout(() => {
      // eslint-disable-next-line react/no-find-dom-node
      ReactDOM.findDOMNode(this.searchField).childNodes[0].focus();
    }, 10);
  }

  @action handleClose = () => {
    this.open = false;
  }

  menuItems = () => [
    <div className={this.props.classes.inputWrapper} key="searchField">
      <SimappIcon
        icon="search"
        className={this.props.classes.searchIcon}
      />
      <Input placeholder="Product Group" ref={(e) => { this.searchField = e; }} value={this.searchString} onChange={this.setSearchString} className={this.props.classes.input} />
    </div>,
    [...this.props.productStore.productGroups.map((pg) => {
      let productSubgroups = this.props.productStore.productSubgroups.filter(p => p.product_group_id === pg.id && !this.disabledProductSubgroups[p.id]);

      if (this.disabledProductGroups[pg.id]) return null;

      if (this.searchString.length > 0) {
        productSubgroups = productSubgroups.filter(psg => psg.name.toLowerCase().indexOf(this.searchStringLower) > -1);
        if (pg.name.toLowerCase().indexOf(this.searchStringLower) === -1 && productSubgroups.length === 0) return null;
      }

      // render subgroup
      const list = productSubgroups.map(p => (this.props.productStore.disabledProductSubgroups[p.id] ? null : (
        <MenuItem
          key={`psg:${p.id}`}
          value={p.id}
          onClick={() => this.onProductSubgroupFilterChange(p.id)}
        >
          <Checkbox
            checked={this.props.productStore.filteredProductSubgroupIds.indexOf(p.id) > -1}
            className={this.props.classes.productSubgroupCheckbox}
            color="primary"
          />
          <ListItemText primary={this.buildName(p.name)} />
        </MenuItem>
      )));

      // render group
      return (
        <div key={`pg:${pg.id}`}>
          <MenuItem
            key={pg.id}
            value={pg.id}
            onClick={() => this.onProductGroupFilterChange(pg.id)}
          >
            <Checkbox
              checked={this.props.productStore.filteredProductGroupIds.indexOf(pg.id) > -1}
              className={this.props.classes.productGroupCheckbox}
              color="primary"
            />
            <ListItemText primary={this.buildName(pg.name)} />
          </MenuItem>
          {list}
        </div>
      );
    })]
  ];

  render() {
    const { productStore, MenuProps } = this.props;
    return (
      <Select
        multiple
        displayEmpty
        value={productStore.filteredProductsForUI}
        disableUnderline
        renderValue={() => 'Product Group'}
        MenuProps={MenuProps}
        open={this.open}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
      >
        {this.menuItems()}
      </Select>
    );
  }
}

ProductGroupsFilter.wrappedComponent.propTypes = {
  productStore: PropTypes.object.isRequired,
  MenuProps: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(ProductGroupsFilter);
