import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import SimAppButton from 'web/components/_ui/simapp-button';
import { simappColors } from 'shared/lib/theme';
import BaseOverlay from 'web/components/_ui/form-elements/base-overlay';
import { getItemFromList, getItemsFromList } from 'shared/helper/qc-helper';
import QcProductTable from './qc-product-table';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontWeight: 'normal'
  },
  headline: {
    flexDirection: 'row',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: '14px',
    paddingBottom: '14px',
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  dropDown: {
    minWidth: '220px'
  },
  buttonRow: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginTop: '30px',
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  button: {
    marginLeft: '5px',
    marginRight: '5px'
  },
  competitorButton: {
    backgroundColor: 'transparent',
    color: `${simappColors.azul}`,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  tableContainer: {
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  tableHeadRow: {
    backgroundColor: `${simappColors.paleGrey}`,
    height: '40px'
  },
  tableHeadCell: {
    fontSize: '10px',
    fontWeight: '500',
    padding: '8px 7px',
    borderBottom: `1px solid ${simappColors.tableBorder}`,
    textAlign: 'center',
    letterSpacing: '0.5pt',
    borderLeft: `1px solid ${simappColors.tableBorder}`,
    width: '300px',
    '&.first': {
      width: 'unset'
    },
    '&.last': {
      borderRight: `1px solid ${simappColors.tableBorder}`,
    }
  },
  tableRowCell: {
    height: '56px',
    borderBottom: `1px solid ${simappColors.tableBorder}`,
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '8px 7px',
    textAlign: 'center',
    borderLeft: `1px solid ${simappColors.tableBorder}`,
    '&.first': {
      borderLeft: 'unset',
      textAlign: 'left',
    },
    '&.last': {
      borderRight: `1px solid ${simappColors.tableBorder}`,
    },
    '&.input': {
      padding: 'unset',
      '& input': {
        width: '100%',
        height: '100%',
        border: 'none',
        textAlign: 'center',
        fontSize: '14px',
        fontFamily: 'helvetica-neue-lt, sans-serif',
        fontWeight: 'normal'
      }
    }
  },
  tableColumnProducts: {
    minWidth: '220px'
  }
};

@inject('qcStore') @observer
class QcCompetitorProducts extends Component {
  productTable;
  constructor(props) {
    super(props);
    this.state = {
      overlayRequestFrom: -1,
      showCompetitorDialog: false,
      overlayAnalyteRequestFrom: -1
    };
  }

  productValueChange = (index, value, type) => {
    const { qcStore } = this.props;
    if (type === 'PricePerUnit') {
      qcStore.updateCompetitorPricePerUnit(index, value);
    } else if (type === 'Quantity') {
      qcStore.updateCompetitorQuantity(index, value);
    }
  }

  setCompetitor = () => {
    this.setState({
      showCompetitorDialog: true
    });
  }

  addCompetitorProduct = () => {
    const { qcStore } = this.props;
    this.setState({
      overlayRequestFrom: qcStore.competitorProductList.length
    });
  }

  editProduct = (index) => {
    this.setState({
      overlayRequestFrom: index
    });
  }

  editAnalytes = (index) => {
    this.setState({
      overlayAnalyteRequestFrom: index
    });
  }

  setBasedOnListPrice = () => {
    const { qcStore } = this.props;
    qcStore.setProductsBasedOnListPrice();
    this.productTable.resetPricesToDefaultValues();
  }

  render() {
    const { qcStore, classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.headline}>
          <SimAppButton className={classes.competitorButton} onClick={() => this.setCompetitor()} small>{qcStore.competitorData.competitor.name ? qcStore.competitorData.competitor.name : qcStore.getText('QcSelectCompetitor')}</SimAppButton>
        </div>
        <div>
          {qcStore.competitorData.competitor.id !== undefined
            ? (
              <QcProductTable
                editProduct={index => this.editProduct(index)}
                editPricePerUnit={index => this.editPricePerUnit(index)}
                editTotalPrice={index => this.editTotalPrice(index)}
                deleteProduct={index => qcStore.deleteSelectedCompetitorProduct(index)}
                productValueChanged={(index, value, type) => this.productValueChange(index, value, type)}
                data={qcStore.competitorProductList}
                pricePerUnitInput
                showKitFormat
                editable
                objRef={(ref) => { this.productTable = ref; }}
                editAnalytes={(index) => { this.editAnalytes(index); }}
              />
            )
            : ''}
        </div>
        {qcStore.competitorData.competitor.id !== undefined
          ? (
            <div className={classes.buttonRow}>
              <div>
                <SimAppButton className={classes.button} onClick={() => this.addCompetitorProduct()} inline>{qcStore.getText('QCProductSelectionComparisonViewAddProductButton')}</SimAppButton>
              </div>
              <div>
                <SimAppButton className={classes.button} onClick={() => this.setBasedOnListPrice()} inline>{qcStore.getText('QCProductSelectionComparisonViewSetBasedListPriceButton')}</SimAppButton>
              </div>
            </div>
          )
          : '' }
        <BaseOverlay
          multiSelect={this.state.overlayRequestFrom === qcStore.competitorProductList.length}
          open={this.state.overlayRequestFrom !== -1}
          onClose={() => {
            this.setState({ overlayRequestFrom: -1 });
          }}
          onSelect={(id) => {
            const itemAdded = this.state.overlayRequestFrom === qcStore.competitorProductList.length;
            if (itemAdded) {
              const products = getItemsFromList(id, qcStore.getCompetitorProductSelectData());
              qcStore.addCompetitorProducts(products);
            } else {
              const product = getItemFromList(id, qcStore.getCompetitorProductSelectData());
              qcStore.updateCompetitorProduct(this.state.overlayRequestFrom, product);
            }
            this.setState({ overlayRequestFrom: -1 });
          }}
          currentSelect={this.state.overlayRequestFrom >= 0 && this.state.overlayRequestFrom < qcStore.competitorProductList.length ? qcStore.competitorProductList[this.state.overlayRequestFrom].product.id : []}
          labelPrefix=""
          label=""
          list={qcStore.getCompetitorProductSelectData()}
          valueField="name"
          additionalField="partNumber"
        />
        <BaseOverlay
          open={this.state.showCompetitorDialog}
          onClose={() => {
            this.setState({ showCompetitorDialog: false });
          }}
          onSelect={(id) => {
            if (qcStore.competitorData.competitor === undefined || id !== qcStore.competitorData.competitor.id) {
              qcStore.updateCompetitor(getItemFromList(id, qcStore.getCompetitorSelectData()));
            }
            this.setState({ showCompetitorDialog: false });
          }}
          currentSelect={this.state.showCompetitorDialog && qcStore.competitorData.competitor ? qcStore.competitorData.competitor.id : ''}
          labelPrefix=""
          label={qcStore.getText('QcSelectCompetitor')}
          list={qcStore.getCompetitorSelectData()}
          valueField="name"
        />
        <BaseOverlay
          multiSelect
          open={this.state.overlayAnalyteRequestFrom !== -1}
          onClose={() => {
            this.setState({ overlayAnalyteRequestFrom: -1 });
          }}
          onSelect={(idList) => {
            qcStore.setCompetitorProductAnalyteList(this.state.overlayAnalyteRequestFrom, idList);
            this.setState({ overlayAnalyteRequestFrom: -1 });
          }}
          currentSelect={this.state.overlayAnalyteRequestFrom >= 0 && this.state.overlayAnalyteRequestFrom < qcStore.competitorProductList.length ? JSON.parse(JSON.stringify(qcStore.competitorProductList[this.state.overlayAnalyteRequestFrom].activeAnalytes)) : []}
          labelPrefix=""
          label=""
          list={qcStore.getCompetitorProductAnalyteList(this.state.overlayAnalyteRequestFrom)}
          valueField="name"
          idField="uid"
        />
      </div>
    );
  }
}

QcCompetitorProducts.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  qcStore: PropTypes.object.isRequired
};

export default withStyles(styles)(QcCompetitorProducts);
