import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Add from '@material-ui/icons/Add';
import AlertDialog from 'web/components/_misc/alert-dialog';
import EventsCalendar from '../events-calendar';
import { headlineRow, section } from '../headline';
import Events from '../events';
import EventFormDialog from '../event-form-dialog';

const styles = {
  headlineRow,
  section,
  grid: {
    display: 'grid',
    gridColumnGap: '2rem',
    gridTemplateColumns: '1fr 1fr',
    marginBottom: 8,
    overflow: 'hidden',
  },
  events: {
    height: 250,
    overflowY: 'auto',
  },
  calendar: {
    height: '100%',
    marginRight: 8,
  },
  addButton: {
    paddingLeft: 0,
    paddingRight: 0,
    minWidth: 0
  }
};

@inject('eventForm') @observer
class EventsSection extends Component {
  render() {
    const { classes, eventForm } = this.props;
    return (
      <div className={classes.section}>
        <div className={classes.grid}>
          <div>
            <div className={classes.headlineRow}>
              <Typography variant="h5">Calendar</Typography>
              <Button
                color="primary"
                onClick={eventForm.new}
                className={classes.addButton}
                title="Add New Event"
              >
                <Add />
              </Button>
            </div>
            <div className={classes.events}>
              <Events />
            </div>
          </div>
          <div className={classes.calendar}>
            <EventsCalendar />
          </div>
        </div>
        <EventFormDialog />
        <AlertDialog trigger={!!String(eventForm.responseError).length} message="Could not save the event. Please try again later or inform your Administrator." close={eventForm.resetResponseError} />
      </div>
    );
  }
}

EventsSection.wrappedComponent.propTypes = {
  classes: PropTypes.object,
  eventForm: PropTypes.object.isRequired,
};

EventsSection.wrappedComponent.defaultProps = {
  classes: {}
};

export default withStyles(styles)(EventsSection);
