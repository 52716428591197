import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import InputField from 'web/components/_ui/form-elements/input-field';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import SaveButton from 'web/components/_ui/form-elements/save-button';
import CancelButton from 'web/components/_ui/form-elements/cancel-button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import InputDateTime from '../_ui/form-elements/input-date-time';
import ModalHeader from '../_ui/modal-header';

const styles = {
  dialog: {
    width: 600
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
};

@inject('eventForm') @observer
class EventFormDialog extends Component {
  render() {
    const { eventForm, classes } = this.props;
    const { fields, onFieldChange } = eventForm;
    return (
      <Dialog
        open={eventForm.showDialog}
        onClose={() => eventForm.close()}
        classes={{
          paper: classes.dialog
        }}
      >
        <ModalHeader title={eventForm.isNew ? 'New Event' : 'Edit Event'} />
        <DialogContent className={classes.content}>

          <form>
            <InputField
              fields={fields}
              name="title"
              onChange={onFieldChange}
            />
            <Divider light />
            <InputField
              fields={fields}
              name="description"
              onChange={onFieldChange}
            />
            <Divider light />
            <InputField
              fields={fields}
              name="location"
              onChange={onFieldChange}
            />
            <Divider light />
            <InputDateTime
              fields={fields}
              name="start"
              onChange={onFieldChange}
            />
            <Divider light />
            <InputDateTime
              fields={fields}
              name="end"
              onChange={onFieldChange}
            />
            <Divider light />
            <InputField
              fields={fields}
              name="attendees"
              onChange={onFieldChange}
            />
          </form>
        </DialogContent>
        <DialogActions className={classes.flex}>
          <CancelButton
            form={eventForm}
            onClick={() => eventForm.close()}
            label="Cancel"
          />
          <div className={classes.flex}>
            <CancelButton
              form={eventForm}
              onClick={() => eventForm.resetValues()}
              label="Reset"
            />
            <SaveButton
              form={eventForm}
              onClick={() => eventForm.save()}
            />
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

EventFormDialog.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  eventForm: PropTypes.object.isRequired,
};

export default withStyles(styles)(EventFormDialog);
