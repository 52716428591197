import { observable, action, intercept } from 'mobx';
import { persist } from 'mobx-persist';
import AddressForm from 'shared/stores/forms/kryptor-addressform';
import { setTestsPerAssay } from 'shared/helper/kryptor-helper';
import { setAssayKits, setConsumablesQTY, getMultiControlQTY } from 'shared/helper/kryptor-calculationhelper';
import { addValueSeparators } from 'shared/helper/kryptor-pdf-helper';
import { consumablesCalculatorStore, applicationStore, countryStore } from '.';
import routing from './router-store';
import createStorage from './create-storage';

export class MultiControl {
  multiControl;
  maxWorkDaysPerWeek;
  quantity;
  @observable pricePerItem;
  @observable totalPrice = 0;
  @observable volumePerAliquot = 0;
  @observable aliquotCoverPerInstrument = 0;
  maxNumberInstrumentRelatedAssay = 0;
}

export class AssayItem {
  id;
  assay;
  name;
  patientsPerCoverTime;
  addedTests;
  workDaysPerWeek = 5;
  numberOfInstruments = 1;
  multiControlId;
  multiControlIds = [];
  runRecoveryTest = false;
  showMultiControl = false;
  showRunRecoveryTest = false;
  reagentKits = 0;
  reagentKitPrice;
  calKits = 0;
  calKitPrice;
  qcKits = 0;
  qcKitPrice;
  amountTests = 0;
  pricePerTest = 0;
  pricePerResult = 0;
  totalPrice = 0;
  volumePerAliquot;
  aliquotCoverPerInstrument;
}

let pdfDataResource = require('shared/lib/resources/kryptor-pdf-resources.json');
let jsonResourcesData = require('shared/lib/resources/kryptor-resources.json');

class KryptorStore {
  constructor() {
    createStorage('kryptorStore', this).then(() => {
      const pdfResource = require('shared/lib/resources/kryptor-pdf-resources.json'); // eslint-disable-line global-require
      const jsonResource = require('shared/lib/resources/kryptor-resources.json'); // eslint-disable-line global-require
      pdfDataResource = pdfResource === undefined ? pdfDataResource : pdfResource;
      jsonResourcesData = jsonResource === undefined ? jsonResourcesData : jsonResource;
      this.jsonResources = jsonResourcesData;
      this.pdfData = pdfDataResource;
    });
    this.resetData();
  }

  @persist('object') jsonResources = jsonResourcesData;
  @persist('list') @observable kryptorAssayList = [];
  @persist('object') @observable customerData = {};
  @persist('list') @observable instrumentTypes = [{ id: 0, name: 'KRYPTOR compact PLUS' }, { id: 1, name: 'KRYPTOR GOLD' }];
  @persist('list') @observable multiControlList = [];
  @persist('object') @observable consumablesData = {};

  @persist totalCostsReagents = 0;
  @persist totalCostMultiControls = 0;
  @persist totalCostConsumables = 0;

  @action resetData = () => {
    this.kryptorAssayList = [];
    this.customerData = { customer: '', contactName: '', address: { streetAndNumber: '', postalCode: '', city: '', country: '' }, currencyCountry: '', kryptorType: undefined, coverTimePeriod: 12, instrumentNumber: 1 };
    this.multiControlList = [];
    this.consumablesData = {
      reactionPlates: { name: this.getText('KryptorConsumable0Name0'), ref: this.getText('KryptorConsumable0Ref0'), totalPrice: 0 },
      solution1: { name: this.getText('KryptorConsumable1Name0'), ref: this.getText('KryptorConsumable1Ref0'), totalPrice: 0 },
      solution2: { name: this.getText('KryptorConsumable2Name0'), ref: this.getText('KryptorConsumable2Ref0'), totalPrice: 0 },
      solution3: { name: this.getText('KryptorConsumable3Name0'), ref: this.getText('KryptorConsumable3Ref0'), totalPrice: 0 },
      solution4: { name: this.getText('KryptorConsumable4Name0'), ref: this.getText('KryptorConsumable4Ref0'), totalPrice: 0 },
      bufferBags: { name: this.getText('KryptorConsumable5Name0'), ref: this.getText('KryptorConsumable5Ref0'), totalPrice: 0 },
      dilutionPlateBox: { name: this.getText('KryptorConsumable6Name0'), ref: this.getText('KryptorConsumable6Ref0'), count: 1, totalPrice: 0 }
    };
    this.totalCostsReagents = 0;
    this.totalCostMultiControls = 0;
    this.totalCostConsumables = 0;
  }

  @action resetMultiControlList = () => {
    this.multiControlList = [];
  }

  @action finishUserAssayInput = () => {
    const multiControls = {};
    this.multiControlList.forEach((control) => {
      multiControls[control.multiControl.id] = {};
      multiControls[control.multiControl.id].pricePerItem = control.pricePerItem;
      multiControls[control.multiControl.id].volumePerAliquot = control.volumePerAliquot;
      multiControls[control.multiControl.id].aliquotCoverPerInstrument = control.aliquotCoverPerInstrument;
    });
    this.resetMultiControlList(); // multi controls are added by setAssayKits
    for (let i = 0; i < this.kryptorAssayList.length; i++) {
      const assayItem = this.kryptorAssayList[i];
      setTestsPerAssay(this, assayItem, i);
      setAssayKits(this, assayItem, i);
    }
    Object.keys(multiControls).map((key) => {
      const control = this.multiControlList.find(obj => `${obj.multiControl.id}` === key);
      if (control !== undefined) {
        control.pricePerItem = multiControls[key].pricePerItem;
        control.volumePerAliquot = multiControls[key].volumePerAliquot;
        control.aliquotCoverPerInstrument = multiControls[key].aliquotCoverPerInstrument;
        control.quantity = getMultiControlQTY(this, control);
      }
    });
    Object.keys(this.consumablesData).map((key) => {
      setConsumablesQTY(this, this.consumablesData[key]);
    });

    Object.keys(this.consumablesData).map((key, index) => {
      this.consumablesData[key].name = this.getText(`KryptorConsumable${index}Name${this.customerData.kryptorType}`);
      this.consumablesData[key].ref = this.getText(`KryptorConsumable${index}Ref${this.customerData.kryptorType}`);
    });
  }

  @action updateResults = () => {
    this.updateAssayTotalCost();
    this.updateMultiControlTotalCost();
    this.updateConsumableTotalCost();
  }

  @action updateAssayTotalCost = () => {
    let assayTotalCosts = 0;
    this.kryptorAssayList.map((assayItem, index) => {
      const assay = assayItem.assay;
      let hasPriceQcKit = true;
      if (assay.multi_control_ids.length > 0 || assayItem.multiControlId !== undefined) {
        hasPriceQcKit = false;
      }
      let totalPrice = 0;
      if (assayItem.reagentKitPrice !== undefined) {
        totalPrice += assayItem.reagentKitPrice * assayItem.reagentKits;
      }
      if (assayItem.calKitPrice !== undefined) {
        totalPrice += assayItem.calKitPrice * assayItem.calKits;
      }
      if (hasPriceQcKit && assayItem.qcKitPrice !== undefined) {
        totalPrice += assayItem.qcKitPrice * assayItem.qcKits;
      }
      assayTotalCosts += totalPrice;
      this.setAssayTotalPrice(totalPrice, index);
    });
    this.totalCostsReagents = assayTotalCosts;
  }

  @action updateMultiControlTotalCost = () => {
    let multiControlTotalCosts = 0;
    this.multiControlList.map((item, index) => {
      let totalPrice = 0;
      if (item.pricePerItem !== undefined) {
        totalPrice += item.pricePerItem * item.quantity;
      }
      multiControlTotalCosts += totalPrice;
      this.setMultiControlTotalPrice(totalPrice, index);
    });
    this.totalCostMultiControls = multiControlTotalCosts;
  }

  @action updateConsumableTotalCost = () => {
    let consumableTotalCosts = 0;
    Object.keys(this.consumablesData).map((key) => {
      const item = this.consumablesData[key];
      let totalPrice = 0;
      if (item.pricePerItem !== undefined) {
        totalPrice += item.pricePerItem * item.count;
      }
      consumableTotalCosts += totalPrice;
      this.setConsumableTotalPrice(totalPrice, key);
    });
    this.totalCostConsumables = consumableTotalCosts;
  }

  // customer data

  @action setCustomer = (value) => {
    this.customerData.customer = value;
  }

  @action setCustomerContact = (value) => {
    this.customerData.contactName = value;
  }

  @action setCustomerAddress = (value) => {
    this.customerData.address = value;
  }

  @action setCustomerCoverTimePeriod = (value) => {
    this.customerData.coverTimePeriod = value;
  }

  @action setInstrumentNumber = (value) => {
    this.customerData.instrumentNumber = value;
  }

  @action setKryptorType = (value) => {
    this.customerData.kryptorType = value;
  }

  // Assay data

  @action getAssayFromList = (index) => {
    if (index >= this.kryptorAssayList.length || index < 0) {
      return undefined;
    }
    return this.kryptorAssayList[index];
  };

  @action removeAssayFromList = index => this.kryptorAssayList.splice(index, 1);

  @action addAssayToList = (assay) => {
    assay.assay = this.getAssay(assay.id);
    this.kryptorAssayList.push(assay);
  }

  @action updateListAssay = (assay, index) => {
    this.kryptorAssayList.splice(index, 1, assay);
  };

  @action setAssayReagentKits = (value, index) => {
    this.kryptorAssayList[index].reagentKits = value;
  }

  @action setAssayReagentKitPrice = (value, index) => {
    this.kryptorAssayList[index].reagentKitPrice = value;
  }

  @action setAssayCalKits = (value, index) => {
    this.kryptorAssayList[index].calKits = value;
  }

  @action setAssayCalKitPrice = (value, index) => {
    this.kryptorAssayList[index].calKitPrice = value;
  }

  @action setAssayQCKits = (value, index) => {
    this.kryptorAssayList[index].qcKits = value;
  }

  @action setAssayQCKitsPrice = (value, index) => {
    this.kryptorAssayList[index].qcKitPrice = value;
  }

  @action setAssayTotalPrice = (value, index) => {
    this.kryptorAssayList[index].totalPrice = value;
  }

  @action setAssayPricePerTest = (value, index) => {
    this.kryptorAssayList[index].pricePerTest = value;
  }

  @action setAssayPricePerResult = (value, index) => {
    this.kryptorAssayList[index].pricePerResult = value;
  }

  @action setAssayPricePerTestAmount = (value, index) => {
    this.kryptorAssayList[index].amountTests = value;
  }

  @action setAssayVolumePerAliquot = (value, index) => {
    this.kryptorAssayList[index].volumePerAliquot = value;
  }

  @action setAssayAliquotCoverPerInstrument = (value, index) => {
    this.kryptorAssayList[index].aliquotCoverPerInstrument = value;
  }

  @action getAssayVolumePerAliquotArray = () => {
    const volumePerAliquotArray = [];
    for (let i = 0; i < this.kryptorAssayList.length; i++) {
      const assayItem = this.kryptorAssayList[i];
      volumePerAliquotArray[i] = assayItem.volumePerAliquot;
    }
    return volumePerAliquotArray;
  }

  @action getAssayAliquotCoverArray = () => {
    const aliquotCoverArray = [];
    for (let i = 0; i < this.kryptorAssayList.length; i++) {
      const assayItem = this.kryptorAssayList[i];
      aliquotCoverArray[i] = assayItem.aliquotCoverPerInstrument;
    }
    return aliquotCoverArray;
  }

  @action setTotalCostsReagents = (value) => {
    this.totalCostsReagents = value;
  }

  @action getAssay = id => consumablesCalculatorStore.assays.find(obj => obj.id === id)

  // multi control quantitiy list

  @action addMultiControl = (multiControlItem) => {
    this.multiControlList.push(multiControlItem);
  }

  @action multiControlExists = id => !(this.multiControlList.find(m => m.multiControl.id === id) === undefined)

  @action getMultiControl = id => consumablesCalculatorStore.multiControls.find(obj => obj.id === id)

  @action setMultiControlPricePerItem = (value, index) => {
    this.multiControlList[index].pricePerItem = value;
  }

  @action setMultiControlTotalPrice = (value, index) => {
    this.multiControlList[index].totalPrice = value;
  }

  @action setTotalCostsMultiControls = (value) => {
    this.totalCostMultiControls = value;
  }

  @action setMultiControlVolumePerAliquot = (value, index) => {
    this.multiControlList[index].volumePerAliquot = value;
  }

  @action setMultiControlCoverPerInstrument = (value, index) => {
    this.multiControlList[index].aliquotCoverPerInstrument = value;
  }

  @action updateMultiControl(index, updatedMultiControl, maxWorkDaysOfAssayMultiControl, quantity) {
    const oldMultiControl = this.multiControlList.find(mc => mc.multiControl.id === index);
    const oldIndex = this.multiControlList.indexOf(oldMultiControl);
    oldMultiControl.multiControl = updatedMultiControl;
    oldMultiControl.maxWorkDaysPerWeek = maxWorkDaysOfAssayMultiControl;
    oldMultiControl.quantity = quantity;
    this.multiControlList[oldIndex] = oldMultiControl;
  }

  // resources

  @action getText = (key) => {
    try {
      return this.jsonResources.unlocalized[key];
    } catch {
      return undefined;
    }
  }

  // consumables

  @action setConsumablePrice = (value, index) => {
    this.consumablesData[index].pricePerItem = value;
  }

  @action setConsumableTotalPrice = (value, index) => {
    this.consumablesData[index].totalPrice = value;
  }

  @action setConsumableCount = (value, index) => {
    this.consumablesData[index].count = value;
  }

  @action setReactionPlates = (value) => {
    this.consumablesData.reactionPlates.count = value;
  }

  @action setReactionPlatesPrice = (value) => {
    this.consumablesData.reactionPlates.pricePerItem = value;
  }

  @action setSolution1 = (value) => {
    this.consumablesData.solution1.count = value;
  }

  @action setSolution1Price = (value) => {
    this.consumablesData.solution1.pricePerItem = value;
  }

  @action setSolution2 = (value) => {
    this.consumablesData.solution2.count = value;
  }

  @action setSolution2Price = (value) => {
    this.consumablesData.solution2.pricePerItem = value;
  }

  @action setSolution3 = (value) => {
    this.consumablesData.solution3.count = value;
  }

  @action setSolution3Price = (value) => {
    this.consumablesData.solution3.pricePerItem = value;
  }

  @action setSolution4 = (value) => {
    this.consumablesData.solution4.count = value;
  }

  @action setSolution4Price = (value) => {
    this.consumablesData.solution4.pricePerItem = value;
  }

  @action setBufferBags = (value) => {
    this.consumablesData.bufferBags.count = value;
  }

  @action setBufferBagsPrice = (value) => {
    this.consumablesData.bufferBags.pricePerItem = value;
  }

  @action setDilutionPlateBox = (value) => {
    this.consumablesData.dilutionPlateBox.count = value;
  }

  @action setDilutionPlatePrice = (value) => {
    this.consumablesData.dilutionPlateBox.pricePerItem = value;
  }

  @action setTotalCostsConsumables = (value) => {
    this.totalCostConsumables = value;
  }

  @action getCurrencyCountry = () => {
    if (this.customerData.currencyCountry !== '') {
      return this.customerData.currencyCountry;
    }
    return 'US';
  }

  @action setCurrencyCountry = (value) => {
    this.customerData.currencyCountry = value;
  }

  // pdf data

  @persist('object') pdfData = pdfDataResource;

  @action getPdfData = (key, countrySpecific) => {
    if (!countrySpecific || countrySpecific === undefined) {
      return this.pdfData[key];
    }
    const country = applicationStore.presentingInIso.toLowerCase();
    const value = this.getPdfDataForCountry(key, country);
    return value === undefined ? this.getPdfDataForCountry(key, this.pdfData.KryptorPdfCountryDefault) : value;
  }

  getPdfDataForCountry(key, countryCode) {
    const value = this.pdfData[countryCode];
    if (value === undefined) {
      return value;
    }
    return value[key];
  }

  getFormattedCurrencyValue(value) {
    // eslint-disable-next-line no-restricted-globals
    const fixedValue = (value !== undefined && !isNaN(value) ? Math.round(value * 100) / 100 : 0);
    const country = countryStore.countries.find(item => item.iso === this.customerData.currencyCountry);
    const decimalSeparator = country.decimal_separator;
    const thousandSeparator = country.thousand_separator;
    const currencySign = country.currency_sign;
    const currencySignPosition = country.currency_position;
    let formattedValue = addValueSeparators(fixedValue, '.', decimalSeparator, thousandSeparator);
    const dividerPos = formattedValue.indexOf(decimalSeparator);
    if (dividerPos === -1) {
      formattedValue = `${formattedValue}${decimalSeparator}00`;
    } else if (formattedValue.length - dividerPos === 2) { // one decimal number
      formattedValue = `${formattedValue}0`;
    }
    return currencySignPosition === 'prefix' ? currencySign + formattedValue : `${formattedValue} ${currencySign}`;
  }

  getCurrencySeparators() {
    const country = countryStore.countries.find(item => item.iso === this.customerData.currencyCountry);
    const currentDecimalSeparator = country.decimal_separator;
    const currentThousandSeparator = country.thousand_separator;
    return { decimalSeparator: currentDecimalSeparator, thousandSeparator: currentThousandSeparator };
  }

  // ----- Kryptor Routing -----
  observeRouting = intercept(routing, 'location', (newLocation) => {
    const oldPathname = routing.location !== null ? routing.location.pathname : ''; // initially, routing.location is null
    if (!oldPathname.includes('kryptor-calculator')
      && !oldPathname.endsWith('results')) {
      this.resetData();
      AddressForm.reset();
    }
    return newLocation;
  });
}

export default new KryptorStore();
