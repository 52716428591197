import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Input from '@material-ui/core/Input';
import ButtonBase from '@material-ui/core/ButtonBase';
import { withStyles } from '@material-ui/core/styles';
import { simappColors } from 'shared/lib/theme';
import SimappIcon from 'web/components/_ui/simapp-icon';

const styles = {
  search: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    marginBottom: '12px',
    borderBottom: `1px solid ${simappColors.paleGrey}`,
  },
  searchIcon: {
    color: simappColors.steelGrey,
    marginLeft: 24,
    marginRight: 15,

  },
  searchField: {
    flexGrow: 1
  },
  createPresentationButton: {
    whiteSpace: 'nowrap',
    height: '100%',
    paddingLeft: '2em',
    paddingRight: '2em',
    borderRadius: 0
  },
  createPresentationIcon: {
    marginRight: '0.5em'
  },
  deleteButton: {
    marginLeft: 24,
    marginRight: 15,
    color: simappColors.strawberry,
    backgroundColor: simappColors.paleGrey,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: simappColors.steelGrey,
    }
  }
};

@observer
class SearchBar extends Component {
  onChange = e => this.props.onChange(e.target.value);

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.search}>
        { this.props.searchString.length > 0
          ? (
            <ButtonBase className={classes.deleteButton} title="Close" onClick={() => { this.props.onChange(''); }}>
              <SimappIcon icon="x" />
            </ButtonBase>
          )
          : (
            <SimappIcon
              icon="search"
              className={classes.searchIcon}
            />
          )
        }
        <Input
          id="search"
          value={this.props.searchString}
          onChange={this.onChange}
          disableUnderline
          autoComplete="off"
          placeholder="Search..."
          className={classes.searchField}
        />
      </div>
    );
  }
}

SearchBar.propTypes = {
  classes: PropTypes.object.isRequired,
  searchString: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
export default withStyles(styles)(SearchBar);
