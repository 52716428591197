import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { simappConstants } from 'shared/lib/theme';
import copeptinConstants from 'shared/lib/resources/copeptin-constants';
import CopeptinTable from './copeptin-table';
import CopeptinNavButton from './copeptin-navbutton';
import CopeptinDialog from './copeptin-dialog';
import ClickableTextButton from '../_misc/clickableTextButton';

const styles = {
  root: {
    width: '100%',
    height: '100vH',
    display: 'flex',
    flexDirection: 'column',
    padding: simappConstants.contentMarginLarge,
    overflowX: 'hidden',
    fontFamily: 'helvetica-neue-lt, sans-serif'
  },
  textContainer: {
    marginTop: '40px',
    marginBottom: '60px',
    marginLeft: simappConstants.copeptinHorizontalMargin,
    marginRight: simappConstants.copeptinHorizontalMargin
  },
  bottomBar: {
    width: '100%',
    marginTop: '30px',
    paddingBottom: '30px'
  },
  referencesLink: {
    marginLeft: '15px'
  }
};

@inject('copeptinStore', 'routing') @observer
class CopeptinInputsPercent extends Component {
  getBottomBar(push) {
    return (
      <div className={this.props.classes.bottomBar}>
        <ClickableTextButton
          isBold
          className={this.props.classes.referencesLink}
          onClick={() => push('/he-models/copeptin-calculator/copeptin-references')}
          text={this.props.copeptinStore.getLocalizedText('copeptinReferences')}
        />
        <CopeptinNavButton
          nextOnClick={location => push(location)}
          nextOnClickLocation="/he-models/copeptin-calculator/results"
          backOnClick={() => {
            this.props.copeptinStore.setLocation(this.props.routing.location.pathname);
            push('/he-models/copeptin-calculator/inputs');
          }}
          screenNumber={3}
        />
      </div>
    );
  }

  render() {
    const { push } = this.props.routing;
    const { classes, copeptinStore } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.textContainer}>
          <Typography align="center" variant="subtitle1">{copeptinStore.getLocalizedText('copeptinInputPercentScreenText')}</Typography>
        </div>
        <div>
          <CopeptinTable
            source={copeptinStore.jsonCalculatorData.patientProportion}
            getSecondColumnValue={copeptinStore.getPatientProportionCopeptinValue}
            setSecondColumnValue={copeptinStore.setPatientProportionCopeptinValue}
            isUserSecondColumnValue={copeptinStore.isUserPatientProportionCopeptinValue}
            getThirdColumnValue={copeptinStore.getPatientProportionDefaultValue}
            setThirdColumnValue={copeptinStore.setPatientProportionDefaultValue}
            isUserThirdColumnValue={copeptinStore.isUserPatientProportionDefaultValue}
            headerFirstColumn={copeptinStore.getLocalizedText('copeptinPatientProportion')}
            headerSecondColumn={copeptinStore.getLocalizedText('copeptinPatientProportionCopeptin')}
            headerThirdColumn={copeptinStore.getLocalizedText('copeptinPatientProportionDefault')}
            defaultExpanded
            dataType={copeptinConstants.CopeptinTableRowPercentage}
          />
        </div>
        {this.getBottomBar(push)}
        <CopeptinDialog />
      </div>
    );
  }
}

CopeptinInputsPercent.wrappedComponent.propTypes = {
  copeptinStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired,
};

export default withStyles(styles)(CopeptinInputsPercent);
