import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { calendarStore, eventForm } from 'shared/stores';
import Event from './event';

const styles = {
  eventList: {
    marginLeft: 8,
    paddingRight: 2,
  },
  noEventsWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

@observer
class Events extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        {calendarStore.filterEventsForCurrentDay.length
          ? (
            <div className={classes.eventList}>
              {(calendarStore.filterEventsForCurrentDay.map(event => (
                <Event
                  key={event.id}
                  event={event}
                  edit={eventForm.edit}
                  remove={calendarStore.deleteEvent}
                />
              )))}
            </div>
          ) : (
            <div className={classes.noEventsWrapper}>
              <Typography variant="body1">No events yet for this date</Typography>
            </div>
          )
        }
      </Fragment>
    );
  }
}

Events.propTypes = {
  classes: PropTypes.object,
};

Events.defaultProps = {
  classes: {}
};

export default withStyles(styles)(Events);
