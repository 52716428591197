import React, { Component, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Typography from '@material-ui/core/Typography';
import PageScroller from 'web/components/_ui/page-scroller';
import AlertDialog from 'web/components/_misc/alert-dialog';
import { getApiBase } from 'shared/lib/helpers';

const styles = {
  scrollableWrapper: {
    width: '100%',
  },
  itemWrapper: {
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  iframe: {
    backgroundColor: '#fff'
  },
  navigationButtons: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    display: 'flex',
    flexDirection: 'column'
  },
  referencesLink: {
    position: 'absolute',
    bottom: 20,
    left: 20
  },
  referenceRow: {
    margin: '0.5rem 0',
    display: 'flex'
  },
  referenceKey: {
    fontWeight: 'bold',
    flex: '0 0 2rem'
  }
};

const References = withStyles(styles)(({ list, classes }) => {
  if (list === null || list.length === 0) return null;
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <Button title="Show References" className={classes.referencesLink} onClick={() => { setOpen(true); }}>
        <Typography>References</Typography>
      </Button>
      <AlertDialog
        trigger={open}
        title="References"
        messageObj={(
          <div>{list.map((ref, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Typography className={classes.referenceRow} key={`${index}${ref}`}>
              <span className={classes.referenceKey}>{ref.key.length > 0 && (<b>{ref.key}:</b>)}</span>
              {ref.value}
            </Typography>
          ))}
          </div>
        )}
        close={() => { setOpen(false); }}
        width="md"
      />
    </Fragment>
  );
});

@inject('contentPieceStore') @observer
class ShowPresentation extends Component {
  gotoNextPage = () => {
    if (typeof this.pageScroller !== 'undefined' && this.pageScroller !== null) {
      this.pageScroller.gotoNextPage();
    }
  }

  gotoPreviousPage = () => {
    if (typeof this.pageScroller !== 'undefined' && this.pageScroller !== null) {
      this.pageScroller.gotoPreviousPage();
    }
  }

  render() {
    const { classes, contentPieceStore, material } = this.props;
    if (!contentPieceStore.hasDataLoaded) {
      return <div />;
    }
    return (
      <div className={classes.scrollableWrapper}>
        <PageScroller containerWidth="100%" ref={(c) => { this.pageScroller = c; }}>
          { material.presentation_items.map((item) => {
            const cp = contentPieceStore.contentPieces.find(p => p.uid === `${item.content_piece_id}`);
            return typeof cp === 'undefined' ? null : (
              <div className={classes.itemWrapper} key={`p:${cp.uid}`}>
                <iframe src={`${getApiBase()}/browser/sharing_content_piece/${cp.uid}/`} title={cp.title} width="1024" height="768" scrolling="no" frameBorder="0" className={classes.iframe} />
                <References list={cp.references} />
              </div>
            );
          })}
        </PageScroller>
        <div className={classes.navigationButtons}>
          <Button title="Previous" onClick={this.gotoPreviousPage} disabled={contentPieceStore.currentSlideIndex === 0}>
            <KeyboardArrowUp />
          </Button>
          <Button title="Next" onClick={this.gotoNextPage} disabled={contentPieceStore.currentSlideIndex === material.presentation_items.length - 1}>
            <KeyboardArrowDown />
          </Button>
        </div>
      </div>
    );
  }
}

ShowPresentation.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  contentPieceStore: PropTypes.object.isRequired,
  material: PropTypes.object.isRequired,
};

export default withStyles(styles)(ShowPresentation);
