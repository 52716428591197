import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { simappColors } from '../../../shared/lib/theme';
import CalendarEvent from './calendar-event';
import DateHeader from './date-header';

const styles = {
  calendar: {
    height: '100%',
    marginRight: 8,
    fontFamily: 'helvetica-neue-lt, sans-serif',
    '& .rbc-toolbar': {
      flexDirection: 'row-reverse',
      paddingTop: 16,
      paddingBottom: 8,
    },
    '& .rbc-btn-group': {
      '& button': {
        cursor: 'pointer',
        '&:focus': {
          outline: 'none',
        },
      },
    },
    '& .rbc-toolbar-label': {
      fontWeight: 300,
      fontSize: '1.25rem',
      textAlign: 'left',
    },
    '& .rbc-header ': {
      borderLeft: 'none',
      borderBottom: 'none',
      textAlign: 'start',
      fontWeight: 500,
      color: simappColors.lightGrey,
      textTransform: 'uppercase'
    },
    '& .rbc-month-view': {
      border: 'none',
    },
    '& .rbc-month-row': {
      border: 'none',
    },
    '& .rbc-date-cell': {
      paddingRight: '0',
      textAlign: 'center',
      fontSize: '0.9rem'
    },
    '& .rbc-day-bg': {
      borderLeft: 'none',
    },
    '& .rbc-current div': {
      backgroundColor: simappColors.lightGrey,
      borderRadius: '50%',
      fontWeight: 500,
    },
    '& .rbc-date-cell.rbc-now.rbc-current ': {
      color: simappColors.white,
      fontWeight: 500,
      '& div': {
        backgroundColor: simappColors.azul,
      },
    },
    '& .rbc-now': {
      color: simappColors.black,
      fontWeight: 500,
    },
    '& .rbc-event': {
      borderRadius: 20,
      backgroundColor: simappColors.azul,
    },
    '& .rbc-event-content': {
      display: 'none',
    },
    '& .rbc-month-row > .rbc-row-content > .rbc-row': {
      position: 'absolute',
      width: '100%',
    },
    '& .rbc-today': {
      backgroundColor: 'transparent',
      '& div': {
        backgroundColor: simappColors.azul,
      },
    },
    '& .rbc-off-range-bg': {
      background: 'transparent',
    },
    '& .rbc-show-more': {
      display: 'none',
    },
  },
  navArrow: {
    marginTop: -6,
    marginBottom: -6,
    fontSize: 21
  }
};

const Calendar = (props) => {
  {
    const { events, classes, onSelectEvent, dayChange } = props;
    moment.updateLocale('en', {
      week: {
        dow: 1
      }
    });
    const localizer = BigCalendar.momentLocalizer(moment);
    const components = {
      eventWrapper: CalendarEvent,
      dateHeader: DateHeader,
    };
    return (
      <div className={classes.calendar}>
        <BigCalendar
          components={components}
          views={['month']}
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={onSelectEvent}
          onNavigate={dayChange}
          messages={{
            previous: <KeyboardArrowLeft className={classes.navArrow} />,
            next: <KeyboardArrowRight className={classes.navArrow} />
          }}
        />
      </div>
    );
  }
};

Calendar.propTypes = {
  events: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  onSelectEvent: PropTypes.func,
  dayChange: PropTypes.func,
};

export default withStyles(styles)(Calendar);
