import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import config from 'shared/config';
import { simappColors } from 'shared/lib/theme';
import SimappIcon from 'web/components/_ui/simapp-icon';
import ThermoFooter from 'web/components/_misc/thermo-footer';

const styles = {
  root: {
    textAlign: 'center',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
    // marginTop: '20%'
  },
  thumbnail: {
    margin: 'auto auto 1em auto',
    width: 120,
    height: 120,
    'background-image': 'url(/icons/mask-placeholder@3x.png)',
    'background-size': 'contain',
    '& > img': {
      width: 120,
      height: 120,
      borderRadius: '50%',
    },
    // [isRetina]: {
    //   'background-image': 'url(/icons/mask-placeholder@3x.png)'
    // },
  },
  loginButton: {
    marginTop: '2em'
  },
  footer: {
    margin: 'auto 0 1em 0'
  },
  envelopeRow: {
    marginTop: '1em',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  stageWarning: {
    color: simappColors.strawberry,
    marginTop: '2em',
    '& > a': {
      color: simappColors.azul
    }
  }
};

@inject('applicationStore', 'routing') @observer
class Login extends Component {
  render() {
    // if we know the user, show "welcome back"
    if (this.props.applicationStore.userLoaded) {
      return (
        <div className={this.props.classes.root}>
          <div className={this.props.classes.thumbnail}>
            <img src={this.props.applicationStore.appUser.get('profile_pic')} alt="" />
          </div>
          <Typography variant="h5" gutterBottom>Welcome back</Typography>
          <Typography variant="h4" gutterBottom>{this.props.applicationStore.appUser.get('name')}</Typography>
          <Typography className={this.props.classes.envelopeRow}>
            <SimappIcon icon="envelope" />
            <span>&nbsp; {this.props.applicationStore.appUser.get('email')}</span>
          </Typography>
          { config.isStage && (
            <Typography className={this.props.classes.stageWarning}>This is a testing instance. If you&lsquo;re looking for the production system, <a href={config.prodUrl}>please follow this link</a>.</Typography>)
          }
          <Typography gutterBottom>
            <Button size="large" variant="contained" color="primary" onClick={this.props.applicationStore.login} className={this.props.classes.loginButton}>Log in</Button>
          </Typography>
          <Typography>
            <Button onClick={this.props.applicationStore.loginNew}>Sign in as another user</Button>
          </Typography>
          <ThermoFooter className={this.props.classes.footer} />
        </div>
      );
    }
    return (
      <div className={this.props.classes.root}>
        <div className={this.props.classes.thumbnail} />
        <Typography variant="h3">Welcome</Typography>
        { config.isStage && (
          <Typography className={this.props.classes.stageWarning}>This is a testing instance. If you&lsquo;re looking for the production system, <a href={config.prodUrl}>please follow this link</a>.</Typography>)
        }
        <Typography gutterBottom>
          <Button size="large" variant="contained" color="primary" onClick={() => { this.props.applicationStore.login(false); }} className={this.props.classes.loginButton}>Log in as Thermo Fisher Scientific user</Button>
        </Typography>
        <Typography gutterBottom>You will be redirected to your organisation&#39;s Office365 login page.</Typography>
        <Typography>
          <Button size="large" variant="contained" color="primary" onClick={() => this.props.routing.push('/login')} className={this.props.classes.loginButton}>Log in as external user</Button>
        </Typography>
        {/* <Typography>
          <Button onClick={() => { this.props.applicationStore.login(true); }}>Sign in and grant Outlook access rights</Button>
        </Typography> */}
        <ThermoFooter className={this.props.classes.footer} />
      </div>
    );
  }
}

Login.wrappedComponent.propTypes = {
  applicationStore: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);
