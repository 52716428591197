import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { action, observable } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import simappColors from 'shared/lib/theme/simapp-colors';
import ModalHeader from 'web/components/_ui/modal-header';
import PiecePreview from './piece-preview';

const styles = {
  backdrop: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  root: {
    position: 'absolute',
    left: '12%',
    top: '10%',
    width: '76%',
    height: '80%',
    backgroundColor: simappColors.white,
    border: `1px solid ${simappColors.steelGrey}`,
    display: 'flex',
    flexDirection: 'column'
  },
  droppableArea: {
    padding: '1rem 2rem',
    display: 'flex',
    flexWrap: 'wrap',
    overflowY: 'auto',
    flex: 1,
    alignContent: 'flex-start'
  },
  contentWrapper: {
    width: '14vw',
    margin: '0.5rem',
    backgroundColor: simappColors.white
  },
  thumbnail: {
    width: '14vw',
    height: '10.5vw',
    display: 'block',
    border: `1px solid ${simappColors.steelGrey}`,
  },
  cpTitle: {
    fontWeight: 500,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    margin: '5px 0',
  },
  cpDesciption: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical'

  }
};

const ObservedTile = observer((props) => {
  props.cp.thumbnailUrl();
  return (
    <div className={props.classes.contentWrapper}>
      <div><img src={props.cp.thumbnailUrlHolder.get()} alt="" className={props.classes.thumbnail} /></div>
      { props.draggedIndex !== props.index && (
        <Fragment>
          <Typography className={props.classes.cpTitle}>{props.cp.title}</Typography>
          <Typography className={props.classes.cpDesciption}>{props.cp.description}</Typography>
        </Fragment>
      )}
    </div>
  );
});

@inject('presentationStore', 'contentPieceStore') @observer
class PresentationPreview extends Component {
  @observable previewPiece = null;

  @action setPreviewPiece = (piece) => {
    this.previewPiece = piece;
  }

  @action closePreviewPiece = () => {
    this.previewPiece = null;
  }

  render() {
    const { classes, presentationStore } = this.props;

    if (presentationStore.pieceSelection !== true) {
      return (<Fragment />);
    }
    return (
      <div className={classes.backdrop}>
        <div
          className={`${classes.root} ${this.props.className}`}
          ref={this.root}
        >
          <ModalHeader title={`${presentationStore.currentCharacteristicName} - ${presentationStore.currentSpecialityName}`} />
          <Droppable droppableId="selection-list">
            {provided => (
              <div ref={provided.innerRef} className={classes.droppableArea}>
                { presentationStore.piecesForSelection.map((cp, index) => (
                  <Draggable draggableId={cp.id} key={cp.id} index={index}>
                    {provided2 => (
                      <div
                        onClick={() => { this.setPreviewPiece(cp); }}
                        ref={provided2.innerRef}
                        {...provided2.draggableProps}
                        {...provided2.dragHandleProps}
                      >
                        <ObservedTile cp={cp} classes={classes} index={index} draggedIndex={this.props.draggedIndex} />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <DialogActions>
            <Button onClick={presentationStore.stopPieceSelection} variant="outlined" color="primary">Back to grid</Button>
            <Button onClick={() => { presentationStore.addAllPieces(); presentationStore.stopPieceSelection(); }} variant="contained" color="primary">Add All</Button>
          </DialogActions>
          { this.previewPiece
          && <PiecePreview piece={this.previewPiece} onClose={this.closePreviewPiece} onAdd={() => { presentationStore.addPiece(this.previewPiece); this.closePreviewPiece(); }} />}
        </div>
      </div>
    );
  }
}

PresentationPreview.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  presentationStore: PropTypes.object.isRequired,
  className: PropTypes.string,
  draggedIndex: PropTypes.number
};

PresentationPreview.wrappedComponent.defaultProps = {
  className: '',
  draggedIndex: null
};

export default withStyles(styles)(PresentationPreview);
