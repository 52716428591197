import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { simappColors } from 'shared/lib/theme';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import NumberFormat from 'react-number-format';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import { getShortNameOfAssay } from 'shared/helper/kryptor-helper';
import { PricesCalculation } from 'shared/helper/kryptor-calculationhelper';
import KryptorInputCell from './kryptor-inputcell';
import InputSelectionHelper from '../../../shared/helper/input-selection-helper';

const styles = {
  toggleButtonsSmall: {
    float: 'right'
  },
  toggleButton: {
    width: '120px',
    height: '32px',
    fontWeight: 'normal',
    minHeight: '0',
    margin: '3px 0',
    border: `1px solid ${simappColors.azul}`,
    padding: '0 8px',
    color: `${simappColors.azul}`,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&.selected': {
      backgroundColor: `${simappColors.azul}`,
      color: `${simappColors.white}`
    },
    '&.selected:hover': {
      backgroundColor: `${simappColors.azul}`
    }
  },
  leftToggleButton: {
    borderRadius: '4px 0 0 4px',

  },
  rightToggleButton: {
    borderRadius: '0 4px 4px 0',

  },
  containerHeadlineTextPrices: {
    margin: '0px 200px 24px 56px',
    '&.first': {
      margin: '24px 16px 24px 56px'
    }
  },
  headlineText: {
    fontSize: '28px',
    lineHeight: '40px',
    fontWeight: '300'
  },
  pricesFirstHeader: {
    display: 'inline-block',
    width: '200px',
    lineHeight: '56px'
  },
  table: {
    marginBottom: '47px',
    '&.sublineVisible': {
      marginBottom: '6px'
    }
  },
  tableHeadRow: {
    backgroundColor: `${simappColors.paleGrey}`,
    height: '40px'
  },
  tableHeadCellPrices: {
    fontSize: '10px',
    fontWeight: '500',
    padding: '8px 7px',
    borderBottom: `1px solid ${simappColors.tableBorder}`,
    textAlign: 'center',
    letterSpacing: '0.5pt',
    textTransform: 'uppercase',
    '&.first': {
      padding: '8px 16px 8px 56px',
      textAlign: 'left',
      width: '232px'
    },
    '&.firstResultsTable': {
      padding: '8px 16px 8px 56px',
      textAlign: 'left',
      width: '472px'
    },
    '&.fixedWidth': {
      width: '255px',
      padding: '8px 32px 8px 11px',
      borderLeft: `1px solid ${simappColors.tableBorder}`,
      textAlign: 'right'
    },
    '&.fixedWidthItem': {
      borderLeft: `1px solid ${simappColors.tableBorder}`,
      width: '240px',
      padding: '8px 24px 8px 3px',
      textAlign: 'right'
    },
    '&.fixedWidthHalf': {
      width: '120px',
      padding: '8px 3px 8px 3px',
      borderLeft: `1px solid ${simappColors.tableBorder}`
    },
    '&.resultText': {
      fontWeight: 'bold'
    }
  },
  tableHeadCellTotal: {
    height: '56px',
    borderBottom: `1px solid ${simappColors.tableBorder}`,
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '8px 7px',
    textAlign: 'center',
    '&.first': {
      padding: '8px 16px 8px 56px',
      textAlign: 'left',
      width: '232px',
      fontWeight: 'bold'
    },
    '&.fixedWidth': {
      width: '255px',
      padding: '8px 32px 8px 11px',
      textAlign: 'right',
      fontSize: '18px',
      fontWeight: 'bold'
    }
  },
  tableRowCellPrices: {
    height: '56px',
    borderBottom: `1px solid ${simappColors.tableBorder}`,
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '8px 7px',
    textAlign: 'center',
    '&.first': {
      padding: '8px 16px 8px 56px',
      textAlign: 'left',
      width: '232px',
      fontWeight: '500'
    },
    '&.firstResultsTable': {
      padding: '8px 16px 8px 56px',
      textAlign: 'left',
      width: '472px',
      fontWeight: '500'
    },
    '&.fixedWidth': {
      width: '255px',
      padding: '8px 32px 8px 11px',
      borderLeft: `1px solid ${simappColors.tableBorder}`,
      textAlign: 'right'
    },
    '&.fixedWidthItem': {
      borderLeft: `1px solid ${simappColors.tableBorder}`,
      width: '240px',
      padding: '8px 24px 8px 3px',
      textAlign: 'right'
    },
    '&.fixedWidthHalf': {
      width: '120px',
      padding: '8px 3px 8px 3px',
      borderLeft: `1px solid ${simappColors.tableBorder}`,
      textAlign: 'right'
    },
    '&.backgroundDark': {
      backgroundColor: `${simappColors.paleGrey}`,
      backgroundClip: 'padding-box'
    },
    '&.resultText': {
      fontWeight: 'bold'
    },
    '&.result': {
      fontSize: '18px',
      fontWeight: 'bold'
    },
    '&.white': {
      backgroundColor: `${simappColors.white}`,
      borderLeft: `1px solid ${simappColors.tableBorder}`,
      position: 'relative',
      backgroundClip: 'padding-box',
      '& input': {
        border: 'none',
        width: '100%',
        height: '24px',
        textAlign: 'right',
        fontSize: '14px',
        fontWeight: 'normal',
        fontFamily: 'helvetica-neue-lt, sans-serif',
        paddingRight: '17px',
        caretColor: `${simappColors.azul}`
      },
      '& input:focus': {
        outline: 'none',
      },
      '& span.editIcon': {
        color: `${simappColors.paleGrey}`,
        top: '16px',
        right: '16px',
        position: 'absolute'
      },
    }
  },
  fixedValue: {
    paddingRight: '13px'
  },
  subline: {
    width: '100%',
    height: '24px',
    textAlign: 'right',
    fontSize: '10px',
    color: `${simappColors.steelGrey}`,
    paddingRight: '32px',
    marginBottom: '17px'
  },
  sublineStar: {
    fontSize: '14px'
  },
  numOfTestPanel: {
    backgroundColor: 'transparent',
    boxShadow: 'unset',
    '&::before': {
      backgroundColor: 'transparent'
    },
    '&:last-child': {
      marginBottom: '29px'
    }
  },
  numOfTestPanelSummary: {
    padding: '0',
    minHeight: '56px !important',
    height: '56px',
    marginLeft: '80px',
    fontSize: '17px',
    lineHeight: '56px',
    '& div:last-child': {
      left: '-28px',
      right: 'unset',
      padding: '0'
    },
    '& div:first-child': {
      margin: '0'
    }
  },
  numOfTestPanelDetails: {
    padding: '0'
  },
  numOfTestTableCell: {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '56px',
    borderBottom: `1px solid ${simappColors.paleGrey}`,
    '&.top': {
      borderTop: `1px solid ${simappColors.paleGrey}`
    },
    '&.first': {
      padding: '0 0 0 56px',
      textAlign: 'left'
    },
    '&.last': {
      padding: '0 32px 0 0',
      textAlign: 'right'
    }
  }
};

const MultiControlType = 'multiControl';

@inject('applicationStore', 'kryptorStore') @observer
class KryptorResultPrices extends Component {
  pricesCalculation;
  inputSelectionHelper;
  selectionIndex;

  decimalSeparator = this.props.applicationStore.presentingInIso.toLowerCase() === 'us' ? '.' : ',';
  thousandSeparator = this.props.applicationStore.presentingInIso.toLowerCase() === 'us' ? ',' : '.';
  constructor(props) {
    super(props);
    this.state = {
      isPricesResult: false
    };
    this.pricesCalculation = new PricesCalculation(props.kryptorStore);
    // cause all values to be recalculated
    this.updateResults();
    this.inputSelectionHelper = new InputSelectionHelper();
    this.props.setPdfMode(0);
  }

  getNumberFormatTableCell(hasFixedValue, value, additionalClasses, hasThousandSeparator, ignoreBaseClass) {
    const val = !this.isUS ? value.highNumberFix(2).replace('.', this.decimalSeparator) : value.highNumberFix(2);
    return (
      <TableCell className={`${!ignoreBaseClass ? this.props.classes.tableRowCellPrices : ''} ${additionalClasses}`}>
        <NumberFormat
          value={val}
          decimalScale={2}
          fixedDecimalScale
          decimalSeparator={this.decimalSeparator}
          displayType="text"
          className={hasFixedValue ? this.props.classes.fixedValue : ''}
          thousandSeparator={hasThousandSeparator ? this.thousandSeparator : ''}
        />
      </TableCell>
    );
  }

  getAssaysPricesResults() {
    const { classes, kryptorStore } = this.props;
    return (
      <div>
        <Table className={`${classes.table} ${this.state.isPricesResult ? 'sublineVisible' : ''}`}>
          <TableHead>
            <TableRow className={classes.tableHeadRow}>
              <TableCell className={`${classes.tableHeadCellPrices} first`}>{kryptorStore.getText('KryptorAssayHeadRowAssayName')}</TableCell>
              <TableCell className={`${classes.tableHeadCellPrices} fixedWidthHalf`}>{kryptorStore.getText('KryptorAssayPricesHeadRowReagentKit')}</TableCell>
              <TableCell className={`${classes.tableHeadCellPrices} fixedWidthHalf`}>{kryptorStore.getText('KryptorAssayPricesHeadRowCalKit')}</TableCell>
              <TableCell className={`${classes.tableHeadCellPrices} fixedWidthHalf`}>{kryptorStore.getText('KryptorAssayPricesHeadRowQCKit')}</TableCell>
              <TableCell className={`${classes.tableHeadCellPrices} fixedWidthHalf`}>
                {
                  this.state.isPricesResult ? kryptorStore.getText('KryptorAssayPricesHeadRowResult') : kryptorStore.getText('KryptorAssayPricesHeadRowTest')
                }
              </TableCell>
              <TableCell className={`${classes.tableHeadCellPrices} fixedWidth resultText`}>{kryptorStore.getText('KryptorPricesHeadRowTotalPrice')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {kryptorStore.kryptorAssayList.map((n, index) => (
              this.getAssayPricesResultTableRow(n, index)
            ))}
            <TableRow className={classes.tableRow}>
              <TableCell colSpan={5} className={`${classes.tableRowCellPrices} first resultText`}>{kryptorStore.getText('KryptorAssayPricesTotalCostReagents')}</TableCell>
              {this.getNumberFormatTableCell(false, kryptorStore.totalCostsReagents, 'fixedWidth backgroundDark result', true, false)}
            </TableRow>
          </TableBody>
        </Table>
        {this.state.isPricesResult
          ? (
            <div className={classes.subline}>
              <span className={classes.sublineStar}>* </span>
              {kryptorStore.getText('KryptorAssayPricesSubline')}
            </div>
          )
          : ''}
      </div>
    );
  }

  getKryptorInputCell(cellValue, onValueChange, additionalClasses) {
    this.selectionIndex++;
    return (
      <KryptorInputCell
        inputSelectionHelper={this.inputSelectionHelper}
        index={this.selectionIndex}
        className={`${this.props.classes.tableRowCellPrices} ${additionalClasses}`}
        value={cellValue}
        decimalScale={2}
        fixedDecimalScale
        decimalSeparator={this.decimalSeparator}
        onValueChange={value => onValueChange(value)}
      />
    );
  }

  getAssayPricesResultTableRow(assayItem, index) {
    const { classes } = this.props;
    const assay = assayItem.assay;
    const hasPriceQcKit = this.pricesCalculation.hasPriceQcKit(assayItem);
    const price = this.pricesCalculation.getPrice(assayItem, index, this.state.isPricesResult);
    return (
      <TableRow className={classes.tableRow} key={index}>
        <TableCell className={`${classes.tableRowCellPrices} first`}>{getShortNameOfAssay(assay.title)}</TableCell>
        {this.getKryptorInputCell(assayItem.reagentKitPrice, (value) => {
          this.props.kryptorStore.setAssayReagentKitPrice(value, index);
          this.assayValueChange();
        }, 'white fixedWidthHalf')}
        {this.getKryptorInputCell(assayItem.calKitPrice, (value) => {
          this.props.kryptorStore.setAssayCalKitPrice(value, index);
          this.assayValueChange();
        }, 'white fixedWidthHalf')}
        {
          hasPriceQcKit
            ? (this.getKryptorInputCell(assayItem.qcKitPrice, (value) => {
              this.props.kryptorStore.setAssayQCKitsPrice(value, index);
              this.assayValueChange();
            }, 'white fixedWidthHalf'))
            : (
              <TableCell className={`${classes.tableRowCellPrices} fixedWidthHalf`}>
                <span className={classes.fixedValue}>n/a</span>
              </TableCell>
            )}
        {this.getNumberFormatTableCell(true, price, 'fixedWidthHalf', true, false)}
        {this.getNumberFormatTableCell(false, assayItem.totalPrice, 'fixedWidth backgroundDark', true, false)}
      </TableRow>
    );
  }

  getPricesTableResults(title, type, array, arrayType) {
    const { classes, kryptorStore } = this.props;
    let totalCost = 0;
    if (arrayType === MultiControlType) {
      totalCost = kryptorStore.totalCostMultiControls;
    } else {
      totalCost = kryptorStore.totalCostConsumables;
    }
    return (
      <div>
        {this.getHeaderPrices(title, false)}
        <Table className={`${classes.table}`}>
          <TableHead>
            <TableRow className={classes.tableHeadRow}>
              <TableCell className={`${classes.tableHeadCellPrices} firstResultsTable`}>{type}</TableCell>
              <TableCell className={`${classes.tableHeadCellPrices} fixedWidthItem`}>{kryptorStore.getText('KryptorPricesHeadRowPriceItem')}</TableCell>
              <TableCell className={`${classes.tableHeadCellPrices} fixedWidth`}>{kryptorStore.getText('KryptorPricesHeadRowTotalPrice')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {arrayType === MultiControlType ? array.map((n, index) => this.getNonAssayTableRow(n, index, true)) : Object.keys(array).map(key => this.getNonAssayTableRow(array[key], key, false))}
            <TableRow className={classes.tableRow}>
              <TableCell colSpan={2} className={`${classes.tableRowCellPrices} first resultText`}>
                {`${kryptorStore.getText('KryptorPricesHeadRowTotalCosts')} ${arrayType === MultiControlType ? kryptorStore.getText('KryptorMultiControlsTitle') : kryptorStore.getText('KryptorConsumablesTitle')}`}
              </TableCell>
              {this.getNumberFormatTableCell(false, totalCost, 'fixedWidth backgroundDark result', true, false)}
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }

  getNonAssayTableRow(item, index, isMultiControl) {
    const { classes } = this.props;
    return (
      <TableRow className={classes.tableRow} key={index}>
        <TableCell className={`${classes.tableRowCellPrices} firstResultsTable`}>{isMultiControl ? item.multiControl.name : item.name}</TableCell>
        {this.getKryptorInputCell(item.pricePerItem,
          value => (isMultiControl ? this.multiControlPriceChange(value, index) : this.consumablePriceChange(value, index)),
          'white fixedWidthItem')}
        {this.getNumberFormatTableCell(false, item.totalPrice, 'fixedWidth backgroundDark', true, false)}
      </TableRow>
    );
  }

  getTotalCosts() {
    const { classes, kryptorStore } = this.props;
    const totalCosts = this.pricesCalculation.getTotalCosts();
    return (
      <div>
        {this.getHeaderPrices(kryptorStore.getText('KryptorPricesHeadRowTotalCosts'), false)}
        <Table className={`${classes.table}`}>
          <TableHead>
            <TableRow className={classes.tableHeadRow}>
              <TableCell className={`${classes.tableHeadCellTotal} first`}>{kryptorStore.getText('KryptorPricesHeadRowTotalCosts')}</TableCell>
              {this.getNumberFormatTableCell(false, totalCosts, `${classes.tableHeadCellTotal} fixedWidth`, true, false)}
            </TableRow>
          </TableHead>
        </Table>
      </div>
    );
  }

  getNumberOfTestsRow(isTop, tableRowClass, text, value) {
    return (
      <TableRow className={tableRowClass}>
        <TableCell className={`${this.props.classes.numOfTestTableCell} first ${isTop ? 'top' : ''}`}>{text}</TableCell>
        <TableCell className={`${this.props.classes.numOfTestTableCell} last ${isTop ? 'top' : ''}`}>
          <NumberFormat
            value={value.highNumberFix(0)}
            decimalScale={0}
            fixedDecimalScale
            displayType="text"
          />
        </TableCell>
      </TableRow>
    );
  }

  getNumberofTests() {
    const { classes, kryptorStore } = this.props;
    const totalTestsPerCoverPeriod = this.pricesCalculation.getTotalTestsPerCoverPeriod();
    const totalTestsPerWorkingDay = this.pricesCalculation.getTotalTestsPerWorkingDay();
    return (
      <ExpansionPanel className={classes.numOfTestPanel} key={`${this.state.isPricesResult ? 'priceResult' : 'priceTest'}TotalTests`}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.numOfTestIcon} />} className={classes.numOfTestPanelSummary}>
          <Typography variant="body1">{kryptorStore.getText('KryptorPricesNumberOfTests')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.numOfTestPanelDetails}>
          <Table>
            <TableBody>
              {this.getNumberOfTestsRow(true, classes.numOfTestTableRow, kryptorStore.getText('KryptorPricesNumberOfTestsTotalPerCoverTimePeriod'), totalTestsPerCoverPeriod)}
              {this.getNumberOfTestsRow(false, undefined, kryptorStore.getText('KryptorPricesNumberOfTestsTotalPerWorkingDay'), totalTestsPerWorkingDay)}
            </TableBody>
          </Table>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }

  getHeaderPrices(text, enableSwitch) {
    const { classes, kryptorStore } = this.props;
    return (
      <div className={`${classes.containerHeadlineTextPrices} ${text === 'Assays' ? 'first' : ''}`}>
        <span className={classes.pricesFirstHeader}>
          <Typography className={`${classes.headlineText} prices`}>{text}</Typography>
        </span>
        {enableSwitch
          ? (
            <span className={classes.toggleButtonsSmall}>
              <Button
                className={`${classes.toggleButton} ${classes.leftToggleButton} small ${this.state.isPricesResult ? 'selected' : ''}`}
                onClick={() => this.clickButtonPrices(true)}
              >{kryptorStore.getText('KryptorPricesResultText')}
              </Button>
              <Button
                className={`${classes.toggleButton} ${classes.rightToggleButton} small ${this.state.isPricesResult ? '' : 'selected'}`}
                onClick={() => this.clickButtonPrices(false)}
              >{kryptorStore.getText('KryptorPricesTestText')}
              </Button>
            </span>
          )
          : ''}
      </div>
    );
  }

  multiControlPriceChange = (value, index) => this.nonAssayValueChange(() => this.props.kryptorStore.setMultiControlPricePerItem(value, index),
    () => this.props.kryptorStore.updateMultiControlTotalCost());

  consumablePriceChange = (value, index) => this.nonAssayValueChange(() => this.props.kryptorStore.setConsumablePrice(value, index),
    () => this.props.kryptorStore.updateConsumableTotalCost());

  nonAssayValueChange = (updatePrice, updateTotal) => {
    updatePrice();
    updateTotal();
    this.setState(prevState => ({
      isPricesResult: prevState.isPricesResult
    }));
  }

  assayValueChange = () => {
    this.props.kryptorStore.updateAssayTotalCost();
  }

  clickButtonPrices = (state) => {
    if (this.state.isPricesResult !== state) {
      this.setState({
        isPricesResult: state
      });
      this.props.setPdfMode(state ? 1 : 0);
    }
  }

  updateResults = () => {
    this.props.kryptorStore.updateResults();
  }

  render() {
    this.selectionIndex = -1;
    const { kryptorStore } = this.props;
    return (
      <div>
        {this.getHeaderPrices(kryptorStore.getText('KryptorAssayTitle'), true)}
        {this.getAssaysPricesResults()}
        {this.getPricesTableResults(
          kryptorStore.getText('KryptorMultiControlsTitle'),
          kryptorStore.getText('KryptorMultiControlsHeadRowMultiControlsName'),
          kryptorStore.multiControlList,
          MultiControlType
        )}
        {this.getPricesTableResults(
          kryptorStore.getText('KryptorConsumablesTitle'),
          kryptorStore.getText('KryptorConsumablesHeadRowConsumableName'),
          kryptorStore.consumablesData
        )}
        {this.getTotalCosts()}
        {this.getNumberofTests()}
      </div>
    );
  }
}

KryptorResultPrices.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  applicationStore: PropTypes.object.isRequired,
  kryptorStore: PropTypes.object.isRequired,
  setPdfMode: PropTypes.func.isRequired
};

export default withStyles(styles)(KryptorResultPrices);
