import { observable, runInAction, when, computed, action } from 'mobx';
import { persist } from 'mobx-persist';
import { Q } from '@nozbe/watermelondb';
import rest from 'shared/lib/rest';
import { merge } from 'shared/lib/helpers';
import createStorage from './create-storage';
import database from './database';

const route = '/content_piece';

/* eslint camelcase: 0 */
class ContentPiece {
  constructor(item) {
    if (typeof item === 'object') {
      Object.keys(item).forEach((key) => {
        this[key] = item[key];
      });
    }
  }

  @persist @observable id;
  @persist('list') @observable characteristic_ids = [];
  @persist('list') @observable country_ids = [];
  @persist('list') @observable speciality_ids = [];
  @persist('list') @observable topic_ids = [];
  @persist('list') @observable excluded_pieces = [];
  @persist('list') @observable required_pieces = [];
  @persist('list') @observable references = [];
  @persist @observable content;
  @persist @observable content_type_id;
  @persist @observable description;
  @persist @observable filename;
  @persist @observable language_id;
  @persist @observable mastercontrol_id;
  @persist @observable shareable;
  @persist @observable title;
  @persist @observable usage;
  @persist @observable recommended;
  @persist @observable updated_timestamp;
  @persist @observable thumbnail; // this is the thumbnail URL delivered by the API
  @observable thumbnailUrlHolder; // this is the thumbnail URL for the created Blob
  @computed get thumbnailUrl() {
    if (typeof this.thumbnailUrlHolder === 'undefined') {
      rest.get(`https:${this.thumbnail}`, { responseType: 'blob' }).then((response) => {
        const file = new Blob(
          [response.data],
          { type: 'image/png' }
        );
        runInAction(() => {
          this.thumbnailUrlHolder = URL.createObjectURL(file);
        });
      });
    }
    return this.thumbnailUrlHolder;
  }
}

class ContentPieceStore {
  constructor() {
    createStorage('contentPieceStore', this).then(() => {
      runInAction(() => {
        this.hydrated = true;
      });
    });
  }

  contentTypes = {
    cover: 1,
    disclaimer: 2,
    content: 3
  }

  @observable hydrated = false;
  contentPieces = [];
  @persist('object') @observable localContentStorage = {};
  updatedLocalContentItems = {}; // much faster to keep interactions with observables to a minimum

  getLocalContentItem = async (uid) => {
    const result = await database.collections.get('materialDownload').query(Q.where('uid', uid)).fetch();
    return result.length > 0 ? result[0]._raw : undefined;
  }

  addUpdateLocalContentItem(item) {
    this.updatedLocalContentItems[item.uid] = item;
  }

  loadData(forceReload = false, lastUpdated = null) {
    const startTime = new Date().getTime();
    return new Promise((resolve) => {
      when(
        () => this.hydrated,
        () => {
          let params = '?';
          if (this.hasDataLoaded && !forceReload && lastUpdated !== null) {
            params += `since=${lastUpdated}`;
          // } else if (forceReload) {
          //   runInAction(() => {
          //     this.contentPieces = [];
          //   });
          }

          rest.get(route + params).then((response) => {
            runInAction(() => {
              const result = [];
              response.data.forEach((entry) => {
                if (entry.characteristic_ids === null) entry.characteristic_ids = [];
                if (entry.country_ids === null) entry.country_ids = [];
                if (entry.speciality_ids === null) entry.speciality_ids = [];
                if (entry.topic_ids === null) entry.topic_ids = [];
                if (entry.excluded_pieces === null) entry.excluded_pieces = [];
                if (entry.required_pieces === null) entry.required_pieces = [];
                if (entry.references === null) entry.references = [];
                result.push(new ContentPiece(entry));
              });
              merge(this.contentPieces, result);
              // downloadContentPieces(toJS(this.contentPieces));
              console.log(`${new Date().getTime() - startTime}ms - ${this.constructor.name}`);
              resolve();
            });
          }).catch((error) => {
            console.log('Could not load content pieces', error.response.data);
            resolve();
          });
        }
      );
    });
  }

  get hasDataLoaded() {
    return !!this.contentPieces.length;
  }

  @observable currentSlideIndex = 0;

  @action setCurrentSlideIndex = (index) => {
    this.currentSlideIndex = index;
  }
}

export default new ContentPieceStore();
