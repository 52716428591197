import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import ContentPieceTile from './content-piece-tile';

const SidebarList = inject('presentationStore', 'contentPieceStore')(observer(props => (
  <Fragment>
    { props.presentationStore.contentPieces.map((cp, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <ContentPieceTile piece={cp.item} key={`${cp.item.id}-${index}`} index={index} isDragDisabled={cp.item.content_type_id !== props.contentPieceStore.contentTypes.content} />
    ))}
  </Fragment>
)));

SidebarList.wrappedComponent.propTypes = {
  presentationStore: PropTypes.object.isRequired,
  contentPieceStore: PropTypes.object.isRequired,
};

export default SidebarList;
