import { PDFDocumentFactory, PDFDocumentWriter, drawText, drawImage, drawRectangle } from 'pdf-lib';
import base64 from 'base64-js';

const lightFontBytes = base64.toByteArray(require('shared/lib/resources/HelveticaNeueBase64.json').ttfNormal);
const boldFontBytes = base64.toByteArray(require('shared/lib/resources/HelveticaNeueBase64.json').ttfBold);
const lightItalicFontBytes = base64.toByteArray(require('shared/lib/resources/HelveticaNeueBase64.json').ttfItalic);
const boldItalicFontBytes = base64.toByteArray(require('shared/lib/resources/HelveticaNeueBase64.json').ttfBoldItalic);

const lightFontName = 'light';
const boldFontName = 'bold';
const lightItalicFontName = 'lightItalic';
const boldItalicFontName = 'boldItalic';

const lightArialFontBytes = base64.toByteArray(require('shared/lib/resources/ArialBase64.json').ttfNormal);
const boldArialFontBytes = base64.toByteArray(require('shared/lib/resources/ArialBase64.json').ttfBold);
const lightItalicArialFontBytes = base64.toByteArray(require('shared/lib/resources/ArialBase64.json').ttfItalic);
const boldItalicArialFontBytes = base64.toByteArray(require('shared/lib/resources/ArialBase64.json').ttfBoldItalic);

const lightArialFontName = 'arialLight';
const boldArialFontName = 'arialBold';
const lightItalicArialFontName = 'arialLightItalic';
const boldItalicArialFontName = 'arialBoldItalic';

const webToNativeSizeMultiplicator = 1.333;
const mmToPixelMultiplicator = 3.78;
// eslint-disable-next-line no-unused-vars

export default class QcPdfTemplate {
  width: Number;
  height: Number;

  fontSizeTitle;
  outerMargin;
  footerYPos;
  defaultFontSize;
  headerFontSize;
  tableFontSize;
  termsAndConditionsFontSize;
  pages = [];
  pdf;
  currentPage;
  currentPageNo;
  currentFontSize = this.defaultFontSize;
  currentFont;
  lightFont;
  boldFont;
  lightItalicFont;
  boldItalicFont;
  lightArialFont;
  boldArialFont;
  lightItalicArialFont;
  boldItalicArialFont;
  pageActions;
  currentY;
  qcStore;
  appUser;
  images;
  isNative;

  constructor(width, height, qcStore, appUser, isNative) {
    this.qcStore = qcStore;
    this.appUser = appUser;
    this.width = width;
    this.height = height;
    this.pdf = PDFDocumentFactory.create();
    this.initializeValues();
    this.initializeFonts();
    this.initializeImage(this.images.logo);
    this.createPage();
    this.isNative = isNative;
  }

  initializeValues() {
    this.fontSizeTitle = 11 * webToNativeSizeMultiplicator;
    this.outerMargin = 13 * mmToPixelMultiplicator;
    this.footerYPos = this.outerMargin * 1.7;
    this.headerFontSize = 7 * webToNativeSizeMultiplicator;
    this.tableFontSize = 6 * webToNativeSizeMultiplicator;
    this.termsAndConditionsFontSize = 4 * webToNativeSizeMultiplicator;
    this.defaultFontSize = 11 * webToNativeSizeMultiplicator;
    this.images = { logo: { content: base64.toByteArray(this.qcStore.getPdfData('QcPdfLogoImage').replace('data:image/png;base64,', '')) } };
    this.currentPageNo = 1;
    this.totalPageCount = 0;
  }

  initializeFonts() {
    let [ref, font] = this.pdf.embedNonstandardFont(Uint8Array.from(lightFontBytes));
    this.lightFont = [ref, font, lightFontName];
    [ref, font] = this.pdf.embedNonstandardFont(Uint8Array.from(boldFontBytes));
    this.boldFont = [ref, font, boldFontName];
    [ref, font] = this.pdf.embedNonstandardFont(Uint8Array.from(lightItalicFontBytes));
    this.lightItalicFont = [ref, font, lightItalicFontName];
    [ref, font] = this.pdf.embedNonstandardFont(Uint8Array.from(boldItalicFontBytes));
    this.boldItalicFont = [ref, font, boldItalicFontName];
    this.currentFont = this.lightFont;

    [ref, font] = this.pdf.embedNonstandardFont(Uint8Array.from(lightArialFontBytes));
    this.lightArialFont = [ref, font, lightArialFontName];
    [ref, font] = this.pdf.embedNonstandardFont(Uint8Array.from(boldArialFontBytes));
    this.boldArialFont = [ref, font, boldArialFontName];
    [ref, font] = this.pdf.embedNonstandardFont(Uint8Array.from(lightItalicArialFontBytes));
    this.lightItalicArialFont = [ref, font, lightItalicArialFontName];
    [ref, font] = this.pdf.embedNonstandardFont(Uint8Array.from(boldItalicArialFontBytes));
    this.boldItalicArialFont = [ref, font, boldItalicArialFontName];
  }

  initializeImage(imageObject) {
    const [ref, dimensions] = this.pdf.embedPNG(imageObject.content);
    imageObject.ref = ref;
    imageObject.dimensions = dimensions;
  }

  createPage() {
    if (this.currentPage !== undefined) {
      const contentStream = this.pdf.createContentStream(this.pageActions);
      this.currentPage.addContentStreams(this.pdf.register(contentStream));
      this.pages.push(this.currentPage);
    }
    this.currentPage = this.pdf
      .createPage([this.width, this.height])
      .addFontDictionary(lightFontName, this.lightFont[0])
      .addFontDictionary(boldFontName, this.boldFont[0])
      .addFontDictionary(lightItalicFontName, this.lightItalicFont[0])
      .addFontDictionary(boldItalicFontName, this.boldItalicFont[0])
      .addFontDictionary(lightArialFontName, this.lightArialFont[0])
      .addFontDictionary(boldArialFontName, this.boldArialFont[0])
      .addFontDictionary(lightItalicArialFontName, this.lightItalicArialFont[0])
      .addFontDictionary(boldItalicArialFontName, this.boldItalicArialFont[0])
      .addImageObject('logo', this.images.logo.ref);
    this.pageActions = [];
  }

  createPdf() {
    this.getPageCountWithDryRun();
    this.addHeader();
    this.addContent();
    this.addFooter();
    const contentStream = this.pdf.createContentStream(this.pageActions);
    this.currentPage.addContentStreams(this.pdf.register(contentStream));
    this.pages.push(this.currentPage);
    this.pages.forEach(page => this.pdf.addPage(page));
    const bytes = PDFDocumentWriter.saveToBytes(this.pdf);
    return this.isNative ? base64.fromByteArray(bytes) : bytes;
  }

  getPageCountWithDryRun() {
    this.createPage();
    this.addHeader();
    this.addContent();
    this.addFooter();
    this.totalPageCount = this.pages.length;
    this.pages = [];
    this.pageActions = [];
    this.currentPageNo = 1;
  }

  addHeader() {
    this.currentY = this.height - (this.outerMargin + 5);
    const text = 'Overwrite addHeader method to create your own header';
    this.drawTextToPdf(text, this.width / 2 - this.measureText(text) / 2, this.currentY, undefined, undefined, [0.5, 0.5, 0.5]);
    this.currentY -= 40 * this.mmToPixelMultiplicator;
  }

  addNewPageHeader() {
    this.currentY = this.height - (this.outerMargin + 5);
    const text = 'Overwrite addNewPageHeader method to create your own new page header';
    this.drawTextToPdf(text, this.width / 2 - this.measureText(text) / 2, this.currentY, undefined, undefined, [0.5, 0.5, 0.5]);
    this.currentY -= 40 * this.mmToPixelMultiplicator;
  }

  addContent() {
    this.currentY = this.footerYPos;
    this.currentFontSize = this.defaultFontSize;
    this.currentFont = this.lightFont;
    const text = 'Overwrite addContent method to create your own content';
    this.drawTextToPdf(text, this.width / 2 - this.measureText(text) / 2, this.height / 2, undefined, undefined, [0.5, 0.5, 0.5]);
  }

  addFooter() {
    this.currentY = this.footerYPos;
    this.currentFontSize = this.defaultFontSize;
    this.currentFont = this.lightFont;
    const text = 'Overwrite addFooter method to create your own footer';
    this.drawTextToPdf(text, this.width / 2 - this.measureText(text) / 2, this.currentY, undefined, undefined, [0.5, 0.5, 0.5]);
  }

  drawTextToPdf(text, x, y, xCutOff, rightAligned, color) {
    const textToDraw = text !== undefined ? text : '';
    if (text === undefined) {
      console.log('qc-pdf-template drawTextToPdf: text is undefined.');
    }
    const textWidth = this.measureText(textToDraw);
    const availableSpace = xCutOff === undefined ? this.width : Math.abs(this.width - (2 * this.outerMargin) - xCutOff) - 2;
    const addLineBreak = xCutOff !== undefined && textWidth > availableSpace;
    const textParts = addLineBreak ? this.getFittingTextParts(textToDraw, availableSpace) : [textToDraw];
    if (textParts[0] !== undefined) {
      this.pushTextToActions(textParts[0], this.getXPosForText(textParts[0], x, rightAligned), y, color);
      if (addLineBreak) {
        for (let i = 1; i < textParts.length; i++) {
          if (textParts[i] !== undefined) {
            const rowY = y - ((this.currentFont[1].heightOfFontAtSize(this.currentFontSize) + 2) * i);
            this.currentY = rowY;
            this.pushTextToActions(textParts[i], this.getXPosForText(textParts[i], x, rightAligned), rowY, color);
          }
        }
      }
    }
    return addLineBreak;
  }

  getXPosForText(text, x, rightAligned) {
    return rightAligned ? this.width - this.outerMargin - this.measureText(text) : x;
  }

  getFittingTextParts(text, maxWidth) {
    if (text === undefined) {
      return [];
    }
    const words = text.split(' ');
    const textParts = [];
    let currentText;
    words.forEach((word) => {
      const newText = currentText !== undefined ? `${currentText} ${word}` : `${word}`;
      if (this.measureText(newText) > maxWidth) {
        textParts.push(currentText);
        currentText = word;
      } else {
        currentText = newText;
      }
    });
    textParts[textParts.length] = currentText;
    return textParts;
  }

  getIncreasedY(textSize) {
    const textHeight = this.currentFont[1].heightOfFontAtSize(textSize) + 2;
    return this.getIncreasedYByHeightValue(textHeight);
  }

  getIncreasedYByHeightValue(heightValue) {
    this.currentY -= heightValue;
    if (this.currentY <= this.footerYPos + this.outerMargin) {
      this.addPageBreak();
    }
    return this.currentY;
  }

  pageHasEnoughSpaceFor(textSize, textLines) {
    const heightValue = this.currentFont[1].heightOfFontAtSize(textSize) + 2;
    const newY = this.currentY - heightValue * textLines;
    if (newY <= this.footerYPos + this.outerMargin) {
      return false;
    }
    return true;
  }

  measureText(text) {
    return this.currentFont[1].widthOfTextAtSize(text, this.currentFontSize);
  }

  pushTextToActions(text, x, y, color = [0, 0, 0]) {
    this.pageActions.push(drawText(this.currentFont[1].encodeText(text), { x, y, size: this.currentFontSize, font: this.currentFont[2], colorRgb: color })[0]);
  }

  drawImage(imageName, x, y, width, height) {
    const imageData = drawImage(imageName, { x, y, width, height });
    imageData.forEach(operator => this.pageActions.push(operator));
  }

  drawLineToPdf(x, y, width) {
    const rect = drawRectangle({
      x,
      y,
      width,
      height: 1,
      rotateDegrees: 0,
      borderWidth: 0.5,
      colorRgb: [0, 0, 0],
      borderColorRgb: [1, 1, 1],
    });
    rect.forEach(operator => this.pageActions.push(operator));
  }

  addPageBreak() {
    this.currentY = this.height;
    const usedFont = this.currentFont;
    const usedFontSize = this.currentFontSize;
    this.addFooter();
    this.currentPageNo += 1;
    this.createPage();
    this.addNewPageHeader();
    this.currentFont = usedFont;
    this.currentFontSize = usedFontSize;
  }
}
