import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import simappColors from 'shared/lib/theme/simapp-colors';
import SimappIcon from 'web/components/_ui/simapp-icon';

const styles = {
  contentWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  thumbnail: {
    width: '14vw',
    height: '10.5vw',
    display: 'block',
    border: `1px solid ${simappColors.steelGrey}`,
    backgroundColor: simappColors.white
  },
  index: {
    marginRight: 8,
    '& p': {
      width: '1rem'
    }
  },
  imageWrapper: {
    position: 'relative',
  },
  deleteButton: {
    position: 'absolute',
    top: 5,
    right: 5,
    color: simappColors.strawberry,
    backgroundColor: 'rgba(200, 200, 200, 0.5)',
    borderRadius: '50%',
  }
};

const ObservedImage = observer(({ piece, className }) => {
  piece.thumbnailUrl();
  return (<img src={piece.thumbnailUrlHolder.get()} alt="" className={className} />
  );
});

@inject('presentationStore') @observer
class ContentPieceTileInner extends Component {
  @observable deleteButtonVisible = false;

  @action showDeleteButton = () => {
    this.deleteButtonVisible = true;
  }

  @action hideDeleteButton = () => {
    this.deleteButtonVisible = false;
  }

  @action removePiece = (e, index) => {
    e.stopPropagation();
    this.props.presentationStore.contentPieces.splice(index, 1);
  };

  render() {
    return (
      <div className={this.props.classes.contentWrapper} onMouseEnter={this.showDeleteButton} onMouseLeave={this.hideDeleteButton}>
        <div className={this.props.classes.index}><Typography variant="body2">{this.props.index + 1}</Typography></div>
        <div className={this.props.classes.imageWrapper}>
          <ObservedImage piece={this.props.piece} className={this.props.classes.thumbnail} />
          { !this.props.isDragDisabled && this.deleteButtonVisible
          && (
            <ButtonBase className={this.props.classes.deleteButton} title="Remove slide from presentation" onClick={(e) => { this.removePiece(e, this.props.index); }}>
              <SimappIcon icon="x" title="Remove slide from presentation" />
            </ButtonBase>
          )
          }
        </div>
      </div>
    );
  }
}

ContentPieceTileInner.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  presentationStore: PropTypes.object.isRequired,
  piece: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  isDragDisabled: PropTypes.bool.isRequired
};

ContentPieceTileInner.wrappedComponent.defaultProps = {
};

export default withStyles(styles)(ContentPieceTileInner);
