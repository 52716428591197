import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { simappConstants } from 'shared/lib/theme';
import PctNavButton from './pct-navbutton';

const styles = {
  root: {
    width: '100%',
    height: '100vH',
    display: 'flex',
    flexDirection: 'column',
    padding: simappConstants.contentMarginLarge,
    overflowX: 'hidden',
    fontFamily: 'helvetica-neue-lt, sans-serif'
  },
  bottomBar: {
    width: '100%',
    marginTop: '60px',
    paddingBottom: '30px'
  },
  textContainer: {
    marginTop: '20px',
    marginBottom: '40px',
  },
  image: {
    display: 'block',
    maxWidth: '95%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
};

@inject('pctStore', 'routing') @observer
class PctInfo extends Component {
  title;
  imagePath;

  constructor(props) {
    super(props);
    this.title = props.pctStore.getInfoTitle();
    this.imagePath = `/images/${props.pctStore.getImageName()}`;
  }

  render() {
    const { goBack } = this.props.routing;
    return (
      <div className={this.props.classes.root}>
        <div>
          <div className={this.props.classes.textContainer}>
            <Typography variant="h5" align="center" gutterBottom>{this.title}</Typography>
          </div>
          <img src={this.imagePath} className={this.props.classes.image} alt={this.imagePath} />
        </div>
        <div className={this.props.classes.bottomBar}>
          <PctNavButton nextOnClick={() => { goBack(1); }} nextText="Back" />
        </div>
      </div>
    );
  }
}

PctInfo.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  pctStore: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired,
};

export default withStyles(styles)(PctInfo);
