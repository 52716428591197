import { action, observable } from 'mobx';
import { qcStore } from 'shared/stores';
import BaseForm from './base-form';

class QcNewCustomerForm extends BaseForm {
  @observable
  fields = {
    customerName: {
      value: '',
      error: null,
      rule: 'required',
      required: true,
      label: 'Customer Name',
      placeholder: 'Customer Name (Lab / Hospital)'
    },
    customerNumber: {
      value: '',
      error: null,
      rule: 'string',
      required: false,
      label: 'Customer Number',
      placeholder: 'Customer Number'
    },
    contactName: {
      value: '',
      error: null,
      rule: 'required',
      required: true,
      label: 'Contact Name',
      placeholder: 'Contact Name'
    },
    contactTitle: {
      value: '',
      error: null,
      rule: 'string',
      required: false,
      label: 'Contact Title',
      placeholder: 'Contact Title'
    },
    contactEmail: {
      value: '',
      error: null,
      required: true,
      label: 'Contact Email',
      rule: 'required|email',
      placeholder: 'Email'
    },
    contactPhone: {
      value: '',
      error: null,
      label: 'Phone',
      rule: 'string',
      placeholder: 'Please also provide the country code, e.g. +49'
    },
    country: {
      value: '',
      error: null,
      label: 'Region',
      rule: 'required',
      required: true,
      placeholder: 'Region'
    },
    workingDaysPerWeek: {
      value: 5,
      error: null,
      label: 'Working Days in a Week',
      rule: 'required|integer|between:1,7',
      required: true
    },
    contractPeriodInMonths: {
      value: 12,
      error: null,
      label: 'Contract Period (Months)',
      rule: 'required|integer|min:1',
      required: true
    },
    customerDescription: {
      value: '',
      error: null,
      label: 'Customer Description',
      rule: 'string',
      placeholder: 'Description'
    }
  }

  defaultValues = {
    customerName: '',
    customerNumber: '',
    contactName: '',
    contactTitle: '',
    contactEmail: '',
    contactPhone: '',
    country: '',
    currency: '',
    workingDaysPerWeek: 5,
    contractPeriodInMonths: 12,
    customerDescription: ''
  };

  @action loadValuesFromStorage = () => {
    const entries = Object.entries(qcStore.customerData);
    entries.forEach((item) => {
      const key = item[0];
      const value = item[1];
      if (typeof this.fields[key] !== 'undefined') {
        this.fields[key].value = value;
        this.onFieldChange({ target: { id: key, value } });
      }
    });
  }

  @action resetToDefaultValues = () => {
    const keys = Object.keys(this.fields);
    keys.forEach((key) => {
      if (typeof this.fields[key] !== 'undefined') {
        const value = this.defaultValues[key];
        qcStore.customerData[key] = value;
        this.onFieldChange({ target: { id: key, value } });
        this.fields[key].error = '';
      }
    });
    qcStore.customerData.proposalTemplate = undefined;
  }

  @action onNativeFieldChange = (e) => {
    this.fields[e.fieldName].value = e.value;
  }

  @action onDropDownFieldChange = (id, field = 'country') => {
    const e = {};
    e.target = {};
    e.target.name = field;
    e.target.value = id;
    this.onFieldChange(e);
  }

  @action save = () => {
    this.saveInProgess = true;
    // trigger validation
    const keys = Object.keys(this.fields);
    keys.map((key) => {
      const value = this.fields[key].value;
      this.onFieldChange({ target: { id: key, value } });
    });

    if (this.isValid) {
      Object.keys(this.fields).forEach((key) => {
        qcStore.customerData[key] = this.fields[key].value;
      });
    }
    this.saveInProgess = false;
  }
}

export default new QcNewCustomerForm();
