import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { observer } from 'mobx-react';

const AddressInputField = observer((props) => {
  const { classes, fields, name, onChange } = props;
  return (
    <div style={classes.divWrapper}>
      <Grid container spacing={24}>
        <Grid item xs={4}>
          <div style={classes.labelWrapper}>
            <InputLabel htmlFor={name} style={classes.label}>{fields[name].label}</InputLabel>
          </div>
        </Grid>
        <Grid item xs={8}>
          <TextField
            inputRef={ref => props.inputSelectionHelper.registerInput(ref)}
            onKeyDown={e => props.inputSelectionHelper.keyDown(e)}
            onFocus={() => props.inputSelectionHelper.setCurrent(props.index)}
            name={name}
            style={classes.textfield}
            InputProps={{ disableUnderline: true }}
            id={name}
            type="text"
            onChange={onChange}
            fullWidth
            placeholder={fields[name].placeholder}
            value={fields[name].value}
          />
        </Grid>
      </Grid>
    </div>
  );
});

AddressInputField.propTypes = {
  classes: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  inputSelectionHelper: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};

export default AddressInputField;
