import { observable, action, runInAction } from 'mobx';
import { persist } from 'mobx-persist';
import moment from 'moment';
import { applicationStore } from 'shared/stores';
import createStorage from 'shared/stores/create-storage';
import config from 'shared/config';
import rest from 'shared/lib/rest';

const refreshInterval = 300; // seconds
let intervalHolder;

const loginRoute = `${config.restEndpoint}/oauth`;

class OauthRequest {
  @persist('object') @observable oauthData = {
    isAuthenticated: false, accessToken: null, accessTokenExpiresAt: null, refreshToken: null, refreshTokenExpiresAt: null, userName: ''
  };

  @action resetOauthData = () => {
    this.oauthData = {
      isAuthenticated: false, accessToken: null, accessTokenExpiresAt: null, refreshToken: null, refreshTokenExpiresAt: null, userName: ''
    };
  }

  isAuthenticated = async () => {
    if (!this.oauthData.isAuthenticated) throw new Error('no user is logged in');
    // check if token is expired, then try to refresh
    const now = Math.floor(+new Date() / 1000);
    if (now + refreshInterval < this.oauthData.accessTokenExpiresAt) {
      this.startCheckTokenInterval();
      return true;
    }
    const refreshSuccess = await this.refreshToken();
    return refreshSuccess;
  };

  async login(username, password) {
    this.resetOauthData();
    const result = await fetch(loginRoute, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&grant_type=password&client_id=1`
    });
    const jsonResult = await result.json();
    if (jsonResult.success) {
      runInAction(() => {
        this.oauthData = { ...jsonResult, isAuthenticated: true, userName: username };
        // FIXME: This is also done in ApplicationStore, but later.
        // We need it here, too, in order to request the user in login-azure.js
        rest.defaults.headers.common.Authorization = this.oauthData.accessToken;
      });
      this.startCheckTokenInterval();
      return true;
    }
    return jsonResult.message;
  }

  @action logout = () => {
    clearInterval(intervalHolder);
    this.resetOauthData();
    applicationStore.reset();
  }

  startCheckTokenInterval = () => {
    intervalHolder = setInterval(() => {
      this.refreshToken();
    }, refreshInterval * 1000);
  };

  refreshToken = async () => {
    if (!this.oauthData.refreshToken) {
      console.log('no refresh token given, user is not logged in');
      return false;
    }
    if (this.oauthData.accessTokenExpiresAt > Math.floor(+new Date() / 1000) + refreshInterval) {
      console.log('token still valid');
      return true;
    }
    const result = await fetch(loginRoute, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: `refresh_token=${this.oauthData.refreshToken}&grant_type=refresh_token&client_id=1`
    });
    const jsonResult = await result.json();
    if (jsonResult.success) {
      runInAction(() => {
        this.oauthData = { ...this.oauthData, ...jsonResult };
        rest.defaults.headers.common.Authorization = this.oauthData.accessToken;
        console.log(`Refreshed token. Valid until ${moment.utc(this.oauthData.accessTokenExpiresAt).format('YYYY-MM-DD HH:mm:ss')}`);
      });
      return true;
    }
    console.log('failed to refresh token; log out');
    applicationStore.logout(true);
    return jsonResult.message;
  }
}

const OauthStore = new OauthRequest();
export default OauthStore;
createStorage('oauthStore', OauthStore).then(() => {});
