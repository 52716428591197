import { action, observable } from 'mobx';
import { kryptorStore } from 'shared/stores';
import BaseForm from './base-form';

class AddressForm extends BaseForm {
  @observable
  fields = {
    streetAndNumber: {
      value: '',
      error: null,
      label: 'Street and number',
      placeholder: 'e.g. Warschauer Str. 39',
      rule: 'string'
    },
    postalCode: {
      value: '',
      error: null,
      rule: 'string',
      label: 'Postal code',
      placeholder: 'e.g. 10238'
    },
    city: {
      value: '',
      error: null,
      label: 'City',
      rule: 'string',
      placeholder: 'e.g.  Berlin'
    },
    country: {
      value: '',
      error: null,
      label: 'Region',
      rule: 'string',
      required: true,
      placeholder: 'e.g. Germany'
    }
  }

  @action setDefaultValues = () => {
    const storedValues = kryptorStore.customerData.address;
    if (storedValues !== null && typeof storedValues !== 'undefined') {
      Object.entries(storedValues).forEach((item) => {
        const key = item[0];
        const value = item[1].value;
        if (typeof key !== 'undefined') {
          if (typeof value !== 'undefined') {
            this.onFieldChange({ target: { id: key, value } });
          } else {
            // reset field
            this.fields[key].value = '';
          }
        }
      });
    }
  };

  @action onNativeFieldChange = (e) => {
    this.fields[e.id].value = e.value;
  }

  @action save = () => {
    // clone fields object to prevent kryptorStore from directly binding to fields object
    const copy = {
      streetAndNumber: {
        value: ''
      },
      postalCode: {
        value: ''
      },
      city: {
        value: '',
      },
      country: {
        value: '',
      }
    };
    copy.streetAndNumber.value = this.fields.streetAndNumber.value;
    copy.postalCode.value = this.fields.postalCode.value;
    copy.city.value = this.fields.city.value;
    copy.country.value = this.fields.country.value;
    kryptorStore.setCustomerAddress(copy);
  }

  @action reset = () => {
    this.fields.streetAndNumber.value = '';
    this.fields.postalCode.value = '';
    this.fields.city.value = '';
    this.fields.country.value = '';
  }
}

export default new AddressForm();
