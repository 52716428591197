import { persist } from 'mobx-persist';
import { observable, runInAction, computed, action } from 'mobx';
import rest from 'shared/lib/rest';
import { merge } from 'shared/lib/helpers';
import createStorage from './create-storage';

const route = '/qcvc_proposal';

class QcCustomerStore {
  @persist('list') @observable customerData = [];
  @persist('list') customerDataUpdate = [];
  updateInProgress = false;

  constructor() {
    createStorage('qcCustomerStore', this);
  }

  @computed get hasDataLoaded() {
    return !!this.customerData.length;
  }

  // eslint-disable-next-line
  @action loadData(forceReload = false, lastUpdated = null) {
    const startTime = new Date().getTime();
    return new Promise((resolve) => {
      let params = '?';
      if (this.hasDataLoaded && !forceReload && lastUpdated !== null) {
        params += `since=${lastUpdated}`;
      }

      rest.get(route + params).then((response) => {
        runInAction(() => {
          merge(this.customerData, response.data, 'id', 'customer_name');
          console.log(`${new Date().getTime() - startTime}ms - ${this.constructor.name}`);
          resolve();
        });
      }).catch((error) => {
        console.log('Error object', error);
        console.log('Could not load qcvc customer data', error.response);
        resolve();
      });
    });
  }

  updateData() {
    return new Promise((resolve) => {
      if (!this.updateInProgress) {
        this.updateInProgress = true;
        this.customerDataUpdate.map((item) => {
          this.uploadItem(item);
        });
        this.customerDataUpdate = [];
        this.updateInProgress = false;
      }
      resolve();
    });
  }

  uploadItem = (item) => {
    const params = '/';
    return new Promise((resolve, reject) => {
      if (item.id !== undefined) {
        rest.put(route + params + item.id, item).then((response) => {
          runInAction(() => {
            if (response.data.success) {
              resolve();
            } else {
              console.log('Could not upload put qcvc customer data', response);
              reject();
            }
          });
        }).catch((error) => {
          console.log('Error object', error);
          console.log('Could not upload put qcvc customer data', error.response.data);
          reject();
        });
      } else {
        rest.post(route, item).then((response) => {
          runInAction(() => {
            if (response.data.success) {
              item.id = response.data.id;
              resolve();
            } else {
              console.log('Could not upload put qcvc customer data', response);
              reject();
            }
          });
        }).catch((error) => {
          console.log('Error object', error);
          console.log('Could not upload post qcvc customer data', error.response.data);
          reject();
        });
      }
    });
  }

  saveCustomer = item => new Promise((resolve) => {
    this.customerDataUpdate = [];
    this.uploadItem(item).then(() => {
      this.loadData().then(() => {
        resolve();
      });
    }).catch((error) => {
      this.customerDataUpdate.push(item);
      console.log('Error object', error);
      resolve();
    });
  })

  deleteCustomer = id => new Promise((resolve, reject) => {
    console.log('delete called');
    rest.delete(`${route}/${id}`).then((response) => {
      runInAction(() => {
        if (response.data.success) {
          const index = this.customerData.findIndex(t => t.id === id);
          this.customerData.splice(index, 1);
          resolve();
        }
      });
    }).catch((error) => {
      console.log('Error object', error);
      console.log('Could not delete qcvc customer data', error.response.data);
      reject();
    });
  })
}

export default new QcCustomerStore();
