import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import BaseOverlay from './base-overlay';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5em',
  },
  labelWrapper: {
    width: '25%',
    display: 'flex',
    position: 'relative',
    minWidth: '210px'
  },
  label: {
    position: 'static',
    transform: 'none',
    alignSelf: 'center',
    whiteSpace: 'nowrap'
  },
  errorMessage: {
    whiteSpace: 'nowrap',
    position: 'absolute',
    bottom: -14
  },
  selectButton: {
    flex: 1,
    paddingLeft: 6,
    transition: 'none',
    '&:hover': {
      background: 'transparent'
    },
    '& span': {
      justifyContent: 'left',
      fontSize: '1rem'
    }
  }
});

@observer
class SelectField extends Component {
  @observable listOpen = false;

  @action openSelectionMenu = () => {
    this.listOpen = true;
  }

  @action closeSelectionMenu = () => {
    this.listOpen = false;
  }

  render() {
    const { fields, name, onChange, currentSelect, currentValue, list, valueField } = this.props;

    return (
      <FormControl
        error={!!fields[name].error}
        disabled={fields[name].disabled}
        required={fields[name].required}
        className={this.props.classes.root}
      >
        <div className={this.props.classes.labelWrapper}>
          <InputLabel htmlFor={name} disableAnimation shrink={false} className={this.props.classes.label}>{fields[name].label}</InputLabel>
          <FormHelperText id={`${name}-error`} className={this.props.classes.errorMessage}>{fields[name].error}</FormHelperText>
        </div>
        <Button color="primary" className={`${this.props.classes.selectButton} ${currentValue.length ? '' : { color: this.props.placeholderTextColor }}`} onClick={this.openSelectionMenu} disableRipple disableFocusRipple>{currentValue || fields[name].placeholder}</Button>
        <BaseOverlay
          open={this.listOpen}
          onClose={this.closeSelectionMenu}
          onSelect={(id) => { onChange(id); }}
          currentSelect={currentSelect}
          label={fields[name].label}
          list={list}
          valueField={valueField}
        />
      </FormControl>
    );
  }
}

SelectField.propTypes = {
  classes: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  currentSelect: PropTypes.number,
  currentValue: PropTypes.string,
  valueField: PropTypes.string,
  placeholderTextColor: PropTypes.string
};

SelectField.defaultProps = {
  currentSelect: null,
  currentValue: '',
  valueField: 'value',
  placeholderTextColor: ''
};

export default withStyles(styles)(SelectField);
