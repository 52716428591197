import { tableSchema } from '@nozbe/watermelondb';
import { field, date } from '@nozbe/watermelondb/decorators';
import Model from './simapp-model';

export const materialDownloadSchema = tableSchema({
  name: 'materialDownload',
  columns: [
    { name: 'uid', type: 'string', isIndexed: true },
    { name: 'document', type: 'string' },
    { name: 'thumbnail', type: 'string' },
    { name: 'created_at', type: 'number' },
    { name: 'updated_at', type: 'number' }
  ]
});

export default class MaterialDownload extends Model {
  static table = 'materialDownload'

  @field('uid') uid
  @field('document') document
  @field('thumbnail') thumbnail
  @date('created_at') createdAt
  @date('updated_at') updatedAt
}
