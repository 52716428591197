/* eslint-disable no-mixed-operators */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { simappColors } from 'shared/lib/theme';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import BaseOverlay from 'web/components/_ui/form-elements/base-overlay';
import { isAnyInstrumentsDataTestEnabled, getItemFromList } from 'shared/helper/qc-helper';
import SimAppButton from 'web/components/_ui/simapp-button';
import ConditionalRerenderWrapper from 'web/components/_ui/conditional-rerenderwrapper';
import QcInstrumentTable from './qc-instrument-table';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontWeight: 'normal'
  },
  borderlessExpansionPanel: {
    '&::before': { content: 'none' },
    marginBottom: '2px',
    boxShadow: 'unset',
    backgroundColor: 'unset'
  },
  expansionContent: {
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  headline: {
    flexDirection: 'row',
    justifyContent: 'start',
    display: 'flex'
  },
  instrumentHeadline: {
    flexDirection: 'row',
    justifyContent: 'start',
    display: 'flex',
    marginBottom: '10px'
  },
  instrumentLabel: {
    lineHeight: '36px',
    padding: 'unset',
    margin: 'unset',
    paddingLeft: '5px',
    paddingRight: '5px'
  },
  buttonRow: {
    width: '100%',
    justifyContent: 'start',
    alignItems: 'left',
    display: 'flex',
    flexDirection: 'row',
    marginTop: '30px'
  },
  button: {
    marginLeft: '5px',
    marginRight: '5px'
  },
  seperator: {
    color: `${simappColors.steelGrey}`,
    width: '100%'
  },
  instrumentSelectButton: {
    display: 'inline',
    paddingHorizontal: '5px',
    cursor: 'pointer',
    marginTop: '-5px',
    backgroundColor: 'transparent',
    color: `${simappColors.azul}`,
    '&:hover': {
      backgroundColor: 'unset'
    }
  },
  removeImageButton: {
    padding: '0',
    width: '24px',
    height: '24px',
    minWidth: 'unset',
    marginLeft: '5px',
    marginTop: '5px',
    minHeight: 'unset'
  },
  removeImage: {
    width: '24px',
    height: '24px',
    backgroundColor: 'transparent',
    '&:hover, &:focus, &:active': {
      backgroundColor: 'transparent'
    },
    '& span:first-child span': {
      display: 'none',
    }
  }
};

const removeImagePath = '/images/kryptorAssayRemove.png';

@inject('applicationStore', 'qcTestRequestForm', 'qcStore', 'routing') @observer
class QcInstrument extends Component {
  rerenderName = undefined;

  constructor(props) {
    super(props);
    this.state = {
      displayAll: true,
      showSelectInstrumentDialog: false,
      enableAll: true,
      selectReagentDialogIndex: -1,
      periodSelection: { testIndex: -1, levelIndex: -1 },
      showPeriodMultiSelection: false,
      showLevelMultiSelection: false,
      showRunsMultiSelection: false
    };
    this.currentSelect = [];
  }

  componentDidMount() {
    const { qcStore, isAnyTestEnabledCallback } = this.props;
    isAnyTestEnabledCallback(isAnyInstrumentsDataTestEnabled(qcStore.instrumentsData));
  }

  onInstrumentSelect = () => {
    this.setState({
      showSelectInstrumentDialog: true
    });
  }

  onPeriodSelect = (testIndex, levelIndex) => {
    this.setState({
      periodSelection: { testIndex, levelIndex }
    });
  }

  onTestRunsInputChange = (value, indexTest, levelIndex) => {
    const { index, qcStore, isAnyTestEnabled, isAnyTestEnabledCallback } = this.props;
    if (value !== undefined) {
      if (this.props.data.tests[indexTest].levels[0].nbRuns !== value && !levelIndex
        || levelIndex !== undefined && this.props.data.tests[indexTest].levels[levelIndex].nbRuns !== value) {
        qcStore.updateInstrumentTestRuns(index, indexTest, value, levelIndex);
      }
    }
    if (!isAnyTestEnabled || value <= 0) {
      isAnyTestEnabledCallback(isAnyInstrumentsDataTestEnabled(qcStore.instrumentsData));
    }
  }

  onTestPeriodSelectionChange = (periodId, indexTest, levelIndex) => {
    const { index, qcStore } = this.props;
    if (this.props.data.tests[indexTest].levels[0].period !== periodId && !levelIndex
      || levelIndex !== undefined && this.props.data.tests[indexTest].levels[levelIndex].period !== periodId) {
      qcStore.updateInstrumentTestPeriod(index, indexTest, periodId, levelIndex);
    }
  }

  onTestSelectedChange = (checked, indexTest, levelIndex) => {
    const { index, qcStore, isAnyTestEnabled, isAnyTestEnabledCallback } = this.props;
    if (this.props.data.tests[indexTest].isEnabled !== checked && levelIndex === undefined
      || levelIndex !== undefined && this.props.data.tests[indexTest].levels[levelIndex].isEnabled !== checked) {
      qcStore.updateInstrumentTestSelection(index, indexTest, checked, levelIndex);
    }
    if (!isAnyTestEnabled || !checked) {
      isAnyTestEnabledCallback(isAnyInstrumentsDataTestEnabled(qcStore.instrumentsData));
    }
  }

  onSeparate = (indexTest) => {
    const { index, qcStore, isAnyTestEnabledCallback } = this.props;
    qcStore.updateInstrumentTestSeparation(index, indexTest);
    isAnyTestEnabledCallback(false);
  }

  onReagentSelect = (testIndex) => {
    this.setState({ selectReagentDialogIndex: testIndex });
  }

  onReagentSelectionFinished = (instrumentIndex, testIndex, reagentCatalogNumber) => {
    const { qcStore, isAnyTestEnabled, isAnyTestEnabledCallback } = this.props;
    qcStore.updateInstrumentTestReagentSelection(instrumentIndex, testIndex, reagentCatalogNumber);
    if (!isAnyTestEnabled) {
      isAnyTestEnabledCallback(isAnyInstrumentsDataTestEnabled(qcStore.instrumentsData));
    }
  }

  getButton = (text, method) => (
    <SimAppButton className={this.props.classes.button} onClick={() => method()} inline small>{text}</SimAppButton>
  )

  enableStandardTests = () => {
    const { index, qcStore, isAnyTestEnabled, isAnyTestEnabledCallback } = this.props;
    qcStore.updateInstrumentEnableDefaultTests(index);
    if (!isAnyTestEnabled || !this.state.enableAll) {
      isAnyTestEnabledCallback(isAnyInstrumentsDataTestEnabled(qcStore.instrumentsData));
    }
  }

  toggleDisplayedTests = () => {
    this.setState(prevState => ({
      displayAll: !prevState.displayAll
    }));
  }

  toggleEnableAllTests = () => {
    const { index, qcStore, isAnyTestEnabled, isAnyTestEnabledCallback } = this.props;
    qcStore.updateInstrumentAllTestsState(index, this.state.enableAll);
    this.setState(prevState => ({
      enableAll: !prevState.enableAll
    }));
    if (!isAnyTestEnabled || !this.state.enableAll) {
      isAnyTestEnabledCallback(isAnyInstrumentsDataTestEnabled(qcStore.instrumentsData));
    }
  }

  onPeriodRunsMultiSelectionChange = (periodId, runs) => {
    const { index, qcStore } = this.props;
    qcStore.updateInstrumentAllTestsPeriod(index, periodId);
    qcStore.updateInstrumentAllTestsRuns(index, runs);
    this.setState(prevState => ({ refreshFlatList: !prevState.refreshFlatList }));
  }

  multiSelectRunsPeriod = () => {
    this.setState({
      showRunsMultiSelection: true
    });
    this.setState(prevState => ({ refreshFlatList: !prevState.refreshFlatList }));
  }

  onLevelMultiSelectionChange = (levels) => {
    const { index, qcStore } = this.props;
    if (!levels || levels.length === 0) {
      return;
    }
    const testLevelObj = [];
    levels.forEach((level) => {
      testLevelObj[level] = 1;
    });
    this.currentSelect = levels;
    qcStore.updateInstrumentAllSelectedTestsSelectLevels(index, testLevelObj);
    this.setState(prevState => ({ refreshFlatList: !prevState.refreshFlatList }));
  }

  multiSelectLevel = () => {
    this.setState({
      showLevelMultiSelection: true
    });
    this.setState(prevState => ({ refreshFlatList: !prevState.refreshFlatList }));
  }

  duplicateInstrument = () => {
    const { index, qcStore } = this.props;
    qcStore.duplicateListInstrument(index);
  }

  requestTest = () => {
    const { qcTestRequestForm, applicationStore } = this.props;
    qcTestRequestForm.resetToDefaultValues();
    qcTestRequestForm.setValues(this.props.data);
    applicationStore.toggleTestRequestDialog();
  }

  getSelectedPeriod = (test, levelIndex) => {
    if (test !== undefined) {
      if (levelIndex >= 0) {
        return test.levels[levelIndex].period;
      }
      return test.levels[0].period;
    }
    return undefined;
  }

  renderSelectInstrumentDialog = () => {
    const { qcStore } = this.props;
    return (
      <ConditionalRerenderWrapper rerender={this.rerenderName === 'instrument_select' || this.state.showSelectInstrumentDialog}>
        <BaseOverlay
          open={this.state.showSelectInstrumentDialog}
          onClose={() => {
            this.rerenderName = 'instrument_select';
            this.setState({ showSelectInstrumentDialog: false });
          }}
          onSelect={(id) => {
            const { isAnyTestEnabledCallback } = this.props;
            if (this.props.data.instrument === undefined || id !== this.props.data.instrument.id) {
              qcStore.updateInstrumentInstrument(this.props.index, getItemFromList(id, qcStore.getInstrumentsSelectData()));
              qcStore.updateListInstrumentTests(this.props.index);
              isAnyTestEnabledCallback(isAnyInstrumentsDataTestEnabled(qcStore.instrumentsData));
            }
          }}
          currentSelect={this.props.data.instrument.id ? this.props.data.instrument.id : ''}
          labelPrefix=""
          label={qcStore.getText('QCProductSelectionTestViewInstrumentSelection')}
          list={qcStore.getInstrumentsSelectData()}
          valueField="name"
        />
      </ConditionalRerenderWrapper>
    );
  }

  renderSelectPeriodDialog = (tests) => {
    const { qcStore } = this.props;
    return (
      <ConditionalRerenderWrapper rerender={this.rerenderName === 'period_select' || this.state.periodSelection.testIndex >= 0}>
        <BaseOverlay
          open={this.state.periodSelection.testIndex >= 0}
          onClose={() => {
            this.rerenderName = 'period_select';
            this.setState({ periodSelection: { testIndex: -1, levelIndex: -1 } });
          }}
          onSelect={(id) => {
            this.onTestPeriodSelectionChange(id, this.state.periodSelection.testIndex, this.state.periodSelection.levelIndex);
          }}
          currentSelect={this.getSelectedPeriod(tests[this.state.periodSelection.testIndex], this.state.periodSelection.levelIndex)}
          label={qcStore.getText('QCProductSelectionTestViewInstrumentPeriodTableHeadline')}
          list={qcStore.periodData}
          valueField="id"
          labelField="id"
        />
      </ConditionalRerenderWrapper>
    );
  }

  renderMultiSelectPeriodDialog = () => {
    const { qcStore } = this.props;
    return (
      <BaseOverlay
        open={this.state.showPeriodMultiSelection}
        onClose={() => {
          this.setState({ showPeriodMultiSelection: false });
        }}
        onSelect={
          (id) => {
            this.onPeriodRunsMultiSelectionChange(id, this.state.runsSelection);
          }
        }
        labelPrefix=""
        label={qcStore.getText('QCProductSelectionTestViewSetFrequencyForSelectionStepTwo')}
        list={qcStore.periodData}
        valueField="id"
        labelField="id"
      />
    );
  }

  renderMultiSelectRunsDialog = () => {
    const { qcStore } = this.props;
    return (
      <BaseOverlay
        open={this.state.showRunsMultiSelection}
        onClose={() => {
          this.setState({ showRunsMultiSelection: false });
        }}
        onSelect={
          (id) => {
            this.setState({
              runsSelection: id,
              showPeriodMultiSelection: true
            });
          }
        }
        labelPrefix=""
        label={qcStore.getText('QCProductSelectionTestViewSetFrequencyForSelectionStepOne')}
        list={qcStore.runsData}
        valueField="id"
        labelField="id"
      />
    );
  }

  renderMultiSelectLevelDialog = () => {
    const { qcStore, index } = this.props;
    return (
      <BaseOverlay
        multiSelect
        open={this.state.showLevelMultiSelection}
        onClose={() => {
          this.setState({ showLevelMultiSelection: false });
        }}
        onSelect={(levels) => { this.onLevelMultiSelectionChange(levels); }}
        currentSelect={Array.from(this.currentSelect)}
        label="Levels"
        list={qcStore.getAllLevelsOfSelectedTests(index)}
        valueField="id"
        labelField="id"
      />
    );
  }

  renderSelectReagentDialog = (tests) => {
    const { qcStore } = this.props;
    return (
      <ConditionalRerenderWrapper rerender={this.rerenderName === 'reagent_select' || this.state.selectReagentDialogIndex >= 0}>
        <BaseOverlay
          open={this.state.selectReagentDialogIndex >= 0}
          onClose={() => {
            this.rerenderName = 'reagent_select';
            this.setState({ selectReagentDialogIndex: -1 });
          }}
          onSelect={(id) => {
            this.onReagentSelectionFinished(this.props.index, this.state.selectReagentDialogIndex, id);
          }}
          currentSelect={this.state.selectReagentDialogIndex >= 0 ? tests[this.state.selectReagentDialogIndex].selectedReagentCatalogNumber : ''}
          label="Reagent"
          list={this.state.selectReagentDialogIndex >= 0 ? qcStore.getReagentSelectData(this.props.index, this.state.selectReagentDialogIndex) : []}
          idField="catalogNumber"
          valueField="catalogNumber"
        />
      </ConditionalRerenderWrapper>
    );
  }

  render() {
    const { classes, index, qcStore, expand } = this.props;
    const { tests } = this.props.data;
    return (
      <div key={index} className={classes.root}>
        <ExpansionPanel className={classes.borderlessExpansionPanel} defaultExpanded={expand} CollapseProps={{ unmountOnExit: true }}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.animationlessPanelSummary}>
            <div className={classes.headline}>
              <p className={classes.instrumentLabel}>{qcStore.getText('QCProductSelectionTestViewInstrmentNumber') + (index + 1)}: {this.props.data.instrument.name}</p>
              <Button
                className={classes.removeImageButton}
                onClick={() => {
                  qcStore.deleteSelectedInstrument(index);
                }}
              >
                <img src={removeImagePath} className={this.props.classes.removeImage} alt={removeImagePath} />
              </Button>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionContent}>
            <div className={classes.instrumentHeadline}>
              <p className={classes.instrumentLabel}>{qcStore.getText('QCProductSelectionTestViewInstrumentSelectionLabel')}</p>
              <SimAppButton className={`${classes.instrumentSelectButton}`} small onClick={() => this.onInstrumentSelect()}>{this.props.data.instrument.name ? this.props.data.instrument.name : qcStore.getText('QCProductSelectionTestViewInstrumentSelection')}</SimAppButton>
            </div>
            <QcInstrumentTable
              tests={tests}
              onTestRunsInputChange={this.onTestRunsInputChange}
              onPeriodSelect={this.onPeriodSelect}
              onTestSelectedChange={this.onTestSelectedChange}
              onReagentSelect={this.onReagentSelect}
              onSeparate={this.onSeparate}
              displayAll={this.state.displayAll}
            />
            <div className={classes.buttonRow}>
              {
                // according to comments from Beta test (7.1.2020) this button shall be hidden for now
                // this.getButton(qcStore.getText('QCProductSelectionTestViewInstrumentSelectStandardTestsButton'), this.enableStandardTests)
              }
              {this.getButton(qcStore.getText('QCProductSelectionTestViewInstrumentShowAllEnabledTestsButton'), this.toggleDisplayedTests)}
              {this.getButton(qcStore.getText('QCProductSelectionTestViewInstrumentCheckAllUncheckAllButton'), this.toggleEnableAllTests)}
              {this.getButton(qcStore.getText('QCProductSelectionTestViewInstrumentDuplicateInstrumentButton'), this.duplicateInstrument)}
              {this.getButton(qcStore.getText('QCProductSelectionTestViewInstrumentRequestNewTestButton'), this.requestTest)}
            </div>
            <div className={classes.buttonRow}>
              {this.getButton(qcStore.getText('QCProductSelectionTestViewSetFrequencyForSelection'), this.multiSelectRunsPeriod)}
              {this.getButton(qcStore.getText('QCProductSelectionTestViewSetLevelForSelection'), this.multiSelectLevel)}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <hr className={classes.seperator} />
        {this.renderSelectInstrumentDialog()}
        {this.renderSelectReagentDialog(tests)}
        {this.renderSelectPeriodDialog(tests)}
        {this.renderMultiSelectPeriodDialog()}
        {this.renderMultiSelectLevelDialog()}
        {this.renderMultiSelectRunsDialog()}
      </div>
    );
  }
}

QcInstrument.wrappedComponent.propTypes = {
  applicationStore: PropTypes.object.isRequired,
  qcTestRequestForm: PropTypes.object.isRequired,
  qcStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  expand: PropTypes.bool,
  isAnyTestEnabledCallback: PropTypes.func,
  isAnyTestEnabled: PropTypes.bool
};

QcInstrument.defaultProps = {
  expand: false,
  isAnyTestEnabledCallback: () => { },
  isAnyTestEnabled: false
};

export default withStyles(styles)(QcInstrument);
