import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { simappColors } from 'shared/lib/theme';
import QcTestView from './qc-test-view';
import QcComparisonView from './qc-comparison-view';
import QcProductView from './qc-product-view';

const styles = {
  root: {
    width: '100%',
    height: '100vH',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontWeight: 'normal'
  },
  headRow: {
    display: 'flex',
    flexShrink: 0,
    height: 46,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: simappColors.white
  },
  buttonRoot: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: simappColors.white
  },
  inline: {
    display: 'flex',
    flex: 1,
  },
  title: {
    margin: '0',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '28px',
    lineHeight: '40px'
  },
  toggleButtons: {
    display: 'inline',
    justifyContent: 'center',
    alignItems: 'center',
  },
  toggleButton: {
    width: '200px',
    height: '32px',
    fontWeight: 'normal',
    minHeight: '0',
    margin: '12px 0',
    border: `1px solid ${simappColors.azul}`,
    padding: '0 8px',
    color: `${simappColors.azul}`,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&.selected': {
      backgroundColor: `${simappColors.azul}`,
      color: `${simappColors.white}`
    },
    '&.selected:hover': {
      backgroundColor: `${simappColors.azul}`
    }
  },
  leftToggleButton: {
    borderRadius: '4px 0 0 4px',

  },
  middleToggleButton: {
    borderRadius: '0 0 0 0'
  },
  rightToggleButton: {
    borderRadius: '0 4px 4px 0',
  },
  separator: {
    color: `${simappColors.steelGrey}`,
    width: '100%',
    marginTop: 0
  },
  contentDiv: {

  }
};

@inject('qcStore', 'routing')
@observer
class QcProductSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminViewClick: 0,
      adminView: false
    };
  }

  clickButton = (view) => {
    const { qcStore } = this.props;
    qcStore.setProductSelectionView(view);
    this.setState({
      adminView: false,
      adminViewClick: 0
    });
  }

  clickAdminView = () => {
    if (!this.state.adminView) {
      if (this.state.adminViewClick + 1 === 5) {
        this.setState({
          adminView: true,
          adminViewClick: 0
        });
      } else {
        this.state.adminViewClick++;
      }
    }
  }

  render() {
    const { classes, qcStore } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.headRow}>
          <Typography
            variant="h1"
            className={classes.title}
          >
            {qcStore.getText('QCProductSelectionTitle')}
          </Typography>
        </div>
        <div className={classes.buttonRoot}>
          <span className={classes.toggleButtons}>
            <Button
              className={`${classes.toggleButton} ${classes.leftToggleButton}${qcStore.productSelectionView === 'TestView' ? ' selected' : ''}`}
              onClick={() => this.clickButton('TestView')}
            >{qcStore.getText('QCProductSelectionTestView')}
            </Button>
            <Button
              className={`${classes.toggleButton} ${classes.middleToggleButton}${qcStore.productSelectionView === 'ComparisonView' ? ' selected' : ''}`}
              onClick={() => this.clickButton('ComparisonView')}
            >{qcStore.getText('QCProductSelectionComparisonView')}
            </Button>
            <Button
              className={`${classes.toggleButton} ${classes.rightToggleButton}${qcStore.productSelectionView === 'ProductView' ? ' selected' : ''}`}
              onClick={() => this.clickButton('ProductView')}
            >{qcStore.getText('QCProductSelectionProductView')}
            </Button>
          </span>
        </div>
        <hr className={classes.separator} />
        <div className={classes.contentDiv}>
          {qcStore.productSelectionView === 'TestView' && <QcTestView />}
          {qcStore.productSelectionView === 'ComparisonView' && <QcComparisonView />}
          {qcStore.productSelectionView === 'ProductView' && <QcProductView adminViewButtonClick={() => this.clickAdminView()} adminView={this.state.adminView} />}
        </div>
      </div>
    );
  }
}

QcProductSelection.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  qcStore: PropTypes.object.isRequired
};

export default withStyles(styles)(QcProductSelection);
