import { css } from 'styled-components';

// this works with styled components, but not withStyles?!
export const media = {
  mobile: (...args) => css`
    @media (max-width: 420px) {
      ${css(...args)}
    }
  `,
  image2x: url => css`
    @media (min--moz-device-pixel-ratio: 1.3),
          (-o-min-device-pixel-ratio: 2.6/2),
          (-webkit-min-device-pixel-ratio: 1.3),
          (min-device-pixel-ratio: 1.3),
          (min-resolution: 1.3dppx) {
            background-image: url(${url});
    }`
};

export const isRetina = '@media (min--moz-device-pixel-ratio: 1.3),(-o-min-device-pixel-ratio: 2.6/2),(-webkit-min-device-pixel-ratio: 1.3),(min-device-pixel-ratio: 1.3),(min-resolution: 1.3dppx)';
