import { persist } from 'mobx-persist';
import { observable, action, intercept } from 'mobx';
import moment from 'moment';
import { sort } from 'shared/lib/helpers';
import { qcInstrumentStore, applicationStore, qcProductStore, qcCountryStore, qcCustomerStore, qcAnalyteStore } from '.';
import routing from './router-store';
import createStorage from './create-storage';

const pdfDataResource = require('shared/lib/resources/qc-pdf-resources.json');
let jsonResourcesData = require('shared/lib/resources/qc-resources.json'); // eslint-disable-line global-require

const integerValueRegex = /(\d+)(\d{3})/;

export class Instrument {
  @observable instrument = { manufacturer: '', name: '' };
  @observable tests = [];
}

export class InstrumentTest {
  id;
  reagents = [];
  name;
  isStandard;
  @observable selectedReagentCatalogNumber = '';
  @observable isEnabled = true;
  @observable isLevelsSeparated = false;
  @observable levels = [];
}

export class Reagent {
  catalogNumber;
  volumeUsage;
}

export class InstrumentTestLevel {
  level;
  @observable nbRuns = 2;
  @observable period = 'weekly';
  @observable isEnabled = true;
}

export class Competitor {
  @observable competitor = { name: '' };
}

export class Product {
  @observable product = { name: '' };
  @observable quantity;
  @observable pricePerUnit;
  @observable totalPrice;
  @observable discount;
  @observable discountedPricePerUnit;

  @action setProduct = (item) => {
    this.product = item;
  }

  @action setQuantity = (item) => {
    this.quantity = item;
  }

  @action setPricePerUnit = (item) => {
    this.pricePerUnit = item;
    this.discountedPricePerUnit = item;
  }

  @action setTotalPrice = (item) => {
    this.totalPrice = item;
  }
}

class QcStore {
  constructor() {
    createStorage('qcStore', this).then(() => {
      const jsonResource = require('shared/lib/resources/qc-resources.json'); // eslint-disable-line global-require
      jsonResourcesData = jsonResource === undefined ? jsonResourcesData : jsonResource;
      this.jsonResources = jsonResourcesData;
      this.pdfData = pdfDataResource;
    });
    for (let i = 1; i < 100; i++) {
      this.runsData.push({ name: i + (i === 1 ? this.getText('QCProductSelectionTestViewInstrumentRunsDropDownSingle') : this.getText('QCProductSelectionTestViewInstrumentRunsDropDownMultiple')), id: i });
    }
    for (let i = 0; i < 101;) {
      this.discountData.push({ name: `${i}%`, id: i, value: (i / 100) });
      i += 5;
    }
    this.periodData = [
      { name: this.getText('QCProductSelectionTestViewInstrumentPeriodDropDownDaily'), id: 'daily' },
      { name: this.getText('QCProductSelectionTestViewInstrumentPeriodDropDownWeekly'), id: 'weekly' },
      { name: this.getText('QCProductSelectionTestViewInstrumentPeriodDropDownMonthly'), id: 'monthly' }
    ];
    this.setNoInstrumentTestWithProduct(false);
    this.setNoCompetitorProductWithThermoFisherProduct(false);
    this.setProductSelectionView('TestView');
    this.resetData();
  }

  @persist('object') @observable customerData = { customerName: '', customerNumber: '', contactName: '', contactTitle: '', contactEmail: '', contactPhone: '', country: '', currency: '', workingDaysPerWeek: 5, contractPeriodInMonths: 12, proposalStatus: undefined, proposalTemplate: undefined, customerDescription: '' };
  @persist('object') jsonResources = jsonResourcesData;
  @persist('list') @observable instrumentsData = [];
  @persist('object') @observable competitorData;
  @persist('list') @observable competitorProductList = [];
  @persist('list') @observable productList = [];
  @persist('object') @observable massDiscount;
  @persist('object') @observable customerProposal;
  @observable cheapestResult;
  @observable fewestSKUResult;
  @observable smallestQuantityResult;
  @observable testsWithoutProduct = [];
  @observable noInstrumentTestWithProduct;
  @observable noCompetitorProductWithThermoFisherProduct;
  runsData = [];
  periodData = [];
  discountData = [];
  @observable productSelectionView;
  @observable calculationView;
  pdfContent = undefined;
  @observable isCalculating = false;

  @action setIsCalculating = (value) => {
    this.isCalculating = value;
  }

  /* Customer Data */

  getCountrySelectData = () => qcCountryStore.countryData

  getCountryObject = (iso) => {
    const items = qcCountryStore.countryData.filter(item => item.iso === iso);
    if (items.length > 0) {
      return items[0];
    }
    return undefined;
  }

  /* Country */

  getPriceCountryIso = () => {
    if (this.customerData.country !== undefined) {
      return this.customerData.country;
    }
    return 'US';
  }

  getCurrencySign = () => {
    const items = qcCountryStore.countryData.filter(item => item.iso === this.customerData.country);
    if (items.length > 0) {
      return items[0].currencySign;
    }
    return '$';
  }

  getCurrencyPosition = () => {
    const items = qcCountryStore.countryData.filter(item => item.iso === this.customerData.country);
    if (items.length > 0) {
      return items[0].currencyPosition;
    }
    return 'prefix';
  }

  getDecimalSeparator = () => {
    const items = qcCountryStore.countryData.filter(item => item.iso === this.customerData.country);
    if (items.length > 0) {
      return items[0].decimalSeparator;
    }
    return '.';
  }

  getThousandSeparator = () => {
    const items = qcCountryStore.countryData.filter(item => item.iso === this.customerData.country);
    if (items.length > 0) {
      return items[0].thousandSeparator;
    }
    return ',';
  }

  getFormattedCurrencyValue(value) {
    // eslint-disable-next-line no-restricted-globals
    const fixedValue = (value !== undefined && !isNaN(value) ? Math.round(value * 100) / 100 : 0);
    const decimalSeparator = this.getDecimalSeparator();
    const currencySign = this.getCurrencySign();
    const currencySignPosition = this.getCurrencyPosition();
    let formattedValue = this.addValueSeparators(fixedValue, '.', decimalSeparator, this.getThousandSeparator());
    const dividerPos = formattedValue.indexOf(decimalSeparator);
    if (dividerPos === -1) {
      formattedValue = `${formattedValue}${decimalSeparator}00`;
    } else if (formattedValue.length - dividerPos === 2) { // one decimal number
      formattedValue = `${formattedValue}0`;
    }
    return currencySignPosition === 'prefix' ? currencySign + formattedValue : `${formattedValue} ${currencySign}`;
  }

  // eslint-disable-next-line class-methods-use-this
  addValueSeparators(integerValue, currentDecimalSeparator, decimalSeparatorToUse, thousandSeparatorToUse) {
    let value = `${integerValue}`;
    const integerEndPos = value.indexOf(currentDecimalSeparator);
    let decimalValue = '';
    if (integerEndPos !== -1) {
      decimalValue = decimalSeparatorToUse + value.substring(integerEndPos + 1, value.length);
      value = value.substring(0, integerEndPos);
    }
    while (integerValueRegex.test(value)) {
      value = value.replace(integerValueRegex, `$1${thousandSeparatorToUse}$2`);
    }
    return value + decimalValue;
  }

  /* Instruments */

  @action getInstrumentsSelectData = () => {
    const instrumentArray = [];
    qcInstrumentStore.instrumentData.map((item) => {
      instrumentArray.push(item);
    });
    return instrumentArray;
  }

  @action getReagentSelectData = (instrumentIndex, testIndex) => {
    const reagentArray = [];
    this.instrumentsData[instrumentIndex].tests[testIndex].reagents.map((item) => {
      reagentArray.push(item);
    });
    return reagentArray;
  }

  @action setInstrumentTests = (index) => {
    this.instrumentsData[index].tests = [];
    const tests = qcInstrumentStore.instrumentData.filter(instrument => this.instrumentsData[index].instrument.id === instrument.id)[0].analytes;
    tests.map((testItem) => {
      if (testItem.reagents.length > 0) {
        const test = new InstrumentTest();
        testItem.reagents.map((reagentItem) => {
          const reagent = new Reagent();
          reagent.catalogNumber = reagentItem.catalogNumber;
          reagent.volumeUsage = reagentItem.volumeUsage;
          test.reagents.push(reagent);
        });
        test.id = testItem.uid;
        test.name = testItem.name;
        test.isStandard = testItem.isDefault;
        if (testItem.reagents.length === 1) {
          test.selectedReagentCatalogNumber = testItem.reagents[0].catalogNumber;
        }
        testItem.levels.map((levelItem) => {
          const level = new InstrumentTestLevel();
          level.level = levelItem;
          test.levels.push(level);
        });
        this.sortLevels(test);
        this.instrumentsData[index].tests.push(test);
      }
    });
  }

  defaultLevelSorting = ['UL', 'L', '1', '2', '3', '4', '5', '6'];

  @action sortLevels(test) {
    const sortedList = [];
    test.levels.map((level) => {
      let position;
      for (let i = 0; i < sortedList.length; i++) {
        if (this.defaultLevelSorting.indexOf(sortedList[i].level) > this.defaultLevelSorting.indexOf(level.level)) {
          position = i;
          i = sortedList.length;
        }
      }
      if (position !== undefined) {
        sortedList.splice(position, 0, level);
      } else {
        sortedList.push(level);
      }
    });
    test.levels = sortedList;
  }

  @action updateInstrumentInstrument(index, value) {
    this.instrumentsData[index].instrument = value;
  }

  @action deleteSelectedInstrument(index) {
    this.instrumentsData.splice(index, 1);
  }

  @action deleteSelectedProduct(index) {
    this.productList.splice(index, 1);
  }

  @action deleteSelectedCompetitorProduct(index) {
    this.competitorProductList.splice(index, 1);
  }

  @action updateInstrumentTestRuns(instrumentIndex, index, value, levelIndex) {
    if (levelIndex !== undefined) {
      this.instrumentsData[instrumentIndex].tests[index].levels[levelIndex].nbRuns = value;
    } else {
      this.instrumentsData[instrumentIndex].tests[index].levels.map((item) => {
        item.nbRuns = value;
      });
    }
  }

  @action updateInstrumentTestPeriod(instrumentIndex, index, periodId, levelIndex) {
    if (levelIndex !== undefined) {
      this.instrumentsData[instrumentIndex].tests[index].levels[levelIndex].period = periodId;
    } else {
      this.instrumentsData[instrumentIndex].tests[index].levels.map((item) => {
        item.period = periodId;
      });
    }
  }

  @action updateInstrumentTestSelection(instrumentIndex, index, checked, levelIndex) {
    if (levelIndex !== undefined) {
      this.instrumentsData[instrumentIndex].tests[index].levels[levelIndex].isEnabled = checked;
      let isEnabled = false;
      for (let i = 0; i < this.instrumentsData[instrumentIndex].tests[index].levels.length; i++) {
        if (this.instrumentsData[instrumentIndex].tests[index].levels[i].isEnabled) {
          isEnabled = true;
        }
      }
      this.instrumentsData[instrumentIndex].tests[index].isEnabled = isEnabled;
    } else {
      this.instrumentsData[instrumentIndex].tests[index].isEnabled = checked;
      this.instrumentsData[instrumentIndex].tests[index].levels.map((level) => {
        level.isEnabled = checked;
      });
    }
  }

  @action updateInstrumentTestReagentSelection(instrumentIndex, index, reagentCatalogNumber) {
    this.instrumentsData[instrumentIndex].tests[index].selectedReagentCatalogNumber = reagentCatalogNumber;
  }

  @action updateInstrumentTestSeparation(instrumentIndex, index) {
    const test = this.instrumentsData[instrumentIndex].tests[index];
    test.isLevelsSeparated = true;
  }

  @action updateInstrumentEnableDefaultTests(instrumentIndex) {
    this.instrumentsData[instrumentIndex].tests.map((item) => {
      if (item.isStandard) {
        item.isEnabled = true;
        item.levels.map((level) => {
          level.isEnabled = true;
        });
      } else {
        item.isEnabled = false;
        item.levels.map((level) => {
          level.isEnabled = false;
        });
      }
    });
  }

  @action updateInstrumentAllTestsState(instrumentIndex, active) {
    this.instrumentsData[instrumentIndex].tests.map((item) => {
      item.levels.map((level) => {
        level.isEnabled = active;
      });
      item.isEnabled = active;
    });
  }

  @action updateInstrumentAllTestsPeriod(instrumentIndex, periodId) {
    this.instrumentsData[instrumentIndex].tests.map((item) => {
      if (item.isEnabled) {
        item.levels.map((level) => {
          if (level.isEnabled) {
            level.period = periodId;
          }
        });
      }
    });
  }

  @action updateInstrumentAllTestsRuns(instrumentIndex, runsId) {
    this.instrumentsData[instrumentIndex].tests.map((item) => {
      if (item.isEnabled) {
        item.levels.map((level) => {
          if (level.isEnabled) {
            level.nbRuns = runsId;
          }
        });
      }
    });
  }

  @action updateListInstrumentTests = (index) => {
    this.setInstrumentTests(index);
  }

  @action addListInstrument = () => {
    this.instrumentsData.push(new Instrument());
  };

  @action duplicateListInstrument = (index) => {
    this.instrumentsData.push(JSON.parse(JSON.stringify(this.instrumentsData[index])));
  }

  /* Options Proposal */

  createContractPeriodText = () => {
    let result = `${this.getText('QCOptionsProposalContractPeriod')} ${this.customerData.contractPeriodInMonths} `;
    if (this.customerData.contractPeriodInMonths > 1) {
      result += this.getText('QCOptionsProposalContractPeriodMonths');
    } else {
      result += this.getText('QCOptionsProposalContractPeriodMonth');
    }
    return result;
  }

  createTestsWithoutProductLevelsText = () => {
    let tests = '';
    this.testsWithoutProduct.map((test) => {
      let levels = '';
      test.levels.map((level) => {
        if (levels !== '') {
          levels = `${levels}, `;
        }
        levels = `${levels}${level.level}`;
      });
      if (tests !== '') {
        tests = `${tests}, `;
      }
      if (test.levels.length > 1) {
        tests = `${tests}${test.name} (${this.getText('QCOptionsProposalTestsWithoutProductLevels')} ${levels})`;
      } else {
        tests = `${tests}${test.name} (${this.getText('QCOptionsProposalTestsWithoutProductLevel')} ${levels})`;
      }
    });
    return tests;
  }

  /* Product Selection */

  @action setProductSelectionView = (view) => {
    this.productSelectionView = view;
  }

  @action setCalculationView = (view) => {
    this.calculationView = view;
  }

  @action setCurrentCustomer(customer) {
    this.customerData = customer;
  }

  sendRequestMail = data => new Promise((resolve, reject) => {
    let mailtext = `<p>Dear Support,<br>
      A new test request was send.
      <br></p>`;

    mailtext += `<p>Instrument Name: ${data.instrumentName.value}</p>`;
    mailtext += `<p>Test Name: ${data.testName.value}</p>`;
    mailtext += `<p>Microliters used: ${data.microlitersUsed.value} µl</p>`;

    applicationStore.sendMail(applicationStore.appUser.get('email'), 'Test Request', mailtext)
      .then(() => { resolve(); })
      .catch((error) => { reject(error); console.log(error); });
  })

  /* Comparison */

  @action getCompetitorSelectData = () => qcProductStore.competitorManufacturer

  @action updateCompetitor = (data) => {
    this.competitorData.competitor = data;
  };

  @action getCompetitorProductSelectData = () => qcProductStore.competitorProductData.filter(item => item.manufacturerId === this.competitorData.competitor.id)

  @action addCompetitorProduct = (product) => {
    const newProduct = new Product();
    newProduct.product = product;
    newProduct.pricePerUnit = product.listPrice[this.getPriceCountryIso()];
    newProduct.quantity = 0;
    this.competitorProductList.push(newProduct);
    this.setCompetitorProductTotalPrice(this.competitorProductList.length - 1);
  }

  @action addCompetitorProducts = (products) => {
    products.map((product) => {
      const newProduct = new Product();
      newProduct.product = product;
      newProduct.pricePerUnit = product.listPrice[this.getPriceCountryIso()];
      newProduct.quantity = 0;
      newProduct.activeAnalytes = JSON.parse(JSON.stringify(product.analyteIds));
      this.competitorProductList.push(newProduct);
      this.setCompetitorProductTotalPrice(this.competitorProductList.length - 1);
    });
  }

  @action updateCompetitorProduct = (index, product) => {
    this.competitorProductList[index].product = product;
    this.competitorProductList[index].activeAnalytes = JSON.parse(JSON.stringify(product.analyteIds));
    this.competitorProductList[index].pricePerUnit = product.listPrice[this.getPriceCountryIso()];
  }

  @action updateCompetitorPricePerUnit = (index, value) => {
    this.competitorProductList[index].pricePerUnit = value;
    this.setCompetitorProductTotalPrice(index);
  }

  @action updateCompetitorQuantity = (index, value) => {
    this.competitorProductList[index].quantity = value;
    this.setCompetitorProductTotalPrice(index);
  }

  @action setProductsBasedOnListPrice = () => {
    this.competitorProductList.map((product) => {
      product.pricePerUnit = product.product.listPrice[this.getPriceCountryIso()];
    });
  }

  @action setCompetitorProductTotalPrice(index) {
    if (this.competitorProductList[index].pricePerUnit !== undefined
      && this.competitorProductList[index].quantity !== undefined) {
      this.competitorProductList[index].totalPrice = this.competitorProductList[index].pricePerUnit * this.competitorProductList[index].quantity;
    } else {
      this.competitorProductList[index].totalPrice = undefined;
    }
  }

  @action setCompetitorProductAnalyteList(index, list) {
    this.competitorProductList[index].activeAnalytes = list;
  }

  @action getCompetitorProductAnalyteList(index) {
    if (index > -1) {
      const analytes = qcAnalyteStore.analyteData.filter(item => this.competitorProductList[index].product.analyteIds.includes(item.uid));
      return sort(analytes, 'name');
    }
    return [];
  }

  /* Product */

  @action setProductList = (newProductList) => {
    this.productList = newProductList;
  }

  @action getThermoProducts = () => qcProductStore.productData.filter(item => item.countries.includes(this.getPriceCountryIso()))

  @action addProduct = (product) => {
    const productItem = new Product();
    productItem.product = product;
    productItem.pricePerUnit = product.listPrice[this.getPriceCountryIso()];
    productItem.quantity = 0;
    if (this.massDiscount) {
      productItem.discountedPricePerUnit = product.listPrice[this.getPriceCountryIso()] * (1 - this.massDiscount.value);
    } else {
      productItem.discountedPricePerUnit = product.listPrice[this.getPriceCountryIso()];
    }
    this.productList.push(productItem);
    this.setProductTotalPrice(this.productList.length - 1);
  }

  @action updateProduct = (product, index) => {
    this.productList[index].product = product;
    this.productList[index].pricePerUnit = product.listPrice[this.getPriceCountryIso()];
    this.productList[index].discount = undefined;
    if (this.massDiscount) {
      this.productList[index].discountedPricePerUnit = product.listPrice[this.getPriceCountryIso()] * (1 - this.massDiscount.value);
    } else {
      this.productList[index].discountedPricePerUnit = product.listPrice[this.getPriceCountryIso()];
    }
    this.setProductTotalPrice(index);
  }

  @action updateProductPricePerUnit = (index, value) => {
    this.productList[index].pricePerUnit = value;
    if (this.productList[index].discount !== undefined) {
      this.productList[index].discountedPricePerUnit = value * (1 - this.productList[index].discount);
    } else if (this.massDiscount) {
      this.productList[index].discountedPricePerUnit = value * (1 - this.massDiscount.value);
    } else {
      this.productList[index].discountedPricePerUnit = value;
    }
    this.setProductTotalPrice(index);
  }

  @action updateProductDiscount = (index, value) => {
    this.productList[index].discount = value;
    if (value !== undefined) {
      this.productList[index].discountedPricePerUnit = this.productList[index].pricePerUnit * (1 - value);
    } else if (this.massDiscount) {
      this.productList[index].discountedPricePerUnit = this.productList[index].pricePerUnit * (1 - this.massDiscount.value);
    } else {
      this.productList[index].discountedPricePerUnit = this.productList[index].pricePerUnit;
    }
    this.setProductTotalPrice(index);
  }

  @action updateProductQuantity = (index, value) => {
    this.productList[index].quantity = value;
    this.setProductTotalPrice(index);
  }

  @action updateProductDiscountedPricePerUnit = (index, value) => {
    this.productList[index].discountedPricePerUnit = value;
    this.setProductTotalPrice(index);
  }

  setProductTotalPrice(index) {
    if (this.productList[index].pricePerUnit !== undefined
      && this.productList[index].quantity !== undefined) {
      if (this.productList[index].discountedPricePerUnit !== undefined) {
        this.productList[index].totalPrice = this.productList[index].discountedPricePerUnit * this.productList[index].quantity;
      } else {
        this.productList[index].totalPrice = this.productList[index].pricePerUnit * this.productList[index].quantity;
      }
    } else {
      this.productList[index].totalPrice = undefined;
    }
  }

  @action getDiscountSelectData = () => this.discountData;

  @action updateMassDiscount(value) {
    this.massDiscount = value;
    this.productList.map((product, index) => {
      if (product.discount === undefined) {
        if (value === undefined) {
          this.updateProductDiscountedPricePerUnit(index, product.pricePerUnit);
        } else {
          this.updateProductDiscountedPricePerUnit(index, product.pricePerUnit * (1 - value.value));
        }
      }
    });
  }

  @action resetData = () => {
    this.customerData = { customerName: '', customerNumber: '', contactName: '', contactTitle: '', contactEmail: '', contactPhone: '', country: '', currency: '', workingDaysPerWeek: 5, contractPeriodInMonths: 12, proposalStatus: undefined, proposalTemplate: undefined };
    this.instrumentsData = [];
    this.competitorData = new Competitor();
    this.competitorProductList = [];
    this.productList = [];
    this.massDiscount = undefined;
    this.customerProposal = undefined;
  }

  /* Customer Proposal */

  @action updatePricesOnCountryChange = () => {
    this.productList.map((product, index) => {
      this.updateProductPricePerUnit(index, product.product.listPrice[this.getPriceCountryIso()]);
    });
  }

  @action setProposalTemplateForCurrentCustomer = (value) => {
    this.customerData.proposalTemplate = value;
  }

  @action setProposalStatusForCurrentCustomer = (value) => {
    this.customerData.proposalStatus = value;
  }

  @action getProposalStatusValues = () => [{ name: this.getText('QCStatusInProgress'), id: 0 }, { name: this.getText('QCStatusReview'), id: 1 }, { name: this.getText('QCStatusComplete'), id: 2 }, { name: this.getText('QCStatusOnHold'), id: 3 }]

  @action getProposalTemplates = () => [{ name: this.getText('QCTemplateUS'), id: 0 }, { name: this.getText('QCTemplateFrance'), id: 1 }, { name: this.getText('QCTemplateGeneric'), id: 2 }]

  @action getText = (key) => {
    try {
      return this.jsonResources.unlocalized[key];
    } catch {
      return undefined;
    }
  }

  @action getCustomerProposalList = () => qcCustomerStore.customerData

  @action loadCustomerProposal = (id) => {
    const customerProposal = qcCustomerStore.customerData.find(item => item.id === id);
    if (customerProposal !== undefined) {
      this.customerProposal = customerProposal;
      this.customerData.customerName = this.customerProposal.customer_name;
      this.customerData.customerNumber = `${this.customerProposal.customer_number}`;
      this.customerData.contactName = this.customerProposal.contact_name;
      this.customerData.contactTitle = this.customerProposal.contact_title;
      this.customerData.contactEmail = this.customerProposal.contact_email;
      this.customerData.contactPhone = this.customerProposal.contact_phone;
      const countryObject = qcCountryStore.countryData.find(item => item.id === this.customerProposal.country_id);
      if (countryObject) {
        this.customerData.country = countryObject.iso;
      } else {
        this.customerData.country = 'US';
      }
      this.customerData.workingDaysPerWeek = this.customerProposal.working_days_per_week;
      this.customerData.contractPeriodInMonths = this.customerProposal.contract_period;
      this.customerData.customerDescription = this.customerProposal.customer_description;
      this.customerData.proposalStatus = this.customerProposal.status;
      const data = JSON.parse(customerProposal.qcvc_data);
      this.instrumentsData = data.instrumentData;
      this.competitorData = data.competitorData;
      this.competitorProductList = data.competitorProductData;
      this.productList = data.productData;
      this.massDiscount = data.massDiscountData;
    }
  }

  @action saveCustomerProposal = () => {
    const isNew = this.customerProposal === undefined;
    const customerProposal = {};
    customerProposal.customer_name = this.customerData.customerName;
    customerProposal.customer_number = this.customerData.customerNumber;
    customerProposal.contact_name = this.customerData.contactName;
    customerProposal.contact_title = this.customerData.contactTitle;
    customerProposal.contact_email = this.customerData.contactEmail;
    customerProposal.contact_phone = this.customerData.contactPhone;
    customerProposal.country_id = this.getCountryObject(this.customerData.country).id;
    customerProposal.working_days_per_week = this.customerData.workingDaysPerWeek;
    customerProposal.contract_period = this.customerData.contractPeriodInMonths;
    customerProposal.customer_description = this.customerData.customerDescription;
    customerProposal.status = this.customerData.proposalStatus;

    const data = {};
    data.instrumentData = this.instrumentsData;
    data.competitorData = this.competitorData;
    data.competitorProductData = this.competitorProductList;
    data.productData = this.productList;
    data.massDiscountData = this.massDiscount;

    customerProposal.qcvc_data = JSON.stringify(data);

    if (!isNew) {
      customerProposal.id = this.customerProposal.id;
      customerProposal.created_at = this.customerProposal.created_at;
      customerProposal.updated_at = moment.utc().format('YYYY-MM-DDTHH:mm:ss');
    }

    qcCustomerStore.saveCustomer(customerProposal).then(() => {
      if (isNew) {
        this.loadCustomerProposal(customerProposal.id);
      }
    });
  }

  @action deleteCustomerProposal = (id) => {
    qcCustomerStore.deleteCustomer(id);
    this.resetData();
  }

  /* Calculation */

  @action setCheapestResult = (item) => {
    this.cheapestResult = item;
  }

  @action setSmallestQuantityResult = (item) => {
    this.smallestQuantityResult = item;
  }

  @action setFewestSKUResult = (item) => {
    this.fewestSKUResult = item;
  }

  @action addTestWithoutProduct = (instrumentTest, level) => {
    const test = Object.assign(new InstrumentTest(), instrumentTest);
    if (level) {
      for (let i = 0; i < this.testsWithoutProduct.length; i++) {
        if (this.testsWithoutProduct[i].id === test.id) {
          let levelAlreadyIncluded = false;
          this.testsWithoutProduct[i].levels.map((levelItem) => {
            levelAlreadyIncluded = levelAlreadyIncluded || levelItem.level === level.level;
          });
          if (!levelAlreadyIncluded) {
            this.testsWithoutProduct[i].levels.push(level);
          }
          return;
        }
      }
      test.levels = [level];
    }
    let testAlreadyIncluded = false;
    let indexToReplace = -1;
    this.testsWithoutProduct.map((testItem, index) => {
      if (testItem.id === test.id) {
        testAlreadyIncluded = true;
        if (testItem.isLevelsSeparated) {
          indexToReplace = index;
        }
      }
    });
    if (indexToReplace >= 0) {
      this.testsWithoutProduct.splice(indexToReplace, 1, test);
    } else if (!testAlreadyIncluded) {
      this.testsWithoutProduct.push(test);
    }
  }

  levelCompare = (a, b) => {
    if (this.defaultLevelSorting.indexOf(a.id) > this.defaultLevelSorting.indexOf(b.id)) return 1;
    if (this.defaultLevelSorting.indexOf(b.id) > this.defaultLevelSorting.indexOf(a.id)) return -1;
    return 0;
  }

  @action getAllLevelsOfSelectedTests= (instrumentIndex) => {
    const availableLevels = [];
    const containedLevels = {};
    if (this.instrumentsData[instrumentIndex]) {
      const tests = this.instrumentsData[instrumentIndex].tests;
      tests.map((test) => {
        if (test.isEnabled) {
          test.levels.map((level) => {
            if (!Object.hasOwnProperty.call(containedLevels, level.level)) {
              availableLevels.push({ id: level.level });
              containedLevels[level.level] = 1;
            }
          });
        }
      });
    }
    return availableLevels.sort(this.levelCompare);
  }

  @action updateInstrumentAllSelectedTestsSelectLevels = (instrumentIndex, selectedLevels) => {
    const tests = this.instrumentsData[instrumentIndex].tests;
    tests.map((test) => {
      let testContainedSelectedLevel = false;
      if (test.isEnabled) {
        test.levels.map((level) => {
          if (Object.hasOwnProperty.call(selectedLevels, level.level)) {
            test.isLevelsSeparated = true;
            level.isEnabled = true;
            testContainedSelectedLevel = true;
          } else {
            level.isEnabled = false;
          }
        });
      }
      if (!testContainedSelectedLevel) {
        test.isEnabled = false;
      }
    });
  }

  @action clearTestsWithoutProduct = () => {
    this.testsWithoutProduct = [];
  }

  @action setNoInstrumentTestWithProduct = (value) => {
    this.noInstrumentTestWithProduct = value;
  }

  @action setNoCompetitorProductWithThermoFisherProduct = (value) => {
    this.noCompetitorProductWithThermoFisherProduct = value;
  }

  // pdf data

  @persist('object') pdfData = pdfDataResource;

  @action getPdfData = (key, language) => {
    if (this.pdfData.localized[key] !== undefined) {
      if (language !== undefined) {
        return this.pdfData.localized[key][language];
      }
      return this.pdfData.localized[key].en;
    }
    return this.pdfData.unlocalized[key];
  };

  @action getLanguageSelectData() {
    return this.pdfData.languages;
  }

  @action setPdfContent(content) {
    this.pdfContent = content;
  }

  // ----- QC Routing -----
  observeRouting = intercept(routing, 'location', (newLocation) => {
    const newPathname = newLocation.newValue.pathname;
    const oldPathname = routing.location !== null ? routing.location.pathname : ''; // initially, routing.location is null
    if ((oldPathname.includes('qc-calculator')
      && !newLocation.newValue.pathname.includes('qc-calculator')) || newLocation.newValue.pathname.endsWith('qc-calculator')) {
      this.resetData();
    }
    if (oldPathname.includes('qc-calculator')
      && (newPathname.endsWith('qc-calculator') || !newPathname.includes('qc-calculator'))) {
      this.setProductSelectionView('TestView');
    }
    return newLocation;
  });

  // ----- Demo Customer -----
  demoCustomer = [
    {
      name: 'customerName',
      value: 'Thermo Fisher Customer Name'
    },
    {
      name: 'customerNumber',
      value: '0123456789'
    },
    {
      name: 'contactName',
      value: 'Thermo Fisher Contact Name'
    },
    {
      name: 'contactTitle',
      value: 'Thermo Fisher Contact Title'
    },
    {
      name: 'contactEmail',
      value: 'thermofisher.contact@thermofisher.com'
    },
    {
      name: 'contactPhone',
      value: '+49123456789'
    },
    {
      name: 'country',
      value: 'US'
    },
    {
      name: 'workingDaysPerWeek',
      value: 5
    },
    {
      name: 'contractPeriodInMonths',
      value: 12
    },
  ];

  @action
  setDemoCustomer = () => {
    this.demoCustomer.map((item) => {
      this.customerData[item.name] = item.value;
    });
  }
}

export default new QcStore();
