import { observable } from 'mobx';
import { persist } from 'mobx-persist';

const jsonResourcesData = require('shared/lib/resources/calculator-resources.json');

class CalculatorStore {
  @persist @observable language = '';

  // ----- Calculator Resources -----

  getLocalizedText = (key) => {
    let value = jsonResourcesData.localized[key];
    if (value === undefined) {
      return value;
    }
    value = jsonResourcesData.localized[key][this.language];
    if (value === undefined) {
      return jsonResourcesData.localized[key].en;
    }
    return value;
  }

  getText = (key) => {
    const value = jsonResourcesData.unlocalized[key];
    if (value === undefined) {
      return this.getLocalizedText(key);
    }
    return value;
  }
}

export default new CalculatorStore();
