import { tableSchema } from '@nozbe/watermelondb';
import { field, date, json } from '@nozbe/watermelondb/decorators';
import Model from './simapp-model';

export const contentPieceSchema = tableSchema({
  name: 'contentPiece',
  columns: [
    { name: 'b_id', type: 'number', isIndexed: true },
    { name: 'uid', type: 'string', isIndexed: true },
    { name: 'characteristic_ids', type: 'string' },
    { name: 'country_ids', type: 'string' },
    { name: 'speciality_ids', type: 'string' },
    { name: 'topic_ids', type: 'string' },
    { name: 'excluded_pieces', type: 'string' },
    { name: 'required_pieces', type: 'string' },
    { name: 'references', type: 'string' },
    { name: 'content_type_id', type: 'number' },
    { name: 'description', type: 'string' },
    { name: 'language_id', type: 'number' },
    { name: 'mastercontrol_id', type: 'number' },
    { name: 'shareable', type: 'boolean' },
    { name: 'title', type: 'string' },
    { name: 'usage', type: 'string' },
    { name: 'filename', type: 'string' },
    { name: 'thumbnail', type: 'string' },
    { name: 'b_created_at', type: 'number' },
    { name: 'b_updated_at', type: 'number' },
    { name: 'created_at', type: 'number' },
    { name: 'updated_at', type: 'number' }
  ]
});

export default class ContentPiece extends Model {
  static table = 'contentPiece'

  @field('b_id') bId
  @field('uid') uid
  @json('characteristic_ids', jsonData => jsonData) characteristicIds;
  @json('country_ids', jsonData => jsonData) countryIds;
  @json('speciality_ids', jsonData => jsonData) specialityIds;
  @json('topic_ids', jsonData => jsonData) topicIds;
  @json('excluded_pieces', jsonData => jsonData) excludedPieces;
  @json('required_pieces', jsonData => jsonData) requiredPieces;
  @json('references', jsonData => jsonData) references;
  @field('content_type_id') contentTypeId;
  @field('description') description;
  @field('filename') filename;
  @field('language_id') languageId;
  @field('mastercontrol_id') mastercontrolId;
  @field('shareable') shareable;
  @field('title') title;
  @field('usage') usage;
  @field('thumbnail') thumbnail;
  @date('b_created_at') bCreatedAt
  @date('b_updated_at') bUpdatedAt
  @date('created_at') createdAt
  @date('updated_at') updatedAt
}
