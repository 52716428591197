import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

const styles = {
  card: {
    position: 'relative',
    marginBottom: '1rem',
  },
  cardContent: {
    paddingTop: 8,
  },
  title: {
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  contentType: {
    fontWeight: 500,
    fontSize: '0.8rem'
  },
  clearButton: {
    position: 'absolute',
    top: 8,
    right: 8,
    height: 20,
    width: 20,
    minHeight: 20,
    '& svg': {
      fontSize: 12
    }
  }
};

const ShareMaterialCard = inject('materialStore')(observer((props) => {
  const material = props.materialStore.getMaterialByUid(props.uid);
  return (
    <Card className={props.classes.card}>
      <CardContent className={props.classes.cardContent}>
        <Typography className={props.classes.contentType}>{material.content_type}</Typography>
        <Typography className={props.classes.title}>{material.title}</Typography>
        <Button variant="fab" color="secondary" className={props.classes.clearButton} onClick={() => { props.materialStore.removeFromSharingMaterials(material.uid); }}>
          <ClearIcon />
        </Button>
      </CardContent>
    </Card>
  );
}));

ShareMaterialCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ShareMaterialCard);
