import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { observable, runInAction, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { simappColors } from 'shared/lib/theme';
import SimappIcon from 'web/components/_ui/simapp-icon';

const menuWidth = 192;

const styles = {
  root: {
    position: ''
  },
  popupMenu: {
    border: `1px solid ${simappColors.tableBorder}`,
    boxShadow: '0px 0px 15px 0px rgba(0,0,0,0.3)',
    overflowX: 'visible',
    overflowY: 'visible',
    width: menuWidth,
    maxWidth: menuWidth,
    '& > ul': {
      padding: 0
    },
  },
  popupMenuBackdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)'
  },
  popupMenuItem: {
    borderBottom: `1px solid ${simappColors.tableBorder}`,
  },
  popupMenuIcon: {
    color: simappColors.azul,
    marginRight: -24
  },
  popupMenuText: {
    width: '10rem',
    textAlign: 'center'
  },
  popupMenuItemDelete: {
    borderBottom: 'none',
    '& span': {
      color: simappColors.strawberry
    }
  },
  triangle: {
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderWidth: '0 13px 13px 13px',
    borderColor: 'transparent transparent #fff transparent',
    position: 'absolute',
    top: -13,
    left: 'calc(50% - 13px)',
    filter: 'drop-shadow(0 -2px 2px rgba(0,0,0,0.3))',
    '&.reverse': {
      borderWidth: '13px 13px 0 13px',
      borderColor: '#fff transparent transparent transparent',
      bottom: -13,
      top: 'auto',
      filter: 'drop-shadow(0 2px 2px rgba(0,0,0,0.3))',
    }
  },
};

@inject('routing', 'applicationStore', 'materialStore', 'presentationStore') @observer
class MaterialTileMenu extends Component {
  @observable node = null;
  @observable reverse = false;

  looky = reaction(
    () => {
      if (this.node === null || this.props.anchorEl === null) return { left: null, top: null };

      // find paper node
      const rect = this.props.anchorEl.getBoundingClientRect();
      // const nodeRect = this.node.children[1].getBoundingClientRect();

      let left = rect.left + (rect.width / 2) - (menuWidth / 2);
      let diff = 0;
      if (window.innerWidth < left + menuWidth) {
        const newLeft = window.innerWidth - menuWidth - 10;
        diff = left - newLeft;
        left = newLeft;
      }

      // if menu would overflow the bottom screen border, show above menu button
      let top = rect.bottom + 8;
      const height = this.props.sharingEnabled ? 3 * 46 : 2 * 46;
      const reverse = window.innerHeight < top + height + 13;
      if (reverse) {
        top = rect.top - height - 13;
      }
      return { left, top, reverse, diff };
    },
    (data) => {
      if (data.left !== null && data.top !== null) {
        // this.node.children[1].style.position = 'fixed';
        this.node.children[1].style.left = `${data.left}px`;
        this.node.children[1].style.top = `${data.top}px`;
        this.node.children[1].children[0].children[0].style.left = `calc(50% - 13px + ${data.diff}px)`;
        this.node.children[1].children[0].children[0].blur();
        this.reverse = data.reverse;
      }
    }
  );

  componentDidUpdate() {
    if (this.node === null && Boolean(this.props.anchorEl)) {
      // Node is not ready immediately, so we wait until it is
      const nodeLookup = window.setInterval(() => {
        // eslint-disable-next-line react/no-find-dom-node
        const node = ReactDOM.findDOMNode(this);
        if (node !== null) {
          runInAction(() => { this.node = node; });
          window.clearInterval(nodeLookup);
        }
      }, 1);
    } else if (this.node !== null && !this.props.anchorEl) {
      // reset on close
      runInAction(() => { this.node = null; });
    }
  }

  render() {
    const { classes, materialUid, applicationStore, handleMenuClose } = this.props;
    const { push } = this.props.routing;
    /**
     * Normally, one would use RootRef here instead of ReactDOM.findDOMNode(),
     * but somehow this remained null until the Modal was closed.
     */
    return (

      <Menu
        anchorReference="none"
        open={Boolean(this.props.anchorEl)}
        onClose={this.props.handleMenuClose}
        classes={{ paper: `${classes.popupMenu} ${this.reverse ? 'reverse' : ''}` }}
        BackdropProps={{ invisible: false, classes: { root: classes.popupMenuBackdrop } }}
      >
        <div className={`${classes.triangle} ${this.reverse ? 'reverse' : ''}`} />
        { this.props.sharingEnabled
        && (
          <MenuItem onClick={() => { handleMenuClose(); applicationStore.startSharing(materialUid); }} className={classes.popupMenuItem}>
            <SimappIcon icon="share" className={classes.popupMenuIcon} />
            <span className={classes.popupMenuText}>Share</span>
          </MenuItem>
        )}

        <MenuItem
          onClick={() => { handleMenuClose(); push(`/create-presentation/${materialUid}`); }}
          className={classes.popupMenuItem}
        >
          <SimappIcon icon="edit" className={classes.popupMenuIcon} />
          <span className={classes.popupMenuText}>Edit</span>
        </MenuItem>
        <MenuItem onClick={this.props.handleDelete} className={`${classes.popupMenuItem} ${classes.popupMenuItemDelete}`}>
          <SimappIcon icon="trash" className={classes.popupMenuIcon} />
          <span className={classes.popupMenuText}>Delete</span>
        </MenuItem>
      </Menu>
    );
  }
}

MaterialTileMenu.wrappedComponent.propTypes = {
  routing: PropTypes.object.isRequired,
  applicationStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  anchorEl: PropTypes.any,
  handleMenuClose: PropTypes.func,
  handleDelete: PropTypes.func.isRequired,
  sharingEnabled: PropTypes.bool.isRequired,
  materialUid: PropTypes.string.isRequired
};

MaterialTileMenu.wrappedComponent.defaultProps = {
  anchorEl: null,
  handleMenuClose: () => {}
};

export default withStyles(styles)(MaterialTileMenu);
