import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

@inject('contentTypeStore') @observer
class ContentTypesFilter extends Component {
  @observable open = false;
  disabledContentTypes = {};

  onFilterSet = (e) => {
    this.props.contentTypeStore.setFilter(e.target.value);
  }

  @action onHandleOpen = () => {
    this.disabledContentTypes = Object.assign(this.props.contentTypeStore.disabledContentTypes);
    this.open = true;
  }

  @action onHandleClose = () => {
    this.open = false;
  }

  typeFilter = () => this.props.contentTypeStore.contentTypes.map((type) => {
    /**
     * Previously, we just disabled menu items without content using
     *  disabled={this.props.contentTypeStore.disabledContentTypes[type.id]}
     * Now they are hidden
     */
    if (this.disabledContentTypes[type.id]) return null;

    return (
      <MenuItem key={type.id} value={type.id}>
        <Checkbox checked={this.props.contentTypeStore.filteredContentTypesForUI.indexOf(type.id) > -1} color="primary" />
        <ListItemText primary={type.name} />
      </MenuItem>
    );
  });

  render() {
    const { contentTypeStore, MenuProps } = this.props;
    return (
      <Select
        multiple
        displayEmpty
        value={contentTypeStore.filteredContentTypesForUI}
        onChange={this.onFilterSet}
        disableUnderline
        renderValue={() => 'Type'}
        MenuProps={MenuProps}
        open={this.open}
        onClose={this.onHandleClose}
        onOpen={this.onHandleOpen}
      >
        {this.typeFilter()}
      </Select>
    );
  }
}

ContentTypesFilter.wrappedComponent.propTypes = {
  contentTypeStore: PropTypes.object.isRequired,
  MenuProps: PropTypes.object.isRequired,
};
export default ContentTypesFilter;
