import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { isRetina } from 'shared/lib/theme/style-utils';

const styles = {
  outer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  logo: {
    width: 141,
    height: 30,
    marginBottom: '0.5em',
    backgroundImage: 'url(/icons/logo.png)',
    backgroundSize: 'contain',
    [isRetina]: {
      backgroundImage: 'url(/icons/logo@2x.png)'
    },
  },
  copy: {
    fontSize: '0.6rem'
  }
};

const year = (new Date()).getFullYear();

const ThermoFooter = props => (
  <div className={`${props.classes.outer} ${props.className}`}>
    <div className={props.classes.logo} />
    <Typography className={props.classes.copy}>&copy;{year} Thermo Fisher Scientific Inc. All rights reserved.</Typography>
  </div>
);

ThermoFooter.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string
};

ThermoFooter.defaultProps = {
  className: ''
};

export default withStyles(styles)(ThermoFooter);
