import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

const styles = {};

@inject('materialStore') @observer
class MaterialsSort extends Component {
  onChange = e => this.props.materialStore.setSort(e.target.value);

  menuItems = () => this.props.materialStore.sorts.map(sort => (
    <MenuItem
      key={sort.id}
      value={sort.id}
    >
      {sort.title}
    </MenuItem>
  ));

  render() {
    const { materialStore, MenuProps } = this.props;
    return (
      <Select
        value={materialStore.sort}
        onChange={this.onChange}
        disableUnderline
        MenuProps={MenuProps}
      >
        {this.menuItems()}
      </Select>

    );
  }
}

MaterialsSort.wrappedComponent.propTypes = {
  materialStore: PropTypes.object.isRequired,
  MenuProps: PropTypes.object.isRequired,
};
export default withStyles(styles)(MaterialsSort);
