import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { simappColors } from 'shared/lib/theme';

const styles = {
  buttonWrapper: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'inherit',
    alignItems: 'inherit',
    width: '100%'
  },
  button: {
    borderRadius: '4px',
    height: '56px',
    fontSize: '17px',
    transition: 'none',
    fontWeight: 'normal',
    backgroundColor: `${simappColors.azul}`,
    color: `${simappColors.white}`,
    '&:hover': {
      backgroundColor: `${simappColors.azul}`
    }
  },
  inline: {
    display: 'flex',
    flex: 1,
  },
  calculateButton: {
    color: `${simappColors.white}`,
    backgroundColor: `${simappColors.darkLimeGreen}`,
    '&:hover': {
      backgroundColor: `${simappColors.darkLimeGreen}`
    }
  },
  navigationButton: {
    color: `${simappColors.white}`,
    backgroundColor: `${simappColors.coolGrey}`,
    '&:hover': {
      backgroundColor: `${simappColors.coolGrey}`
    }
  },
  small: {
    height: '48px'
  },
  disabled: {
    backgroundColor: 'transparent',
    border: `1px solid ${simappColors.disableButtonGrey}`,
    color: `${simappColors.disableButtonGrey}`,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  disabledNoBorder: {
    backgroundColor: 'transparent',
    border: 'none',
    color: `${simappColors.disableButtonGrey}`,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  buttonProgress: {
    color: simappColors.azul,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
};

function getDisabledClasses(noBorder, classes) {
  return noBorder ? classes.disabledNoBorder : classes.disabled;
}

const SimAppButton = (props) => {
  const { classes, inline, isCalculateButton, isNavigationButton, small, disabled, className, onClick, children, noBorder, isCalculating } = props;
  return (

    <div className={`${classes.buttonWrapper} ${inline && classes.inline}`}>
      <Button
        className={`${classes.button} ${inline && classes.inline} ${isCalculateButton && classes.calculateButton} ${isNavigationButton && classes.navigationButton} ${small && classes.small} ${(disabled || isCalculating) && getDisabledClasses(noBorder, classes)} ${className}`}
        onClick={onClick}
        disabled={disabled || isCalculating}
      >
        {children}
      </Button>
      {isCalculating && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
};

SimAppButton.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isCalculateButton: PropTypes.bool,
  isNavigationButton: PropTypes.bool,
  inline: PropTypes.bool,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  noBorder: PropTypes.bool,
  isCalculating: PropTypes.bool
};

SimAppButton.defaultProps = {
  className: '',
  onClick: () => { },
  isCalculateButton: false,
  isNavigationButton: false,
  inline: false,
  small: false,
  disabled: false,
  noBorder: false,
  isCalculating: false
};

export default withStyles(styles)(SimAppButton);
