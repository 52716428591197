import React, { Component } from 'react';
import { LinearGradient, Line, Text, Defs, Stop, TSpan } from 'react-native-svg';
import PropTypes from 'prop-types';
import { calculateChartingMaximum } from 'shared/helper/copeptin-calculation-helper';

class CopeptinAbstractChart extends Component {
  getPropsForBackgroundLines() {
    const { propsForBackgroundLines = {} } = this.props.chartConfig;
    return {
      style: { stroke: this.props.chartConfig.color(0.2) },
      strokeDasharray: '5, 10',
      strokeWidth: '1',
      ...propsForBackgroundLines
    };
  }

  calcScaler = (data) => {
    if (this.props.fromZero) {
      return Math.max(...data, 0) - Math.min(...data, 0) || 1;
    }
    return Math.max(...data) - Math.min(...data) || 1;
  };

  calcBaseHeight = (data, height) => {
    const min = Math.min(...data);
    const max = Math.max(...data);
    if (min >= 0 && max >= 0) {
      return height;
    } if (min < 0 && max <= 0) {
      return 0;
    } if (min < 0 && max > 0) {
      return (height * max) / this.calcScaler(data);
    }
    return 0;
  };

  calcHeight = (val, data, height) => {
    const max = Math.max(...data);
    const min = Math.min(...data);
    if (min < 0 && max > 0) {
      return height * (val / this.calcScaler(data));
    } if (min >= 0 && max >= 0) {
      return this.props.fromZero
        ? height * (val / this.calcScaler(data))
        : height * ((val - min) / this.calcScaler(data));
    } if (min < 0 && max <= 0) {
      return this.props.fromZero
        ? height * (val / this.calcScaler(data))
        : height * ((val - max) / this.calcScaler(data));
    }
    return 0;
  };

  renderHorizontalLines = (config) => {
    const { data, width, height, paddingTop, paddingRight, chartHeightRatio = 0.8 } = config;
    const maxValue = Math.max.apply(null, data);
    const maxLineValue = calculateChartingMaximum(maxValue, this.props.minMaxLineValue);
    const count = (maxLineValue / this.props.horizontalLineStep) + 1;
    const heightPerUnit = height * chartHeightRatio / maxValue;
    const yOffset = (maxValue > maxLineValue) ? (heightPerUnit * Math.round(maxValue - maxLineValue)) : 0;
    return [...new Array(count)].map((_, i) => (
      <Line
        key={Math.random()}
        x1={`${paddingRight / 1.1}`}
        y1={`${((height * chartHeightRatio - yOffset) / (count - 1)) * i + paddingTop + yOffset}`}
        x2={`${width * (this.props.data.legend !== undefined && this.props.data.legend.length > 0 ? 0.6 : 1)}`}
        y2={`${((height * chartHeightRatio - yOffset) / (count - 1)) * i + paddingTop + yOffset}`}
        {...this.getPropsForBackgroundLines()}
      />
    ));
  };

  renderHorizontalLine = (config) => {
    const { width, height, paddingTop, paddingRight } = config;
    return (
      <Line
        key={Math.random()}
        x1={`${paddingRight}`}
        y1={`${height - height / 4 + paddingTop}`}
        x2={`${width}`}
        y2={`${height - height / 4 + paddingTop}`}
        {...this.getPropsForBackgroundLines()}
      />
    );
  };

  renderHorizontalLabels = (config) => {
    const {
      data,
      height,
      paddingTop,
      paddingRight,
      yLabelsOffset = 16,
      horizontalLabelRotation = 0,
      chartHeightRatio = 0.8
    } = config;
    const maxDataValue = Math.max.apply(null, data);
    const maxLineValue = calculateChartingMaximum(maxDataValue, this.props.minMaxLineValue);
    const count = Math.ceil(maxLineValue / this.props.horizontalLineStep) + 1;
    const heightPerUnit = height * chartHeightRatio / maxDataValue;
    const yOffset = (maxDataValue > maxLineValue) ? (heightPerUnit * Math.round(maxDataValue - maxLineValue)) : 0;
    const decimalPlaces = this.props.chartConfig.decimalPlaces === undefined
      ? 2
      : this.props.chartConfig.decimalPlaces;
    const yAxisLabel = this.props.yAxisLabel || '';
    const fontSize = 12;

    return [...new Array(count)].map((_, i) => {
      let yLabel;

      if (count === 1) {
        yLabel = `${yAxisLabel}${data[0].toFixed(decimalPlaces)}`;
      } else {
        let label;
        if (maxLineValue) {
          label = this.props.fromZero
            ? (maxLineValue / (count - 1)) * i + Math.min(...data, 0)
            : (maxLineValue / (count - 1)) * i + Math.min(...data);
        } else {
          label = this.props.fromZero
            ? (this.calcScaler(data) / (count - 1)) * i + Math.min(...data, 0)
            : (this.calcScaler(data) / (count - 1)) * i + Math.min(...data);
        }
        yLabel = `${yAxisLabel}${label.toFixed(decimalPlaces)}`;
      }
      const x = paddingRight - yLabelsOffset;
      const y = (height * chartHeightRatio - yOffset) / (count - 1) * (count - 1 - i) + paddingTop + yOffset + fontSize / 3;
      return (
        <Text
          rotation={horizontalLabelRotation}
          origin={`${x}, ${y}`}
          key={Math.random()}
          x={`${x}`}
          textAnchor="end"
          y={`${y}`}
          fontSize={fontSize}
          style={{ fill: this.props.chartConfig.color(0.5) }}
        >
          {yLabel}
        </Text>
      );
    });
  };

  renderVerticalLabels = (config) => {
    const {
      labels = [],
      width,
      height,
      paddingRight,
      paddingTop,
      horizontalOffset = 0,
      stackedBar = false,
      barWidth = 64,
      verticalLabelRotation = 0,
      colors,
      barChartSpace = 1,
      barChartSecondSpace = 1,
      useColors,
      chartHeightRatio = 0.8
    } = config;
    const fontSize = 12;
    let x = paddingRight - barWidth * 2;
    return labels.map((label, i) => {
      if (stackedBar) {
        x = (((width - paddingRight) / labels.length) * i + paddingRight + horizontalOffset) * 0.71;
      } else {
        x = i % 2 ? x + barWidth * (1 + barChartSpace) : x + barWidth * (1 + barChartSecondSpace);
      }
      const y = (height * chartHeightRatio) + paddingTop + fontSize * 2;
      let color = this.props.chartConfig.color(0.5);
      if (useColors) {
        color = colors[i % 2];
      }
      if (label.includes('\n')) {
        const text = label.split('\n');
        return (
          <Text
            origin={`${x}, ${y}`}
            rotation={verticalLabelRotation}
            key={Math.random()}
            x={`${x}`}
            y={`${y}`}
            fontSize={fontSize}
            style={{ fill: color }}
            textAnchor={verticalLabelRotation === 0 ? 'middle' : 'start'}
          >
            {text.map((value, index) => (<TSpan style={{ fill: color }} key={Math.random()} x={`${x}`} dy={`${index * 15}`}>{value}</TSpan>))}
          </Text>
        );
      }
      return (
        <Text
          origin={`${x}, ${y}`}
          rotation={`${verticalLabelRotation}`}
          key={Math.random()}
          x={`${x}`}
          y={`${y}`}
          fontSize={fontSize}
          style={{ fill: color }}
          textAnchor={verticalLabelRotation === 0 ? 'middle' : 'start'}
        >
          {label}
        </Text>
      );
    });
  };

  renderVerticalLines = (config) => {
    const { data, width, height, paddingTop, paddingRight } = config;
    return [...new Array(data.length)].map((_, i) => (
      <Line
        key={Math.random()}
        x1={`${Math.floor(
          ((width - paddingRight) / data.length) * i + paddingRight
        )}`}
        y1={`${0}`}
        x2={`${Math.floor(
          ((width - paddingRight) / data.length) * i + paddingRight
        )}`}
        y2={`${height - height / 4 + paddingTop}`}
        {...this.getPropsForBackgroundLines()}
      />
    ));
  };

  renderVerticalLine = (config) => {
    const { height, paddingTop, paddingRight } = config;
    return (
      <Line
        key={Math.random()}
        x1={`${Math.floor(paddingRight)}`}
        y1={`${0}`}
        x2={`${Math.floor(paddingRight)}`}
        y2={`${height - height / 4 + paddingTop}`}
        {...this.getPropsForBackgroundLines()}
      />
    );
  };

  renderDefs = (config) => {
    const {
      width,
      height,
      backgroundGradientFrom,
      backgroundGradientTo
    } = config;
    const fromOpacity = config.hasOwnProperty('backgroundGradientFromOpacity') // eslint-disable-line
      ? config.backgroundGradientFromOpacity
      : 1.0;
    const toOpacity = config.hasOwnProperty('backgroundGradientToOpacity') // eslint-disable-line
      ? config.backgroundGradientToOpacity
      : 1.0;

    return (
      <Defs>
        <LinearGradient
          id="backgroundGradient"
          x1="0"
          y1={height}
          x2={width}
          y2={0}
        >
          <Stop
            offset="0"
            stopColor={backgroundGradientFrom}
            stopOpacity={fromOpacity}
          />
          <Stop
            offset="1"
            stopColor={backgroundGradientTo}
            stopOpacity={toOpacity}
          />
        </LinearGradient>
        <LinearGradient
          id="fillShadowGradient"
          x1={0}
          y1={0}
          x2={0}
          y2={height}
        >
          <Stop
            offset="0"
            stopColor={this.props.chartConfig.color()}
            stopOpacity="0.1"
          />
          <Stop
            offset="1"
            stopColor={this.props.chartConfig.color()}
            stopOpacity="0"
          />
        </LinearGradient>
      </Defs>
    );
  };
}

CopeptinAbstractChart.propTypes = {
  chartConfig: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  yAxisLabel: PropTypes.string,
  fromZero: PropTypes.number,
  minMaxLineValue: PropTypes.number,
  horizontalLineStep: PropTypes.number
};

CopeptinAbstractChart.defaultProps = {
  yAxisLabel: '',
  fromZero: false,
  minMaxLineValue: undefined,
  horizontalLineStep: 50
};

export default CopeptinAbstractChart;
