import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { simappColors } from 'shared/lib/theme';
import Typography from '@material-ui/core/Typography';
import CalendarDay from './calendar-day';
import SimappIcon from '../_ui/simapp-icon';

const styles = {
  event: {
    backgroundColor: simappColors.white,
    marginBottom: '1rem',
    borderLeft: `4px solid ${simappColors.azul}`,
    padding: '0.5rem',
    borderRadius: '4px',
  },
  title: {
    fontWeight: 500,
    marginBottom: '0.3em',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative'
  },
  time: {
    color: simappColors.steelGrey,
  },
  divider: {
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
  },
  icon: {
    cursor: 'pointer',
    opacity: 0,
    color: simappColors.lightGrey,
    '&:hover': {
      color: simappColors.steelGrey,
    },
    '&.visible': {
      opacity: 1,
    },
  },
};

@observer
class Event extends Component {
  @observable buttonsVisible = false;

  @action showButtons = () => {
    this.buttonsVisible = true;
  };

  @action hideButtons = () => {
    this.buttonsVisible = false;
  };

  render() {
    const { classes, event, remove, edit } = this.props;
    return (
      <div
        className={classes.event}
        onMouseEnter={this.showButtons}
        onMouseLeave={this.hideButtons}
      >
        <div className={classes.header}>
          <Typography className={classes.time}>
            <CalendarDay date={event.start} />
            <span className={classes.divider}>-</span>
            <CalendarDay date={event.end} />
          </Typography>
          <div>
            {edit && (
              <SimappIcon
                className={`${classes.icon} ${this.buttonsVisible ? 'visible' : ''}`}
                icon="edit"
                title="Edit Event"
                onClick={() => edit(event)}
              />
            )}
            {remove && (
              <SimappIcon
                className={`${classes.icon} ${this.buttonsVisible ? 'visible' : ''}`}
                icon="trash"
                title="Delete Event"
                onClick={() => remove(event)}
              />
            )}
          </div>
        </div>
        <Typography className={classes.title}>{event.title}</Typography>
        {event.location && <Typography>{event.location}</Typography>}
      </div>
    );
  }
}

Event.propTypes = {
  event: PropTypes.object.isRequired,
  edit: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  classes: PropTypes.object
};

Event.defaultProps = {
  classes: {}
};

export default withStyles(styles)(Event);
