import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { simappColors } from 'shared/lib/theme';
import SimappIcon from 'web/components/_ui/simapp-icon';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import createPdf from 'web/helper/kryptor-pdf-helper';
import KryptorResultPrices from './kryptor-prices';
import KryptorResultConsumption from './kryptor-consumption';

const styles = {
  root: {
    width: '100%',
    height: '100vH',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    position: 'relative'
  },
  topBar: {
    position: 'absolute',
    top: '0',
    width: '100%',
    height: '56px',
    fontWeight: 'normal',
    borderBottom: `1px solid ${simappColors.paleGrey}`,
    backgroundColor: `${simappColors.white}`
  },
  contentDiv: {
    marginTop: '56px',
    marginBottom: '0',
    overflowY: 'auto'
  },
  backButton: {
    color: `${simappColors.azul}`,
    width: '100px',
    padding: '8px 12px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  backIcon: {
    width: '16px',
    height: '36px'
  },
  backText: {
    fontSize: '17px',
    color: `${simappColors.azul}`
  },
  toggleButtons: {
    display: 'inline',
    marginLeft: 'calc(50% - 100px - 200px)',
    marginRight: 'calc(50% - 190px - 200px)'
  },
  toggleButtonsSmall: {
    float: 'right'
  },
  toggleButton: {
    width: '200px',
    height: '32px',
    fontWeight: 'normal',
    minHeight: '0',
    margin: '12px 0',
    border: `1px solid ${simappColors.azul}`,
    padding: '0 8px',
    color: `${simappColors.azul}`,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&.selected': {
      backgroundColor: `${simappColors.azul}`,
      color: `${simappColors.white}`
    },
    '&.selected:hover': {
      backgroundColor: `${simappColors.azul}`
    },
    '&.small': {
      width: '120px',
      margin: '3px 0'
    }
  },
  leftToggleButton: {
    borderRadius: '4px 0 0 4px',

  },
  rightToggleButton: {
    borderRadius: '0 4px 4px 0',

  },
  shareText: {
    fontSize: '17px',
    fontWeight: 'normal',
    marginRight: '12px',
    color: `${simappColors.azul}`
  },
  shareButton: {
    color: `${simappColors.azul}`,
    width: '190px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
};

@inject('applicationStore', 'kryptorStore', 'routing') @observer
class KryptorResult extends Component {
  pdfMode;

  constructor(props) {
    super(props);
    this.state = {
      isConsumption: true,
      toggleRender: false
    };
    props.kryptorStore.updateResults();
  }

  getTopBar() {
    const { classes, kryptorStore } = this.props;
    return (
      <div className={classes.topBar}>
        <div>
          <Button className={classes.backButton} onClick={() => this.backClick()}>
            <KeyboardArrowLeft className={classes.backIcon} viewBox="8 0 10 24" />
            <Typography
              className={classes.backText}
            >
              {kryptorStore.getText('KryptorBackText')}
            </Typography>
          </Button>
          <span className={classes.toggleButtons}>
            <Button
              className={`${classes.toggleButton} ${classes.leftToggleButton} ${this.state.isConsumption ? 'selected' : ''}`}
              onClick={() => this.clickButton(true)}
            >{kryptorStore.getText('KryptorConsumptionText')}
            </Button>
            <Button
              className={`${classes.toggleButton} ${classes.rightToggleButton} ${this.state.isConsumption ? '' : 'selected'}`}
              onClick={() => this.clickButton(false)}
            >{kryptorStore.getText('KryptorPricesText')}
            </Button>
          </span>
          <Button className={classes.shareButton} onClick={() => this.shareClick()}>
            <Typography
              className={classes.shareText}
            >
              {kryptorStore.getText('KryptorShareOrPrintText')}
            </Typography>
            <SimappIcon icon="share" />
          </Button>
        </div>
      </div>
    );
  }

  setPdfMode(mode) {
    this.pdfMode = mode;
  }

  shareClick = () => {
    const pdfMode = this.state.isConsumption ? 0 : this.pdfMode;
    createPdf(this.props.kryptorStore, this.props.applicationStore, pdfMode);
  }

  backClick = () => {
    const { goBack } = this.props.routing;
    goBack(1);
  }

  clickButton = (state) => {
    if (this.state.isConsumption !== state) {
      this.setState(prevState => ({
        isConsumption: state,
        dilutionBox: prevState.dilutionBox,
        toggleRender: prevState.toggleRender
      }));
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        {this.getTopBar()}
        <div className={classes.contentDiv}>
          {this.state.isConsumption ? <KryptorResultConsumption /> : <KryptorResultPrices setPdfMode={mode => this.setPdfMode(mode)} />}
        </div>
      </div>
    );
  }
}

KryptorResult.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  kryptorStore: PropTypes.object.isRequired,
  applicationStore: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired
};

export default withStyles(styles)(KryptorResult);
