import { drawRectangle } from 'pdf-lib';

export const TextAlign = { Left: 1, Center: 2, Right: 3 };
const textColorTableHeader = [1, 1, 1];
const textColorTableRow = [0, 0, 0];
const backgroundColorTableHeader = [0.31, 0.51, 0.74];
const backgroundColor1TableRow = [0.86, 0.9, 0.95];
const backgroundColor2TableRow = [1, 1, 1];

function getAlternatingBgColor(rowCount) {
  return rowCount % 2 === 0 ? backgroundColor1TableRow : backgroundColor2TableRow;
}

export default class QcPdfTable {
  tableData;
  template;
  colSpacings;
  boldFont;
  lightFont;
  customCurrencyFont;
  tableFontBold;
  widthPerColSpacing;
  tableFontSize;
  tableCellMargin = 2;
  qcStore;

  constructor(tableData, template, lightFont, boldFont, customCurrencyFont, tableFontSize, qcStore) {
    this.tableData = tableData;
    this.template = template;
    this.lightFont = lightFont;
    this.boldFont = boldFont;
    this.customCurrencyFont = customCurrencyFont;
    this.tableFontSize = tableFontSize;
    this.colSpacings = this.getTotalColSpacings(this.tableData);
    this.widthPerColSpacing = (this.template.width - (this.template.outerMargin * 2)) / this.colSpacings;
    this.qcStore = qcStore;
  }

  create(tableContent, totalCostData) {
    this.template.currentFontSize = this.tableFontSize;
    this.createHeader();
    this.createTableContent(tableContent, totalCostData);
  }

  createHeader() {
    this.template.currentFont = this.boldFont;
    this.template.currentFontSize = this.tableFontSize;
    this.drawTableRow(this.tableData, this.boldFont, textColorTableHeader, backgroundColorTableHeader, true, false);
  }

  createTableContent(tableContent, totalCostData) {
    if (tableContent === undefined || tableContent.length === 0) {
      console.log('Error: tableContent is undefined or empty');
    }
    let rowCount = 0;
    tableContent.forEach((rowData) => {
      this.drawTableRow(rowData, this.boldFont, textColorTableRow, getAlternatingBgColor(rowCount), false, true);
      rowCount++;
    });
    this.drawTableRow(totalCostData, this.boldFont, textColorTableRow, getAlternatingBgColor(rowCount++), false, true);
  }

  drawTableRow(data, font, fontColor, backgroundColor, isTableHeaderCenterAll, isCurrencyRow) {
    this.template.currentFont = font;
    let currentX = this.template.outerMargin;
    const rowHeight = this.calculateRowHeight(data, font, isTableHeaderCenterAll);
    this.moveToDrawingPosition(rowHeight);
    for (let i = 0; i < data.length; i++) {
      const colWidth = this.widthPerColSpacing * this.tableData[i].colSize;
      this.drawCellRect(currentX, colWidth, rowHeight, backgroundColor);
      if (isCurrencyRow && [3, 4, 6].includes(i)) {
        this.template.currentFont = this.customCurrencyFont;
      }
      this.drawCellText(data[i].content, isCurrencyRow && [3, 4, 6].includes(i) ? this.customCurrencyFont : font, currentX, i, colWidth, rowHeight, isTableHeaderCenterAll, fontColor);
      this.template.currentFont = font;
      currentX += colWidth;
    }
  }

  calculateRowHeight(data, font, isTableHeaderCenterAll) {
    let maxTextRows = 1;
    for (let i = 0; i < data.length; i++) {
      const colWidth = this.widthPerColSpacing * this.tableData[i].colSize;
      const content = this.getFormattedCellText(data[i].content, i, isTableHeaderCenterAll);
      const textParts = this.template.getFittingTextParts(content, colWidth - 2);
      if (maxTextRows < textParts.length) {
        maxTextRows = textParts.length;
      }
    }
    return (font[1].heightOfFontAtSize(this.tableFontSize) + 2) * maxTextRows;
  }

  moveToDrawingPosition(rowHeight) {
    const oldY = this.template.currentY;
    this.template.currentY = this.template.getIncreasedYByHeightValue(rowHeight);
    // draw new table header if a page break occured
    if (oldY < this.template.currentY) {
      this.createHeader();
      this.template.currentY = this.template.getIncreasedYByHeightValue(rowHeight);
    }
  }

  drawCellRect(currentX, colWidth, rowHeight, backgroundColor) {
    const rect = drawRectangle({
      x: currentX,
      y: this.template.currentY,
      width: colWidth,
      height: rowHeight,
      rotateDegrees: 0,
      borderWidth: 0.5,
      colorRgb: backgroundColor,
      borderColorRgb: [0, 0, 0],
    });
    rect.forEach(operator => this.template.pageActions.push(operator));
  }

  drawCellText(text, font, currentX, cellIndex, colWidth, rowHeight, isTableHeaderCenterAll, fontColor) {
    if (text === undefined) {
      return;
    }
    const outputText = this.getFormattedCellText(text, cellIndex, isTableHeaderCenterAll);
    const tableCellMetaData = this.tableData[cellIndex];
    let labelPosY = this.template.currentY + (rowHeight - font[1].heightOfFontAtSize(this.tableFontSize));
    const textParts = this.template.getFittingTextParts(outputText, colWidth - 2);
    textParts.forEach((textPart) => {
      const labelPosX = this.getLabelPosX(isTableHeaderCenterAll ? TextAlign.Center : tableCellMetaData.align, currentX, colWidth, cellIndex, textPart, font);
      this.template.drawTextToPdf(textPart, labelPosX, labelPosY, 0, false, fontColor);
      labelPosY -= font[1].heightOfFontAtSize(this.tableFontSize);
    });
  }

  getFormattedCellText(text, cellIndex, isTableHeaderCenterAll) {
    let outputText = text;
    if (this.tableData[cellIndex].isCurrencyValue && !isTableHeaderCenterAll) {
      outputText = this.qcStore.getFormattedCurrencyValue(text);
    }
    return outputText;
  }

  getLabelPosX(align, currentX, colWidth, index, text, font) {
    switch (align) {
      case TextAlign.Center:
        return currentX + (this.widthPerColSpacing * this.tableData[index].colSize / 2) - (this.measureText(this.boldFont, text) / 2);
      case TextAlign.Left:
        return currentX + this.tableCellMargin;
      case TextAlign.Right:
        return currentX + colWidth - (this.measureText(font, text)) - this.tableCellMargin;
      default:
        console.error(`QcPdfTable: unknown align: ${align}`);
        return currentX;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getTotalColSpacings(tableData) {
    let totalColSpacings = 0;
    tableData.forEach((data) => {
      totalColSpacings += data.colSize;
    });
    return totalColSpacings;
  }

  measureText(font, text) {
    return font[1].widthOfTextAtSize(text, this.template.currentFontSize);
  }
}
