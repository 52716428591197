import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { simappColors } from 'shared/lib/theme';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { CustomerTableSorter, customerTableFields } from 'shared/helper/qc-customer-table-sorter';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import moment from 'moment';

const styles = {
  headRow: {
    backgroundColor: `${simappColors.paleGrey}`,
    height: '40px'
  },
  tableRowCell: {
    height: '56px',
    borderBottom: `1px solid ${simappColors.tableBorder}`,
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '8px 7px',
    textAlign: 'center',
    borderLeft: `1px solid ${simappColors.tableBorder}`,
    '&.last': {
      borderRight: `1px solid ${simappColors.tableBorder}`,
    },
    '&.colCustomerNumber': {
      width: '80px'
    },
    '&.colCustomerName': {
      width: '80px'
    },
    '&.colCustomerDescription': {
    },
    '&.colCustomerLastModified': {
      width: '80px'
    },
    '&.colCustomerActions': {
      width: '80px'
    },
    '&.grey': {
      backgroundColor: `${simappColors.paleGrey}`
    }
  },
  tableHeadCell: {
    fontSize: '10px',
    fontWeight: '500',
    padding: '8px 7px',
    borderBottom: `1px solid ${simappColors.tableBorder}`,
    textAlign: 'center',
    letterSpacing: '0.5pt',
    borderLeft: `1px solid ${simappColors.tableBorder}`,
    minWidth: '80px',
    '&.last': {
      borderRight: `1px solid ${simappColors.tableBorder}`,
    },
    '&.colCustomerNumber': {
      width: '150px'
    },
    '&.colCustomerName': {
      width: '250px'
    },
    '&.colCustomerDescription': {
      // no width = auto width
    },
    '&.colCustomerLastModified': {
      width: '200px'
    },
    '&.colCustomerActions': {
      width: '150px'
    }
  },
  unfoldMoreIcon: {
    width: '10px',
    height: '10px'
  },
  removeImageButton: {
    padding: '0',
    width: '24px',
    height: '24px',
    minWidth: 'unset',
    marginLeft: '5px',
    marginTop: '5px',
    minHeight: 'unset'
  },
  removeImage: {
    width: '24px',
    height: '24px',
    backgroundColor: 'transparent',
    '&:hover, &:focus, &:active': {
      backgroundColor: 'transparent'
    },
    '& span:first-child span': {
      display: 'none',
    }
  },
  selectButton: {
    padding: '0',
    display: 'inline',
    paddingLeft: '15px',
    color: `${simappColors.azul}`,
    cursor: 'pointer',
    marginTop: '-3px',
    minWidth: 'unset',
    '&:hover': {
      backgroundColor: 'unset'
    }
  }
};

export function getLocalTimeString(utcTimestamp) {
  const utcTime = moment.utc(utcTimestamp).toDate();
  return moment(utcTime).local().format('MM/DD/YYYY h:mm:ss a');
}

@inject('qcStore', 'routing') @observer
class QcCustomerTable extends Component {
  textInput = [];
  sorter = new CustomerTableSorter();

  constructor(props) {
    super(props);
    this.state = {
      refreshTable: false,
      deleteDialogOpen: false,
      customerNameToDelete: undefined
    };
  }

  getDeleteDialog = (trigger, message, customerName, okText, cancelText, onOk, onCancel) => (
    <Dialog open={trigger} onExited={() => {}}>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        <DialogContentText style={{ textAlign: 'center', marginTop: '10px' }}>{customerName}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onOk()}
          color="primary"
        >{okText}
        </Button>
        <Button onClick={onCancel} color="primary">{cancelText}</Button>
      </DialogActions>
    </Dialog>
  )

  // eslint-disable-next-line class-methods-use-this
  getTableRow(customer, index) {
    const { classes } = this.props;
    return (
      <TableRow key={index} className={classes.tableRowCell}>
        <TableCell className={`${classes.tableRowCell} colCustomerNumber`}>
          {customer.customer_number}
        </TableCell>
        <TableCell className={`${classes.tableRowCell} colCustomerName`}>
          {customer.customer_name}
        </TableCell>
        <TableCell className={`${classes.tableRowCell} colCustomerDescription`}>
          {customer.customer_description}
        </TableCell>
        <TableCell className={`${classes.tableRowCell} colCustomerLastModified`}>
          {getLocalTimeString(customer.updated_at)}
        </TableCell>
        <TableCell className={`${classes.tableRowCell} colCustomerActions last`}>
          <Button className={this.props.classes.selectButton} onClick={() => { this.deleteCustomer(customer); }}>{this.props.qcStore.getText('QcDelete')}</Button>
          <Button className={this.props.classes.selectButton} onClick={() => this.selectCustomer(customer)}>{this.props.qcStore.getText('QcSelect')}</Button>
        </TableCell>
      </TableRow>
    );
  }

  deleteCustomer(customer) {
    this.setState({ deleteDialogOpen: true, customerNameToDelete: `${customer.customer_number} - ${customer.customer_name}`, customerIdToDelete: customer.id });
    this.refreshTable();
  }

  selectCustomer(customer) {
    this.props.onCustomerSelected(customer.id);
  }

  refreshTable() {
    this.setState(prevState => ({ refreshTable: !prevState.refreshTable }));
  }

  getSortIconForField(fieldName) {
    return this.sorter.isSortedBy(fieldName) ? ExpandMoreIcon : UnfoldMoreIcon;
  }

  // eslint-disable-next-line class-methods-use-this
  getHeader() {
    const { classes, qcStore } = this.props;
    return (
      <TableHead className={classes.headRow}>
        <TableRow className={classes.tableHeadRow}>
          <TableCell className={`${classes.tableHeadCell} colCustomerNumber`} sortDirection={this.sorter.isSortedAscending(customerTableFields.CUSTOMER_NUMBER) ? 'asc' : 'desc'}>
            <TableSortLabel
              active
              direction={this.sorter.isSortedAscending(customerTableFields.CUSTOMER_NUMBER) ? 'asc' : 'desc'}
              onClick={() => { this.sorter.setFieldName(customerTableFields.CUSTOMER_NUMBER); this.refreshTable(); }}
              IconComponent={this.getSortIconForField(customerTableFields.CUSTOMER_NUMBER)}
            >
              {qcStore.getText('QCCustomerNumber')}
            </TableSortLabel>
          </TableCell>
          <TableCell className={`${classes.tableHeadCell} colCustomerName`}>
            <TableSortLabel
              active
              direction={this.sorter.isSortedAscending(customerTableFields.CUSTOMER_NAME) ? 'asc' : 'desc'}
              onClick={() => { this.sorter.setFieldName(customerTableFields.CUSTOMER_NAME); this.refreshTable(); }}
              IconComponent={this.getSortIconForField(customerTableFields.CUSTOMER_NAME)}
            >
              {qcStore.getText('QCCustomerName')}
            </TableSortLabel>
          </TableCell>
          <TableCell className={`${classes.tableHeadCell} colCustomerDescription`}>
            <TableSortLabel
              active
              direction={this.sorter.isSortedAscending(customerTableFields.CUSTOMER_DESCRIPTION) ? 'asc' : 'desc'}
              onClick={() => { this.sorter.setFieldName(customerTableFields.CUSTOMER_DESCRIPTION); this.refreshTable(); }}
              hideSortIcon={false}
              IconComponent={this.getSortIconForField(customerTableFields.CUSTOMER_DESCRIPTION)}
            >
              {qcStore.getText('QCCustomerDescription')}
            </TableSortLabel>
          </TableCell>
          <TableCell className={`${classes.tableHeadCell} colCustomerLastModified`}>
            <TableSortLabel
              active
              direction={this.sorter.isSortedAscending(customerTableFields.CUSTOMER_LAST_MODIFIED) ? 'asc' : 'desc'}
              onClick={() => { this.sorter.setFieldName(customerTableFields.CUSTOMER_LAST_MODIFIED); this.refreshTable(); }}
              IconComponent={this.getSortIconForField(customerTableFields.CUSTOMER_LAST_MODIFIED)}
            >
              {qcStore.getText('QCCustomerLastModified')}
            </TableSortLabel>
          </TableCell>
          <TableCell className={`${classes.tableHeadCell} colCustomerActions last`}>
            {qcStore.getText('QCCustomerActions')}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }

  getTable(customers) {
    return (
      <Table>
        {this.getHeader()}
        <TableBody>
          {customers.map((customer, index) => (
            this.getTableRow(customer, index)
          ))}
        </TableBody>
      </Table>
    );
  }

  render() {
    const { data, qcStore } = this.props;
    return (
      <div>
        {this.getTable(this.sorter.sort(data))}
        {this.getDeleteDialog(this.state.deleteDialogOpen,
          qcStore.getText('QcCustomerDeleteConfirmation'),
          this.state.customerNameToDelete,
          qcStore.getText('QcConfirmationOk'),
          qcStore.getText('QcConfirmationCancel'),
          () => { this.props.deleteCustomer(this.state.customerIdToDelete); this.setState({ deleteDialogOpen: false }); },
          () => { this.setState({ deleteDialogOpen: false }); })}

      </div>
    );
  }
}

QcCustomerTable.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  qcStore: PropTypes.object.isRequired,
  deleteCustomer: PropTypes.func,
  data: PropTypes.array,
  onCustomerSelected: PropTypes.func
};

QcCustomerTable.defaultProps = {
  deleteCustomer: undefined,
  data: undefined,
  onCustomerSelected: () => console.log('no action defined for customer selection')
};

export default withStyles(styles)(QcCustomerTable);
