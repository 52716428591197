import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import simappColors from 'shared/lib/theme/simapp-colors';
import simappConstants from 'shared/lib/theme/simapp-constants';
import SimappIcon from 'web/components/_ui/simapp-icon';
import SidebarListitemSmall from 'web/components/_ui/sidebar-listitem-small';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  listItem: {
    paddingLeft: simappConstants.sidebarPadding,
    paddingRight: simappConstants.sidebarPadding,
    width: '100%'
  },
  deleteButton: {
    position: 'absolute',
    top: 8,
    right: 8,
    color: simappColors.white,
    backgroundColor: 'rgba(200, 200, 200, 0.5)',
    borderRadius: '50%',
    '&:hover *': {
      color: simappColors.strawberry
    }
  }
};

@inject('materialStore', 'applicationStore', 'routing') @observer
class ContentPieceTileInner extends Component {
  @observable deleteButtonVisible = false;

  @action showDeleteButton = () => {
    this.deleteButtonVisible = !this.props.applicationStore.sharingMode;
  }

  @action hideDeleteButton = () => {
    this.deleteButtonVisible = false;
  }

  @action removePiece = (e, uid, isCUrrent) => {
    e.stopPropagation();
    this.props.materialStore.removeFromCurrent(uid);
    if (isCUrrent) {
      if (this.props.materialStore.current.length) {
        this.props.routing.push(`/presentation/${this.props.materialStore.current[0]}`);
      } else {
        this.props.routing.push('/presentation');
      }
    }
  };

  render() {
    const { material, classes } = this.props;
    const { location, push } = this.props.routing;
    const current = location.pathname.split('/').pop() === material.uid;
    return (
      <div className={classes.root} onMouseEnter={this.showDeleteButton} onMouseLeave={this.hideDeleteButton}>
        <SidebarListitemSmall
          onClick={() => push(`/presentation/${material.uid}`)}
          primary={material.title}
          shareable={material.shareable}
          uid={material.uid}
          current={current}
          className={classes.listItem}
        />

        { this.deleteButtonVisible
        && (
          <ButtonBase className={classes.deleteButton} title="Close Material" onClick={(e) => { this.removePiece(e, material.uid, current); }}>
            <SimappIcon icon="x" />
          </ButtonBase>
        )
        }
      </div>
    );
  }
}

ContentPieceTileInner.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired,
  applicationStore: PropTypes.object.isRequired,
  materialStore: PropTypes.object.isRequired,
  material: PropTypes.object.isRequired,
};

ContentPieceTileInner.wrappedComponent.defaultProps = {
};

export default withStyles(styles)(ContentPieceTileInner);
