import fillPdf from 'shared/helper/kryptor-pdf-helper';
import KryptorPdf, { pdfModes } from 'shared/helper/kryptor-pdf-creation-helper';

function saveFile(name, bytes) {
  const file = new Blob([bytes], { type: 'application/pdf' });
  if (window.navigator.msSaveOrOpenBlob) { // IE10+
    window.navigator.msSaveOrOpenBlob(file, name);
  } else { // Others
    const a = document.createElement('a');
    const url = URL.createObjectURL(file);
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}

// pdfModes: 0 = test, 1 = result
export default function createPdf(kryptorStore, applicationStore, pdfMode, fileName) {
  const name = fileName === undefined ? `price_${pdfModes[pdfMode]}.pdf` : fileName;
  const pdf = new KryptorPdf(false, pdfModes[pdfMode]);
  fillPdf(pdf, kryptorStore, applicationStore);
  const bytes = pdf.create();
  saveFile(name, bytes);
}
