import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { simappColors } from 'shared/lib/theme';

const styles = {
  flex: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: 35,
    paddingTop: 2
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: '50%',
    backgroundColor: simappColors.pumpkin,
  },
};

const CalendarEvent = (props) => {
  {
    const { classes, onSelect, event } = props;
    return (
      <div className={classes.flex}>
        <div
          role="button"
          tabIndex="0"
          className={classes.dot}
          onClick={e => onSelect && onSelect(event, e)}
          onKeyDown={e => onSelect && onSelect(event, e)}
        />
      </div>
    );
  }
};

export default withStyles(styles)(CalendarEvent);
