import { observable, runInAction, toJS, action } from 'mobx';
import { persist } from 'mobx-persist';
import rest from 'shared/lib/rest';
import { merge } from 'shared/lib/helpers';
import createStorage from './create-storage';

const route = '/language';

class LanguageStore {
  constructor() {
    createStorage('languageStore', this).then(() => {
    });
  }

  @persist('list') @observable languages = [];

  @observable filteredLanguages = [];
  @observable disabledLanguages = {};

  loadData(forceReload = false, lastUpdated = null) {
    const startTime = new Date().getTime();
    return new Promise((resolve) => {
      let params = '?';
      if (this.hasDataLoaded && !forceReload && lastUpdated !== null) {
        params += `since=${lastUpdated}`;
      // } else if (forceReload) {
      //   runInAction(() => {
      //     this.languages = [];
      //   });
      }

      rest.get(route + params).then((response) => {
        runInAction(() => {
          if (response.data.length > 0) {
            merge(this.languages, response.data, 'id', 'name');
            this.resetFilters();
          }
          console.log(`${new Date().getTime() - startTime}ms - ${this.constructor.name}`);
          resolve();
        });
      }).catch((error) => {
        console.log('Could not load languages', error.response.data);
        resolve();
      });
    });
  }

  get hasDataLoaded() {
    return !!this.languages.length;
  }

  getLanguageName(id) {
    const result = this.languages.find(l => l.id === id);
    return typeof result !== 'undefined' ? result.name : '';
  }

  get filteredLanguagesForUI() {
    return this.filteredLanguages.length === this.languages.length ? [] : toJS(this.filteredLanguages);
  }

  @action setFilter(f) {
    if (f.length === 0) {
      this.resetFilters();
    } else {
      this.filteredLanguages = f;
    }
  }

  @action toggleFilter(id) {
    if (this.filteredLanguagesForUI.length > 0) {
      const index = this.filteredLanguages.indexOf(id);
      if (index > -1) {
        this.filteredLanguages.splice(index, 1);
        if (this.filteredLanguages.length === 0) {
          this.resetFilters();
        }
      } else {
        this.filteredLanguages.push(id);
      }
    } else {
      this.filteredLanguages = [id];
    }
  }

  @action resetFilters = () => {
    if (this.filteredLanguages.length !== this.languages.length) {
      this.filteredLanguages = this.languages.map(l => l.id);
    }
  }
}

export default new LanguageStore();
