import pctConstants from 'shared/lib/resources/pct-constants';

export function isPercentage(type) {
  return type === pctConstants.PctTableRowPercentage;
}

export function calculateValue(value0, value1) {
  return Math.round(value0 * 100 - value1 * 100) / 100;
}

export function calculatePercentage(numberPatientsIcu, occuranceSepsisPercentage) {
  return numberPatientsIcu * occuranceSepsisPercentage / 100;
}

export function checkChange(pctStore, dateFieldName, pctExceptions = [], noPctExceptions = []) {
  let changed = false;
  const dataField = dateFieldName;
  let i = 0;
  let valuePct = pctStore.getPctValue(dataField, i);
  let valueNoPct = pctStore.getNoPctValue(dataField, i);
  while (valuePct !== undefined && valueNoPct !== undefined && !changed) {
    changed = (pctExceptions.includes(i) ? false : pctStore.objectExists(dataField, i, false, true))
      || (noPctExceptions.includes(i) ? false : pctStore.objectExists(dataField, i, false, false));
    i++;
    valuePct = pctStore.getPctValue(dataField, i);
    valueNoPct = pctStore.getNoPctValue(dataField, i);
  }
  return changed;
}
