import { action, computed, observable, runInAction, autorun } from 'mobx';
import moment from 'moment';
import { persist } from 'mobx-persist';
import Adal from 'shared/lib/adal-request';
import { merge } from 'shared/lib/helpers';
import createStorage from './create-storage';
import applicationStore from './application-store';

class CalendarStore {
  @observable date = null; // new Date();

  constructor() {
    createStorage('calendarStore', this).then(() => {
      this.loadData();
    });
  }

  @persist('list') @observable events = [];

  datetimeFormat = 'YYYY-MM-DDTHH:mm:ss';

  @action loadData(forceReload = false, lastUpdated = null) {
    if (this.hasDataLoaded && !forceReload && !lastUpdated) {
      // return;
    }
    this.date = new Date();
    this.date.setHours(0, 0, 0, 0);
  }

  toUiEvent = event => ({
    id: event.Id,
    title: event.Subject,
    description: event.BodyPreview,
    start: moment.utc(event.Start.DateTime).local().format(this.datetimeFormat),
    end: moment.utc(event.End.DateTime).local().format(this.datetimeFormat),
    location: event.Location && event.Location.DisplayName ? event.Location.DisplayName : null,
    attendees: event.Attendees.map(emailObj => emailObj.EmailAddress.Address).filter(email => email !== applicationStore.appUser.get('email')).join(', ')
  });

  toApiEvent = event => ({
    Id: event.id || null,
    Subject: event.title,
    Body: {
      ContentType: 'text',
      Content: event.description
    },
    Start: {
      DateTime: moment(event.start).utc().format(this.datetimeFormat),
      TimeZone: 'UTC'
    },
    End: {
      DateTime: moment(event.end).utc().format(this.datetimeFormat),
      TimeZone: 'UTC'
    },
    Location: {
      DisplayName: event.location,
    },
    Attendees: event.attendees.split(/[,;]+/).map(email => ({
      EmailAddress: {
        Address: email.trim(),
      },
      Type: 'Required'
    }))
  });

  fetchCalendar = url => new Promise((resolve) => {
    const events = [];
    const settings = {
      url,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    };
    Adal.adalRequest(settings).then((result) => {
      if (typeof result !== 'undefined') {
        result.value.forEach((event) => { events.push(this.toUiEvent(event)); });
      }
      resolve(events);
    });
  });

  loadEventsForMonth = async (date) => {
    /**
     * 1. collect all calendars
     * 2. collect all events in these calendars async
     * 3. merge
     */
    const momentObj = moment(date);
    const startDatetime = momentObj.startOf('month').format(this.datetimeFormat);
    const endDatetime = momentObj.endOf('month').format(this.datetimeFormat);
    const settings = {
      url: 'https://outlook.office.com/api/v2.0/me/calendars',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    };
    const calendars = await Adal.adalRequest(settings);
    const eventPromises = [];
    if (typeof calendars.value === 'undefined') return;
    calendars.value.forEach((calendar) => {
      const calendarId = calendar.Id;
      eventPromises.push(this.fetchCalendar(`https://outlook.office.com/api/v2.0/me/calendars/${calendarId}/calendarview?startDateTime=${startDatetime}&endDateTime=${endDatetime}&$top=1000`));
    });

    await Promise.all(eventPromises).then((responses) => {
      runInAction(() => {
        // Before we can merge events, we must delete all in the current month
        // They might have been deleted in another UI and deleted are not transmitted
        const monthStart = momentObj.startOf('month').valueOf();
        const monthEnd = momentObj.endOf('month').valueOf();
        this.events.forEach((event, index) => {
          if (monthStart < new Date(event.end).getTime() && monthEnd > new Date(event.start).getTime()) {
            this.events.splice(index, 1);
          }
        });
        responses.forEach((response) => {
          merge(this.events, response);
        });
      });
    });
  }

  fetchEvents = autorun(() => {
    if (this.date === null || !applicationStore.isAuthenticated) return;
    this.loadEventsForMonth(this.date);
  });

  get hasDataLoaded() {
    return !!this.events.length;
  }

  @computed get filterEventsForCurrentDay() {
    // get start and end of this.date and and check if any event has an overlapping period
    const momentObj = moment(this.date);
    const dayStart = momentObj.startOf('day').valueOf();
    const dayEnd = momentObj.endOf('day').valueOf();
    return this.events
      .filter(event => dayStart < new Date(event.end).getTime() && dayEnd > new Date(event.start).getTime())
      .sort((a, b) => {
        if (a.start < b.start) { return -1; }
        if (a.start > b.start) { return 1; }
        if (a.title < b.title) { return -1; }
        if (a.title > b.title) { return 1; }
        return 0;
      });
  }

  @action addEvent = event => new Promise((resolve, reject) => {
    const apiEvent = this.toApiEvent(event);

    const settings = {
      url: 'https://outlook.office.com/api/v2.0/me/events',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      data: JSON.stringify(apiEvent)
    };
    Adal.adalRequest(settings)
      .then((result) => {
        runInAction(() => {
          event.id = result.Id;
          this.events = [...this.events, event];
          resolve();
        });
      })
      .catch((error) => {
        console.log('Calendar Error', error);
        reject(error);
      });
  });

  @action updateEvent = event => new Promise((resolve, reject) => {
    const apiEvent = this.toApiEvent(event);

    const settings = {
      url: `https://outlook.office.com/api/v2.0/me/events/${apiEvent.Id}`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      data: JSON.stringify(apiEvent)
    };
    Adal.adalRequest(settings)
      .then((result) => {
        runInAction(() => {
          event.id = result.Id;
          const filteredEvents = this.events.filter(existingEvent => existingEvent.id !== event.id);
          this.events = [...filteredEvents, event];
          resolve();
        });
      })
      .catch((error) => {
        console.log('Calendar Error', error);
        reject(error);
      });
  });

  @action deleteEvent = event => new Promise((resolve, reject) => {
    const settings = {
      url: `https://outlook.office.com/api/v2.0/me/events/${event.id}`,
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'DELETE'
    };
    Adal.adalRequest(settings)
      .then(() => {
        runInAction(() => {
          this.events = this.events.filter(existingEvent => existingEvent.id !== event.id);
          resolve();
        });
      })
      .catch((error) => {
        console.log('Calendar Error', error);
        reject(error);
      });
  });

  // Dashboard related

  openOnlyCalendar = () => {
    applicationStore.setDashboardFullView(applicationStore.dashboardFullOptions.calendar);
  }

  @action backToDashboard = () => {
    applicationStore.setDashboardFullView(null);
  }

  // eslint-disable-next-line class-methods-use-this
  @computed get onlyCalendar() {
    return applicationStore.dashboardFullView === applicationStore.dashboardFullOptions.calendar;
  }
}

export default new CalendarStore();
