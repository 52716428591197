import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  buttonWrapper: {
    position: 'relative',
    marginLeft: 8,
    marginRight: 8
  }
};

const CancelButton = observer((props) => {
  const { form, classes, label, ...buttonProps } = props;

  return (
    <div className={classes.buttonWrapper}>
      <Button variant="outlined" color="primary" disabled={form.saveInProgess} {...buttonProps}>{label}</Button>
    </div>
  );
});

CancelButton.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string
};

CancelButton.defaultProps = {
  label: 'Cancel'
};

export default withStyles(styles)(CancelButton);
