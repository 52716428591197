import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const buttonClick = (props) => {
  if (props.onClick === undefined) {
    props.close();
  } else {
    props.onClick();
  }
};

const AlertDialog = observer(props => (
  <Dialog
    open={props.trigger}
    onClose={props.close}
    maxWidth={props.width}
  >
    <DialogTitle>{props.title}</DialogTitle>
    <DialogContent>
      {props.message.length > 0
        ? (
          <DialogContentText id="alert-dialog-description">
            {props.message}
          </DialogContentText>
        )
        : props.messageObj
      }
    </DialogContent>
    <DialogActions>
      { props.additionalActions.map(action => (
        <Button onClick={action.action} key={action.label} variant="outlined" color="primary">
          {action.label}
        </Button>
      ))}
      <Button onClick={() => { buttonClick(props); }} variant="contained" color="primary">
        {props.okLabel}
      </Button>
    </DialogActions>
  </Dialog>
));

AlertDialog.propTypes = {
  trigger: PropTypes.bool.isRequired,
  message: PropTypes.string,
  messageObj: PropTypes.object, // can be used instead of message to transfer React Nodes for more complex content
  title: PropTypes.string,
  okLabel: PropTypes.string,
  close: PropTypes.func.isRequired,
  additionalActions: PropTypes.array,
  width: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', false]),
  onClick: PropTypes.func,
  buttonText: PropTypes.string
};

AlertDialog.defaultProps = {
  title: '',
  okLabel: 'OK',
  additionalActions: [],
  width: 'sm',
  onClick: undefined,
  message: '',
  messageObj: null
};

export default AlertDialog;
