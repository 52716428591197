import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { simappColors } from 'shared/lib/theme';
import { inject, observer } from 'mobx-react';
import { intercept } from 'mobx';
import { routing, pctStore } from 'shared/stores';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import PanoramaFishEye from '@material-ui/icons/PanoramaFishEye';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '10px',
  },
  navigationbutton: {
    borderRadius: '4px',
    height: '56px',
    fontSize: '17px',
    transition: 'none',
    fontWeight: 'normal',
    backgroundColor: `${simappColors.coolGrey}`,
    color: `${simappColors.white}`,
    '&:hover': {
      backgroundColor: `${simappColors.coolGrey}`
    }
  },
  back: {
    backgroundColor: `${simappColors.steelGrey}`,
    '&:hover': {
      backgroundColor: `${simappColors.steelGrey}`
    }
  },
  fulfilled: {
    backgroundColor: `${simappColors.darkLimeGreen}`,
    '&:hover': {
      backgroundColor: `${simappColors.darkLimeGreen}`
    }
  },
  usButton: {
    backgroundColor: `${simappColors.coolGrey}`,
    '&:hover': {
      backgroundColor: `${simappColors.coolGrey}`
    }
  },
  disabled: {
    backgroundColor: 'transparent',
    border: `1px solid ${simappColors.disableButtonGrey}`,
    color: `${simappColors.disableButtonGrey}`,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  inline: {
    display: 'flex',
    flex: 1,
  },
  fullLength: {
    width: '100%'
  },
  halfLength: {
    width: '50%'
  },
  usPageSelect: {
    width: '7%',
  },
  usPageSelected: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '-2px'
  },
  circle: {
    height: '44px',
    width: '44px'
  },
  posAbsolut: {
    position: 'absolute'
  }
};

@inject('pctStore') @observer
class PctNavButton extends Component {
  usNavigationButtons;

  resetPctState = intercept(routing, 'location', (newLocation) => {
    try {
      if (((newLocation.newValue.pathname.endsWith('pct-algorithm-sepsis')
      || newLocation.newValue.pathname.endsWith('pct-algorithm-lrti')
      || newLocation.newValue.pathname.endsWith('pct-algorithm'))
      && pctStore.image === '')
      || ((newLocation.newValue.pathname.endsWith('population')
        || newLocation.newValue.pathname.endsWith('healthcare')
        || newLocation.newValue.pathname.endsWith('pct-calculator/results')
        || newLocation.newValue.pathname.endsWith('clinicaleffect')
        || newLocation.newValue.pathname.endsWith('clinicaleffect-lrti')
        || newLocation.newValue.pathname.endsWith('clinicaleffect-sepsis'))
        && pctStore.jsonCalculatorData === null)) {
        pctStore.resetData();
        routing.push('/he-models/pct-calculator');
      }
    } catch {
    // not ready on first page load
    }
    return newLocation;
  });

  getUsNavigationButtons() {
    this.usNavigationButtons = [];
    this.usNavigationButtons.push('/he-models/pct-calculator/population');
    this.usNavigationButtons.push('/he-models/pct-calculator/healthcare');
    this.usNavigationButtons.push('/he-models/pct-calculator/clinicaleffect-lrti');
    this.usNavigationButtons.push('/he-models/pct-calculator/clinicaleffect-sepsis');
    this.usNavigationButtons.push('/he-models/pct-calculator/results');
  }

  getUsSelectedButton(index) {
    return (
      <div className={this.props.classes.usPageSelected}>
        <PanoramaFishEye className={this.props.classes.circle} />
        <div className={this.props.classes.posAbsolut}>{index + 1}</div>
      </div>
    );
  }

  render() {
    const { nextText, backText } = this.props;
    this.getUsNavigationButtons();
    if (this.props.backButton || this.props.isUS) {
      if (this.props.isUS) {
        return (
          <div className={`${this.props.classes.root} ${this.props.classes.inline}`}>
            <Button
              onClick={this.props.screenNumber !== 5 ? () => this.props.backOnClick() : () => this.props.nextOnClick(this.props.nextOnClickLocation)}
              className={`${this.props.classes.navigationbutton} ${this.props.classes.usButton} ${this.props.classes.inline}`}
            >
              <ArrowBack />
            </Button>
            {this.usNavigationButtons.map((value, index) => {
              const buttonIndex = index;
              return (
                <Button
                  key={buttonIndex}
                  onClick={() => {
                    if (index + 1 !== this.props.screenNumber) {
                      this.props.nextOnClick(value);
                    }
                  }}
                  className={`${this.props.classes.navigationbutton} ${this.props.classes.usButton} ${this.props.classes.usPageSelect}`}
                >{index + 1 === this.props.screenNumber ? this.getUsSelectedButton(index) : <div>{index + 1}</div>}
                </Button>
              );
            })}
            <Button
              onClick={this.props.screenNumber !== 5 ? () => this.props.nextOnClick(this.props.nextOnClickLocation) : () => { }}
              className={`${this.props.classes.navigationbutton}
                          ${this.props.classes.usButton}
                          ${this.props.classes.inline}
                          ${this.props.screenNumber !== 5 ? '' : this.props.classes.disabled}`}
            >
              <ArrowForward />
            </Button>
          </div>
        );
      }
      return (
        <div className={`${this.props.classes.root} `}>
          <Button onClick={this.props.backOnClick} className={`${this.props.classes.navigationbutton} ${this.props.classes.halfLength} ${this.props.classes.back} `}>
            {backText}
          </Button>
          <Button onClick={() => this.props.nextOnClick(this.props.nextOnClickLocation)} className={`${this.props.classes.navigationbutton} ${this.props.classes.halfLength} `}>
            {nextText}
          </Button>
        </div>
      );
    }
    return (
      <div className={`${this.props.classes.root} `}>
        <Button onClick={() => this.props.nextOnClick(this.props.nextOnClickLocation)} className={`${this.props.classes.navigationbutton} ${this.props.classes.fullLength} `}>
          {nextText}
        </Button>
      </div>
    );
  }
}

PctNavButton.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  nextText: PropTypes.string.isRequired,
  isUS: PropTypes.bool,
  backText: PropTypes.string,
  nextOnClick: PropTypes.func,
  backButton: PropTypes.bool,
  backOnClick: PropTypes.func,
  screenNumber: PropTypes.number,
  nextOnClickLocation: PropTypes.string
};

PctNavButton.wrappedComponent.defaultProps = {
  isUS: false,
  backText: '',
  backButton: false,
  backOnClick: () => { },
  nextOnClick: () => { },
  screenNumber: 0,
  nextOnClickLocation: undefined
};

export default withStyles(styles)(PctNavButton);
