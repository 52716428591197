import React from 'react';
import { Switch, Route, Router, Redirect } from 'react-router-dom';
import { history } from './main-router';
import loginLocal from './login/login-local';
import forgotPassword from './login/forgot-password';
import login from './login/login';

const LoginRouter = () => (
  <Router history={history}>
    <Switch>
      <Route path="/login" component={loginLocal} />
      <Route path="/forgot-password" component={forgotPassword} />
      <Route path="/" exact component={login} />
      <Redirect to="/" />
    </Switch>
  </Router>
);

export default LoginRouter;
