import { PDFDocumentFactory, PDFDocumentWriter, drawText, drawImage, drawRectangle } from 'pdf-lib';
import { kryptorStore } from 'shared/stores';
import { addValueSeparators } from 'shared/helper/kryptor-pdf-helper';
import base64 from 'base64-js';

const images = {
  logo: { content: base64.toByteArray(kryptorStore.getPdfData('KryptorPdfLogoImage').replace('data:image/jpeg;base64,', '')) }
};

const mmToPixelMultiplicator = 2.83;
const webToNativeSizeMultiplicator = 1.333;
const outerMargin = 25 * mmToPixelMultiplicator;
const a4Height = 297 * mmToPixelMultiplicator;
const a4Width = 210 * mmToPixelMultiplicator;
const defaultFontSize = 7 * webToNativeSizeMultiplicator;
const fontSizeTitle = 11 * webToNativeSizeMultiplicator;
const tableOffset = 0 * mmToPixelMultiplicator;
const xContentBlock1 = outerMargin * 2.75; // right aligned
const xContentBlock2 = xContentBlock1 + outerMargin * 0.55;
const xContentBlock3 = xContentBlock2 + outerMargin * 0.75;
const xContentBlock4 = xContentBlock3 + outerMargin * 1.25;
const xContentBlock5 = xContentBlock4 + outerMargin * 1.25; // right aligned
const xContentBlock6 = a4Width - outerMargin; // right aligned
const block4To5Distance = xContentBlock5 - xContentBlock4;
const block3To4Distance = xContentBlock4 - xContentBlock3;
const separatorMargin = 4 * webToNativeSizeMultiplicator;
const footerYPos = outerMargin;

export const pdfModes = ['test', 'result'];

const lightFontBytes = base64.toByteArray(require('shared/lib/resources/HelveticaNeueBase64.json').ttfNormal);
const boldFontBytes = base64.toByteArray(require('shared/lib/resources/HelveticaNeueBase64.json').ttfBold);
const lightItalicFontBytes = base64.toByteArray(require('shared/lib/resources/HelveticaNeueBase64.json').ttfItalic);
const boldItalicFontBytes = base64.toByteArray(require('shared/lib/resources/HelveticaNeueBase64.json').ttfBoldItalic);

const lightArialFontBytes = base64.toByteArray(require('shared/lib/resources/ArialBase64.json').ttfNormal);
const boldArialFontBytes = base64.toByteArray(require('shared/lib/resources/ArialBase64.json').ttfBold);
const lightItalicArialFontBytes = base64.toByteArray(require('shared/lib/resources/ArialBase64.json').ttfItalic);
const boldItalicArialFontBytes = base64.toByteArray(require('shared/lib/resources/ArialBase64.json').ttfBoldItalic);

const lightFontName = 'light';
const boldFontName = 'bold';
const lightItalicFontName = 'lightItalic';
const boldItalicFontName = 'boldItalic';

const lightArialFontName = 'arialLight';
const boldArialFontName = 'arialBold';
const lightItalicArialFontName = 'arialLightItalic';
const boldItalicArialFontName = 'arialBoldItalic';

export default class KryptorPdf {
  brahms = kryptorStore.getPdfData('KryptorPdfBrahms');
  dateOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
  displaySeparators = false;
  pdfMode;
  kryptorStore;
  customer;
  contact;
  department;
  address;
  coverTimePeriod;
  instrumentType;
  signature;
  date = new Date();
  useDollar = false;
  currentY;
  content = [];
  totalPrice = 0;
  pages = [];
  pdf;
  currentPage;
  currentFontSize = defaultFontSize;
  currentFont;
  lightFont;
  boldFont;
  lightItalicFont;
  boldItalicFont;
  lightDefaultFont;
  boldDefaultFont;
  lightItalicDefaultFont;
  boldItalicDefaultFont;
  pageActions;
  isNative;

  initializeFonts() {
    let [ref, font] = this.pdf.embedNonstandardFont(Uint8Array.from(lightFontBytes));
    this.lightFont = [ref, font, lightFontName];
    [ref, font] = this.pdf.embedNonstandardFont(Uint8Array.from(boldFontBytes));
    this.boldFont = [ref, font, boldFontName];
    [ref, font] = this.pdf.embedNonstandardFont(Uint8Array.from(lightItalicFontBytes));
    this.lightItalicFont = [ref, font, lightItalicFontName];
    [ref, font] = this.pdf.embedNonstandardFont(Uint8Array.from(boldItalicFontBytes));
    this.boldItalicFont = [ref, font, boldItalicFontName];
    this.currentFont = this.lightFont;

    [ref, font] = this.pdf.embedNonstandardFont(Uint8Array.from(lightArialFontBytes));
    this.lightDefaultFont = [ref, font, lightArialFontName];
    [ref, font] = this.pdf.embedNonstandardFont(Uint8Array.from(boldArialFontBytes));
    this.boldDefaultFont = [ref, font, boldArialFontName];
    [ref, font] = this.pdf.embedNonstandardFont(Uint8Array.from(lightItalicArialFontBytes));
    this.lightItalicDefaultFont = [ref, font, lightItalicArialFontName];
    [ref, font] = this.pdf.embedNonstandardFont(Uint8Array.from(boldItalicArialFontBytes));
    this.boldItalicDefaultFont = [ref, font, boldItalicArialFontName];
  }

  initializeImage(imageObject) {
    const [ref, dimensions] = this.pdf.embedJPG(imageObject.content);
    imageObject.ref = ref;
    imageObject.dimensions = dimensions;
  }

  constructor(isNative, mode) {
    this.pdfMode = mode === undefined ? pdfModes[0] : mode;
    this.isNative = isNative;
    this.pdf = PDFDocumentFactory.create();
    this.initializeFonts();
    this.initializeImage(images.logo);
    this.createPage();
  }

  createPage() {
    if (this.currentPage !== undefined) {
      const contentStream = this.pdf.createContentStream(this.pageActions);
      this.currentPage.addContentStreams(this.pdf.register(contentStream));
      this.pages.push(this.currentPage);
    }
    this.currentPage = this.pdf
      .createPage([a4Width, a4Height])
      .addFontDictionary(lightFontName, this.lightFont[0])
      .addFontDictionary(boldFontName, this.boldFont[0])
      .addFontDictionary(lightItalicFontName, this.lightItalicFont[0])
      .addFontDictionary(boldItalicFontName, this.boldItalicFont[0])
      .addFontDictionary(lightArialFontName, this.lightDefaultFont[0])
      .addFontDictionary(boldArialFontName, this.boldDefaultFont[0])
      .addFontDictionary(lightItalicArialFontName, this.lightItalicDefaultFont[0])
      .addFontDictionary(boldItalicArialFontName, this.boldItalicDefaultFont[0])
      .addImageObject('logo', images.logo.ref);
    this.pageActions = [];
  }

  pushTextToActions(text, x, y, color = [0, 0, 0]) {
    this.pageActions.push(drawText(this.currentFont[1].encodeText(text), { x, y, size: this.currentFontSize, font: this.currentFont[2], colorRgb: color })[0]);
  }

  drawText(text, x, y, xCutOff, rightAligned, color) {
    const textWidth = this.measureText(text);
    const availableSpace = xCutOff === undefined ? a4Width : Math.abs((x + textWidth) - xCutOff) - 2;
    const addLineBreak = xCutOff !== undefined && textWidth > availableSpace;
    const halfTextIndex = Math.ceil(text.length / 2);
    const textArray = addLineBreak ? [text.substr(0, halfTextIndex), text.substr(halfTextIndex, text.length - halfTextIndex)] : [text, ''];
    const firstRowTextLength = addLineBreak ? this.measureText(textArray[0]) : undefined;
    const secondRowTextLength = addLineBreak ? this.measureText(textArray[1]) : undefined;
    const firstRowX = addLineBreak && rightAligned
      ? x + secondRowTextLength - (!this.useDollar && !text.includes('a') ? this.measureText(' €') : 0)
      : x;
    const secondRowX = addLineBreak && rightAligned
      ? x + firstRowTextLength
      : x;
    this.pushTextToActions(textArray[0], firstRowX, y, color);
    if (addLineBreak) {
      const secondRowY = y - this.currentFont[1].heightOfFontAtSize(this.currentFontSize) + 2;
      this.pushTextToActions(textArray[1], secondRowX, secondRowY, color);
    }

    return addLineBreak;
  }

  drawImage(imageName, x, y, width, height) {
    const imageData = drawImage(imageName, { x, y, width, height });
    imageData.forEach(operator => this.pageActions.push(operator));
  }

  measureText(text) {
    return this.currentFont[1].widthOfTextAtSize(text, this.currentFontSize);
  }

  isTestMode = () => this.pdfMode === pdfModes[0];

  getIncreasedY(textSize) {
    this.currentY -= this.currentFont[1].heightOfFontAtSize(textSize) + 2;
    return this.currentY;
  }

  addCustomerAddressRow(addressPart, addressRowCount) {
    if (addressPart !== undefined && addressPart.trim().length > 0 && addressPart !== null) {
      this.drawText(addressPart, outerMargin, this.getIncreasedY(defaultFontSize));
      return addressRowCount + 1;
    }
    return addressRowCount;
  }

  addPdfAddressHeader() {
    const address = this.address !== null && this.address !== undefined ? this.address.split(', ') : [];
    let i;
    let text;
    this.addPageHeader(true);
    this.currentFontSize = fontSizeTitle;
    this.currentFont = this.boldFont;
    this.drawText(kryptorStore.getPdfData('KryptorPdfTitle'), outerMargin, this.currentY);
    this.currentFont = this.lightFont;
    this.currentFontSize = defaultFontSize;
    const kryptorPdfCustomerIntroYPosition = this.getIncreasedY(defaultFontSize * 2);
    let addressRowCount = this.addCustomerAddressRow(this.customer, 0);
    addressRowCount = this.addCustomerAddressRow(this.contact, addressRowCount);
    addressRowCount = this.addCustomerAddressRow(this.department, addressRowCount);
    for (i = 0; i < address.length; i++) {
      addressRowCount = this.addCustomerAddressRow(address[i].trim(), addressRowCount);
    }
    if (addressRowCount > 0) {
      this.drawText(kryptorStore.getPdfData('KryptorPdfCustomerIntro'), outerMargin, kryptorPdfCustomerIntroYPosition);
    }
    this.currentY -= (5 - addressRowCount) * 1.5 * mmToPixelMultiplicator;
    const month = this.date.getMonth() + 1;
    const day = this.date.getDate();
    this.drawText(
      text = `${this.date.getFullYear()} ${month < 10 ? (`0${month}`) : month} ${day < 10 ? (`0${day}`) : day}`,
      a4Width - outerMargin - this.measureText(text),
      this.getIncreasedY(defaultFontSize * 2)
    );
  }

  addPageHeader(isPdfHeader) {
    this.currentY = a4Height - outerMargin * 0.75;
    const logoWidth = Math.ceil((a4Width - outerMargin * 2) / 3);
    const logoHeight = Math.ceil(logoWidth / 2.15);
    this.drawImage('logo', outerMargin * 0.75, this.currentY - logoHeight + 10, logoWidth, logoHeight);
    this.currentY -= (isPdfHeader ? 40 : 24) * mmToPixelMultiplicator;
  }

  addPageFooter() {
    this.currentY = footerYPos;
    this.addSeparator(true);
    this.currentFontSize = defaultFontSize;
    this.currentFont = this.lightFont;
    const text = kryptorStore.getPdfData('KryptorPdfFooter');
    this.drawText(text, a4Width / 2 - this.measureText(text) / 2, this.currentY, undefined, undefined, [0.5, 0.5, 0.5]);
  }

  addSeparator(short) {
    this.currentY -= (separatorMargin / 3 * 2 + 1) * mmToPixelMultiplicator;
    if (this.displaySeparators) {
      const imageData = drawRectangle({
        x: outerMargin,
        y: this.currentY,
        height: 0.01,
        width: a4Width * (short ? 0.82 : 1) - 2 * outerMargin,
        borderWidth: 0.001,
        borderColorRgb: [0, 0, 0]
      });
      imageData.forEach(operator => this.pageActions.push(operator));
    }
    this.currentY -= (separatorMargin * 3 / 2 - 1) * mmToPixelMultiplicator;
  }

  addPdfContent() {
    this.currentY -= 11 * mmToPixelMultiplicator;
    this.currentFontSize = defaultFontSize;
    let coverTimeUnit = kryptorStore.getPdfData('KryptorPdfCoverTimeUnit');
    if (this.coverTimePeriod === 1) {
      coverTimeUnit = coverTimeUnit.substr(0, coverTimeUnit.length - 1);
    }
    this.drawText(`${kryptorStore.getPdfData('KryptorPdfInstrumentType')} ${this.instrumentType}`, outerMargin, this.getIncreasedY(defaultFontSize));
    this.drawText(`${kryptorStore.getPdfData('KryptorPdfCoveredPeriod')} ${this.coverTimePeriod}${coverTimeUnit}`, outerMargin, this.getIncreasedY(defaultFontSize));
    for (let i = 0; i < this.content.length; i++) {
      this.addPdfCategory(i);
    }
    this.newPage(false);
    this.addSeparator();
    if (this.addSum(kryptorStore.getFormattedCurrencyValue(this.totalPrice))) {
      this.getIncreasedY(this.currentFontSize);
    }

    this.addSignature();
  }

  addSignatureRow(row) {
    let currentOffset = outerMargin;
    const splitRow = row.split(' ');
    for (let i = 0; i < splitRow.length; i++) {
      const word = `${splitRow[i]} `;
      const wordLength = this.measureText(word);
      if (currentOffset + wordLength > a4Width - outerMargin * 2) {
        this.getIncreasedY(defaultFontSize);
        this.newPage(true);
        currentOffset = outerMargin;
      }
      this.drawText(word, currentOffset, this.currentY);
      currentOffset += wordLength;
    }
  }

  addSignature() {
    this.newPage(true);
    this.currentFont = this.lightFont;
    this.drawText(kryptorStore.getPdfData('KryptorPdfContactSalesRepresentative'), outerMargin, this.getIncreasedY(defaultFontSize * 2));
    this.getIncreasedY(defaultFontSize * 1.5);
    const lineBreak = this.signature.includes('\r\n') ? '\r\n' : '\n';
    if (this.signature.includes(lineBreak)) {
      const splitSignature = this.signature.split(lineBreak);
      splitSignature.forEach((row) => {
        this.addSignatureRow(row);
        this.getIncreasedY(defaultFontSize);
      });
    } else {
      this.addSignatureRow(this.signature);
    }
  }

  addPdfCategory(index) {
    const category = this.content[index];
    this.newPage(true);
    this.addCategoryTitle(category);
    this.addPdfCategoryTestPriceRow(category);

    let isFirstItem = true;
    category.items.forEach((item) => {
      this.currentFont = this.lightItalicFont;
      this.addItemRow(category, item, isFirstItem);
      isFirstItem = false;
    });
  }

  addPdfCategoryTestPriceRow(category) {
    if (!this.isTestMode() && category.isAssay()) {
      let text = '';
      this.currentFont = this.lightItalicFont;
      this.drawText(text = kryptorStore.getPdfData('KryptorPdfPatientsResults'), xContentBlock4 - this.measureText(text) - 5, this.currentY);
      const pricePerPatient = category.pricePerPatient;
      const patientsAmount = category.patientsResultsAmount;
      const price = pricePerPatient * patientsAmount;
      this.currentFont = this.lightItalicDefaultFont;
      const pricePerPatientString = `a ${kryptorStore.getFormattedCurrencyValue(pricePerPatient)}`;
      const pricePerPatientWidth = this.measureText(pricePerPatientString);
      const currencySeparators = kryptorStore.getCurrencySeparators();
      let lineBreak = this.drawText(text = addValueSeparators(patientsAmount, undefined, currencySeparators.decimalSeparator, currencySeparators.thousandSeparator), xContentBlock4, this.currentY, xContentBlock5);
      lineBreak = this.drawText(pricePerPatientString, xContentBlock5 - pricePerPatientWidth, this.currentY, xContentBlock4 + block4To5Distance / 3, true)
        || lineBreak;
      this.totalPrice += price;
      this.currentFont = this.lightItalicFont;
      lineBreak = this.addSum(kryptorStore.getFormattedCurrencyValue(price)) || lineBreak;
      if (lineBreak) {
        this.getIncreasedY(this.currentFontSize);
      }
    }
  }

  addItemRow(category, item, isFirstItem) {
    const newPage = this.newPage(false);
    if (newPage) {
      this.addCategoryTitle(category);
      this.currentFont = this.lightItalicFont;
    }
    this.addItemRowName(category, item, isFirstItem, newPage);
    this.drawText(`${item.id}`, xContentBlock2, this.currentY);
    const currencySeparators = kryptorStore.getCurrencySeparators();
    let lineBreak = this.drawText(`${addValueSeparators(item.amount, undefined, currencySeparators.decimalSeparator, currencySeparators.thousandSeparator)}${kryptorStore.getPdfData('KryptorPdfItemUnit')}`, xContentBlock3, this.currentY, xContentBlock4);
    lineBreak = this.addItemRowPrice(category, item) || lineBreak;
    if (lineBreak) {
      this.getIncreasedY(this.currentFontSize);
    }
  }

  addCategoryMultiControlName(name, currentTextSize) {
    let offset = 0;
    const baseOffset = outerMargin + tableOffset;
    if (name.includes(this.brahms)) {
      this.currentFont = this.lightFont;
      this.drawText(this.brahms, baseOffset, this.getIncreasedY(currentTextSize));
      offset = this.measureText(this.brahms);
    }
    this.currentFont = this.lightItalicFont;
    const brahmslessText = name.replace(this.brahms, '');
    if (brahmslessText.includes('anti')) {
      const splitText = brahmslessText.split(' '); // 0 is empty
      this.drawText(` ${splitText[1]}`, baseOffset + offset, this.currentY);
      this.drawText(splitText[2], baseOffset, this.getIncreasedY(currentTextSize));
    } else {
      this.drawText(brahmslessText, baseOffset + offset, this.currentY);
    }
  }

  addItemRowName(category, item, isFirstItem, newPage) {
    const text = `${item.name}:`;
    const currentTextSize = defaultFontSize + (isFirstItem || newPage ? 3 * webToNativeSizeMultiplicator : 0);
    if (!this.isTestMode() && category.isAssay() && isFirstItem) {
      this.drawText(kryptorStore.getPdfData('KryptorPdfIncluding'), outerMargin + tableOffset, this.getIncreasedY(currentTextSize));
      this.drawText(text, xContentBlock1 - this.measureText(text), this.currentY);
    } else if (category.isMultiControl()) {
      this.addCategoryMultiControlName(text, currentTextSize);
    } else {
      this.drawText(text, xContentBlock1 - this.measureText(text), this.getIncreasedY(currentTextSize));
    }
  }

  addItemRowPrice(category, item) {
    if (this.isTestMode()) {
      const oldFont = this.currentFont;
      this.currentFont = this.selectDefaultFont(this.currentFont);
      const text = `a ${kryptorStore.getFormattedCurrencyValue(item.price)}`;
      const lineBreak = this.drawText(text, xContentBlock5 - block4To5Distance / 2 - this.measureText(text), this.currentY, xContentBlock3 + block3To4Distance / 1.75, true);
      this.currentFont = oldFont;
      this.totalPrice += item.amount * (item.price === undefined ? 0 : item.price);
      return this.addSum(kryptorStore.getFormattedCurrencyValue(item.amount * item.price)) || lineBreak;
    }
    if (!category.isAssay()) {
      this.drawText(kryptorStore.getPdfData('KryptorPdfIncluded'), xContentBlock4, this.currentY);
    }
    return false;
  }

  addSum(text, isTotal) {
    this.currentFont = this.boldDefaultFont;
    const textWidth = this.measureText(text);
    const defaultCutOff = this.isTestMode() ? xContentBlock4 + block4To5Distance / 2 : xContentBlock5;
    const xCutOff = isTotal ? undefined : defaultCutOff;
    const lineBreak = this.drawText(text, xContentBlock6 - textWidth - tableOffset, this.currentY, xCutOff, true);
    if (isTotal) {
      const totalString = kryptorStore.getPdfData('KryptorPdfTotal');
      const totalStringWidth = this.measureText(totalString);
      const totalX = xContentBlock6 - textWidth - tableOffset - totalStringWidth - 7;
      this.drawText(totalString, totalX, this.currentY);
    }
    this.currentFont = this.boldFont;
    return lineBreak;
  }

  addCategoryTitle(category) {
    this.addSeparator();
    let offset = 0;
    const baseOffset = outerMargin + tableOffset;
    if (category.name.includes(this.brahms)) {
      this.currentFont = this.boldFont;
      this.drawText(this.brahms, baseOffset, this.currentY);
      offset = this.measureText(this.brahms);
    }
    this.currentFont = this.boldItalicFont;
    this.drawText(category.name.replace(this.brahms, ''), baseOffset + offset, this.currentY);
  }

  newPage(isNewCategory) {
    const additionalSpace = isNewCategory ? separatorMargin * 2 : 2 * mmToPixelMultiplicator;
    if (this.currentY >= footerYPos + defaultFontSize + additionalSpace) {
      return false;
    }
    this.addPageFooter();
    this.createPage();
    this.currentFontSize = defaultFontSize;
    this.addPageHeader();
    return true;
  }

  create() {
    this.addPdfAddressHeader();
    this.addPdfContent();
    this.addPageFooter();
    const contentStream = this.pdf.createContentStream(this.pageActions);
    this.currentPage.addContentStreams(this.pdf.register(contentStream));
    this.pages.push(this.currentPage);
    this.pages.forEach(page => this.pdf.addPage(page));
    const bytes = PDFDocumentWriter.saveToBytes(this.pdf);
    return this.isNative ? base64.fromByteArray(bytes) : bytes;
  }

  selectDefaultFont(inCurrentFont) {
    switch (inCurrentFont[2]) {
      case this.lightFont[2]:
        return this.lightDefaultFont;
      case this.boldFont[2]:
        return this.boldDefaultFont;
      case this.lightItalicFont[2]:
        return this.lightItalicDefaultFont;
      case this.boldItalicFont[2]:
        return this.boldItalicDefaultFont;
      default:
        return this.lightDefaultFont;
    }
  }
}
