import { appSchema, Database } from '@nozbe/watermelondb';
import Adapter, { adapterConfig } from './adapter';
import news, { newsSchema } from './news';
import material, { materialSchema } from './material';
import contentPiece, { contentPieceSchema } from './content-piece';
import materialDownload, { materialDownloadSchema } from './material-download';
import qcCountry, { qcCountrySchema } from './qc-country';
import qcInstrument, { qcInstrumentSchema } from './qc-instrument';
import qcAnalyte, { qcAnalyteSchema } from './qc-analyte';
import qcManufacturer, { qcManufacturerSchema } from './qc-manufacturer';
import qcProductFamily, { qcProductFamilySchema } from './qc-product-family';
import qcProduct, { qcProductSchema } from './qc-product';
import qcCompetitorManufacturer, { qcCompetitorManufacturerSchema } from './qc-competitor-manufacturer';
import qcCompetitorProductFamily, { qcCompetitorProductFamilySchema } from './qc-competitor-product-family';
import qcCompetitorProduct, { qcCompetitorProductSchema } from './qc-competitor-product';
import qcProposal, { qcProposalSchema } from './qc-proposal';
import keyword, { keywordSchema } from './keyword';
import accCompany, { accCompanySchema } from './acc-company';
import accAssay, { accAssaySchema } from './acc-assay';
import accAssayCompanyAssay, { accAssayCompanyAssaySchema } from './acc-assay-company-assay';

const schema = appSchema({
  version: 1,
  tables: [
    newsSchema,
    materialSchema,
    contentPieceSchema,
    materialDownloadSchema,
    qcCountrySchema,
    qcManufacturerSchema,
    qcAnalyteSchema,
    qcInstrumentSchema,
    qcProductSchema,
    qcProductFamilySchema,
    qcCompetitorProductSchema,
    qcCompetitorProductFamilySchema,
    qcCompetitorManufacturerSchema,
    qcProposalSchema,
    keywordSchema,
    accCompanySchema,
    accAssaySchema,
    accAssayCompanyAssaySchema
  ]
});

// First, create the adapter to the underlying database:
const adapter = new Adapter({
  dbName: 'SIMAPP',
  schema,
  ...adapterConfig
});

// Then, make a Watermelon database from it!
const database = new Database({
  adapter,
  modelClasses: [
    news,
    material,
    contentPiece,
    materialDownload,
    qcCountry,
    qcAnalyte,
    qcInstrument,
    qcManufacturer,
    qcProductFamily,
    qcProduct,
    qcCompetitorManufacturer,
    qcCompetitorProductFamily,
    qcCompetitorProduct,
    qcProposal,
    keyword,
    accCompany,
    accAssay,
    accAssayCompanyAssay
  ],
  actionsEnabled: true,
});

export default database;
