import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { simappColors } from 'shared/lib/theme';
import QcProductTable from './qc-product-table';

const styles = {
  root: {
    height: '100vH',
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    flexDirection: 'column',
    display: 'flex',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontWeight: 'normal'
  },
  headRow: {
    display: 'flex',
    flexShrink: 0,
    height: 46,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: simappColors.white
  },
  title: {
    margin: '0',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '28px',
    lineHeight: '40px'
  },
  testsWithoutProduct: {
    display: 'flex',
    fontSize: '17px',
    alignItems: 'center',
    paddingLeft: 40
  },
  optionTitle: {
    display: 'flex',
    fontSize: '17px',
    lineHeight: '40px',
    alignItems: 'center',
    paddingLeft: 40
  },
  selectButton: {
    variant: 'text',
    display: 'flex',
    flexDirection: 'row',
    color: `${simappColors.azul}`,
    '&:hover': {
      backgroundColor: 'unset'
    }
  },
  selectButtonText: {
    display: 'flex',
    fontSize: '17px',
    color: `${simappColors.azul}`,
    lineHeight: '40px'
  },
  separator: {
    color: `${simappColors.steelGrey}`,
    width: '100%',
    marginTop: 0
  },
  optionsWrapper: {
    paddingTop: 10,
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  contractPeriod: {
    display: 'flex',
    fontSize: '17px',
    lineHeight: '40px',
    paddingLeft: 20
  },
  disclaimerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px'
  },
  disclaimerBorderBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    borderWidth: '2px',
    borderColor: `${simappColors.steelGrey}`
  },
  disclaimer: {
    textAlign: 'center',
    display: 'flex',
    fontSize: '17px',
    padding: '10px',
  }
};

@inject('qcStore', 'routing')
@observer
class QcOptionsProposal extends Component {
  onSelectClick(optionList) {
    const { qcStore } = this.props;
    qcStore.updateMassDiscount(undefined);
    qcStore.setProductList(optionList);
    qcStore.setProductSelectionView(qcStore.calculationView === 'TestView' ? 'ProductView' : 'ComparisonView');
    this.props.routing.push('/qc-calculator/product-selection');
  }

  renderTestsWithoutProduct() {
    const { classes, qcStore } = this.props;
    return (
      <Typography variant="h1" className={classes.testsWithoutProduct}>
        {qcStore.getText('QCOptionsProposalTestsWithoutProduct')} {qcStore.createTestsWithoutProductLevelsText()}
      </Typography>
    );
  }

  renderOptionsList(title, data) {
    const { classes, qcStore } = this.props;
    return (
      <div>
        <Typography variant="h1" className={classes.optionTitle}>
          {`${qcStore.getText(title)}:`}
          <Button className={classes.selectButton} onClick={() => this.onSelectClick(data)}>
            <Typography variant="h1" className={classes.selectButtonText}>
              {qcStore.getText('QCOptionsProposalSelectAndCustomize')}
            </Typography>
          </Button>
        </Typography>
        <QcProductTable data={data} hasTotalRow showKitFormat />
      </div>
    );
  }

  render() {
    const { classes, qcStore } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.headRow}>
          <Typography variant="h1" className={classes.title}>
            {qcStore.getText('QCOptionsProposalTitle')}
          </Typography>
        </div>
        <hr className={classes.separator} />
        <div className={classes.optionsWrapper}>
          <Typography variant="h1" className={classes.contractPeriod}>
            {qcStore.createContractPeriodText()}
          </Typography>
          {qcStore.calculationView === 'TestView' && qcStore.testsWithoutProduct.length > 0 && this.renderTestsWithoutProduct()}
          {this.renderOptionsList('QCOptionsProposalOptionCheapest', qcStore.cheapestResult)}
          {this.renderOptionsList('QCOptionsProposalOptionFewestProductSKUs', qcStore.fewestSKUResult)}
          {this.renderOptionsList('QCOptionsProposalOptionSmallestQuantity', qcStore.smallestQuantityResult)}
        </div>
        {qcStore.calculationView === 'ComparisonView' && (
          <div className={classes.disclaimerWrapper}>
            <div className={classes.disclaimerBorderBox}>
              <Typography variant="h1" className={classes.disclaimer}>
                {qcStore.getText('QCOptionsProposalDisclaimer')}
              </Typography>
            </div>
          </div>
        )}
      </div>
    );
  }
}

QcOptionsProposal.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  qcStore: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired
};

export default withStyles(styles)(QcOptionsProposal);
