import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { observable, runInAction, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import { simappColors } from 'shared/lib/theme';
import SimappIcon from 'web/components/_ui/simapp-icon';
import DeleteOverlay from './delete-overlay';
import MaterialTileMenu from './material-tile-menu';

const styles = {
  paper: {
    position: 'relative',
    width: 230,
    height: '256px !important',
    margin: 5,
    overflow: 'hidden',
    border: `1px solid ${simappColors.paleGrey}`,
  },
  paperImg: {
    width: 228,
    height: 128,
    background: '#ddd',
    position: 'relative',
    '& img': {
      width: 232,
      height: 128,
      objectFit: 'cover',
      objectPosition: '50% 0%',

    },
    '& button::after': {
      backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0.0) 0%, rgba(0,0,0,0.5) 100%)',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      content: '""'
    }
  },
  paperContent: {
    padding: '12px 8px'
  },
  paperContentLeft: {
    width: '70%',
    float: 'left'
  },
  paperContentRight: {
    width: '27%',
    float: 'right',
    paddingTop: '0.5em'
  },
  paperTitle: {
    fontWeight: 500,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    height: '4.4em',
    marginBottom: '0.5em'
  },
  isFavorite: {
    color: simappColors.azul
  },
  contentType: {
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 500
  },
  mastercontrolId: {
    fontSize: 12,
    lineHeight: '20px',
    color: simappColors.steelGrey,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  icon: {
    color: simappColors.steelGrey,
    '&:hover': {
      color: simappColors.azul
    },
    '&:last-child': {
      marginLeft: '0.5em'
    }
  },
  iconActive: {
    color: simappColors.azul
  },
  recommended: {
    height: 4,
    marginTop: -4,
    position: 'relative',
    background: simappColors.pumpkin,
  },
  recommendedStar: {
    color: simappColors.white,
    background: simappColors.pumpkin,
    borderRadius: '3px 3px 0 0',
    position: 'absolute',
    top: -24,
    left: 16
  },
  selectSharing: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    borderRadius: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    color: simappColors.azul,
    '& svg': {
      marginTop: 120
    }
  },
  selectSharingChecked: {
    backgroundColor: 'rgba(255, 255, 255, 0)',
  },
};

// const MaterialTile = inject('materialStore')(observer((props) => {
@inject('routing', 'applicationStore', 'materialStore', 'presentationStore') @observer
class MaterialTile extends Component {
  @observable source = null;
  @observable anchorEl = null;
  @observable reverse = false;
  @observable deleteModalOpen = false;
  @observable menuOpen = false;
  uid = null;

  constructor(props) {
    super(props);
    props.materialStore.loadThumbnail(props.material).then((img) => { runInAction(() => { this.source = img; }); });
    this.uid = props.material.uid;
  }

  componentDidUpdate() {
    if (typeof this.props.material !== 'undefined' && this.props.material.uid !== this.uid) {
      this.uid = this.props.material.uid;
      runInAction(() => { this.source = null; });
      this.props.materialStore.loadThumbnail(this.props.material).then((img) => { runInAction(() => { this.source = img; }); });
    }
  }

  updateSelection = (e, checked) => {
    if (checked) {
      this.props.materialStore.addToSharingMaterialsCache(e.target.value);
    } else {
      this.props.materialStore.removeFromSharingMaterialsCache(e.target.value);
    }
  }

  @action handleMenuOpen = (event) => {
    this.anchorEl = event.currentTarget;
  };

  @action handleMenuClose = () => {
    this.anchorEl = null;
  };

  @action openDeleteModal = () => {
    this.handleMenuClose();
    this.deleteModalOpen = true;
  }

  @action closeDeleteModal = () => {
    this.deleteModalOpen = false;
  }

  @action handleDelete = () => {
    this.props.presentationStore.delete(this.props.material.id, this.props.material.title).then(() => {
      this.closeDeleteModal();
    });
  }

  render() {
    const { material, materialStore, applicationStore, disableSharing, classes } = this.props;
    const { push } = this.props.routing;
    if (typeof material === 'undefined') {
      return (<Fragment />);
    }

    return (
      <Paper className={classes.paper} key={material.uid} id={material.uid} style={this.props.style}>
        <div className={classes.paperImg}>
          <ButtonBase onClick={() => { push(`/presentation/${material.uid}`); return false; }}>
            <img alt="" src={this.source} />
          </ButtonBase>
        </div>
        { material.isRecommended()
          && (
            <div className={classes.recommended}>
              <SimappIcon icon="star" className={classes.recommendedStar} />
            </div>
          )
        }
        <div className={classes.paperContent}>
          <Typography className={classes.paperTitle}>{material.title}</Typography>
          <div className={classes.paperContentLeft}>
            <Typography className={classes.contentType}>{material.content_type}</Typography>
            <Typography className={classes.mastercontrolId}>{material.material_type === materialStore.materialTypes.scientificLiterature ? material.bibliography_number : material.mastercontrol_id}</Typography>
          </div>
          <div className={classes.paperContentRight}>
            <ButtonBase className={classes.icon} title="Favorite" onClick={() => { materialStore.toggleFavorite(material.uid); }}><SimappIcon icon={material.favorite.get() ? 'heartFilled' : 'heartEmpty'} className={material.favorite.get() ? classes.isFavorite : null} /></ButtonBase>
            { material.material_type === materialStore.materialTypes.presentation
            && (
              <Fragment>
                <ButtonBase title="Open for more options" className={`${classes.icon} ${this.anchorEl ? classes.iconActive : ''}`} onClick={this.handleMenuOpen}>
                  <SimappIcon icon={this.anchorEl ? 'materialMenuFilled' : 'materialMenuEmpty'} />
                </ButtonBase>

                <MaterialTileMenu
                  anchorEl={this.anchorEl}
                  materialUid={material.uid}
                  handleMenuClose={this.handleMenuClose}
                  handleDelete={this.openDeleteModal}
                  sharingEnabled={!disableSharing && material.shareable && !applicationStore.isDistributor}
                />

                <DeleteOverlay
                  open={this.deleteModalOpen}
                  onClose={this.closeDeleteModal}
                  onDelete={this.handleDelete}
                  presentationTitle={material.title}
                />
              </Fragment>
            )
            }
            { !disableSharing && material.shareable && material.material_type !== materialStore.materialTypes.presentation && !applicationStore.isDistributor
            && (
              <ButtonBase title="Share Material" className={classes.icon} onClick={() => { applicationStore.startSharing(material.uid); }}><SimappIcon icon="share" /></ButtonBase>
            )}
          </div>
        </div>
        { applicationStore.sharingMode && !applicationStore.sharingOpen && material.shareable
        && (
          <Checkbox color="primary" className={classes.selectSharing} classes={{ checked: classes.selectSharingChecked }} value={material.uid} centerRipple={false} onChange={this.updateSelection} checked={this.props.materialStore.sharingMaterialsCache.indexOf(material.uid) > -1} />
        )}
        { applicationStore.sharingMode && !applicationStore.sharingOpen && !material.shareable
        && (
          <div className={classes.selectSharing} />
        )}
      </Paper>
    );
  }
}

MaterialTile.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired,
  material: PropTypes.object.isRequired,
  materialStore: PropTypes.object.isRequired,
  presentationStore: PropTypes.object.isRequired,
  applicationStore: PropTypes.object.isRequired,
  style: PropTypes.object,
  disableSharing: PropTypes.bool
};

MaterialTile.wrappedComponent.defaultProps = {
  style: {},
  disableSharing: false
};

export default withStyles(styles)(MaterialTile);
