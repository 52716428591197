import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const CalendarDay = (props) => {
  {
    const calendarStrings = {
      lastDay: '[Yesterday,] HH:mm',
      sameDay: '[Today,] HH:mm',
      nextDay: '[Tomorrow,] HH:mm',
      lastWeek: '[last] dddd [at] HH:mm',
      nextWeek: '[next] dddd [at] HH:mm',
      sameElse: 'DD MMM YYYY HH:mm'
    };

    const { date } = props;
    return (
      <span>{moment(date).calendar(null, calendarStrings)}</span>
    );
  }
};

CalendarDay.propTypes = {
  date: PropTypes.string.isRequired
};

export default CalendarDay;
