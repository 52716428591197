const simappConstants = {
  sidebarMinWidth: 56,
  sidebarOpenWidth: 256,
  sidebarPadding: 16,
  profilePicMin: 32,
  profilePic: 56,
  contentMarginLarge: '24px 56px',
  contentMarginLargeVertical: 24,
  contentMarginLargeHorizontal: 56,
  pctHorizontalMargin: 56,
  copeptinHorizontalMargin: 56,
  LanguageKey: 'Language',
  DataSetKey: 'DataSet',
  defaultLanguageCode: 'en',
  LanguageCodeEnglish: 'en',
  LanguageCodeGerman: 'de',
  LanguageCodeFrench: 'fr',
  LanguageCodeSpanish: 'es',
  LanguageCodeUSA: 'us'
};

export default simappConstants;
