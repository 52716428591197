import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { simappConstants } from 'shared/lib/theme';
import copeptinConstants from 'shared/lib/resources/copeptin-constants';
import CopeptinTable from './copeptin-table';
import CopeptinNavButton from './copeptin-navbutton';
import CopeptinDialog from './copeptin-dialog';
import ClickableTextButton from '../_misc/clickableTextButton';

const styles = {
  root: {
    width: '100%',
    height: '100vH',
    display: 'flex',
    flexDirection: 'column',
    padding: simappConstants.contentMarginLarge,
    overflowX: 'hidden',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontWeight: 'normal'
  },
  textContainer: {
    marginTop: '40px',
    marginBottom: '60px',
    marginLeft: simappConstants.copeptinHorizontalMargin,
    marginRight: simappConstants.copeptinHorizontalMargin
  },
  bottomBar: {
    width: '100%',
    marginTop: '30px',
    paddingBottom: '30px'
  },
  referencesLink: {
    marginLeft: '15px'
  }
};

@inject('copeptinStore', 'routing') @observer
class CopeptinInputs extends Component {
  getBottomBar(push) {
    const { copeptinStore } = this.props;
    return (
      <div className={this.props.classes.bottomBar}>
        <ClickableTextButton
          isBold
          className={this.props.classes.referencesLink}
          onClick={() => push('/he-models/copeptin-calculator/copeptin-references')}
          text={copeptinStore.getLocalizedText('copeptinReferences')}
        />
        <CopeptinNavButton
          nextOnClick={location => this.props.routing.push(location)}
          nextOnClickLocation="/he-models/copeptin-calculator/inputs-percent"
          backOnClick={() => {
            this.props.copeptinStore.setLocation(this.props.routing.location.pathname);
            push('/he-models/copeptin-calculator/population');
          }}
          screenNumber={2}
        />
      </div>
    );
  }

  render() {
    const { push } = this.props.routing;
    const { classes, copeptinStore } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.textContainer}>
          <Typography align="center" variant="subtitle1">{copeptinStore.getLocalizedText('copeptinInputScreenText')}</Typography>
        </div>
        <div>
          <CopeptinTable
            source={copeptinStore.jsonCalculatorData.staffTimes}
            getSecondColumnValue={copeptinStore.getStaffTimesDoctorValue}
            setSecondColumnValue={copeptinStore.setStaffTimesDoctorValue}
            isUserSecondColumnValue={copeptinStore.isUserStaffTimesDoctorValue}
            getThirdColumnValue={copeptinStore.getStaffTimesNursingValue}
            setThirdColumnValue={copeptinStore.setStaffTimesNursingValue}
            isUserThirdColumnValue={copeptinStore.isUserStaffTimesNursingValue}
            headerFirstColumn={copeptinStore.getLocalizedText('copeptinStaffTimes')}
            headerSecondColumn={copeptinStore.getLocalizedText('copeptinStaffTimesDoctor')}
            headerThirdColumn={copeptinStore.getLocalizedText('copeptinStaffTimesNursing')}
            dataType={copeptinConstants.CopeptinTableRowTime}
          />
          <CopeptinTable
            source={copeptinStore.jsonCalculatorData.medicalCheckCosts}
            getSecondColumnValue={copeptinStore.getMedicalCheckCostsCostsValue}
            setSecondColumnValue={copeptinStore.setMedicalCheckCostsCostsValue}
            isUserSecondColumnValue={copeptinStore.isUserMedicalCheckCostsCostsValue}
            headerFirstColumn={copeptinStore.getLocalizedText('copeptinMedicalCheckCosts')}
            dataType={copeptinConstants.CopeptinTableRowCurrency}
          />
          <CopeptinTable
            source={copeptinStore.jsonCalculatorData.medicationCosts}
            getSecondColumnValue={copeptinStore.getMedicationCostsCostsValue}
            setSecondColumnValue={copeptinStore.setMedicationCostsCostsValue}
            isUserSecondColumnValue={copeptinStore.isUserMedicationCostsCostsValue}
            headerFirstColumn={copeptinStore.getLocalizedText('copeptinMedicationCosts')}
            dataType={copeptinConstants.CopeptinTableRowCurrency}
            isLastTable
          />
        </div>
        {this.getBottomBar(push)}
        <CopeptinDialog />
      </div>
    );
  }
}

CopeptinInputs.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  copeptinStore: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired
};

export default withStyles(styles)(CopeptinInputs);
