import { tableSchema } from '@nozbe/watermelondb';
import { field, date } from '@nozbe/watermelondb/decorators';
import Model from './simapp-model';

export const accAssayCompanyAssaySchema = tableSchema({
  name: 'accAssayCompanyAssay',
  columns: [
    { name: 'b_id', type: 'number', isIndexed: true },
    { name: 'uid', type: 'string', isIndexed: true },
    { name: 'assay_id', type: 'number' },
    { name: 'competitor_company_id', type: 'number' },
    { name: 'competitor_assay_id', type: 'number' },
    { name: 'kryptor_plus_base64', type: 'string' },
    { name: 'kryptor_gold_base64', type: 'string' },
    { name: 'b_created_at', type: 'number' },
    { name: 'b_updated_at', type: 'number' },
    { name: 'created_at', type: 'number' },
    { name: 'updated_at', type: 'number' },
  ]
});

export default class AccAssayCompanyAssay extends Model {
  static table = 'accAssayCompanyAssay'

  @field('b_id') bId;
  @field('uid') uid;
  @field('assay_id') assayId;
  @field('competitor_company_id') competitorCompanyId;
  @field('competitor_assay_id') competitorAssayId;
  @field('kryptor_plus_base64') kryptorPlusBase64;
  @field('kryptor_gold_base64') kryptorGoldBase64;
  @date('b_created_at') bCreatedAt;
  @date('b_updated_at') bUpdatedAt;
  @date('created_at') createdAt;
  @date('updated_at') updatedAt;
}
