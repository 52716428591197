
import { memoize, camelCase } from 'lodash';

const memoizedCamelCase = memoize(camelCase);

const camelcaseObjectDeep = (value) => {
  if (Array.isArray(value)) {
    return value.map(camelcaseObjectDeep);
  }

  if (value && typeof value === 'object') {
    if (value.constructor === Object) {
      const obj = {};
      const keys = Object.keys(value);
      const len = keys.length;

      for (let i = 0; i < len; i += 1) {
        // HINT: This indexOf-check is not necessary for the function itself
        // this is just an ugly workaround to keep the keys in the "listPrice" object uppercase (qcvc_product endpoint)
        obj[keys[i].indexOf('_') === -1 ? keys[i] : memoizedCamelCase(keys[i])] = camelcaseObjectDeep(value[keys[i]]);
      }

      return obj;
    }
  }

  return value;
};

export default camelcaseObjectDeep;
