export const customerTableFields = {
  CUSTOMER_NUMBER: 'customer_number',
  CUSTOMER_NAME: 'customer_name',
  CUSTOMER_DESCRIPTION: 'customer_description',
  CUSTOMER_LAST_MODIFIED: 'customer_last_modified'
};

export const sortOrder = {
  ASCENDING: 'ASC',
  DESCENDING: 'DESC'
};

export class CustomerTableSorter {
  sortOrderAscending = {};
  sortedBy = undefined;

  constructor() {
    this.sortOrderAscending[customerTableFields.CUSTOMER_NUMBER] = true;
    this.sortOrderAscending[customerTableFields.CUSTOMER_NAME] = true;
    this.sortOrderAscending[customerTableFields.CUSTOMER_DESCRIPTION] = true;
    this.sortOrderAscending[customerTableFields.CUSTOMER_LAST_MODIFIED] = true;
  }

  isSortedBy(fieldName) {
    if (this.sortedBy === fieldName) {
      return true;
    }
    return false;
  }

  isSortedAscending(fieldName) {
    return this.sortOrderAscending[fieldName];
  }

  setFieldName(fieldName) {
    this.sortedBy = fieldName;
    this.sortOrderAscending[fieldName] = !this.sortOrderAscending[fieldName];
  }

  sort(data) {
    if (this.sortedBy) {
      if (this.sortedBy === customerTableFields.CUSTOMER_LAST_MODIFIED) {
        return this.sortByCustomerLastModified(data);
      }
      return this.sortByFieldName(data, this.sortedBy);
    }
    return data;
  }

  sortByFieldName(data, fieldName) {
    if (this.sortOrderAscending[fieldName]) {
      return this.sortInObservableArray(data, fieldName);
    }
    return this.sortInObservableArray(data, fieldName, sortOrder.DESCENDING);
  }

  sortByCustomerNumber(data) {
    this.sortByFieldName(data, customerTableFields.CUSTOMER_NUMBER);
  }

  sortByCustomerName(data) {
    this.sortByFieldName(data, customerTableFields.CUSTOMER_NAME);
  }

  sortByCustomerDescription(data) {
    this.sortByFieldName(data, customerTableFields.CUSTOMER_DESCRIPTION);
  }

  sortByCustomerLastModified(data) {
    if (this.sortOrderAscending[customerTableFields.CUSTOMER_LAST_MODIFIED]) {
      return data.slice().sort(this.sortByCustomerLastModifiedAsc);
    }
    return data.slice().sort(this.sortByCustomerLastModifiedDesc);
  }

  sortByCustomerLastModifiedAsc = (a, b) => {
    const modifiedDateA = new Date(a.updated_at).getTime();
    const modifiedDateB = new Date(b.updated_at).getTime();
    if (modifiedDateA > modifiedDateB) return 1;
    if (modifiedDateB > modifiedDateA) return -1;
    return 0;
  }

  sortByCustomerLastModifiedDesc = (a, b) => {
    const modifiedDateA = new Date(a.updated_at).getTime();
    const modifiedDateB = new Date(b.updated_at).getTime();
    if (modifiedDateA < modifiedDateB) return 1;
    if (modifiedDateB < modifiedDateA) return -1;
    return 0;
  }

  sortInObservableArray = (target, sortBy, sortDir = 'ASC') => {
    const sort1 = sortDir === 'ASC' ? -1 : 1;
    const sort2 = -1 * sort1;
    return target.slice().sort((a, b) => {
      if (a[sortBy] < b[sortBy]) { return sort1; }
      if (a[sortBy] > b[sortBy]) { return sort2; }
      return 0;
    });
  }
}
