import moment from 'moment';

const calendarStrings = {
  lastDay: '[Yesterday]',
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  sameElse: 'DD MMM YYYY'
};

const calendarStringsHours = {
  lastDay: '[Yesterday,] HH:mm',
  sameDay: '[Today,] HH:mm',
  nextDay: '[Tomorrow,] HH:mm',
  lastWeek: '[last] dddd [at] HH:mm',
  nextWeek: '[next] dddd [at] HH:mm',
  sameElse: 'DD MMM YYYY HH:mm'
};

export default function calendarDay(date, withHours = true) {
  moment.locale('en');
  return moment(date).calendar(null, withHours ? calendarStringsHours : calendarStrings);
}
