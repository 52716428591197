import { tableSchema } from '@nozbe/watermelondb';
import { field, date, json } from '@nozbe/watermelondb/decorators';
import camelcaseObjectDeep from 'shared/lib/camelcase-object-deep';
import Model from './simapp-model';

export const qcProductSchema = tableSchema({
  name: 'qcProduct',
  columns: [
    { name: 'b_id', type: 'number', isIndexed: true },
    { name: 'uid', type: 'string', isIndexed: true },
    { name: 'part_number', type: 'string' },
    { name: 'name', type: 'string' },
    { name: 'levels', type: 'string' },
    { name: 'list_price', type: 'string' },
    { name: 'product_family_id', type: 'number' },
    { name: 'manufacturer_id', type: 'number' },
    { name: 'multi_pack', type: 'boolean' },
    { name: 'kit_size', type: 'number' },
    { name: 'kit_volume', type: 'number' },
    { name: 'analyte_ids', type: 'string' },
    { name: 'countries', type: 'string' },
    { name: 'b_created_at', type: 'number' },
    { name: 'b_updated_at', type: 'number' },
    { name: 'created_at', type: 'number' },
    { name: 'updated_at', type: 'number' },
  ]
});

export default class QcProduct extends Model {
  static table = 'qcProduct'

  @field('b_id') bId;
  @field('uid') uid;
  @field('part_number') partNumber;
  @field('name') name;
  @json('levels', jsonData => camelcaseObjectDeep(jsonData)) levels;
  @json('list_price', jsonData => jsonData) listPrice;
  @field('product_family_id') productFamilyId;
  @field('manufacturer_id') manufacturerId;
  @field('multi_pack') multiPack;
  @field('kit_size') kitSize;
  @field('kit_volume') kitVolume;
  @json('analyte_ids', jsonData => jsonData) analyteIds;
  @json('countries', jsonData => jsonData) countries;
  @date('b_created_at') bCreatedAt;
  @date('b_updated_at') bUpdatedAt;
  @date('created_at') createdAt;
  @date('updated_at') updatedAt;
}
