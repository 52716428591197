import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { simappColors } from 'shared/lib/theme';
import { inject, observer } from 'mobx-react';
import { intercept } from 'mobx';
import { routing, copeptinStore } from 'shared/stores';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import PanoramaFishEye from '@material-ui/icons/PanoramaFishEye';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '10px'
  },
  navigationbutton: {
    borderRadius: '4px',
    height: '56px',
    fontSize: '17px',
    transition: 'none',
    fontWeight: 'normal',
    backgroundColor: `${simappColors.coolGrey}`,
    color: `${simappColors.white}`,
    '&:hover': {
      backgroundColor: `${simappColors.coolGrey}`
    }
  },
  button: {
    backgroundColor: `${simappColors.coolGrey}`,
    '&:hover': {
      backgroundColor: `${simappColors.coolGrey}`
    }
  },
  invisible: {
    backgroundColor: 'transparent',
    border: 'transparent',
    color: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  inline: {
    display: 'flex',
    flex: 1,
  },
  pageSelect: {
    width: '7%',
  },
  pageSelected: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '-2px'
  },
  circle: {
    height: '44px',
    width: '44px'
  },
  posAbsolut: {
    position: 'absolute'
  }
};

@inject('copeptinStore') @observer
class CopeptinNavButton extends Component {
  resetCopeptinState = intercept(routing, 'location', (newLocation) => {
    try {
      if (((newLocation.newValue.pathname.endsWith('population')
      || newLocation.newValue.pathname.endsWith('inputs')
      || newLocation.newValue.pathname.endsWith('inputs-percent')
      || newLocation.newValue.pathname.endsWith('results'))
        && copeptinStore.jsonCalculatorData === null)) {
        copeptinStore.resetData();
        routing.push('/he-models/copeptin-calculator');
      }
    } catch {
      // not ready on first page load
    }
    return newLocation;
  });

  getNavigationButtons() {
    this.navigationButtons = [];
    this.navigationButtons.push('/he-models/copeptin-calculator/population');
    this.navigationButtons.push('/he-models/copeptin-calculator/inputs');
    this.navigationButtons.push('/he-models/copeptin-calculator/inputs-percent');
    this.navigationButtons.push('/he-models/copeptin-calculator/results');
  }

  getSelectedButton(index) {
    return (
      <div className={this.props.classes.pageSelected}>
        <PanoramaFishEye className={this.props.classes.circle} />
        <div className={this.props.classes.posAbsolut}>{index + 1}</div>
      </div>
    );
  }

  navigationButtons;

  renderArrowForwardButton = () => {
    if (this.props.screenNumber !== this.navigationButtons.length) {
      return (
        <Button
          onClick={this.props.screenNumber !== this.navigationButtons.length ? () => this.props.nextOnClick(this.props.nextOnClickLocation) : () => { }}
          className={`${this.props.classes.navigationbutton} ${this.props.classes.button} ${this.props.classes.inline}`}
        >
          <ArrowForward />
        </Button>
      );
    }
    return (
      <div className={`${this.props.classes.navigationbutton} ${this.props.classes.button} ${this.props.classes.inline} ${this.props.classes.invisible}`} />
    );
  }

  render() {
    this.getNavigationButtons();
    return (
      <div className={`${this.props.classes.root} ${this.props.classes.inline}`}>
        <Button
          onClick={() => this.props.backOnClick()}
          className={`${this.props.classes.navigationbutton} ${this.props.classes.button} ${this.props.classes.inline}`}
        >
          <ArrowBack />
        </Button>
        {this.navigationButtons.map((value, index) => {
          const buttonIndex = index;
          return (
            <Button
              key={buttonIndex}
              onClick={() => {
                if (index + 1 !== this.props.screenNumber) {
                  this.props.nextOnClick(value);
                }
              }}
              className={`${this.props.classes.navigationbutton} ${this.props.classes.button} ${this.props.classes.pageSelect}`}
            >{index + 1 === this.props.screenNumber ? this.getSelectedButton(index) : <div>{index + 1}</div>}
            </Button>
          );
        })}
        {this.renderArrowForwardButton()}
      </div>
    );
  }
}

CopeptinNavButton.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  nextOnClick: PropTypes.func,
  backOnClick: PropTypes.func,
  screenNumber: PropTypes.number,
  nextOnClickLocation: PropTypes.string
};

CopeptinNavButton.wrappedComponent.defaultProps = {
  backOnClick: () => { },
  nextOnClick: () => { },
  screenNumber: 0,
  nextOnClickLocation: undefined
};

export default withStyles(styles)(CopeptinNavButton);
