import React from 'react';
import SVG, { Rect, Line, G, Text, Polygon, Circle } from 'react-native-svg';
import PropTypes from 'prop-types';
import { calculateChartingMaximum } from 'shared/helper/copeptin-calculation-helper';
import CopeptinAbstractChart from './copeptin-abstract-chart';
import { simappColors } from '../../../shared/lib/theme/index';

const barWidth = 64;
const barChartSpace = 0.2;
const barChartSecondSpace = 1.5;
const chartHeightRatio = 0.7;

class CopeptinBarChart extends CopeptinAbstractChart {
  renderBars = (config) => {
    const {
      data,
      height,
      paddingTop,
      paddingRight,
      colors
    } = config;
    let xC = paddingRight;
    const maxValue = Math.max.apply(null, data);
    const maxLineValue = calculateChartingMaximum(maxValue);
    const maxHeightValue = (maxValue > maxLineValue) ? maxValue : maxLineValue;
    const heightPerUnit = height * chartHeightRatio / maxHeightValue;
    return data.map((x, i) => {
      const ret = [];
      let h = 0;
      let y = chartHeightRatio * height + paddingTop;
      for (let z = 0; z < x.length; z++) {
        if (x[z] > 0) {
          h = heightPerUnit * x[z];
          y -= h;
          ret.push(
            <Rect
              key={Math.random()}
              x={`${xC}`}
              y={`${y}`}
              width={`${barWidth}`}
              height={`${h}`}
              style={{ fill: colors[i % 2] }}
            />
          );
          const yText = y > 0 ? y : 0;
          ret.push(
            <Text
              key={Math.random()}
              x={`${xC + barWidth / 2}`}
              textAnchor="middle"
              y={`${h > 20 ? yText + 15 : yText + 9}`}
              fontSize={12}
              style={{ fill: '#fff' }}
            >
              {x[z].toFixed(2)}
            </Text>
          );
          xC = i % 2 ? xC + barWidth * (1 + barChartSecondSpace) : xC + barWidth * (1 + barChartSpace);
        }
      }

      return ret;
    });
  };

  renderLegend = (config) => {
    const { legend, colors, width, height } = config;
    return legend.map((x, i) => (
      <G key={Math.random()}>
        <Rect
          width="16px"
          height="16px"
          style={{ fill: colors[i] }}
          rx="8"
          ry="8"
          x={`${width * 0.71}`}
          y={`${height * 0.7 - i * 50}`}
        />
        <Text
          style={{ fill: '#fff' }}
          fontSize={16}
          x={`${width * 0.78}`}
          y={`${height * 0.76 - i * 50}`}
        >
          {x}
        </Text>
      </G>
    ));
  };

  renderVerticalSubLabels = (config) => {
    const {
      labels = [],
      height,
      paddingRight,
      paddingTop,
      verticalLabelRotation = 0,
      color
    } = config;
    let x = paddingRight - barWidth * (1 + barChartSpace / 2 + barChartSecondSpace);
    return labels.map((label) => {
      x += barWidth * (2 + barChartSpace + barChartSecondSpace);
      const y = (height * chartHeightRatio) + paddingTop + 80;
      return (
        <Text
          origin={`${x}, ${y}`}
          rotation={`${verticalLabelRotation}`}
          key={Math.random()}
          x={`${x}`}
          y={`${y}`}
          style={{ fill: color }}
          textAnchor={verticalLabelRotation === 0 ? 'middle' : 'start'}
        >
          {label}
        </Text>
      );
    });
  };

  renderDifferences = (config) => {
    const { data, height, paddingTop, paddingRight, differencesAbsolute, differencesLabel, color } = config;
    const maxValue = Math.max.apply(null, data);
    const maxLineValue = calculateChartingMaximum(maxValue);
    const maxHeightValue = (maxValue > maxLineValue) ? maxValue : maxLineValue;
    const heightPerUnit = height * chartHeightRatio / maxHeightValue;
    return [...new Array(data.length / 2)].map((_, i) => {
      const localMaxValue = height * chartHeightRatio - Math.max(data[i * 2], data[i * 2 + 1]) * heightPerUnit + paddingTop;
      const localMinValue = height * chartHeightRatio - Math.min(data[i * 2], data[i * 2 + 1]) * heightPerUnit + paddingTop;
      const localDiffValue = Math.abs(localMaxValue - localMinValue);
      const xDefaultBarStart = paddingRight + i * barWidth * (2 + barChartSpace + barChartSecondSpace);
      const xCopeptinBarMiddle = xDefaultBarStart + barWidth * (1.5 + barChartSpace);
      const xCopeptinBarEnd = xCopeptinBarMiddle + barWidth * 0.5;
      const arrowSize = 10;
      const arrowHeight = arrowSize * 1.5;
      const circleRadius = 18;
      const fontSize = 12;
      return (
        <G key={Math.random()}>
          <Line
            key={Math.random()}
            x1={`${xDefaultBarStart}`}
            y1={`${localMaxValue}`}
            x2={`${xCopeptinBarEnd}`}
            y2={`${localMaxValue}`}
            style={{ stroke: color }}
            strokeDasharray={['6', '6']}
            strokeWidth="2"
          />
          <Line
            key={Math.random()}
            x1={`${xCopeptinBarMiddle}`}
            y1={`${localMaxValue}`}
            x2={`${xCopeptinBarMiddle}`}
            y2={`${localDiffValue >= arrowHeight ? localMinValue - arrowHeight : localMaxValue}`}
            style={{ stroke: color }}
            strokeWidth="8"
          />
          <Polygon
            key={Math.random()}
            points={`${xCopeptinBarMiddle + arrowSize},${localDiffValue >= arrowHeight ? localMinValue - arrowHeight : undefined}
              ${xCopeptinBarMiddle},${localMinValue}
              ${xCopeptinBarMiddle - arrowSize},${localDiffValue >= arrowHeight ? localMinValue - arrowHeight : undefined}`}
            style={{ stroke: color, fill: color }}
          />
          <Circle
            key={Math.random()}
            cx={`${localDiffValue >= circleRadius * 2 + arrowHeight ? xCopeptinBarMiddle : xCopeptinBarEnd + circleRadius}`}
            cy={`${localDiffValue >= circleRadius * 2 + arrowHeight ? localMinValue - circleRadius - (localDiffValue - circleRadius) / 2 : localMaxValue + circleRadius}`}
            r={`${circleRadius}`}
            style={{ stroke: color, fill: simappColors.palerGrey, strokeWidth: 2 }}
          />
          {differencesLabel.length > 1 ? (
            <G key={Math.random()}>
              <Text
                key={Math.random()}
                x={`${localDiffValue >= circleRadius * 2 + arrowHeight ? xCopeptinBarMiddle : xCopeptinBarEnd + circleRadius}`}
                y={`${localDiffValue >= circleRadius * 2 + arrowHeight ? localMinValue - circleRadius - (localDiffValue - circleRadius) / 2 - fontSize / 6 : localMaxValue + circleRadius - fontSize / 6}`}
                fontSize={fontSize}
                fontWeight="bold"
                style={{ fill: color }}
                textAnchor="middle"
              >
                {differencesAbsolute ? `${(data[i * 2 + 1] - data[i * 2]).toFixed(0)}` : `${((data[i * 2] - data[i * 2 + 1]) / data[i * 2] * -100).toFixed(0)}`}
              </Text>
              <Text
                key={Math.random()}
                x={`${localDiffValue >= circleRadius * 2 + arrowHeight ? xCopeptinBarMiddle : xCopeptinBarEnd + circleRadius}`}
                y={`${localDiffValue >= circleRadius * 2 + arrowHeight ? localMinValue - circleRadius - (localDiffValue - circleRadius) / 2 + 2 * fontSize / 3 : localMaxValue + circleRadius + 2 * fontSize / 3}`}
                fontSize={fontSize}
                fontWeight="bold"
                style={{ fill: color }}
                textAnchor="middle"
              >
                {`${differencesLabel}`}
              </Text>
            </G>
          ) : (
            <Text
              key={Math.random()}
              x={`${localDiffValue >= circleRadius * 2 + arrowHeight ? xCopeptinBarMiddle : xCopeptinBarEnd + circleRadius}`}
              y={`${localDiffValue >= circleRadius * 2 + arrowHeight ? localMinValue - circleRadius - (localDiffValue - circleRadius) / 2 + fontSize / 3 : localMaxValue + circleRadius + fontSize / 3}`}
              fontSize={fontSize}
              fontWeight="bold"
              style={{ fill: color }}
              textAnchor="middle"
            >
              {differencesAbsolute ? `${(data[i * 2 + 1] - data[i * 2]).toFixed(0)}${differencesLabel}` : `${((data[i * 2] - data[i * 2 + 1]) / data[i * 2] * -100).toFixed(0)}${differencesLabel}`}
            </Text>
          )}
        </G>
      );
    });
  };

  render() {
    const paddingTop = 15;
    const paddingRight = 55;
    const {
      width,
      height,
      style = {},
      data,
      withHorizontalLabels = true,
      withVerticalLabels = true,
      showDifferences,
      differencesAbsolute,
      differencesLabel,
      differencesColor = simappColors.copeptinRed
    } = this.props;
    const { borderRadius = 0 } = style;
    const config = {
      width,
      height
    };
    let border = 0;
    for (let i = 0; i < data.data.length; i++) {
      const actual = data.data[i].reduce((pv, cv) => pv + cv, 0);
      if (actual > border) {
        border = actual;
      }
    }

    return (
      <div style={style}>
        <SVG height={`${height}`} width={`${width}`}>
          <Rect
            width="100%"
            height={`${height}`}
            rx={`${borderRadius}`}
            ry={`${borderRadius}`}
            style={{ fill: 'url(#backgroundGradient)' }}
          />
          <G>
            {this.renderHorizontalLines({
              ...config,
              data: data.data,
              paddingTop,
              paddingRight,
              chartHeightRatio
            })}
          </G>
          <G>
            {withHorizontalLabels
              ? this.renderHorizontalLabels({
                ...config,
                data: [0, border],
                paddingTop,
                paddingRight,
                chartHeightRatio
              })
              : null}
          </G>
          <G>
            {withVerticalLabels
              ? this.renderVerticalLabels({
                ...config,
                labels: data.labels,
                paddingRight: paddingRight + 20,
                stackedBar: false,
                barWidth,
                paddingTop,
                horizontalOffset: barWidth,
                colors: this.props.data.barColors,
                barChartSpace,
                barChartSecondSpace,
                useColors: true,
                chartHeightRatio
              })
              : null}
          </G>
          <G>
            {this.renderVerticalSubLabels({
              ...config,
              labels: data.subLabels,
              paddingRight: paddingRight + 20,
              paddingTop,
              horizontalOffset: barWidth,
              color: data.subLabelColor
            })}
          </G>
          <G>
            {this.renderBars({
              ...config,
              data: data.data,
              colors: this.props.data.barColors,
              paddingTop,
              paddingRight: paddingRight + 20
            })}
          </G>
          <G>
            {showDifferences
              ? this.renderDifferences({
                ...config,
                data: data.data,
                paddingRight: paddingRight + 20,
                paddingTop,
                differencesAbsolute,
                differencesLabel,
                color: differencesColor
              })
              : null}
          </G>
          {this.renderLegend({
            ...config,
            legend: data.legend,
            colors: this.props.data.barColors
          })}
        </SVG>
      </div>
    );
  }
}

CopeptinBarChart.propTypes = {
  chartConfig: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  yAxisLabel: PropTypes.string,
  fromZero: PropTypes.bool,
  showDifferences: PropTypes.bool,
  differencesAbsolute: PropTypes.bool,
  differencesLabel: PropTypes.string,
  horizontalLineStep: PropTypes.number
};

CopeptinBarChart.defaultProps = {
  yAxisLabel: '',
  fromZero: false,
  showDifferences: false,
  differencesAbsolute: false,
  differencesLabel: '%',
  horizontalLineStep: 50
};

export default CopeptinBarChart;
