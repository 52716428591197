import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { simappConstants, simappColors } from 'shared/lib/theme';
import Typography from '@material-ui/core/Typography';
import copeptinConstants from 'shared/lib/resources/copeptin-constants';
import CopeptinResultCalculation from 'shared/helper/copeptin-calculation-helper';
import CopeptinTableResults from './copeptin-table-results';
import CopeptinNavButton from './copeptin-navbutton';
import CopeptinDialog from './copeptin-dialog';
import CopeptinBarChart from './copeptin-bar-chart';
import ClickableTextButton from '../_misc/clickableTextButton';

const styles = {
  root: {
    width: '100%',
    height: '100vH',
    display: 'flex',
    flexDirection: 'column',
    padding: simappConstants.contentMarginLarge,
    overflowX: 'hidden',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontWeight: 'normal'
  },
  textContainer: {
    marginTop: '40px',
    marginBottom: '60px',
    marginLeft: simappConstants.copeptinHorizontalMargin,
    marginRight: simappConstants.copeptinHorizontalMargin
  },
  bottomBar: {
    width: '100%',
    marginTop: '30px',
    paddingBottom: '30px'
  },
  barChartWrapper: {
    marginBottom: '60px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  barChartYAxis: {
    minWidth: '200px',
    fontSize: 12,
    paddings: '0px',
    textAlign: 'center',
    marginTop: '-40px',
    marginLeft: '-90px',
    marginRight: '-90px',
    color: simappColors.copeptinGrey,
    transform: 'rotate( 270deg )'
  },
  stackedbarChartYAxis: {
    minWidth: '200px',
    fontSize: 12,
    paddings: '0px',
    textAlign: 'center',
    marginTop: '-50px',
    marginLeft: '-90px',
    marginRight: '-90px',
    color: simappColors.copeptinGrey,
    transform: 'rotate( 270deg )'
  },
  stackedbarChart: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  barChart: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  image: {
    display: 'block',
    maxHeight: '400px',
    marginLeft: '20px'
  },
  referencesBar: {
    width: '100%',
    marginRight: simappConstants.pctHorizontalMargin,
    display: 'flex',
    justifyContent: 'flex-start'
  },
  referencesLink: {
    marginLeft: '15px'
  },
};

@inject('copeptinStore', 'routing') @observer
class CopeptinResults extends Component {
  constructor(props) {
    super(props);
    this.calculationHelper = new CopeptinResultCalculation(props.copeptinStore);
    this.calculationHelper.calculateStaffTimesDoctor();
    this.calculationHelper.calculateStaffTimesNursing();
    this.calculationHelper.calculateStaffTimesTotal();
    this.calculationHelper.calculateAcuteSettingCosts();
    this.calculationHelper.calculateAcuteSettingCostsAllPatients();
  }

  getBottomBar(push) {
    const { copeptinStore } = this.props;
    return (
      <div className={this.props.classes.bottomBar}>
        <div className={this.props.classes.referencesBar}>
          <ClickableTextButton
            isBold
            className={this.props.classes.referencesLink}
            onClick={() => push('/he-models/copeptin-calculator/copeptin-references')}
            text={copeptinStore.getLocalizedText('copeptinReferences')}
          />
        </div>
        <CopeptinNavButton
          nextOnClick={location => this.props.routing.push(location)}
          backOnClick={() => {
            this.props.copeptinStore.setLocation(this.props.routing.location.pathname);
            push('/he-models/copeptin-calculator/inputs-percent');
          }}
          screenNumber={4}
        />
      </div>
    );
  }

  calculationHelper;

  getStackedBarChartData = () => {
    const { copeptinStore } = this.props;
    const legendArray = [];
    const dataArray = [[], []];
    const lastIndex = copeptinStore.acuteSettingCosts.length - 1;
    copeptinStore.acuteSettingCosts.map((item, index) => {
      if (index !== 9 && index < lastIndex) {
        legendArray.push(copeptinStore.getLocalizedText(item.name));
        dataArray[0].push(this.calculationHelper.fixFloat(item.copeptin_value));
        dataArray[1].push(this.calculationHelper.fixFloat(item.default_value));
      }
    });
    return {
      labels: [copeptinStore.getLocalizedText('copeptinResultStackedBarChartCopeptin'), copeptinStore.getLocalizedText('copeptinResultStackedBarChartDefault')],
      data: dataArray,
      maxValue: Math.ceil(Math.max(copeptinStore.acuteSettingCosts[lastIndex].copeptin_value, copeptinStore.acuteSettingCosts[lastIndex].default_value) / 50) * 50,
      legend: legendArray,
      barColors: simappColors.copeptinStackBarChart
    };
  }

  getCostsBarChartData = () => {
    const { copeptinStore } = this.props;
    const labelsArray = [];
    const dataArray = [];
    labelsArray.push(copeptinStore.getLocalizedText('copeptinResultBarChartDefault'));
    dataArray.push([copeptinStore.acuteSettingCosts[copeptinStore.acuteSettingCosts.length - 1].default_value]);
    labelsArray.push(copeptinStore.getLocalizedText('copeptinResultBarChartCopeptin'));
    dataArray.push([copeptinStore.acuteSettingCosts[copeptinStore.acuteSettingCosts.length - 1].copeptin_value]);
    return {
      labels: labelsArray,
      data: dataArray,
      legend: [],
      barColors: [simappColors.copeptinGrey, simappColors.copeptinTurqois],
      subLabelColor: simappColors.black
    };
  }

  getStackedBarChartConfig = () => ({
    backgroundColor: simappColors.palerGrey,
    backgroundGradientFrom: simappColors.palerGrey,
    backgroundGradientTo: simappColors.palerGrey,
    decimalPlaces: 0,
    color: () => simappColors.copeptinGrey,
    strokeWidth: 7, // optional, default 3
    barPercentage: 1,
  })

  getBarChartData = () => {
    const { copeptinStore } = this.props;
    const labelsArray = [];
    const subLabelsArray = [];
    const dataArray = [];
    labelsArray.push(copeptinStore.getLocalizedText('copeptinResultBarChartDefault'));
    dataArray.push([copeptinStore.staffTimesDoctor[copeptinStore.staffTimesDoctor.length - 1].default_value]);
    labelsArray.push(copeptinStore.getLocalizedText('copeptinResultBarChartCopeptin'));
    dataArray.push([copeptinStore.staffTimesDoctor[copeptinStore.staffTimesDoctor.length - 1].copeptin_value]);
    subLabelsArray.push(copeptinStore.getLocalizedText('copeptinResultBarChartDoctor'));
    labelsArray.push(copeptinStore.getLocalizedText('copeptinResultBarChartDefault'));
    dataArray.push([copeptinStore.staffTimesNursing[copeptinStore.staffTimesNursing.length - 1].default_value]);
    labelsArray.push(copeptinStore.getLocalizedText('copeptinResultBarChartCopeptin'));
    dataArray.push([copeptinStore.staffTimesNursing[copeptinStore.staffTimesNursing.length - 1].copeptin_value]);
    subLabelsArray.push(copeptinStore.getLocalizedText('copeptinResultBarChartNursing'));
    labelsArray.push(copeptinStore.getLocalizedText('copeptinResultBarChartDefault'));
    dataArray.push([copeptinStore.staffTimesTotal[copeptinStore.staffTimesTotal.length - 1].default_value]);
    labelsArray.push(copeptinStore.getLocalizedText('copeptinResultBarChartCopeptin'));
    dataArray.push([copeptinStore.staffTimesTotal[copeptinStore.staffTimesTotal.length - 1].copeptin_value]);
    subLabelsArray.push(copeptinStore.getLocalizedText('copeptinResultBarChartTotal'));
    return {
      labels: labelsArray,
      subLabels: subLabelsArray,
      data: dataArray,
      legend: [],
      barColors: [simappColors.copeptinGrey, simappColors.copeptinTurqois],
      subLabelColor: simappColors.black
    };
  }

  getBarChartConfig = () => ({
    backgroundColor: simappColors.palerGrey,
    backgroundGradientFrom: simappColors.palerGrey,
    backgroundGradientTo: simappColors.palerGrey,
    decimalPlaces: 0, // optional, defaults to 0dp
    color: () => simappColors.copeptinGrey,
    strokeWidth: 7, // optional, default 3
    barPercentage: 1
  })

  render() {
    const { push } = this.props.routing;
    const { classes, copeptinStore } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.textContainer}>
          <Typography align="center" variant="subtitle1">{copeptinStore.getLocalizedText('copeptinResultHeaderText')}</Typography>
        </div>
        <div>
          <CopeptinTableResults
            source={copeptinStore.staffTimesDoctor}
            headerFirstColumn={copeptinStore.getLocalizedText('copeptinResultStaffTimesDoctor')}
            headerSecondColumn={copeptinStore.getLocalizedText('copeptinResultCopeptin')}
            headerThirdColumn={copeptinStore.getLocalizedText('copeptinResultDefault')}
            headerFourthColumn={copeptinStore.getLocalizedText('copeptinResultDiff')}
            dataType={copeptinConstants.CopeptinTableRowTime}
          />
          <CopeptinTableResults
            source={copeptinStore.staffTimesNursing}
            headerFirstColumn={copeptinStore.getLocalizedText('copeptinResultStaffTimesNursing')}
            headerSecondColumn={copeptinStore.getLocalizedText('copeptinResultCopeptin')}
            headerThirdColumn={copeptinStore.getLocalizedText('copeptinResultDefault')}
            headerFourthColumn={copeptinStore.getLocalizedText('copeptinResultDiff')}
            dataType={copeptinConstants.CopeptinTableRowTime}
          />
          <CopeptinTableResults
            source={copeptinStore.staffTimesTotal}
            headerFirstColumn={copeptinStore.getLocalizedText('copeptinResultStaffTimesTotal')}
            headerSecondColumn={copeptinStore.getLocalizedText('copeptinResultCopeptin')}
            headerThirdColumn={copeptinStore.getLocalizedText('copeptinResultDefault')}
            headerFourthColumn={copeptinStore.getLocalizedText('copeptinResultDiff')}
            dataType={copeptinConstants.CopeptinTableRowTime}
            isExpandable={false}
          />
          <CopeptinTableResults
            source={copeptinStore.acuteSettingCosts}
            headerFirstColumn={copeptinStore.getLocalizedText('copeptinResultCosts')}
            headerSecondColumn={copeptinStore.getLocalizedText('copeptinResultCopeptin')}
            headerThirdColumn={copeptinStore.getLocalizedText('copeptinResultDefault')}
            headerFourthColumn={copeptinStore.getLocalizedText('copeptinResultDiff')}
            dataType={copeptinConstants.CopeptinTableRowCurrency}
          />
          <CopeptinTableResults
            source={copeptinStore.acuteSettingCostsAllPatients}
            headerFirstColumn={copeptinStore.getLocalizedText('copeptinResultTotalCosts')}
            headerSecondColumn={copeptinStore.getLocalizedText('copeptinResultCopeptin')}
            headerThirdColumn={copeptinStore.getLocalizedText('copeptinResultDefault')}
            headerFourthColumn={copeptinStore.getLocalizedText('copeptinResultDiff')}
            headerPercentageColumn={copeptinStore.getLocalizedText('copeptinResultPercentage')}
            dataType={copeptinConstants.CopeptinTableRowCurrency}
            hasPercentageColumn
          />
        </div>
        <div className={classes.textContainer}>
          <Typography align="center" variant="subtitle1">{copeptinStore.getLocalizedText('copeptinResultBottomText')}</Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography align="center" variant="subtitle1">{copeptinStore.getLocalizedText('copeptinResultBarChartHeader')}</Typography>
        </div>
        <div className={classes.barChartWrapper}>
          <div className={classes.barChart}>
            <Typography className={classes.barChartYAxis} align="center" variant="subtitle1">{copeptinStore.getLocalizedText('copeptinResultBarChartYAxis')}</Typography>
            <CopeptinBarChart
              data={this.getBarChartData()}
              width={760}
              height={350}
              verticalLabelRotation={0}
              fromZero
              chartConfig={this.getBarChartConfig()}
              withHorizontalLabels
              showDifferences
              differencesLabel="min"
              differencesAbsolute
              minMaxLineValue={200}
            />
          </div>
        </div>
        <div className={classes.textContainer}>
          <Typography align="center" variant="subtitle1">{copeptinStore.getLocalizedText('copeptinResultStackedBarChartHeader')}</Typography>
        </div>
        <div className={classes.barChart}>
          <Typography className={classes.barChartYAxis} align="center" variant="subtitle1">{copeptinStore.getLocalizedText('copeptinResultStackedBarChartYAxis')}</Typography>
          <CopeptinBarChart
            data={this.getCostsBarChartData()}
            width={240}
            height={400}
            verticalLabelRotation={0}
            fromZero
            chartConfig={this.getBarChartConfig()}
            withHorizontalLabels
            showDifferences
          />
        </div>
        {this.getBottomBar(push)}
        <CopeptinDialog />
      </div>
    );
  }
}

CopeptinResults.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  copeptinStore: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired
};

export default withStyles(styles)(CopeptinResults);
