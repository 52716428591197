import { observable, runInAction, computed } from 'mobx';
import rest from 'shared/lib/rest';
import { merge } from 'shared/lib/helpers';

const route = '/qcvc_analyte';

class QcAnalyteStore {
  @observable analyteData = [];

  @computed get hasDataLoaded() {
    return !!this.analyteData.length;
  }

  // eslint-disable-next-line
  loadData(forceReload = false, lastUpdated = null) {
    const startTime = new Date().getTime();
    return new Promise((resolve) => {
      let params = '?';
      if (this.hasDataLoaded && !forceReload && lastUpdated !== null) {
        params += `since=${lastUpdated}`;
      }

      rest.get(route + params).then((response) => {
        runInAction(() => {
          merge(this.analyteData, response.data, 'id', 'name');
          console.log(`${new Date().getTime() - startTime}ms - ${this.constructor.name}`);
          resolve();
        });
      }).catch((error) => {
        console.log('Error object', error);
        console.log('Could not load qcvc analyte data', error.response.data);
        resolve();
      });
    });
  }
}

export default new QcAnalyteStore();
