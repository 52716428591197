import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { simappConstants } from 'shared/lib/theme';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import InputField from 'web/components/_ui/form-elements/input-field';
import Divider from '@material-ui/core/Divider';
import SimAppButton from 'web/components/_ui/simapp-button';
import SelectField from 'web/components/_ui/form-elements/select-field';
import { getItemFromList } from '../../../shared/helper/qc-helper';
import InputSelectionHelper from '../../../shared/helper/input-selection-helper';

const styles = {
  root: {
    height: '100vH',
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: simappConstants.contentMarginLarge
  },
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 8,
    marginTop: '1em',
    marginBottom: '1em'
  },
  headRow: {
    display: 'flex',
    flexShrink: 0,
    height: 46,
    marginBottom: '24px',
    alignItems: 'left'
  },
  formPaperTop: {
    paddingLeft: '1em',
    paddingRight: '1em'
  },
  formPaperMiddle: {
    paddingLeft: '1em',
    paddingRight: '1em'
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  saveButton: {
    marginTop: '1em'
  }
};

@inject('qcStore', 'routing', 'qcNewCustomerForm') @observer
class QcNewCustomer extends Component {
  constructor(props) {
    super(props);

    this.inputSelectionHelper = new InputSelectionHelper();
    this.props.qcNewCustomerForm.resetToDefaultValues();
  }

  save = () => {
    const { push } = this.props.routing;
    this.props.qcNewCustomerForm.save();
    if (this.props.qcNewCustomerForm.isValid) {
      push('/qc-calculator/product-selection');
    }
  }

  getCurrentSelectedCountry = () => {
    const { qcStore, qcNewCustomerForm } = this.props;
    const countryItem = qcStore.getCountryObject(qcNewCustomerForm.fields.country.value);
    if (countryItem) {
      return countryItem.id;
    }
    return undefined;
  }

  getCurrentValueCountry = () => {
    const { qcStore, qcNewCustomerForm } = this.props;
    const countryItem = qcStore.getCountryObject(qcNewCustomerForm.fields.country.value);
    if (countryItem) {
      return countryItem.name;
    }
    return undefined;
  }

  render() {
    const { qcStore, classes, qcNewCustomerForm } = this.props;
    const { fields, onFieldChange, onDropDownFieldChange } = qcNewCustomerForm;

    return (
      <div className={classes.root}>
        <div className={classes.headerRow}>
          <Typography variant="h5" gutterBottom>{qcStore.getText('QCNewCustomerTitle')}</Typography>
        </div>
        <Grid item xs={12} md={8} lg={8}>
          <form>
            <Paper className={classes.formPaperTop}>
              <InputField fields={fields} name="customerName" onChange={onFieldChange} />
              <Divider light />
              <InputField fields={fields} name="customerNumber" onChange={onFieldChange} />
              <Divider light />
              <SelectField
                fields={fields}
                name="country"
                list={qcStore.getCountrySelectData()}
                currentSelect={this.getCurrentSelectedCountry()}
                currentValue={this.getCurrentValueCountry()}
                valueField="name"
                onChange={id => onDropDownFieldChange(getItemFromList(id, qcStore.getCountrySelectData()).iso)}
              />
              <Divider light />
              <InputField fields={fields} name="workingDaysPerWeek" onChange={onFieldChange} />
              <Divider light />
              <InputField fields={fields} name="contractPeriodInMonths" onChange={onFieldChange} />
              <Divider light />
              <InputField fields={fields} name="customerDescription" onChange={onFieldChange} />
            </Paper>
            <div className={classes.headerRow}>
              <Typography variant="h5" gutterBottom>{qcStore.getText('QCPrimaryCustomerContactTitle')}</Typography>
            </div>
            <Paper className={classes.formPaperMiddle}>
              <InputField fields={fields} name="contactName" onChange={onFieldChange} />
              <Divider light />
              <InputField fields={fields} name="contactTitle" onChange={onFieldChange} />
              <Divider light />
              <InputField fields={fields} name="contactEmail" onChange={onFieldChange} />
              <Divider light />
              <InputField fields={fields} name="contactPhone" onChange={onFieldChange} />
            </Paper>
          </form>
          <div className={classes.buttonRow}>
            <SimAppButton className={classes.saveButton} onClick={() => { this.save(); }}>{qcStore.getText('QCSaveNewCustomerButtonLabel')}</SimAppButton>
          </div>
        </Grid>
      </div>
    );
  }
}

QcNewCustomer.wrappedComponent.propTypes = {
  qcStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  qcNewCustomerForm: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired
};

export default withStyles(styles)(QcNewCustomer);
