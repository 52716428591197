import { Model } from '@nozbe/watermelondb';

export default class SimappModel extends Model {
  /**
   * In contrast to _raw, this returns an object with the field names in camelCase (as defined in the model class)
   */
  getFlat() {
    const result = {};
    for (const key in this) {
      if (typeof this[key] !== 'function' && key !== 'collection' && key !== 'id' && key !== 'bId' && key.charAt(0) !== '_') {
        result[key] = this[key];
      }
    }
    // overwrite Watermelon ID with backend ID
    result.id = this.bId;
    return result;
  }
}
