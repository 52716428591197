import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { simappColors } from 'shared/lib/theme';
import NumberFormat from 'react-number-format';
import { getTotalCosts, getTotalQCVolume } from 'shared/helper/qc-helper';
import SimAppButton from 'web/components/_ui/simapp-button';
import AlertDialog from 'web/components/_misc/alert-dialog';
import WebWorker from 'web/helper/web-worker';
import TestViewWorker from 'shared/helper/qc-calculator-worker';
import QcCompetitorProducts from './qc-competitor-products';
import QcProductTable from './qc-product-table';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontWeight: 'normal'
  },
  container: {
    display: 'flex',
    flexDirection: 'row'
  },
  halfWidth: {
    width: 'calc(50% - 1px)',
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
    '&.border': {
      borderRight: `1px solid ${simappColors.steelGrey}`
    }
  },
  fullWidth: {
    width: '100%',
    flex: 1,
    flexDirection: 'column',
    display: 'flex',
  },
  headline: {
    flexDirection: 'row',
    justifyContent: 'center',
    display: 'flex',
    height: '36px',
    marginTop: '20px',
    marginBottom: '20px',
    paddingLeft: '20px',
    paddingRight: '20px',
    '& img': {
      height: '100%'
    }
  },
  separator: {
    color: `${simappColors.steelGrey}`,
    width: '100%'
  },
  summaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& div': {
      alignItems: 'center',
      flexDirection: 'column',
      display: 'flex'
    },
    '& div div': {
      alignItems: 'center',
      flexDirection: 'row',
      display: 'flex'
    },
    '&.bold': {
      fontWeight: 'bold',
      marginTop: '30px'
    }
  },
  inlineText: {
    display: 'flex',
    flex: 1,
    marginLeft: '3px'
  },
  buttonRow: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginTop: '30px',
    paddingLeft: '20px',
    paddingRight: '20px',
    marginBottom: '20px'
  },
};

@inject('qcStore', 'routing') @observer
class QcComparisonView extends Component {
  qcCalculator;

  constructor(props) {
    super(props);
    this.state = {
      isCalculating: false
    };
  }

  componentDidMount = () => {
    this.worker = new WebWorker(TestViewWorker);
  };

  onClose = () => {
    const { qcStore } = this.props;
    qcStore.setNoCompetitorProductWithThermoFisherProduct(false);
  }

  onCalculateClick = () => {
    this.setState({
      isCalculating: true
    });

    this.worker.postMessage(JSON.stringify(
      {
        competitorProductList: this.props.qcStore.competitorProductList,
        productList: this.props.qcStore.getThermoProducts(),
        workingDaysPerWeek: this.props.qcStore.customerData.workingDaysPerWeek,
        contractPeriodInMonths: this.props.qcStore.customerData.contractPeriodInMonths,
        isTestView: false,
        priceIso: this.props.qcStore.getPriceCountryIso()
      }
    ));
    this.worker.addEventListener('message', (e) => {
      this.setState({
        isCalculating: false
      });
      const result = JSON.parse(e.data);
      this.props.qcStore.setNoCompetitorProductWithThermoFisherProduct(result.noProducts);
      this.props.qcStore.setCheapestResult(result.cheapest);
      this.props.qcStore.setSmallestQuantityResult(result.smallestQuantity);
      this.props.qcStore.setFewestSKUResult(result.smallestSKU);
      if (!this.props.qcStore.noCompetitorProductWithThermoFisherProduct) {
        this.props.qcStore.setCalculationView('ComparisonView');
        this.props.routing.push('/qc-calculator/options-proposal');
      }
    });
  }

  getProductSummary(products) {
    const { classes, qcStore } = this.props;
    const numberOfProducts = products.length;
    const totalCosts = getTotalCosts(products);
    const totalQCVolume = getTotalQCVolume(products);
    const costPerML = totalCosts !== 0 && totalQCVolume !== 0 ? Math.ceil(totalCosts / totalQCVolume) : 0;
    return (
      <div className={classes.summaryContainer}>
        <div>
          <div>{qcStore.getText('QCProductSelectionSummaryNumberOfProducts')}: {numberOfProducts}</div>
          <div>{qcStore.getText('QCProductSelectionSummaryCostPerML')}:
            <NumberFormat
              id="costPerMl"
              value={costPerML}
              className={classes.inlineText}
              onValueChange={() => { }}
              decimalScale={2}
              fixedDecimalScale
              decimalSeparator={qcStore.getDecimalSeparator()}
              thousandSeparator={qcStore.getThousandSeparator()}
              prefix={qcStore.getCurrencyPosition() === 'prefix' ? qcStore.getCurrencySign() : ''}
              suffix={qcStore.getCurrencyPosition() === 'suffix' ? ` ${qcStore.getCurrencySign()}` : ''}
              displayType="text"
            />
          </div>
          <div>{qcStore.getText('QCProductSelectionSummaryTotalQCVolume')}:
            <NumberFormat
              id="totalQcVolume"
              value={totalQCVolume}
              className={classes.inlineText}
              onValueChange={() => { }}
              decimalScale={0}
              fixedDecimalScale
              decimalSeparator={qcStore.getDecimalSeparator()}
              thousandSeparator={qcStore.getThousandSeparator()}
              suffix=" ml"
              displayType="text"
            />
          </div>
          <div>{qcStore.getText('QCProductSelectionSummaryTotalCost')}:
            <NumberFormat
              id="totalCost"
              value={totalCosts}
              className={classes.inlineText}
              onValueChange={() => { }}
              decimalScale={2}
              fixedDecimalScale
              decimalSeparator={qcStore.getDecimalSeparator()}
              thousandSeparator={qcStore.getThousandSeparator()}
              prefix={qcStore.getCurrencyPosition() === 'prefix' ? qcStore.getCurrencySign() : ''}
              suffix={qcStore.getCurrencyPosition() === 'suffix' ? ` ${qcStore.getCurrencySign()}` : ''}
              displayType="text"
            />
          </div>
        </div>
      </div>
    );
  }

  getComparisonSummary() {
    const { classes, qcStore } = this.props;
    const totalCostsCompared = getTotalCosts(qcStore.productList) - getTotalCosts(qcStore.competitorProductList);
    const totalNumberCompared = qcStore.productList.length - qcStore.competitorProductList.length;
    return (
      <div className={`${classes.summaryContainer} bold`}>
        <div>
          <div>{qcStore.getText('QCProductSelectionComparisonViewSummaryTotalCostCompared')}:
            <NumberFormat
              id="totalCostCompared"
              value={totalCostsCompared}
              className={classes.inlineText}
              onValueChange={() => { }}
              decimalScale={2}
              fixedDecimalScale
              decimalSeparator={qcStore.getDecimalSeparator()}
              thousandSeparator={qcStore.getThousandSeparator()}
              prefix={qcStore.getCurrencyPosition() === 'prefix' ? qcStore.getCurrencySign() : ''}
              suffix={qcStore.getCurrencyPosition() === 'suffix' ? ` ${qcStore.getCurrencySign()}` : ''}
              displayType="text"
            />
          </div>
          <div>{qcStore.getText('QCProductSelectionComparisonViewSummaryNumberOfProductsCompared')}: {totalNumberCompared}</div>
        </div>
      </div>
    );
  }

  render() {
    const { classes, qcStore } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={qcStore.productList.length > 0 ? `${classes.halfWidth} border` : `${classes.fullWidth}`}>
            <QcCompetitorProducts />
          </div>
          {qcStore.productList.length > 0
            ? (
              <div className={classes.halfWidth}>
                <div className={classes.headline}>
                  <img src="/icons/logo@2x.png" alt="" />
                </div>
                <QcProductTable showKitFormat showDiscountedPriceWhenActive />
              </div>
            )
            : ''}
        </div>
        <hr className={classes.separator} />
        <div className={classes.container}>
          <div className={qcStore.productList.length > 0 ? `${classes.halfWidth} border` : `${classes.fullWidth}`}>
            {this.getProductSummary(qcStore.competitorProductList, qcStore)}
          </div>
          {qcStore.productList.length > 0
            ? (
              <div className={classes.halfWidth}>
                {this.getProductSummary(qcStore.productList, qcStore)}
              </div>
            ) : ''}
        </div>
        {qcStore.productList.length > 0
          ? (
            <div>
              {this.getComparisonSummary()}
            </div>
          ) : ''}
        <div className={classes.buttonRow}>
          <SimAppButton
            className={classes.button}
            onClick={() => this.onCalculateClick()}
            disabled={!getTotalQCVolume(qcStore.competitorProductList)}
            inline
            isCalculateButton
            isCalculating={this.state.isCalculating}
          >{qcStore.getText('QCProductSelectionCalculate')}
          </SimAppButton>
        </div>
        <AlertDialog trigger={qcStore.noCompetitorProductWithThermoFisherProduct} message={qcStore.getText('QCProductSelectionComparisonViewAllCompetitorProductsWithoutThermoFisherProduct')} close={this.onClose} />
      </div>
    );
  }
}

QcComparisonView.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired,
  qcStore: PropTypes.object.isRequired
};

export default withStyles(styles)(QcComparisonView);
