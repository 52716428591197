import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { simappConstants } from 'shared/lib/theme';
import SearchBar from 'web/components/_ui/searchbar';
import QcCustomerTable, { getLocalTimeString } from 'web/components/qc/qc-customer-table';

const constants = simappConstants;

const styles = {
  root: {
    width: '100%',
    height: '100vH',
    display: 'flex',
    flexDirection: 'column',
    padding: constants.contentMarginLarge,
    overflowX: 'hidden',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontWeight: 'normal'
  },
  headRow: {
    display: 'flex',
    flexDirection: 'column',
    flexShrink: 0,
    height: 46,
    marginBottom: '12px',
    marginTop: '12px',
    alignItems: 'left'
  },
  buttonRoot: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '200px',
  },
  navigationbutton: {
    marginLeft: '5px',
    marginRight: '5px'
  },
  title: {
    margin: '0',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '28px',
    lineHeight: '40px'
  }
};

@inject('qcStore', 'qcCustomerStore', 'routing', 'qcNewCustomerForm') @observer
class QcExistingCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: ''
    };
  }

  onSelect = (id) => {
    if (this.props.qcStore.getCustomerProposalList().length > 0) {
      this.props.qcStore.loadCustomerProposal(id);
      this.props.qcNewCustomerForm.loadValuesFromStorage();
    }
    this.props.routing.push('/qc-calculator/existing-customer-review');
  }

  onSearchBarChange = (value) => {
    this.setState({
      searchString: value
    });
  }

  filterCustomer(customer) {
    if ((customer.customer_number && customer.customer_number.toString().toLowerCase().startsWith(this.state.searchString.toLowerCase()))
    || (customer.customer_name && customer.customer_name.toLowerCase().includes(this.state.searchString.toLowerCase()))
    || (customer.customer_description && customer.customer_description.toLowerCase().includes(this.state.searchString.toLowerCase()))
    || (customer.updated_at && getLocalTimeString(customer.updated_at).toLowerCase().includes(this.state.searchString.toLowerCase()))) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <div className={this.props.classes.root}>
        <div className={this.props.classes.headRow}>
          <Typography
            variant="h1"
            className={this.props.classes.title}
          >{this.props.qcStore.getText('QCExistingCustomerHeadline')}
          </Typography>
        </div>
        <div className={this.props.classes.headRow}>
          <SearchBar onChange={this.onSearchBarChange} searchString={this.state.searchString} />
        </div>
        <QcCustomerTable
          data={this.state.searchString ? this.props.qcCustomerStore.customerData.filter(customer => this.filterCustomer(customer)) : this.props.qcCustomerStore.customerData}
          deleteCustomer={(id) => { this.props.qcCustomerStore.deleteCustomer(id); }}
          onCustomerSelected={(id) => { this.onSelect(id); }}
        />
      </div>
    );
  }
}

QcExistingCustomer.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  qcStore: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired,
  qcNewCustomerForm: PropTypes.object.isRequired,
  qcCustomerStore: PropTypes.object.isRequired
};

export default withStyles(styles)(QcExistingCustomer);
