import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

let checkBoxValue = false;

function GetPctDialog(trigger, message, okText, cancelText, onOk, onCancel, hasCheckbox, checkboxMessage) {
  return (
    <Dialog open={trigger} onExited={() => { checkBoxValue = false; }}>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        {hasCheckbox
          ? (
            <FormControlLabel
              control={(<Checkbox onChange={() => { checkBoxValue = !checkBoxValue; }} value="checkedB" color="primary" />)}
              label={checkboxMessage}
            />
          )
          : ''}
      </DialogContent>
      <DialogActions>
        {hasCheckbox ? '' : <Button onClick={onCancel} color="primary">{cancelText}</Button>}
        <Button
          onClick={
            hasCheckbox
              ? () => onOk(checkBoxValue)
              : () => onOk()}
          color="primary"
        >{okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function GetPctNavigationDialog(applicationStore, pctStore, routing) {
  const message = pctStore.getText(applicationStore.location.endsWith('population') ? 'PctGoBackAlert' : 'PctNavigationAlert');
  const okText = pctStore.getText(applicationStore.location.endsWith('population') ? 'PctGoBackAlertOk' : 'PctNavigationAlertOk');
  const cancelText = pctStore.getText(applicationStore.location.endsWith('population') ? 'PctGoBackAlertCancel' : 'PctNavigationAlertCancel');

  const onCancel = () => {
    applicationStore.disablePctLeavingAlert();
    applicationStore.setLocation('');
    // although mobx-router has still the Pct pathname, the browser does not
    // so we have to set it again
    routing.push(routing.location.pathname);
  };
  const onOk = () => {
    pctStore.resetData();
    applicationStore.disablePctLeavingAlert();
    applicationStore.setLocation('');
    routing.push(applicationStore.interceptedPathname);
  };

  return (
    <Dialog open={applicationStore.isPctLeavingAlert}>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="outlined" color="primary">{cancelText}</Button>
        <Button onClick={onOk} variant="contained" color="primary">{okText}</Button>
      </DialogActions>
    </Dialog>
  );
}

const PctDialog = inject('applicationStore', 'pctStore', 'routing')(observer((props) => {
  if (props.trigger === undefined) {
    const { applicationStore, pctStore, routing } = props;
    return GetPctNavigationDialog(applicationStore, pctStore, routing);
  }
  const { trigger, message, okText, cancelText, onOk, onCancel, hasCheckbox, checkboxMessage } = props;
  return GetPctDialog(trigger, message, okText, cancelText, onOk, onCancel, hasCheckbox, checkboxMessage);
}));

PctDialog.wrappedComponent.propTypes = {
  applicationStore: PropTypes.object.isRequired,
  pctStore: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired,
  trigger: PropTypes.bool,
  message: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  hasCheckbox: PropTypes.bool,
  checkboxMessage: PropTypes.string,
};

PctDialog.defaultProps = {
  trigger: undefined,
  message: '',
  okText: 'ok',
  cancelText: 'cancel',
  onOk: () => { },
  onCancel: () => { },
  hasCheckbox: false,
  checkboxMessage: ''
};

export default PctDialog;
