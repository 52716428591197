import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { simappColors } from 'shared/lib/theme';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NumberFormat from 'react-number-format';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import EditIcon from '@material-ui/icons/Create';
import { ConsumptionCalculation } from '../../../shared/helper/kryptor-calculationhelper';

const colors = simappColors;

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'hidden',
  },
  assay: {
    display: 'flex',
    background: colors.white,
    flexDirection: 'column',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    width: '100%',
    borderRadius: '2px 2px 2px 2px'
  },
  title: {
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontSize: '18px',
    lineHeight: '56px',
    paddingLeft: '8px'
  },
  rowTitle: {
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontSize: '16px',
    lineHeight: '56px',
    fontWeight: '500',
    whiteSpace: 'nowrap'
  },
  inputTitle: {
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontSize: '10px',
    lineHeight: '22px',
    paddingTop: '7px',
    fontWeight: '500',
    letterSpacing: '0.91px',
    color: colors.steelGrey,
    width: '200px'
  },
  input: {
    fontSize: '16px',
    height: '32px',
    lineHeight: '32px',
    paddingBottom: '8px',
    border: 'none',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    WebkitTextFillColor: colors.placeholderGrey,
    '&.value': {
      WebkitTextFillColor: colors.black
    }
  },
  gridRow: {
    borderBottom: `1px solid ${colors.paleGrey}`,
    alignItems: 'flex-start',
    justifyContent: 'center',
    '&.last': {
      borderBottom: 'none'
    }
  },
  gridItem: {
    minHeight: '56px',
    flexBasis: 'unset',
    paddingLeft: '24px',
    flexGrow: 0,
    '&.full': {
      minWidth: '100%'
    },
    '&.nopadding': {
      paddingLeft: '0'
    },
    '&.patient': {
      minWidth: '208px',
      minHeight: '69px',
      width: '30%',
      borderRight: `1px solid ${colors.paleGrey}`,
      '& input': {
        width: '95%'
      },
      '& input:focus': {
        outline: 'none',
      }
    },
    '&.tests': {
      minWidth: '115px',
      minHeight: '69px',
      borderRight: `1px solid ${colors.paleGrey}`,
      paddingLeft: '15px',
      width: '30%',
      '& input': {
        width: '95%'
      },
      '& input:focus': {
        outline: 'none',
      },
      '& p': {
        width: '100px'
      }
    },
    '&.workingDays': {
      minWidth: '311px',
      minHeight: '69px',
      paddingLeft: '16px',
      paddingRight: '16px',
      width: '40%',
      '& input': {
        width: '95%'
      },
      '& input:focus': {
        outline: 'none',
      },
      '& p': {
        width: '140px'
      }
    },
    '&.numberInstruments': {
      minWidth: '311px',
      minHeight: '69px',
      paddingLeft: '16px',
      paddingRight: '16px',
      width: '40%',
      '&.bigPadding': {
        paddingLeft: '24px'
      },
      '& input': {
        width: '95%'
      },
      '& input:focus': {
        outline: 'none',
      },
      '& p': {
        width: '194px'
      }
    },
    '&.aliquot': {
      minWidth: '208px',
      minHeight: '69px',
      borderRight: `1px solid ${colors.paleGrey}`,
      width: '30%',
      '& input': {
        width: '95%'
      },
      '& input:focus': {
        outline: 'none',
      },
      '&.placeholder': {
        marginLeft: '30%',
        minWidth: 'unset'
      }
    },
    '&.qcAliquot': {
      minWidth: '115px',
      minHeight: '69px',
      borderRight: `1px solid ${colors.paleGrey}`,
      paddingLeft: '15px',
      width: '30%',
      '& input': {
        width: '95%'
      },
      '& input:focus': {
        outline: 'none',
      },
      '& p': {
        width: '183px'
      }
    }
  },
  button: {
    fontSize: '16px',
    height: '32px',
    color: `${colors.azul}`,
    border: `1px solid ${colors.azul}`,
    borderRadius: '0',
    minHeight: 'unset',
    minWidth: 'unset',
    padding: '3px 5px 5px 5px',
    width: '54px',
    '&:hover, &:focus, &:active': {
      backgroundColor: 'transparent'
    }
  },
  minusButton: {
    borderRadius: '4px 0 0 4px',
    borderRight: 'none',
    '&.inactive': {
      border: `1px solid ${colors.paleGrey}`,
      borderRight: 'none',
      color: `${colors.paleGrey}`
    }
  },
  plusButton: {
    borderRadius: '0 4px 4px 0',
    borderLeft: `1px solid ${colors.azul}`,
    '&.inactive': {
      border: `1px solid ${colors.paleGrey}`,
      borderLeft: `1px solid ${colors.azul}`,
      color: `${colors.paleGrey}`
    },
    '&.inactive.inactiveAll': {
      borderLeft: `1px solid ${colors.paleGrey}`,
    }
  },
  toggleControl: {
    width: '200px',
    fontSize: '16px',
    fontWeight: 'normal',
    lineHeight: '32px',
    marginTop: '11px',
    marginBottom: '12px',
    padding: '0',
    color: `${colors.azul}`,
    border: `1px solid ${colors.azul}`,
    borderRadius: '0',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&.active': {
      backgroundColor: `${colors.azul}`,
      color: `${colors.white}`
    }
  },
  leftToggle: {
    borderRadius: '4px 0 0 4px',
    borderRight: 'none'
  },
  rightToggle: {
    borderLeft: 'none',
    borderRadius: '0 4px 4px 0'
  },
  editIcon: {
    width: '24px',
    height: '24px',
  },
  editIconButton: {
    width: '24px',
    height: '24px',
    padding: '0',
    minWidth: 'unset',
    minHeight: 'unset',
    marginTop: '16px',
    marginLeft: '16px',
    backgroundColor: 'transparent',
    color: `${simappColors.paleGrey}`,
    '&:hover, &:focus, &:active': {
      backgroundColor: 'transparent'
    },
    '& span:first-child span': {
      display: 'none',
    }
  },
  removeImageDiv: {
    display: 'flex',
    alignItems: 'center',
    width: '56px'
  },
  removeImageButton: {
    padding: '0',
    width: '21px',
    height: '21px',
    minWidth: 'unset',
    marginLeft: '16px',
    minHeight: 'unset'
  },
  removeImage: {
    width: '21px',
    height: '21px',
    backgroundColor: 'transparent',
    '&:hover, &:focus, &:active': {
      backgroundColor: 'transparent'
    },
    '& span:first-child span': {
      display: 'none',
    }
  },
  divAddAssay: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '56px'
  },
  addIcon: {
    color: `${colors.azul}`,
    height: '32px',
    width: '32px'
  },
  addAssay: {
    fontFamily: 'helvetica-neue-lt, sans-serif',
    cursor: 'pointer',
    color: `${colors.azul}`,
    lineHeight: '56px',
    fontSize: '17px',
    marginLeft: '5px',
    fontWeight: 'normal'
  },
  divInline: {
    display: 'inline-block',
  },
  divInlineFullWidth: {
    display: 'inline-block',
    width: '100%'
  },
  divRightCentered: {
    display: 'inline-block',
    width: '110px',
    float: 'right',
    paddingTop: '19px',
    paddingBottom: '18px'
  },
  divSingleLineFlexLeft: {
    display: 'flex',
    flexDirection: 'row'
  },
  mcButtonContainer: {
    float: 'right',
    paddingRight: '16px'
  },
  fullRowLabel: {
    width: 'calc(100% - 4px)',
    marginLeft: 0,
    justifyContent: 'space-between'
  }
});

const removeImagePath = '/images/kryptorAssayRemove.png';
const workdayInput = 'workdayInput';
const patientsInput = 'patientsInput';
const addedTestsInput = 'addedTestsInput';
const workdayInputPlus = 'workdayInputPlus';
const workdayInputMinus = 'workdayInputMinus';
const workingDayRange = [1, 7];
const aliquotInput = 'aliquotInput';
const qcAliquotInput = 'qcAliquotInput';
const numberOfRelatedInstrumentsInput = 'relatedInstrumentsInput';
const numberInstrumentInputPlus = 'numberInstrumentInputPlus';
const numberInstrumentInputMinus = 'numberInstrumentInputMinus';
const VOLUME_PER_ALIQUOT_LOWER_LIMIT = 150;

@inject('kryptorStore', 'applicationStore') @observer
class KryptorAssay extends Component {
  calculation;

  daysSuffix = this.props.kryptorStore.getText('KryptorWorkingDaysSuffix');
  patientsSuffix = this.props.kryptorStore.getText('KryptorPatientsSuffix');
  kryptorAssay = undefined;

  constructor(props) {
    super(props);
    const assay = props.kryptorStore.getAssayFromList(props.id);
    this.calculation = new ConsumptionCalculation(props.kryptorStore);

    if (assay !== undefined) {
      // create independent copy to avoid mobx error
      this.kryptorAssay = JSON.parse(JSON.stringify(assay));
      this.state = {
        isAdded: !!this.kryptorAssay && !!this.kryptorAssay.name,
        patientCoverTimeValue: (this.kryptorAssay.patientsPerCoverTime !== undefined && this.kryptorAssay.patientsPerCoverTime !== '') && true,
        addedTests: (this.kryptorAssay.addedTests !== undefined && this.kryptorAssay.addedTests !== '') && true,
        volumePerAliquot: (this.kryptorAssay.volumePerAliquot !== undefined && this.kryptorAssay.volumePerAliquot !== '') && true,
        qCAliquot: (this.kryptorAssay.aliquotCoverPerInstrument !== undefined && this.kryptorAssay.aliquotCoverPerInstrument !== '') && true
      };
    } else {
      this.state = {
        isAdded: !!this.kryptorAssay && !!this.kryptorAssay.name
      };
    }
  }

  getActiveItem() {
    return (
      <div className={this.props.classes.root}>
        <Grid container direction="row" className={this.props.classes.assay}>
          <Grid container direction="row" className={this.props.classes.gridRow}>
            <Grid item xs className={`${this.props.classes.gridItem} full nopadding`}>
              <div className={this.props.classes.divSingleLineFlexLeft}>
                <Button className={this.props.classes.editIconButton} onClick={() => this.props.requestOverlay(this.props.id)}>
                  <EditIcon className={this.props.classes.editIcon} viewBox="-4 -4 32 32" />
                </Button>
                <Typography variant="body1" className={this.props.classes.title}>{this.kryptorAssay.name}</Typography>
              </div>
            </Grid>
          </Grid>
          <Grid container direction="row" className={this.props.classes.gridRow}>
            <Grid item xs className={`${this.props.classes.gridItem} patient`}>
              <Typography className={this.props.classes.inputTitle}>{this.props.kryptorStore.getText('KryptorNoPatients')}</Typography>
              <NumberFormat
                getInputRef={ref => this.props.inputSelectionHelper.registerInput(ref)}
                onKeyDown={e => this.props.inputSelectionHelper.keyDown(e)}
                onFocus={() => this.props.inputSelectionHelper.setCurrent(this.props.inputSelectionStartIndex + 1)}
                id={patientsInput + this.props.id}
                displayType="input"
                thousandSeparator=" "
                className={`${this.props.classes.input} ${this.state.patientCoverTimeValue && 'value'}`}
                suffix={this.kryptorAssay.patientsPerCoverTime === 1 ? this.patientsSuffix.substr(0, this.patientsSuffix.length - 1) : this.patientsSuffix}
                placeholder={this.props.kryptorStore.getText('KryptorPatientsPlaceholder')}
                onClick={event => event.target.select()}
                isAllowed={values => values.floatValue > 0 || values.floatValue === undefined}
                onValueChange={(values) => {
                  this.kryptorAssay.patientsPerCoverTime = values.floatValue;
                  this.update(false);
                  this.setState({
                    patientCoverTimeValue: (this.kryptorAssay.patientsPerCoverTime !== undefined && this.kryptorAssay.patientsPerCoverTime !== '') && true
                  });
                }}
                value={this.kryptorAssay.patientsPerCoverTime}
              />
            </Grid>
            <Grid item xs className={`${this.props.classes.gridItem} tests`}>
              <Typography variant="body1" className={this.props.classes.inputTitle}>{this.props.kryptorStore.getText('KryptorAddedTests')}</Typography>
              <NumberFormat
                getInputRef={ref => this.props.inputSelectionHelper.registerInput(ref)}
                onKeyDown={e => this.props.inputSelectionHelper.keyDown(e)}
                onFocus={() => this.props.inputSelectionHelper.setCurrent(this.props.inputSelectionStartIndex + 2)}
                id={addedTestsInput + this.props.id}
                displayType="input"
                className={`${this.props.classes.input} ${this.state.addedTests && 'value'}`}
                suffix={this.props.kryptorStore.getText('KryptorAddedTestsSuffix')}
                placeholder={this.props.kryptorStore.getText('KryptorAddedTestsPlaceholder')}
                isAllowed={values => values.floatValue >= -1 || values.floatValue === undefined}
                onClick={event => event.target.select()}
                onValueChange={(values) => {
                  this.kryptorAssay.addedTests = values.floatValue !== undefined ? values.floatValue / 100 : undefined;
                  this.update(false);
                  this.setState({
                    addedTests: (this.kryptorAssay.addedTests !== undefined && this.kryptorAssay.addedTests !== '') && true
                  });
                }}
                decimalSeparator={this.props.applicationStore.presentingInIso.toLowerCase() === 'us' ? '.' : ','}
                decimalScale={1}
                value={this.kryptorAssay.addedTests * 100}
              />
            </Grid>
            <Grid item xs className={`${this.props.classes.gridItem} workingDays`}>
              <div className={this.props.classes.divInline}>
                <Typography variant="body1" className={this.props.classes.inputTitle}>{this.props.kryptorStore.getText('KryptorWorkingDays')}</Typography>
                <NumberFormat
                  id={workdayInput + this.props.id}
                  value={this.kryptorAssay.workDaysPerWeek}
                  displayType="text"
                  className={`${this.props.classes.input} value`}
                  suffix={this.kryptorAssay.workDaysPerWeek === 1 ? this.daysSuffix.substr(0, this.daysSuffix.length - 1) : this.daysSuffix}
                  isAllowed={values => values.floatValue >= workingDayRange[0] && values.floatValue <= workingDayRange[1]}
                  onValueChange={(values) => {
                    this.kryptorAssay.workDaysPerWeek = values.floatValue;
                    this.updateWorkingDaysPerWeek(0);
                  }}
                />
              </div>
              <div className={this.props.classes.divRightCentered}>
                <Button
                  id={workdayInputMinus + this.props.id}
                  className={`${this.props.classes.button} ${this.props.classes.minusButton} ${this.kryptorAssay.workDaysPerWeek === 1 ? 'inactive' : ''}`}
                  onClick={() => this.updateWorkingDaysPerWeek(-1)}
                ><RemoveIcon />
                </Button>
                <Button
                  id={workdayInputPlus + this.props.id}
                  className={`${this.props.classes.button} ${this.props.classes.plusButton} ${this.kryptorAssay.workDaysPerWeek === 7 ? 'inactive' : ''}`}
                  onClick={() => this.updateWorkingDaysPerWeek(1)}
                ><AddIcon />
                </Button>
              </div>
            </Grid>
          </Grid>
          <Grid container direction="row" className={`${this.props.classes.gridRow}${!this.kryptorAssay.showMultiControl && !this.kryptorAssay.showRunRecoveryTest ? ' last' : ''}`}>
            {!this.kryptorAssay.showMultiControl
              ? (
                <Grid item xs className={`${this.props.classes.gridItem} aliquot`}>
                  <Typography className={this.props.classes.inputTitle}>{this.props.kryptorStore.getText('KryptorAliquotQC')}</Typography>
                  <NumberFormat
                    getInputRef={ref => this.props.inputSelectionHelper.registerInput(ref)}
                    onKeyDown={e => this.props.inputSelectionHelper.keyDown(e)}
                    onFocus={() => this.props.inputSelectionHelper.setCurrent(this.props.inputSelectionStartIndex + 3)}
                    id={aliquotInput + this.props.id}
                    displayType="input"
                    thousandSeparator=","
                    className={`${this.props.classes.input} ${this.state.volumePerAliquot && 'value'}`}
                    placeholder={this.props.kryptorStore.getText('KryptorAliquotPlaceholder')}
                    suffix={this.props.kryptorStore.getText('KryptorAliquotSuffix')}
                    onClick={event => event.target.select()}
                    isAllowed={values => values.floatValue > -1 || values.floatValue === undefined}
                    onValueChange={(values) => {
                      this.kryptorAssay.volumePerAliquot = values.floatValue;
                      this.update(false);
                      this.setState({
                        volumePerAliquot: (this.kryptorAssay.volumePerAliquot !== undefined && this.kryptorAssay.volumePerAliquot !== '') && true
                      });
                    }}
                    onBlur={() => {
                      this.kryptorAssay.volumePerAliquot = this.calculation.validateAndUpdateVolumeAliquot(this.kryptorAssay.volumePerAliquot, VOLUME_PER_ALIQUOT_LOWER_LIMIT, this.props.applicationStore, this.props.kryptorStore);
                      this.update(false);
                      this.setState({
                        volumePerAliquot: (this.kryptorAssay.volumePerAliquot !== undefined && this.kryptorAssay.volumePerAliquot !== '') && true
                      });
                    }}
                    value={this.kryptorAssay.volumePerAliquot}
                  />
                </Grid>
              )
              : <Grid item xs className={`${this.props.classes.gridItem} aliquot placeholder`} />
            }
            {!this.kryptorAssay.showMultiControl
              ? (
                <Grid item xs className={`${this.props.classes.gridItem} qcAliquot`}>
                  <Typography variant="body1" className={this.props.classes.inputTitle}>{this.props.kryptorStore.getText('KryptorQcAliquotCover')}</Typography>
                  <NumberFormat
                    getInputRef={ref => this.props.inputSelectionHelper.registerInput(ref)}
                    onKeyDown={e => this.props.inputSelectionHelper.keyDown(e)}
                    onFocus={() => this.props.inputSelectionHelper.setCurrent(this.props.inputSelectionStartIndex + 4)}
                    id={qcAliquotInput + this.props.id}
                    displayType="input"
                    className={`${this.props.classes.input} ${this.state.qCAliquot && 'value'}`}
                    placeholder={this.props.kryptorStore.getText('KryptorQCAliquotPlaceholder')}
                    value={this.kryptorAssay.aliquotCoverPerInstrument}
                    onClick={event => event.target.select()}
                    onValueChange={(values) => {
                      this.kryptorAssay.aliquotCoverPerInstrument = values.floatValue;
                      this.update(false);
                      this.setState({
                        qCAliquot: (this.kryptorAssay.aliquotCoverPerInstrument !== undefined && this.kryptorAssay.aliquotCoverPerInstrument !== '') && true
                      });
                    }}
                    onBlur={() => {
                      this.kryptorAssay.aliquotCoverPerInstrument = this.calculation.validateAndUpdateAliquotCoverPerInstrument(this.kryptorAssay.aliquotCoverPerInstrument, this.props.applicationStore, this.props.kryptorStore);
                      this.update(false);
                      this.setState({
                        qCAliquot: (this.kryptorAssay.aliquotCoverPerInstrument !== undefined && this.kryptorAssay.aliquotCoverPerInstrument !== '') && true
                      });
                    }}
                  />
                </Grid>
              )
              : ''}
            <Grid item xs className={`${this.props.classes.gridItem} numberInstruments ${this.kryptorAssay.showMultiControl ? 'bigPadding' : ''}`}>
              <div className={this.props.classes.divInline}>
                <Typography variant="body1" className={this.props.classes.inputTitle}>{this.props.kryptorStore.getText('KryptorNoInstruments')}</Typography>
                <NumberFormat
                  id={numberOfRelatedInstrumentsInput + this.props.id}
                  value={this.kryptorAssay.numberOfInstruments}
                  displayType="text"
                  className={`${this.props.classes.input} value`}
                  isAllowed={values => values.floatValue > 1 && values.floatValue <= this.props.kryptorStore.customerData.instrumentNumber}
                  onValueChange={(values) => {
                    this.kryptorAssay.numberOfInstruments = values.floatValue;
                    this.updateNumberRelatedInstruments(0);
                  }}
                />
              </div>
              <div className={this.props.classes.divRightCentered}>
                <Button
                  id={numberInstrumentInputMinus + this.props.id}
                  className={`${this.props.classes.button} ${this.props.classes.minusButton}${this.isRelatedInstrumentsMinusButtonActive() ? '' : ' inactive'}`}
                  onClick={() => this.updateNumberRelatedInstruments(-1)}
                ><RemoveIcon />
                </Button>
                <Button
                  id={numberInstrumentInputPlus + this.props.id}
                  className={`${this.props.classes.button} ${this.props.classes.plusButton}${this.isRelatedInstrumentsPlusButtonActive() ? '' : ' inactive'} ${this.isRelatedInstrumentsButtonGroupActive() ? '' : ' inactiveAll'}`}
                  onClick={() => this.updateNumberRelatedInstruments(1)}
                ><AddIcon />
                </Button>
              </div>
            </Grid>
          </Grid>
          {this.kryptorAssay.showMultiControl ? this.getMultiControlRow() : ''}
          {this.kryptorAssay.showRunRecoveryTest
            ? (
              <Grid container direction="row" className={this.props.classes.gridRow}>
                <Grid item xs className={`${this.props.classes.gridItem} full`}>
                  <div className={this.props.classes.divInlineFullWidth}>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          color="primary"
                          checked={this.kryptorAssay.runRecoveryTest}
                          onChange={(event, checked) => { this.kryptorAssay.runRecoveryTest = checked; this.update(false); }}
                        />
                      )}
                      label={this.props.kryptorStore.getText('KryptorRunRecoveryTests')}
                      labelPlacement="start"
                      classes={{ label: this.props.classes.rowTitle }}
                      className={this.props.classes.fullRowLabel}
                    />
                  </div>
                </Grid>
              </Grid>
            ) : ''}
        </Grid>
        <div className={this.props.classes.removeImageDiv}>
          <Button
            className={this.props.classes.removeImageButton}
            onClick={() => {
              this.props.kryptorStore.removeAssayFromList(this.props.id);
              this.props.update(false);
            }}
          ><img src={removeImagePath} className={this.props.classes.removeImage} alt={removeImagePath} />
          </Button>
        </div>
      </div>
    );
  }

  getMultiControlRow() {
    if (this.kryptorAssay.multiControlIds.length === 1) {
      return '';
    }
    const leftToggled = this.kryptorAssay.multiControlId === this.kryptorAssay.multiControlIds[0];
    return (
      <Grid container direction="row" alignItems="center" justify="center">
        <Grid item xs className={`${this.props.classes.gridItem} full`}>
          <div className={this.props.classes.divInline}>
            <Typography variant="body1" className={this.props.classes.rowTitle}>{this.props.kryptorStore.getText('KryptorMultiControl')}</Typography>
          </div>
          <div className={this.props.classes.mcButtonContainer}>
            <Button
              className={`${this.props.classes.toggleControl} ${this.props.classes.leftToggle} ${leftToggled ? 'active' : ''}`}
              onClick={() => this.handleToggleChange(this.kryptorAssay.multiControlIds[0])}
            >{this.props.kryptorStore.getMultiControl(this.kryptorAssay.multiControlIds[0]).short_name}
            </Button>
            <Button
              className={`${this.props.classes.toggleControl} ${this.props.classes.rightToggle} ${!leftToggled ? 'active' : ''}`}
              onClick={() => this.handleToggleChange(this.kryptorAssay.multiControlIds[1])}
            >{this.props.kryptorStore.getMultiControl(this.kryptorAssay.multiControlIds[1]).short_name}
            </Button>
          </div>
        </Grid>
      </Grid>
    );
  }

  isRelatedInstrumentsMinusButtonActive() {
    return this.kryptorAssay.numberOfInstruments > 0;
  }

  isRelatedInstrumentsPlusButtonActive() {
    return this.kryptorAssay.numberOfInstruments < this.props.kryptorStore.customerData.instrumentNumber;
  }

  isRelatedInstrumentsButtonGroupActive() {
    return this.kryptorAssay.numberOfInstruments > 1 || (this.kryptorAssay.numberOfInstruments <= this.props.kryptorStore.customerData.instrumentNumber);
  }

  initializeControlValue(baseKey, propValue, removeStyle) {
    // eslint-disable-next-line no-restricted-globals
    if (propValue !== undefined && !isNaN(propValue) && propValue !== 0 && removeStyle) {
      document.getElementById(baseKey + this.props.id).style = '';
    }
  }

  handleToggleChange(value) {
    this.kryptorAssay.multiControlId = value;
    this.update(false);
  }

  update(createNewItem) {
    if (!createNewItem) {
      this.props.kryptorStore.updateListAssay(this.kryptorAssay, this.props.id);
    }
    this.props.update(createNewItem);
  }

  updateWorkingDaysPerWeek(adjustment) {
    const value = this.kryptorAssay.workDaysPerWeek + adjustment;
    if (value > workingDayRange[1] || value < workingDayRange[0]) {
      return;
    }
    this.kryptorAssay.workDaysPerWeek = value;
    this.update(false);
  }

  updateNumberRelatedInstruments(adjustment) {
    const value = this.kryptorAssay.numberOfInstruments + adjustment;
    if (value < 0 || value > this.props.kryptorStore.customerData.instrumentNumber) {
      this.props.applicationStore.setKryptorValidationMsg(this.props.kryptorStore.getText('KryptorAliquotInstrumentsLimit'));
      this.props.applicationStore.toggleKryptorValidationDialog();
      return;
    }
    this.kryptorAssay.numberOfInstruments = value;
    this.update(false);
  }

  alterButtonStyle(baseKey, isActive, isLeftButton, value) {
    const button = document.getElementById(baseKey + this.props.id);
    button.style.color = isActive ? colors.azul : colors.paleGrey;
    button.style.borderColor = isActive ? colors.azul : colors.paleGrey;
    if (isLeftButton) {
      button.style.borderRightWidth = isActive ? '1px' : 0;
    } else {
      button.style.borderLeftWidth = value <= workingDayRange[0] ? '1px' : 0;
    }
  }

  render() {
    return (
      <div>
        {this.state.isAdded ? this.getActiveItem()
          : (
            <div className={this.props.classes.divAddAssay}>
              <AddIcon className={this.props.classes.addIcon} viewBox="0 0 24 24" />
              <Typography
                className={this.props.classes.addAssay}
                onClick={() => this.props.requestOverlay(this.props.id)}
                variant="body1"
              >{this.props.kryptorStore.getText('KryptorAddAssay')}
              </Typography>
            </div>
          )}
      </div>
    );
  }
}

KryptorAssay.wrappedComponent.propTypes = {
  applicationStore: PropTypes.object.isRequired,
  kryptorStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  update: PropTypes.func.isRequired,
  requestOverlay: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  inputSelectionHelper: PropTypes.object.isRequired,
  inputSelectionStartIndex: PropTypes.number.isRequired
};

export default withStyles(styles)(KryptorAssay);
