import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
  outer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vW',
    height: '100vH',
    justifyContent: 'center',
    alignItems: 'center'
  },
};

const Blank = props => (
  <div className={props.classes.outer}>
    <CircularProgress className={props.classes.progress} size={50} />
  </div>
);

Blank.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Blank);
