import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = {
  wrapper: {
    backgroundColor: 'transparent',
    flex: 1,
    padding: 0,
    margin: 0
  }
};

class ConditionalRerenderWrapper extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.rerender;
  }

  render() {
    return (
      <div style={{ ...styles.wrapper, ...this.props.style }}>
        {this.props.children}
      </div>
    );
  }
}

ConditionalRerenderWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  rerender: PropTypes.bool.isRequired,
  style: PropTypes.object
};

ConditionalRerenderWrapper.defaultProps = {
  style: undefined
};

export default withStyles(styles)(ConditionalRerenderWrapper);
