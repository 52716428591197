import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5em',
  },
  labelWrapper: {
    width: '25%',
    display: 'flex',
    position: 'relative',
    height: 32
  },
  label: {
    position: 'static',
    transform: 'none',
    alignSelf: 'center',
    whiteSpace: 'nowrap',
    width: '100%'
  },
  errorMessage: {
    whiteSpace: 'nowrap',
    position: 'absolute',
    bottom: -6
  },
  inputField: {
    flex: 1,
  },
  rootVertical: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'flex-start',
    '& textarea': {
      padding: 0,
      overflowY: 'auto'
    },
    MuiInput: {
      root: { width: '100%' }
    }
  },
  inputVertical: {
    width: '100%',
    '& div': {
      overflowY: 'auto',
      height: '100%'
    },
    '& textarea': {
      minHeight: '100%'
    }
  }
};

const InputDateTime = observer((props) => {
  const { fields, name, onChange } = props;
  const rootClassVertical = fields[name].vertical ? props.classes.rootVertical : null;
  const inputClassVertical = fields[name].vertical ? props.classes.inputVertical : null;

  return (
    <FormControl
      error={!!fields[name].error}
      disabled={fields[name].disabled}
      required={fields[name].required}
      className={`${props.classes.root} ${rootClassVertical}`}
    >
      {!!fields[name].label
      && (
        <div className={props.classes.labelWrapper}>
          <InputLabel
            htmlFor={name}
            disableAnimation
            shrink={false}
            className={props.classes.label}
          >
            {fields[name].label}
          </InputLabel>
          <FormHelperText
            id={`${name}-error`}
            className={props.classes.errorMessage}
          >
            {fields[name].error}
          </FormHelperText>
        </div>
      )
      }
      <Input
        id={name}
        value={fields[name].value}
        type="datetime-local"
        onChange={onChange}
        placeholder={fields[name].placeholder}
        className={`${props.classes.inputField} ${inputClassVertical}`}
        disableUnderline
      />
    </FormControl>
  );
});

InputDateTime.propTypes = {
  classes: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default withStyles(styles)(InputDateTime);
