const pctConstants = {
  CountryCodeUK: 'uk',
  CountryCodeDE: 'de',
  CountryCodeNL: 'nl',
  CountryCodeUS: 'us',
  CountryCodeNone: 'none',
  CountryNameUS: 'USA',
  // default value constants
  PopulationField: 'firstMenuField',
  CostsField: 'costField',
  ClinicalEffectField: 'clinicalField',
  ClinicalEffectLrti: 'clinicalLrtiField',
  ClinicalEffectSepsis: 'clinicalSepsisField',
  LanguageKey: 'Language',
  DataSetKey: 'DataSet',
  DefaultLanguageCode: 'en',
  LanguageCodeEnglish: 'en',
  LanguageCodeGerman: 'de',
  LanguageCodeFrench: 'fr',
  LanguageCodeSpanish: 'es',
  LanguageCodeUSA: 'us',
  DataTypePct: 'pct',
  DataTypeNoPct: 'no_pct',
  // navigation constants
  PctNextStep: 'PctNextStep',
  PctPreviousStep: 'PctPreviousStep',
  // text field constants
  PctTableRowCurrency: 'currency',
  PctTableRowPercentage: 'percentage',
  PctTableRowDays: 'days',
  PctTableRowTypePct: 'singlePct',
  PctTableRowTypeBoth: 'both',
  PctTableRowNone: 'none',
  // text constants
  PctReferences: 'References',
  PctWithPct: 'PctWithPct',
  PctWithoutPct: 'PctWithoutPct',
  PctDifference: 'PctDifference',
  PctHealthcareCostsHeadText0: 'PctHealthcareCostsHeadText0',
  PctHealthcareCostsTableHeadText: 'PctHealthcareCostsTableHeadText',
  PctHealthcareCostsTableHeadText0FieldText2: 'PctHealthcareCostsTableHeadText0FieldText2',
  PctHealthcareCostsTableHeadText2FieldText1: 'PctHealthcareCostsTableHeadText2FieldText1',
  PctFieldText: 'FieldText',
  PctClinicalEffectHeadText: 'PctClinicalEffectHeadText',
  PctClinicalEffectTableHeadText: 'PctClinicalEffectTableHeadText',
  PctClinicalEffectTableHeadText1FieldText0: 'PctClinicalEffectTableHeadText1FieldText0',
  PctClinicalEffectTableHeadText3FieldText0: 'PctClinicalEffectTableHeadText3FieldText0',
  PctClinicalEffectTableHeadText3FieldText1: 'PctClinicalEffectTableHeadText3FieldText1',
  PctClinicalEffectTableHeadText3FieldText2: 'PctClinicalEffectTableHeadText3FieldText2',
  PctClinicalEffectTableHeadText4FieldText1: 'PctClinicalEffectTableHeadText4FieldText1',
  PctClinicalEffectSepsisHeadText: 'PctClinicalEffectSepsisHeadText',
  PctClinicalEffectSepsisTableHeadText: 'PctClinicalEffectSepsisTableHeadText',
  PctResultsHeadText: 'PctResultsHeadText',
  PctResultsBottomText: 'PctResultsBottomText',
  PctResultsTableHeadText0: 'PctResultsTableHeadText0',
  PctResultsTableHeadText0TextField0: 'PctResultsTableHeadText0TextField0',
  PctResultsTableHeadText0TextField1: 'PctResultsTableHeadText0TextField1',
  PctResultsTableHeadText0TextField2: 'PctResultsTableHeadText0TextField2',
  PctResultsTableHeadText0TextField3: 'PctResultsTableHeadText0TextField3',
  PctResultsTableHeadText0TextField4: 'PctResultsTableHeadText0TextField4',
  PctResultsTableHeadText0TextField5: 'PctResultsTableHeadText0TextField5',
  PctResultsTableHeadText0TextField6: 'PctResultsTableHeadText0TextField6',
  PctResultsTableHeadText1: 'PctResultsTableHeadText1',
  PctResultsTableHeadText1TextField0: 'PctResultsTableHeadText1TextField0',
  PctResultsTableHeadText1TextField1: 'PctResultsTableHeadText1TextField1',
  PctResultsTableHeadText1TextField2: 'PctResultsTableHeadText1TextField2',
  PctResultsTableHeadText1TextField3: 'PctResultsTableHeadText1TextField3',
  PctResultsTableHeadText1TextField4: 'PctResultsTableHeadText1TextField4',
  PctResultsTableHeadText1TextField5: 'PctResultsTableHeadText1TextField5',
  PctResultsTableHeadText2: 'PctResultsTableHeadText2',
  PctResultsTableHeadText2TextField0: 'PctResultsTableHeadText2TextField0',
  PctResultsTableHeadText3: 'PctResultsTableHeadText3',
  PctResultsTableHeadText3TextField0: 'PctResultsTableHeadText3TextField0',
  PctResultsTableHeadText3TextField1: 'PctResultsTableHeadText3TextField1',
  PctResultsTableHeadText3TextField2: 'PctResultsTableHeadText3TextField2',
  PctResultsTableHeadText3TextField3: 'PctResultsTableHeadText3TextField3',
  PctResultsTableHeadText4: 'PctResultsTableHeadText4',
  PctResultsTableHeadText4TextField0: 'PctResultsTableHeadText4TextField0',
  PctResultsTableHeadText4TextField1: 'PctResultsTableHeadText4TextField1',
  PctResultsTableHeadTextTextFieldLRTI: 'PctResultsTableHeadTextTextFieldLRTI',
  PctResultsTableHeadTextTextFieldSepsis: 'PctResultsTableHeadTextTextFieldSepsis',
  PctResultsTableHeadTextTextFieldTotal: 'PctResultsTableHeadTextTextFieldTotal',
  PctResultsTableHeadTextTextFieldOverview: 'PctResultsTableHeadTextTextFieldOverview',
  PctResultsTableHeadTextTextFieldDetails: 'PctResultsTableHeadTextTextFieldDetails',
  PctResultsTableHeadTextTextFieldTotalPop: 'PctResultsTableHeadTextTextFieldTotalPop',
  PctResultsTableHeadTextTextFieldTotalPatient: 'PctResultsTableHeadTextTextFieldTotalPatient',
  PctSepsis: 'sepsis',
  PctLrti: 'LRTI'
};

export default pctConstants;
