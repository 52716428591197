import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import { simappColors } from 'shared/lib/theme';
import { pctStore } from 'shared/stores';
import pctConstants from 'shared/lib/resources/pct-constants';

const styles = {
  defaultTextField: {
    width: '100%',
    backgroundColor: simappColors.inputYellowColor,
    padding: '6px 2px 3px 3px',
    textAlign: 'center',
    fontSize: '1rem',
    border: 'none',
    fontWeight: '500',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    whiteSpace: 'nowrap',
    '&:focus': {
      outline: 'none'
    },
    '&.right': {
      textAlign: 'right',
      paddingRight: '10px'
    }
  },
  changedTextField: {
    backgroundColor: simappColors.inputLightRedColor
  },
  readonlyTextField: {
    float: 'right',
    backgroundColor: simappColors.palerGrey
  },
  textRight: {
    textAlign: 'right',
    paddingRight: '10px'
  }
};

const thousandSeparatorEU = '.';
const thousandSeparatorUS = ',';
const decimalSeparatorEU = ',';
const decimalSeparatorUS = '.';

class PctInputField extends Component {
  prefix;
  suffix;
  decimalSeparator = decimalSeparatorEU;
  thousandSeparator = thousandSeparatorEU;

  decimalScale = 2;
  fixedDecimalScale = false;

  constructor(props) {
    super(props);
    this.state = { value: props.value };
  }

  setSeparators() {
    if (this.props.decimalSeparator === undefined) {
      this.decimalSeparator = this.props.isUSNumberInput ? decimalSeparatorUS : decimalSeparatorEU;
    } else {
      this.decimalSeparator = this.props.decimalSeparator;
    }
    if (this.props.thousandSeparator === undefined) {
      this.thousandSeparator = this.props.isUSNumberInput ? thousandSeparatorUS : thousandSeparatorEU;
    } else {
      this.thousandSeparator = this.props.thousandSeparator;
    }
  }

  onValueChange = (values) => {
    const { floatValue } = values;
    if (floatValue !== this.state.value || floatValue === undefined) {
      this.refreshFromState = true;
      const hasShownDialog = pctStore.hasShownDialog(this.props.fieldName, this.props.fieldIndex, this.props.dataType, this.props.isUSNumberInput);
      const showDialog = floatValue !== undefined && floatValue !== null && !hasShownDialog;
      pctStore.setHasShownDialog(this.props.fieldName, this.props.fieldIndex, this.props.dataType, showDialog || hasShownDialog);
      this.props.onValueChange(this.props.fieldName, this.props.fieldIndex, this.enforceMaxValue(floatValue), this.props.type, showDialog);
    }
  }

  setSuffixAndPrefix() {
    if (this.props.type === pctConstants.PctTableRowCurrency) {
      if (this.props.isUSNumberInput) {
        this.prefix = this.getCurrencyValue();
        this.suffix = '';
      } else {
        this.suffix = this.getCurrencyValue();
        this.prefix = '';
      }
      this.decimalScale = 2;
      this.fixedDecimalScale = true;
    } else if (this.props.type === pctConstants.PctTableRowPercentage) {
      this.prefix = '';
      this.suffix = ' %';
    } else if (this.props.type === pctConstants.PctTableRowDays) {
      this.prefix = '';
      this.suffix = '';
    }
  }

  getClassName(isChanged) {
    if (this.props.displayType === 'text') {
      return (`${this.props.classes.defaultTextField} ${this.props.classes.readonlyTextField}${this.props.textAlign === 'right' ? ' right' : ''}`);
    }
    if (!this.props.isUSNumberInput || !isChanged) {
      return (`${this.props.classes.defaultTextField}${this.props.textAlign === 'right' ? ' right' : ''}`);
    }
    return (`${this.props.classes.defaultTextField} ${this.props.classes.changedTextField}${this.props.textAlign === 'right' ? ' right' : ''}`);
  }

  getCurrencyValue() {
    if (this.props.isUSNumberInput) {
      return '$';
    }
    return ' €';
  }

  enforceMaxValue(input) {
    let value = input;
    if (this.props.maxValue > 0 && value > this.props.maxValue) {
      value = this.props.maxValue;
    }
    this.setState({ value });
    return value;
  }

  render() {
    this.setSeparators();
    this.setSuffixAndPrefix();
    const className = this.getClassName(this.props.isChanged);
    const value = this.props.value !== null && this.props.displayType !== 'input'
      ? this.props.value.highNumberFix(this.decimalScale)
      : this.props.value;
    const inputField = (
      <NumberFormat
        id={this.props.fieldName}
        className={className}
        value={value}
        isNumericString
        prefix={this.prefix}
        suffix={this.suffix}
        displayType={this.props.displayType}
        decimalScale={this.decimalScale}
        decimalSeparator={this.decimalSeparator}
        thousandSeparator={this.thousandSeparator}
        onValueChange={this.onValueChange}
        onDoubleClick={event => this.props.displayType === 'input' && event.target.select()}
        fixedDecimalScale={this.fixedDecimalScale}
        allowNegative={this.props.allowNegative}
        maxLength={22}
      />
    );
    return (this.props.displayType === 'text'
      ? <div>{inputField}</div> : inputField);
  }
}

PctInputField.propTypes = {
  classes: PropTypes.object.isRequired,
  fieldName: PropTypes.string,
  fieldIndex: PropTypes.number,
  isUSNumberInput: PropTypes.bool,
  dataType: PropTypes.string,
  displayType: PropTypes.string,
  isChanged: PropTypes.bool,
  onValueChange: PropTypes.func,
  value: PropTypes.any,
  type: PropTypes.string,
  maxValue: PropTypes.number,
  allowNegative: PropTypes.bool,
  decimalSeparator: PropTypes.string,
  thousandSeparator: PropTypes.string,
  textAlign: PropTypes.string
};

PctInputField.defaultProps = {
  fieldName: '',
  fieldIndex: -1,
  isUSNumberInput: false,
  onValueChange: () => { },
  value: null,
  isChanged: false,
  dataType: pctConstants.DataTypePct,
  allowNegative: false,
  type: 'currency',
  maxValue: -1,
  displayType: 'input',
  decimalSeparator: undefined,
  thousandSeparator: undefined,
  textAlign: 'center'
};

export default withStyles(styles)(PctInputField);
