import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { simappColors } from 'shared/lib/theme';
import texts from 'shared/lib/resources/texts';
import InputField from 'web/components/_ui/form-elements/input-field';
import SaveButton from 'web/components/_ui/form-elements/save-button';
import CancelButton from 'web/components/_ui/form-elements/cancel-button';
import ModalHeader from 'web/components/_ui/modal-header';
import AlertDialog from 'web/components/_misc/alert-dialog';

const styles = {
  root: {
  },
  paper: {
    width: '50vw',
    height: '50vh',
    maxWidth: '50vw',
    maxHeight: '50vh',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    padding: 0,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  grid: {
    flex: 1,
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '12px 24px !important'
  },
  saveBtnWrapper: {
    position: 'relative'
  },
  saveBtn: {
    marginLeft: 8,
    marginRight: 8
  },
  errorText: {
    textAlign: 'center',
    marginBottom: 0,
    fontSize: '1.1rem'
  },
  loginButton: {
    marginTop: '2rem'
  },
  buttonProgress: {
    color: simappColors.azul,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
};

@inject('SendProposalForm', 'applicationStore') @observer
class QcProposalSend extends Component {
  handleSend = () => {
    this.props.SendProposalForm.setSendMailFunction(this.props.applicationStore.sendPdfMailBlob);
    this.props.SendProposalForm.save();
  }

  handleCancel = () => {
    this.props.applicationStore.toggleSendProposalDialog();
  }

  errorMessage = () => {
    switch (this.props.SendProposalForm.responseError) {
      case this.props.SendProposalForm.errors.NO_MAIL_PERMISSION:
        return (
          <Typography className={this.props.classes.errorText}>It seems that you have not given Office 365 the required permission to send mails.<br />Please re-login and grant requested permissions:
            <Button size="large" variant="contained" color="primary" className={this.props.classes.loginButton} onClick={() => { this.props.applicationStore.login(true); }}>Log in</Button>
          </Typography>
        );
      default:
        return texts.couldNotSendMail;
    }
  }

  render() {
    const { applicationStore, SendProposalForm, titleText, cancelText, sendText } = this.props;
    const { fields, onFieldChange } = SendProposalForm;
    SendProposalForm.setDefaultValues();

    return (
      <Fragment>
        { applicationStore.sendProposalDialogOpen
          && (
            <Dialog
              open
              onClose={this.handleCancel}
              classes={{
                paper: this.props.classes.paper,
              }}
            >
              <form className={this.props.classes.form}>
                <ModalHeader title={titleText} />
                <DialogContent classes={{ root: this.props.classes.dialogContent }}>
                  <div className={this.props.classes.grid}>
                    <div className={this.props.classes.col}>
                      <InputField fields={fields} name="mailto" onChange={onFieldChange} />
                      <Divider light />
                      <InputField fields={fields} name="subject" onChange={onFieldChange} />
                      <Divider light />
                      <InputField fields={fields} name="message" onChange={onFieldChange} className={this.props.classes.buttonArea} />
                    </div>
                  </div>
                </DialogContent>
                <DialogActions>
                  <CancelButton form={SendProposalForm} onClick={this.handleCancel} label={cancelText} />
                  <SaveButton form={SendProposalForm} onClick={this.handleSend} label={sendText} />
                </DialogActions>
              </form>
              <AlertDialog trigger={!!String(SendProposalForm.responseError).length} message={this.errorMessage()} close={SendProposalForm.resetResponseError} />
            </Dialog>
          )
        }
      </Fragment>
    );
  }
}

QcProposalSend.wrappedComponent.propTypes = {
  applicationStore: PropTypes.object.isRequired,
  SendProposalForm: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  titleText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  sendText: PropTypes.string.isRequired,
};

export default withStyles(styles)(QcProposalSend);
