import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { simappColors } from 'shared/lib/theme';
import SimAppButton from 'web/components/_ui/simapp-button';

const styles = {
  paper: {
    position: 'relative',
    width: 500,
    margin: 20,
    marginBotton: 20,
    overflow: 'hidden',
    border: `1px solid ${simappColors.paleGrey}`,
    padding: '15px'
  },
  headRow: {
    display: 'flex',
    flexShrink: 0,
    marginTop: '10px',
    marginBottom: '12px',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '28px',
    lineHeight: '40px'
  },
  image: {
    display: 'block',
    maxHeight: '120px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  button: {
    marginLeft: '5px',
    marginRight: '5px',
    marginBottom: '5px'
  }
};

class HEModelItem extends Component {
  navigate = () => {
    this.props.routing.push(this.props.path);
  }

  render() {
    const { classes, store } = this.props;
    const imagePath = `/images/${store.onboardingImageName}`;
    return (
      <Paper className={classes.paper} key={store.onboardingImageName}>
        <div className={classes.headRow}>
          <img src={imagePath} className={classes.image} alt={store.getText('CalculationsTitle')} />
        </div>
        <SimAppButton className={classes.button} onClick={() => { this.navigate(); }} isNavigationButton inline>{store.getText('CalculationsStartButton')}</SimAppButton>
      </Paper>
    );
  }
}

HEModelItem.propTypes = {
  classes: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired

};

export default withStyles(styles)(HEModelItem);
