import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  day: {
    paddingTop: 9,
    height: 36,
    width: 36,
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
  },
};

const DateHeader = (props) => {
  {
    const { classes, label, onDrillDown } = props;
    return (
      <div
        role="button"
        tabIndex="0"
        className={classes.day}
        onClick={e => onDrillDown && onDrillDown(e)}
        onKeyDown={() => {
        }}
      >
        {label}
      </div>
    );
  }
};

export default withStyles(styles)(DateHeader);
