import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { simappConstants, simappColors } from '../../../shared/lib/theme';
import ClickableTextButton from '../_misc/clickableTextButton';
import copeptinConstants from '../../../shared/lib/resources/copeptin-constants';
import CopeptinDialog from './copeptin-dialog';

const styles = {
  root: {
    width: '100%',
    height: '100vH',
    display: 'flex',
    flexDirection: 'column',
    padding: simappConstants.contentMarginLarge,
    overflowX: 'hidden',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontWeight: 'normal'
  },
  background: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  mainContent: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch'
  },
  wrapper: {
    flexDirection: 'row',
    display: 'flex'
  },
  textWrapper: {
    flex: 1,
    flexDirection: 'column',
    display: 'flex'
  },
  navigationbutton: {
    borderRadius: '2px',
    height: '56px',
    width: '100%',
    fontSize: '17px',
    fontWeight: 'normal',
    transition: 'none',
    backgroundColor: `${simappColors.coolGrey}`,
    color: `${simappColors.white}`,
    '&:hover': {
      backgroundColor: `${simappColors.coolGrey}`
    }
  },
  textBlock: {
    marginBottom: '20px',
    lineHeight: '20px',
    '&.marginBottom': {
      marginBottom: '35px'
    },
    '&.marginTop': {
      marginTop: '35px'
    },
    color: 'black'
  },
  textContainer: {
    marginTop: '60px',
    marginBottom: '70px',
    marginLeft: simappConstants.pctHorizontalMargin,
    marginRight: simappConstants.pctHorizontalMargin,
    paddingRight: '10px',
  },
  textContainerIndented: {
    marginLeft: '30px'
  },
  bottomBar: {
    width: '100%',
    marginTop: '30px',
    marginRight: simappConstants.pctHorizontalMargin,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  referencesLink: {
    marginLeft: '15px'
  },
  divInfoLink: {
    marginRight: '15px',
    float: 'right'
  },
  introductionDiv: {
    marginBottom: '10px'
  },
  button: {
    width: '130px',
    borderRadius: 58,
    background: simappColors.DefaultButtonColor,
    borderWidth: 1.4,
    borderStyle: 'solid',
    borderColor: simappColors.DefaultButtonBorderColor,
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontWeight: 500,
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionsContainer: {
    marginLeft: simappConstants.pctHorizontalMargin,
    maxWidth: '700px'
  },
  buttonSelected: {
    backgroundColor: simappColors.SelectedButtonColor,
    borderColor: simappColors.SelectedButtonColor
  },
  table: {
    width: '100%',
    marginBottom: '40px'
  },
  disabledButton: {
    backgroundColor: 'transparent',
    border: `1px solid ${simappColors.disableButtonGrey}`,
    color: `${simappColors.disableButtonGrey}`,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  languageLabel: {
    width: '130px'
  },
  image: {
    display: 'block',
    maxHeight: '120px',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
};

@inject('copeptinStore', 'routing') @observer
class CopeptinStart extends Component {
  constructor(props) {
    super(props);
    this.logoPath = `/images/${props.copeptinStore.logoName}`;
    this.state = {
      selectedLanguage: props.copeptinStore.language
    };
  }

  navigate = () => {
    const { copeptinStore } = this.props;
    this.setState({ selectedLanguage: '' });
    copeptinStore.readBIC8aResources();
    this.props.routing.push('/he-models/copeptin-calculator/population');
  }

  logoPath;

  getMainContent() {
    const { copeptinStore } = this.props;
    return (
      <div>
        <img src={this.logoPath} className={this.props.classes.image} alt="" />
        <div className={this.props.classes.textContainer}>
          <Typography variant="body1" className={this.props.classes.textBlock}>{copeptinStore.getLocalizedText('copeptinStartPageIntro1')}</Typography>
          <div className={this.props.classes.textContainerIndented}>
            <Typography variant="body1" className={this.props.classes.textBlock}>{copeptinStore.getLocalizedText('copeptinStartPageBulletPoint1')}</Typography>
            <Typography variant="body1" className={this.props.classes.textBlock}>{copeptinStore.getLocalizedText('copeptinStartPageBulletPoint2')}</Typography>
            <Typography variant="body1" className={this.props.classes.textBlock}>{copeptinStore.getLocalizedText('copeptinStartPageBulletPoint3')}</Typography>
          </div>
          <Typography variant="body1" className={this.props.classes.textBlock}>{copeptinStore.getLocalizedText('copeptinStartPageIntroEnd')}</Typography>
          <Typography variant="body1" className={this.props.classes.textBlock}>{copeptinStore.getLocalizedText('copeptinStartPageNote')}</Typography>
        </div>
      </div>
    );
  }

  saveValue(value) {
    this.setState({ selectedLanguage: value });
    this.props.copeptinStore.setLanguage(value);
  }

  getSelectedButtonClass(languageCode) {
    return this.state.selectedLanguage === languageCode ? this.props.classes.buttonSelected : undefined;
  }

  getOptions(copeptinStore) {
    return (
      <div className={this.props.classes.optionsContainer}>
        <Grid container direction="row" className={this.props.classes.table} alignItems="center" justify="space-between">
          <Grid item xs sm={1}>
            <Button
              id={`${copeptinConstants.LanguageKey}_en`}
              onClick={() => this.saveValue(copeptinConstants.LanguageCodeEnglish)}
              className={`${this.props.classes.button} ${this.getSelectedButtonClass(copeptinConstants.LanguageCodeEnglish)}`}
            >{copeptinStore.getText('LanguageEn')}
            </Button>
          </Grid>
          <Grid item xs sm={1}>
            <Button
              id={`${copeptinConstants.LanguageKey}_de`}
              onClick={() => this.saveValue(copeptinConstants.LanguageCodeGerman)}
              className={`${this.props.classes.button} ${this.getSelectedButtonClass(copeptinConstants.LanguageCodeGerman)}`}
            >{copeptinStore.getText('LanguageDe')}
            </Button>
          </Grid>
          <Grid item xs sm={1}>
            <Button
              id={`${copeptinConstants.LanguageKey}_fr`}
              onClick={() => this.saveValue(copeptinConstants.LanguageCodeFrench)}
              className={`${this.props.classes.button} ${this.getSelectedButtonClass(copeptinConstants.LanguageCodeFrench)}`}
            >{copeptinStore.getText('LanguageFr')}
            </Button>
          </Grid>
          <Grid item xs sm={1}>
            <Button
              id={`${copeptinConstants.LanguageKey}_es`}
              onClick={() => this.saveValue(copeptinConstants.LanguageCodeSpanish)}
              className={`${this.props.classes.button} ${this.getSelectedButtonClass(copeptinConstants.LanguageCodeSpanish)}`}
            >{copeptinStore.getText('LanguageEs')}
            </Button>
          </Grid>
          <Grid item xs sm={1} />
        </Grid>
      </div>
    );
  }

  navigateToInfoPage(push, target, title, imageName) {
    const returnLocation = '/he-models/copeptin-calculator';
    this.props.copeptinStore.setCalculatorInformation(imageName, returnLocation);
    push(target);
  }

  getBottomBar(push) {
    const { copeptinStore } = this.props;
    return (
      <div className={this.props.classes.bottomBar}>
        <ClickableTextButton
          isBold
          className={this.props.classes.referencesLink}
          onClick={() => push('/he-models/copeptin-calculator/copeptin-references')}
          text={copeptinStore.getLocalizedText('startPageReferencesButtonLabel')}
        />
        <div className={this.props.classes.divInfoLink}>
          <ClickableTextButton
            isBold
            onClick={
              () => {
                const algorithmFileName = this.props.copeptinStore.getLocalizedAlgorithmFileName();
                this.navigateToInfoPage(push, '/he-models/copeptin-calculator/copeptin-algorithm', copeptinStore.getLocalizedText('copeptinAmiAlgorithmHeadline'), algorithmFileName);
              }
            }
            text={copeptinStore.getText('startPageAlgorithmButtonLabel')}
          />
        </div>
      </div>
    );
  }

  render() {
    const { copeptinStore } = this.props;
    const { push } = this.props.routing;
    return (
      <div className={this.props.classes.root}>
        <div className={this.props.classes.mainContent}>
          {this.getMainContent()}
          {this.getOptions(copeptinStore)}
          {this.getBottomBar(push)}
        </div>
        <Button disabled={this.state.selectedLanguage === ''} onClick={() => this.navigate()} className={`${this.props.classes.navigationbutton} ${this.state.selectedLanguage !== '' ? this.props.classes.navigationbutton : this.props.classes.disabledButton}`}>{copeptinStore.getLocalizedText('copeptinStartScreenButton')}</Button>
        <CopeptinDialog />
      </div>
    );
  }
}

CopeptinStart.wrappedComponent.propTypes = {
  routing: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  copeptinStore: PropTypes.object.isRequired
};

export default withStyles(styles)(CopeptinStart);
