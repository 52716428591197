import { MultiControl } from '../stores/kryptor-store';
import { getTimeframe, getMaxWorkDaysOfAssayMultiControl, getMaxNumberInstrumentReleatedAssayMultiControl } from './kryptor-helper';

const daysInYear = 365;
const daysInWeek = 7;
const weeksInYear = (daysInYear / daysInWeek);
const KryptorGoldSolutionDividerBaseVol = 100000;
const KryptorCompactSolutionDividerBaseVol = 55000;
const BufferBagVolumeInMl = 5000;
const SolutionDividerBaseVolReduction = 4000;
const Solution4TestsPerAssayMultiplicator = 200;
const KryptorCompactReactionPlatesTestPerAssayDivider = 96;
const KryptorGoldReactionPlatesTestPerAssayDivider = 120;
const KryptorCompactReactionPlateBoxSize = 60;
const KryptorGoldReactionPlateBoxSize = 30;
const MultiQcKitsDividerGM = 3;
const MultiQcKitsDenominator = 6;
const NonMultiControlValuePerQcLevel = 6;
const BagsPerBuffer = 5;
const KryptorCompactBottlesPerKit = 4;
const KryptorGoldBottlesPerKit = 3;
const KryptorCompactSolution3Multiplicator = 3;
const KryptorGoldSolution3Multiplicator = 4;
const KryptorAliquotDividerValue = 2000;

const MultiControlIds = [1, 2, 3];
export const MultiControlGMId = 1;
export const MultiQcKitsDividerTM1AndAnti = 2;

export const ReactPlates = 'Reaction plates box';
export const Solution1 = 'Solution 1';
export const Solution2 = 'Solution 2';
export const Solution3 = 'Solution 3';
export const Solution4 = 'Solution 4';
export const BufferBag = 'Buffer à 5';

function getMultiControl(kryptorStore, assayItem) {
  const assay = assayItem.assay;
  if (assay.multi_control_ids.length === 0) {
    return { short_name: assay.ref_qc };
  }
  if (assay.multi_control_ids.length === 1) {
    return kryptorStore.getMultiControl(assay.multi_control_ids[0]);
  }
  return kryptorStore.getMultiControl(assayItem.multiControlId);
}

export function getMultiControlQTY(kryptorStore, multiControl) {
  let divider = MultiQcKitsDividerTM1AndAnti;
  if (multiControl.multiControl.id === MultiControlGMId) {
    divider = MultiQcKitsDividerGM;
  }
  if (multiControl.aliquotCoverPerInstrument === 0 || multiControl.aliquotCoverPerInstrument === undefined) {
    return 0;
  }
  const value = Math.ceil(((weeksInYear * multiControl.maxWorkDaysPerWeek) / ((MultiQcKitsDenominator / divider) * (KryptorAliquotDividerValue / multiControl.volumePerAliquot)) * getTimeframe(kryptorStore.customerData.coverTimePeriod) * (multiControl.maxNumberInstrumentRelatedAssay / multiControl.aliquotCoverPerInstrument)));
  if (isNaN(value)) { // eslint-disable-line no-restricted-globals
    return 0;
  }
  return value;
}

export class ConsumptionCalculation {
  kryptorStore;

  constructor(kryptorStore) {
    this.kryptorStore = kryptorStore;
  }

  // eslint-disable-next-line class-methods-use-this
  getNearestValidValue(value, minValue, maxValue) {
    if (value < minValue) {
      return minValue;
    }
    if (value > maxValue) {
      return maxValue;
    }
    return value;
  }

  // eslint-disable-next-line class-methods-use-this
  isVolumePerAliquotValid(value, lowerLimit) {
    if (value !== undefined && value !== '' && value >= lowerLimit) {
      return true;
    }
    return false;
  }

  validateAndUpdateVolumeAliquot(value, lowerLimit, applicationStore, kryptorStore) {
    if (this.isVolumePerAliquotValid(value, lowerLimit) === false) {
      applicationStore.setKryptorValidationMsg(kryptorStore.getText('KryptorVolumePerAliquotLowerLimit'));
      applicationStore.toggleKryptorValidationDialog();
      return lowerLimit;
    }
    return value;
  }

  validateAndUpdateAliquotCoverPerInstrument(value, applicationStore, kryptorStore) {
    if (value === undefined || value === null) {
      return undefined;
    }
    const origValue = (typeof value === 'string') ? parseInt(value, 10) : value;
    const validValue = this.getNearestValidValue(origValue, 0, kryptorStore.customerData.instrumentNumber);
    if (validValue !== origValue) {
      applicationStore.setKryptorValidationMsg(kryptorStore.getText('KryptorAliquotInstrumentsLimit'));
      applicationStore.toggleKryptorValidationDialog();
    }
    return validValue;
  }

  getAssayReagentKits(assayItem) {
    const assay = assayItem.assay;
    const reagentKits = assayItem.amountTests / assay.tests_per_kit;
    const compareValue = (weeksInYear * assayItem.workDaysPerWeek * getTimeframe(this.kryptorStore.customerData.coverTimePeriod)) / assay.on_board_stability;
    return Math.ceil(Math.max(compareValue, reagentKits));
  }

  getAssayCalKits(assayItem) {
    const assay = assayItem.assay;
    if (assay.calibration_stability < assayItem.workDaysPerWeek) {
      return Math.ceil(((weeksInYear * assayItem.workDaysPerWeek) / (assay.calibration_stability / assay.calibrations_per_cal_kit)) * getTimeframe(this.kryptorStore.customerData.coverTimePeriod) * assayItem.numberOfInstruments);
    }
    return Math.ceil(((daysInYear / assay.calibration_stability) / assay.calibrations_per_cal_kit) * getTimeframe(this.kryptorStore.customerData.coverTimePeriod) * assayItem.numberOfInstruments);
  }

  getAssayQCKits(assayItem, multiControl) {
    const assay = assayItem.assay;
    if (MultiControlIds.includes(multiControl.id)) {
      const control = new MultiControl();
      control.multiControl = multiControl;
      control.maxWorkDaysPerWeek = getMaxWorkDaysOfAssayMultiControl(this.kryptorStore, multiControl.id);
      control.maxNumberInstrumentRelatedAssay = getMaxNumberInstrumentReleatedAssayMultiControl(this.kryptorStore, multiControl.id);
      control.quantity = getMultiControlQTY(this.kryptorStore, control);
      if (!(this.kryptorStore.multiControlExists(multiControl.id))) {
        this.kryptorStore.addMultiControl(control);
      } else {
        this.kryptorStore.updateMultiControl(multiControl.id, multiControl, control.maxWorkDaysPerWeek, control.quantity);
      }
      return this.kryptorStore.getText('KryptorAssayRowQCKitQtyMultiControl');
    }
    return Math.ceil(((weeksInYear * assayItem.workDaysPerWeek) / ((NonMultiControlValuePerQcLevel / assay.qc_levels) * (KryptorAliquotDividerValue / assayItem.volumePerAliquot))) * getTimeframe(this.kryptorStore.customerData.coverTimePeriod) * (assayItem.numberOfInstruments / assayItem.aliquotCoverPerInstrument));
  }

  getSumTestsPerAssay() {
    let sum = 0;
    this.kryptorStore.kryptorAssayList.map((n) => {
      sum += n.amountTests;
    });
    return sum;
  }

  getSumAverageTestInstrument() {
    let sum = 0;
    this.kryptorStore.kryptorAssayList.map((n) => {
      sum += n.amountTests / n.numberOfInstruments;
    });
    return sum;
  }

  getSumSolutionPerAssay(solution) {
    let sum = 0;
    this.kryptorStore.kryptorAssayList.map((n) => {
      const assay = n.assay;
      sum += n.reagentKits * (assay[`${solution}_xl`] + assay[`${solution}_k`]);
    });
    return sum;
  }

  getSolutionCalculation = (value, isKryptorGold) => {
    const solutionDividerBaseVol = isKryptorGold ? KryptorGoldSolutionDividerBaseVol : KryptorCompactSolutionDividerBaseVol;
    const bottlesPerKit = isKryptorGold ? KryptorGoldBottlesPerKit : KryptorCompactBottlesPerKit;
    return ((value / (solutionDividerBaseVol - SolutionDividerBaseVolReduction)) / bottlesPerKit);
  }

  getBufferBox() {
    let sum = 0;
    let buffer = 0;
    this.kryptorStore.kryptorAssayList.map((n) => {
      const assay = n.assay;
      sum += (n.amountTests * assay.buffer_priming_per_test) / n.numberOfInstruments;
    });
    buffer = (sum * this.kryptorStore.customerData.instrumentNumber) / BufferBagVolumeInMl / BagsPerBuffer;
    if (buffer < (this.kryptorStore.customerData.coverTimePeriod / BagsPerBuffer)) {
      buffer = this.kryptorStore.customerData.coverTimePeriod / BagsPerBuffer;
    }
    return buffer;
  }

  getConsumablesQTY(consumable, setValue) {
    if (setValue) {
      setConsumablesQTY(this.kryptorStore, consumable); // eslint-disable-line
    }
    if (consumable.name.includes(ReactPlates)) {
      return this.kryptorStore.consumablesData.reactionPlates.count;
    } if (consumable.name.includes(Solution1)) {
      return this.kryptorStore.consumablesData.solution1.count;
    } if (consumable.name.includes(Solution2)) {
      return this.kryptorStore.consumablesData.solution2.count;
    } if (consumable.name.includes(Solution3)) {
      return this.kryptorStore.consumablesData.solution3.count;
    } if (consumable.name.includes(Solution4)) {
      return this.kryptorStore.consumablesData.solution4.count;
    } if (consumable.name.includes(BufferBag)) {
      return this.kryptorStore.consumablesData.bufferBags.count;
    }
    return 0;
  }

  getMultiControlName = (assayItem) => {
    const multiControl = getMultiControl(this.kryptorStore, assayItem);
    if (assayItem.assay.multi_control_ids.length === 0) {
      return multiControl.short_name;
    }
    return `${this.kryptorStore.getText('KryptorAssayRowQCRefMulti')} ${multiControl.short_name}`;
  }
}

export function setAssayKits(kryptorStore, assayItem, index) {
  const calculation = new ConsumptionCalculation(kryptorStore);
  kryptorStore.setAssayReagentKits(calculation.getAssayReagentKits(assayItem), index);
  kryptorStore.setAssayCalKits(calculation.getAssayCalKits(assayItem), index);
  kryptorStore.setAssayQCKits(calculation.getAssayQCKits(assayItem, getMultiControl(kryptorStore, assayItem)), index);
}

export function setConsumablesQTY(kryptorStore, consumable) {
  const calculation = new ConsumptionCalculation(kryptorStore);
  const isKryptorGold = kryptorStore.customerData.kryptorType === 1;
  if (consumable.name.includes(ReactPlates)) {
    const reactionPlateDevider = isKryptorGold ? KryptorGoldReactionPlatesTestPerAssayDivider : KryptorCompactReactionPlatesTestPerAssayDivider;
    const reactionPlateBoxSize = isKryptorGold ? KryptorGoldReactionPlateBoxSize : KryptorCompactReactionPlateBoxSize;
    kryptorStore.setReactionPlates(Math.ceil((calculation.getSumTestsPerAssay() / reactionPlateDevider) / reactionPlateBoxSize));
    return;
  } if (consumable.name.includes(Solution1)) {
    kryptorStore.setSolution1(Math.ceil(calculation.getSolutionCalculation(calculation.getSumSolutionPerAssay('volume_sol1_rec'), isKryptorGold)));
    return;
  } if (consumable.name.includes(Solution2)) {
    kryptorStore.setSolution2(Math.ceil(calculation.getSolutionCalculation(calculation.getSumSolutionPerAssay('volume_sol2_rec'), isKryptorGold)));
    return;
  } if (consumable.name.includes(Solution3)) {
    const solution3Multiplicator = isKryptorGold ? KryptorGoldSolution3Multiplicator : KryptorCompactSolution3Multiplicator;
    kryptorStore.setSolution3(Math.ceil(solution3Multiplicator * getTimeframe(kryptorStore.customerData.coverTimePeriod) * kryptorStore.customerData.instrumentNumber));
    return;
  } if (consumable.name.includes(Solution4)) {
    kryptorStore.setSolution4(Math.ceil(calculation.getSolutionCalculation(calculation.getSumAverageTestInstrument() * kryptorStore.customerData.instrumentNumber * Solution4TestsPerAssayMultiplicator, isKryptorGold)));
    return;
  } if (consumable.name.includes(BufferBag)) {
    kryptorStore.setBufferBags(Math.ceil(calculation.getBufferBox()));
  }
}

export class PricesCalculation {
  kryptorStore;

  constructor(kryptorStore) {
    this.kryptorStore = kryptorStore;
  }

  getNumberOfMCAssay(multiControlId) {
    let count = 0;
    this.kryptorStore.kryptorAssayList.map((item) => {
      if (item.multiControlId === multiControlId
        || (item.assay.multi_control_ids.length > 0 && item.assay.multi_control_ids[0] === multiControlId)) {
        count++;
      }
    });
    return count;
  }

  getAssayPriceResult(assayItem, multiControlSelect) {
    const assay = assayItem.assay;
    const allTestsPerAssays = this.getTotalTestsPerCoverPeriod();
    let returnValue = (assayItem.totalPrice / assayItem.patientsPerCoverTime);
    if (this.kryptorStore.totalCostConsumables !== 0) {
      returnValue += (((this.kryptorStore.totalCostConsumables / allTestsPerAssays) * assayItem.amountTests) / assayItem.patientsPerCoverTime);
    } else {
      returnValue = 0;
    }
    if (multiControlSelect) {
      const multiControlId = assayItem.multiControlId ?? assay.multi_control_ids[0];
      const multiControlItem = this.kryptorStore.multiControlList.find(item => item.multiControl.id === multiControlId);
      if (multiControlItem.totalPrice > 0) {
        returnValue += multiControlItem.totalPrice / this.getNumberOfMCAssay(multiControlId) / assayItem.patientsPerCoverTime;
      } else {
        returnValue = 0;
      }
    }
    return returnValue;
  }

  getAssayPriceTest = (assayItem) => {
    const assay = assayItem.assay;
    if (assayItem.reagentKitPrice !== undefined) {
      return assayItem.reagentKitPrice / assay.tests_per_kit;
    }
    return 0;
  }

  getTotalTestsPerCoverPeriod() {
    let totalTestsPerCoverPeriod = 0;
    this.kryptorStore.kryptorAssayList.map((n) => {
      totalTestsPerCoverPeriod += n.amountTests;
    });
    return totalTestsPerCoverPeriod;
  }

  getTotalTestsPerWorkingDay() {
    let totalTestsPerWorkingDay = 0;
    const timeFrame = getTimeframe(this.kryptorStore.customerData.coverTimePeriod);
    this.kryptorStore.kryptorAssayList.map((n) => {
      totalTestsPerWorkingDay += Math.ceil(n.amountTests / (daysInYear * timeFrame / daysInWeek * n.workDaysPerWeek));
    });
    return totalTestsPerWorkingDay;
  }

  getTotalCosts() {
    let totalCosts = this.kryptorStore.totalCostsReagents;
    totalCosts += this.kryptorStore.totalCostMultiControls;
    totalCosts += this.kryptorStore.totalCostConsumables;
    return totalCosts;
  }

  hasPriceQcKit = (assayItem) => {
    const assay = assayItem.assay;
    return !(assay.multi_control_ids.length > 0 || assayItem.multiControlId !== undefined);
  }

  getPrice(assayItem, index, isPricesResult) {
    const pricePerResult = this.getAssayPriceResult(assayItem, !this.hasPriceQcKit(assayItem));
    const pricePerTest = this.getAssayPriceTest(assayItem);
    const price = (isPricesResult ? pricePerResult : pricePerTest);
    this.kryptorStore.setAssayPricePerResult(pricePerResult, index);
    this.kryptorStore.setAssayPricePerTest(pricePerTest, index);
    return price;
  }
}
