import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SelectedIcon from '@material-ui/icons/Done';
import { simappColors } from 'shared/lib/theme';

const styles = {
  button: {
    width: '100%',
    height: '100%',
    padding: '0px',
    justifyContent: 'left',
    borderRadius: '0',
    fontSize: '17px',
    fontWeight: 'normal',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  buttonSelected: {
    marginRight: '-80px',
  },
  selectedIcon: {
    width: '47px',
    height: '47px',
    padding: '16px',
    justifyContent: 'flex-end',
    color: simappColors.azul,
    fontSize: '17px',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    backgroundColor: 'transparent',
    '&:hover, &:focus, &:active': {
      backgroundColor: 'transparent'
    },
  },
  listItem: {
    height: '47px',
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginLeft: '16px',
    paddingLeft: '8px',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${simappColors.paleGrey}`,
    }
  }
};

@inject('addressForm') @observer
class InstrumentTypeItem extends Component {
  render() {
    const { option } = this.props;

    return (
      this.props.showSelectedIcon
        ? (
          <li className={this.props.classes.listItem}>
            <Button
              className={`${this.props.classes.button} ${this.props.classes.buttonSelected}`}
              key={option.id}
              onClick={() => this.props.callback(option.id)}
            >
              {option.name}
            </Button>
            <SelectedIcon className={this.props.classes.selectedIcon} />

          </li>
        )
        : (
          <li className={this.props.classes.listItem}>
            <Button
              className={this.props.classes.button}
              key={option.id}
              onClick={() => this.props.callback(option.id)}
            >
              {option.name}
            </Button>
          </li>
        )
    );
  }
}

InstrumentTypeItem.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  option: PropTypes.any.isRequired,
  showSelectedIcon: PropTypes.bool,
  callback: PropTypes.func.isRequired,
};

InstrumentTypeItem.defaultProps = {
  showSelectedIcon: false,
};

export default withStyles(styles)(InstrumentTypeItem);
