import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { simappColors, simappConstants } from 'shared/lib/theme';
import config from 'shared/config';

const styles = theme => ({
  root: {
    position: 'fixed',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: simappColors.darkGrey,
    width: simappConstants.sidebarMinWidth,
    height: '100%',
    boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.75)',
    borderRight: config.isStage ? `2px solid ${simappColors.strawberry}` : '0px',
    transition: `${theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    })}, ${theme.transitions.create('height', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    })}`,
    overflow: 'hidden',
    '& h3': {
      whiteSpace: 'nowrap'
    },
    '&.expanded': {
      width: simappConstants.sidebarOpenWidth
    },
    '&.minimized.presentationmode': {
      height: '3.3em'
    }
  },
  toggler: {
    minWidth: simappConstants.sidebarMinWidth,
    width: simappConstants.sidebarMinWidth,
    alignSelf: 'flex-end',
    color: simappColors.steelGrey,
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 0,
    '& svg': {
      width: '1.5em',
      height: '1.5em',
    }
  },
  topNav: {
    flex: 1
  },
  bottomNav: {
    '& > div': {
      paddingLeft: simappConstants.sidebarPadding,
      paddingRight: simappConstants.sidebarPadding,
    },
  },
  current: {
    backgroundColor: simappColors.dark,
    '& svg *': {
      color: simappColors.pumpkin
    },
    '& h3': {
      color: simappColors.white
    }
  },
});

const Sidebar = (WrappedComponent) => {
  @inject('routing', 'applicationStore', 'countryStore') @observer
  class HOC extends Component {
    constructor(props) {
      super(props);
      this.sidebar = React.createRef();
    }

    render() {
      const { sidebarOpen } = this.props.applicationStore;
      const expanded = sidebarOpen ? 'expanded' : 'minimized';
      const modeClass = this.props.applicationStore.presentationMode ? 'presentationmode' : '';

      return (
        <div className={`${this.props.classes.root} ${expanded} ${modeClass}`} ref={this.sidebar}>
          <Button onClick={this.props.applicationStore.toggleSidebar} className={this.props.classes.toggler}>
            {sidebarOpen ? <KeyboardArrowLeft /> : <KeyboardArrowRight /> }
          </Button>
          <WrappedComponent
            {...this.props}
          />
        </div>
      );
    }
  }
  HOC.wrappedComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    applicationStore: PropTypes.object.isRequired,
    countryStore: PropTypes.object.isRequired,
    routing: PropTypes.object.isRequired,
  };

  return withStyles(styles)(HOC);
};

export default Sidebar;
