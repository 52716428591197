import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import TableCell from '@material-ui/core/TableCell';
import SimappIcon from 'web/components/_ui/simapp-icon';

class KryptorInputCell extends Component {
  constructor(props) {
    super(props);
    this.state = { showEditIcon: props.showEditIcon };
  }

  inputFocus = () => {
    this.setState({
      showEditIcon: false
    });
    if (this.props.index >= 0) {
      this.props.inputSelectionHelper.setCurrent(this.props.index);
    }
  }

  inputReleaseFocus = () => {
    this.setState({
      showEditIcon: true
    });
    if (this.props.onBlur) {
      this.props.onBlur(this.props.value);
    }
  }

  render() {
    const { className } = this.props;
    return (
      <TableCell className={className}>
        <NumberFormat
          id={this.props.index}
          getInputRef={ref => (this.props.index >= 0 ? this.props.inputSelectionHelper.registerInput(ref) : {})}
          onKeyDown={e => (this.props.index >= 0 ? this.props.inputSelectionHelper.keyDown(e) : {})}
          value={this.props.value}
          onValueChange={values => this.props.onValueChange(values.floatValue)}
          onFocus={event => this.inputFocus(event)}
          onBlur={event => this.inputReleaseFocus(event)}
          decimalScale={this.props.decimalScale}
          fixedDecimalScale={this.props.fixedDecimalScale}
          decimalSeparator={this.props.decimalSeparator}
        />
        {this.state.showEditIcon && (this.props.value === undefined || this.props.forceIcon) && <SimappIcon icon="edit" className="editIcon" />}
      </TableCell>
    );
  }
}

KryptorInputCell.propTypes = {
  className: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.number,
  decimalSeparator: PropTypes.string,
  showEditIcon: PropTypes.bool,
  decimalScale: PropTypes.number,
  fixedDecimalScale: PropTypes.bool,
  forceIcon: PropTypes.bool,
  inputSelectionHelper: PropTypes.object,
  index: PropTypes.number
};

KryptorInputCell.defaultProps = {
  inputSelectionHelper: {},
  onBlur: undefined,
  index: -1,
  value: undefined,
  decimalSeparator: '.',
  showEditIcon: true,
  decimalScale: 0,
  fixedDecimalScale: false,
  forceIcon: false
};

export default KryptorInputCell;
