/* eslint-disable no-mixed-operators */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { simappColors } from 'shared/lib/theme';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { getPeriodName } from 'shared/helper/qc-helper';
import QcInputCell from './qc-inputcell';

const styles = {
  separateButton: {
    padding: '0',
    display: 'inline',
    paddingLeft: '5px',
    color: `${simappColors.azul}`,
    cursor: 'pointer',
    marginTop: '-5px',
    '&:hover': {
      backgroundColor: 'unset'
    }
  },
  reagentSelectionButton: {
    color: `${simappColors.azul}`,
    cursor: 'pointer',
    marginLeft: '-8px',
    '&:hover': {
      background: 'transparent'
    },
    '& span': {
      justifyContent: 'left'
    }
  },
  levelText: {
    display: 'inline'
  },
  inputTableRow: {
    backgroundColor: 'white',
    '& input': {
      backgroundColor: 'transparent',
      border: 'none',
      fontFamily: 'helvetica-neue-lt, sans-serif'
    },
    '& input:focus': {
      outline: 'none',
    }
  },
  periodSelectButton: {
    padding: '0',
    display: 'inline',
    color: `${simappColors.azul}`,
    backgroundColor: 'transparent',
    textAlign: 'left',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'unset'
    }
  }
};

@inject('applicationStore', 'qcTestRequestForm', 'qcStore', 'routing') @observer
class QcInstrumentTable extends Component {
  getReagentSelection = (test, testIndex) => {
    const { classes, qcStore } = this.props;
    if (test.reagents.length > 0) {
      if (test.reagents.length > 1) {
        return (
          <TableCell>
            <Button color="primary" className={classes.reagentSelectionButton} onClick={() => this.props.onReagentSelect(testIndex)} disableRipple disableFocusRipple>
              {test.selectedReagentCatalogNumber || qcStore.getText('QCProductSelectionTestViewInstrumentSelectReagent')}
            </Button>
          </TableCell>
        );
      }
      return (<TableCell>{test.reagents[0].catalogNumber}</TableCell>);
    }
    return (<TableCell />);
  }

  getTableRow(test, index, level, levelIndex) {
    if (this.props.displayAll || (
      !this.props.displayAll && (test.isEnabled && !level
        || level !== undefined && level.isEnabled)
    )) {
      const { classes, qcStore } = this.props;
      const runs = levelIndex ? test.levels[levelIndex].nbRuns : test.levels[0].nbRuns;
      return (
        <TableRow key={levelIndex !== undefined ? `${index}${levelIndex}` : index}>
          <TableCell>
            <Checkbox
              checked={levelIndex !== undefined ? level.isEnabled : test.isEnabled}
              color="primary"
              onChange={(object, checked) => this.props.onTestSelectedChange(checked, index, levelIndex)}
            />
          </TableCell>
          <TableCell>{test.name}</TableCell>
          {this.getReagentSelection(test, index)}
          <QcInputCell
            className={`${classes.inputTableRow}`}
            onValueChange={value => this.props.onTestRunsInputChange(value, index, levelIndex)}
            value={runs}
            fixedDecimalScale
            minValue={0}
            maxValue={9999}
          />
          <TableCell>
            <Button className={`${classes.periodSelectButton}`} onClick={() => this.props.onPeriodSelect(index, levelIndex)}>{getPeriodName(levelIndex ? test.levels[levelIndex].period : test.levels[0].period, qcStore)}</Button>
          </TableCell>
          <TableCell>
            <p className={classes.levelText}>{levelIndex !== undefined ? level.level : test.levels.map((item, itemIndex) => {
              if (test.levels.length - 1 === itemIndex) {
                return `${item.level}`;
              }
              return `${item.level},`;
            })}
            </p>
            {levelIndex === undefined && <Button className={classes.separateButton} onClick={() => this.props.onSeparate(index)}>{qcStore.getText('QCProductSelectionTestViewInstrumentSeparateButton')}</Button>}
          </TableCell>
        </TableRow>
      );
    }
    return null;
  }

  render() {
    const { classes, qcStore, tests } = this.props;
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableHeadRow}>
            <TableCell className={`${classes.tableHeadCell} first`} />
            <TableCell className={`${classes.tableHeadCell} fixedWidthHalf left`}>{qcStore.getText('QCProductSelectionTestViewInstrumentAnalyteTableHeadline')}</TableCell>
            <TableCell className={`${classes.tableHeadCell} fixedWidthHalf left`}>{qcStore.getText('QCProductSelectionTestViewInstrumentReagentTableHeadline')}</TableCell>
            <TableCell className={`${classes.tableHeadCell} fixedWidthHalf `}>{qcStore.getText('QCProductSelectionTestViewInstrumentNumberRunsTableHeadline')}</TableCell>
            <TableCell className={`${classes.tableHeadCell} fixedWidthHalf left`}>{qcStore.getText('QCProductSelectionTestViewInstrumentPeriodTableHeadline')}</TableCell>
            <TableCell className={`${classes.tableHeadCell} fixedWidthHalf`}>{qcStore.getText('QCProductSelectionTestViewInstrumentLevelsTableHeadline')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tests.map((test, index) => {
            if (test.isLevelsSeparated) {
              return test.levels.map((level, levelIndex) => this.getTableRow(test, index, level, levelIndex));
            }
            return (
              this.getTableRow(test, index)
            );
          })}
        </TableBody>
      </Table>
    );
  }

  getSelectedPeriod = (test, levelIndex) => {
    if (test !== undefined) {
      if (levelIndex >= 0) {
        return test.levels[levelIndex].period;
      }
      return test.levels[0].period;
    }
    return undefined;
  }
}

QcInstrumentTable.wrappedComponent.propTypes = {
  qcStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  tests: PropTypes.array.isRequired,
  onTestRunsInputChange: PropTypes.func,
  onPeriodSelect: PropTypes.func,
  onTestSelectedChange: PropTypes.func,
  onReagentSelect: PropTypes.func,
  onSeparate: PropTypes.func,
  displayAll: PropTypes.bool
};

QcInstrumentTable.defaultProps = {
  onTestRunsInputChange: () => {},
  onPeriodSelect: () => {},
  onTestSelectedChange: () => {},
  onReagentSelect: () => {},
  onSeparate: () => {},
  displayAll: false
};

export default withStyles(styles)(QcInstrumentTable);
