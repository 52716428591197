import { observable, runInAction } from 'mobx';
import { persist } from 'mobx-persist';
import rest from 'shared/lib/rest';
import { merge } from 'shared/lib/helpers';
import createStorage from './create-storage';

class RoleStore {
  constructor() {
    createStorage('roleStore', this).then(() => {
    });
  }

  api = {
    role: '/role',
  };

  @persist('list') @observable roles = [];

  loadData(forceReload = false, lastUpdated = null) {
    const startTime = new Date().getTime();
    return new Promise((resolve) => {
      let params = '?';
      if (this.hasDataLoaded && !forceReload && lastUpdated !== null) {
        params += `since=${lastUpdated}`;
      // } else if (forceReload) {
      //   runInAction(() => {
      //     this.roles = [];
      //   });
      }
      rest.get(this.api.role + params)
        .then((response) => {
          runInAction(() => {
            merge(this.roles, response.data, 'id', 'name');
            console.log(`${new Date().getTime() - startTime}ms - ${this.constructor.name}`);
            resolve(true);
          });
        }).catch((error) => {
          console.log('Could not load roles', error.response.data);
          resolve();
        });
    });
  }

  get roleCount() {
    return this.roles.length;
  }

  get hasDataLoaded() {
    return !!this.roles.length;
  }

  getRoleName(id) {
    const foundRole = this.roles.find(role => role.id === id);
    return typeof foundRole !== 'undefined' ? foundRole.name : '';
  }

  getRolesForSelect() {
    return this.roles.map(role => ({
      id: role.id,
      value: role.name
    }));
  }
}

export default new RoleStore();
