import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { simappConstants } from 'shared/lib/theme';
import Typography from '@material-ui/core/Typography';
import pctConstants from 'shared/lib/resources/pct-constants';
import PctNavButton from './pct-navbutton';

const styles = {
  root: {
    padding: simappConstants.contentMarginLarge,
    width: '100%',
    overflow: 'auto'
  },
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 8
  },
  title: {
    fontSize: '28px',
    fontWeight: '300'
  },
  tableBox: {
    paddingTop: 20,
    paddingBottom: 40
  },
  table: {
    width: '100%',
    display: 'table',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    borderSpacing: 0,
    borderCollapse: 'collapse'
  },
  tableBody: {
    display: 'table-row-group'
  },
  tableRow: {
    color: 'inherit',
    height: 48,
    display: 'table-row',
    outline: 'none',
    textAlign: 'middle'
  },
  tableCell: {
    display: 'table-cell',
    padding: '4px 56px 4px 24px',
    borderBottom: '1px solid rgba(226, 226, 226, 1)',
    verticalAlign: 'inherit',
    textAlign: 'left',
    direction: 'row-reverse',
    color: '#0e0e10',
    fontSize: '0.8125rem',
    fontWeight: 'normal',
    '&:last-child': {
      paddingRight: 24
    }
  },
  tableCellNumerical: {
    textAlign: 'right',
    flexDirection: 'row-reverse'
  },
  bottomBar: {
    width: '100%',
    marginTop: '30px',
    paddingBottom: '30px'
  },
  text: {
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontSize: '0.8125rem',
    '&.bold': {
      fontWeight: 'bold'
    }
  }
};

const json = require('shared/lib/resources/pct-references.json');

@inject('applicationStore', 'pctStore', 'routing') @observer
class PctReferences extends Component {
  rows;

  createData(countryName) {
    if (countryName === pctConstants.CountryNameUS) {
      this.rows = json.us;
      return;
    }
    this.rows = json.default;
  }

  render() {
    const { classes } = this.props;
    const { goBack } = this.props.routing;
    this.createData(this.props.applicationStore.presentingInName);
    return (
      <div className={classes.root}>
        <div className={classes.headerRow}>
          <Typography variant="h5" className={classes.title} gutterBottom>References</Typography>
        </div>
        <div className={classes.tableBox}>
          <table className={classes.table}>
            <tbody className={classes.tableBody}>
              {this.rows.map((row) => {
                if (row.url !== undefined) {
                  return (
                    <tr className={classes.tableRow} key={row.id} id={row.id}>
                      <td className={`${classes.tableCell} ${classes.tableCellNumerical}`}>{row.id}</td>
                      <td className={classes.tableCell}>
                        {row.text} <a href={row.url}>{row.url}</a>
                      </td>
                    </tr>
                  );
                }
                return (
                  <tr className={classes.tableRow} key={row.id} id={row.id}>
                    <td className={`${classes.tableCell} ${classes.tableCellNumerical}`}>{row.id}</td>
                    <td className={classes.tableCell}>
                      {row.text}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <p className={`${classes.text} bold`}>{this.props.pctStore.getText('ReferencesTextHead')}</p>
          <p className={classes.text}>{this.props.pctStore.getText('ReferencesText1')}</p>
          <p className={classes.text}>{this.props.pctStore.getText('ReferencesText2')}</p>
          <p className={classes.text}>{this.props.pctStore.getText('ReferencesText3')}</p>
          <p className={classes.text}>{this.props.pctStore.getText('ReferencesText4')}</p>
          <p className={classes.text}>{this.props.pctStore.getText('ReferencesText5')}</p>
          <p className={classes.text}>{this.props.pctStore.getText('ReferencesText6')}</p>
          <p className={classes.text}>{this.props.pctStore.getText('ReferencesText7')}</p>
        </div>
        <div className={classes.bottomBar}>
          <PctNavButton nextText="back" nextOnClick={() => goBack(1)} />
        </div>
      </div>
    );
  }
}

PctReferences.wrappedComponent.propTypes = {
  applicationStore: PropTypes.object.isRequired,
  pctStore: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(PctReferences);
