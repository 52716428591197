import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { simappConstants } from 'shared/lib/theme';
import HEModelItem from './he-model-item';

const styles = {
  root: {
    width: '100%',
    height: '100vH',
    display: 'flex',
    flexDirection: 'column',
    padding: simappConstants.contentMarginLarge,
    overflowX: 'hidden',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontWeight: 'normal'
  },
  headRow: {
    display: 'flex',
    flexShrink: 0,
    height: 46,
    marginBottom: '24px',
    alignItems: 'left'
  },
  title: {
    margin: '0',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '28px',
    lineHeight: '40px'
  },
  textContainer: {
    marginTop: '20px',
    marginBottom: '20px',
    paddingRight: '10px',
  },
  textBlock: {
    marginBottom: '20px',
    lineHeight: '20px',
    '&.marginBottom': {
      marginBottom: '35px'
    },
    '&.marginTop': {
      marginTop: '35px'
    },
    color: 'black'
  },
  grid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  }
};

@inject('pctStore', 'copeptinStore', 'calculatorStore', 'routing') @observer
class HEModelScreen extends Component {
  render() {
    const { classes, calculatorStore, pctStore, copeptinStore, routing } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.headRow}>
          <Typography
            variant="h1"
            className={classes.title}
          >
            {calculatorStore.getText('title')}
          </Typography>
        </div>
        <div className={classes.textContainer}>
          <Typography variant="body1" className={classes.textBlock}>{calculatorStore.getText('introParagraphOne')}</Typography>
          <Typography variant="body1" className={classes.textBlock}>{calculatorStore.getText('introParagraphTwo')}</Typography>
        </div>
        <div className={classes.grid}>
          <HEModelItem store={pctStore} routing={routing} path="/he-models/pct-calculator" />
          <HEModelItem store={copeptinStore} routing={routing} path="/he-models/copeptin-calculator" />
        </div>
      </div>
    );
  }
}

HEModelScreen.wrappedComponent.propTypes = {
  routing: PropTypes.object.isRequired,
  calculatorStore: PropTypes.object.isRequired,
  pctStore: PropTypes.object.isRequired,
  copeptinStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(HEModelScreen);
