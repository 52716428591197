import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = {
  clickableTextButton: {
    backgroundColor: 'transparent',
    '&:hover, &:focus, &:active': {
      backgroundColor: 'transparent',
      '& span:first-child span': {
        display: 'none',
      }
    }
  },
  bold: {
    fontWeight: 'bold'
  }
};

const ClickableTextButton = props => (
  <Button className={`${props.classes.clickableTextButton}`} onClick={props.onClick}><Typography variant="body1" className={`${props.isBold ? props.classes.bold : ''}`}>{props.text}</Typography></Button>
);

ClickableTextButton.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isBold: PropTypes.bool
};

ClickableTextButton.defaultProps = {
  isBold: false
};

export default withStyles(styles)(ClickableTextButton);
