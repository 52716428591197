import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import { applicationStore, materialStore, presentationStore, routing } from 'shared/stores';

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'relative',
    width: 600,
    overflow: 'hidden',
    outline: 'none',
    userSelect: 'none',
    padding: '1em 0 0',
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    padding: 30,
    width: '100%',
    textAlign: 'center'
  },
  next: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
    width: '100%',
    textAlign: 'center'
  },
  buttonArea: {
    justifyContent: 'space-around'
  },
};

@observer
class NextStepOverlay extends Component {
  material;

  goToDashboard = () => {
    this.props.onClose();
    presentationStore.confirmLeave();
    routing.push('/');
    presentationStore.reset();
  }

  goToPresent = () => {
    this.props.onClose();
    presentationStore.confirmLeave();
    routing.push(`/presentation/${this.material.uid}`);
    presentationStore.reset();
  }

  goToShare = () => {
    this.props.onClose();
    presentationStore.confirmLeave();
    routing.push('/materials');
    applicationStore.startSharing(this.material.uid);
    presentationStore.reset();
  }

  goToEdit = () => {
    this.props.onClose();
  }

  render() {
    const { classes } = this.props;
    this.material = materialStore.getMaterialByUid(`p:${presentationStore.id}`);

    return (
      <Modal
        open={this.props.open}
        className={classes.modal}
      >
        <Paper className={classes.paper} tabIndex="-1">
          <Typography className={classes.title} variant="h6">{presentationStore.title}</Typography>
          <Typography className={classes.next}>What would you like to do next?</Typography>
          <DialogActions className={classes.buttonArea}>
            <Button variant="contained" color="primary" onClick={this.goToDashboard}>Main Menu</Button>
            <Button variant="contained" color="primary" onClick={this.goToPresent}>Present</Button>
            { typeof this.material !== 'undefined' && this.material.shareable && (
              <Button variant="contained" color="primary" onClick={this.goToShare}>Share</Button>
            )}
            <Button variant="contained" color="primary" onClick={this.goToEdit}>Edit</Button>
          </DialogActions>
        </Paper>
      </Modal>
    );
  }
}

NextStepOverlay.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

NextStepOverlay.defaultProps = {
};

export default withStyles(styles)(NextStepOverlay);
