import { action, observable, runInAction } from 'mobx';
import { qcStore } from 'shared/stores';
import BaseForm from './base-form';

class QcTestRequestForm extends BaseForm {
  @observable
  fields = {
    instrumentName: {
      value: '',
      error: null,
      rule: 'required',
      required: false,
      label: 'Instrument Name',
      placeholder: '',
      disabled: true
    },
    testName: {
      value: '',
      error: null,
      rule: 'required',
      required: true,
      label: 'Test Name',
      placeholder: ''
    },
    microlitersUsed: {
      value: 0,
      error: null,
      required: true,
      label: 'Microliters used',
      rule: 'required|numeric|min:0.1'
    }
  }

  defaultValues = {
    manufacturer: '',
    productName: '',
    testName: '',
    microlitersUsed: ''
  };

  @action setValues = (instrument) => {
    this.fields.instrumentName.value = instrument.instrument.name;
  }

  @action resetToDefaultValues = () => {
    const keys = Object.keys(this.fields);
    keys.forEach((key) => {
      if (typeof this.fields[key] !== 'undefined') {
        const value = this.defaultValues[key];
        qcStore.customerData[key] = value;
        this.onFieldChange({ target: { id: key, value } });
        this.fields[key].error = '';
      }
    });
  }

  errors = {
    NO_MAIL_PERMISSION: 'NO_MAIL_PERMISSION',
    MAIL_SEND_ERROR: 'MAIL_SEND_ERROR'
  }

  @action onNativeFieldChange = (e) => {
    this.fields[e.fieldName].value = e.value;
  }

  @action send = () => {
    this.saveInProgess = true;
    // trigger validation
    const keys = Object.keys(this.fields);
    keys.map((key) => {
      const value = this.fields[key].value;
      this.onFieldChange({ target: { id: key, value } });
    });

    if (this.isValid) {
      return new Promise((resolve, reject) => {
        qcStore.sendRequestMail(this.fields)
          .then(() => {
            this.resetToDefaultValues();
            resolve();
          })
          .catch((error) => {
            // happens only for old backend user with fewer permissions
            if (typeof error === 'object' && typeof error.error !== 'undefined' && error.error.code === 'ErrorAccessDenied') {
              this.setResponseError(this.errors.NO_MAIL_PERMISSION);
            } else {
              this.setResponseError(this.errors.MAIL_SEND_ERROR);
            }
            reject();
          }).finally(() => {
            runInAction(() => {
              this.saveInProgess = false;
            });
          });
      });
    }

    return new Promise((resolve, reject) => { reject(); });
  }
}

export default new QcTestRequestForm();
