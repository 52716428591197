import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { simappConstants } from 'shared/lib/theme';
import pctConstants from 'shared/lib/resources/pct-constants';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import PctInputField from 'web/components/pct/pct-inputfield';
import ClickableTextButton from 'web/components/_misc/clickableTextButton';
import { checkChange } from 'shared/helper/pct-helper';
import PctNavButton from './pct-navbutton';
import PctDialog from './pct-dialog';
import PctAbbreviations from './pct-abbreviations';

const styles = {
  root: {
    width: '100%',
    height: '100vH',
    display: 'flex',
    flexDirection: 'column',
    padding: simappConstants.contentMarginLarge,
    overflowX: 'hidden',
    fontFamily: 'helvetica-neue-lt, sans-serif'
  },
  borderlessExpansionPanel: {
    '&::before': { content: 'none' },
    marginBottom: '2px',
    boxShadow: 'unset'
  },
  bottomBar: {
    width: '100%',
    marginTop: '30px',
    paddingBottom: '30px'
  },
  textContainer: {
    marginTop: '40px',
    marginBottom: '60px',
    marginLeft: simappConstants.pctHorizontalMargin,
    marginRight: simappConstants.pctHorizontalMargin
  },
  rightTableCell: {
    textAlign: 'right',
    width: '200px'
  },
  expansionContent: {
    paddingLeft: '10px',
    paddingRight: '0px',
  },
  mediumText: {
    fontWeight: '500'
  },
  table: {
    marginTop: '-15px',
    marginBottom: '-25px',
    paddingLeft: '10px',
    paddingRight: '0px'
  },
  animationlessPanelSummary: {
    minHeight: '48px !important',
    lineHeight: '48px',
    '& div:last-child': {
      padding: '0'
    },
    '& div:first-child': {
      margin: '0'
    }
  },
  leftAlignedText: {
    textAlign: 'left',
    marginLeft: '-40px'
  },
  borderlessCell: {
    border: 'none'
  }
};

@inject('applicationStore', 'pctStore', 'routing') @observer
class PctHealthcare extends Component {
  isUS = false;
  constructor(props) {
    super(props);
    this.state = {
      showValueChangeDialog: false,
      showNavigationDialog: false,
      valueChanged: false
    };
    this.isUS = props.applicationStore.presentingInName === pctConstants.CountryNameUS;
  }

  onNavigationClick(location) {
    if (!this.isUS || !this.state.valueChanged || this.props.pctStore.pctNavigateDialogPopUp[this.props.routing.location.pathname] === false) {
      this.props.routing.push(location);
      return;
    }
    this.setState({
      showNavigationDialog: true,
      navigationDestination: location
    });
  }

  onChangePctValue(key, index, value, type, showDialog) {
    this.props.pctStore.setPctValue(key, index, value);
    this.setState({
      showValueChangeDialog: showDialog,
      valueChanged: true
    });
  }

  getExpansionPanelArray() {
    let end = false;
    const panel = [];
    for (let i = 0; end !== true; i++) {
      if (this.props.pctStore.getText(pctConstants.PctHealthcareCostsTableHeadText + i) !== undefined) {
        panel.push(this.props.pctStore.getText(pctConstants.PctHealthcareCostsTableHeadText + i));
      } else {
        end = true;
      }
    }
    return panel;
  }

  getTableRowArray(i) {
    let end = false;
    const table = [];
    for (let j = 0; end !== true; j++) {
      if (this.props.pctStore.getText(pctConstants.PctHealthcareCostsTableHeadText + i + pctConstants.PctFieldText + j) !== undefined) {
        table.push(this.props.pctStore.getText(pctConstants.PctHealthcareCostsTableHeadText + i + pctConstants.PctFieldText + j));
      } else {
        end = true;
      }
    }
    return table;
  }

  render() {
    const { push } = this.props.routing;
    let i = -1;
    this.state.valueChanged = checkChange(this.props.pctStore, pctConstants.CostsField);
    const expansionPanel = this.getExpansionPanelArray();
    return (
      <div className={this.props.classes.root}>
        <div className={this.props.classes.textContainer}>
          <Typography align="center" variant="subtitle1" className={this.isUS ? this.props.classes.leftAlignedText : undefined}>{this.props.pctStore.getText(pctConstants.PctHealthcareCostsHeadText0)}</Typography>
        </div>
        <div>
          {expansionPanel.map((text, index0) => {
            const index = index0;
            const tableRow = this.getTableRowArray(index0);
            return (
              <ExpansionPanel key={index} CollapseProps={{ timeout: 0 }} className={this.props.classes.borderlessExpansionPanel}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={this.props.classes.animationlessPanelSummary}>
                  <Typography variant="body1" className={this.props.classes.mediumText}>{text}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={this.props.classes.expansionContent}>
                  <Table className={this.props.classes.table}>
                    <TableBody>
                      {tableRow.map((rowText, index1) => {
                        const rowIndex = `${index0}${index1}`;
                        const isLastRow = index0 === expansionPanel.length - 1 && index1 === tableRow.length - 1;
                        i++;
                        return (
                          <TableRow key={rowIndex}>
                            <TableCell className={isLastRow ? this.props.classes.borderlessCell : undefined}>
                              <Typography variant="body1">{rowText}</Typography>
                            </TableCell>
                            <TableCell className={`${this.props.classes.rightTableCell} ${isLastRow ? this.props.classes.borderlessCell : undefined}`}>
                              <PctInputField
                                fieldName={pctConstants.CostsField}
                                fieldIndex={i}
                                isUSNumberInput={this.isUS}
                                value={this.props.pctStore.getPctValue(pctConstants.CostsField, i)}
                                onValueChange={(key, fieldIndex, value, type, showDialog) => { this.onChangePctValue(key, fieldIndex, value, type, showDialog); }}
                                isChanged={this.props.pctStore.isUserPctValue(pctConstants.CostsField, i)}
                                textAlign={this.isUS ? 'right' : 'center'}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          })}
        </div>
        <div><PctAbbreviations cDiff abr hidden={!this.isUS} /></div>
        <div className={this.props.classes.bottomBar}>
          <ClickableTextButton onClick={() => this.props.routing.push('/he-models/pct-calculator/references')} text={this.props.pctStore.getText(pctConstants.PctReferences)} />
          <PctNavButton
            nextText={this.props.pctStore.getText(pctConstants.PctNextStep)}
            backText={this.props.pctStore.getText(pctConstants.PctPreviousStep)}
            backButton
            backOnClick={() => this.onNavigationClick('/he-models/pct-calculator/population')}
            nextOnClickLocation={this.isUS ? '/he-models/pct-calculator/clinicaleffect-lrti' : '/he-models/pct-calculator/clinicaleffect'}
            nextOnClick={(location) => {
              this.onNavigationClick(location);
            }}
            isUS={this.isUS}
            screenNumber={2}
          />
          {this.isUS
            ? (
              <div>
                <PctDialog
                  trigger={this.state.showNavigationDialog && this.state.valueChanged}
                  message={this.props.pctStore.getText('PctValueChangeNavigationDialogText')}
                  okText={this.props.pctStore.getText('PctValueChangeDialogOkText')}
                  cancelText={this.props.pctStore.getText('PctNavigationAlertCancel')}
                  onOk={() => {
                    this.props.pctStore.setNavigationDialogSetting(this.props.routing.location.pathname, false);
                    push(this.state.navigationDestination);
                  }}
                  onCancel={() => {
                    this.setState({
                      showValueChangeDialog: false,
                      showNavigationDialog: false
                    });
                  }}
                />
                <PctDialog
                  trigger={this.state.showValueChangeDialog}
                  message={this.props.pctStore.getText('PctValueChangeDialogText')}
                  okText={this.props.pctStore.getText('PctValueChangeDialogOkText')}
                  hasCheckbox
                  checkboxMessage={this.props.pctStore.getText('PctValueChangeDialogCheckboxText')}
                  onOk={(state) => {
                    this.props.pctStore.setValueChangeDialogSetting(state);
                    this.setState({
                      showValueChangeDialog: false,
                      showNavigationDialog: false
                    });
                  }}
                />
              </div>
            )
            : ''
          }
        </div>
        <PctDialog />
      </div>
    );
  }
}

PctHealthcare.wrappedComponent.propTypes = {
  applicationStore: PropTypes.object.isRequired,
  pctStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired,
};

export default withStyles(styles)(PctHealthcare);
