import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { simappColors } from 'shared/lib/theme';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '10px',
  },
  navigationbutton: {
    borderRadius: '2px',
    height: '56px',
    width: '100%',
    fontSize: '17px',
    fontWeight: 'normal',
    transition: 'none',
    backgroundColor: `${simappColors.coolGrey}`,
    color: `${simappColors.white}`,
    '&:hover': {
      backgroundColor: `${simappColors.coolGrey}`
    }
  },
  fulfilled: {
    backgroundColor: `${simappColors.darkLimeGreen}`,
    '&:hover': {
      backgroundColor: `${simappColors.darkLimeGreen}`
    }
  },
  disabled: {
    backgroundColor: 'transparent',
    border: `1px solid ${simappColors.disableButtonGrey}`,
    color: `${simappColors.disableButtonGrey}`,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
};

const KryptorNavButton = (props) => {
  const { text } = props;

  return (
    <div className={`${props.classes.root}`}>
      <Button
        onClick={props.isValid ? () => props.onClick() : () => { }}
        className={`${props.classes.navigationbutton} ${props.isValid ? props.classes.fulfilled : props.classes.disabled}`}
      >
        {text}
      </Button>
    </div>
  );
};

KryptorNavButton.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isValid: PropTypes.bool
};

KryptorNavButton.defaultProps = {
  onClick: () => { },
  isValid: false
};

export default withStyles(styles)(KryptorNavButton);
