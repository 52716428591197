import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import SimAppButton from 'web/components/_ui/simapp-button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { simappConstants } from 'shared/lib/theme';

const constants = simappConstants;

const styles = {
  root: {
    width: '100%',
    height: '100vH',
    display: 'flex',
    flexDirection: 'column',
    padding: constants.contentMarginLarge,
    overflowX: 'hidden',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontWeight: 'normal'
  },
  headRow: {
    display: 'flex',
    flexShrink: 0,
    height: 46,
    marginBottom: '24px',
    alignItems: 'left'
  },
  buttonRoot: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '200px',
  },
  navigationbutton: {
    marginLeft: '5px',
    marginRight: '5px'
  },
  title: {
    margin: '0',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '28px',
    lineHeight: '40px'
  }
};

@inject('qcStore', 'routing') @observer
class QcCalculator extends Component {
  classes;
  qcStore;

  render() {
    this.classes = this.props.classes;
    this.qcStore = this.props.qcStore;
    const { push } = this.props.routing;

    return (
      <div className={this.classes.root}>
        <div className={this.classes.headRow}>
          <Typography
            variant="h1"
            className={this.classes.title}
          >
            {this.qcStore.getText('QCalculatorHeadline')}
          </Typography>
        </div>
        <div className={this.classes.buttonRoot}>
          <SimAppButton className={this.classes.navigationbutton} onClick={() => push('/qc-calculator/new-customer')} isNavigationButton inline>{this.qcStore.getText('QCNewCustomerTitle')}</SimAppButton>
          <SimAppButton className={this.classes.navigationbutton} onClick={() => push('/qc-calculator/existing-customer')} isNavigationButton inline>{this.qcStore.getText('QCExistingCustomer')}</SimAppButton>
        </div>
      </div>
    );
  }
}

QcCalculator.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  qcStore: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired
};

export default withStyles(styles)(QcCalculator);
