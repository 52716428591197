import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { simappColors } from 'shared/lib/theme';
import QcInputCell from './qc-inputcell';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontWeight: 'normal'
  },
  tableContainer: {
    paddingLeft: '20px',
    paddingRight: '20px',
    marginBottom: '20px'
  },
  tableHeadRow: {
    backgroundColor: `${simappColors.paleGrey}`,
    height: '40px'
  },
  tableHeadCell: {
    fontSize: '10px',
    fontWeight: '500',
    padding: '8px 7px',
    borderBottom: `1px solid ${simappColors.tableBorder}`,
    textAlign: 'center',
    letterSpacing: '0.5pt',
    borderLeft: `1px solid ${simappColors.tableBorder}`,
    minWidth: '80px',
    '&.product': {
      minWidth: 'unset'
    },
    '&.last': {
      borderRight: `1px solid ${simappColors.tableBorder}`,
    },
    '&.productCode': {
      width: '110px'
    },
    '&.pricePerUnit': {
      width: '120px'
    },
    '&.discountedPricePerUnit': {
      width: '120px'
    },
    '&.kitFormat': {
      width: '120px'
    },
    '&.quantity': {
      width: '80px'
    },
    '&.total': {
      width: '180px'
    }
  },
  tableRowCell: {
    height: '56px',
    borderBottom: `1px solid ${simappColors.tableBorder}`,
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '8px 7px',
    textAlign: 'center',
    borderLeft: `1px solid ${simappColors.tableBorder}`,
    '&.product': {
      textAlign: 'left'
    },
    '&.last': {
      borderRight: `1px solid ${simappColors.tableBorder}`,
    },
    '&.input': {
      backgroundColor: '#ffffff',
      '& input': {
        width: '100%',
        height: '24px',
        border: 'none',
        textAlign: 'center',
        fontSize: '14px',
        fontFamily: 'helvetica-neue-lt, sans-serif',
        fontWeight: 'normal'
      },
      '& input:focus': {
        outline: 'none',
      }
    },
    '&.kitFormat': {
      width: '80px'
    },
    '&.productCode': {
      width: '80px'
    },
    '&.pricePerUnit': {
      width: '120px'
    },
    '&.discountedPricePerUnit': {
      width: '120px'
    },
    '&.quantity': {
      width: '80px'
    },
    '&.total': {
      width: '180px'
    },
    '&.grey': {
      backgroundColor: `${simappColors.paleGrey}`
    }
  },
  tableTotalRowCell: {
    borderBottom: `1px solid ${simappColors.tableBorder}`,
    fontSize: '14px',
    fontWeight: 'bold',
    padding: '8px 7px',
    textAlign: 'center',
    borderLeft: `1px solid ${simappColors.tableBorder}`,
    '&.first': {
      textAlign: 'left',
      paddingLeft: '16px'
    },
    '&.last': {
      borderRight: `1px solid ${simappColors.tableBorder}`,
      fontSize: '18px',
      width: '180px'
    },
    '&.quantity': {
      width: '80px'
    },
    '&.grey': {
      backgroundColor: `${simappColors.paleGrey}`
    }
  },
  tableTotalRow: {
    height: '40px'
  },
  tableColumnProducts: {
    minWidth: '220px'
  },
  editButton: {
    padding: '0',
    display: 'inline',
    paddingLeft: '5px',
    color: `${simappColors.azul}`,
    cursor: 'pointer',
    marginTop: '-3px',
    minWidth: 'unset',
    '&:hover': {
      backgroundColor: 'unset'
    }
  }
};

@inject('qcStore', 'routing') @observer
class QcProductTable extends Component {
  priceTextInput = [];

  constructor(props) {
    super(props);
    this.state = {
      discountIsActive: false
    };
    if (props.showDiscountedPriceWhenActive) {
      this.isDiscountActive(props);
    }
  }

  isDiscountActive = (props) => {
    const { data, qcStore } = props;
    const products = data === undefined ? qcStore.productList : data;
    for (let i = 0; i < products.length; i++) {
      if (products[i].pricePerUnit !== products[i].discountedPricePerUnit) {
        this.state = {
          discountIsActive: true
        };
        break;
      }
    }
  }

  getKitVolumeText = (volumeText) => {
    if (volumeText === undefined) {
      return '';
    }
    return `${volumeText} ml`;
  }

  componentDidMount() {
    this.props.objRef(this);
  }

  componentWillUnmount() {
    this.props.objRef(undefined);
  }

  resetPricesToDefaultValues() {
    this.props.data.map((product, index) => (
      this.props.qcStore.setCompetitorProductTotalPrice(index)
    ));
    this.priceTextInput.forEach((element) => {
      element.overwriteStateValue(undefined);
    });
  }

  getTableRow(product, index) {
    const { classes, editProduct, deleteProduct, qcStore, editable, showDiscountedPricePerUnit, showKitFormat, adminView, pricePerUnitInput, editAnalytes } = this.props;
    return (
      <TableRow key={index} className={classes.tableRowCell}>
        <TableCell className={`${classes.tableRowCell} productCode`}>
          {product.product.id !== undefined && product.product.partNumber}
        </TableCell>
        <TableCell className={`${classes.tableRowCell} product`}>
          {product.product.name}
          {editProduct !== undefined && <Button className={classes.editButton} onClick={() => editProduct(index)}>{qcStore.getText('QCProductSelectionProductTableEditButton')}</Button>}
          {deleteProduct !== undefined && <Button className={classes.editButton} onClick={() => deleteProduct(index)}>{qcStore.getText('QCProductSelectionProductTableDeleteButton')}</Button>}
          {editAnalytes !== undefined && <Button className={classes.editButton} onClick={() => editAnalytes(index)}>{qcStore.getText('QCProductSelectionProductTableAnalyteButton')}</Button>}
        </TableCell>
        <QcInputCell
          index={`${index}0`}
          objRef={(component) => { this.priceTextInput[index] = component; }}
          className={`${classes.tableRowCell} inputCell pricePerUnit ${pricePerUnitInput ? 'input' : 'text'}`}
          onValueChange={(value) => { this.productValueChange(index, value, 'PricePerUnit'); }}
          value={product.pricePerUnit}
          currencyValue={qcStore.getCurrencySign()}
          currencyPosition={qcStore.getCurrencyPosition()}
          decimalSeparator={qcStore.getDecimalSeparator()}
          thousandSeparator={qcStore.getThousandSeparator()}
          displayType={pricePerUnitInput ? 'input' : 'text'}
          decimalScale={2}
          fixedDecimalScale
        />
        {(this.state.discountIsActive || showDiscountedPricePerUnit)
          && (
            <QcInputCell
              index={`${index}1`}
              className={`${classes.tableRowCell} inputCell discountedPricePerUnit text`}
              onValueChange={(value) => { this.productValueChange(index, value, 'DiscountedPricePerUnit'); }}
              value={product.discountedPricePerUnit}
              currencyValue={qcStore.getCurrencySign()}
              currencyPosition={qcStore.getCurrencyPosition()}
              decimalSeparator={qcStore.getDecimalSeparator()}
              thousandSeparator={qcStore.getThousandSeparator()}
              displayType="text"
              editableButton={editable && adminView}
              editableButtonClick={() => this.editDiscountedPricePerUnit(index)}
              editableButtonText={qcStore.getText('QCProductSelectionProductTableEditButton')}
              decimalScale={2}
              fixedDecimalScale
            />
          )
        }
        {showKitFormat
          && (
            <TableCell className={`${classes.tableRowCell} kitFormat`}>
              {this.getKitFormat(product)}
            </TableCell>
          )
        }
        {editable
          ? (
            <QcInputCell
              index={`${index}2`}
              className={`${classes.tableRowCell} quantity input`}
              onValueChange={(value => this.productValueChange(index, value, 'Quantity'))}
              value={product.quantity}
            />
          )
          : (
            <QcInputCell
              index={`${index}2`}
              className={`${classes.tableRowCell} quantity`}
              displayType="text"
              value={product.quantity}
            />
          )
        }
        <QcInputCell
          index={`${index}3`}
          className={`${classes.tableRowCell} total last ${this.props.hasTotalRow && 'grey'}`}
          onValueChange={() => { }}
          value={product.totalPrice}
          currencyValue={qcStore.getCurrencySign()}
          currencyPosition={qcStore.getCurrencyPosition()}
          decimalSeparator={qcStore.getDecimalSeparator()}
          thousandSeparator={qcStore.getThousandSeparator()}
          displayType="text"
          editableButton={editable && adminView}
          editableButtonClick={() => this.editTotalPrice(index)}
          editableButtonText={qcStore.getText('QCProductSelectionProductTableEditButton')}
          decimalScale={2}
          fixedDecimalScale
        />
      </TableRow>
    );
  }

  getKitFormat = (product) => {
    const { qcStore } = this.props;
    const value = product.product.kitVolume / product.product.kitSize;
    const valueString = String(value).replace('.', qcStore.getDecimalSeparator());
    if (product.product.id !== undefined) {
      return `${product.product.kitSize} ${qcStore.getText('QcKitMultiplier')} ${valueString} ${qcStore.getText('QcKitUnit')}`;
    }
    return '';
  }

  getTableTotalRow(products) {
    const { classes, qcStore } = this.props;
    let totalQuantity = 0;
    let totalSum = 0;
    if (products) {
      products.map((product) => {
        totalQuantity += product.quantity;
        totalSum += product.totalPrice;
      });
    }
    return (
      <TableRow className={classes.tableTotalRow}>
        <TableCell colSpan={4} className={`${classes.tableTotalRowCell} first`}>
          {qcStore.getText('QCProdcutSelectionComparisonViewTotalTableBottomline')}
        </TableCell>
        <QcInputCell
          index={`${products.legth}0`}
          className={`${classes.tableTotalRowCell} quantity grey`}
          value={totalQuantity}
          decimalSeparator={qcStore.getDecimalSeparator()}
          thousandSeparator={qcStore.getThousandSeparator()}
          displayType="text"
        />
        <QcInputCell
          index={`${products.legth}1`}
          className={`${classes.tableTotalRowCell} last grey`}
          onValueChange={() => { }}
          value={totalSum}
          currencyValue={qcStore.getCurrencySign()}
          currencyPosition={qcStore.getCurrencyPosition()}
          decimalSeparator={qcStore.getDecimalSeparator()}
          thousandSeparator={qcStore.getThousandSeparator()}
          displayType="text"
          decimalScale={2}
          fixedDecimalScale
        />
      </TableRow>
    );
  }

  getTable(products) {
    const { classes, qcStore, showKitFormat, showDiscountedPricePerUnit, hasTotalRow } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow className={classes.tableHeadRow}>
            <TableCell className={`${classes.tableHeadCell} productCode`}>{qcStore.getText('QCProdcutSelectionComparisonViewCatalogNumberTableHeadline')}</TableCell>
            <TableCell className={`${classes.tableHeadCell} product ${classes.tableColumnProducts}`}>{qcStore.getText('QCProdcutSelectionComparisonViewProductsTableHeadline')}</TableCell>
            <TableCell className={`${classes.tableHeadCell} pricePerUnit`}>{qcStore.getText('QCProdcutSelectionComparisonViewPricePerUniTableHeadline')}</TableCell>
            {(this.state.discountIsActive || showDiscountedPricePerUnit) && <TableCell className={`${classes.tableHeadCell} discountedPricePerUnit`}>{qcStore.getText('QCProdcutSelectionComparisonViewDiscountedPricePerUnitTableHeadline')}</TableCell>}
            {showKitFormat && <TableCell className={`${classes.tableHeadCell} kitFormat`}>{qcStore.getText('QCProdcutSelectionComparisonViewKitFormatTableHeadline')}</TableCell>}
            <TableCell className={`${classes.tableHeadCell} quantity`}>{qcStore.getText('QCProdcutSelectionComparisonViewQuantityTableHeadline')}</TableCell>
            <TableCell className={`${classes.tableHeadCell} total last`}>{qcStore.getText('QCProdcutSelectionComparisonViewTotalTableHeadline')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product, index) => (
            this.getTableRow(product, index)
          ))}
          {hasTotalRow && this.getTableTotalRow(products)}
        </TableBody>
      </Table>
    );
  }

  editDiscountedPricePerUnit = (index) => {
    this.props.editDiscountedPricePerUnit(index);
  }

  editTotalPrice = (index) => {
    this.props.editTotalPrice(index);
  }

  productValueChange = (index, value, type) => {
    this.props.productValueChanged(index, value, type);
  }

  render() {
    const { qcStore, classes, data } = this.props;
    return (
      <div className={classes.root}>
        <div className={classes.tableContainer}>
          {this.getTable(data === undefined ? qcStore.productList : data)}
        </div>
      </div>
    );
  }
}

QcProductTable.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  qcStore: PropTypes.object.isRequired,
  editProduct: PropTypes.func,
  deleteProduct: PropTypes.func,
  showKitFormat: PropTypes.bool,
  showDiscountedPricePerUnit: PropTypes.bool,
  adminView: PropTypes.bool,
  editDiscountedPricePerUnit: PropTypes.func,
  editable: PropTypes.bool,
  editTotalPrice: PropTypes.func,
  data: PropTypes.array,
  pricePerUnitInput: PropTypes.bool,
  productValueChanged: PropTypes.func,
  hasTotalRow: PropTypes.bool,
  objRef: PropTypes.func,
  showDiscountedPriceWhenActive: PropTypes.bool,
  editAnalytes: PropTypes.func
};

QcProductTable.defaultProps = {
  editProduct: undefined,
  deleteProduct: undefined,
  showKitFormat: false,
  showDiscountedPricePerUnit: false,
  adminView: false,
  editDiscountedPricePerUnit: () => { },
  editable: false,
  editTotalPrice: () => { },
  data: undefined,
  pricePerUnitInput: false,
  productValueChanged: () => { },
  hasTotalRow: false,
  objRef: () => { },
  showDiscountedPriceWhenActive: false,
  editAnalytes: undefined
};

export default withStyles(styles)(QcProductTable);
