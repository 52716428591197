import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { action, observable, autorun } from 'mobx';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Typography from '@material-ui/core/Typography';
import simappColors from 'shared/lib/theme/simapp-colors';
import PageScroller from 'web/components/_ui/page-scroller';
import { getApiBase } from 'shared/lib/helpers';
import ModalHeader from 'web/components/_ui/modal-header';

const styles = {
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: simappColors.palerGrey,
  },
  pageScroller: {
    margin: '1rem 2rem',
  },
  navigationButtons: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    display: 'flex',
    flexDirection: 'column'
  },
  itemWrapper: {
    width: '100%',
    height: '100%',
    margin: 'auto',
    overflow: 'hidden',
    position: 'relative',
  },
  iframe: {
    backgroundColor: '#fff',
    width: 1024,
    height: 768,
    transform: 'scale(0)',
    transformOrigin: 'left top',
    overflow: 'hidden'
  },
  title: {
    position: 'absolute',
    bottom: 0,
    height: '3em'
  }
};

@inject('presentationStore', 'contentPieceStore') @observer
class PresentationPreview extends Component {
  @observable scale = 0;
  @observable height = 0;
  @observable width = 0;

  disposer = autorun(() => {
    // eslint-disable-next-line no-unused-vars
    const index = this.props.presentationStore.previewStartIndex; // only used for mobx observation
    this.goToPage();
  }, { onError: (e) => { console.log(e); } });

  constructor(props) {
    super(props);
    this.root = React.createRef();
  }

  componentDidUpdate() {
    this.goToPage();
    this.setScale();
    window.addEventListener('resize', this.setScale);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setScale);
  }

  @action setScale = () => {
    if (this.root.current === null) return;
    const scale1 = (this.root.current.offsetHeight - 120) / 768;
    const scale2 = (this.root.current.offsetWidth - 50) / 1024;
    let scale = scale1 < scale2 ? scale1 : scale2;
    if (scale > 1) scale = 1;
    if (scale !== this.scale) {
      this.scale = scale;
      this.height = this.scale * 768 + 60;
      this.width = this.scale * 1024;
    }
  }

  goToPage = () => {
    if (typeof this.pageScroller !== 'undefined' && this.pageScroller !== null && this.props.presentationStore.previewStartIndex !== null) {
      this.pageScroller.goToPage(this.props.presentationStore.previewStartIndex);
      this.props.presentationStore.clearStartIndex();
    }
  }

  gotoNextPage = () => {
    if (typeof this.pageScroller !== 'undefined' && this.pageScroller !== null) {
      this.pageScroller.gotoNextPage();
    }
  }

  gotoPreviousPage = () => {
    if (typeof this.pageScroller !== 'undefined' && this.pageScroller !== null) {
      this.pageScroller.gotoPreviousPage();
    }
  }

  render() {
    const { classes, presentationStore, contentPieceStore } = this.props;

    if (presentationStore.previewPresentation !== true) {
      return (<Fragment />);
    }
    return (
      <div
        className={`${classes.root} ${this.props.className}`}
        ref={this.root}
      >
        <ModalHeader
          handleClose={presentationStore.stopPreviewPresentation}
        />
        <div className={classes.pageScroller}>
          <PageScroller containerWidth="100%" ref={(c) => { this.pageScroller = c; }}>
            { presentationStore.contentPieces.map(cp => (
              <div className={classes.itemWrapper} key={`p:${cp.item.id}`} style={{ height: `${this.height}px`, width: `${this.width}px` }}>
                <iframe src={`${getApiBase()}/browser/sharing_content_piece/${cp.item.id}/`} title={cp.item.title} scrolling="no" frameBorder="0" className={classes.iframe} style={{ transform: `scale(${this.scale})` }} />
                <Typography variant="body2" className={classes.title}>{cp.item.title}<br /><i>{cp.item.description}</i></Typography>
              </div>
            ))}
          </PageScroller>
          <div className={classes.navigationButtons}>
            <Button title="Previous" onClick={this.gotoPreviousPage} disabled={contentPieceStore.currentSlideIndex === 0}>
              <KeyboardArrowUp />
            </Button>
            <Button title="Next" onClick={this.gotoNextPage} disabled={contentPieceStore.currentSlideIndex === presentationStore.contentPieces.length - 1}>
              <KeyboardArrowDown />
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

PresentationPreview.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  presentationStore: PropTypes.object.isRequired,
  contentPieceStore: PropTypes.object.isRequired,
  className: PropTypes.string
};

PresentationPreview.wrappedComponent.defaultProps = {
  className: ''
};

export default withStyles(styles)(PresentationPreview);
