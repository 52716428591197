/* eslint-disable */
export default () => {
  self.addEventListener('message', (e) => {
    const getVolumeForLevel = (level, volume, workingDaysPerWeek) => {
      if (level.period === 'daily') {
        return level.nbRuns * workingDaysPerWeek * 52 * volume;
      } if (level.period === 'weekly') {
        return level.nbRuns * 52 * volume;
      } if (level.period === 'monthly') {
        return level.nbRuns * 12 * volume;
      }
      return 0;
    };

    const isProductForTestAvailable = (productList, id, level) => productList.filter(product => product.analyteIds.filter(analyteId => analyteId === id && (level ? product.levels.filter(levelObject => levelObject.level === level).length > 0 : true)).length).length;

    const getVolumeValues = (instrumentsData, productList, workingDaysPerWeek) => {
      const volume = {};
      const noProductList = [];
      instrumentsData.map((item) => {
        const tests = item.tests.filter(test => (test.selectedReagentCatalogNumber !== "" && test.isEnabled));
        tests.map((test) => {
          let levels = test.levels.filter(level => level.isEnabled && level.nbRuns > 0);
          if (volume[test.id] === undefined) {
            if (test.isLevelsSeparated) {
              for (let i = 0; i < levels.length; i++) {
                if (isProductForTestAvailable(productList, test.id, levels[i].level)) {
                  volume[test.id] = {};
                  break;
                }
              }
            } else if (isProductForTestAvailable(productList, test.id)) {
              volume[test.id] = {};
            }
          }
          if (volume[test.id]) {
            levels.map((level) => {
              if (volume[test.id][level.level] === undefined) {
                if (isProductForTestAvailable(productList, test.id, level.level)) {
                  volume[test.id][level.level] = getVolumeForLevel(level, test.reagents.find(reagent => test.selectedReagentCatalogNumber === reagent.catalogNumber).volumeUsage, workingDaysPerWeek);
                } else {
                  noProductList.push({ testItem: test, levelItem: level });
                }
              } else {
                if (isProductForTestAvailable(productList, test.id, level.level)) {
                  volume[test.id][level.level] += getVolumeForLevel(level, test.reagents.find(reagent => test.selectedReagentCatalogNumber === reagent.catalogNumber).volumeUsage, workingDaysPerWeek);
                } else {
                  noProductList.push({ testItem: test, levelItem: level });
                }
              }
            });
          } else {
            levels.map((level) => {
              noProductList.push({ testItem: test, levelItem: level });
            });
          }
        });
      });
      return { volume, noProductList };
    };

    const getVolumeValuesFromCompetitorProducts = (competitorProductList, productList) => {
      const volume = {};
      competitorProductList.map((competitorProduct) => {
        if (competitorProduct.quantity > 0) {
          const competitorProductVolumePerAnalyte = competitorProduct.product.kitVolume * competitorProduct.quantity / (competitorProduct.activeAnalytes.length * competitorProduct.product.levels.length);
          competitorProduct.activeAnalytes.map((id) => {
            competitorProduct.product.levels.map((level) => {
              if (isProductForTestAvailable(productList, id, level.level)) {
                if (volume[id] === undefined) {
                  volume[id] = {};
                }
                if (volume[id][level] !== undefined) {
                  volume[id][level.level] += competitorProductVolumePerAnalyte;
                } else {
                  volume[id][level.level] = competitorProductVolumePerAnalyte;
                }
              }
            });
          });
        }
      });
      return { volume };
    };

    const addReserveAmount = (volume) => {
      Object.keys(volume).map((keyTest) => {
        Object.keys(volume[keyTest]).map((keyLevel) => {
          volume[keyTest][keyLevel] *= 1.0;
        });
      });
    };

    const getNumberOfTests = (tests) => {
      let i = 0;
      Object.keys(tests).map((keyTest) => {
        Object.keys(tests[keyTest]).map(() => {
          i++;
        });
      });
      return i;
    };

    const findCombination = (tests, testIndex, levelIndex, prodIndex, results, productList) => {
      let testIndexValue = testIndex;
      let levelIndexValue = levelIndex;
      let prodIndexValue = prodIndex;
      let firstProduct = true;
      for (; Object.keys(tests).indexOf(testIndexValue) > -1;) {
        const isAssayed = results.find(product => product.product.name.indexOf('Assayed') > 0) !== undefined ? productList[prodIndexValue].name.indexOf('Unassayed') === -1 : true;
        const isUnassayed = results.find(product => product.product.name.indexOf('Unassayed') > 0) !== undefined ? productList[prodIndexValue].name.indexOf('Assayed') === -1 : true;
        if (Object.keys(tests[testIndexValue]).indexOf(levelIndexValue) > -1
          && prodIndexValue < productList.length
          && productList[prodIndexValue].analyteIds.includes(testIndexValue)
          && productList[prodIndexValue].levels.filter(level => level.level === levelIndexValue).length > 0
          && isAssayed && isUnassayed) {
          results.push({ product: productList[prodIndexValue], volumes: tests[testIndexValue], test: testIndexValue, level: levelIndexValue });
          levelIndexValue = Object.keys(tests[testIndexValue])[Object.keys(tests[testIndexValue]).indexOf(levelIndexValue) + 1];
          prodIndexValue = prodIndex;
          firstProduct = true;
        } else if (Object.keys(tests).indexOf(testIndexValue) < Object.keys(tests).length && Object.keys(tests[testIndexValue]).indexOf(levelIndexValue) < 0) {
          testIndexValue = Object.keys(tests)[Object.keys(tests).indexOf(testIndexValue) + 1];
          if (testIndexValue === undefined) {
            levelIndexValue = undefined;
          } else {
            levelIndexValue = Object.keys(tests[testIndexValue])[0];
          }
          prodIndexValue = prodIndex;
          firstProduct = true;
        } else if (prodIndexValue === prodIndex && !firstProduct) {
          if (Object.keys(tests).indexOf(testIndexValue) < Object.keys(tests).length && Object.keys(tests[testIndexValue]).indexOf(levelIndexValue) < tests[testIndexValue].length) {
            levelIndexValue = Object.keys(tests[testIndexValue])[Object.keys(tests[testIndexValue]).indexOf(levelIndexValue) + 1];
            prodIndexValue = prodIndex;
            firstProduct = true;
          } else {
            testIndexValue = Object.keys(tests)[Object.keys(tests).indexOf(testIndexValue) + 1];
            if (testIndexValue === undefined) {
              levelIndexValue = undefined;
            } else {
              levelIndexValue = Object.keys(tests[testIndexValue])[0];
            }
            prodIndexValue = prodIndex;
            firstProduct = true;
          }
        } else {
          prodIndexValue++;
          if (prodIndexValue >= productList.length) {
            prodIndexValue = 0;
          }
        }
        if ((prodIndexValue - 1 === prodIndex || (prodIndex === productList.length - 1 && prodIndexValue - 1 === 0)) && firstProduct) {
          firstProduct = false;
        }
      }
      if (results.length < getNumberOfTests(tests)) {
        return [];
      }
      return results;
    };

    const findProductCombinations = (volume, productList) => {
      const combinations = [];
      for (let i = 0; i < productList.length; i++) {
        const foundCombinations = findCombination(volume, Object.keys(volume)[0], Object.keys(volume[Object.keys(volume)[0]])[0], i, [], productList);
        if (foundCombinations.length !== 0) {
          combinations.push({ combination: foundCombinations });
        }
      }
      return combinations;
    };

    const extractProductsAndVolumes = (combinations) => {
      combinations.map((combination) => {
        combination.products = [];
        combination.combination.map((product) => {
          if (combination.products.find(productItem => productItem.product.analyteIds.includes(product.test)
            && !productItem.level.includes(product.level)
            && productItem.product.levels.filter(level => level.level === product.level).length > 0
            && productItem.product.multiPack) !== undefined) {
            const foundProduct = combination.products.find(productItem => productItem.product.analyteIds.includes(product.test)
              && !productItem.level.includes(product.level)
              && productItem.product.levels.filter(level => level.level === product.level).length > 0
              && productItem.product.multiPack);
            if (foundProduct.level.find(level => level === product.level) === undefined) {
              foundProduct.level.push(product.level);
              foundProduct.volume[product.level] = product.volumes[product.level];
            } else {
              foundProduct.volume[product.level] += product.volumes[product.level];
            }
          } else if (combination.products.find(productItem => productItem.product.partNumber === product.product.partNumber) !== undefined) {
            const foundProduct = combination.products.find(productItem => productItem.product.partNumber === product.product.partNumber);

            foundProduct.volume[product.level] += product.volumes[product.level];
          } else {
            const item = {};
            item.product = product.product;
            item.level = [];
            item.level.push(product.level);
            item.volume = {};
            item.volume[product.level] = product.volumes[product.level];
            combination.products.push(item);
          }
        });
      });
    };

    const calculateQuantity = (combinations, contractPeriodInMonths) => {
      combinations.map((combination) => {
        combination.products.map((product) => {
          if (product.product.multiPack) {
            let volume = 0;
            let kitSize = 0;
            let bottleVolume = 0;
            product.level.map((level) => {
              if (volume < product.volume[level]) {
                volume = product.volume[level];
                kitSize = product.product.levels.find(levelObject => levelObject.level === level).numberOfBottles;
                bottleVolume = product.product.levels.find(levelObject => levelObject.level === level).volumePerBottle;
              }
            });
            product.quantity = Math.ceil(volume / (bottleVolume * kitSize * 1000));
            if (Math.ceil((volume + (product.quantity * kitSize * 300)) / (bottleVolume * kitSize * 1000)) !== product.quantity) {
              product.quantity = Math.ceil((volume + (product.quantity * kitSize * 300)) / (bottleVolume * kitSize * 1000));
            }
          } else {
            product.quantity = Math.ceil(product.volume[product.level] / (product.product.kitVolume * 1000));
            if (Math.ceil((product.volume[product.level] + (product.quantity * product.product.kitSize * 300)) / (product.product.kitVolume * 1000)) !== product.quantity) {
              product.quantity = Math.ceil((product.volume[product.level] + (product.quantity * product.product.kitSize * 300)) / (product.product.kitVolume * 1000));
            }
          }
          product.quantity = Math.ceil(product.quantity * contractPeriodInMonths / 12);
        });
      });
    };

    const calculateQuantityFromCompetitorProducts = (combinations, contractPeriodInMonths) => {
      combinations.map((combination) => {
        combination.products.map((product) => {
          if (product.product.multiPack) {
            let volume = 0;
            let kitSize = 0;
            let bottleVolume = 0;
            product.level.map((level) => {
              if (volume < product.volume[level]) {
                volume = product.volume[level];
                kitSize = product.product.levels.find(levelObject => levelObject.level === level).numberOfBottles;
                bottleVolume = product.product.levels.find(levelObject => levelObject.level === level).volumePerBottle;
              }
            });
            product.quantity = Math.ceil(volume / (bottleVolume * kitSize) * contractPeriodInMonths / 12);
          } else {
            product.quantity = Math.ceil(product.volume[product.level] / product.product.kitVolume * contractPeriodInMonths / 12);
          }
        });
      });
    };

    const setTotalPrice = (combinations, priceIso) => {
      combinations.map((combination) => {
        combination.totalPrice = 0;
        combination.products.map((product) => {
          product.pricePerUnit = product.product.listPrice[priceIso];
          product.discountedPricePerUnit = product.product.listPrice[priceIso];
          product.totalPrice = product.pricePerUnit * product.quantity;
          combination.totalPrice += product.totalPrice;
        });
      });
    };

    const getCheapestCombination = (combinations) => {
      let cheapest;
      combinations.map((combination) => {
        if (cheapest === undefined) {
          cheapest = combination;
        } else if (cheapest.totalPrice > combination.totalPrice) {
          cheapest = combination;
        }
      });
      return cheapest;
    };

    const getSmallestSKU = (combinations) => {
      let lessSKU;
      combinations.map((combination) => {
        if (lessSKU === undefined) {
          lessSKU = combination;
        } else if (lessSKU.products.length > combination.products.length || (lessSKU.products.length === combination.products.length && lessSKU.totalPrice > combination.totalPrice)) {
          lessSKU = combination;
        }
      });
      return lessSKU;
    };

    const getSmallestQuantity = (combinations) => {
      let smallestAmount;
      let smallestQuantity;
      combinations.map((combination) => {
        if (smallestAmount === undefined) {
          let quantity = 0;
          combination.products.map((product) => {
            quantity += product.quantity;
          });
          smallestQuantity = quantity;
          smallestAmount = combination;
        } else {
          let quantity = 0;
          combination.products.map((product) => {
            quantity += product.quantity;
          });
          if (quantity < smallestQuantity) {
            smallestQuantity = quantity;
            smallestAmount = combination;
          }
        }
      });
      return smallestAmount;
    };

    // eslint-disable-line no-restricted-globals
    if (!e || e.data === undefined) return;
    const data = JSON.parse(e.data);
    let volume;
    let combinations;
    let volumeResult;
    const result = {};
    result.noProducts = false;
    if (data.isTestView) {
      volumeResult = getVolumeValues(data.instrumentsData, data.productList, data.workingDaysPerWeek);
      volume = volumeResult.volume;
      if (Object.keys(volume).length) {
        addReserveAmount(volume);
        combinations = findProductCombinations(volume, data.productList);
        extractProductsAndVolumes(combinations);
        calculateQuantity(combinations, data.contractPeriodInMonths);
      } else {
        result.noProducts = true;
      }
    } else {
      volumeResult = getVolumeValuesFromCompetitorProducts(data.competitorProductList, data.productList);
      volume = volumeResult.volume;
      if (Object.keys(volume).length) {
        combinations = findProductCombinations(volume, data.productList);
        extractProductsAndVolumes(combinations);
        calculateQuantityFromCompetitorProducts(combinations, data.contractPeriodInMonths);
      } else {
        result.noProducts = true;
      }
    }
    result.noProductsList = volumeResult.noProductList;
    if (Object.keys(volume).length) {
      setTotalPrice(combinations, data.priceIso);
      result.cheapest = getCheapestCombination(combinations).products;
      result.smallestQuantity = getSmallestQuantity(combinations).products;
      result.smallestSKU = getSmallestSKU(combinations).products;
    }

    postMessage(JSON.stringify(result));
  });
};
