import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

@inject('languageStore') @observer
class LanguagesFilter extends Component {
  @observable open = false;
  disabledLanguages = {};

  onFilterSet = (e) => {
    this.props.languageStore.setFilter(e.target.value);
  };

  @action onHandleOpen = () => {
    this.disabledLanguages = Object.assign(this.props.languageStore.disabledLanguages);
    this.open = true;
  }

  @action onHandleClose = () => {
    this.open = false;
  }

  languageFilter = () => this.props.languageStore.languages.map((l) => {
    /**
     * Previously, we just disabled menu items without content using
     *  disabled={this.props.languageStore.disabledLanguages[l.id]}
     * Now they are hidden
     */
    if (this.disabledLanguages[l.id]) return null;
    return (
      <MenuItem key={l.id} value={l.id}>
        <Checkbox checked={this.props.languageStore.filteredLanguagesForUI.indexOf(l.id) > -1} color="primary" />
        <ListItemText primary={l.name} />
      </MenuItem>
    );
  });

  render() {
    const { languageStore, MenuProps } = this.props;
    return (
      <Select
        multiple
        displayEmpty
        value={languageStore.filteredLanguagesForUI}
        onChange={this.onFilterSet}
        disableUnderline
        renderValue={() => 'Language'}
        MenuProps={MenuProps}
        open={this.open}
        onClose={this.onHandleClose}
        onOpen={this.onHandleOpen}
      >
        {this.languageFilter()}
      </Select>
    );
  }
}

LanguagesFilter.wrappedComponent.propTypes = {
  languageStore: PropTypes.object.isRequired,
  MenuProps: PropTypes.object.isRequired,
};
export default LanguagesFilter;
