import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { simappConstants } from 'shared/lib/theme';
import CopeptinInputField from 'web/components/copeptin/copeptin-inputfield';
import Typography from '@material-ui/core/Typography';
import copeptinConstants from 'shared/lib/resources/copeptin-constants';
import CopeptinNavButton from './copeptin-navbutton';
import CopeptinDialog from './copeptin-dialog';
import ClickableTextButton from '../_misc/clickableTextButton';

const styles = {
  root: {
    width: '100%',
    height: '100vH',
    display: 'flex',
    flexDirection: 'column',
    padding: simappConstants.contentMarginLarge,
    overflowX: 'hidden',
    fontFamily: 'helvetica-neue-lt, sans-serif'
  },
  borderlessExpansionPanel: {
    '&::before': { content: 'none' },
    marginBottom: '2px',
    boxShadow: 'unset'
  },
  bottomBar: {
    width: '100%',
    marginTop: '30px',
    paddingBottom: '30px'
  },
  textContainer: {
    marginTop: '40px',
    marginBottom: '70px',
    marginLeft: simappConstants.copeptinHorizontalMargin,
    marginRight: simappConstants.copeptinHorizontalMargin,
    textAlign: 'center',
  },
  rightTableCell: {
    textAlign: 'right',
    width: '200px'
  },
  expansionContent: {
    paddingLeft: '10px',
    paddingRight: '0px',
  },
  mediumText: {
    fontWeight: '500'
  },
  table: {
    marginTop: '-15px',
    marginBottom: '-25px',
    paddingLeft: '10px',
    paddingRight: '0px'
  },
  animationlessPanelSummary: {
    minHeight: '48px !important',
    lineHeight: '48px',
    cursor: 'text !important',
    '& div:last-child': {
      padding: '0'
    },
    '& div:first-child': {
      margin: '0'
    },
  },
  borderlessCell: {
    border: 'none'
  },
  referencesLink: {
    marginLeft: '15px'
  }
};

@inject('copeptinStore', 'routing') @observer
class CopeptinPopulation extends Component {
  onValueChangeTotalPatientsValue(index, value) {
    this.props.copeptinStore.setTotalNumberPatientsTotalValue(index, value);
    this.forceUpdate();
  }

  onValueChangePercentageValue(index, value) {
    this.props.copeptinStore.setTotalNumberPatientsPercentageValue(index, value);
    this.forceUpdate();
  }

  getTableRows(copeptinStore, field0, field1) {
    return (
      <TableBody>
        <TableRow key={1}>
          <TableCell>
            <Typography variant="subtitle1" className={this.props.classes.tableCellText}>{copeptinStore.getLocalizedText('CopeptinPatientPopulationScreenTotalPatients')}</Typography>
          </TableCell>
          <TableCell className={this.props.classes.rightTableCell}>
            <CopeptinInputField
              fieldIndex={0}
              isChanged={this.props.copeptinStore.isUserTotalNumberPatientsTotalValue(0)}
              type=""
              onValueChange={(index, value) => this.onValueChangeTotalPatientsValue(index, value)}
              value={field0}
            />
          </TableCell>
        </TableRow>
        <TableRow key={2}>
          <TableCell>
            <Typography variant="subtitle1" className={this.props.classes.tableCellText}>{copeptinStore.getLocalizedText('CopeptinPatientPopulationScreenPercentage')}</Typography>
          </TableCell>
          <TableCell className={this.props.classes.rightTableCell}>
            <CopeptinInputField
              fieldIndex={0}
              isChanged={this.props.copeptinStore.isUserTotalNumberPatientsPercentageValue(0)}
              type={copeptinConstants.CopeptinTableRowPercentage}
              onValueChange={(index, value) => this.onValueChangePercentageValue(index, value)}
              value={field1}
              maxValue={1}
            />
          </TableCell>
        </TableRow>
        <TableRow key={3}>
          <TableCell className={this.props.classes.borderlessCell}>
            <Typography variant="subtitle1" className={this.props.classes.tableCellText}>{copeptinStore.getLocalizedText('CopeptinPatientPopulationScreenSuspectedPatients')}</Typography>
          </TableCell>
          <TableCell className={`${this.props.classes.rightTableCell} ${this.props.classes.borderlessCell}`}>
            <CopeptinInputField
              type=""
              displayType="text"
              decimalScale={0}
              value={Math.ceil(field0 * field1)}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  getTable(copeptinStore) {
    const field0 = this.props.copeptinStore.getTotalNumberPatientsTotalValue(0);
    const field1 = this.props.copeptinStore.getTotalNumberPatientsPercentageValue(0);

    return (
      <div>
        <ExpansionPanel expanded className={this.props.classes.borderlessExpansionPanel}>
          <ExpansionPanelSummary className={this.props.classes.animationlessPanelSummary}>
            <Typography variant="subtitle1" className={this.props.classes.mediumText}>{copeptinStore.getLocalizedText('CopeptinPatientPopulationScreenHeader')}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails key={0} className={this.props.classes.expansionContent}>
            <Table className={this.props.classes.table}>
              {this.getTableRows(copeptinStore, field0, field1)}
            </Table>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }

  getBottomBar(push) {
    return (
      <div className={this.props.classes.bottomBar}>
        <ClickableTextButton
          isBold
          className={this.props.classes.referencesLink}
          onClick={() => push('/he-models/copeptin-calculator/copeptin-references')}
          text={this.props.copeptinStore.getLocalizedText('copeptinReferences')}
        />
        <CopeptinNavButton
          nextOnClick={location => this.props.routing.push(location)}
          nextOnClickLocation="/he-models/copeptin-calculator/inputs"
          backOnClick={() => {
            this.props.copeptinStore.setLocation(this.props.routing.location.pathname);
            push('/he-models/copeptin-calculator');
          }}
          screenNumber={1}
        />
      </div>
    );
  }

  render() {
    const { push } = this.props.routing;
    const { copeptinStore } = this.props;

    const result = (
      <div className={this.props.classes.root}>
        <div>
          {this.getTable(copeptinStore)}
          {this.getBottomBar(push)}
        </div>
        <CopeptinDialog />
      </div>
    );
    return result;
  }
}

CopeptinPopulation.wrappedComponent.propTypes = {
  copeptinStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired,
};

export default withStyles(styles)(CopeptinPopulation);
