import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { simappConstants, simappColors } from 'shared/lib/theme';
import Typography from '@material-ui/core/Typography';
import pctConstants from 'shared/lib/resources/pct-constants';
import TextLink from '../_misc/textlink';
import PctNavButton from './pct-navbutton';
import ClickableTextButton from '../_misc/clickableTextButton';
import AlertDialog from '../_misc/alert-dialog';
import Popover from '../_misc/popover';

const constants = simappConstants;
const colors = simappColors;

const styles = {
  root: {
    width: '100%',
    height: '100vH',
    display: 'flex',
    flexDirection: 'column',
    padding: constants.contentMarginLarge,
    overflowX: 'hidden',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontWeight: 'normal'
  },
  bottomBar: {
    width: '100%',
    marginTop: '30px'
  },
  textContainer: {
    marginTop: '40px',
    marginBottom: '70px',
    marginLeft: constants.pctHorizontalMargin,
    marginRight: constants.pctHorizontalMargin,
    paddingRight: '10px'
  },
  textBlock: {
    marginBottom: '20px',
    lineHeight: '20px',
    '&.marginBottom': {
      marginBottom: '35px'
    },
    '&.marginTop': {
      marginTop: '35px'
    },
    color: 'black'
  },
  table: {
    marginLeft: constants.pctHorizontalMargin,
    marginRight: constants.pctHorizontalMargin,
    width: '85%',
    marginBottom: '40px',
  },
  button: {
    width: '105px',
    borderRadius: 58,
    background: colors.DefaultButtonColor,
    borderWidth: 1.4,
    borderStyle: 'solid',
    borderColor: colors.DefaultButtonBorderColor,
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontWeight: 500,
    justifyContent: 'center',
    alignItems: 'center',
  },
  helpLinkContainer: {
    marginLeft: '8px',
    paddingRight: '10px',
    display: 'flex',
    flexShrink: 0,
    height: 46,
    alignItems: 'center',
    '& :last-child': {
      marginLeft: 'auto',
    }
  },
  referencesLink: {
    marginLeft: '15px'
  },
  divInfoLink: {
    marginRight: '15px',
    float: 'right'
  },
  divInfoLinks: {
    marginRight: '15px',
    display: 'inline-block',
    float: 'right'
  },
  serialDiv: {
    paddingLeft: '16px'
  },
  listColumn: {
    verticalAlign: 'top',
    '& p': {
      marginTop: '0',
      lineHeight: '20px',
    }
  },
  usTable: {
    marginBottom: '35px'
  },
  helpLink: {
    width: '112px',
    marginTop: '38px',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'row',
    color: colors.azul,
    cursor: 'pointer',
  },
  topRowContainer: {
    display: 'flex',
    alignItems: 'flexStart',
    color: colors.azul,
    cursor: 'pointer',
  },
  popoverText: {
    fontWeight: 'normal',
    marginBottom: '12px',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontSize: '17px',
  },
  popoverLinkText: {
    marginBottom: '12px',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontSize: '17px',
    color: colors.azul,
  },
};

@inject('applicationStore', 'pctStore', 'routing') @observer
class PctStart extends Component {
  lastSelectionLanguage;
  lastSelectionDataSet;

  constructor(props) {
    super(props);
    this.state = { showAlert: false, showStartNotification: false };
  }

  getOptions(pctStore) {
    return (
      <Grid container direction="row">
        <Grid container direction="row" className={this.props.classes.table} alignItems="center" justify="center">
          <Grid item xs><Typography variant="body1">{pctStore.getText('SelectLanguage')}</Typography></Grid>
          <Grid item xs>
            <Button
              id={`${pctConstants.LanguageKey}_en`}
              onClick={() => this.saveValue(pctConstants.LanguageKey, pctConstants.LanguageCodeEnglish)}
              className={this.props.classes.button}
            >
              {pctStore.getText('LanguageEn')}
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              id={`${pctConstants.LanguageKey}_de`}
              onClick={() => this.saveValue(pctConstants.LanguageKey, pctConstants.LanguageCodeGerman)}
              className={this.props.classes.button}
            >{pctStore.getText('LanguageDe')}
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              id={`${pctConstants.LanguageKey}_fr`}
              onClick={() => this.saveValue(pctConstants.LanguageKey, pctConstants.LanguageCodeFrench)}
              className={this.props.classes.button}
            >{pctStore.getText('LanguageFr')}
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              id={`${pctConstants.LanguageKey}_es`}
              onClick={() => this.saveValue(pctConstants.LanguageKey, pctConstants.LanguageCodeSpanish)}
              className={this.props.classes.button}
            >{pctStore.getText('LanguageEs')}
            </Button>
          </Grid>
          <Grid item xs />
          <Grid item xs />
          <Grid item xs />
        </Grid>
        <Grid container direction="row" className={this.props.classes.table} alignItems="center" justify="center">
          <Grid item xs><Typography variant="body1">{pctStore.getText('SelectDataSet')}</Typography></Grid>
          <Grid item xs><Button id={`${pctConstants.DataSetKey}_uk`} onClick={() => this.saveValue(pctConstants.DataSetKey, pctConstants.CountryCodeUK)} className={this.props.classes.button}>{pctStore.getText('DataSetUK')}</Button></Grid>
          <Grid item xs><Button id={`${pctConstants.DataSetKey}_de`} onClick={() => this.saveValue(pctConstants.DataSetKey, pctConstants.CountryCodeDE)} className={this.props.classes.button}>{pctStore.getText('DataSetGermany')}</Button></Grid>
          <Grid item xs />
          <Grid item xs />
          <Grid item xs><Button id={`${pctConstants.DataSetKey}_nl`} onClick={() => this.saveValue(pctConstants.DataSetKey, pctConstants.CountryCodeNL)} className={this.props.classes.button}>{pctStore.getText('DataSetNetherlands')}</Button></Grid>
          <Grid item xs><Button id={`${pctConstants.DataSetKey}_none`} onClick={() => this.saveValue(pctConstants.DataSetKey, pctConstants.CountryCodeNone)} className={this.props.classes.button}>{pctStore.getText('DataSetNone')}</Button></Grid>
          <Grid item xs />
        </Grid>
      </Grid>
    );
  }

  getHelpLink() {
    return (
      <div>
        <Popover
          triggerNode={(
            <Typography
              variant="body1"
              className={this.props.classes.helpLink}
            >
              {this.props.pctStore.getText('PCTHelpLink')}
            </Typography>
          )}
          trigger="click"
        >
          <div>
            <Typography
              variant="body1"
              className={this.props.classes.popoverText}
            >
              {this.props.pctStore.getText('PCTHelpText')}
            </Typography>
            <a
              className={this.props.classes.popoverLinkText}
              href={this.props.pctStore.getText('PCTHelpMailtoLink')}
            >
              {this.props.pctStore.getText('PCTHelpMailText')}
            </a>
          </div>
        </Popover>
      </div>
    );
  }

  getBottomBar(pctStore, isUS, push) {
    return (
      <div className={this.props.classes.bottomBar}>
        <div>
          <ClickableTextButton
            className={this.props.classes.referencesLink}
            onClick={() => push('/he-models/pct-calculator/references')}
            text={pctStore.getText('References')}
            isBold={isUS}
          />
          {isUS
            ? (
              <div className={this.props.classes.divInfoLinks}>
                <ClickableTextButton
                  onClick={
                    () => this.navigateToInfoPage(push, '/he-models/pct-calculator/pct-algorithm-sepsis', pctStore.getText('BrahmsPctAlgorithmHEUS'), 'PCTAlgorithmHEUS.jpg')
                  }
                  text={pctStore.getText('BrahmsPctAlgorithm')}
                  isBold
                />
              </div>
            )
            : (
              <div className={this.props.classes.divInfoLink}>
                <ClickableTextButton
                  onClick={
                    () => this.navigateToInfoPage(push, '/he-models/pct-calculator/pct-algorithm-sepsis', pctStore.getText('BrahmsPctAlgorithmInfoTitle'), 'pctAlgorithmSepsis.png')
                  }
                  text={pctStore.getText('BrahmsPctAlgorithm')}
                />
              </div>
            )}
        </div>
        <PctNavButton nextOnClick={() => this.navigate(push, isUS)} nextText={pctStore.getText('PctStart')} />
        {isUS
          ? (
            <div className={this.props.classes.serialDiv}>
              <p>{pctStore.getText('PctUSSerial')}</p>
            </div>
          )
          : null}
        <AlertDialog trigger={this.state.showAlert} title={pctStore.getText('PctNoSelectionAlertTitle')} message={pctStore.getText('PctNoSelectionAlertMessage')} close={() => { this.setState({ showAlert: false }); }} />
        <AlertDialog
          trigger={this.state.showStartNotification}
          title=""
          message={pctStore.getText('PctStartAlertMessage')}
          buttonText="Yes"
          close={() => {
            this.setState({ showStartNotification: false });
          }}
          onClick={() => {
            this.setState({ showStartNotification: false });
            pctStore.readJSONData();
            push('/he-models/pct-calculator/population');
          }}
        />
      </div>
    );
  }

  navigateToInfoPage(push, target, title, imageName) {
    this.props.pctStore.setCalculatorInformation(imageName, title);
    push(target);
  }

  markSelection(key, value) {
    const currentSelection = document.getElementById(`${key}_${value}`);
    if (currentSelection === undefined || currentSelection === null) {
      return;
    }
    const lastSelection = key === pctConstants.LanguageKey ? this.lastSelectionLanguage : this.lastSelectionDataSet;
    if (lastSelection !== undefined) {
      lastSelection.style.backgroundColor = colors.DefaultButtonColor;
      lastSelection.style.borderColor = colors.DefaultButtonBorderColor;
    }
    currentSelection.style.backgroundColor = colors.SelectedButtonColor;
    currentSelection.style.borderColor = colors.SelectedButtonColor;
    if (key === pctConstants.LanguageKey) {
      this.lastSelectionLanguage = currentSelection;
    } else {
      this.lastSelectionDataSet = currentSelection;
    }
  }

  saveValue(key, value) {
    this.markSelection(key, value);
    if (key === pctConstants.DataSetKey) {
      this.props.pctStore.setDataSet(value);
    } else {
      this.props.pctStore.setLanguage(value);
    }
  }

  navigate(push, isUS) {
    if (this.canNavigate() || isUS) {
      if (isUS) {
        this.setState({
          showStartNotification: true
        });
      } else {
        this.props.pctStore.readJSONData();
        push('/he-models/pct-calculator/population');
      }
    } else {
      this.setState({ showAlert: true });
    }
  }

  canNavigate() {
    return this.lastSelectionLanguage !== undefined && this.lastSelectionDataSet !== undefined;
  }

  render() {
    const { push } = this.props.routing;
    const { pctStore } = this.props;
    const isUS = this.props.applicationStore.presentingInName === pctConstants.CountryNameUS;
    this.saveValue(pctConstants.LanguageKey, isUS ? pctConstants.LanguageCodeUSA : '');
    this.saveValue(pctConstants.DataSetKey, isUS ? pctConstants.CountryCodeUS : '');

    const pageIntro2 = pctStore.getText('PctStartPageIntro2');
    const bulletPointHead = pctStore.getText('PctStartBulletPointHead');
    const bulletPoint4 = pctStore.getText('StartPageBulletPoint4');
    return (
      <div className={this.props.classes.root}>
        <div>
          <div className={this.props.classes.helpLinkContainer}>
            {this.getHelpLink()}
          </div>
          <div className={this.props.classes.textContainer}>
            <TextLink text={pctStore.getText('PctStartPageIntro1')} className={this.props.classes.textBlock} linkText={pctStore.getText('PctStartPageIntro1LinkText')} onClick={() => push('/he-models/pct-calculator/references#16')} />
            {pageIntro2.length > 0 ? <TextLink variant="body1" className={this.props.classes.textBlock} text={pageIntro2} linkText={pctStore.getText('PctStartPageIntro2LinkText')} linkTarget="https://www.ncbi.nlm.nih.gov/pubmed/31013271" onClick={() => {}} /> : ''}
            {bulletPointHead.length > 0 ? <Typography variant="body1" className={`${this.props.classes.textBlock} marginTop`}>{bulletPointHead}</Typography> : ''}
            {
              isUS
                ? (
                  <table className={this.props.classes.usTable}>
                    <tbody>
                      <tr>
                        <td className={this.props.classes.listColumn}><p>-</p></td><td><TextLink text={pctStore.getText('StartPageBulletPoint1')} className={this.props.classes.textBlock} linkText={pctStore.getText('StartPageBulletPoint1LinkText')} onClick={() => push('/he-models/pct-calculator/references')} /></td>
                      </tr>
                      <tr>
                        <td className={this.props.classes.listColumn}><p>-</p></td><td><TextLink text={pctStore.getText('StartPageBulletPoint2')} className={this.props.classes.textBlock} linkText={pctStore.getText('StartPageBulletPoint2LinkText')} onClick={() => push('/he-models/pct-calculator/references#16')} /></td>
                      </tr>
                      <tr>
                        <td className={this.props.classes.listColumn}><p>-</p></td><td><Typography variant="body1" className={this.props.classes.textBlock}>{pctStore.getText('StartPageBulletPoint3')}</Typography></td>
                      </tr>
                      {bulletPoint4.length > 0 ? <tr><td className={this.props.classes.listColumn}><p>-</p></td><td><Typography variant="body1" className={`${this.props.classes.textBlock}`}>{bulletPoint4}</Typography></td></tr> : ''}
                    </tbody>
                  </table>
                )
                : (
                  <div>
                    <TextLink text={pctStore.getText('StartPageBulletPoint1')} className={this.props.classes.textBlock} linkText={pctStore.getText('StartPageBulletPoint1LinkText')} onClick={() => push('/he-models/pct-calculator/references')} />
                    <TextLink text={pctStore.getText('StartPageBulletPoint2')} className={this.props.classes.textBlock} linkText={pctStore.getText('StartPageBulletPoint2LinkText')} onClick={() => push('/he-models/pct-calculator/references#16')} />
                    <Typography variant="body1" className={this.props.classes.textBlock}>{pctStore.getText('StartPageBulletPoint3')}</Typography>
                    {bulletPoint4.length > 0 ? <Typography variant="body1" className={`${this.props.classes.textBlock} marginBottom`}>{bulletPoint4}</Typography> : ''}
                  </div>
                )
            }
            <Typography variant="body1" className={this.props.classes.textBlock}>{pctStore.getText('PctStartPageIntroEnd')}</Typography>
            <Typography variant="body1" className={this.props.classes.textBlock}>{pctStore.getText('PctStartPageNote')}</Typography>
          </div>
          {isUS ? '' : this.getOptions(pctStore)}
          {this.getBottomBar(pctStore, isUS, push)}
        </div>
      </div>
    );
  }
}

PctStart.wrappedComponent.propTypes = {
  applicationStore: PropTypes.object.isRequired,
  pctStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired,
};

export default withStyles(styles)(PctStart);
