import { action, observable, runInAction } from 'mobx';
import { applicationStore } from 'shared/stores';
import BaseForm from './base-form';

class ForgotPasswordForm extends BaseForm {
  @observable
  fields = {
    username: {
      value: '',
      error: null,
      label: 'Email',
      rule: 'required',
      type: 'email'
    },
  }

  @action resetForm = () => {
    this.fields.username.value = '';
    this.error = null;
  }

  @action save = () => {
    this.saveInProgess = true;
    // send request to backend
    applicationStore.resetPassword(this.fields.username.value.trim().toLowerCase()).then(() => {
      runInAction(() => {
        this.saveInProgess = false;
        this.resetForm();
      });
    });
  }
}

export default new ForgotPasswordForm();
