export default class InputSelectionHelper {
  inputRefs = [];
  currentIndex = 0;

  registerInput(ref) {
    if (this.canAdd(ref)) {
      this.inputRefs.push(ref);
    }
  }

  canAdd(ref) {
    if (ref === null || ref === undefined) {
      return false;
    }
    const refId = ref.id ?? ref.props.id;
    for (let i = 0; i < this.inputRefs.length; i++) {
      const inputRef = this.inputRefs[i] ?? { id: Math.random() };
      if (inputRef.id === ref.id) {
        const currentRefId = inputRef.id ?? inputRef.props.id;
        if (currentRefId === refId) {
          return false;
        }
      }
    }
    return true;
  }

  unregisterFollowing(index) {
    if (index >= 0) {
      this.inputRefs.splice(index, this.inputRefs.length - index);
    }
  }

  updateRegistration(ref, index) {
    if (this.inputRefs.length <= index) {
      this.registerInput(ref);
    } else if (this.inputRefs[index] !== ref) {
      this.inputRefs.splice(index, 1, ref);
    }
  }

  setCurrent(index) {
    this.currentIndex = index;
  }

  keyDown(e) {
    if (e.keyCode === 13 || (e.nativeEvent !== undefined && e.nativeEvent.key === 'Enter')) {
      this.next();
    }
  }

  next() {
    let focusItem;
    while (focusItem === undefined || focusItem === null) {
      focusItem = this.getNext();
    }
    focusItem.focus();
  }

  getNext() {
    if (this.inputRefs.length === 0) {
      return undefined;
    }
    if (this.currentIndex + 1 >= this.inputRefs.length) {
      this.currentIndex = 0;
    } else {
      this.currentIndex++;
    }
    return this.inputRefs[this.currentIndex];
  }
}
