import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';

const styles = {
  popoverContent: {
    display: 'flex',
    left: '-220px',
    backgroundColor: '#fff',
    WebkitBackgroundClip: 'padding-box',
    MozBackgroundClip: 'padding-box',
    backgroundClip: 'padding-box',
    position: 'absolute',
    boxShadow: '0 3px 8px rgba(0, 0, 0, .3)',
    border: '1px solid rgba(0,0,0,.15)',
    borderRadius: '3px',
    outline: '0'
  },
  popoverArrowBackground: {
    content: '',
    position: 'absolute',
    left: '240px',
    top: '-10px',
    borderLeft: '9px solid transparent',
    borderRight: '9px solid transparent',
    borderBottom: '10px solid rgba(0, 0, 0, .15)',
  },
  popoverArrowForeground: {
    content: '',
    position: 'absolute',
    left: '241px',
    top: '-8px',
    marginLeft: '1px',
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderBottom: '8px solid #fff',
  },
  popoverInner: {
    padding: '8px 16px 16px 24px'
  },
};

@inject('routing') @observer
class PopoverContent extends Component {
  componentDidMount() {
    this.node.focus();
    window.document.addEventListener('click', this.handleClickOutSide.bind(this), false);
  }

  componentWillUnmount() {
    this.node.blur();
    window.document.removeEventListener('click', this.handleClickOutSide.bind(this), false);
  }

  onKeyDown(e) {
    if (e.keyCode === 27) {
      e.stopPropagation();
      this.props.onClose();
    }
  }

  handleClickOutSide(e) {
    if (this.node) {
      if (this.props.closeOnClick) {
        this.props.onClose();
      } else if (!this.node.contains(e.target)) {
        this.props.onClickOutSide();
      }
    }
  }

  render() {
    return (
      <div>
        <div
          role="menuitem"
          ref={(node) => { this.node = node; }}
          tabIndex="-1"
          onKeyDown={this.onKeyDown.bind(this)}
          className={`${this.props.classes.popoverContent} ${this.props.className}`}
          style={this.props.style}
        >
          <div className={this.props.classes.popoverInner}>
            {this.props.children}
          </div>
          <div className={`${this.props.classes.popoverArrowBackground} ${this.props.popoverArrowBackground}`} />
          <div className={`${this.props.classes.popoverArrowForeground} ${this.props.popoverArrowForeground}`} />
        </div>
      </div>
    );
  }
}

PopoverContent.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  popoverArrowBackground: PropTypes.string,
  popoverArrowForeground: PropTypes.string,
  closeOnClick: PropTypes.bool,
  onClickOutSide: PropTypes.func,
  onClose: PropTypes.func,
};

PopoverContent.wrappedComponent.defaultProps = {
  style: null,
  closeOnClick: false,
  onClickOutSide: () => { },
  onClose: () => { },
  className: '',
  popoverArrowBackground: '',
  popoverArrowForeground: ''
};

export default withStyles(styles)(PopoverContent);
