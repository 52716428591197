import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  video: {
    width: '100%',
    maxHeight: '100%',
    alignSelf: 'center'
  },
  image: {
    maxWidth: '100%',
    alignSelf: 'center',
    margin: 'auto'
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  }
};

const ShowDocument = (props) => {
  switch (props.material.mimetype.split('/')[0]) {
    case 'video':
      return <video src={props.source} className={props.classes.video} controls />;
    case 'image':
      return <img src={props.source} className={props.classes.image} alt="" />;
    default:
      return <iframe src={`/pdfjs/web/viewer.html?file=${props.source}&name=${props.material.original_filename}`} className={props.classes.iframe} title={props.material.title} />;
  }
};

ShowDocument.propTypes = {
  classes: PropTypes.object.isRequired,
  material: PropTypes.object.isRequired,
  source: PropTypes.any.isRequired,
};

export default withStyles(styles)(ShowDocument);
