import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import simappColors from 'shared/lib/theme/simapp-colors';
import { getApiBase } from 'shared/lib/helpers';

const styles = {
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: simappColors.white,
    display: 'flex',
    flexDirection: 'column'
  },
  pageScroller: {
    margin: '1rem 2rem',
    flex: 1
  },
  itemWrapper: {
    width: '100%',
    height: '100%',
    margin: 'auto',
    overflow: 'hidden',
    position: 'relative',
  },
  iframe: {
    backgroundColor: '#fff',
    width: 1024,
    height: 768,
    transform: 'scale(0)',
    transformOrigin: 'left top',
    overflow: 'hidden',
    border: `1px solid ${simappColors.steelGrey}`,
  },
  title: {
    position: 'absolute',
    bottom: 0,
  }
};

@observer
class PiecePreview extends Component {
  @observable scale = 0;
  @observable height = 0;
  @observable width = 0;

  constructor(props) {
    super(props);
    this.root = React.createRef();
  }

  componentDidMount() {
    this.setScale();
    window.addEventListener('resize', this.setScale);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setScale);
  }

  @action setScale = () => {
    if (this.root.current === null) return;
    const scale1 = (this.root.current.offsetHeight - 160) / 768;
    const scale2 = (this.root.current.offsetWidth - 50) / 1024;
    let scale = scale1 < scale2 ? scale1 : scale2;
    if (scale > 1) scale = 1;
    if (scale !== this.scale) {
      this.scale = scale;
      this.height = this.scale * 768 + 60;
      this.width = this.scale * 1024;
    }
  }

  render() {
    const { classes, piece } = this.props;
    return (
      <div
        className={`${classes.root} ${this.props.className}`}
        ref={this.root}
      >
        <div className={classes.pageScroller}>
          <div className={classes.itemWrapper} key={`p:${piece.id}`} style={{ height: `${this.height}px`, width: `${this.width}px` }}>
            <iframe src={`${getApiBase()}/browser/sharing_content_piece/${piece.id}/`} title={piece.title} scrolling="no" frameBorder="0" className={classes.iframe} style={{ transform: `scale(${this.scale})` }} />
            <Typography variant="body2" className={classes.title}>{piece.title}<br /><i>{piece.description}</i></Typography>
          </div>
        </div>
        <DialogActions>
          <Button onClick={this.props.onClose} variant="outlined" color="primary" className={classes.closeButton}>Back</Button>
          <Button onClick={this.props.onAdd} variant="contained" color="primary">Add</Button>
        </DialogActions>
      </div>
    );
  }
}

PiecePreview.propTypes = {
  classes: PropTypes.object.isRequired,
  piece: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  className: PropTypes.string
};

PiecePreview.defaultProps = {
  className: ''
};

export default withStyles(styles)(PiecePreview);
