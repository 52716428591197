import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import SimAppButton from 'web/components/_ui/simapp-button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { simappConstants } from 'shared/lib/theme';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import InputField from 'web/components/_ui/form-elements/input-field';
import Divider from '@material-ui/core/Divider';
import SelectField from 'web/components/_ui/form-elements/select-field';
import { getItemFromList } from '../../../shared/helper/qc-helper';
import InputSelectionHelper from '../../../shared/helper/input-selection-helper';

const constants = simappConstants;

const styles = {
  root: {
    width: '100%',
    height: '100vH',
    display: 'flex',
    flexDirection: 'column',
    padding: constants.contentMarginLarge,
    overflowX: 'hidden',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontWeight: 'normal'
  },
  headRow: {
    display: 'flex',
    flexShrink: 0,
    height: 46,
    marginBottom: '24px',
    alignItems: 'left'
  },
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 8,
    marginTop: '1em',
    marginBottom: '1em'
  },
  buttonRoot: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '20px',
  },
  navigationbutton: {
    marginLeft: '5px',
    marginRight: '5px'
  },
  title: {
    margin: '0',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '28px',
    lineHeight: '40px'
  },
  formPaperTop: {
    paddingLeft: '1em',
    paddingRight: '1em'
  },
  formPaperMiddle: {
    paddingLeft: '1em',
    paddingRight: '1em'
  }
};

@inject('qcStore', 'routing', 'qcNewCustomerForm') @observer
class QcExistingCustomerReview extends Component {
  constructor(props) {
    super(props);
    this.inputSelectionHelper = new InputSelectionHelper();
  }

  getCurrentSelectedCountry = () => {
    const { qcStore, qcNewCustomerForm } = this.props;
    const countryItem = qcStore.getCountryObject(qcNewCustomerForm.fields.country.value);
    if (countryItem) {
      return countryItem.id;
    }
    return undefined;
  }

  getCurrentValueCountry = () => {
    const { qcStore, qcNewCustomerForm } = this.props;
    const countryItem = qcStore.getCountryObject(qcNewCustomerForm.fields.country.value);
    if (countryItem) {
      return countryItem.name;
    }
    return undefined;
  }

  onEditClicked = () => {
    const { qcStore, routing, qcNewCustomerForm } = this.props;
    const countryChanged = qcNewCustomerForm.fields.country.value !== qcStore.customerData.country;
    qcNewCustomerForm.save();
    if (countryChanged) {
      qcStore.updatePricesOnCountryChange();
    }
    routing.push('/qc-calculator/product-selection');
  }

  onSendAndReviewClicked = () => {
    const { qcStore, routing, qcNewCustomerForm } = this.props;
    const countryChanged = qcNewCustomerForm.fields.country.value !== qcStore.customerData.country;
    qcNewCustomerForm.save();
    if (countryChanged) {
      qcStore.updatePricesOnCountryChange();
    }
    qcStore.saveCustomerProposal();
    routing.push('/qc-calculator/proposal-review');
  }

  render() {
    const { classes, qcStore, qcNewCustomerForm } = this.props;
    const { fields, onFieldChange, onDropDownFieldChange } = qcNewCustomerForm;
    return (
      <div className={classes.root}>
        <div className={classes.headRow}>
          <Typography
            variant="h1"
            className={classes.title}
          >{`${qcStore.customerData.customerName} - Account# ${qcStore.customerData.customerNumber}`}
          </Typography>
        </div>
        <Grid item xs={12} md={8} lg={8}>
          <form>
            <Paper className={classes.formPaperTop}>
              <InputField fields={fields} name="customerName" onChange={onFieldChange} />
              <Divider light />
              <InputField fields={fields} name="customerNumber" onChange={onFieldChange} />
              <Divider light />
              <SelectField
                fields={fields}
                name="country"
                list={qcStore.getCountrySelectData()}
                currentSelect={this.getCurrentSelectedCountry()}
                currentValue={this.getCurrentValueCountry()}
                valueField="name"
                onChange={id => onDropDownFieldChange(getItemFromList(id, qcStore.getCountrySelectData()).iso)}
              />
              <Divider light />
              <InputField fields={fields} name="workingDaysPerWeek" onChange={onFieldChange} />
              <Divider light />
              <InputField fields={fields} name="contractPeriodInMonths" onChange={onFieldChange} />
              <Divider light />
              <InputField fields={fields} name="customerDescription" onChange={onFieldChange} />
            </Paper>
            <div className={classes.headerRow}>
              <Typography variant="h5" gutterBottom>{qcStore.getText('QCPrimaryCustomerContactTitle')}</Typography>
            </div>
            <Paper className={classes.formPaperMiddle}>
              <InputField fields={fields} name="contactName" onChange={onFieldChange} />
              <Divider light />
              <InputField fields={fields} name="contactTitle" onChange={onFieldChange} />
              <Divider light />
              <InputField fields={fields} name="contactEmail" onChange={onFieldChange} />
              <Divider light />
              <InputField fields={fields} name="contactPhone" onChange={onFieldChange} />
            </Paper>
          </form>
          <div className={classes.buttonRoot}>
            <SimAppButton className={classes.navigationbutton} onClick={() => this.onEditClicked()} isNavigationButton inline>{qcStore.getText('QCExistingCustomerReviewEditProposal')}</SimAppButton>
            <SimAppButton className={classes.navigationbutton} onClick={() => this.onSendAndReviewClicked()} isNavigationButton inline>{qcStore.getText('QCExistingCustomerReviewReviewAndSend')}</SimAppButton>
          </div>
        </Grid>
      </div>
    );
  }
}

QcExistingCustomerReview.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  qcStore: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired,
  qcNewCustomerForm: PropTypes.object.isRequired
};

export default withStyles(styles)(QcExistingCustomerReview);
