import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import SimappIcon from 'web/components/_ui/simapp-icon';

const styles = {
  listItem: {
    padding: 16
  },
  listText: {
    whiteSpace: 'nowrap'
  }
};

const SidebarListitem = props => (
  <ListItem button onClick={props.onClick} title={props.primary} className={`${props.classes.listItem} ${props.className}`} disabled={props.disabled}>
    <ListItemIcon className="listitem-icon">
      <SimappIcon icon={props.icon} title={props.primary} />
    </ListItemIcon>
    <ListItemText primary={props.primary} className={props.classes.listText} />
  </ListItem>
);

SidebarListitem.propTypes = {
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  primary: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

SidebarListitem.defaultProps = {
  className: '',
  disabled: false
};

export default withStyles(styles)(SidebarListitem);
