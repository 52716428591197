import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import copeptinConstants from 'shared/lib/resources/copeptin-constants';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CopeptinInputField from './copeptin-inputfield';

const styles = {
  tableCell: {
    textAlign: 'center',
    width: '200px',
    padding: '0 5px'
  },
  tableHeaderRow: {
    height: '29px'
  },
  borderlessExpansionPanel: {
    '&::before': { content: 'none' },
    marginBottom: '2px',
    boxShadow: 'unset'
  },
  expansionContent: {
    paddingLeft: '10px',
    paddingRight: '0px',
  },
  mediumText: {
    fontWeight: '500'
  },
  animationlessPanelSummary: {
    minHeight: '48px !important',
    lineHeight: '48px',
    '& div:last-child': {
      padding: '0'
    },
    '& div:first-child': {
      margin: '0'
    }
  },
  table: {
    marginTop: '-35px',
    marginBottom: '-25px',
    paddingLeft: '10px',
    paddingRight: '0px'
  },
  borderlessCell: {
    border: 'none'
  },
  rowLabelWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  rowLabelNumber: {
    fontSize: '1.03rem'
  }
};

@inject('copeptinStore')
@observer
class CopeptinTable extends Component {
  onValueChangeSecondColumn(index, value) {
    this.props.setSecondColumnValue(index, value);
    this.forceUpdate();
  }

  onValueChangeThirdColumn(index, value) {
    this.props.setThirdColumnValue(index, value);
    this.forceUpdate();
  }

  getRowLabel = (label) => {
    const { classes } = this.props;
    if (label.charAt(0) >= '0' && label.charAt(0) <= '9') {
      return (
        <div className={classes.rowLabelWrapper}>
          <Typography variant="body1" className={classes.rowLabelNumber}>{label.charAt(0)}</Typography>
          <Typography variant="body1">{label.substr(1)}</Typography>
        </div>
      );
    }
    return (
      <Typography variant="body1">{label}</Typography>
    );
  }

  getTableRow(source, item, index) {
    const { classes, copeptinStore } = this.props;
    const isLastRow = this.props.isLastTable && index === source.length - 1;
    const maxValue = this.props.dataType === copeptinConstants.CopeptinTableRowPercentage ? 1 : -1; // at this point: 1 = 100% (conversion is done directly in CopeptinInputField)
    return (
      <TableRow key={index}>
        <TableCell className={isLastRow ? classes.borderlessCell : ''}>
          {this.getRowLabel(copeptinStore.getLocalizedText(item.name))}
        </TableCell>
        <TableCell className={`${classes.tableCell} ${isLastRow ? classes.borderlessCell : ''}`}>
          <CopeptinInputField
            fieldIndex={index}
            isChanged={this.props.isUserSecondColumnValue(index)}
            type={this.props.dataType}
            onValueChange={(itemIndex, value) => this.onValueChangeSecondColumn(itemIndex, value)}
            maxValue={maxValue}
            value={this.props.getSecondColumnValue(index)}
            isUSNumberInput={this.props.isUSNumberInput}
          />
        </TableCell>
        {this.props.getThirdColumnValue && (
          <TableCell className={`${classes.tableCell} ${isLastRow ? classes.borderlessCell : ''}`}>
            <CopeptinInputField
              fieldIndex={index}
              isChanged={this.props.isUserThirdColumnValue(index)}
              type={this.props.dataType}
              onValueChange={(itemIndex, value) => this.onValueChangeThirdColumn(itemIndex, value)}
              maxValue={maxValue}
              value={this.props.getThirdColumnValue(index)}
              isUSNumberInput={this.props.isUSNumberInput}
            />
          </TableCell>
        )}
      </TableRow>
    );
  }

  getTable(source) {
    const { classes } = this.props;
    return (
      <ExpansionPanel defaultExpanded={this.props.defaultExpanded} key={0} CollapseProps={{ timeout: 0 }} className={classes.borderlessExpansionPanel}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={classes.animationlessPanelSummary}>
          <Typography variant="body1" className={classes.mediumText}>{this.props.headerFirstColumn}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.expansionContent}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableHeaderRow}>
                <TableCell />
                <TableCell className={classes.tableCell}>{this.props.headerSecondColumn}</TableCell>
                {this.props.getThirdColumnValue && <TableCell className={classes.tableCell}>{this.props.headerThirdColumn}</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {source.map((item, index) => (this.getTableRow(source, item, index)))}
            </TableBody>
          </Table>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }

  render() {
    const { source } = this.props;

    return (
      this.getTable(source)
    );
  }
}

CopeptinTable.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  copeptinStore: PropTypes.object.isRequired,
  source: PropTypes.array.isRequired,
  getSecondColumnValue: PropTypes.func.isRequired,
  setSecondColumnValue: PropTypes.func.isRequired,
  isUserSecondColumnValue: PropTypes.func.isRequired,
  getThirdColumnValue: PropTypes.func,
  setThirdColumnValue: PropTypes.func,
  isUserThirdColumnValue: PropTypes.func,
  headerFirstColumn: PropTypes.string,
  headerSecondColumn: PropTypes.string,
  headerThirdColumn: PropTypes.string,
  defaultExpanded: PropTypes.bool,
  dataType: PropTypes.string,
  isLastTable: PropTypes.bool,
  isUSNumberInput: PropTypes.bool
};

CopeptinTable.defaultProps = {
  getThirdColumnValue: undefined,
  setThirdColumnValue: undefined,
  isUserThirdColumnValue: undefined,
  headerFirstColumn: '',
  headerSecondColumn: '',
  headerThirdColumn: '',
  defaultExpanded: false,
  dataType: copeptinConstants.CopeptinTableRowTime,
  isLastTable: false,
  isUSNumberInput: false
};

export default withStyles(styles)(CopeptinTable);
