import pctConstants from 'shared/lib/resources/pct-constants';
import { calculateValue } from './pct-helper';

export class PctClinicalEffectCalculation {
  pctStore;
  isUS;
  pageType;

  constructor(pctStore, isUS, pageType) {
    this.pctStore = pctStore;
    this.isUS = isUS;
    this.pageType = pageType;
  }

  getLaboratoryAnalysis(dataField, i) {
    const valueWithout = this.pctStore.getNoPctValue(dataField, 0) + this.pctStore.getNoPctValue(dataField, 1);
    const valueWith = this.pctStore.getPctValue(dataField, 0) + this.pctStore.getPctValue(dataField, 1);
    this.pctStore.setNoPctValue(dataField, i, valueWithout);
    this.pctStore.setPctValue(dataField, i, valueWith);
    return [false, false, valueWithout, valueWith];
  }

  getAbrBaseline(dataField, i) {
    const valueWithout = this.pctStore.getNoPctValue(dataField, i) * 100;
    let valueWith;
    const reduction = this.pctStore.getNoPctValue(dataField, 11);
    const baseLine = this.pctStore.getNoPctValue(dataField, 12);
    const antibioticsDurationPct = this.pctStore.getPctValue(dataField, 4);
    const antibioticsDurationStandard = this.pctStore.getNoPctValue(dataField, 4);
    const antibioticsSharePct = this.pctStore.getPctValue(dataField, 2);
    const antibioticsShareStandard = this.pctStore.getNoPctValue(dataField, 2);
    if (this.pageType === 'lrti') {
      valueWith = baseLine
        - (reduction * (1 - antibioticsDurationPct / antibioticsDurationStandard)
          + (reduction * (1 - antibioticsSharePct / antibioticsShareStandard)))
        / 2;
    } else {
      valueWith = baseLine - (reduction * (1 - antibioticsDurationPct / antibioticsDurationStandard));
    }
    this.pctStore.setPctValue(dataField, i, valueWith);
    valueWith *= 100;
    return [true, false, valueWithout, Math.round(valueWith * 100) / 100];
  }

  getRowValues(tableRowType, rowText, dataField, i, lastRowText) {
    if (rowText === this.pctStore.getText('PctClinicalEffectTableHeadText4FieldText0') && !this.isUS) {
      return this.getLaboratoryAnalysis(dataField, i);
    }
    if (lastRowText === this.pctStore.getText('PctClinicalEffectTableHeadText5FieldText0')
      && rowText === this.pctStore.getText('PctClinicalEffectTableHeadText5FieldText1')
      && this.isUS) {
      return this.getAbrBaseline(dataField, i);
    }
    const standardIsInput = true;
    const pctIsInput = true;
    const valueWithout = tableRowType === pctConstants.PctTableRowPercentage
      ? this.pctStore.getNoPctValue(dataField, i) * 100
      : this.pctStore.getNoPctValue(dataField, i);
    const valueWith = tableRowType === pctConstants.PctTableRowPercentage
      ? this.pctStore.getPctValue(dataField, i) * 100
      : this.pctStore.getPctValue(dataField, i);
    return [standardIsInput, pctIsInput, valueWithout, valueWith];
  }
}

function getRowValues(pctStore, key, index) {
  if (key.includes(pctConstants.CostsField)) {
    return ([' ', pctStore.getPctValue(key, index)]);
  }
  return ([pctStore.getNoPctValue(key, index), pctStore.getPctValue(key, index)]);
}

function getRowFieldNames(fieldNames, tab, row) {
  const rowFieldNames = [];
  for (let i = 0; i < fieldNames.length; i++) {
    if (rowFieldNames.length === 3) {
      break;
    }
    if (fieldNames[i].startsWith(`tab${tab}row${row}`)) {
      rowFieldNames.push(fieldNames[i]);
    }
  }
  const noPct = rowFieldNames[0];
  const pct = rowFieldNames[rowFieldNames.length === 3 ? 1 : 0];
  const dif = rowFieldNames[2];
  return [noPct, pct, dif];
}

function updateUSTotalContent(rowContent, calculationInstance, isUSOverview) {
  if (!isUSOverview) {
    rowContent.noPct = calculationInstance.resultTotalNoPct;
    rowContent.pct = calculationInstance.resultTotalPct;
    rowContent.dif = calculateValue(calculationInstance.resultTotalPct, calculationInstance.resultTotalNoPct);
  } else {
    rowContent.noPct = rowContent.row === 0 ? calculationInstance.overviewRow0TotalCostPopulationNoPct : calculationInstance.overviewRow1TotalCostPatientNoPct;
    rowContent.pct = rowContent.row === 0 ? calculationInstance.overviewRow0TotalCostPopulationPct : calculationInstance.overviewRow1TotalCostPatientPct;
    rowContent.dif = rowContent.row === 0 ? calculationInstance.overviewRow0TotalCostPopulationDif : calculationInstance.overviewRow1TotalCostPatientDif;
  }
}

const ROUNDING_BIG_VALUE_LIMIT = 100000;

// Rounds the given PCT value according to Thermofisher requirements, EU model only
export function roundPctValueEU(value) {
  if (Math.abs(value) >= ROUNDING_BIG_VALUE_LIMIT) {
    return Math.round(value);
  }
  const num = Number(value);
  return num.toFixed(2);
}

export function getTabContent(tab, textKeysAndTypes, calculationInstance, isUSTotal, isUSOverview) {
  const content = [];
  const fieldNames = Object.keys(calculationInstance);
  for (let i = 0; i < textKeysAndTypes.length; i++) {
    const rowFieldNames = !isUSTotal ? getRowFieldNames(fieldNames, tab, i) : undefined;
    const rowContent = {
      resourceName: textKeysAndTypes[i].text,
      noPct: !isUSTotal && calculationInstance[rowFieldNames[0]] ? roundPctValueEU(calculationInstance[rowFieldNames[0]]) : 0,
      pct: !isUSTotal && calculationInstance[rowFieldNames[1]] ? roundPctValueEU(calculationInstance[rowFieldNames[1]]) : 0,
      dif: !isUSTotal && calculationInstance[rowFieldNames[2]] ? roundPctValueEU(calculationInstance[rowFieldNames[2]]) : 0,
      type: textKeysAndTypes[i].type,
      tab,
      row: i
    };
    if (isUSTotal) {
      updateUSTotalContent(rowContent, calculationInstance, isUSOverview);
    }
    content.push(rowContent);
  }
  return content;
}

export class PctEUResultCalculation {
  constructor(pctStore) {
    // hospital stay
    const regularWardDays = getRowValues(pctStore, pctConstants.ClinicalEffectField, 0);
    this.tab0row0RegularWardDaysNoPct = regularWardDays[0];
    this.tab0row0RegularWardDaysPct = regularWardDays[1];
    this.tab0row0RegularWardDaysDif = calculateValue(this.tab0row0RegularWardDaysPct, this.tab0row0RegularWardDaysNoPct);

    const regularWardDayCosts = getRowValues(pctStore, pctConstants.CostsField, 0)[1];
    this.tab0row1RegularWardNoPctTotal = regularWardDayCosts * this.tab0row0RegularWardDaysNoPct;
    this.tab0row1RegularWardPctTotal = regularWardDayCosts * this.tab0row0RegularWardDaysPct;
    this.tab0row1RegularWardTotalDif = calculateValue(this.tab0row1RegularWardPctTotal, this.tab0row1RegularWardNoPctTotal);

    this.tab0row2IcuAdmissionFee = pctStore.getPctValue(pctConstants.CostsField, 2);
    this.tab0row2IcuAdmissionFeeDif = 0;

    const icuDays = getRowValues(pctStore, pctConstants.ClinicalEffectField, 1);
    this.tab0row3IcuDaysNoPct = icuDays[0];
    this.tab0row3IcuDaysPct = icuDays[1];
    this.tab0row3IcuDaysDif = calculateValue(this.tab0row3IcuDaysPct, this.tab0row3IcuDaysNoPct);

    const icuDayCosts = getRowValues(pctStore, pctConstants.CostsField, 1)[1];
    this.tab0row4IcuTotalNoPct = icuDayCosts * this.tab0row3IcuDaysNoPct;
    this.tab0row4IcuTotalPct = icuDayCosts * this.tab0row3IcuDaysPct;
    this.tab0row4IcuTotalDif = calculateValue(this.tab0row4IcuTotalPct, this.tab0row4IcuTotalNoPct);

    this.tab0row5StayTotalCostPerPatientNoPct = this.tab0row1RegularWardNoPctTotal + this.tab0row4IcuTotalNoPct;
    this.tab0row5StayTotalCostPerPatientPct = this.tab0row1RegularWardPctTotal + this.tab0row4IcuTotalPct;
    this.tab0row5StayTotalCostPerPatientDif = this.tab0row1RegularWardTotalDif + this.tab0row4IcuTotalDif;

    // treatments / measures
    const patientPercentageAntibiotics = getRowValues(pctStore, pctConstants.ClinicalEffectField, 2);
    this.tab1row0patientPercentageAntibioticsNoPct = patientPercentageAntibiotics[0] * 100;
    this.tab1row0patientPercentageAntibioticsPct = patientPercentageAntibiotics[1] * 100;
    this.tab1row0patientPercentageAntibioticsDif = calculateValue(this.tab1row0patientPercentageAntibioticsPct, this.tab1row0patientPercentageAntibioticsNoPct);

    const antibioticsDays = getRowValues(pctStore, pctConstants.ClinicalEffectField, 4);
    this.tab1row1antibioticsDaysNoPct = antibioticsDays[0];
    this.tab1row1antibioticsDaysPct = antibioticsDays[1];
    this.tab1row1antibioticsDaysDif = calculateValue(this.tab1row1antibioticsDaysPct, this.tab1row1antibioticsDaysNoPct);

    const antibioticsDayCost = getRowValues(pctStore, pctConstants.CostsField, 3)[1];
    this.tab1row2antibioticsDayCostNoPct = antibioticsDayCost * this.tab1row1antibioticsDaysNoPct;
    this.tab1row2antibioticsDayCostPct = antibioticsDayCost * this.tab1row1antibioticsDaysPct;
    this.tab1row2antibioticsDayCostDif = calculateValue(this.tab1row2antibioticsDayCostPct, this.tab1row2antibioticsDayCostNoPct);

    const patientPercentageMechanicalVentilation = getRowValues(pctStore, pctConstants.ClinicalEffectField, 3);
    this.tab1row3patientPercentageMechanicalVentilationNoPct = patientPercentageMechanicalVentilation[0] * 100;
    this.tab1row3patientPercentageMechanicalVentilationPct = patientPercentageMechanicalVentilation[1] * 100;
    this.tab1row3patientPercentageMechanicalVentilationDif = calculateValue(this.tab1row3patientPercentageMechanicalVentilationPct, this.tab1row3patientPercentageMechanicalVentilationNoPct);

    const mechanicalVentilationDays = getRowValues(pctStore, pctConstants.ClinicalEffectField, 5);
    this.tab1row4mechanicalVentilationDaysNoPct = mechanicalVentilationDays[0];
    this.tab1row4mechanicalVentilationDaysPct = mechanicalVentilationDays[1];
    this.tab1row4mechanicalVentilationDaysDif = calculateValue(this.tab1row4mechanicalVentilationDaysPct, this.tab1row4mechanicalVentilationDaysNoPct);

    const mechanicalVentilationDayCost = getRowValues(pctStore, pctConstants.CostsField, 4)[1];
    this.tab1row5mechanicalVentilationDayCostNoPct = mechanicalVentilationDayCost * this.tab1row4mechanicalVentilationDaysNoPct;
    this.tab1row5mechanicalVentilationDayCostPct = mechanicalVentilationDayCost * this.tab1row4mechanicalVentilationDaysPct;
    this.tab1row5mechanicalVentilationDayCostDif = calculateValue(this.tab1row5mechanicalVentilationDayCostPct, this.tab1row5mechanicalVentilationDayCostNoPct);

    const totalCostAntibioticsPerPatientNoPct = (this.tab1row0patientPercentageAntibioticsNoPct / 100) * this.tab1row2antibioticsDayCostNoPct;
    const totalCostMechanicalVentilationPerPatientNoPct = (this.tab1row3patientPercentageMechanicalVentilationNoPct / 100) * this.tab1row5mechanicalVentilationDayCostNoPct;
    const totalCostAntibioticsPerPatientPct = (this.tab1row0patientPercentageAntibioticsPct / 100) * this.tab1row2antibioticsDayCostPct;
    const totalCostMechanicalVentilationPerPatientPct = (this.tab1row3patientPercentageMechanicalVentilationPct / 100) * this.tab1row5mechanicalVentilationDayCostPct;
    this.tab1row6totalCostTreatmentPerPatientNoPct = totalCostAntibioticsPerPatientNoPct + totalCostMechanicalVentilationPerPatientNoPct;
    this.tab1row6totalCostTreatmentPerPatientPct = totalCostAntibioticsPerPatientPct + totalCostMechanicalVentilationPerPatientPct;
    this.tab1row6totalCostTreatmentPerPatientDif = calculateValue(this.tab1row6totalCostTreatmentPerPatientPct, this.tab1row6totalCostTreatmentPerPatientNoPct);

    // blood cultures
    const patientsPossibleSepsisBloodCulture = getRowValues(pctStore, pctConstants.ClinicalEffectField, 6);
    this.tab2row0patientsPossibleSepsisBloodCultureNoPct = patientsPossibleSepsisBloodCulture[0] * 100;
    this.tab2row0patientsPossibleSepsisBloodCulturePct = patientsPossibleSepsisBloodCulture[1] * 100;
    this.tab2row0patientsPossibleSepsisBloodCultureDif = calculateValue(this.tab2row0patientsPossibleSepsisBloodCulturePct, this.tab2row0patientsPossibleSepsisBloodCultureNoPct);

    const averageBloodCulturePerPatient = getRowValues(pctStore, pctConstants.ClinicalEffectField, 7);
    this.tab2row1averageBloodCulturePerPatientNoPct = averageBloodCulturePerPatient[0];
    this.tab2row1averageBloodCulturePerPatientPct = averageBloodCulturePerPatient[1];
    this.tab2row1averageBloodCulturePerPatientDif = calculateValue(this.tab2row1averageBloodCulturePerPatientPct, this.tab2row1averageBloodCulturePerPatientNoPct);

    const patientsDiagnosedSepsisBloodCulture = getRowValues(pctStore, pctConstants.ClinicalEffectField, 8);
    this.tab2row2patientsDiagnosedSepsisBloodCultureNoPct = patientsDiagnosedSepsisBloodCulture[0] * 100;
    this.tab2row2patientsDiagnosedSepsisBloodCulturePct = patientsDiagnosedSepsisBloodCulture[1] * 100;
    this.tab2row2patientsDiagnosedSepsisBloodCultureDif = calculateValue(this.tab2row2patientsDiagnosedSepsisBloodCulturePct, this.tab2row2patientsDiagnosedSepsisBloodCultureNoPct);

    const costsBloodCulture = getRowValues(pctStore, pctConstants.CostsField, 5)[1];
    this.tab2row3costsBloodCultureTotalNoPct = costsBloodCulture * averageBloodCulturePerPatient[0] * patientsPossibleSepsisBloodCulture[0] * (1 / patientsDiagnosedSepsisBloodCulture[0]);
    this.tab2row3costsBloodCultureTotalPct = costsBloodCulture * averageBloodCulturePerPatient[1] * patientsPossibleSepsisBloodCulture[1] * (1 / patientsDiagnosedSepsisBloodCulture[1]);
    this.tab2row3costsBloodCultureTotalDif = calculateValue(this.tab2row3costsBloodCultureTotalPct, this.tab2row3costsBloodCultureTotalNoPct);

    // laboratory analyses
    const laboratoryAnalysesAmount = getRowValues(pctStore, pctConstants.ClinicalEffectField, 9);
    this.tab3row0laboratoryAnalysesAmountNoPct = laboratoryAnalysesAmount[0];
    this.tab3row0laboratoryAnalysesAmountPct = laboratoryAnalysesAmount[1];
    this.tab3row0laboratoryAnalysesAmountDif = calculateValue(this.tab3row0laboratoryAnalysesAmountPct, this.tab3row0laboratoryAnalysesAmountNoPct);

    const pctPerPatient = getRowValues(pctStore, pctConstants.ClinicalEffectField, 10);
    this.tab3row1pctPerPatientNoPct = pctPerPatient[0];
    this.tab3row1pctPerPatientPct = pctPerPatient[1];
    this.tab3row1pctPerPatientDif = calculateValue(this.tab3row1pctPerPatientPct, this.tab3row1pctPerPatientNoPct);

    const laboratoryAnalysesOrderTariff = getRowValues(pctStore, pctConstants.CostsField, 6)[1];
    this.tab3row2laboratoryAnalysesOrderTariffNoPct = laboratoryAnalysesOrderTariff;
    this.tab3row2laboratoryAnalysesOrderTariffPct = laboratoryAnalysesOrderTariff;
    this.tab3row2laboratoryAnalysesOrderTariffDif = 0;

    const averageLaboratoryCostPerDay = getRowValues(pctStore, pctConstants.CostsField, 7)[1];
    this.tab3row3totalCostLaboratoryAnalysesNoPct = averageLaboratoryCostPerDay * this.tab3row0laboratoryAnalysesAmountNoPct;
    this.tab3row3totalCostLaboratoryAnalysesPct = averageLaboratoryCostPerDay * this.tab3row0laboratoryAnalysesAmountPct;
    this.tab3row3totalCostLaboratoryAnalysesDif = calculateValue(this.tab3row3totalCostLaboratoryAnalysesPct, this.tab3row3totalCostLaboratoryAnalysesNoPct);

    const costPctTest = getRowValues(pctStore, pctConstants.CostsField, 8)[1];
    this.tab3row4totalCostPctTestsNoPct = costPctTest * this.tab3row1pctPerPatientNoPct;
    this.tab3row4totalCostPctTestsPct = costPctTest * this.tab3row1pctPerPatientPct;
    this.tab3row4totalCostPctTestsDif = calculateValue(this.tab3row4totalCostPctTestsPct, this.tab3row4totalCostPctTestsNoPct);

    this.tab3row5totalCostLaboratoryAnalysesPerPatientNoPct = this.tab2row3costsBloodCultureTotalNoPct + this.tab3row3totalCostLaboratoryAnalysesNoPct + this.tab3row4totalCostPctTestsNoPct;
    this.tab3row5totalCostLaboratoryAnalysesPerPatientPct = this.tab2row3costsBloodCultureTotalPct + this.tab3row3totalCostLaboratoryAnalysesPct + this.tab3row4totalCostPctTestsPct;
    this.tab3row5totalCostLaboratoryAnalysesPerPatientDif = calculateValue(this.tab3row5totalCostLaboratoryAnalysesPerPatientPct, this.tab3row5totalCostLaboratoryAnalysesPerPatientNoPct);

    // total
    this.tab4row1totalCostPerPatientNoPct = this.tab0row5StayTotalCostPerPatientNoPct + this.tab1row6totalCostTreatmentPerPatientNoPct + this.tab3row5totalCostLaboratoryAnalysesPerPatientNoPct;
    this.tab4row1totalCostPerPatientPct = this.tab0row5StayTotalCostPerPatientPct + this.tab1row6totalCostTreatmentPerPatientPct + this.tab3row5totalCostLaboratoryAnalysesPerPatientPct;
    this.tab4row1totalCostPerPatientDif = calculateValue(this.tab4row1totalCostPerPatientPct, this.tab4row1totalCostPerPatientNoPct);

    const patientsIcuAnually = getRowValues(pctStore, pctConstants.PopulationField, 0)[1];
    const occurenceSepsisIcu = getRowValues(pctStore, pctConstants.PopulationField, 1)[1];
    this.tab4row0totalAnualCostNoPct = patientsIcuAnually * occurenceSepsisIcu * this.tab4row1totalCostPerPatientNoPct;
    this.tab4row0totalAnualCostPct = patientsIcuAnually * occurenceSepsisIcu * this.tab4row1totalCostPerPatientPct;
    this.tab4row0totalAnualCostDif = calculateValue(this.tab4row0totalAnualCostPct, this.tab4row0totalAnualCostNoPct);
  }

  getPageContent() {
    const tab0Content = getTabContent(0,
      [
        { text: pctConstants.PctResultsTableHeadText0TextField0, type: pctConstants.PctTableRowDays },
        { text: pctConstants.PctResultsTableHeadText0TextField1, type: pctConstants.PctTableRowCurrency },
        { text: pctConstants.PctHealthcareCostsTableHeadText0FieldText2, type: pctConstants.PctTableRowCurrency },
        { text: pctConstants.PctResultsTableHeadText0TextField2, type: pctConstants.PctTableRowDays },
        { text: pctConstants.PctResultsTableHeadText0TextField3, type: pctConstants.PctTableRowCurrency },
        { text: pctConstants.PctResultsTableHeadText0TextField4, type: pctConstants.PctTableRowCurrency },
      ], this);

    const tab1Content = getTabContent(1,
      [
        { text: pctConstants.PctClinicalEffectTableHeadText1FieldText0, type: pctConstants.PctTableRowPercentage },
        { text: pctConstants.PctResultsTableHeadText1TextField0, type: pctConstants.PctTableRowDays },
        { text: pctConstants.PctResultsTableHeadText1TextField1, type: pctConstants.PctTableRowCurrency },
        { text: pctConstants.PctResultsTableHeadText1TextField2, type: pctConstants.PctTableRowPercentage },
        { text: pctConstants.PctResultsTableHeadText1TextField3, type: pctConstants.PctTableRowDays },
        { text: pctConstants.PctResultsTableHeadText1TextField4, type: pctConstants.PctTableRowCurrency },
        { text: pctConstants.PctResultsTableHeadText1TextField5, type: pctConstants.PctTableRowCurrency },
      ], this);

    const tab2Content = getTabContent(2,
      [
        { text: pctConstants.PctClinicalEffectTableHeadText3FieldText0, type: pctConstants.PctTableRowPercentage },
        { text: pctConstants.PctClinicalEffectTableHeadText3FieldText1, type: pctConstants.PctTableRowDays },
        { text: pctConstants.PctClinicalEffectTableHeadText3FieldText2, type: pctConstants.PctTableRowPercentage },
        { text: pctConstants.PctResultsTableHeadText2TextField0, type: pctConstants.PctTableRowCurrency }
      ], this);

    const tab3Content = getTabContent(3,
      [
        { text: pctConstants.PctResultsTableHeadText3TextField0, type: pctConstants.PctTableRowDays },
        { text: pctConstants.PctClinicalEffectTableHeadText4FieldText1, type: pctConstants.PctTableRowDays },
        { text: pctConstants.PctHealthcareCostsTableHeadText2FieldText1, type: pctConstants.PctTableRowCurrency },
        { text: pctConstants.PctResultsTableHeadText3TextField1, type: pctConstants.PctTableRowCurrency },
        { text: pctConstants.PctResultsTableHeadText3TextField2, type: pctConstants.PctTableRowCurrency },
        { text: pctConstants.PctResultsTableHeadText3TextField3, type: pctConstants.PctTableRowCurrency }
      ], this);

    const tab4Content = getTabContent(4,
      [
        { text: pctConstants.PctResultsTableHeadText4TextField0, type: pctConstants.PctTableRowCurrency },
        { text: pctConstants.PctResultsTableHeadText4TextField1, type: pctConstants.PctTableRowCurrency }
      ], this);

    const pageContent = [
      { tabTitleKey: pctConstants.PctResultsTableHeadText0, tabContent: tab0Content, totalIndex: 5 },
      { tabTitleKey: pctConstants.PctResultsTableHeadText1, tabContent: tab1Content, totalIndex: 6 },
      { tabTitleKey: pctConstants.PctResultsTableHeadText2, tabContent: tab2Content },
      { tabTitleKey: pctConstants.PctResultsTableHeadText3, tabContent: tab3Content, totalIndex: 5 },
      { tabTitleKey: pctConstants.PctResultsTableHeadText4, tabContent: tab4Content, totalIndex: 1, isLast: true },
    ];
    return pageContent;
  }

  // hospital stay
  tab0row0RegularWardDaysNoPct;
  tab0row0RegularWardDaysPct;
  tab0row0RegularWardDaysDif;

  tab0row1RegularWardNoPctTotal;
  tab0row1RegularWardPctTotal;
  tab0row1RegularWardTotalDif;

  tab0row2IcuAdmissionFee;
  tab0row2IcuAdmissionFeeDif;

  tab0row3IcuDaysNoPct;
  tab0row3IcuDaysPct;
  tab0row3IcuDaysDif;

  tab0row4IcuTotalNoPct;
  tab0row4IcuTotalPct;
  tab0row4IcuTotalDif;

  tab0row5StayTotalCostPerPatientNoPct;
  tab0row5StayTotalCostPerPatientPct;
  tab0row5StayTotalCostPerPatientDif;

  // treatments / measures
  tab1row0patientPercentageAntibioticsNoPct;
  tab1row0patientPercentageAntibioticsPct;
  tab1row0patientPercentageAntibioticsDif;

  tab1row1antibioticsDaysNoPct;
  tab1row1antibioticsDaysPct;
  tab1row1antibioticsDaysDif;

  tab1row2antibioticsDayCostNoPct;
  tab1row2antibioticsDayCostPct;
  tab1row2antibioticsDayCostDif;

  tab1row3patientPercentageMechanicalVentilationNoPct;
  tab1row3patientPercentageMechanicalVentilationPct;
  tab1row3patientPercentageMechanicalVentilationDif;

  tab1row4mechanicalVentilationDaysNoPct;
  tab1row4mechanicalVentilationDaysPct;
  tab1row4mechanicalVentilationDaysDif;

  tab1row5mechanicalVentilationDayCostNoPct;
  tab1row5mechanicalVentilationDayCostPct;
  tab1row5mechanicalVentilationDayCostDif;

  totalCostAntibioticsPerPatientNoPct;
  totalCostMechanicalVentilationPerPatientNoPct;
  tab1row6totalCostTreatmentPerPatientNoPct;
  totalCostAntibioticsPerPatientPct;
  totalCostMechanicalVentilationPerPatientPct;
  tab1row6totalCostTreatmentPerPatientPct;
  tab1row6totalCostTreatmentPerPatientDif;

  // blood cultures
  tab2row0patientsPossibleSepsisBloodCultureNoPct;
  tab2row0patientsPossibleSepsisBloodCulturePct;
  tab2row0patientsPossibleSepsisBloodCultureDif;

  tab2row1averageBloodCulturePerPatientNoPct;
  tab2row1averageBloodCulturePerPatientPct;
  tab2row1averageBloodCulturePerPatientDif;

  tab2row2patientsDiagnosedSepsisBloodCultureNoPct;
  tab2row2patientsDiagnosedSepsisBloodCulturePct;
  tab2row2patientsDiagnosedSepsisBloodCultureDif;

  tab2row3costsBloodCultureTotalNoPct;
  tab2row3costsBloodCultureTotalPct;
  tab2row3costsBloodCultureTotalDif;

  // laboratory analyses
  tab3row0laboratoryAnalysesAmountNoPct;
  tab3row0laboratoryAnalysesAmountPct;
  tab3row0laboratoryAnalysesAmountDif;

  tab3row1pctPerPatientNoPct;
  tab3row1pctPerPatientPct;
  tab3row1pctPerPatientDif;

  tab3row2laboratoryAnalysesOrderTariffNoPct;
  tab3row2laboratoryAnalysesOrderTariffPct;
  tab3row2laboratoryAnalysesOrderTariffDif;

  tab3row3totalCostLaboratoryAnalysesNoPct;
  tab3row3totalCostLaboratoryAnalysesPct;
  tab3row3totalCostLaboratoryAnalysesDif;

  tab3row4totalCostPctTestsNoPct;
  tab3row4totalCostPctTestsPct;
  tab3row4totalCostPctTestsDif;

  tab3row5totalCostLaboratoryAnalysesPerPatientNoPct;
  tab3row5totalCostLaboratoryAnalysesPerPatientPct;
  tab3row5totalCostLaboratoryAnalysesPerPatientDif;

  // total
  tab4row1totalCostPerPatientNoPct;
  tab4row1totalCostPerPatientPct;
  tab4row1totalCostPerPatientDif;

  tab4row0totalAnualCostNoPct;
  tab4row0totalAnualCostPct;
  tab4row0totalAnualCostDif;
}

export class PctUSResultCalculation {
  resultTotalNoPct;
  resultTotalPct;
  pctStore;
  currentClinicalEffect;

  constructor(pctStore) {
    this.pctStore = pctStore;
  }

  roundCurrencyValues() {
    this.tab0row1totalCostRegularWardNoPct = Math.round(this.tab0row1totalCostRegularWardNoPct);
    this.tab0row1totalCostRegularWardPct = Math.round(this.tab0row1totalCostRegularWardPct);
    this.tab0row1totalCostRegularWardDif = calculateValue(this.tab0row1totalCostRegularWardPct, this.tab0row1totalCostRegularWardNoPct);

    this.tab0row3costDayIcuNoPct = Math.round(this.tab0row3costDayIcuNoPct);
    this.tab0row3costDayIcuPct = Math.round(this.tab0row3costDayIcuPct);
    this.tab0row3costDayIcuDif = calculateValue(this.tab0row3costDayIcuPct, this.tab0row3costDayIcuNoPct);

    this.tab0row4additionalCostAbrNoPct = Math.round(this.tab0row4additionalCostAbrNoPct);
    this.tab0row4additionalCostAbrPct = Math.round(this.tab0row4additionalCostAbrPct);
    this.tab0row4additionalCostAbrDif = calculateValue(this.tab0row4additionalCostAbrPct, this.tab0row4additionalCostAbrNoPct);

    this.tab0row5additionalCostCdiffNoPct = Math.round(this.tab0row5additionalCostCdiffNoPct);
    this.tab0row5additionalCostCdiffPct = Math.round(this.tab0row5additionalCostCdiffPct);
    this.tab0row5additionalCostCdiffDif = calculateValue(this.tab0row5additionalCostCdiffPct, this.tab0row5additionalCostCdiffNoPct);

    this.tab0row6totalCostHospitalStayPatientNoPct = Math.round(this.tab0row6totalCostHospitalStayPatientNoPct);
    this.tab0row6totalCostHospitalStayPatientPct = Math.round(this.tab0row6totalCostHospitalStayPatientPct);
    this.tab0row6totalCostHospitalStayPatientDif = calculateValue(this.tab0row6totalCostHospitalStayPatientPct, this.tab0row6totalCostHospitalStayPatientNoPct);

    this.tab1row2totalCostAntibioticsNoPct = Math.round(this.tab1row2totalCostAntibioticsNoPct);
    this.tab1row2totalCostAntibioticsPct = Math.round(this.tab1row2totalCostAntibioticsPct);
    this.tab1row2totalCostAntibioticsDif = calculateValue(this.tab1row2totalCostAntibioticsPct, this.tab1row2totalCostAntibioticsNoPct);

    this.tab1row5totalMechanicalVentilationCostNoPct = Math.round(this.tab1row5totalMechanicalVentilationCostNoPct);
    this.tab1row5totalMechanicalVentilationCostPct = Math.round(this.tab1row5totalMechanicalVentilationCostPct);
    this.tab1row5totalMechanicalVentilationCostDif = calculateValue(this.tab1row5totalMechanicalVentilationCostPct, this.tab1row5totalMechanicalVentilationCostNoPct);

    this.tab1row6totalCostTreatmentNoPct = Math.round(this.tab1row6totalCostTreatmentNoPct);
    this.tab1row6totalCostTreatmentPct = Math.round(this.tab1row6totalCostTreatmentPct);
    this.tab1row6totalCostTreatmentDif = calculateValue(this.tab1row6totalCostTreatmentPct, this.tab1row6totalCostTreatmentNoPct);

    this.tab2row3totalCostBloodCultureNoPct = Math.round(this.tab2row3totalCostBloodCultureNoPct);
    this.tab2row3totalCostBloodCulturePct = Math.round(this.tab2row3totalCostBloodCulturePct);
    this.tab2row3totalCostBloodCultureDif = calculateValue(this.tab2row3totalCostBloodCulturePct, this.tab2row3totalCostBloodCultureNoPct);

    this.tab3row2costLaboratoryAnalysisNoPct = Math.round(this.tab3row2costLaboratoryAnalysisNoPct);
    this.tab3row2costLaboratoryAnalysisPct = Math.round(this.tab3row2costLaboratoryAnalysisPct);
    this.tab3row2costLaboratoryAnalysisDif = calculateValue(this.tab3row2costLaboratoryAnalysisPct, this.tab3row2costLaboratoryAnalysisNoPct);

    this.tab3row3costPctTestNoPct = Math.round(this.tab3row3costPctTestNoPct);
    this.tab3row3costPctTestPct = Math.round(this.tab3row3costPctTestPct);
    this.tab3row3costPctTestDif = calculateValue(this.tab3row3costPctTestPct, this.tab3row3costPctTestNoPct);

    this.tab3row4totalCostLaboratoryAnalysesNoPct = Math.round(this.tab3row4totalCostLaboratoryAnalysesNoPct);
    this.tab3row4totalCostLaboratoryAnalysesPct = Math.round(this.tab3row4totalCostLaboratoryAnalysesPct);
    this.tab3row4totalCostLaboratoryAnalysesDif = calculateValue(this.tab3row4totalCostLaboratoryAnalysesPct, this.tab3row4totalCostLaboratoryAnalysesNoPct);

    this.overviewRow1TotalCostPatientNoPct = Math.round(this.overviewRow1TotalCostPatientNoPct);
    this.overviewRow1TotalCostPatientPct = Math.round(this.overviewRow1TotalCostPatientPct);
    this.overviewRow1TotalCostPatientDif = calculateValue(this.overviewRow1TotalCostPatientPct, this.overviewRow1TotalCostPatientNoPct);

    this.overviewRow0TotalCostPopulationNoPct = Math.round(this.overviewRow0TotalCostPopulationNoPct);
    this.overviewRow0TotalCostPopulationPct = Math.round(this.overviewRow0TotalCostPopulationPct);
    this.overviewRow0TotalCostPopulationDif = calculateValue(this.overviewRow0TotalCostPopulationPct, this.overviewRow0TotalCostPopulationNoPct);

    this.resultTotalNoPct = Math.round(this.resultTotalNoPct);
    this.resultTotalPct = Math.round(this.resultTotalPct);
  }

  execute(clinicalEffect, enableABR = true, enableCdiff = true) {
    this.currentClinicalEffect = clinicalEffect;
    // hospital stay
    const regularWardDays = getRowValues(this.pctStore, clinicalEffect, 0);
    this.tab0row0regularWardDaysNoPct = regularWardDays[0];
    this.tab0row0regularWardDaysPct = regularWardDays[1];
    this.tab0row0regularWardDaysDif = calculateValue(this.tab0row0regularWardDaysPct, this.tab0row0regularWardDaysNoPct);

    const costDayRegularWard = getRowValues(this.pctStore, pctConstants.CostsField, 0)[1];
    this.tab0row1totalCostRegularWardNoPct = costDayRegularWard * this.tab0row0regularWardDaysNoPct;
    this.tab0row1totalCostRegularWardPct = costDayRegularWard * this.tab0row0regularWardDaysPct;

    const icuDays = getRowValues(this.pctStore, clinicalEffect, 1);
    this.tab0row2icuDaysNoPct = icuDays[0];
    this.tab0row2icuDaysPct = icuDays[1];
    this.tab0row2icuDaysDif = calculateValue(this.tab0row2icuDaysPct, this.tab0row2icuDaysNoPct);

    const costDayIcu = getRowValues(this.pctStore, pctConstants.CostsField, 1)[1];
    const proportionAdmittedIcu = getRowValues(this.pctStore, clinicalEffect, 3);
    this.tab0row3costDayIcuNoPct = costDayIcu * this.tab0row2icuDaysNoPct * proportionAdmittedIcu[0];
    this.tab0row3costDayIcuPct = costDayIcu * this.tab0row2icuDaysPct * proportionAdmittedIcu[1];

    const costIsolationPerDay = getRowValues(this.pctStore, pctConstants.CostsField, 2)[1];
    let costAdditionalDaysOnWardAbrNoPct = getRowValues(this.pctStore, pctConstants.CostsField, 11)[1];
    let costAdditionalDaysOnWardAbrPct = getRowValues(this.pctStore, pctConstants.CostsField, 13)[1];
    if (clinicalEffect === pctConstants.ClinicalEffectLrti) {
      costAdditionalDaysOnWardAbrNoPct = getRowValues(this.pctStore, pctConstants.CostsField, 10)[1];
      costAdditionalDaysOnWardAbrPct = getRowValues(this.pctStore, pctConstants.CostsField, 12)[1];
    }

    const patientPercentageAntibiotics = getRowValues(this.pctStore, clinicalEffect, 2);
    if (enableABR) {
      const additionalDaysOnWardAbr = getRowValues(this.pctStore, clinicalEffect, 13);
      const baselineAbr = getRowValues(this.pctStore, clinicalEffect, 12);
      this.tab0row4additionalCostAbrNoPct = (((costDayRegularWard + costIsolationPerDay) * additionalDaysOnWardAbr[0]) + costAdditionalDaysOnWardAbrNoPct) * baselineAbr[0] * patientPercentageAntibiotics[0];
      this.tab0row4additionalCostAbrPct = (((costDayRegularWard + costIsolationPerDay) * additionalDaysOnWardAbr[1]) + costAdditionalDaysOnWardAbrPct) * baselineAbr[1] * patientPercentageAntibiotics[1];
    } else {
      this.tab0row4additionalCostAbrNoPct = 0;
      this.tab0row4additionalCostAbrPct = 0;
      this.tab0row4additionalCostAbrDif = 0;
    }

    if (enableCdiff) {
      const costAdditionalDaysOnWardCdiff = getRowValues(this.pctStore, pctConstants.CostsField, 9)[1];
      const additionalDaysOnWardCdiff = getRowValues(this.pctStore, clinicalEffect, 15);
      const baselineCdiff = getRowValues(this.pctStore, clinicalEffect, 14);
      this.tab0row5additionalCostCdiffNoPct = (((costDayRegularWard + costIsolationPerDay) * additionalDaysOnWardCdiff[0]) + costAdditionalDaysOnWardCdiff) * baselineCdiff[0] * patientPercentageAntibiotics[0];
      this.tab0row5additionalCostCdiffPct = (((costDayRegularWard + costIsolationPerDay) * additionalDaysOnWardCdiff[1]) + costAdditionalDaysOnWardCdiff) * baselineCdiff[1] * patientPercentageAntibiotics[1];
    } else {
      this.tab0row5additionalCostCdiffNoPct = 0;
      this.tab0row5additionalCostCdiffPct = 0;
      this.tab0row5additionalCostCdiffDif = 0;
    }

    this.tab0row6totalCostHospitalStayPatientNoPct = this.tab0row1totalCostRegularWardNoPct + this.tab0row3costDayIcuNoPct + this.tab0row4additionalCostAbrNoPct + this.tab0row5additionalCostCdiffNoPct;
    this.tab0row6totalCostHospitalStayPatientPct = this.tab0row1totalCostRegularWardPct + this.tab0row3costDayIcuPct + this.tab0row4additionalCostAbrPct + this.tab0row5additionalCostCdiffPct;

    // treatments / measures
    this.tab1row0patientPercentageAntibioticsNoPct = patientPercentageAntibiotics[0] * 100;
    this.tab1row0patientPercentageAntibioticsPct = patientPercentageAntibiotics[1] * 100;
    this.tab1row0patientPercentageAntibioticsDif = calculateValue(this.tab1row0patientPercentageAntibioticsPct, this.tab1row0patientPercentageAntibioticsNoPct);

    const antibioticsDays = getRowValues(this.pctStore, clinicalEffect, 4);
    this.tab1row1antibioticsDaysNoPct = antibioticsDays[0];
    this.tab1row1antibioticsDaysPct = antibioticsDays[1];
    this.tab1row1antibioticsDaysDif = calculateValue(this.tab1row1antibioticsDaysPct, this.tab1row1antibioticsDaysNoPct);

    let costAntibioticsPerDay;
    if (clinicalEffect === pctConstants.ClinicalEffectLrti) {
      costAntibioticsPerDay = getRowValues(this.pctStore, pctConstants.CostsField, 3)[1];
    } else {
      costAntibioticsPerDay = getRowValues(this.pctStore, pctConstants.CostsField, 4)[1];
    }
    this.tab1row2totalCostAntibioticsNoPct = costAntibioticsPerDay * this.tab1row1antibioticsDaysNoPct * patientPercentageAntibiotics[0];
    this.tab1row2totalCostAntibioticsPct = costAntibioticsPerDay * this.tab1row1antibioticsDaysPct * patientPercentageAntibiotics[1];

    this.tab1row3proportionAdmittedIcuNoPct = proportionAdmittedIcu[0] * 100;
    this.tab1row3proportionAdmittedIcuPct = proportionAdmittedIcu[1] * 100;
    this.tab1row3proportionAdmittedIcuDif = calculateValue(this.tab1row3proportionAdmittedIcuPct, this.tab1row3proportionAdmittedIcuNoPct);

    const mechanicalVentilationDays = getRowValues(this.pctStore, clinicalEffect, 5);
    this.tab1row4mechanicalVentilationDaysNoPct = mechanicalVentilationDays[0];
    this.tab1row4mechanicalVentilationDaysPct = mechanicalVentilationDays[1];
    this.tab1row4mechanicalVentilationDaysDif = calculateValue(this.tab1row4mechanicalVentilationDaysPct, this.tab1row4mechanicalVentilationDaysNoPct);

    const mechanicalVentilationCost = getRowValues(this.pctStore, pctConstants.CostsField, 5)[1];
    this.tab1row5totalMechanicalVentilationCostNoPct = mechanicalVentilationCost * this.tab1row4mechanicalVentilationDaysNoPct * proportionAdmittedIcu[0];
    this.tab1row5totalMechanicalVentilationCostPct = mechanicalVentilationCost * this.tab1row4mechanicalVentilationDaysPct * proportionAdmittedIcu[1];

    this.tab1row6totalCostTreatmentNoPct = this.tab1row2totalCostAntibioticsNoPct + this.tab1row5totalMechanicalVentilationCostNoPct;
    this.tab1row6totalCostTreatmentPct = this.tab1row2totalCostAntibioticsPct + this.tab1row5totalMechanicalVentilationCostPct;

    // blood cultures
    const patientsSuspectedSepsisBloodCulture = getRowValues(this.pctStore, clinicalEffect, 6);
    this.tab2row0patientsSuspectedSepsisBloodCultureNoPct = patientsSuspectedSepsisBloodCulture[0] * 100;
    this.tab2row0patientsSuspectedSepsisBloodCulturePct = patientsSuspectedSepsisBloodCulture[1] * 100;
    this.tab2row0patientsSuspectedSepsisBloodCultureDif = calculateValue(this.tab2row0patientsSuspectedSepsisBloodCulturePct, this.tab2row0patientsSuspectedSepsisBloodCultureNoPct);

    const averageNumberBloodCulturePatient = getRowValues(this.pctStore, clinicalEffect, 7);
    this.tab2row1averageNumberBloodCulturePatientNoPct = averageNumberBloodCulturePatient[0];
    this.tab2row1averageNumberBloodCulturePatientPct = averageNumberBloodCulturePatient[1];
    this.tab2row1averageNumberBloodCulturePatientDif = calculateValue(this.tab2row1averageNumberBloodCulturePatientPct, this.tab2row1averageNumberBloodCulturePatientNoPct);

    const patientsDiagnosedSepsisBloodCulture = getRowValues(this.pctStore, clinicalEffect, 8);
    this.tab2row2patientsDiagnosedSepsisBloodCultureNoPct = patientsDiagnosedSepsisBloodCulture[0] * 100;
    this.tab2row2patientsDiagnosedSepsisBloodCulturePct = patientsDiagnosedSepsisBloodCulture[1] * 100;
    this.tab2row2patientsDiagnosedSepsisBloodCultureDif = calculateValue(this.tab2row2patientsDiagnosedSepsisBloodCulturePct, this.tab2row2patientsDiagnosedSepsisBloodCultureNoPct);

    const costBloodCulture = getRowValues(this.pctStore, pctConstants.CostsField, 6)[1];
    this.tab2row3totalCostBloodCultureNoPct = averageNumberBloodCulturePatient[0] * patientsSuspectedSepsisBloodCulture[0] * costBloodCulture * (1 / patientsDiagnosedSepsisBloodCulture[0]);
    this.tab2row3totalCostBloodCulturePct = averageNumberBloodCulturePatient[1] * patientsSuspectedSepsisBloodCulture[1] * costBloodCulture * (1 / patientsDiagnosedSepsisBloodCulture[1]);

    // laboratory analyses
    const totalLaboratoryAnalyes = getRowValues(this.pctStore, clinicalEffect, 9);
    this.tab3row0totalLaboratoryAnalyesNoPct = totalLaboratoryAnalyes[0];
    this.tab3row0totalLaboratoryAnalyesPct = totalLaboratoryAnalyes[1];
    this.tab3row0totalLaboratoryAnalyesDif = calculateValue(this.tab3row0totalLaboratoryAnalyesPct, this.tab3row0totalLaboratoryAnalyesNoPct);

    const pctTestsPerPatient = getRowValues(this.pctStore, clinicalEffect, 10);
    this.tab3row1pctTestsPerPatientNoPct = pctTestsPerPatient[0];
    this.tab3row1pctTestsPerPatientPct = pctTestsPerPatient[1];
    this.tab3row1pctTestsPerPatientDif = calculateValue(this.tab3row1pctTestsPerPatientPct, this.tab3row1pctTestsPerPatientNoPct);

    const costLaboratoryAnalysis = getRowValues(this.pctStore, pctConstants.CostsField, 7)[1];
    this.tab3row2costLaboratoryAnalysisNoPct = costLaboratoryAnalysis * this.tab3row0totalLaboratoryAnalyesNoPct;
    this.tab3row2costLaboratoryAnalysisPct = costLaboratoryAnalysis * this.tab3row0totalLaboratoryAnalyesPct;

    const costPctTest = getRowValues(this.pctStore, pctConstants.CostsField, 8)[1];
    this.tab3row3costPctTestNoPct = costPctTest * this.tab3row1pctTestsPerPatientNoPct;
    this.tab3row3costPctTestPct = costPctTest * this.tab3row1pctTestsPerPatientPct;

    this.tab3row4totalCostLaboratoryAnalysesNoPct = this.tab2row3totalCostBloodCultureNoPct + this.tab3row2costLaboratoryAnalysisNoPct + this.tab3row3costPctTestNoPct;
    this.tab3row4totalCostLaboratoryAnalysesPct = this.tab2row3totalCostBloodCulturePct + this.tab3row2costLaboratoryAnalysisPct + this.tab3row3costPctTestPct;

    // total overview
    this.overviewRow1TotalCostPatientNoPct = this.tab0row6totalCostHospitalStayPatientNoPct + this.tab1row6totalCostTreatmentNoPct + this.tab3row4totalCostLaboratoryAnalysesNoPct;
    this.overviewRow1TotalCostPatientPct = this.tab0row6totalCostHospitalStayPatientPct + this.tab1row6totalCostTreatmentPct + this.tab3row4totalCostLaboratoryAnalysesPct;

    if (clinicalEffect === pctConstants.ClinicalEffectLrti) {
      this.overviewRow0TotalCostPopulationNoPct = this.pctStore.getPctValue(pctConstants.PopulationField, 0) * this.overviewRow1TotalCostPatientNoPct;
      this.overviewRow0TotalCostPopulationPct = this.pctStore.getPctValue(pctConstants.PopulationField, 0) * this.overviewRow1TotalCostPatientPct;
    } else {
      this.overviewRow0TotalCostPopulationNoPct = this.pctStore.getPctValue(pctConstants.PopulationField, 1) * this.overviewRow1TotalCostPatientNoPct;
      this.overviewRow0TotalCostPopulationPct = this.pctStore.getPctValue(pctConstants.PopulationField, 1) * this.overviewRow1TotalCostPatientPct;
    }

    this.resultTotalNoPct += this.overviewRow0TotalCostPopulationNoPct;
    this.resultTotalPct += this.overviewRow0TotalCostPopulationPct;
    this.roundCurrencyValues();
  }

  getCostContent() {
    const tabOverview = getTabContent(4,
      [
        { text: pctConstants.PctResultsTableHeadText4TextField0, type: pctConstants.PctTableRowCurrency },
        { text: pctConstants.PctResultsTableHeadText4TextField1, type: pctConstants.PctTableRowCurrency }
      ],
      this, true, true);

    const tabDetails = [
      {
        tabTitleKey: pctConstants.PctResultsTableHeadText0,
        tabContent: getTabContent(0,
          [
            { text: pctConstants.PctResultsTableHeadText0TextField0, type: pctConstants.PctTableRowDays },
            { text: pctConstants.PctResultsTableHeadText0TextField1, type: pctConstants.PctTableRowCurrency },
            { text: pctConstants.PctResultsTableHeadText0TextField2, type: pctConstants.PctTableRowDays },
            { text: pctConstants.PctResultsTableHeadText0TextField3, type: pctConstants.PctTableRowCurrency },
            { text: pctConstants.PctResultsTableHeadText0TextField5, type: pctConstants.PctTableRowCurrency },
            { text: pctConstants.PctResultsTableHeadText0TextField6, type: pctConstants.PctTableRowCurrency },
            { text: pctConstants.PctResultsTableHeadText0TextField4, type: pctConstants.PctTableRowCurrency }
          ], this, false, false),
        totalIndex: 6
      },
      {
        tabTitleKey: pctConstants.PctResultsTableHeadText1,
        tabContent: getTabContent(1,
          [
            { text: pctConstants.PctClinicalEffectTableHeadText1FieldText0, type: pctConstants.PctTableRowPercentage },
            { text: pctConstants.PctResultsTableHeadText1TextField0, type: pctConstants.PctTableRowDays },
            { text: pctConstants.PctResultsTableHeadText1TextField1, type: pctConstants.PctTableRowCurrency },
            { text: pctConstants.PctResultsTableHeadText1TextField2, type: pctConstants.PctTableRowPercentage },
            { text: pctConstants.PctResultsTableHeadText1TextField3, type: pctConstants.PctTableRowDays },
            { text: pctConstants.PctResultsTableHeadText1TextField4, type: pctConstants.PctTableRowCurrency },
            { text: pctConstants.PctResultsTableHeadText1TextField5, type: pctConstants.PctTableRowCurrency }
          ], this, false, false),
        totalIndex: 6
      },
      {
        tabTitleKey: pctConstants.PctResultsTableHeadText2,
        tabContent: getTabContent(2,
          [
            { text: pctConstants.PctClinicalEffectTableHeadText3FieldText0, type: pctConstants.PctTableRowPercentage },
            { text: pctConstants.PctClinicalEffectTableHeadText3FieldText1, type: pctConstants.PctTableRowDays },
            { text: pctConstants.PctClinicalEffectTableHeadText3FieldText2, type: pctConstants.PctTableRowPercentage },
            { text: pctConstants.PctResultsTableHeadText2TextField0, type: pctConstants.PctTableRowCurrency }
          ], this, false, false),
      },
      {
        tabTitleKey: pctConstants.PctResultsTableHeadText3,
        tabContent: getTabContent(3,
          [
            { text: pctConstants.PctResultsTableHeadText3TextField0, type: pctConstants.PctTableRowDays },
            { text: pctConstants.PctClinicalEffectTableHeadText4FieldText1, type: pctConstants.PctTableRowDays },
            { text: pctConstants.PctResultsTableHeadText3TextField1, type: pctConstants.PctTableRowCurrency },
            { text: pctConstants.PctResultsTableHeadText3TextField2, type: pctConstants.PctTableRowCurrency },
            { text: pctConstants.PctResultsTableHeadText3TextField3, type: pctConstants.PctTableRowCurrency }
          ], this, false, false),
        totalIndex: 4
      },
      {
        tabTitleKey: pctConstants.PctResultsTableHeadText4,
        id: this.currentClinicalEffect,
        tabContent: tabOverview,
        totalIndex: 1,
        isLast: true
      }
    ];

    const costTableContent = [
      { tabTitleKey: pctConstants.PctResultsTableHeadTextTextFieldOverview, tabContent: tabOverview },
      { tabTitleKey: pctConstants.PctResultsTableHeadTextTextFieldDetails, tabContent: tabDetails }
    ];
    return costTableContent;
  }

  // hospital stay
  tab0row0regularWardDaysNoPct;
  tab0row0regularWardDaysPct;
  tab0row0regularWardDaysDif;

  tab0row1totalCostRegularWardNoPct;
  tab0row1totalCostRegularWardPct;
  tab0row1totalCostRegularWardDif;

  tab0row2icuDaysNoPct;
  tab0row2icuDaysPct;
  tab0row2icuDaysDif;

  tab0row3costDayIcuNoPct;
  tab0row3costDayIcuPct;
  tab0row3costDayIcuDif;

  tab0row4additionalCostAbrNoPct;
  tab0row4additionalCostAbrPct;
  tab0row4additionalCostAbrDif;

  tab0row5additionalCostCdiffNoPct;
  tab0row5additionalCostCdiffPct;
  tab0row5additionalCostCdiffDif;

  tab0row6totalCostHospitalStayPatientNoPct;
  tab0row6totalCostHospitalStayPatientPct;
  tab0row6totalCostHospitalStayPatientDif;

  // treatments / measures
  tab1row0patientPercentageAntibioticsNoPct;
  tab1row0patientPercentageAntibioticsPct;
  tab1row0patientPercentageAntibioticsDif;

  tab1row1antibioticsDaysNoPct;
  tab1row1antibioticsDaysPct;
  tab1row1antibioticsDaysDif;

  tab1row2totalCostAntibioticsNoPct;
  tab1row2totalCostAntibioticsPct;
  tab1row2totalCostAntibioticsDif;

  tab1row3proportionAdmittedIcuNoPct;
  tab1row3proportionAdmittedIcuPct;
  tab1row3proportionAdmittedIcuDif;

  tab1row4mechanicalVentilationDaysNoPct;
  tab1row4mechanicalVentilationDaysPct;
  tab1row4mechanicalVentilationDaysDif;

  tab1row5totalMechanicalVentilationCostNoPct;
  tab1row5totalMechanicalVentilationCostPct;
  tab1row5totalMechanicalVentilationCostDif;

  tab1row6totalCostTreatmentNoPct;
  tab1row6totalCostTreatmentPct;
  tab1row6totalCostTreatmentDif;

  // blood cultures
  tab2row0patientsSuspectedSepsisBloodCultureNoPct;
  tab2row0patientsSuspectedSepsisBloodCulturePct;
  tab2row0patientsSuspectedSepsisBloodCultureDif;

  tab2row1averageNumberBloodCulturePatientNoPct;
  tab2row1averageNumberBloodCulturePatientPct;
  tab2row1averageNumberBloodCulturePatientDif;

  tab2row2patientsDiagnosedSepsisBloodCultureNoPct;
  tab2row2patientsDiagnosedSepsisBloodCulturePct;
  tab2row2patientsDiagnosedSepsisBloodCultureDif;

  tab2row3totalCostBloodCultureNoPct;
  tab2row3totalCostBloodCulturePct;
  tab2row3totalCostBloodCultureDif;

  // laboratory analyses
  tab3row0totalLaboratoryAnalyesNoPct;
  tab3row0totalLaboratoryAnalyesPct;
  tab3row0totalLaboratoryAnalyesDif;

  tab3row1pctTestsPerPatientNoPct;
  tab3row1pctTestsPerPatientPct;
  tab3row1pctTestsPerPatientDif;

  tab3row2costLaboratoryAnalysisNoPct;
  tab3row2costLaboratoryAnalysisPct;
  tab3row2costLaboratoryAnalysisDif;

  tab3row3costPctTestNoPct;
  tab3row3costPctTestPct;
  tab3row3costPctTestDif;

  tab3row4totalCostLaboratoryAnalysesNoPct;
  tab3row4totalCostLaboratoryAnalysesPct;
  tab3row4totalCostLaboratoryAnalysesDif;

  // total overview
  overviewRow1TotalCostPatientNoPct;
  overviewRow1TotalCostPatientPct;
  overviewRow1TotalCostPatientDif;

  overviewRow0TotalCostPopulationNoPct;
  overviewRow0TotalCostPopulationPct;
  overviewRow0TotalCostPopulationDif;
}
