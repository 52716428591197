import { tableSchema } from '@nozbe/watermelondb';
import { field, date, json } from '@nozbe/watermelondb/decorators';
import Model from './simapp-model';

export const qcAnalyteSchema = tableSchema({
  name: 'qcAnalyte',
  columns: [
    { name: 'b_id', type: 'number', isIndexed: true },
    { name: 'uid', type: 'string', isIndexed: true },
    { name: 'name', type: 'string' },
    { name: 'qcvc_product_family_ids', type: 'string' },
    { name: 'b_created_at', type: 'number' },
    { name: 'b_updated_at', type: 'number' },
    { name: 'created_at', type: 'number' },
    { name: 'updated_at', type: 'number' },
  ]
});

export default class QcAnalyte extends Model {
  static table = 'qcAnalyte'

  @field('b_id') bId;
  @field('uid') uid;
  @field('name') name;
  @json('qcvc_product_family_ids', jsonData => jsonData) qcvcProductFamilyIds;
  @date('b_created_at') bCreatedAt;
  @date('b_updated_at') bUpdatedAt;
  @date('created_at') createdAt;
  @date('updated_at') updatedAt;
}
