import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

@inject('materialStore') @observer
class GroupFilter extends Component {
  onChange = e => this.props.materialStore.setGroup(e.target.value);

  menuItems = () => this.props.materialStore.getGroups().map(group => (
    <MenuItem
      key={group.id}
      value={group.id}
    >
      {group.title}
    </MenuItem>
  ));

  render() {
    const { materialStore, MenuProps } = this.props;
    return (
      <Select
        value={materialStore.group}
        onChange={this.onChange}
        disableUnderline
        MenuProps={MenuProps}
      >
        {this.menuItems()}
      </Select>
    );
  }
}

GroupFilter.wrappedComponent.propTypes = {
  materialStore: PropTypes.object.isRequired,
  MenuProps: PropTypes.object.isRequired,
};
export default GroupFilter;
