/* eslint-disable */
export function getPeriodName(id, qcStore) {
  if (id === 'daily') {
    return qcStore.getText('QCProductSelectionTestViewInstrumentPeriodDaily');
  } if (id === 'weekly') {
    return qcStore.getText('QCProductSelectionTestViewInstrumentPeriodWeekly');
  }
  return qcStore.getText('QCProductSelectionTestViewInstrumentPeriodMonthly');
}

export function getTotalCosts(products) {
  let costs = 0;
  products.map((product) => {
    if (product.totalPrice !== undefined) {
      costs += product.totalPrice;
    }
  });
  return costs;
}

export function getTotalQCVolume(products) {
  let mL = 0;
  products.map((product) => {
    if (product.product.kitVolume !== undefined && product.quantity !== undefined) {
      mL += (product.product.kitVolume * product.quantity);
    }
  });
  return mL;
}

export function round(value) {
  return Math.round(value * 100) / 100;
}

function isAnyInstrumentsLevelTestEnabled (levels, isUnseparatedTestEnabled) {
  for (let i = 0; i < levels.length; i++) {
    if ((levels[i].isEnabled || isUnseparatedTestEnabled) && levels[i].nbRuns > 0) {
      return true;
    }
  }
  return false;
}

function isAnyInstrumentsTestEnabled(tests) {
  for (let i = 0; i < tests.length; i++) {
    let isUnseparatedTestEnabled = false;
    if (!tests[i].isLevelsSeparated && tests[i].isEnabled && tests[i].selectedReagentCatalogNumber) {
      return true;
    }
    if (tests[i].isLevelsSeparated && isAnyInstrumentsLevelTestEnabled(tests[i].levels, isUnseparatedTestEnabled) && tests[i].selectedReagentCatalogNumber) {
      return true;
    }
  }
  return false;
}

export function isAnyInstrumentsDataTestEnabled(instrumentsData) {
  for (let i = 0; i < instrumentsData.length; i++) {
    if (isAnyInstrumentsTestEnabled(instrumentsData[i].tests)) {
      return true;
    }
  }
  return false;
}

export function getItemFromList(id, list) {
  for (let i = 0; i < list.length; i++) {
    if (list[i].id === id) {
      return list[i];
    }
  }
  return undefined;
}

export function getItemsFromList(ids, list) {
  const returnList = [];
  for (let i = 0; i < list.length; i++) {
    if (ids.indexOf(list[i].id) > -1) {
      returnList.push(list[i]);
    }
  }
  return returnList;
}
