import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import { simappColors } from 'shared/lib/theme';
import SimappIcon from 'web/components/_ui/simapp-icon';
import DividerV from 'web/components/_ui/divider-v';
import ContentTypesFilter from './content-types-filter';
import ProductsFilter from './products-filter';
import ProductGroupsFilter from './product-groups-filter';
import LanguagesFilter from './languages-filter';
import GroupFilter from './group-filter';
import MaterialsSort from './materials-sort';
import MaterialsSearch from './materials-search';

const styles = {
  root: {
    marginBottom: 8,
  },
  searchRow: {
    background: simappColors.white,
    display: 'flex',
    height: 46,
    alignItems: 'center',
    borderBottom: `1px solid ${simappColors.paleGrey}`
  },
  filterRow: {
    background: simappColors.white,
    display: 'flex',
    height: 46,
    paddingLeft: 24,
    paddingRight: 24,
    justifyContent: 'space-between',
    borderBottom: `1px solid ${simappColors.paleGrey}`,
  },
  filterGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  chipRow: {
    display: 'flex',
    paddingLeft: 16,
    marginTop: 8,
    '&.wideView': {
      paddingLeft: 4
    },
  },
  chipsWrapper: {
    flex: 1,
  },
  chip: {
    margin: '6px 4px',
    color: simappColors.white,
    background: simappColors.azul,
    '&:focus': {
      color: simappColors.white,
      background: simappColors.azul,
    },
    '& span': {
      color: simappColors.white,
    }
  },
  reset: {
    color: simappColors.strawberry,
    marginTop: 3
  },
  resetText: {
    fontSize: '1.1rem',
    paddingBottom: '0.2em',
    paddingLeft: '0.3em'
  }
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: '50vH',
      boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.75)',
      marginTop: 50,
      marginRight: -15,
    },
  },
  // overwrite weird defaults that lead to horizontal scrolling
  MenuListProps: {
    style: {
      width: '100%',
      padding: 0,
    }
  },
  BackdropProps: { invisible: false, style: { backgroundColor: 'rgba(0, 0, 0, 0.1)' } }
};

@inject('applicationStore', 'materialStore', 'contentTypeStore', 'languageStore', 'productStore') @observer
class MaterialsSearchbar extends Component {
  render() {
    const { materialStore, contentTypeStore, languageStore, productStore, classes } = this.props;
    /**
     * Hint: wideView was introduced, because the UI sketches used smaller padding when the sidebar is closed.
     * This was only done for optimized view on the iPad. Since Laptop browser sizes vary, we don't need this here
     * (Mainly, because it doesn't look good.)
     */
    const wideView = ''; // this.props.applicationStore.sidebarOpen ? '' : 'wideView';
    return (
      <div className={classes.root}>
        <div className={classes.searchRow}>
          <MaterialsSearch />
        </div>
        <div className={classes.filterRow}>
          <div className={classes.filterGroup}>
            <ProductGroupsFilter MenuProps={MenuProps} />
            <DividerV light />
            <ProductsFilter MenuProps={MenuProps} />
            <DividerV light />
            <ContentTypesFilter MenuProps={MenuProps} />
            <DividerV light />
            <LanguagesFilter MenuProps={MenuProps} />
          </div>

          <div className={classes.filterGroup}>
            <GroupFilter MenuProps={MenuProps} />
            <DividerV light />
            <MaterialsSort MenuProps={MenuProps} />
          </div>
        </div>
        {[...productStore.filteredProductsForUI, ...productStore.filteredProductGroupIds, ...productStore.filteredProductSubgroupIds, ...contentTypeStore.filteredContentTypesForUI, ...languageStore.filteredLanguagesForUI].length > 0
        && (
          <div className={`${classes.chipRow} ${wideView}`}>
            <div className={classes.chipsWrapper}>
              {productStore.productGroups.filter(pg => productStore.filteredProductGroupIds.includes(pg.id)).map(entry => (
                <Chip
                  key={entry.id}
                  label={entry.name}
                  className={classes.chip}
                  onDelete={() => {
                    productStore.toggleGroupFilter(entry.id);
                  }}
                  deleteIcon={<SimappIcon icon="x" />}
                />
              ))}
              {productStore.productSubGroupsIndependent.map(entry => (
                <Chip
                  key={entry.id}
                  label={entry.name}
                  className={classes.chip}
                  onDelete={() => {
                    productStore.toggleSubgroupFilter(entry.id);
                  }}
                  deleteIcon={<SimappIcon icon="x" />}
                />
              ))}
              {productStore.productsUnique.filter(l => productStore.filteredProductsForUI.includes(l.id)).map(entry => (
                <Chip
                  key={entry.id}
                  label={entry.name}
                  className={classes.chip}
                  onDelete={() => {
                    productStore.toggleFilter(entry.id, true);
                  }}
                  deleteIcon={<SimappIcon icon="x" />}
                />
              ))}
              {contentTypeStore.contentTypes.filter(l => contentTypeStore.filteredContentTypesForUI.includes(l.id))
                .map(entry => (
                  <Chip
                    key={entry.id}
                    label={entry.name}
                    className={classes.chip}
                    onDelete={() => {
                      contentTypeStore.toggleFilter(entry.id);
                    }}
                    deleteIcon={<SimappIcon icon="x" />}
                  />
                ))}
              {languageStore.languages.filter(l => languageStore.filteredLanguagesForUI.includes(l.id)).map(entry => (
                <Chip
                  key={entry.id}
                  label={entry.name}
                  className={classes.chip}
                  onDelete={() => {
                    languageStore.toggleFilter(entry.id);
                  }}
                  deleteIcon={<SimappIcon icon="x" />}
                />
              ))}
            </div>
            <div className={classes.resetFilter}>
              <Button
                onClick={materialStore.resetAllFilters}
                className={classes.reset}
              >
                <SimappIcon icon="x" />
                <span className={classes.resetText}>Reset all filters</span>
              </Button>
            </div>
          </div>
        )
        }
      </div>
    );
  }
}

MaterialsSearchbar.wrappedComponent.propTypes = {
  materialStore: PropTypes.object.isRequired,
  contentTypeStore: PropTypes.object.isRequired,
  languageStore: PropTypes.object.isRequired,
  productStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MaterialsSearchbar);
