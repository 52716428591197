import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { simappColors } from 'shared/lib/theme';

const styles = {
  buttonWrapper: {
    position: 'relative',
    marginLeft: 8,
    marginRight: 8
  },
  buttonProgress: {
    color: simappColors.azul,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
};

const SaveButton = observer((props) => {
  const { form, classes, label, ...buttonProps } = props;

  return (
    <div className={classes.buttonWrapper}>
      <Button type="submit" variant="contained" color="primary" disabled={!form.isValid || form.saveInProgess} {...buttonProps}>{label}</Button>
      {form.saveInProgess && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );
});

SaveButton.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string
};

SaveButton.defaultProps = {
  label: 'Save'
};

export default withStyles(styles)(SaveButton);
