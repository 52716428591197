/* eslint-disable */

const q = require('q');
const AuthenticationContext = require('expose-loader?AuthenticationContext!adal-angular');
import config from 'shared/config';
import rest from 'shared/lib/rest';

let adal = new AuthenticationContext(config.adal);

const oauthData = {
  isAuthenticated: false, accessToken: '', userName: '', loginError: '', profile: ''
};

let intervalHolder;

const processAdalCallback = () => {
  const hash = window.location.hash;
  if (adal.isCallback(hash)) {
    if (window.parent && window.parent._adalInstance) {
      adal = window.parent._adalInstance;
    }

    if (adal._openedWindows.length > 0) {
      const lastWindow = adal._openedWindows[adal._openedWindows.length - 1];
      if (lastWindow.opener &&
        lastWindow.opener._adalInstance) {
        adal = lastWindow.opener._adalInstance;
      }
    }

    // callback can come from login or iframe request
    adal.verbose(`Processing the hash: ${hash}`);
    const requestInfo = adal.getRequestInfo(hash);
    adal.saveTokenFromHash(requestInfo);
    window.location.hash = '';
    // Return to callback if it is sent from iframe
    const callback = adal._callBackMappedToRenewStates[requestInfo.stateResponse] || adal.callback;

    if (requestInfo.stateMatch) {
      if (callback && typeof callback === 'function') {
        // Call within the same context without full page redirect keeps the callback
        if (requestInfo.requestType === adal.REQUEST_TYPE.RENEW_TOKEN) {
          // Idtoken or Accestoken can be renewed
          if (requestInfo.parameters.access_token) {
            callback(adal._getItem(adal.CONSTANTS.STORAGE.ERROR_DESCRIPTION), requestInfo.parameters.access_token);
            return;
          }

          if (requestInfo.parameters.id_token) {
            callback(adal._getItem(adal.CONSTANTS.STORAGE.ERROR_DESCRIPTION), requestInfo.parameters.id_token);
          }
        }
      } else {
        // normal full login redirect happened on the page
        updateDataFromCache(adal.config.loginResource);
        if (oauthData.userName) {
          // IDtoken is added as token for the app
          window.setTimeout(() => {
            updateDataFromCache(adal.config.loginResource);
            // redirect to login requested page
            const loginStartPage = adal._getItem(adal.CONSTANTS.STORAGE.START_PAGE);
            if (loginStartPage) {
              window.location.path(loginStartPage);
            }
          }, 1);
        }
      }
    }
  }
};

const updateDataFromCache = (resource) => {
  // only cache lookup here to not interrupt with events
  const token = adal.getCachedToken(resource);
  oauthData.isAuthenticated = token !== null && token.length > 0;
  oauthData.accessToken = token;
  const user = adal.getCachedUser() || {
    userName: ''
  };
  oauthData.userName = user.userName;
  oauthData.profile = user.profile;
  oauthData.loginError = adal.getLoginError();
};

const isAuthenticated = () => {
  const deferred = q.defer();
  window.setTimeout(() => {
    updateDataFromCache(adal.config.loginResource);
    if (!adal._renewActive && !oauthData.isAuthenticated && !oauthData.userName) {
      if (!adal._getItem(adal.CONSTANTS.STORAGE.FAILED_RENEW)) {
        // Idtoken is expired or not present
        adal.acquireToken(adal.config.loginResource, (error, tokenOut) => {
          if (error) {
            adal.error('adal:loginFailure', 'auto renew failure');
            deferred.reject();
          } else if (tokenOut) {
            oauthData.isAuthenticated = true;
            oauthData.accessToken = tokenOut;
            refreshToken();
            deferred.resolve();
          } else {
            deferred.reject();
          }
        });
      } else {
        refreshToken();
        deferred.resolve();
      }
    } else {
      refreshToken();
      deferred.resolve();
    }
  }, 10);

  return deferred.promise;
};
const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(window.atob(base64));
};
const refreshToken = () => {
  const interval = 60000; // ms = 1min
  const checkMin = Math.floor(interval / 500); // interval in seconds two times
  intervalHolder = setInterval(() => {
    if (oauthData.profile.exp < Math.floor(Date.now() / 1000) + checkMin) {
      adal.acquireToken(adal.config.loginResource, (error, tokenOut) => {
        if (error) {
          adal.error('adal:loginFailure', 'auto renew failure');
        } else if (tokenOut) {
          adal.info('auto renew worked')
          oauthData.isAuthenticated = true;
          oauthData.accessToken = tokenOut;
          // update exp. etc
          oauthData.profile = parseJwt(tokenOut);
          rest.defaults.headers.common.Authorization = tokenOut;
        } else {
          adal.error('auto renew unkown failure')
        }
      });
    } else adal.info("token still valid")
  }, interval);
};

const makeRequest = (settings) => {
  const deferred = q.defer();
  const xhr = new XMLHttpRequest();
  xhr.timeout = 60000;
  xhr.onreadystatechange = function () {
    if (this.readyState === 4) {
      if (this.status.toString()[0] == 2) {  // in general 200, but sometimes e.g. 202
        deferred.resolve(this.response);
      } else if (this.status >= 400) {
        deferred.reject(this.response);
      }
    }
  };
  xhr.ontimeout = function () {
    console.error('HttpRequest timeout');
    deferred.reject();
  }
  xhr.open(settings.method || 'GET', settings.url, true);

  for (const header in settings.headers) {
    xhr.setRequestHeader(header, settings.headers[header]);
  }

  xhr.responseType = settings.dataType || 'json';
  xhr.send(settings.data);

  return deferred.promise;
};

const adalRequest = (settings) => {
  const deferred = q.defer();

  isAuthenticated().then(() => {
    const resource = adal.getResourceForEndpoint(settings.url);

    if (!resource) {
      adal.info(`No resource configured for '${settings.url}'`);
      deferred.reject();
      return deferred.promise;
    }

    const tokenStored = adal.getCachedToken(resource);
    if (tokenStored) {
      if (!settings.headers) {
        settings.headers = {
        };
      }

      settings.headers.Authorization = `Bearer ${tokenStored}`;
      if(!settings.headers['Content-Type']) {
        settings.headers['Content-Type'] = 'application/json';
      }

      makeRequest(settings).then(deferred.resolve, deferred.reject);
    } else {
      let isEndpoint = false;

      for (const endpointUrl in adal.config.endpoints) {
        if (settings.url.indexOf(endpointUrl) > -1) {
          isEndpoint = true;
        }
      }

      if (adal.loginInProgress()) {
        adal.info('Login already in progress');
        deferred.reject();
      } else if (isEndpoint) {
        adal.acquireToken(resource, (error, tokenOut) => {
          if (error) {
            deferred.reject();
            adal.error(error);
          } else if (tokenOut) {
            adal.verbose('Token is available');
            if (!settings.headers) {
              settings.headers = {
              };
            }
            settings.headers.Authorization = `Bearer ${tokenOut}`;
            makeRequest(settings).then(deferred.resolve, deferred.reject);
          }
        });
      }
    }
  }, () => {
    // adal.login();
  });

  return deferred.promise;
};

const login = (force=false, prompt=false) => {
  if (force && prompt) {
    adal.config.extraQueryParameter += '&prompt=consent';
  } else if(force) {
    adal.config.extraQueryParameter += '&prompt=login';
  // } else if(prompt === true) {
    // adal.config.extraQueryParameter += `&prompt=consent&login_hint=${oauthData.userName}`;
  } else if(oauthData.userName.length > 0 ){
    adal.config.extraQueryParameter += `&login_hint=${oauthData.userName}`;
  }
  adal.login();
};

const logout = () => {
  clearInterval(intervalHolder);
  adal.clearCache();
};

export default {
  adalRequest,
  isAuthenticated,
  processAdalCallback,
  login,
  logout,
  oauthData
};
