import { action } from 'mobx';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Calendar from './calendar';

const dayInMilliseconds = 24 * 60 * 60 * 1000;

@inject('calendarStore') @observer
class EventsCalendar extends Component {
  @action
  onDateChange = (date) => {
    this.props.calendarStore.date = new Date(date);
  };

  buildPerDayEvents = events => events
    .map((event) => {
      const eventStartDate = new Date(event.start);
      const eventEndDate = new Date(event.end);
      const timeDifference = Math.abs(eventStartDate.getTime() - eventEndDate.getTime());
      const dayDifference = Math.ceil(timeDifference / dayInMilliseconds);
      const dayEvents = [];
      for (let i = 0; i < dayDifference; i++) {
        const { id, title, start } = event;
        const startDate = new Date(start);
        const timeStampOfDay = startDate.setDate(eventStartDate.getDate() + i);
        const startAndEndDate = new Date(timeStampOfDay);
        dayEvents.push({
          id,
          title,
          start: startAndEndDate,
          end: startAndEndDate,
        });
      }
      return dayEvents;
    })
    .reduce((memo, event) => [...memo, ...event], []);

  render() {
    const { events } = this.props.calendarStore;
    const perDayEvents = this.buildPerDayEvents(events);
    return (
      <Calendar
        events={perDayEvents}
        dayChange={event => this.onDateChange(event)}
      />
    );
  }
}

EventsCalendar.wrappedComponent.propTypes = {
  calendarStore: PropTypes.object.isRequired,
};

export default EventsCalendar;
