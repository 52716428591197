import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { simappColors } from 'shared/lib/theme';
import SimappIcon from 'web/components/_ui/simapp-icon';
import MaterialsSearchbar from './materials-searchbar';
import MaterialsGrid from './materials-grid';

const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  grid: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    paddingLeft: 16,
    '&.wideView': {
      paddingLeft: 8
    }
  },
  counter: {
    fontSize: 12,
    lineHeight: '20px',
    color: simappColors.steelGrey,
    fontWeight: 500,
    textTransform: 'uppercase'
  },
  paperWrapper: {
    overflow: 'hidden',
    flex: 1,
    marginLeft: -10,
    '&.wideView': {
      marginLeft: -4
    },
    '& > div > div': {
      marginTop: 6
    },
    '& > div > div > div': {
      marginTop: -6,
      position: 'relative'
    }
  },
  nothingWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100vH - 7.5em)',
    justifyContent: 'center',
    alignItems: 'center',
    '& > span': {
      marginBottom: '2em'
    }
  }
};

@inject('materialStore') @observer
class Materials extends Component {
  render() {
    const { materialStore, classes } = this.props;
    /**
     * Hint: wideView was introduced, because the UI sketches used smaller padding when the sidebar is closed.
     * This was only done for optimized view on the iPad. Since Laptop browser sizes vary, we don't need this here
     * (Mainly, because it doesn't look good.)
     */
    const wideView = ''; // this.props.applicationStore.sidebarOpen ? '' : 'wideView';
    materialStore.materialsUpdated; // trigger UI update
    if (!materialStore.hasDataLoaded) {
      return (<div />);
    }

    return (
      <div className={classes.root}>
        <MaterialsSearchbar />
        { materialStore.materialCount === 0
          && (
            <div className={classes.nothingWrapper}>
              <SimappIcon icon="empty" />
              <Typography variant="h3">No Materials found</Typography>
            </div>
          )
        }
        { materialStore.materialCount > 0
          && (
            <div className={`${classes.grid} ${wideView}`}>
              <Typography className={classes.counter}>{materialStore.materialCount} {materialStore.materialCount === 1 ? 'Material' : 'Materials'} found</Typography>
              <div className={`${classes.paperWrapper} ${wideView}`} id="materials-grid-wrapper">
                <MaterialsGrid />
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

Materials.wrappedComponent.propTypes = {
  materialStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Materials);
