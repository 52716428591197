import { observable, runInAction, computed } from 'mobx';
import { persist } from 'mobx-persist';
import rest from 'shared/lib/rest';
import { merge } from 'shared/lib/helpers';
import createStorage from './create-storage';
import { kryptorStore } from '.';

const assayRoute = '/consumables_calculator_assay';
const multiControlRoute = '/consumables_calculator_multi_control';

class ConsumablesCalculatorStore {
  constructor() {
    createStorage('consumablesCalculatorStore', this).then(() => {
    });
  }

  @persist('list') @observable assays = [];
  @persist('list') @observable multiControls = [];

  loadData(forceReload = false, lastUpdated = null) {
    const startTime = new Date().getTime();
    return new Promise((resolve) => {
      let params = '?';
      if (this.hasDataLoaded && !forceReload && lastUpdated !== null) {
        params += `since=${lastUpdated}`;
      } else if (forceReload) {
        runInAction(() => {
          this.assays = [];
          this.multiControls = [];
          kryptorStore.kryptorAssayList = [];
          kryptorStore.consumablesData = [];
          kryptorStore.multiControlList = [];
        });
      }

      Promise
        .all([rest.get(assayRoute + params), rest.get(multiControlRoute + params)])
        .then((response) => {
          runInAction(() => {
            response[0].data.forEach((entry) => {
              if (!Array.isArray(entry.multi_control_ids)) {
                entry.multi_control_ids = [];
              }
            });
            merge(this.assays, response[0].data, 'id', 'title');
            merge(this.multiControls, response[1].data);
            // remove deleted selected assay or multi controls, if selected
            kryptorStore.kryptorAssayList.forEach((item, index) => {
              if (typeof this.assays.find(m => m.id === item.assay.id) === 'undefined') {
                kryptorStore.kryptorAssayList.splice(index, 1);
              }
              // Hint: we should remove deleted multi controls from item.multi_control_ids and item.multi_control_id, too
              // but the IDs 1 and 2 are hardcoded in kryptor_assay.js, so they may not be deleted anyway
              // https://git.world-of-biomarkers.com/simapp/simapp-webapp/issues/86
            });
            console.log(`${new Date().getTime() - startTime}ms - ${this.constructor.name}`);
            resolve();
          });
        })
        .catch((error) => {
          console.log(error);
          resolve();
        });
    });
  }

  @computed get hasDataLoaded() {
    return !!this.assays.length && !!this.multiControls.length;
  }
}

export default new ConsumablesCalculatorStore();
