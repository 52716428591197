
export default function highNumberFix(number, precision) {
  const string = number.toFixed(precision !== undefined ? precision : 2);
  if (string.indexOf('e+') < 0) {
    return precision !== undefined ? string : `${number}`;
  }

  let result = parseFloat(number.toString().split('+')[1]);
  if (result > 20) {
    result -= 20;
    const num = number / (10 ** result);
    result = num.toString() + new Array(result + 1).join('0');
  }
  if (precision > 0) {
    result += '.';
    for (let i = 0; i < precision; i++) {
      result += '0';
    }
  }
  return result;
}
