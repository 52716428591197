import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import Close from '@material-ui/icons/Close';
import Done from '@material-ui/icons/Done';
import { simappColors, simappConstants } from 'shared/lib/theme';
import SimappIcon from 'web/components/_ui/simapp-icon';
import SidebarListitem from 'web/components/_ui/sidebar-listitem';
import Sidebar from 'web/components/_ui/sidebar';
import SidebarItem from './sidebar-item';

const styles = theme => ({
  root: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: simappColors.darkGrey,
    width: simappConstants.sidebarMinWidth,
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: 'hidden',
    '& h3': {
      whiteSpace: 'nowrap'
    },
    '&.expanded': {
      width: simappConstants.sidebarOpenWidth
    },
  },
  toggler: {
    minWidth: simappConstants.sidebarMinWidth,
    width: simappConstants.sidebarMinWidth,
    alignSelf: 'flex-end',
    color: simappColors.steelGrey,
  },
  profilePic: {
    width: simappConstants.profilePicMin,
    height: simappConstants.profilePicMin,
    padding: 0,
    minWidth: 0,
    marginTop: '1em',
    alignSelf: 'center',
    '&.expanded': {
      width: simappConstants.profilePic,
      height: simappConstants.profilePic,
      marginTop: '-1.5em',
      marginBottom: '1em',
    },
    '& img': {
      width: simappConstants.profilePicMin,
      height: simappConstants.profilePicMin,
      borderRadius: '50%',
      objectFit: 'cover'
    },
    '&.expanded img': {
      width: simappConstants.profilePic,
      height: simappConstants.profilePic,
    }
  },
  current: {
    backgroundColor: simappColors.dark,
    '& svg *': {
      color: simappColors.pumpkin
    },
    '& h3': {
      color: simappColors.white
    }
  },
  topNav: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '& *': {
      color: simappColors.steelGrey
    },
  },
  bottomNav: {
    '& > div': {
      paddingLeft: simappConstants.sidebarPadding,
      paddingRight: simappConstants.sidebarPadding,
    },
    '& *': {
      color: simappColors.steelGrey
    },
    '& > div:hover *': {
      color: simappColors.white
    },
    '& > div:hover .listitem-icon *': {
      color: simappColors.pumpkin
    },
  },
  title: {
    textAlign: 'center',
    color: simappColors.azul,
    whiteSpace: 'nowrap'
  },
  materialsListWrapper: {
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  materialsOpen: {
    padding: '8px 16px',
    borderBottom: `2px solid ${simappColors.dark}`,
  },
  editFavShare: {
    backgroundColor: simappColors.dark,
    paddingTop: 6,
    paddingBottom: 6,
  },
  editFavShareMin: {
    backgroundColor: simappColors.dark,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 0,
    paddingRight: 0
  },
  editFavShareMinLast: {
    marginBottom: 100
  },
  editFavShareButton: {
    width: '50%',
    display: 'block',
    textAlign: 'center',
    '& *': {
      color: simappColors.azul,
    },
    '&:hover *': {
      color: lighten(simappColors.azul, 0.4)
    }
  },
  editFavShareButtonMin: {
    minWidth: 0,
    paddingLeft: 0,
    paddingRight: 0,
    width: '100%'
  },
  editFavShareIcon: {
    margin: 0,
  }
});

@inject('routing', 'applicationStore', 'countryStore', 'materialStore') @observer
class PresentationSidebar extends Component {
  render() {
    const { location, push } = this.props.routing;
    const { sidebarOpen } = this.props.applicationStore;
    const materials = this.props.materialStore.getCurrent();
    return (
      <Fragment>
        { sidebarOpen
          ? (
            <Fragment>
              <List component="nav" className={this.props.classes.topNav}>
                <Typography variant="h5" gutterBottom className={this.props.classes.title}>Presentation<br />Mode</Typography>

                {materials.length > 0
              && (
                <Fragment>
                  <Typography className={this.props.classes.materialsOpen}>Materials open: {materials.length}</Typography>
                  <div className={this.props.classes.materialsListWrapper}>
                    {/* check in case a material has been deleted but is still in current */}
                    {materials.map(material => (typeof material === 'undefined' ? null : (
                      <SidebarItem material={material} key={material.uid} />
                    )))
                    }
                  </div>
                </Fragment>
              )
                }
              </List>
              <List component="nav" className={this.props.classes.bottomNav}>
                { this.props.applicationStore.currentlyPresenting && !this.props.applicationStore.sharingMode
                && (
                  <ListItem className={this.props.classes.editFavShare}>
                    <ButtonBase className={this.props.classes.editFavShareButton} onClick={() => { this.props.materialStore.toggleFavorite(this.props.applicationStore.currentlyPresenting.uid); }}>
                      <ListItemIcon className={this.props.classes.editFavShareIcon}>
                        <SimappIcon icon={this.props.applicationStore.currentlyPresenting.favorite.get() ? 'heartFilled' : 'heartEmpty'} />
                      </ListItemIcon>
                      <Typography>Favorite</Typography>
                    </ButtonBase>
                    { this.props.applicationStore.currentlyPresenting.shareable && !this.props.applicationStore.isDistributor
                    && (
                      <ButtonBase className={this.props.classes.editFavShareButton} onClick={() => { this.props.applicationStore.startSharing(this.props.applicationStore.currentlyPresenting.uid); }}>
                        <ListItemIcon className={this.props.classes.editFavShareIcon}>
                          <SimappIcon icon="share" />
                        </ListItemIcon>
                        <Typography>Share</Typography>
                      </ButtonBase>
                    )}
                  </ListItem>
                )
                }
                { this.props.applicationStore.sharingMode && !this.props.applicationStore.sharingOpen
                && (
                  <ListItem className={this.props.classes.editFavShare}>
                    <Button className={this.props.classes.editFavShareButton} onClick={this.props.applicationStore.cancelSelectMore}>
                      <Typography>Cancel</Typography>
                    </Button>
                    <Button className={this.props.classes.editFavShareButton} onClick={this.props.applicationStore.doneSelectMore}>
                      <Typography>Done</Typography>
                    </Button>
                  </ListItem>
                )
                }
                <Divider />
                <SidebarListitem onClick={() => push('/presentation-materials')} icon="marketingMaterials" primary="All Material" className={location.pathname === '/presentation-materials' ? this.props.classes.current : ''} />
                <Divider />
                <SidebarListitem onClick={() => push('/materials')} icon="mainMenu" primary="Main Menu" disabled={this.props.applicationStore.sharingMode} />
                <Divider />
              </List>
            </Fragment>
          )
          : (
            <Fragment>
              { this.props.applicationStore.sharingMode && !this.props.applicationStore.sharingOpen
                && (
                  <Fragment>
                    <List component="nav" className={this.props.classes.topNav} />
                    <List component="nav" className={this.props.classes.bottomNav}>
                      <ListItem className={this.props.classes.editFavShareMin}>
                        <Button className={`${this.props.classes.editFavShareButton} ${this.props.classes.editFavShareButtonMin}`} onClick={this.props.applicationStore.cancelSelectMore}>
                          <Close />
                        </Button>
                      </ListItem>
                      <ListItem className={`${this.props.classes.editFavShareMin} ${this.props.classes.editFavShareMinLast}`}>
                        <Button className={`${this.props.classes.editFavShareButton} ${this.props.classes.editFavShareButtonMin}`} onClick={this.props.applicationStore.doneSelectMore}>
                          <Done />
                        </Button>
                      </ListItem>
                    </List>
                  </Fragment>
                )}
            </Fragment>
          )
        }
      </Fragment>
    );
  }
}

PresentationSidebar.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  applicationStore: PropTypes.object.isRequired,
  materialStore: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired,
};

export default Sidebar(withStyles(styles)(PresentationSidebar));
