import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { syncHistoryWithStore } from 'mobx-react-router';
import { createBrowserHistory } from 'history';
import { Switch, Route, Router, Redirect } from 'react-router-dom';
import Settings from 'web/components/settings/settings';
import Materials from 'web/components/materials/materials';
import Presentation from 'web/components/presentation/presentation';
import Dashboard from 'web/components/dashboard/dashboard';
import NewPresentation from 'web/components/create-presentation/new-presentation';
import PctReferences from 'web/components/pct/pct-references';
import PctPopulation from 'web/components/pct/pct-population';
import PctHealthcare from 'web/components/pct/pct-healthcare';
import PctClinicalEffect from 'web/components/pct/pct-clinicaleffect';
import PctResults from 'web/components/pct/pct-results';
import DefaultSidebar from 'web/components/default-sidebar';
import PctStart from 'web/components/pct/pct-start';
import { routing } from 'shared/stores';
import { simappConstants } from 'shared/lib/theme';
import presentationSidebar from 'web/components/presentation/presentation-sidebar';
import ComponentsDemo from 'web/components/components-demo';
import KryptorCalculator from 'web/components/kryptor/kryptor-calculator';
import KryptorResult from 'web/components/kryptor/kryptor-result';
import pctInfo from 'web/components/pct/pct-info';
import QcCalculator from 'web/components/qc/qc-calculator';
import QcNewCustomer from 'web/components/qc/qc-new-customer';
import QcExistingCustomer from 'web/components/qc/qc-existing-customer';
import QcExistingCustomerReview from 'web/components/qc/qc-existing-customer-review';
import CopeptinStart from 'web/components/copeptin/copeptin-start';
import CopeptinPopulation from 'web/components/copeptin/copeptin-population';
import CopeptinInputs from 'web/components/copeptin/copeptin-inputs';
import CopeptinInputsPercent from 'web/components/copeptin/copeptin-inputs-percent';
import CopeptinResults from 'web/components/copeptin/copeptin-results';
import CopeptinInfo from 'web/components/copeptin/copeptin-info';
import CopeptinReferences from 'web/components/copeptin/copeptin-references';
import QcProposalReview from './qc/qc-proposal-review';
import QcOptionsProposal from './qc/qc-options-proposal';
import QcProductSelection from './qc/qc-product-selection';
import HEModelScreen from './he-model/he-model-screen';

export const history = syncHistoryWithStore(createBrowserHistory(), routing);

const styles = theme => ({
  root: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    marginLeft: simappConstants.sidebarMinWidth,
    transition: theme.transitions.create('margin-left', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '&.presentationmode': {
      marginLeft: 0
    }
  }
});

// each entry consists of: path, component, exact (bool)
const thermoOnlyRoutes = [
  ['/settings', Settings],
  ['/components-demo', ComponentsDemo],
];
const heModelRoutes = [
  ['/he-models', HEModelScreen, true],
];
const pctRoutes = [
  ['/he-models/pct-calculator', PctStart, true],
  ['/he-models/pct-calculator/references', PctReferences],
  ['/he-models/pct-calculator/population', PctPopulation],
  ['/he-models/pct-calculator/healthcare', PctHealthcare],
  ['/he-models/pct-calculator/clinicaleffect', PctClinicalEffect],
  ['/he-models/pct-calculator/clinicaleffect-lrti', PctClinicalEffect],
  ['/he-models/pct-calculator/clinicaleffect-sepsis', PctClinicalEffect],
  ['/he-models/pct-calculator/pct-algorithm', pctInfo],
  ['/he-models/pct-calculator/pct-algorithm-sepsis', pctInfo],
  ['/he-models/pct-calculator/pct-algorithm-lrti', pctInfo],
  ['/he-models/pct-calculator/results', PctResults],
];
const copeptinRoutes = [
  ['/he-models/copeptin-calculator', CopeptinStart, true],
  ['/he-models/copeptin-calculator/copeptin-algorithm', CopeptinInfo],
  ['/he-models/copeptin-calculator/copeptin-references', CopeptinReferences],
  ['/he-models/copeptin-calculator/population', CopeptinPopulation],
  ['/he-models/copeptin-calculator/inputs', CopeptinInputs],
  ['/he-models/copeptin-calculator/inputs-percent', CopeptinInputsPercent],
  ['/he-models/copeptin-calculator/results', CopeptinResults],
];
const kryptorRoutes = [
  ['/kryptor-calculator', KryptorCalculator, true],
  ['/kryptor-calculator/results', KryptorResult],
];
const qcvcRoutes = [
  ['/qc-calculator', QcCalculator, true],
  ['/qc-calculator/new-customer', QcNewCustomer],
  ['/qc-calculator/existing-customer', QcExistingCustomer],
  ['/qc-calculator/existing-customer-review', QcExistingCustomerReview],
  ['/qc-calculator/product-selection', QcProductSelection],
  ['/qc-calculator/options-proposal', QcOptionsProposal],
  ['/qc-calculator/proposal-review', QcProposalReview],
];
const defaultRoutes = [
  ['/', Dashboard, true],
];
const materialsRoutes = [
  ['/materials', Materials],
  ['/presentation-materials', Materials],
  ['/presentation', Presentation, true],
  ['/presentation/:uid', Presentation],
  ['/create-presentation', NewPresentation, true],
  ['/create-presentation/:uid', NewPresentation],
];
const accRoutes = [
];

@inject('routing', 'applicationStore') @observer
class MainRouter extends Component {
  render() {
    const path = this.props.routing.location.pathname.split('/');

    // if logged in but unknown user, always force settings page
    if (this.props.applicationStore.userLoaded === false) {
      return <Settings />;
    }

    // if we are in the presentaion area, we need to add the current presentation to the list of active
    // FIXME: This shouldn't be in render() and we need to check if the uid is valid
    this.props.applicationStore.setPresentationMode(path[1] === 'presentation');

    const presentationModeClass = this.props.applicationStore.presentationMode ? 'presentationmode' : '';

    return (
      <Router history={history}>
        <Fragment>
          <Switch>
            <Route path="/presentation" component={presentationSidebar} />
            <Route path="/presentation-materials" component={presentationSidebar} />
            <Route path="/" component={DefaultSidebar} />
          </Switch>
          <div className={`${this.props.classes.root} ${presentationModeClass}`} id="MainRouter">
            <Switch>
              {!this.props.applicationStore.isDistributor && (
                thermoOnlyRoutes.map(row => (<Route path={row[0]} component={row[1]} exact={row[2]} key={`t:${row[0]}`} />))
              )}
              {this.props.applicationStore.canAccessMaterials && (
                materialsRoutes.map(row => (<Route path={row[0]} component={row[1]} exact={row[2]} key={`t:${row[0]}`} />))
              )}
              {this.props.applicationStore.canAccessHeModels && (
                heModelRoutes.map(row => (<Route path={row[0]} component={row[1]} exact={row[2]} key={`t:${row[0]}`} />))
              )}
              {this.props.applicationStore.canAccessPct && (
                pctRoutes.map(row => (<Route path={row[0]} component={row[1]} exact={row[2]} key={`t:${row[0]}`} />))
              )}
              {this.props.applicationStore.canAccessCopeptin && (
                copeptinRoutes.map(row => (<Route path={row[0]} component={row[1]} exact={row[2]} key={`t:${row[0]}`} />))
              )}
              {this.props.applicationStore.canAccessQcvc && (
                qcvcRoutes.map(row => (<Route path={row[0]} component={row[1]} exact={row[2]} key={`t:${row[0]}`} />))
              )}
              {this.props.applicationStore.canAccessKryptor && (
                kryptorRoutes.map(row => (<Route path={row[0]} component={row[1]} exact={row[2]} key={`t:${row[0]}`} />))
              )}
              {this.props.applicationStore.canAccessAcc && (
                accRoutes.map(row => (<Route path={row[0]} component={row[1]} exact={row[2]} key={`t:${row[0]}`} />))
              )}
              {defaultRoutes.map(row => (<Route path={row[0]} component={row[1]} exact={row[2]} key={`t:${row[0]}`} />))}
              <Redirect to="/" />
            </Switch>
          </div>
        </Fragment>
      </Router>
    );
  }
}

MainRouter.wrappedComponent.propTypes = {
  applicationStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired,
};

export default withStyles(styles)(MainRouter);
