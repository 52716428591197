import React from 'react';
import PropTypes from 'prop-types';
import { useLocalStore, useObserver } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5em',
  },
  labelWrapper: {
    width: '25%',
    display: 'flex',
    position: 'relative',
    height: 32
  },
  label: {
    position: 'static',
    transform: 'none',
    alignSelf: 'center',
    whiteSpace: 'nowrap',
    width: '100%'
  },
  errorMessage: {
    whiteSpace: 'nowrap',
    position: 'absolute',
    bottom: -6
  },
  inputField: {
    flex: 1,
  },
  rootVertical: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'flex-start',
    '& textarea': {
      padding: 0,
      overflowY: 'auto'
    },
    MuiInput: {
      root: { width: '100%' }
    }
  },
  inputVertical: {
    width: '100%',
    '& div': {
      overflowY: 'auto',
      height: '100%'
    },
    '& textarea': {
      minHeight: '100%'
    }
  }
};

const InputField = (props) => {
  const { fields, name, onChange } = props;
  const rootClassVertical = fields[name].vertical ? props.classes.rootVertical : null;
  const inputClassVertical = fields[name].vertical ? props.classes.inputVertical : null;

  const state = useLocalStore(() => ({
    fieldType: typeof fields[name].type !== 'undefined' ? fields[name].type : 'text',
    inputRef: null
  }));

  // make password visible/hidden and set focus and cursor position
  const togglePasswordVisibility = () => {
    runInAction(() => {
      state.fieldType = (state.fieldType === 'password') ? 'text' : 'password';
      const selectionStart = state.inputRef.selectionStart;
      const selectionEnd = state.inputRef.selectionEnd;
      state.inputRef.focus();
      // window.setTimeout is needed due to chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=32865
      window.setTimeout(() => {
        state.inputRef.setSelectionRange(selectionStart, selectionEnd);
      }, 0);
    });
  };

  return useObserver(() => (
    <FormControl
      error={!!fields[name].error}
      disabled={fields[name].disabled}
      required={fields[name].required}
      className={`${props.classes.root} ${rootClassVertical}`}
    >
      { !!fields[name].label
      && (
        <div className={props.classes.labelWrapper}>
          <InputLabel htmlFor={name} disableAnimation shrink={false} className={props.classes.label}>{fields[name].label}</InputLabel>
          <FormHelperText id={`${name}-error`} className={props.classes.errorMessage}>{fields[name].error}</FormHelperText>
        </div>
      )
      }
      <Input
        id={name}
        value={fields[name].value}
        onChange={onChange}
        placeholder={fields[name].placeholder}
        multiline={fields[name].multiline}
        className={`${props.classes.inputField} ${inputClassVertical}`}
        disableUnderline
        rows={fields[name].rows}
        type={state.fieldType}
        autoFocus={props.autoFocus}
        inputRef={(input) => { runInAction(() => { state.inputRef = input; }); }}
      />
      { fields[name].type === 'password' && (
        <Button onClick={togglePasswordVisibility} title={state.fieldType === 'password' ? 'Show Password' : 'Hide Password'}>
          { state.fieldType === 'password' ? <Visibility /> : <VisibilityOff />}
        </Button>
      )}
    </FormControl>
  ));
};

InputField.propTypes = {
  classes: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
};

InputField.defaultProps = {
  autoFocus: false,
};

export default withStyles(styles)(InputField);
