import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import DatabaseProvider from '@nozbe/watermelondb/DatabaseProvider';
import Adal from 'shared/lib/adal-request';
import stores from 'shared/stores';
import database from 'shared/stores/database';
import App from './app';

const Main = () => (
  <Provider {...stores}>
    <DatabaseProvider database={database}>
      <App />
    </DatabaseProvider>
  </Provider>
);

// initialize Adal for Azure oAuth
Adal.processAdalCallback();
if (window === window.parent) {
  ReactDOM.render(<Main />, document.getElementById('app'));
}
