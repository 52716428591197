import { observable, runInAction, toJS, action, computed } from 'mobx';
import { persist } from 'mobx-persist';
import rest from 'shared/lib/rest';
import { merge } from 'shared/lib/helpers';
import createStorage from './create-storage';

const route = '/topic';

/* eslint camelcase: 0 */
class Topic {
  @persist @observable id;
  @persist @observable name;
  @persist('list') @observable product_ids = [];
}

class TopicStore {
  constructor() {
    createStorage('topicStore', this).then(() => {
    });
  }

  @persist('list', Topic) @observable topics = [];

  @observable filteredTopics = [];
  @observable disabledTopics = {};

  loadData(forceReload = false, lastUpdated = null) {
    const startTime = new Date().getTime();
    return new Promise((resolve) => {
      let params = '?';
      if (this.hasDataLoaded && !forceReload && lastUpdated !== null) {
        params += `since=${lastUpdated}`;
      // } else if (forceReload) {
      //   runInAction(() => {
      //     this.topics = [];
      //   });
      }

      rest.get(route + params).then((response) => {
        runInAction(() => {
          response.data.forEach((entry) => {
            entry.name = entry.title;
          });
          if (response.data.length > 0) {
            merge(this.topics, response.data, 'id', 'name');
            this.resetFilters();
          }
          console.log(`${new Date().getTime() - startTime}ms - ${this.constructor.name}`);
          resolve();
        });
      }).catch((error) => {
        console.log('Could not load topics', error.response.data);
        resolve();
      });
    });
  }

  @computed get hasDataLoaded() {
    return !!this.topics.length;
  }

  getTopicName(id) {
    const result = this.topics.find(l => l.id === id);
    return typeof result !== 'undefined' ? result.name : '';
  }

  getProductIdsByIds(ids) {
    const result = [];
    this.topics.forEach((topic) => {
      if (ids.includes(topic.id)) {
        topic.product_ids.forEach((productId) => {
          if (result.indexOf(productId) < 0) {
            result.push(productId);
          }
        });
      }
    });
    return result;
  }

  get filteredTopicsForUI() {
    return this.filteredTopics.length === this.topics.length ? [] : toJS(this.filteredTopics);
  }

  @action setFilter(f) {
    if (f.length === 0) {
      this.resetFilters();
    } else {
      this.filteredTopics = f;
    }
  }

  @action toggleFilter(id) {
    if (this.filteredTopicsForUI.length > 0) {
      const index = this.filteredTopics.indexOf(id);
      if (index > -1) {
        this.filteredTopics.splice(index, 1);
        if (this.filteredTopics.length === 0) {
          this.resetFilters();
        }
      } else {
        this.filteredTopics.push(id);
      }
    } else {
      this.filteredTopics = [id];
    }
  }

  @action resetFilters = () => {
    this.filteredTopics = this.topics.map(l => l.id);
  }
}

export default new TopicStore();
