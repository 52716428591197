import { tableSchema } from '@nozbe/watermelondb';
import { field, date, json } from '@nozbe/watermelondb/decorators';
import camelcaseObjectDeep from 'shared/lib/camelcase-object-deep';
import Model from './simapp-model';

export const qcProposalSchema = tableSchema({
  name: 'qcProposal',
  columns: [
    { name: 'b_id', type: 'number', isIndexed: true },
    { name: 'uid', type: 'string', isIndexed: true },
    { name: 'customer_name', type: 'string' },
    { name: 'customer_number', type: 'string' },
    { name: 'contact_name', type: 'string' },
    { name: 'customer_description', type: 'string' },
    { name: 'contact_title', type: 'string' },
    { name: 'contact_email', type: 'string' },
    { name: 'contact_phone', type: 'string' },
    { name: 'country_id', type: 'number' },
    { name: 'working_days_per_week', type: 'number' },
    { name: 'contract_period', type: 'number' },
    { name: 'status', type: 'string' },
    { name: 'associated_representative', type: 'number' },
    { name: 'qcvc_data', type: 'string' },
    { name: 'b_created_at', type: 'number' },
    { name: 'b_updated_at', type: 'number' },
    { name: 'created_at', type: 'number' },
    { name: 'updated_at', type: 'number' },
  ]
});

export default class QcProposal extends Model {
  static table = 'qcProposal';

  @field('b_id') bId;
  @field('uid') uid;
  @field('customer_name') customerName;
  @field('customer_number') customerNumber;
  @field('contact_name') contactName;
  @field('customer_description') customerDescription;
  @field('contact_title') contactTitle;
  @field('contact_email') contactEmail;
  @field('contact_phone') contactPhone;
  @field('country_id') countryId;
  @field('working_days_per_week') workingDaysPerWeek;
  @field('contract_period') contractPeriod;
  @field('status') status;
  @field('associated_representative') associatedRepresentative;
  @json('qcvc_data', jsonData => camelcaseObjectDeep(jsonData)) qcvcData;
  @date('b_created_at') bCreatedAt;
  @date('b_updated_at') bUpdatedAt;
  @date('created_at') createdAt;
  @date('updated_at') updatedAt;
}
