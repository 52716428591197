import { tableSchema } from '@nozbe/watermelondb';
import { field, date, json } from '@nozbe/watermelondb/decorators';
import Model from './simapp-model';

export const qcCountrySchema = tableSchema({
  name: 'qcCountry',
  columns: [
    { name: 'b_id', type: 'number', isIndexed: true },
    { name: 'uid', type: 'string', isIndexed: true },
    { name: 'name', type: 'string' },
    { name: 'iso', type: 'string' },
    { name: 'currency_name', type: 'string' },
    { name: 'currency_code', type: 'string' },
    { name: 'currency_sign', type: 'string' },
    { name: 'currency_position', type: 'string' },
    { name: 'decimal_separator', type: 'string' },
    { name: 'thousand_separator', type: 'string' },
    { name: 'default_language', type: 'string' },
    { name: 'address_list', type: 'string' },
    { name: 'b_created_at', type: 'number' },
    { name: 'b_updated_at', type: 'number' },
    { name: 'created_at', type: 'number' },
    { name: 'updated_at', type: 'number' },
  ]
});

export default class QcCountry extends Model {
  static table = 'qcCountry'

  @field('b_id') bId;
  @field('uid') uid;
  @field('name') name;
  @field('iso') iso;
  @field('currency_name') currencyName;
  @field('currency_code') currencyCode;
  @field('currency_sign') currencySign;
  @field('currency_position') currencyPosition;
  @field('decimal_separator') decimalSeparator;
  @field('thousand_separator') thousandSeparator;
  @field('default_language') defaultLanguage;
  @json('address_list', jsonData => jsonData) addressList;
  @date('b_created_at') bCreatedAt;
  @date('b_updated_at') bUpdatedAt;
  @date('created_at') createdAt;
  @date('updated_at') updatedAt;
}
