import { observable, computed } from 'mobx';

class QcProductStore {
  @observable manufacturerData = [];
  @observable productFamilyData = [];
  @observable productData = [];
  @observable competitorManufacturer = [];
  @observable competitorProductFamily = [];
  @observable competitorProductData = [];

  @computed get hasDataLoadedManufacturer() {
    return !!this.manufacturerData.length;
  }

  @computed get hasDataLoadedProductFamily() {
    return !!this.productFamilyData.length;
  }

  @computed get hasDataLoadedProductData() {
    return !!this.productData.length;
  }

  @computed get hasDataLoadedCompetitorManufacturer() {
    return !!this.competitorManufacturer.length;
  }

  @computed get hasDataLoadedCompetitorProductFamily() {
    return !!this.competitorProductFamily.length;
  }

  @computed get hasDataLoadedCompetitorProductData() {
    return !!this.competitorProductData.length;
  }
}

export default new QcProductStore();
