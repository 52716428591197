import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable, action, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import DialogActions from '@material-ui/core/DialogActions';
import Radio from '@material-ui/core/Radio';
import ListItemText from '@material-ui/core/ListItemText';
import simappColors from 'shared/lib/theme/simapp-colors';
import ModalHeader from '../_ui/modal-header';

const styles = {
  paper: {
    maxHeight: '80vh',
    width: '50vw',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${simappColors.steelGrey}`,
  },
  paperContent: {
    overflowY: 'auto',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
};

@observer class Selector extends Component {
  @observable selected = null;

  constructor(props) {
    super(props);
    runInAction(() => { this.selected = props.selected; });
  }

  @action toggleSelection = (itemId) => {
    this.selected = itemId;
  }

  typeFilter = () => this.props.list.map(item => (
    <MenuItem key={item.id} value={item.name} button onClick={() => this.toggleSelection(item.id)}>
      <Radio name="" checked={this.selected === item.id} color="primary" />
      <ListItemText primary={item.name} />
    </MenuItem>
  ));

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.paper} tabIndex="-1">
        <ModalHeader title={this.props.title} />
        <div className={classes.paperContent}>
          <MenuList className={classes.list}>
            {this.typeFilter()}
          </MenuList>
        </div>
        <DialogActions>
          <Button variant="contained" color="primary" disabled={this.selected === null} onClick={() => { this.props.selectAction(this.selected); }}>Continue</Button>
        </DialogActions>
      </Paper>
    );
  }
}

Selector.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  list: PropTypes.array.isRequired,
  selectAction: PropTypes.func.isRequired,
  selected: PropTypes.number
};

Selector.defaultProps = {
  selected: null
};

export default withStyles(styles)(Selector);
