import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { simappColors } from 'shared/lib/theme';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { getShortNameOfAssay } from 'shared/helper/kryptor-helper';
import { ConsumptionCalculation, getMultiControlQTY } from 'shared/helper/kryptor-calculationhelper';
import KryptorInputCell from './kryptor-inputcell';
import InputSelectionHelper from '../../../shared/helper/input-selection-helper';

const styles = {
  containerHeadlineText: {
    margin: '0px 200px 24px 56px',
    '&.first': {
      margin: '24px 200px 24px 56px'
    }
  },
  headlineText: {
    fontSize: '28px',
    lineHeight: '40px',
    fontWeight: '300'
  },
  table: {
    marginBottom: '47px',
    '&.sublineVisible': {
      marginBottom: '6px'
    }
  },
  tableHeadRow: {
    backgroundColor: `${simappColors.paleGrey}`,
    height: '40px'
  },
  tableHeadCell: {
    fontSize: '10px',
    fontWeight: '500',
    padding: '8px 7px',
    borderBottom: `1px solid ${simappColors.tableBorder}`,
    textAlign: 'center',
    letterSpacing: '0.5pt',
    '&.first': {
      padding: '8px 16px 8px 56px',
      textAlign: 'left',
    },
    '&.left': {
      borderLeft: `1px solid ${simappColors.tableBorder}`,
    },
    '&.fixedWidth': {
      width: '240px',
      padding: '8px 4px 8px 11px',
      '&.fixedWidth:last-child': {
        width: '255px'
      }
    },
    '&.fixedWidthHalf': {
      width: '120px',
      padding: '8px 8px 8px 0',
      '&.left': {
        padding: '8px 0 8px 7px'
      },
      '&.fixedWidthHalf:nth-last-child(-n+2)': {
        width: '127.5px'
      }
    }
  },
  tableRowCell: {
    height: '56px',
    borderBottom: `1px solid ${simappColors.tableBorder}`,
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '8px 7px',
    textAlign: 'center',
    '&.first': {
      padding: '8px 16px 8px 56px',
      textAlign: 'left',
      fontWeight: '500'
    },
    '&.left': {
      borderLeft: `1px solid ${simappColors.tableBorder}`,
    },
    '&.white': {
      backgroundColor: `${simappColors.white}`,
      position: 'relative',
      backgroundClip: 'padding-box',
      '& input': {
        border: 'none',
        width: '230px',
        height: '24px',
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: 'normal',
        fontFamily: 'helvetica-neue-lt, sans-serif'
      },
      '& input:focus': {
        outline: 'none',
      }
    },
    '&.whiteHalf': {
      backgroundColor: `${simappColors.white}`,
      position: 'relative',
      backgroundClip: 'padding-box',
      paddingRight: '16px',
      '& input': {
        border: 'none',
        width: '115px',
        height: '24px',
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: 'normal',
        fontFamily: 'helvetica-neue-lt, sans-serif'
      },
      '& input:focus': {
        outline: 'none',
      }
    },
    '&.fixedWidth': {
      width: '240px',
      padding: '8px 4px 8px 11px',
      '&.fixedWidth:last-child': {
        width: '255px'
      }
    },
    '&.fixedWidthHalf': {
      width: '120px',
      padding: '8px 8px 8px 0',
      '&.left': {
        padding: '8px 0 8px 7px'
      },
      '&.fixedWidthHalf:nth-last-child(-n+2)': {
        width: '127.5px'
      }
    },
    '& span.editIcon': {
      color: `${simappColors.paleGrey}`,
      top: '16px',
      right: '20px',
      position: 'absolute'
    }
  }
};

const VOLUME_PER_ALIQUOT_LOWER_LIMIT = 150;

@inject('applicationStore', 'kryptorStore') @observer
class KryptorResultConsumption extends Component {
  consumptionCalculation;
  inputSelectionHelper;

  constructor(props) {
    super(props);
    this.state = {
      dilutionBox: props.kryptorStore.consumablesData.dilutionPlateBox.count
    };
    this.consumptionCalculation = new ConsumptionCalculation(props.kryptorStore);
    this.props.kryptorStore.updateResults();
    this.inputSelectionHelper = new InputSelectionHelper();
  }

  getAssays() {
    const { classes, kryptorStore } = this.props;
    return (
      <div>
        {this.getHeader(kryptorStore.getText('KryptorAssayTitle'))}
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableHeadRow}>
              <TableCell className={`${classes.tableHeadCell} first`}>{kryptorStore.getText('KryptorAssayHeadRowAssayName')}</TableCell>
              <TableCell className={`${classes.tableHeadCell} fixedWidthHalf left`}>{kryptorStore.getText('KryptorAssayHeadRowRefKit')}</TableCell>
              <TableCell className={`${classes.tableHeadCell} fixedWidthHalf `}>{kryptorStore.getText('KryptorAssayHeadRowReagentKitQty')}</TableCell>
              <TableCell className={`${classes.tableHeadCell} fixedWidthHalf left`}>{kryptorStore.getText('KryptorAssayHeadRowRefCal')}</TableCell>
              <TableCell className={`${classes.tableHeadCell} fixedWidthHalf`}>{kryptorStore.getText('KryptorAssayHeadRowCalKitQty')}</TableCell>
              <TableCell className={`${classes.tableHeadCell} fixedWidthHalf left`}>{kryptorStore.getText('KryptorAssayHeadRowRefQC')}</TableCell>
              <TableCell className={`${classes.tableHeadCell} fixedWidthHalf`}>{kryptorStore.getText('KryptorAssayHeadRowQCKitQty')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {kryptorStore.kryptorAssayList.map((n, index) => (
              this.getAssayTableRow(n, index)
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }

  getAssayTableRow(assayItem, index) {
    const { classes } = this.props;
    const assay = assayItem.assay;
    const multiControlName = this.consumptionCalculation.getMultiControlName(assayItem, index);
    return (
      <TableRow className={classes.tableRow} key={index}>
        <TableCell className={`${classes.tableRowCell} first`}>{getShortNameOfAssay(assay.title)}</TableCell>
        <TableCell className={`${classes.tableRowCell} fixedWidthHalf left`}>{assay.ref}</TableCell>
        <TableCell className={`${classes.tableRowCell} fixedWidthHalf`}>{assayItem.reagentKits}</TableCell>
        <TableCell className={`${classes.tableRowCell} fixedWidthHalf left`}>{assay.ref_cal}</TableCell>
        <TableCell className={`${classes.tableRowCell} fixedWidthHalf `}>{assayItem.calKits}</TableCell>
        <TableCell className={`${classes.tableRowCell} fixedWidthHalf left`}>{multiControlName}</TableCell>
        <TableCell className={`${classes.tableRowCell} fixedWidthHalf`}>{assayItem.qcKits}</TableCell>
      </TableRow>
    );
  }

  getQuantityForRefConsumables(title, name, list, getTableRowFunc) {
    const { classes, kryptorStore } = this.props;
    return (
      <div>
        {this.getHeader(title)}
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableHeadRow}>
              <TableCell className={`${classes.tableHeadCell} first`}>{name}</TableCell>
              <TableCell className={`${classes.tableHeadCell} fixedWidth left`}>{kryptorStore.getText('KryptorHeadRowRef')}</TableCell>
              <TableCell className={`${classes.tableHeadCell} fixedWidth left`}>{kryptorStore.getText('KryptorHeadRowQuantity')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((n, index) => getTableRowFunc(n, index))}
          </TableBody>
        </Table>
      </div>
    );
  }

  getQuantityForRefMultiControl(title, name, list, getTableRowFunc) {
    const { classes, kryptorStore } = this.props;
    return (
      <div>
        {this.getHeader(title)}
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.tableHeadRow}>
              <TableCell className={`${classes.tableHeadCell} first`}>{name}</TableCell>
              <TableCell className={`${classes.tableHeadCell} fixedWidth left`}>{kryptorStore.getText('KryptorVolumePerAliqot')}</TableCell>
              <TableCell className={`${classes.tableHeadCell} fixedWidth left`}>{kryptorStore.getText('KryptorQcAliquotCoverInstrumentCount')}</TableCell>
              <TableCell className={`${classes.tableHeadCell} fixedWidth left`}>{kryptorStore.getText('KryptorHeadRowRef')}</TableCell>
              <TableCell className={`${classes.tableHeadCell} fixedWidth left`}>{kryptorStore.getText('KryptorHeadRowQuantity')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((n, index) => getTableRowFunc(n, index))}
          </TableBody>
        </Table>
      </div>
    );
  }

  getMultiControlTableRow(multiControl, index) {
    const { classes } = this.props;
    return (
      <TableRow className={classes.tableRow} key={multiControl.multiControl.id}>
        <TableCell className={`${classes.tableRowCell} first`}>{multiControl.multiControl.name}</TableCell>
        <KryptorInputCell
          index={index}
          key={`multiControlVolumePerAliquotInput_${multiControl.index}`}
          inputSelectionHelper={this.inputSelectionHelper}
          className={`${classes.tableRowCell} white left`}
          value={this.props.kryptorStore.multiControlList[index].volumePerAliquot}
          onValueChange={value => this.onMultiControlVolumePerAliquotChange(value, index, false)}
          onBlur={value => this.onMultiControlVolumePerAliquotChange(value, index, true)}
          forceIcon
        />
        <KryptorInputCell
          index={index + 1}
          key={`multiControlAliquotCoverInput_${multiControl.index}`}
          className={`${classes.tableRowCell} white left`}
          inputSelectionHelper={this.inputSelectionHelper}
          value={this.props.kryptorStore.multiControlList[index].aliquotCoverPerInstrument}
          onValueChange={value => this.onMultiControlAliquotCoverChange(value, index, false)}
          onBlur={value => this.onMultiControlAliquotCoverChange(value, index, true)}
          forceIcon
        />
        <TableCell className={`${classes.tableRowCell} fixedWidth left`}>{multiControl.multiControl.ref}</TableCell>
        <TableCell className={`${classes.tableRowCell} fixedWidth left`}>{multiControl.quantity}</TableCell>
      </TableRow>
    );
  }

  onDilutionValueChange = (value) => {
    this.setState({ dilutionBox: value });
    this.props.kryptorStore.setDilutionPlateBox(value);
  }

  onMultiControlVolumePerAliquotChange = (value, index, isOnBlur) => {
    let updatedValue = value;
    if (isOnBlur) {
      updatedValue = this.consumptionCalculation.validateAndUpdateVolumeAliquot(value, VOLUME_PER_ALIQUOT_LOWER_LIMIT, this.props.applicationStore, this.props.kryptorStore);
    }
    this.props.kryptorStore.setMultiControlVolumePerAliquot(updatedValue, index);
    const multiControl = this.props.kryptorStore.multiControlList[index];
    const quantity = getMultiControlQTY(this.props.kryptorStore, multiControl);
    this.props.kryptorStore.updateMultiControl(multiControl.multiControl.id, multiControl.multiControl, multiControl.maxWorkDaysPerWeek, quantity);
  }

  onMultiControlAliquotCoverChange = (value, index, isOnBlur) => {
    let updatedValue = value;
    if (isOnBlur) {
      updatedValue = this.consumptionCalculation.validateAndUpdateAliquotCoverPerInstrument(value, this.props.applicationStore, this.props.kryptorStore);
    }
    this.props.kryptorStore.setMultiControlCoverPerInstrument(updatedValue, index);
    const multiControl = this.props.kryptorStore.multiControlList[index];
    const quantity = getMultiControlQTY(this.props.kryptorStore, multiControl);
    this.props.kryptorStore.updateMultiControl(multiControl.multiControl.id, multiControl.multiControl, multiControl.maxWorkDaysPerWeek, quantity);
  }

  getConsumablesTableRow(consumable, index) {
    const { classes } = this.props;
    return (
      <TableRow className={classes.tableRow} key={index}>
        <TableCell className={`${classes.tableRowCell} first`}>{consumable.name}</TableCell>
        <TableCell className={`${classes.tableRowCell} left`}>{consumable.ref}</TableCell>
        {consumable.name.includes('Dilution plate box')
          ? (
            <KryptorInputCell
              index={index}
              inputSelectionHelper={this.inputSelectionHelper}
              className={`${classes.tableRowCell} white left`}
              value={this.state.dilutionBox}
              onValueChange={value => this.onDilutionValueChange(value)}
              forceIcon
            />
          )
          : <TableCell className={`${classes.tableRowCell} left`}>{this.consumptionCalculation.getConsumablesQTY(consumable)}</TableCell>}
      </TableRow>
    );
  }

  getHeader(text) {
    const { classes } = this.props;
    return (
      <div className={`${classes.containerHeadlineText} ${text === 'Assays' ? 'first' : ''}`}>
        <Typography className={classes.headlineText}>{text}</Typography>
      </div>
    );
  }

  updateStateMultiControlVolumePerAliquot = (value, index) => {
    this.setState((state) => {
      const list = state.multiControlVolumePerAliquot.map((originalValue, j) => {
        if (j === index) {
          return value;
        }
        return originalValue;
      });

      return {
        multiControlVolumePerAliquot: list
      };
    });
  };

  updateStateMultiControlAliquotCover = (value, index) => {
    this.setState((state) => {
      const list = state.multiControlAliquotCover.map((originalValue, j) => {
        if (j === index) {
          return value;
        }
        return originalValue;
      });

      return {
        multiControlAliquotCover: list
      };
    });
  };

  updateStateAssayVolumePerAliquot = (value, index) => {
    this.setState((state) => {
      const list = state.assayVolumePerAliquot.map((originalValue, j) => {
        if (j === index) {
          return value;
        }
        return originalValue;
      });

      return {
        assayVolumePerAliquot: list
      };
    });
  };

  updateStateAssayAliquotCover = (value, index) => {
    this.setState((state) => {
      const list = state.assayAliquotCover.map((originalValue, j) => {
        if (j === index) {
          return value;
        }
        return originalValue;
      });

      return {
        assayAliquotCover: list
      };
    });
  };

  render() {
    const { kryptorStore } = this.props;
    const consumables = [];
    Object.keys(kryptorStore.consumablesData).map((key) => {
      consumables.push(kryptorStore.consumablesData[key]);
    });
    return (
      <div>
        {this.getAssays()}
        {this.getQuantityForRefMultiControl(kryptorStore.getText('KryptorMultiControlsTitle'), kryptorStore.getText('KryptorMultiControlsHeadRowMultiControlsName'), kryptorStore.multiControlList, (n, index) => this.getMultiControlTableRow(n, index))}
        {this.getQuantityForRefConsumables(kryptorStore.getText('KryptorConsumablesTitle'), kryptorStore.getText('KryptorConsumablesHeadRowConsumableName'), consumables, (n, index) => this.getConsumablesTableRow(n, index))}
      </div>
    );
  }
}

KryptorResultConsumption.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  applicationStore: PropTypes.object.isRequired,
  kryptorStore: PropTypes.object.isRequired
};

export default withStyles(styles)(KryptorResultConsumption);
