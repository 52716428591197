import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { simappColors } from 'shared/lib/theme';
import ThermoFooter from 'web/components/_misc/thermo-footer';
import InputField from 'web/components/_ui/form-elements/input-field';
import SaveButton from 'web/components/_ui/form-elements/save-button';
import forgotPasswordForm from 'shared/stores/forms/forgot-password-form';

const styles = {
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  thumbnail: {
    margin: 'auto auto 1em auto',
    width: 120,
    height: 120,
    'background-image': 'url(/icons/mask-placeholder@3x.png)',
    'background-size': 'contain',
    '& > img': {
      width: 120,
      height: 120,
      borderRadius: '50%',
    },
  },
  headline: {
    margin: '0 auto 1em'
  },
  loginButton: {
    marginTop: '0.5em',
    marginBottom: '0.5em',
    width: '100%'
  },
  footer: {
    margin: 'auto 0 1em 0'
  },
  formPaper: {
    padding: '1em',
    width: 600,
    margin: '0 auto'
  },
  dividerFullWidth: {
    marginLeft: '-1em',
    marginRight: '-1em',
    backgroundColor: simappColors.steelGrey
  },
  error: {
    textAlign: 'center',
    color: simappColors.strawberry,
    marginTop: '1em'
  }
};

@observer
class Login extends Component {
  @observable submitted = false;
  @action save = () => {
    forgotPasswordForm.save();
    this.submitted = true;
  }

  render() {
    const { fields, onFieldChange } = forgotPasswordForm;
    return (
      <div className={this.props.classes.root}>
        <div className={this.props.classes.thumbnail} />
        <Typography variant="h4" className={this.props.classes.headline}>Reset Password</Typography>
        <Paper className={this.props.classes.formPaper}>
          { this.submitted ? (
            <Typography gutterBottom>We&lsquo;ll send you a mail shortly. Please close this tab and follow the instruction in the mail.</Typography>
          ) : (
            <form>
              <Typography gutterBottom>Please enter your email address. We will send you a link where you can set a new password.</Typography>
              <InputField autoFocus fields={fields} name="username" onChange={onFieldChange} />
              <Divider className={this.props.classes.dividerFullWidth} />
              <Typography className={this.props.classes.error}>{forgotPasswordForm.error || '\u00A0'}</Typography>
              <SaveButton form={forgotPasswordForm} onClick={this.save} label="Send Link" className={this.props.classes.loginButton} />
            </form>
          )}
        </Paper>
        <ThermoFooter className={this.props.classes.footer} />
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Login);
