import { action, observable, runInAction } from 'mobx';
import { applicationStore } from 'shared/stores';
import BaseForm from './base-form';

class LoginForm extends BaseForm {
  @observable
  fields = {
    username: {
      value: '',
      error: null,
      label: 'Email',
      rule: 'required',
      type: 'email'
    },
    password: {
      value: '',
      error: null,
      rule: 'required',
      label: 'Password',
      type: 'password'
    }
  }

  @action resetForm = () => {
    this.fields.username.value = '';
    this.fields.password.value = '';
    this.error = null;
  }

  @action save = () => {
    this.saveInProgess = true;
    // send request to backend
    // if login is OK, close form, otherwise show error
    applicationStore.loginLocal(this.fields.username.value.trim().toLowerCase(), this.fields.password.value).then((status) => {
      runInAction(() => {
        this.saveInProgess = false;
        if (status === true) {
          this.resetForm();
        } else {
          this.error = status;
        }
      });
    });
  }
}

export default new LoginForm();
