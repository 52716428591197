import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

function GetCopeptinNavigationDialog(copeptinStore, routing) {
  const message = copeptinStore.getLocalizedText(copeptinStore.location.endsWith('population') ? 'CopeptinGoBackAlert' : 'CopeptinNavigationAlert');
  const okText = copeptinStore.getLocalizedText(copeptinStore.location.endsWith('population') ? 'CopeptinGoBackAlertOk' : 'CopeptinNavigationAlertOk');
  const cancelText = copeptinStore.getLocalizedText(copeptinStore.location.endsWith('population') ? 'CopeptinGoBackAlertCancel' : 'CopeptinNavigationAlertCancel');

  const onCancel = () => {
    copeptinStore.disableCopeptinLeavingAlert();
    copeptinStore.setLocation('');
    // although mobx-router has still the Copeptin pathname, the browser does not
    // so we have to set it again
    routing.push(routing.location.pathname);
  };
  const onOk = () => {
    copeptinStore.resetData();
    copeptinStore.disableCopeptinLeavingAlert();
    copeptinStore.setLocation('');
    routing.push(copeptinStore.interceptedPathname);
  };

  return (
    <Dialog open={copeptinStore.isCopeptinLeavingAlert}>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="outlined" color="primary">{cancelText}</Button>
        <Button onClick={onOk} variant="contained" color="primary">{okText}</Button>
      </DialogActions>
    </Dialog>
  );
}

const CopeptinDialog = inject('copeptinStore', 'routing')(observer((props) => {
  const { copeptinStore, routing } = props;
  return GetCopeptinNavigationDialog(copeptinStore, routing);
}));

CopeptinDialog.wrappedComponent.propTypes = {
  copeptinStore: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired
};

export default CopeptinDialog;
