import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import Collection from 'react-virtualized/dist/commonjs/Collection';
import MaterialTile from './material-tile';

const styles = {
  collection: {
    '& > div': {
      width: '100% !important',
      maxWidth: '100% !important'
    }
  }
};

@inject('materialStore') @observer
class MaterialsGrid extends Component {
  columnCount = 1;

  cellRenderer = ({ index, key, style }) => (
    <MaterialTile style={style} index={index} key={key} material={this.props.materialStore.filteredMaterials[index]} />
  )

  calculateColumnCount = (width) => {
    let newColumnCount = Math.floor(width / 240);
    // no division by zero, please
    if (newColumnCount === 0) newColumnCount = 1;
    if (newColumnCount !== this.columnCount) {
      this.columnCount = newColumnCount;
      this.grid.recomputeCellSizesAndPositions();
    }
  }

  componentDidUpdate = () => {
    document.getElementById('materials-grid-wrapper').childNodes[0].childNodes[0].scrollTo(0, 0);
  }

  onResize = ({ width }) => {
    this.calculateColumnCount(width);
  }

  setGridRef = (ref) => {
    this.grid = ref;
  }

  // Render your grid
  render() {
    const count = this.props.materialStore.materialCount;
    // sort is not needed here, but we need to "use" it for MobX to update the component
    const sort = this.props.materialStore.sort; // eslint-disable-line
    return (
      <AutoSizer
        onResize={this.onResize}
        overscanByPixels={540}
      >
        {({ height, width }) => (
          <Collection
            className={this.props.classes.collection}
            cellCount={count}
            cellRenderer={this.cellRenderer}
            cellSizeAndPositionGetter={index => ({
              width: 230,
              height: 264,
              x: (index.index % this.columnCount) * 240,
              y: Math.floor(index.index / this.columnCount) * 266
            })}
            height={height}
            ref={this.setGridRef}
            width={width}
            scrollToCell={0}
          />
        )}
      </AutoSizer>
    );
  }
}

MaterialsGrid.wrappedComponent.propTypes = {
  materialStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MaterialsGrid);
