import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { inject } from 'mobx-react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import ModalHeader from 'web/components/_ui/modal-header';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import NumberFormat from 'react-number-format';

const styles = {
  divWrapper: {
    paddingTop: '12px',
    paddingBottom: '12px'
  },
  inputField: {
    width: '100%',
    '& input:focus': {
      outline: 'none',
    }
  },
  labelWrapper: {
    width: '25%',
    display: 'flex',
    position: 'relative',
    height: 32
  },
  textfield: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    height: 32,
    textDecoration: 'none',
    fontWeight: 'normal',
    fontSize: '16px',
    border: 'unset',
    fontFamily: 'helvetica-neue-lt, sans-serif',
  },
  dialogContent: {
    width: '100%',
    padding: 'unset'
  },
  label: {
    position: 'static',
    transform: 'none',
    alignSelf: 'center',
    whiteSpace: 'nowrap',
    width: '100%',
    fontWeight: '500',
    fontSize: '17px',
    paddingLeft: '24px'
  },
  dialog: {
    maxWidth: '496pt',
    maxHeight: '282pt',
    margin: 'auto'
  },

};

@inject('qcStore')
class QcPriceDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      price: undefined,
      percentage: undefined
    };
  }

  onChange = (value, field) => {
    if (field === 'price') {
      this.setState({
        price: value
      });
    } else if (value > 100) {
      this.setState({
        percentage: 100
      });
    } else {
      this.setState({
        percentage: value
      });
    }
  }

  onSave = () => {
    this.props.onSave(this.state.price, this.state.percentage === undefined ? this.state.percentage : this.state.percentage / 100);
    this.setState({
      price: undefined,
      percentage: undefined
    });
  }

  render() {
    const { classes, qcStore, showPrice, showDiscount } = this.props;
    return (
      <Dialog
        open={this.props.trigger}
        title="Dialog"
        aria-labelledby="simple-dialog-title"
        fullWidth
        onClose={() => {
          this.setState({
            price: undefined,
            percentage: undefined,
          });
          this.props.onClose();
        }}

      >
        <ModalHeader id="simple-dialog-title" title={this.props.message} />
        <DialogContent className={classes.dialogContent}>
          {showPrice && (
            <div className={classes.divWrapper}>
              <Grid container>
                <Grid item xs={4}>
                  <div className={classes.labelWrapper}>
                    <InputLabel htmlFor={qcStore.getText('QCProductSelectionProductViewPriceDialogPriceLabel')} className={classes.label}>{qcStore.getText('QCProductSelectionProductViewPriceDialogPriceLabel')}</InputLabel>
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <div className={classes.inputField}>
                    <NumberFormat
                      id="price"
                      className={classes.textfield}
                      value={this.state.price}
                      onValueChange={values => this.onChange(values.floatValue, 'price')}
                      decimalScale={2}
                      fixedDecimalScale
                      mask={this.props.currencyValue}
                      prefix={this.props.currencyPosition === 'prefix' ? this.props.currencyValue : ''}
                      suffix={this.props.currencyPosition === 'suffix' ? ` ${this.props.currencyValue}` : ''}
                      decimalSeparator={qcStore.getDecimalSeparator()}
                      thousandSeparator={qcStore.getThousandSeparator()}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
          {showPrice && showDiscount && <Divider light />}
          {showDiscount && (
            <div className={classes.divWrapper}>
              <Grid container>
                <Grid item xs={4}>
                  <div className={classes.labelWrapper}>
                    <InputLabel htmlFor={qcStore.getText('QCProductSelectionProductViewPriceDialogDiscountLabel')} className={classes.label}>{qcStore.getText('QCProductSelectionProductViewPriceDialogDiscountLabel')}</InputLabel>
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <div className={classes.inputField}>
                    <NumberFormat
                      id="percentage"
                      className={classes.textfield}
                      value={this.state.percentage}
                      onValueChange={values => this.onChange(values.floatValue, 'percentage')}
                      decimalScale={0}
                      fixedDecimalScale
                      mask="%"
                      suffix=" %"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button variant="outlined" onClick={() => this.props.onClose()} color="primary">{this.props.cancelText}</Button>
          <Button variant="contained" onClick={() => this.onSave()} color="primary">{this.props.okText}</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

QcPriceDialog.wrappedComponent.propTypes = {
  qcStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  trigger: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  okText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  currencyPosition: PropTypes.string,
  currencyValue: PropTypes.string,
  showPrice: PropTypes.bool,
  showDiscount: PropTypes.bool
};

QcPriceDialog.defaultProps = {
  currencyPosition: 'prefix',
  currencyValue: '$',
  showPrice: false,
  showDiscount: false
};

export default withStyles(styles)(QcPriceDialog);
