import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import NumberFormat from 'react-number-format';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { simappConstants, simappColors } from 'shared/lib/theme';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Create';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { showAddressString, showCurrencyString, getKryptorInstrumentTypeString } from 'shared/helper/kryptor-helper';
import Popover from '../_misc/popover';
import KryptorNavButton from './kryptor-navbutton';
import AddressDialog from './kryptor-addressdialog';
import KryptorAssays from './kryptor-assays';
import InstrumentTypeDialog from './kryptor-instrumentTypeDialog';
import InputSelectionHelper from '../../../shared/helper/input-selection-helper';
import BaseOverlay from '../_ui/form-elements/base-overlay';
import KryptorValidationDialog from './kryptor-validationDialog';

const constants = simappConstants;
const colors = simappColors;

const styles = {
  root: {
    width: '100vW',
    height: '100vH',
    flexDirection: 'column',
    padding: constants.contentMarginLarge,
    overflowX: 'hidden',
    fontFamily: 'helvetica-neue-lt, sans-serif'
  },
  bottomBar: {
    width: '100%',
    marginTop: '32px',
    paddingBottom: '30px'
  },
  helpLink: {
    marginTop: '20px',
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'row',
    color: colors.azul,
    cursor: 'pointer',
  },
  title: {
    margin: '0',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '28px',
    lineHeight: '40px'
  },
  cellTitle: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '55px'
  },
  filterRow: {
    display: 'flex',
    flexShrink: 0,
    height: 46,
    marginBottom: '24px',
    alignItems: 'center',
    '& :last-child': {
      marginLeft: 'auto',
    }
  },
  popoverText: {
    fontWeight: 'normal',
    marginBottom: '12px',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontSize: '17px',
  },
  popoverLinkText: {
    marginBottom: '12px',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontSize: '17px',
    color: colors.azul,
  },
  gridContainer: {
    display: 'flex',
    background: colors.white,
    fontFamily: 'helvetica-neue-lt, sans-serif',
    borderRadius: '2px 2px 2px 2px',
    marginBottom: '16px',
    '& div:last-child': {
      border: 'none'
    }
  },
  gridRow: {
    minHeight: '56px',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    borderBottom: `1px solid ${colors.paleGrey}`,
    marginLeft: '24px',
    width: 'calc(100% - 24px)'
  },
  gridItem: {
    '&.right': {
      minWidth: '128px',
      height: '56px',
      paddingRight: '16px',
      textAlign: 'right'
    },
    '& :last': {
      border: 'none'
    },
    '& .inline': {
      display: 'inline'
    }
  },
  required: {
    display: 'inline',
    color: colors.pumpkin
  },
  gridInputItem: {
    paddingLeft: '24px',
    minHeight: '56px',
    lineHeight: '56px',
    minWidth: '360px'
  },
  input: {
    width: '100%',
    minHeight: '54px',
    fontSize: '16px',
    border: 'none',
    lineHeight: '54px',
    padding: '0',
    caretColor: `${simappColors.azul}`,
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontWight: 'normal',
    backgroundColor: 'transparent',
    WebkitTextFillColor: colors.placeholderGrey,
    '&.value': {
      WebkitTextFillColor: colors.black
    },
    '& input': {
      padding: 0
    },
    '& input:focus': {
      outline: 'none',
    },
    '&:focus': {
      outline: 'none',
    }
  },
  inputAddress: {
    width: '100%',
    fontSize: '16px',
    border: 'none',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    color: colors.azul,
    cursor: 'pointer',
    lineHeight: '54px',
    minHeight: '54px',
    backgroundColor: 'transparent'
  },
  inputCurrency: {
    width: '100%',
    fontSize: '16px',
    border: 'none',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    color: colors.azul,
    cursor: 'pointer',
    lineHeight: '54px',
    minHeight: '54px',
    backgroundColor: 'transparent'
  },
  button: {
    height: '32px',
    width: '54px',
    minHeight: 'unset',
    minWidth: 'unset',
    padding: '3px 5px 5px 5px',
    color: colors.azul,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.azul,
    '&:hover, &:focus, &:active': {
      backgroundColor: 'transparent'
    }
  },
  minusButton: {
    borderRadius: '4px 0 0 4px',
    borderRight: 'none',
    '&.inactive': {
      border: `1px solid ${colors.paleGrey}`,
      borderRight: 'none',
      color: `${colors.paleGrey}`
    }
  },
  plusButton: {
    borderRadius: '0 4px 4px 0',
    '&.inactive': {
      border: `1px solid ${colors.paleGrey}`,
      borderLeft: `1px solid ${colors.azul}`,
      color: `${colors.paleGrey}`
    }
  },
  headline: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'hidden',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    color: colors.steelGrey,
    marginBottom: '8px',
    fontSize: '10px',
    fontWeight: '500',
    letterSpacing: '0.91px',
    lineHeight: '22px'
  },
  plusMinusButtons: {
    display: 'inline-block',
    float: 'right',
    paddingTop: '10px',
    paddingBottom: '13px',
    minWidth: '112px'
  },
  editImageButton: {
    height: '56px',
    padding: '0px',
    color: colors.azul,
    fontSize: '16px',
    fontWeight: 'normal',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    backgroundColor: 'transparent',
    '&:hover, &:focus, &:active': {
      backgroundColor: 'transparent'
    },
  },
  instrumentTypeRow: {
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    '& :last-child': {
      marginLeft: 'auto',
    }
  },
};

const customerInput = 'customerInput';
const contactInput = 'contactInput';
const addressInputLink = 'addressInputLink';
const currencyInputLink = 'currencyInputLink';
const instrumentTypeInputLink = 'instrumentTypeInputLink';
const coverTimePeriodInput = 'coverTimePeriodInput';
const coverTimePeriodPlus = 'coverTimePeriodPlus';
const coverTimePeriodMinus = 'coverTimePeriodMinus';
const monthsSuffix = ' months';
const coverTimePeriodMonthsMin = 1;
const coverTimePeriodMonthsMax = 999;
const instrumentNumberInput = 'instrumentNumberInput';
const instrumentNumberMin = 1;
const instrumentNumberMax = 10;

@inject('applicationStore', 'routing', 'countryStore', 'kryptorStore') @observer
class KryptorCalculator extends Component {
  inputSelectionHelper;

  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      monthSuffix: monthsSuffix,
      isInstrumentTypeChosen: true,
      customer: (props.kryptorStore.customerData.customer !== undefined && props.kryptorStore.customerData.customer !== '') && true,
      contactName: (props.kryptorStore.customerData.contactName !== undefined && props.kryptorStore.customerData.contactName !== '') && true,
      showCurrencyDialog: false,
      isCurrencyCountryChosen: true
    };
    const store = this.props.kryptorStore;
    if (store.customerData.kryptorType === null || typeof store.customerData.kryptorType === 'undefined') {
      this.state.isInstrumentTypeChosen = false;
    }

    if (store.customerData.currencyCountry === '') {
      this.state.isCurrencyCountryChosen = false;
    }

    this.inputSelectionHelper = new InputSelectionHelper();
  }

  setValidationState(isValid) {
    this.setState({ isValid });
  }

  getBottomBar(push) {
    return (
      <div className={this.props.classes.bottomBar}>
        <KryptorNavButton
          onClick={() => {
            this.props.kryptorStore.finishUserAssayInput();
            push('/kryptor-calculator/results');
          }}
          text={this.props.kryptorStore.getText('KryptorButtonText')}
          isValid={this.state.isValid && this.state.isInstrumentTypeChosen && this.state.isCurrencyCountryChosen}
        />
      </div>
    );
  }

  getTopBar() {
    return (
      <div className={this.props.classes.filterRow}>
        <Typography
          variant="h1"
          className={this.props.classes.title}
        >
          {this.props.kryptorStore.getText('KryptorTitle')}
        </Typography>
        <Popover
          triggerNode={(
            <Typography
              variant="body1"
              className={this.props.classes.helpLink}
            >
              {this.props.kryptorStore.getText('KryptorHelpLink')}
            </Typography>
          )}
          trigger="click"
        >
          <div>
            <Typography
              variant="body1"
              className={this.props.classes.popoverText}
            >
              {this.props.kryptorStore.getText('KryptorHelpText')}
            </Typography>
            <a
              className={this.props.classes.popoverLinkText}
              href={this.props.kryptorStore.getText('KryptorHelpMailtoLink')}
            >
              {this.props.kryptorStore.getText('KryptorHelpMailText')}
            </a>
          </div>
        </Popover>
      </div>
    );
  }

  getCustomerInfoContainer() {
    return (
      <Grid container direction="row" className={this.props.classes.gridContainer}>
        <Grid container className={this.props.classes.gridRow}>
          <Grid item xs className={this.props.classes.gridItem}>
            {this.getGridTitleCell(this.props.kryptorStore.getText('KryptorCustomerInput'))}
          </Grid>
          <Grid item xs className={this.props.classes.gridInputItem}>
            <Input
              inputRef={ref => this.inputSelectionHelper.registerInput(ref)}
              onKeyDown={e => this.inputSelectionHelper.keyDown(e)}
              onFocus={() => this.inputSelectionHelper.setCurrent(0)}
              id={customerInput}
              className={`${this.props.classes.input} ${this.state.customer && 'value'}`}
              style={this.customerInputTextOrPlaceholderColor}
              placeholder={this.props.kryptorStore.getText('KryptorCustomerInputPlaceholder')}
              value={this.props.kryptorStore.customerData.customer}
              disableUnderline
              onChange={(e) => {
                this.props.kryptorStore.setCustomer(e.target.value);
                this.setState({
                  customer: (this.props.kryptorStore.customerData.customer !== undefined && this.props.kryptorStore.customerData.customer !== '') && true
                });
              }}
            />
          </Grid>
          <Grid item xs className={`${this.props.classes.gridItem} right`} />
        </Grid>
        <Grid container className={this.props.classes.gridRow}>
          <Grid item xs className={this.props.classes.gridItem}>
            {this.getGridTitleCell(this.props.kryptorStore.getText('KryptorContactInput'))}
          </Grid>
          <Grid item xs className={this.props.classes.gridInputItem}>
            <Input
              inputRef={ref => this.inputSelectionHelper.registerInput(ref)}
              onKeyDown={e => this.inputSelectionHelper.keyDown(e)}
              onFocus={() => this.inputSelectionHelper.setCurrent(1)}
              id={contactInput}
              className={`${this.props.classes.input} ${this.state.contactName && 'value'}`}
              style={this.contactInputTextOrPlaceholderColor}
              placeholder={this.props.kryptorStore.getText('KryptorContactInputPlaceholder')}
              value={this.props.kryptorStore.customerData.contactName}
              disableUnderline
              onChange={(e) => {
                this.props.kryptorStore.setCustomerContact(e.target.value);
                this.setState({
                  contactName: (this.props.kryptorStore.customerData.contactName !== undefined && this.props.kryptorStore.customerData.contactName !== '') && true
                });
              }}
            />
          </Grid>
          <Grid item xs className={`${this.props.classes.gridItem} right`} />
        </Grid>
        <Grid container className={this.props.classes.gridRow}>
          <Grid item xs className={this.props.classes.gridItem}>
            {this.getGridTitleCell(this.props.kryptorStore.getText('KryptorAddressInput'))}
          </Grid>
          <Grid item xs className={this.props.classes.gridInputItem} zeroMinWidth>
            <div>
              <Typography
                id={addressInputLink}
                variant="body1"
                className={this.props.classes.inputAddress}
                onClick={() => this.props.applicationStore.toggleAddressDialog()}
                noWrap
              >
                {showAddressString(this.props.kryptorStore)}
              </Typography>
            </div>
          </Grid>
          <Grid item xs className={`${this.props.classes.gridItem} right`} />
        </Grid>
        <Grid container className={this.props.classes.gridRow}>
          <Grid item xs className={this.props.classes.gridItem}>
            {this.getGridTitleCell(this.props.kryptorStore.getText('KryptorCurrencyInput'), true)}
          </Grid>
          <Grid item xs className={this.props.classes.gridInputItem} zeroMinWidth>
            <div>
              <Typography
                id={currencyInputLink}
                variant="body1"
                className={this.props.classes.inputCurrency}
                onClick={this.openCurrencyDialog}
                noWrap
              >
                {showCurrencyString(this.props.kryptorStore, this.props.countryStore)}
              </Typography>
            </div>
          </Grid>
          <Grid item xs className={`${this.props.classes.gridItem} right`} />
        </Grid>
        <Grid container className={this.props.classes.gridRow}>
          <Grid item xs className={this.props.classes.gridItem}>
            {this.getGridTitleCell(this.props.kryptorStore.getText('KryptorInstrumentTypeInput'), true)}
          </Grid>
          <Grid item xs className={this.props.classes.gridInputItem} zeroMinWidth>
            {this.showInstrumentTypeString()}
          </Grid>
          <Grid item xs className={`${this.props.classes.gridItem} right`}>
            {this.getInstrumentTypeChangeButton()}
          </Grid>
        </Grid>
        <Grid container className={this.props.classes.gridRow}>
          <Grid item xs className={this.props.classes.gridItem}>
            {this.getGridTitleCell(this.props.kryptorStore.getText('KryptorCoverTimeInput'))}
          </Grid>
          <Grid item xs className={this.props.classes.gridInputItem}>
            <NumberFormat
              getInputRef={ref => this.inputSelectionHelper.registerInput(ref)}
              onKeyDown={e => this.inputSelectionHelper.keyDown(e)}
              onFocus={() => this.inputSelectionHelper.setCurrent(2)}
              id={coverTimePeriodInput}
              displayType="input"
              className={`${this.props.classes.input} value`}
              suffix={this.state.monthSuffix}
              value={this.props.kryptorStore.customerData.coverTimePeriod}
              allowNegative={false}
              onBlur={() => { this.updateCoverTimePeriod(0, true); }}
              onValueChange={() => { this.updateCoverTimePeriod(0, false); }}
              onClick={this.handleOnFocusInput}
            />
          </Grid>
          <Grid item xs className={`${this.props.classes.gridItem} right`}>
            <div className={this.props.classes.plusMinusButtons}>
              <Button
                id={coverTimePeriodMinus}
                className={`${this.props.classes.button} ${this.props.classes.minusButton} ${this.props.kryptorStore.customerData.coverTimePeriod === coverTimePeriodMonthsMin ? 'inactive' : ''}`}
                onClick={() => this.updateCoverTimePeriod(-1, false)}
              ><RemoveIcon />
              </Button>
              <Button
                id={coverTimePeriodPlus}
                className={`${this.props.classes.button} ${this.props.classes.plusButton} ${this.props.kryptorStore.customerData.coverTimePeriod === coverTimePeriodMonthsMax ? 'inactive' : ''}`}
                onClick={() => this.updateCoverTimePeriod(1, false)}
              ><AddIcon />
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid container className={this.props.classes.gridRow}>
          <Grid item xs className={this.props.classes.gridItem}>
            {this.getGridTitleCell(this.props.kryptorStore.getText('KryptorInstrumentNumberInput'))}
          </Grid>
          <Grid item xs className={this.props.classes.gridInputItem}>
            <NumberFormat
              getInputRef={ref => this.inputSelectionHelper.registerInput(ref)}
              onKeyDown={e => this.inputSelectionHelper.keyDown(e)}
              onFocus={() => this.inputSelectionHelper.setCurrent(3)}
              id={instrumentNumberInput}
              displayType="input"
              className={`${this.props.classes.input} value`}
              value={this.props.kryptorStore.customerData.instrumentNumber}
              allowNegative={false}
              onBlur={() => { this.updateInstrumentNumber(0, true); }}
              onValueChange={() => { this.updateInstrumentNumber(0, false); }}
              onClick={this.handleOnFocusInput}
            />
          </Grid>
          <Grid item xs className={`${this.props.classes.gridItem} right`}>
            <div className={this.props.classes.plusMinusButtons}>
              <Button
                className={`${this.props.classes.button} ${this.props.classes.minusButton} ${this.props.kryptorStore.customerData.instrumentNumber === instrumentNumberMin ? 'inactive' : ''}`}
                onClick={() => this.updateInstrumentNumber(-1, false)}
              ><RemoveIcon />
              </Button>
              <Button
                className={`${this.props.classes.button} ${this.props.classes.plusButton} ${this.props.kryptorStore.customerData.instrumentNumber === instrumentNumberMax ? 'inactive' : ''}`}
                onClick={() => this.updateInstrumentNumber(1, false)}
              ><AddIcon />
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  getInstrumentTypeChangeButton() {
    if (this.state.isInstrumentTypeChosen) {
      return (
        <Button className={this.props.classes.editImageButton} onClick={() => this.props.applicationStore.toggleInstrumentTypeDialog()}>
          <EditIcon />
          change
        </Button>
      );
    }
    return null;
  }

  getGridTitleCell(title, required) {
    return (
      <Grid item xs>
        <Typography variant="body1" className={`${this.props.classes.cellTitle} ${required ? 'inline' : ''}`}>{title}</Typography>
        {required && <span className={this.props.classes.required}> *</span>}
      </Grid>
    );
  }

  openCurrencyDialog = () => {
    this.setState({
      showCurrencyDialog: true
    });
  }

  closeCurrencyDialog = () => {
    this.setState({
      showCurrencyDialog: false
    });
  }

  updateCoverTimePeriod(adjustment, checkForEmptyField) {
    const coverTimePeriodInputControl = document.getElementById(coverTimePeriodInput);
    let value = parseInt(coverTimePeriodInputControl.value.split(' ')[0], 10) + adjustment;
    if (checkForEmptyField && Number.isNaN(value)) {
      value = coverTimePeriodMonthsMin;
    }
    if (value > coverTimePeriodMonthsMax) {
      value = coverTimePeriodMonthsMax;
    } else if (value < coverTimePeriodMonthsMin) {
      value = coverTimePeriodMonthsMin;
    }
    if (value > coverTimePeriodMonthsMax) {
      value = coverTimePeriodMonthsMax;
    } else if (value < coverTimePeriodMonthsMin) {
      value = coverTimePeriodMonthsMin;
    }
    const suffix = value === 1 ? monthsSuffix.substr(0, monthsSuffix.length - 1) : monthsSuffix;
    this.props.kryptorStore.setCustomerCoverTimePeriod(value);
    this.setState({
      monthSuffix: suffix
    });
  }

  updateInstrumentNumber(adjustment, checkForEmptyField) {
    const instrumentNumberInputControl = document.getElementById(instrumentNumberInput);
    let value = parseInt(instrumentNumberInputControl.value, 10) + adjustment;
    if (checkForEmptyField && Number.isNaN(value)) {
      value = instrumentNumberMin;
    }
    if (value > instrumentNumberMax) {
      value = instrumentNumberMax;
    } else if (value < instrumentNumberMin) {
      value = instrumentNumberMin;
    }
    if (value > instrumentNumberMax) {
      value = instrumentNumberMax;
    } else if (value < instrumentNumberMin) {
      value = instrumentNumberMin;
    }
    this.props.kryptorStore.setInstrumentNumber(value);
  }

  showInstrumentTypeString() {
    const instrumentTypeString = getKryptorInstrumentTypeString(this.props.kryptorStore);
    if (instrumentTypeString == null || instrumentTypeString === '') {
      return (
        <Typography
          id={instrumentTypeInputLink}
          variant="body1"
          className={this.props.classes.inputAddress}
          onClick={() => this.props.applicationStore.toggleInstrumentTypeDialog()}
          noWrap
        >
          {this.props.kryptorStore.getText('KryptorInstrumentTypeInputLink')}
        </Typography>
      );
    }
    return (
      <Typography
        id={instrumentTypeInputLink}
        variant="body1"
        className={this.props.classes.inputAddress}
        style={{ color: colors.black }}
        onClick={() => this.props.applicationStore.toggleInstrumentTypeDialog()}
        noWrap
      >
        {instrumentTypeString}
      </Typography>
    );
  }

  handleInstrumentTypeStyle(isSelected) {
    if (isSelected) {
      document.getElementById(instrumentTypeInputLink).style.color = colors.black;
      this.setState({ isInstrumentTypeChosen: true });
    } else {
      document.getElementById(instrumentTypeInputLink).style.color = colors.azul;
    }
  }

  render() {
    const { push } = this.props.routing;
    return (
      <div className={this.props.classes.root}>
        {this.getTopBar()}
        <div>
          <Typography variant="body1" className={this.props.classes.headline}>{this.props.kryptorStore.getText('KryptorCustomerInfo')}</Typography>
        </div>
        {this.getCustomerInfoContainer()}
        <KryptorAssays setValidState={isValid => this.setValidationState(isValid)} inputSelectionHelper={this.inputSelectionHelper} inputSelectionStartIndex={3} />
        <AddressDialog
          trigger={this.props.applicationStore.showAddressDialog}
          message={this.props.kryptorStore.getText('KryptorAddressDialogHeadline')}
          okText={this.props.kryptorStore.getText('KryptorAddressDialogSaveButtonText')}
          cancelText={this.props.kryptorStore.getText('KryptorAddressDialogCancelButtonText')}
        />
        <InstrumentTypeDialog
          trigger={this.props.applicationStore.showInstrumentTypeDialog}
          message={this.props.kryptorStore.getText('KryptorInstrumentTypeDialogHeadline')}
          cancelText={this.props.kryptorStore.getText('KryptorInstrumentTypeDialogCancelButtonText')}
          callback={isInstrumentTypeChosen => this.handleInstrumentTypeStyle(isInstrumentTypeChosen)}
        />
        <KryptorValidationDialog />
        <div>
          {this.getBottomBar(push)}
        </div>
        <BaseOverlay
          open={this.state.showCurrencyDialog}
          onClose={this.closeCurrencyDialog}
          onSelect={(iso) => {
            this.props.kryptorStore.setCurrencyCountry(iso);
            this.setState({
              isCurrencyCountryChosen: true
            });
          }}
          currentSelect={this.props.kryptorStore.customerData.currencyCountry}
          label="Currency"
          list={this.props.countryStore.getCountriesForSelect()}
          idField="iso"
        />
      </div>
    );
  }
}

KryptorCalculator.wrappedComponent.propTypes = {
  kryptorStore: PropTypes.object.isRequired,
  applicationStore: PropTypes.object.isRequired,
  countryStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired
};

export default withStyles(styles)(KryptorCalculator);
