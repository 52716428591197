import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import ModalHeader from 'web/components/_ui/modal-header';
import AddressInputField from './kryptor-addressInputField';
import InputSelectionHelper from '../../../shared/helper/input-selection-helper';

const inputFieldStyles = {
  divWrapper: {
    paddingTop: '12px',
    paddingBottom: '12px'
  },
  inputField: {
    width: '100%'
  },
  labelWrapper: {
    width: '25%',
    display: 'flex',
    position: 'relative',
    height: 32
  },
  textfield: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    height: 32,
    textDecoration: 'none',
    fontWeight: 'normal',
    fontSize: '16px'
  },
  dialogContent: {
    width: '90%'
  },
  label: {
    position: 'static',
    transform: 'none',
    alignSelf: 'center',
    whiteSpace: 'nowrap',
    width: '100%',
    fontWeight: '500',
    fontSize: '17px'
  }
};

const dialogStyles = {
  inputField: {
    width: '100%'
  },
  labelWrapper: {
    width: '25%',
    display: 'flex',
    position: 'relative',
    height: 32
  },
  textfield: {
    width: '90%',
    display: 'flex',
    position: 'relative',
    height: 32
  },
  dialogContent: {
    width: '100%',
    padding: '0 0 0 24px',
    overflow: 'hidden'
  },
  label: {
    position: 'static',
    transform: 'none',
    alignSelf: 'center',
    whiteSpace: 'nowrap',
    width: '100%',
    fontWeight: '500',
    fontSize: '17px'
  },
  dialog: {
    maxWidth: '496pt',
    maxHeight: '282pt',
    margin: 'auto'
  }
};

@inject('applicationStore', 'addressForm') @observer
class AddressDialog extends Component {
  defaultValuesInitialized = false;

  onSave() {
    this.props.addressForm.save();
    this.props.applicationStore.toggleAddressDialog();
  }

  render() {
    const { classes, addressForm } = this.props;
    const { fields, onFieldChange } = addressForm;
    const inputSelectionHelper = new InputSelectionHelper();

    if (!this.defaultValuesInitialized) {
      addressForm.setDefaultValues();
      this.defaultValuesInitialized = true;
    }
    return (
      <Dialog
        open={this.props.trigger}
        title="Dialog"
        aria-labelledby="simple-dialog-title"
        fullWidth
        onClose={() => {
          this.props.applicationStore.toggleAddressDialog();
        }}

      >
        <ModalHeader id="simple-dialog-title" title={this.props.message} />
        <DialogContent className={classes.dialogContent}>
          <AddressInputField classes={inputFieldStyles} fields={fields} name="streetAndNumber" onChange={onFieldChange} inputSelectionHelper={inputSelectionHelper} index={0} />
          <Divider light />
          <AddressInputField classes={inputFieldStyles} fields={fields} name="postalCode" onChange={onFieldChange} inputSelectionHelper={inputSelectionHelper} index={1} />
          <Divider light />
          <AddressInputField classes={inputFieldStyles} fields={fields} name="city" onChange={onFieldChange} inputSelectionHelper={inputSelectionHelper} index={2} />
          <Divider light />
          <AddressInputField classes={inputFieldStyles} fields={fields} name="country" onChange={onFieldChange} inputSelectionHelper={inputSelectionHelper} index={3} />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => this.props.applicationStore.toggleAddressDialog()} color="primary">{this.props.cancelText}</Button>
          <Button variant="contained" onClick={() => this.onSave()} color="primary">{this.props.okText}</Button>
        </DialogActions>
      </Dialog>
    );
  }
}

AddressDialog.wrappedComponent.propTypes = {
  addressForm: PropTypes.object.isRequired,
  applicationStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  trigger: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  okText: PropTypes.string.isRequired
};

export default withStyles(dialogStyles)(AddressDialog);
