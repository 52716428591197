import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MenuList from '@material-ui/core/MenuList';
import { applicationStore } from 'shared/stores/index';
import ModalHeader from 'web/components/_ui/modal-header';
import InstrumentTypeItem from './kryptor-instrumentTypeItem';

const styles = {
  dialogContent: {
    width: '100%',
    padding: '0px',
    overflow: 'hidden',
  },
  buttonRight: {
    fontSize: '17px',
    fontWeight: 'normal',
    position: 'absolute',
    right: '0',
    top: '5px',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  dialogTitle: {
    padding: '4px 0'
  },
  dialogTitleText: {
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '44px'
  },
  dialog: {
    maxWidth: '496pt',
    maxHeight: '276pt',
    margin: 'auto'
  },
  list: {
    height: 'calc(100% - 2.5em)',
    paddingTop: '0px',
    paddingBottom: '0px',
  }
};

@inject('addressForm', 'kryptorStore') @observer
class InstrumentTypeDialog extends Component {
  defaultValuesInitialized = false;
  instruments = [];
  store = this.props.kryptorStore;

  constructor(props) {
    super(props);
    this.instruments = this.store.instrumentTypes;
  }

  onSave(id) {
    this.store.setKryptorType(id);
    this.props.callback(true);
    applicationStore.toggleInstrumentTypeDialog();
  }

  getIconForSelectedType(option) {
    if (this.store.customerData.kryptorType === option.id) {
      return (
        <InstrumentTypeItem
          showSelectedIcon
          option={option}
          callback={id => this.onSave(id)}
          key={option.id}
        />
      );
    }
    return (
      <InstrumentTypeItem
        showSelectedIcon={false}
        option={option}
        callback={id => this.onSave(id)}
        key={option.id}
      />
    );
  }

  render() {
    const { classes } = this.props;

    if (!this.defaultValuesInitialized) {
      this.defaultValuesInitialized = true;
    }
    return (
      <Dialog
        open={this.props.trigger}
        title="Dialog"
        aria-labelledby="simple-dialog-title"
        fullWidth
        className={classes.dialog}
        onClose={() => {
          applicationStore.toggleInstrumentTypeDialog();
        }}
      >

        <ModalHeader id="simple-dialog-title" title={this.props.message} handleClose={applicationStore.toggleInstrumentTypeDialog} />
        <DialogContent className={classes.dialogContent}>
          <MenuList className={this.props.classes.list}>
            {this.instruments.map(option => (
              this.getIconForSelectedType(option)
            ))}
          </MenuList>
        </DialogContent>
      </Dialog>
    );
  }
}

InstrumentTypeDialog.wrappedComponent.propTypes = {
  kryptorStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  trigger: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
};

export default withStyles(styles)(InstrumentTypeDialog);
