import { action, observable, runInAction } from 'mobx';
import { calendarStore } from 'shared/stores';
import moment from 'moment';
import BaseForm from './base-form';

const startDate = moment().startOf('hour');
const endDate = moment(startDate).add(1, 'hours');

const defaultEvent = {
  title: '',
  description: '',
  location: '',
  start: startDate.format(moment.HTML5_FMT.DATETIME_LOCAL),
  end: endDate.format(moment.HTML5_FMT.DATETIME_LOCAL),
  attendees: ''
};

class EventForm extends BaseForm {
  @observable
  fields = {
    title: {
      value: '',
      error: null,
      vertical: false,
      required: true,
      label: 'Title',
      rule: 'required',
      placeholder: 'Event title'
    },
    description: {
      value: '',
      error: null,
      vertical: false,
      multiline: true,
      rule: 'string',
      label: 'Description',
      placeholder: ''
    },
    location: {
      value: '',
      error: null,
      vertical: false,
      rule: 'string',
      label: 'Location',
      placeholder: 'Event location'
    },
    start: {
      value: '',
      error: null,
      vertical: false,
      rule: 'required|before:end',
      required: true,
      label: 'Start Date',
      validateWith: ['end']
    },
    end: {
      value: '',
      error: null,
      vertical: false,
      rule: 'required|after:start',
      required: true,
      label: 'End Date',
      validateWith: ['start']
    },
    attendees: {
      value: '',
      vertical: false,
      error: null,
      label: 'Invite via e-mail',
      placeholder: 'comma separated email addresses',
      rule: ['regex:/^((([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))+[,;][ ]?)*(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$/'], // regex allows multiple email addresses, separated by comma or semicolon
    },
  };

  @observable event = {};
  @observable isNew = true;
  @observable showDialog = false;
  @observable iosDatetimePicker = null;

  @action setIosDatetimePicker = (field = null) => {
    this.iosDatetimePicker = field;
  }

  @action close = () => {
    this.showDialog = false;
  };

  @action edit = (event) => {
    this.showDialog = true;
    this.setFieldValuesForEvent(event);
    this.setFieldValuesForEvent(event); // need to run validation second time, otherwise before:end is triggerd
  };

  @action new = () => {
    this.showDialog = true;
    const e = { ...defaultEvent };
    const currentHour = parseInt(moment().format('H'), 10);
    e.start = moment(calendarStore.date).add(currentHour, 'hours').format(moment.HTML5_FMT.DATETIME_LOCAL);
    e.end = moment(calendarStore.date).add(currentHour + 1, 'hours').format(moment.HTML5_FMT.DATETIME_LOCAL);
    this.setFieldValuesForEvent(e);
    this.resetErrorStatus();
  };

  @action setFieldValuesForEvent = (event) => {
    this.event = { ...event };
    this.isNew = !(event.id);
    Object.keys(event).forEach((key) => {
      if (typeof this.fields[key] !== 'undefined') {
        const value = event[key] || '';
        this.fields[key].value = value;
        this.onFieldChange({
          target: {
            id: key,
            value,
          }
        });
      }
    });
  };

  @action resetErrorStatus = () => {
    Object.keys(this.fields).forEach((key) => {
      this.fields[key].error = null;
    });
  }

  @action resetValues = () => {
    this.setFieldValuesForEvent(this.event);
  };

  @action delete = () => {
    calendarStore.deleteEvent({ id: this.event.id });
    this.showDialog = false;
  }

  @action save = () => {
    this.saveInProgess = true;
    Object.keys(this.fields).forEach((key) => {
      this.event[key] = this.fields[key].value;
    });

    return new Promise((resolve, reject) => {
      let method = null;
      if (this.isNew) {
        method = calendarStore.addEvent(this.event);
      } else {
        method = calendarStore.updateEvent(this.event);
      }

      method
        .then(() => {
          runInAction(() => {
            this.showDialog = false;
          });
          resolve();
        })
        .catch((error) => {
          if (typeof error === 'object' && typeof error.error !== 'undefined') {
            this.setResponseError(error.error.code);
          } else {
            this.setResponseError('unkown error');
          }
          reject();
        }).finally(() => {
          runInAction(() => {
            this.saveInProgess = false;
          });
        });
    });
  }
}

export default new EventForm();
