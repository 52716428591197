import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  textLink: {
    color: '#00f',
    cursor: 'pointer'
  }
};

const TextLink = (props) => {
  {
    const { text, classes, linkText, onClick, className, linkTarget } = props;
    const linkTextIndex = linkText.length > 0 ? text.indexOf(linkText) : -1;
    if (linkTextIndex < 0) {
      return (<Typography variant="body1" className={className}>{text}</Typography>);
    }

    // eslint-disable-next-line no-irregular-whitespace
    const stringStart = `${text.substring(0, linkTextIndex).trim()} `;
    const stringEndIndex = linkTextIndex + linkText.length;

    if (stringEndIndex - text.length === 0) {
      if (linkTarget !== undefined && (linkTarget.includes('http') || linkTarget.includes('https'))) {
        return (<div className={className}>{stringStart}{<a className={classes.textLink} href={linkTarget}>{linkText}</a>}</div>);
      }
      // right margin to force space between divs
      return (
        <div className={className}>{stringStart}{<a className={classes.textLink} href={linkTarget} onClick={onClick}>{linkText}</a>}</div>
      );
    }
    let stringEnd = text.substring(stringEndIndex, text.length);
    stringEnd = stringEnd.startsWith('.') || stringEnd.startsWith(',')
      ? stringEnd
      // eslint-disable-next-line no-irregular-whitespace
      : ` ${stringEnd.trim()}`;
    if (linkTarget !== undefined && (linkTarget.includes('http') || linkTarget.includes('https'))) {
      return (<div className={className}>{stringStart}{<a className={classes.textLink} href={linkTarget}>{linkText}</a>}{stringEnd}</div>);
    }
    return (<div className={className}>{stringStart}{<a className={classes.textLink} href={linkTarget} onClick={onClick}>{linkText}</a>}{stringEnd}</div>
    );
  }
};

TextLink.propTypes = {
  text: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  linkTarget: PropTypes.string,
  className: PropTypes.any
};

TextLink.defaultProps = {
  className: '',
  linkTarget: undefined
};

export default withStyles(styles)(TextLink);
