
const daysInYear = 365;
const daysInWeek = 7;
const weeksInYear = (daysInYear / daysInWeek);
const RecoveryTestPerPatientMultiplicator = 2;

export function getTimeframe(period) {
  return period / 12;
}

export function getShortNameOfAssay(title) {
  return title.replace('B·R·A·H·M·S ', '').replace(' KRYPTOR', '');
}

export function getMaxWorkDaysOfAssayMultiControl(kryptorStore, multiControlId) {
  let days = 0;
  kryptorStore.kryptorAssayList.map((n) => {
    if (n.multiControlId === multiControlId) {
      if (days < n.workDaysPerWeek) {
        days = n.workDaysPerWeek;
      }
    }
  });
  return days;
}

export function getMaxNumberInstrumentReleatedAssayMultiControl(kryptorStore, multiControlId) {
  let number = 0;
  kryptorStore.kryptorAssayList.map((n) => {
    if (n.multiControlId === multiControlId) {
      if (number < n.numberOfInstruments) {
        number = n.numberOfInstruments;
      }
    }
  });
  return number;
}

export function getTestsPerAssay(assayItem, customerData) {
  const assay = assayItem.assay;
  const multiplicator = (assay.title.includes('hTg') && !assayItem.runRecoveryTest) || (!assay.title.includes('hTg'))
    ? 1 : RecoveryTestPerPatientMultiplicator;
  const timeFrame = getTimeframe(customerData.coverTimePeriod);
  return Math.ceil(assayItem.patientsPerCoverTime * multiplicator + assayItem.patientsPerCoverTime * assayItem.addedTests)
                + Math.ceil((((daysInYear / assay.calibration_stability) * assay.test_per_cal) * timeFrame * assayItem.numberOfInstruments))
                + Math.ceil(((weeksInYear * assayItem.workDaysPerWeek * assay.qc_levels) * timeFrame * assayItem.numberOfInstruments));
}

export function setTestsPerAssay(kryptorStore, assayItem, index) {
  const tests = getTestsPerAssay(assayItem, kryptorStore.customerData);
  kryptorStore.setAssayPricePerTestAmount(tests, index);
  return tests;
}

export function storedValueIsValid(object) {
  if (object !== null && typeof object !== 'undefined') {
    if (object.value !== null && typeof object.value !== 'undefined' && object.value !== '') {
      return true;
    }
  }
  return false;
}

export function getResultStringFromArray(resultArray) {
  let resultString = '';
  for (let i = 0; i < resultArray.length; i++) {
    const item = resultArray[i];
    if (item !== undefined && item !== null && item.length > 0) {
      resultString += item;
      if (i < resultArray.length - 1) {
        resultString += ', ';
      }
    }
  }
  return resultString.length > 0 ? resultString : null;
}

function getKryptorAddressString(kryptorStore) {
  if (kryptorStore.customerData.address === null || typeof kryptorStore.customerData.address === 'undefined') {
    return null;
  }
  const storedValues = kryptorStore.customerData.address;
  const resultArray = [];
  if (storedValues !== null && typeof storedValues !== 'undefined') {
    if (storedValueIsValid(storedValues.streetAndNumber)) {
      resultArray.push(storedValues.streetAndNumber.value);
    }
    let cityString = '';
    if (storedValueIsValid(storedValues.postalCode)) {
      cityString = storedValues.postalCode.value;
    }
    if (storedValueIsValid(storedValues.city)) {
      if (cityString.length > 0) {
        cityString += ' ';
      }
      cityString += storedValues.city.value;
    }
    if (cityString.length > 0) {
      resultArray.push(cityString);
    }
    if (storedValueIsValid(storedValues.country)) {
      resultArray.push(storedValues.country.value);
    }
    return getResultStringFromArray(resultArray);
  }
  return null;
}

export function showAddressString(kryptorStore) {
  const addressString = getKryptorAddressString(kryptorStore);
  if (addressString == null || addressString === '') {
    return kryptorStore.getText('KryptorAddressInputLink');
  }
  return addressString;
}

export function showCurrencyString(kryptorStore, countryStore) {
  const country = countryStore.countries.find(item => item.iso === kryptorStore.customerData.currencyCountry);
  if (country === undefined || kryptorStore.customerData.currencyCountry === '') {
    return kryptorStore.getText('KryptorCurrencyInputLink');
  }
  return country.name;
}

export function getKryptorInstrumentTypeString(kryptorStore) {
  if (kryptorStore.customerData.kryptorType === null || typeof kryptorStore.customerData.kryptorType === 'undefined') {
    return null;
  }
  for (let i = 0; i < kryptorStore.instrumentTypes.length; i++) {
    if (kryptorStore.customerData.kryptorType === kryptorStore.instrumentTypes[i].id) {
      return kryptorStore.instrumentTypes[i].name;
    }
  }
  return null;
}
