import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { simappConstants, simappColors } from 'shared/lib/theme';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import SimAppButton from 'web/components/_ui/simapp-button';
import Grid from '@material-ui/core/Grid';
import createPdf from 'web/helper/qc-pdf-helper-web';
import BaseOverlay from 'web/components/_ui/form-elements/base-overlay';
import QcProposalSend from './qc-proposal-send';

const styles = {
  root: {
    height: '100vH',
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    fontWeight: 'normal',
    padding: simappConstants.contentMarginLarge,
    paddingBottom: '0'
  },
  dropDown: {
    width: '350px',
    marginLeft: '30px'
  },
  buttonRow: {
    width: 'calc(100% - 40px)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginTop: '20px',
    marginLeft: '20px',
    marginRight: '20px',
  },
  comboCol: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    width: '95%',
    maxWidth: '350px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cellTitle: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '55px'
  },
  headlineDiv: {
    display: 'flex',
    flexShrink: 0,
    height: 46,
    marginBottom: '24px',
    alignItems: 'center'
  },
  pdfPreviewDiv: {
    height: 'calc(100% - 245px)',
    marginLeft: '-56px',
    marginRight: '-56px',
    marginTop: '20px',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'space-between'
  },
  pdfPreviewFrame: {
    width: '100%',
    height: '100%'
  },
  selectButton: {
    display: 'inline',
    paddingHorizontal: '5px',
    cursor: 'pointer',
    marginTop: '-5px',
    backgroundColor: 'transparent',
    color: `${simappColors.azul}`,
    '&:hover': {
      backgroundColor: 'unset'
    }
  },
  selectLabel: {
    textAlign: 'center'
  }
};

@inject('qcStore', 'applicationStore', 'routing') @observer
class QcProposalReview extends Component {
  uri;
  countries;
  addresses = [];
  languages = [];

  constructor(props) {
    super(props);
    this.languages = props.qcStore.getLanguageSelectData();
    this.countries = props.qcStore.getCountrySelectData();
    this.state = {
      pdfCreated: false,
      countryOverlayOpen: false,
      addressOverlayOpen: false,
      languageOverlayOpen: false,
      selectedCountry: undefined,
      selectedAddress: undefined,
      selectedLanguage: undefined
    };
  }

  // eslint-disable-next-line react/sort-comp
  async loadPdfAsBlob() {
    const pdf = await fetch(this.uri);
    return pdf.blob();
  }

  async asyncLoadPdf() {
    const pdfBlob = await this.loadPdfAsBlob();
    this.props.qcStore.setPdfContent(pdfBlob);
  }

  getGridTitleCell(title) {
    const { classes } = this.props;
    return (
      <Grid item xs>
        <Typography variant="body1" className={classes.cellTitle}>{title}</Typography>
      </Grid>
    );
  }

  createPdfButtonClicked() {
    this.uri = createPdf(this.props.qcStore, this.props.applicationStore.appUser, this.state.selectedAddress, this.state.selectedLanguage.iso);
    // eslint-disable-next-line react/no-unused-state
    this.setState({ pdfCreated: true });
    this.asyncLoadPdf();
  }

  getPdfIFrame() {
    if (this.uri) {
      return (<iframe src={this.uri} title="pdfPreviewArea" className={this.props.classes.pdfPreviewFrame} />);
    }
    return (<div />);
  }

  isProposalTemplateSelected = () => this.props.qcStore.customerData.proposalTemplate && this.props.qcStore.customerData.proposalTemplate.id >= 0 && this.props.qcStore.customerData.proposalTemplate.id < this.props.qcStore.getProposalTemplates().length;

  sendProposal = () => {
    this.props.applicationStore.toggleSendProposalDialog();
  }

  canCreatePdf() {
    return this.state.selectedCountry && this.state.selectedAddress && this.state.selectedLanguage;
  }

  countrySelectionChanged(id) {
    if (this.state.selectedCountry === undefined || this.state.selectedCountry.id !== id) {
      const country = this.countries.find(c => c.id === id);
      this.addresses = country.addressList;
      const selectedAddress = this.addresses.length > 1 ? undefined : this.addresses[0];
      const selectedLanguage = this.languages.find(l => l.iso === country.defaultLanguage);
      this.uri = undefined;
      this.setState({ selectedCountry: country, selectedAddress, selectedLanguage, pdfCreated: false });
    }
  }

  addressSelectionChanged(id) {
    if (this.state.selectedAddress === undefined || this.state.selectedAddress.id !== id) {
      const address = this.addresses.find(a => a.id === id);
      this.uri = undefined;
      this.setState({ selectedAddress: address, pdfCreated: false });
    }
  }

  languageSelectionChanged(id) {
    if (this.state.selectedLanguage === undefined || this.state.selectedLanguage.id !== id) {
      const language = this.languages.find(l => l.id === id);
      this.uri = undefined;
      this.setState({ selectedLanguage: language, pdfCreated: false });
    }
  }

  getCountrySelectLabel() {
    return this.state.selectedCountry ? this.state.selectedCountry.name : 'Select Country';
  }

  getAddressSelectLabel() {
    return this.state.selectedAddress ? this.state.selectedAddress.name : 'Select Address';
  }

  getLanguageSelectLabel() {
    return this.state.selectedLanguage ? this.state.selectedLanguage.name : 'Select Language';
  }

  createCountryOverlay() {
    return (
      <BaseOverlay
        open={this.state.countryOverlayOpen}
        onClose={() => {
          this.setState({ countryOverlayOpen: false });
        }}
        onSelect={(id) => {
          this.countrySelectionChanged(id);
        }}
        currentSelect={this.state.selectedCountry ? this.state.selectedCountry.id : undefined}
        labelPrefix=""
        label=""
        list={this.countries}
        valueField="name"
      />
    );
  }

  createAddressOverlay() {
    return (
      <BaseOverlay
        open={this.state.addressOverlayOpen}
        onClose={() => {
          this.setState({ addressOverlayOpen: false });
        }}
        onSelect={(id) => {
          this.addressSelectionChanged(id);
        }}
        currentSelect={this.state.selectedAddress ? this.state.selectedAddress.id : undefined}
        labelPrefix=""
        label=""
        list={this.addresses}
        valueField="name"
      />
    );
  }

  createLanguageOverlay() {
    return (
      <BaseOverlay
        open={this.state.languageOverlayOpen}
        onClose={() => {
          this.setState({ languageOverlayOpen: false });
        }}
        onSelect={(id) => {
          this.languageSelectionChanged(id);
        }}
        currentSelect={this.state.selectedLanguage ? this.state.selectedLanguage.id : undefined}
        labelPrefix=""
        label=""
        list={this.languages}
        valueField="name"
      />
    );
  }

  render() {
    const { qcStore, classes } = this.props;
    return (
      <div className={this.props.classes.root}>
        <div className={classes.headlineDiv}>
          <Typography variant="h5">{qcStore.getText('QCReviewSendProposal')}</Typography>
        </div>
        <div className={classes.buttonRow}>
          <div className={classes.comboCol}>
            <Typography className={classes.selectLabel}>{qcStore.getText('QCSelectCountryLabel')}</Typography>
          </div>
          <div className={classes.comboCol}>
            <Typography className={classes.selectLabel}>{qcStore.getText('QCSelectAddressLabel')}</Typography>
          </div>
          <div className={classes.comboCol}>
            <Typography className={classes.selectLabel}>{qcStore.getText('QCSelectLanguageLabel')}</Typography>
          </div>
        </div>
        <div className={classes.buttonRow}>
          <div className={classes.comboCol}>
            <SimAppButton
              className={classes.selectButton}
              small
              onClick={() => this.setState({ countryOverlayOpen: true })}
            >{this.getCountrySelectLabel()}
            </SimAppButton>
          </div>
          <div className={classes.comboCol}>
            <SimAppButton
              className={classes.selectButton}
              small
              disabled={!this.state.selectedCountry}
              onClick={() => this.setState({ addressOverlayOpen: true })}
              noBorder
            >{this.getAddressSelectLabel()}
            </SimAppButton>
          </div>
          <div className={classes.comboCol}>
            <SimAppButton
              className={classes.selectButton}
              small
              onClick={() => this.setState({ languageOverlayOpen: true })}
            >{this.getLanguageSelectLabel()}
            </SimAppButton>
          </div>
        </div>
        <div className={classes.buttonRow}>
          <div className={classes.comboCol} />
          <div className={classes.comboCol}>
            <SimAppButton
              className={classes.button}
              onClick={() => this.createPdfButtonClicked()}
              small
              disabled={!this.canCreatePdf()}
            >{qcStore.getText('CreatePDF')}
            </SimAppButton>
          </div>
          <div className={classes.comboCol}>
            <SimAppButton
              className={classes.button}
              onClick={() => this.sendProposal()}
              small
              disabled={!this.state.pdfCreated}
            >{qcStore.getText('QCSendTo')}
            </SimAppButton>
          </div>
        </div>
        <div className={this.props.classes.pdfPreviewDiv}>
          {this.getPdfIFrame()}
        </div>
        <QcProposalSend
          titleText={qcStore.getText('QCSendProposalTitle')}
          sendText={qcStore.getText('QCSendProposalSendButton')}
          cancelText={qcStore.getText('QCSendProposalCancelButton')}
        />
        {this.createCountryOverlay()}
        {this.createAddressOverlay()}
        {this.createLanguageOverlay()}
      </div>
    );
  }
}

QcProposalReview.wrappedComponent.propTypes = {
  qcStore: PropTypes.object.isRequired,
  applicationStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(QcProposalReview);
