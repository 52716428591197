import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import { simappColors } from 'shared/lib/theme';
import NumberFormat from 'react-number-format';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';

const styles = {
  editButton: {
    padding: '0',
    display: 'inline',
    paddingLeft: '5px',
    color: `${simappColors.azul}`,
    cursor: 'pointer',
    marginTop: '-3px',
    minWidth: 'unset',
    '&:hover': {
      backgroundColor: 'unset'
    }
  },
  editText: {
    '& span': {
      paddingTop: '5px'
    }
  }
};

@observer
class QcInputCell extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.objRef(this);
  }

  componentWillUnmount() {
    this.props.objRef(undefined);
  }

  inputFocus = () => {
    /*
    if (this.props.index >= 0) {
      this.props.inputSelectionHelper.setCurrent(this.props.index);
    } */
  }

  inputReleaseFocus = () => {

  }

  overwriteStateValue(val) {
    this.setState({ value: val });
  }

  onValueChange = (value) => {
    const { onValueChange, minValue, maxValue } = this.props;
    let resultValue = value;
    if (minValue !== undefined && value !== undefined) {
      if (minValue > value) {
        resultValue = minValue;
      }
    }
    if (maxValue !== undefined && value !== undefined) {
      if (maxValue < value) {
        resultValue = maxValue;
      }
    }
    if (value !== undefined) {
      this.setState({
        value: resultValue
      });
      onValueChange(resultValue);
    } else {
      this.setState({
        value: null
      });
      onValueChange(null);
    }
  }

  render() {
    const { className, classes, editableButton, editableButtonClick, editableButtonText } = this.props;
    if (this.state.value !== this.props.value) {
      this.state.value = this.props.value;
    }
    return (
      <TableCell className={`${className} ${editableButton ? classes.editText : ''}`}>
        <NumberFormat
          id={this.props.index}
          getInputRef={ref => (this.props.index >= 0 && this.props.inputSelectionHelper !== undefined ? this.props.inputSelectionHelper.registerInput(ref) : {})}
          onKeyDown={e => (this.props.index >= 0 && this.props.inputSelectionHelper !== undefined ? this.props.inputSelectionHelper.keyDown(e) : {})}
          value={this.state.value && this.props.displayType === 'input' ? this.state.value : this.props.value}
          onValueChange={values => this.onValueChange(values.floatValue)}
          onFocus={event => this.inputFocus(event)}
          onBlur={event => this.inputReleaseFocus(event)}
          decimalScale={this.props.decimalScale}
          fixedDecimalScale={this.props.fixedDecimalScale}
          decimalSeparator={this.props.decimalSeparator}
          thousandSeparator={this.props.thousandSeparator}
          prefix={this.props.currencyPosition === 'prefix' ? this.props.currencyValue : ''}
          suffix={this.props.currencyPosition === 'suffix' ? ` ${this.props.currencyValue}` : ''}
          displayType={this.props.displayType}
        />
        {editableButton && <Button className={classes.editButton} onClick={() => editableButtonClick()}>{editableButtonText}</Button>}
      </TableCell>
    );
  }
}

QcInputCell.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  onValueChange: PropTypes.func,
  value: PropTypes.number,
  decimalSeparator: PropTypes.string,
  thousandSeparator: PropTypes.string,
  decimalScale: PropTypes.number,
  fixedDecimalScale: PropTypes.bool,
  inputSelectionHelper: PropTypes.object,
  index: PropTypes.string,
  currencyValue: PropTypes.string,
  currencyPosition: PropTypes.string,
  displayType: PropTypes.string,
  editableButton: PropTypes.bool,
  editableButtonClick: PropTypes.func,
  editableButtonText: PropTypes.string,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  objRef: PropTypes.func,
};

QcInputCell.defaultProps = {
  inputSelectionHelper: undefined,
  index: '-1',
  onValueChange: () => {},
  value: undefined,
  decimalSeparator: '.',
  thousandSeparator: ',',
  decimalScale: 0,
  fixedDecimalScale: false,
  currencyValue: '',
  currencyPosition: 'suffix',
  displayType: 'input',
  editableButton: false,
  editableButtonClick: () => {},
  editableButtonText: '',
  minValue: 0,
  maxValue: 10000,
  objRef: () => {}
};

export default withStyles(styles)(QcInputCell);
