import { getResultStringFromArray } from 'shared/helper/kryptor-helper';
import { simappConstants } from 'shared/lib/theme';
import settingsForm from 'shared/stores/forms/settings-form';

class Category {
  name = '';
  patientsResultsAmount = 0;
  pricePerPatient = 0;
  isAssay = () => !this.isMultiControl() && this.name !== this.kryptorStore.getPdfData('KryptorPdfConsumables');
  isMultiControl = () => this.name === this.kryptorStore.getPdfData('KryptorPdfMultiControl');
  items = [];
  kryptorStore;
  constructor(kryptorStore) {
    this.kryptorStore = kryptorStore;
  }
}

class Item {
  name;
  id;
  amount;
  price;
  constructor(name, id, amount, price) {
    this.name = name;
    this.id = id;
    this.amount = amount;
    this.price = price;
  }
}

function intializeCategory(kryptorPdf, category, kryptorStore) {
  for (let i = 0; i < kryptorPdf.content.length; i++) {
    if (kryptorPdf.content[i].name === category) {
      return kryptorPdf.content[i];
    }
  }
  const newCategory = new Category(kryptorStore);
  newCategory.name = category;
  kryptorPdf.content.push(newCategory);
  return newCategory;
}

function addPriceResult(kryptorPdf, kryptorStore, category, amount, pricePerPatient) {
  const currentCategory = intializeCategory(kryptorPdf, category, kryptorStore);
  currentCategory.patientsResultsAmount = amount;
  currentCategory.pricePerPatient = pricePerPatient;
}

function addItem(kryptorPdf, kryptorStore, category, name, id, amount, pricePerItem) {
  const currentCategory = intializeCategory(kryptorPdf, category, kryptorStore);
  currentCategory.items.push(new Item(name, id, amount, pricePerItem));
}

function addAssayItemToPdf(pdf, kryptorStore, assayName, name, ref, amount, pricePerItem) {
  if (amount === undefined || ref === undefined || ref.length === 0 || amount === 0) {
    return;
  }
  addItem(pdf, kryptorStore, assayName, name, ref, amount, pricePerItem);
}

function addAssayToPdf(pdf, kryptorStore, assay, index) {
  const rawAssay = assay.assay;
  let assayName = rawAssay.title;
  for (let i = index - 1; i >= 0; i--) {
    if (rawAssay.id === kryptorStore.kryptorAssayList[i].id) {
      assayName += ' '; // prevents grouping under the same tag
    }
  }

  addPriceResult(pdf, kryptorStore, assayName, assay.patientsPerCoverTime, assay.pricePerResult);

  addAssayItemToPdf(pdf, kryptorStore, assayName, kryptorStore.getPdfData('KryptorPdfReagent'), rawAssay.ref, assay.reagentKits, assay.reagentKitPrice);
  addAssayItemToPdf(pdf, kryptorStore, assayName, kryptorStore.getPdfData('KryptorPdfCalibrator'), rawAssay.ref_cal, assay.calKits, assay.calKitPrice);
  addAssayItemToPdf(pdf, kryptorStore, assayName, kryptorStore.getPdfData('KryptorPdfControl'), rawAssay.ref_qc, assay.qcKits, assay.qcKitPrice);
}

function addMultiControlToPdf(pdf, kryptorStore, control) {
  const name = control.multiControl.name.replace(' QC', '');
  addItem(pdf, kryptorStore, kryptorStore.getPdfData('KryptorPdfMultiControl'), name, control.multiControl.ref, control.quantity, control.pricePerItem);
}

function getConsumable(kryptorStore, name, value) {
  const consumable = [];
  consumable.push(kryptorStore.getPdfData('KryptorPdfConsumables'));
  consumable.push(kryptorStore.getPdfData(`KryptorPdfConsumable${name}`));
  consumable.push(kryptorStore.getPdfData(`KryptorPdfConsumable${name}Ref`));
  consumable.push(value.count);
  consumable.push(value.pricePerItem);
  return consumable;
}

function addConsumablesToPdf(pdf, kryptorStore, consumables) {
  const consumablesList = [
    getConsumable(kryptorStore, 'ReactionPlates', consumables.reactionPlates),
    getConsumable(kryptorStore, 'Solution1', consumables.solution1),
    getConsumable(kryptorStore, 'Solution2', consumables.solution2),
    getConsumable(kryptorStore, 'Solution3', consumables.solution3),
    getConsumable(kryptorStore, 'Solution4', consumables.solution4),
    getConsumable(kryptorStore, 'Buffer', consumables.bufferBags),
    getConsumable(kryptorStore, 'DilutionPlate', consumables.dilutionPlateBox)
  ];

  consumablesList.forEach(item => addItem(pdf, kryptorStore, item[0], item[1], item[2], item[3], item[4]));
}

function addCustomerDataToPdf(pdf, customerData) {
  const { customer, contactName, address, coverTimePeriod } = customerData;
  pdf.coverTimePeriod = coverTimePeriod;
  pdf.customer = customer;
  pdf.contact = contactName;
  // TODO pdf.department = 'Department of Jazz in Space'; (where does this come from?)
  let cityString = address.postalCode.value;
  if (address.city.value !== undefined && address.city.value.length > 0) {
    if (cityString.length > 0) {
      cityString += ' ';
    }
    cityString += address.city.value;
  }
  pdf.address = getResultStringFromArray([address.streetAndNumber.value, cityString, address.country.value]);
}

export default function fillPdf(pdf, kryptorStore, applicationStore) {
  pdf.useDollar = applicationStore.presentingInIso.toLowerCase() === simappConstants.LanguageCodeUSA;
  pdf.instrumentType = kryptorStore.instrumentTypes[kryptorStore.customerData.kryptorType].name;
  settingsForm.setDefaultValues();
  const fields = settingsForm.fields;
  pdf.signature = fields.signature.value.length === 0 ? fields.email.value : fields.signature.value;

  addCustomerDataToPdf(pdf, kryptorStore.customerData);
  kryptorStore.kryptorAssayList.forEach((assay, index) => addAssayToPdf(pdf, kryptorStore, assay, index));
  kryptorStore.multiControlList.forEach(control => addMultiControlToPdf(pdf, kryptorStore, control));
  addConsumablesToPdf(pdf, kryptorStore, kryptorStore.consumablesData);
}

const integerValueRegex = /(\d+)(\d{3})/;

export function addValueSeparators(integerValue, currentDecimalSeparator, decimalSeparatorToUse, thousandSeparatorToUse) {
  let value = `${integerValue}`;
  const integerEndPos = value.indexOf(currentDecimalSeparator);
  let decimalValue = '';
  if (integerEndPos !== -1) {
    decimalValue = decimalSeparatorToUse + value.substring(integerEndPos + 1, value.length);
    value = value.substring(0, integerEndPos);
  }
  while (integerValueRegex.test(value)) {
    value = value.replace(integerValueRegex, `$1${thousandSeparatorToUse}$2`);
  }
  return value + decimalValue;
}
