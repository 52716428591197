import { action, observable } from 'mobx';
import BaseForm from './base-form';
import applicationStore from '../application-store';
import qcStore from '../qc-store';

//
class SendProposalForm extends BaseForm {
  sendMailFunction;
  @observable
  fields = {
    mailto: {
      value: '',
      error: null,
      label: 'Email',
      rule: ['required', 'regex:/^((([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))+[,;][ ]?)*(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$/'], // regex allows multiple email addresses, separated by comma or semicolon
      required: true
    },
    subject: {
      value: '',
      error: null,
      rule: 'required',
      required: true,
      label: 'Subject',
    },
    message: {
      value: '',
      error: null,
      label: 'Message',
      rule: 'string',
      multiline: true,
      required: false,
      vertical: true,
    }
  }

  errors = {
    NO_MAIL_PERMISSION: 'NO_MAIL_PERMISSION',
    MAIL_SEND_ERROR: 'MAIL_SEND_ERROR'
  }

  @action setSendMailFunction(func) {
    this.sendMailFunction = func;
  }

  @action setDefaultValues = () => {
    const receiverMail = qcStore.customerData.contactEmail;
    const subject = qcStore.getPdfData('QcPdfTitle-US-LOC');
    this.onFieldChange({ target: { id: 'mailto', value: receiverMail } });
    this.onFieldChange({ target: { id: 'subject', value: subject } });
    this.onFieldChange({ target: { id: 'message', value: '' } });
  }

  @action save = () => {
    this.saveInProgess = true;
    const data = {};
    Object.keys(this.fields).forEach((key) => {
      data[key] = this.fields[key].value;
    });
    const content = qcStore.pdfContent;
    this.sendMailFunction(data.mailto, data.subject, data.message, qcStore.getPdfData('QcPdfFileName-US'), content).then(
      // eslint-disable-next-line no-unused-vars
      result => this.sendPdfSuccessful(),
      // eslint-disable-next-line no-unused-vars
      error => this.sendPdfFailed(error)
    );
  }

  @action sendPdfSuccessful() {
    applicationStore.addNotification(qcStore.getPdfData('QcProposalMailSuccess'));
    this.saveInProgess = false;
    applicationStore.toggleSendProposalDialog();
  }

  @action sendPdfFailed(error) {
    this.setResponseError(qcStore.getPdfData('QcProposalMailFailure'));
    console.error(`Error in outlook mail API calls: ${error}`);
    this.saveInProgess = false;
    // applicationStore.toggleSendProposalDialog();
  }

  @action onNativeFieldChange = (e) => {
    this.fields[e.id].value = e.value;
  }
}

export default new SendProposalForm();
