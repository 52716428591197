import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { simappColors } from 'shared/lib/theme';
import texts from 'shared/lib/resources/texts';
import InputField from 'web/components/_ui/form-elements/input-field';
import SaveButton from 'web/components/_ui/form-elements/save-button';
import CancelButton from 'web/components/_ui/form-elements/cancel-button';
import ModalHeader from 'web/components/_ui/modal-header';
import AlertDialog from 'web/components/_misc/alert-dialog';
import ShareMaterialList from './share-material-list';

const styles = {
  root: {
  },
  paper: {
    width: '90vw',
    height: '90vh',
    maxWidth: '90vw',
    maxHeight: '90vh',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    padding: 0,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  grid: {
    flex: 1,
  },
  leftCol: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '12px 24px !important'
  },
  rightCol: {
    overflowY: 'auto',
    maxHeight: '100%',
    flex: 1,
    borderLeft: `1px solid ${simappColors.paleGrey}`,
    padding: '24px 24px !important',
    backgroundColor: simappColors.palerGrey
  },
  saveBtnWrapper: {
    position: 'relative'
  },
  saveBtn: {
    marginLeft: 8,
    marginRight: 8
  },
  errorText: {
    textAlign: 'center',
    marginBottom: 0,
    fontSize: '1.1rem'
  },
  loginButton: {
    marginTop: '2rem'
  },
  buttonProgress: {
    color: simappColors.azul,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
};

@inject('shareForm', 'applicationStore') @observer
class Sharing extends Component {
  handleSend = () => {
    this.props.shareForm.save().then(() => {
      this.props.applicationStore.cancelSharing();
      this.props.applicationStore.addNotification('Mail Sent.');
    }).catch(() => {
    });
  }

  handleCancel = () => {
    this.props.applicationStore.cancelSharing();
  }

  errorMessage = () => {
    switch (this.props.shareForm.responseError) {
      case this.props.shareForm.errors.NO_MAIL_PERMISSION:
        return (
          <Typography className={this.props.classes.errorText}>It seems that you have not given Office 365 the required permission to send mails.<br />Please re-login and grant requested permissions:
            <Button size="large" variant="contained" color="primary" className={this.props.classes.loginButton} onClick={() => { this.props.applicationStore.login(true); }}>Log in</Button>
          </Typography>
        );
      default:
        return texts.couldNotSendMail;
    }
  }

  render() {
    const { applicationStore, shareForm } = this.props;
    const { fields, onFieldChange } = shareForm;
    return (
      <Fragment>
        { applicationStore.sharingOpen
          && (
            <Dialog
              open
              onClose={this.handleCancel}
              classes={{
                paper: this.props.classes.paper,
              }}
            >
              <form className={this.props.classes.form}>
                <ModalHeader title="Share Materials" />
                <DialogContent classes={{ root: this.props.classes.dialogContent }}>
                  <Grid container spacing={0} classes={{ container: this.props.classes.grid }}>
                    <Grid item xs={7} className={this.props.classes.leftCol}>
                      <InputField fields={fields} name="mailto" onChange={onFieldChange} />
                      <Divider light />
                      <InputField fields={fields} name="subject" onChange={onFieldChange} />
                      <Divider light />
                      <InputField fields={fields} name="message" onChange={onFieldChange} className={this.props.classes.buttonArea} />
                    </Grid>
                    <Grid item xs={5} className={this.props.classes.rightCol}>
                      <ShareMaterialList />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <CancelButton form={shareForm} onClick={this.handleCancel} />
                  <SaveButton form={shareForm} onClick={this.handleSend} label="Send Mail" />
                </DialogActions>
              </form>
              <AlertDialog trigger={!!String(shareForm.responseError).length} message={this.errorMessage()} close={shareForm.resetResponseError} />
            </Dialog>
          )
        }
      </Fragment>
    );
  }
}

Sharing.wrappedComponent.propTypes = {
  applicationStore: PropTypes.object.isRequired,
  shareForm: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Sharing);
