import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { simappColors } from 'shared/lib/theme';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    float: 'right',
    justifyContent: 'start',
    alignItems: 'start',
    paddingTop: '6px',
    paddingBottom: '5px',
    fontSize: '12px',
    fontFamily: 'helvetica-neue-lt, sans-serif',
    color: simappColors.steelGrey
  }
};

@inject('pctStore') @observer
class PctAbbreviations extends Component {
  render() {
    const cDiff = this.props.cDiff ? <div>{this.props.pctStore.getText('AbbreviationCdiff')}</div> : '';
    const abr = this.props.abr ? <div>{this.props.pctStore.getText('AbbreviationAbr')}</div> : '';
    return (this.props.hidden ? '' : <div className={`${this.props.classes.root}`}>{cDiff}{abr}</div>);
  }
}

PctAbbreviations.wrappedComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  pctStore: PropTypes.object.isRequired,
  hidden: PropTypes.bool,
  cDiff: PropTypes.bool,
  abr: PropTypes.bool
};

PctAbbreviations.defaultProps = {
  hidden: false,
  cDiff: false,
  abr: false
};

export default withStyles(styles)(PctAbbreviations);
