import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observable, runInAction, when } from 'mobx';
import { inject, observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ShowDocument from './show-document';
import ShowPresentation from './show-presentation';

const styles = {
  video: {
    width: '100%',
    alignSelf: 'center'
  },
  image: {
    maxWidth: '100%',
    alignSelf: 'center',
    margin: 'auto'
  },
  iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  nothingOuter: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vW',
    height: '100vH',
    justifyContent: 'center',
    alignItems: 'center'
  },
};

@inject('applicationStore', 'materialStore', 'newsStore', 'routing') @observer
class Presentation extends Component {
  @observable source = null;

  constructor(props) {
    super(props);
    // What to do when /presentation without ID is called
    if (typeof props.match.params.uid === 'undefined') {
      // try to find first
      if (this.props.materialStore.current.length) {
        [props.match.params.uid] = this.props.materialStore.current;
        this.props.routing.push(`/presentation/${this.props.materialStore.current[0]}`);
      }
    }
    if (typeof props.match.params.uid !== 'undefined') {
      when(
        () => this.props.materialStore.materialsUpdated > 0,
        () => {
          const material = props.materialStore.getMaterialByUid(props.match.params.uid);
          this.props.applicationStore.setCurrentlyPresenting(material);
          if (this.props.applicationStore.currentlyPresenting.material_type !== this.props.materialStore.materialTypes.presentation) {
            this.props.materialStore.loadMaterial(material).then((document) => { runInAction(() => { this.source = document; }); });
          }
        }
      );
    }
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    when(
      () => this.props.materialStore.materialsUpdated > 0,
      () => {
        if (typeof nextProps.match.params.uid !== 'undefined') {
          const material = this.props.materialStore.getMaterialByUid(nextProps.match.params.uid);
          this.props.applicationStore.setCurrentlyPresenting(material);
          if (this.props.applicationStore.currentlyPresenting.material_type !== this.props.materialStore.materialTypes.presentation) {
            this.props.materialStore.loadMaterial(material).then((document) => { runInAction(() => { this.source = document; }); });
          }
        }
      }
    );
  }

  render() {
    // called /presentation
    if (typeof this.props.match.params.uid === 'undefined') {
      return (
        <div className={this.props.classes.nothingOuter}>
          <Typography variant="h4">You have no open presentations yet.</Typography>
        </div>
      );
    }
    // not yet loaded
    if (this.props.applicationStore.currentlyPresenting === null) {
      return <div />;
    }
    if (this.props.applicationStore.currentlyPresenting.material_type === this.props.materialStore.materialTypes.presentation) {
      return <ShowPresentation material={this.props.applicationStore.currentlyPresenting} />;
    }
    if (this.source === null) {
      return <div />;
    }
    return <ShowDocument material={this.props.applicationStore.currentlyPresenting} source={this.source} />;
  }
}

Presentation.wrappedComponent.propTypes = {
  match: PropTypes.object.isRequired,
  applicationStore: PropTypes.object.isRequired,
  materialStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  routing: PropTypes.object.isRequired,
};

export default withStyles(styles)(Presentation);
