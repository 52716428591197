import { tableSchema } from '@nozbe/watermelondb';
import { field, date } from '@nozbe/watermelondb/decorators';
import Model from './simapp-model';

export const accAssaySchema = tableSchema({
  name: 'accAssay',
  columns: [
    { name: 'b_id', type: 'number', isIndexed: true },
    { name: 'uid', type: 'string', isIndexed: true },
    { name: 'product', type: 'string' },
    { name: 'name', type: 'string' },
    { name: 'company_id', type: 'number' },
    { name: 'available_on', type: 'string' },
    { name: 'technology', type: 'string' },
    { name: 'tests', type: 'string' },
    { name: 'assay', type: 'string' },
    { name: 'reagent', type: 'string' },
    { name: 'sample_material', type: 'string' },
    { name: 'unit', type: 'string' },
    { name: 'measuring_range', type: 'string' },
    { name: 'measuring_range_automatic_dilution', type: 'string' },
    { name: 'dilution', type: 'string' },
    { name: 'time_to_results', type: 'string' },
    { name: 'onboard_stability', type: 'string' },
    { name: 'calibrator', type: 'string' },
    { name: 'calibrator_stability', type: 'string' },
    { name: 'detection_limit', type: 'string' },
    { name: 'fas', type: 'string' },
    { name: 'number_of_controls', type: 'string' },
    { name: 'no_hdh', type: 'string' },
    { name: 'international_standard', type: 'string' },
    { name: 'ifu', type: 'string' },
    { name: 'b_created_at', type: 'number' },
    { name: 'b_updated_at', type: 'number' },
    { name: 'created_at', type: 'number' },
    { name: 'updated_at', type: 'number' },
  ]
});

export default class AccAssay extends Model {
  static table = 'accAssay'

  @field('b_id') bId;
  @field('uid') uid;
  @field('product') product;
  @field('name') name;
  @field('company_id') companyId;
  @field('available_on') availableOn;
  @field('technology') technology;
  @field('tests') tests;
  @field('assay') assay;
  @field('reagent') reagent;
  @field('sample_material') sampleMaterial;
  @field('unit') unit;
  @field('measuring_range') measuringRange;
  @field('measuring_range_automatic_dilution') measuringRangeAutomaticDilution;
  @field('dilution') dilution;
  @field('time_to_results') timeToResults;
  @field('onboard_stability') onboardStability;
  @field('calibrator') calibrator;
  @field('calibrator_stability') calibratorStability;
  @field('detection_limit') detectionLimit;
  @field('fas') fas;
  @field('number_of_controls') numberOfControls;
  @field('no_hdh') noHdh;
  @field('international_standard') internationalStandard;
  @field('ifu') ifu;
  @date('b_created_at') bCreatedAt;
  @date('b_updated_at') bUpdatedAt;
  @date('created_at') createdAt;
  @date('updated_at') updatedAt;
}
