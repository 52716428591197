import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import { Draggable } from 'react-beautiful-dnd';
import { withStyles } from '@material-ui/core/styles';
import InnerTile from './content-piece-tile-inner';

const styles = {
  root: {
    width: '100%',
    marginBottom: '1em',
    cursor: 'pointer'
  }
};

@inject('presentationStore')
class ContentPieceTile extends Component {
  render() {
    return (
      <Draggable draggableId={`content-piece-list-${this.props.index}`} index={this.props.index} isDragDisabled={this.props.isDragDisabled}>
        {provided => (
          <div
            onClick={() => { this.props.presentationStore.startPreviewPresentation(this.props.index); }}
            className={this.props.classes.root}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <InnerTile piece={this.props.piece} index={this.props.index} isDragDisabled={this.props.isDragDisabled} />
          </div>
        )}
      </Draggable>
    );
  }
}

ContentPieceTile.wrappedComponent.propTypes = {
  presentationStore: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  piece: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  isDragDisabled: PropTypes.bool
};

ContentPieceTile.wrappedComponent.defaultProps = {
  isDragDisabled: false
};

export default withStyles(styles)(ContentPieceTile);
